<template>
  <div>
    <template v-if="!mobile">
      <AkButton
        v-if="!isLoading"
        data-testid="stock-alert-btn"
        class="stock-alert-btn"
        :class="{ 'ds-w-full': fullWidth }"
        :size="buttonSize"
        :outlined="btnType === 'outlined'"
        :link="btnType === 'link'"
        :loading="isLoading"
        :multiline="multiline"
        @click="onClick"
      >
        <span class="ds-flex ds-items-center">
          <AkIcon
            v-if="hasAlreadySetAlert"
            symbol="check"
            :size="iconSize"
            class="ds-mr-2"
          />
          <AkIcon
            v-else
            symbol="bell"
            :size="iconSize"
            class="ds-mr-2"
          />
          {{ alertMessage }}
        </span>
      </AkButton>
      <div
        v-else
        class="ds-w-full ds-h-4"
      >
        <AkSkeleton class="ds-w-[80px]" />
      </div>
    </template>
    <button
      v-else
      class="stock-alert-btn-mobile"
      @click="onClick"
    >
      <AkIcon
        :class="{ 'stock-alert-btn-mobile__icon--active': hasAlreadySetAlert || isLoading }"
        symbol="bell"
        size="lg"
      />
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import useI18n from '@/composables/use-i18n';

export default defineComponent({
  name: 'StockAlertBtn',
  props: {
    stockAlert: {
      type: Object,
      required: false,
      default: () => null,
    },
    productVariantId: {
      type: Number,
      required: false,
      default: null,
    },
    btnType: {
      type: String,
      required: false,
      default: 'outlined',
    },
    btnSize: {
      type: String,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    alertText: {
      type: String,
      required: false,
      default: null,
    },
    subscribedAlertText: {
      type: String,
      required: false,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['set-stock-alert'],
  setup(props, { emit }) {
    const i18n = useI18n();

    const hasAlreadySetAlert = computed(() => {
      return !!props.stockAlert?.id;
    });

    const buttonSize = computed((): string => {
      if (props.btnSize) {
        return props.btnSize;
      }

      return props.btnType === 'link' ? 'md' : 'lg';
    });

    const iconSize = computed((): string => {
      return buttonSize.value === 'md' ? 'sm' : 'md';
    });

    const alertMessage = computed((): string => {
      if (hasAlreadySetAlert.value) {
        return props.subscribedAlertText ?? i18n.t('@@reorder.stock_alert_btn.subscribed_alert');
      }

      return props.alertText ?? i18n.t('@@reorder.stock_alert_btn.set_alert');
    });

    const onClick = async () => {
      if (hasAlreadySetAlert.value) {
        return;
      }

      emit('set-stock-alert', props.productVariantId);
    };

    return {
      hasAlreadySetAlert,
      buttonSize,
      iconSize,
      alertMessage,
      onClick,
    };
  },
});
</script>

<style scoped>
.stock-alert-btn-mobile {
  @apply ds-bg-white ds-bg-opacity-30 ds-flex ds-items-center ds-justify-center ds-relative ds-h-6.5 ds-w-6.5;
  @apply ds-rounded-full ds-border-solid ds-border-neutral-300 ds-border;
  backdrop-filter: blur(16px);
}
.stock-alert-btn-mobile__icon--active {
  @apply ds-opacity-20;
}
</style>
