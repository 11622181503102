import { computed, Ref } from 'vue';
import { Location } from '@bc/discovery/domain/catalog';

export const useSortedLocations = (locations: Ref<Location[]>, locationFacetCounts: Ref<Record<string, number>>) => {
  const locationsMap = computed(() => new Map(locations.value.map((location) => [location.id, location])));

  return computed(() => {
    const descendingByFacetCount = (locationA: Location, locationB: Location) =>
      (locationFacetCounts.value[locationB.id] ?? 0) - (locationFacetCounts.value[locationA.id] ?? 0);

    const alphabeticalByName = (locationAId: string, locationBId: string) =>
      locationsMap.value.get(locationAId).name.localeCompare(locationsMap.value.get(locationBId).name);

    return [...locations.value].sort(descendingByFacetCount).map((location) => ({
      ...location,
      children: [...location.children].sort(alphabeticalByName),
    }));
  });
};
