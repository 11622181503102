<template>
  <div class="dropdown-widget__title">
    <AkParagraph class="ds-text-neutral-900 ds-py-2">
      {{ filter.label }}
    </AkParagraph>
    <AkButton
      v-if="hasSelectedValue"
      link
      symbol="x"
      @click="setValue('')"
    >
      {{ $t('brands.contactManagement.filtersDrawer.clear') }}
    </AkButton>
  </div>
  <div class="dropdown-widget">
    <AkSelect
      size="lg"
      :options="filter.values"
      :placeholder="filter.placeholder || $t('Select')"
      ::no-elements-message="$t('brand.productManagement.attributes.empty')"
      :model-value="modelValue.value"
      @update:model-value="setValue"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { isEmpty } from 'lodash-es';
import { AkParagraph, AkSelect } from '@ankorstore/design-system';
import { DropdownWidgetComponent } from '../../types/filter.types';
import { ContactFilterType, ValueContactFilter } from '../../types/api.types';

export default defineComponent({
  name: 'DropdownWidget',
  components: {
    AkParagraph,
    AkSelect,
  },
  props: {
    filter: {
      type: Object as PropType<DropdownWidgetComponent>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<ValueContactFilter>,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { filter } = props;
    const setValue = (value: string[]) => {
      emit('update:modelValue', {
        type: ContactFilterType.Value,
        name: filter.attributeName,
        value: value,
      });
    };

    const hasSelectedValue = computed(() => !isEmpty(props?.modelValue?.value));
    return { setValue, hasSelectedValue };
  },
});
</script>
<style scoped lang="scss">
.dropdown-widget {
  @apply ds-px-4;

  &__title {
    @apply ds-flex ds-items-center ds-justify-between;
  }
}
</style>
