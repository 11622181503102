import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import type { CategoryProps } from './types';
import { isEnabled } from '@/services/features';

const categorySlugPattern = '[A-Za-z-]+\\d+';
const toNumberWithDefault = (str: string, defaultValue = 0) => {
  const num = Number(str);
  return isNaN(num) ? defaultValue : num;
};
const getCategoryIds = (to: RouteLocationNormalized) => {
  if (!Array.isArray(to.params.categories)) {
    return [];
  }

  return to.params.categories
    .map((category) => {
      const categoryIdMatch = /\d+$/.exec(category);

      if (!categoryIdMatch?.length) {
        return undefined;
      }

      return Number(categoryIdMatch[0]);
    })
    .filter(Boolean);
};

const getCurrentView = (to: RouteLocationNormalized): CategoryProps['view'] => {
  const searchParams = new URLSearchParams(to.hash?.substring(1));
  return toNumberWithDefault(searchParams.get('view')) === 1 ? 'brands' : 'products';
};

const CategoryBrowser = () => import('./pages/category-browser.vue');
const EventBrowser = () => import('./pages/event-browser.vue');

export const createRoutes = (): RouteRecordRaw[] => [
  {
    name: 'new',
    component: CategoryBrowser,
    path: `/shop/new/:categories(${categorySlugPattern})*`,
    props: (to): CategoryProps => {
      return {
        categories: getCategoryIds(to),
        view: isEnabled('RET-2902') ? getCurrentView(to) : 'brands',
        basePath: '/shop/new',
      };
    },
  },
  {
    name: 'catalogEvent',
    component: EventBrowser,
    // Matches event slugs that end with a-z characters. They cannot end with a number.
    // This is to avoid conflicts with category slugs that do end with a number.
    path: `/shop/:slug(.*[A-Za-z])/:categories(${categorySlugPattern})*`,
    props: (to): CategoryProps => {
      return {
        categories: getCategoryIds(to),
        view: getCurrentView(to),
        basePath: `/shop/${to.params.slug}`,
      };
    },
  },
  {
    name: 'all-categories',
    path: `/shop/all/:categories(${categorySlugPattern})*`,
    component: CategoryBrowser,
    props: (to): CategoryProps => {
      return {
        categories: getCategoryIds(to),
        view: getCurrentView(to),
        basePath: '/shop/all',
      };
    },
  },
  {
    name: 'repeat-offer-locked-brands',
    path: `/shop/locked-brands/:categories(${categorySlugPattern})*`,
    component: CategoryBrowser,
    props: (to): CategoryProps => {
      return {
        categories: getCategoryIds(to),
        view: getCurrentView(to),
        basePath: '/shop/locked-brands',
        customFilter: 'repeat_offer_locked_brands:true',
      };
    },
  },
  {
    name: 'repeat-offer-unlocked-brands',
    path: `/shop/unlocked-brands/:categories(${categorySlugPattern})*`,
    component: CategoryBrowser,
    props: (to): CategoryProps => {
      return {
        categories: getCategoryIds(to),
        view: getCurrentView(to),
        basePath: '/shop/unlocked-brands',
        customFilter: 'repeat_offer_unlocked_brands:true',
      };
    },
  },
  {
    name: 'category',
    component: CategoryBrowser,
    path: `/shop/:categories(${categorySlugPattern})*`,
    props: (to): CategoryProps => {
      return {
        categories: getCategoryIds(to),
        view: getCurrentView(to),
        basePath: '/shop',
      };
    },
  },
];
