export interface HeaderPills {
  pill?: HeaderPill;
  ankorstore_plus_opt_in?: boolean;
}

export interface HeaderPill {
  title: string;
  description: string;
  action_type?: actionTypes;
  action_label?: string;
  feedback?: string;
  link_to_brand?: string;
}

export enum actionTypes {
  COPY = 'copy',
  INFO = 'info',
}
