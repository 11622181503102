<template>
  <div class="download-banner ds-mt-4">
    <AkBanner
      :title="
        type === UrlType.Invoices
          ? $t('Please, find the download link(s) of your invoices export below:')
          : $t('Please, find the download link(s) of your accounting export below:')
      "
    >
      <template #actions>
        <div>
          <AkParagraph class="ds-mb-1">
            {{ $t('Please note that the download link(s) will expire in 24 hours.') }}
          </AkParagraph>
          <a
            v-for="(url, i) in urls"
            :key="`${i}_${url.text}`"
            class="ak-link ds-block"
            :href="url.url"
          >
            {{ url.text }}
          </a>
        </div>
      </template>
    </AkBanner>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

interface LinkEntry {
  url: string;
  text: string;
}

enum UrlType {
  Invoices = 'invoices',
  Accounting = 'accounting',
}

export default defineComponent({
  name: 'BannerDownloadInvoices',
  props: {
    type: {
      type: String as PropType<UrlType>,
      default: 'invoices',
    },
    urls: {
      type: Array as PropType<LinkEntry[]>,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      UrlType,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
</style>
