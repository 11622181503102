export const combineArrays = (array1: { value: string, label: string }[], array2: { value: string, [k: string]: unknown }[]): { value: string, label: string }[] => {
  const valueLabelMap = new Map(array1.map(item => [item.value, item.label]));

  return array2.reduce((result, { value }) => {
      if (valueLabelMap.has(value)) {
          result.push({ value, label: valueLabelMap.get(value)});
      }
      return result;
  }, [] as {value: string, label: string}[]);
};
