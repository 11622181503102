import AnalyticsEvent from './analytics-event';

export enum UserDisplayEventAction {
  HOVER = 'hover',
  DISPLAY = 'display',
}

interface UserDisplayEventProps {
  component: string;
  action: UserDisplayEventAction;
  value?: string;
}

export class UserDisplayEvent extends AnalyticsEvent {
  public readonly name: string = 'User Display';

  constructor(public readonly properties: UserDisplayEventProps) {
    super();
  }
}
