import http from '@/services/api/http';
import { OnboardingAttributes } from '../../types';

const jsonApiConfigs = {
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
};

export const getBrandOnboardingInfo = async (brandUuid: string): Promise<OnboardingAttributes> => {
  const apiRoute = `/api/internal/v1/brands/${brandUuid}?include=onboarding`;
  const response = await http().get(apiRoute, jsonApiConfigs);
  return response.data?.included?.[0].attributes;
};

export const activateCandidateBrand = async (brandUuid: string): Promise<void> => {
  const apiRoute = `/api/internal/v1/brands/${brandUuid}`;
  const apiPayload = {
    status: 'online',
  };
  await http().patch(apiRoute, apiPayload, jsonApiConfigs);
  return;
};
