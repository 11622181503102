<template>
  <form
    class="email-only-form"
    @submit.prevent="onSubmit"
  >
    <div class="header">
      <R2bTitlePersonalize
        :brand-name="brandName"
        :lift-code-amount="promoAmount"
        :lift-code="promoCode"
      />
    </div>

    <div class="content">
      <div
        class="form"
        :class="fieldClassName(v$.email)"
        data-testid="email-validator"
      >
        <div class="form-label-group">
          <input
            id="email"
            v-model.lazy.trim="model.email"
            type="email"
            class="form-control hide-placeholder-on-focus"
            data-testid="register-email-input"
            name="email"
            autocomplete="email"
            required
            :placeholder="$t('retailer.registration.form.emailOnly.email.placeholder')"
            :class="{ 'is-invalid': !!v$.email.$errors.length }"
            @input="resetEmailValidation"
          />
          <label
            for="email"
            data-testid="register-email-input-label"
          >{{ $t('Business email address') }}
          </label>

          <div
            v-if="v$.email.$dirty"
            class="error-message"
          >
            <!-- validation messages -->
            <div
              v-if="v$.email.required && v$.email.required.$invalid"
              data-testid="business-email-is-required"
            >
              {{ $t('Business email is required') }}
            </div>
            <div
              v-if="v$.email.email && v$.email.email.$invalid"
              data-testid="business-email-is-invalid"
            >
              {{ $t('Business email is invalid') }}
            </div>
            <div
              v-if="!isEmailValidAfterCallToBE"
              data-testid="business-email-is-invalid-after-be-call"
            >
              {{ $t('Business email is invalid') }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="checkbox-form">
          <input
            id="tc"
            v-model="model.tc"
            type="checkbox"
            name="tc"
            data-akt="register-terms-checkbox"
            data-testid="register-terms-checkbox"
            required
          />
          <i18n-t
            keypath="I acknowledge having read and accept without reservation the {0}"
            tag="label"
            class="side-label"
            for="tc"
          >
            <a
              class="small-link ds-underline"
              :href="getGlobalConfig().language_config.retailer_terms_of_service_link"
              target="_blank"
              rel="noopener"
            >{{ $t('terms and conditions') }}</a>
          </i18n-t>
        </div>

        <div class="error-message">
          <div
            v-if="v$.tc.$error"
            data-testid="must-accept-terms-and-conditions"
          >
            {{ $t('You must accept the terms and conditions to complete the registration.') }}
          </div>
        </div>
      </div>
      <div>
        <AkButton
          v-if="!sending"
          class="button--extended"
          size="lg"
          data-testid="email-sign-up-submit-button"
          type="submit"
          @click="v$.$touch()"
        >
          {{ $t('Sign Up for free') }}
        </AkButton>
        <LoaderIcon v-else />
      </div>
    </div>

    <div class="footer">
      <AkButton
        link
        data-akt="register-change-to-login-link"
        data-testid="register-change-to-login-link"
        @click="$emit('change-to-login')"
      >
        {{ $t('Already have an account?') }} {{ $t('Log in now') }}
      </AkButton>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, reactive, watchEffect } from 'vue';
import Analytics from '@/services/analytics';
import { SignUpEventNames } from '@/services/analytics/events/sign-up/common/event-names';
import SignUpEvent from '@/services/analytics/events/sign-up/sign-up-event';
import { AkButton } from '@ankorstore/design-system';
import http from '@/services/api/http';
import { RetailerFormType } from '@/types/retailer-form-type';
import { SignUpProps } from '@/services/analytics/properties/sign-up-property';
import fieldClassName from '@/utilities/fieldClassName';
import { mapGetters } from 'vuex';
import { FeatureFlag } from '@/services/features';
import R2bTitlePersonalize from '@/components/sign-up/common/r2b-title-personalize.vue';
import LoaderIcon from '@/components/loader-icon.vue';
import { getGlobalConfig } from '@/services/global-config';
import { required, email } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default defineComponent({
  name: 'EmailSignUpForm',
  components: { AkButton, LoaderIcon, R2bTitlePersonalize },
  props: {
    analyticProps: {
      type: Object as () => SignUpProps,
      required: false,
      default: () => null,
    },
    email: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['success', 'change-to-login'],
  setup(props) {
    const model = reactive({
      email: '',
      tc: false,
    });
    const rules = {
      email: { required, email },
      tc: { required, value: (value: boolean) => value },
    };

    watchEffect(() => {
      if (props.email) {
        model.email = props.email;
      }
    });

    const v$ = useVuelidate(rules, model);

    return { v$, model };
  },
  data() {
    return {
      isEmailValidAfterCallToBE: true,
      sending: false,
      checkedTerms: false,
    };
  },
  computed: {
    ...mapGetters('signUp', ['liftBrandName', 'liftPromoCode', 'liftCodeAmount', 'email']),
    isLiftPage(): boolean {
      return window.location.pathname.startsWith('/lift');
    },
    brandName(): string {
      return this.$isEnabled(FeatureFlag.SignUpPersonalize) ? this.liftBrandName : '';
    },
    promoCode(): string {
      return this.$isEnabled(FeatureFlag.SignUpPersonalize) ? this.liftPromoCode : '';
    },
    promoAmount(): string {
      return this.$isEnabled(FeatureFlag.SignUpPersonalize) ? this.$root.formatPrice(this.liftCodeAmount, '$0') : '';
    },
  },

  mounted() {
    Analytics.track(
      new SignUpEvent(SignUpEventNames.EmailViewedEvent, {
        type: 'Element Viewed',
        ...this.analyticProps,
      })
    );
  },
  methods: {
    async onSubmit() {
      this.v$.$touch();
      const isValid = await this.v$.$validate();

      if (isValid) {
        this.signUpWithEmail();
      } else if (this.v$.email.$invalid) {
        Analytics.track(
          new SignUpEvent(SignUpEventNames.EmailMandatoryEvent, {
            type: 'Click',
            ...this.analyticProps,
          })
        );
      }
    },
    signUpWithEmail() {
      this.resetEmailValidation();
      this.sending = true;

      http()
        .post('/api/internal/v1/retailer/email-signup', {
          email: this.model.email,
        })
        .then((result) => {
          Analytics.track(
            new SignUpEvent(SignUpEventNames.EmailSubmittedEvent, {
              type: 'Click or Form Submit',
              ...this.analyticProps,
            })
          );
          if (result.status === 200 || result.status === 201) {
            this.$emit('success', {
              email: this.model.email,
              nextStep: RetailerFormType.Register,
            });
          } else if (result.status === 204) {
            this.$emit('success', {
              email: this.model.email,
              nextStep: RetailerFormType.Login,
            });
            Analytics.track(
              new SignUpEvent(SignUpEventNames.AlreadyAccountEvent, {
                type: 'Click',
                ...this.analyticProps,
              })
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.isEmailValidAfterCallToBE = false;
            Analytics.track(
              new SignUpEvent(SignUpEventNames.EmailMandatoryEvent, {
                type: 'Click',
                ...this.analyticProps,
              })
            );
          }
        })
        .finally(() => {
          this.sending = false;
        });
    },
    resetEmailValidation() {
      this.isEmailValidAfterCallToBE = true;
    },
    fieldClassName,
    getGlobalConfig,
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.modal-body form {
  @apply ds-px-4 md:ds-px-6;
}

.email-only-form {
  @apply ds-relative #{!important};
  top: 70px;

  .modal-body form {
    @apply ds-px-4 md:ds-px-6;
  }

  .header {
    &:deep(.title) {
      @apply ds-font-basic ds-font-semibold ds-text-lg lg:ds-text-xl ds-mb-2;
    }
    &:deep(.sub-title) {
      @apply ds-font-basic ds-text-center ds-mt-2 ds-mb-7 ds-font-normal ds-text-sm;
    }
  }

  .button--extended {
    @apply ds-mb-2;
    margin-top: 54px;
  }

  .small-link {
    @apply ds-text-primary;
  }

  .side-label {
    @apply ds-text-sm #{!important};
  }

  .footer {
    @apply ds-h-7 ds-flex ds-justify-center ds-mt-2.5 #{!important};
  }
}
</style>
