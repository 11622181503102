import { defineComponent } from 'vue';
import Dinero from 'dinero.js';
import {
  absolutePrice,
  amountPropertiesToUnit,
  amountToUnit,
  comparePrices,
  formatPrice,
  getCurrencySymbol,
  subtractPrices,
  sumPrices,
  toMinorUnitProperties,
  toUnit,
} from '@/utilities/price';
import { Amount } from '@/types/amount';

export const formatPriceMixins = defineComponent({
  data() {
    return {
      locale: this.$lang,
    };
  },
  methods: {
    getCurrencySymbol,
    absolutePrice,
    formatPrice(price: Amount, format = null) {
      return formatPrice(price, format, this.locale);
    },
    substractPrices: subtractPrices,
    sumPrices(prices: Dinero.DineroObject[]) {
      return sumPrices(prices, this.$currency);
    },
    comparePrices,
    toMinorUnitProperties,
    toUnit,
    amountToUnit,
    amountPropertiesToUnit,
  },
});
