import {
  AkNavigationItemLink,
  AkNavMenuItem,
  AkNavMenuRelated,
  AkNavMenuMeta,
  AkNavigationItemGroup,
  AkNavMenuData,
  AkNavigationItem,
  AkNavMenuChild,
} from '@ankorstore/design-system';

export const getCategoryItem = (item: AkNavMenuItem): AkNavigationItemLink =>
  ({
    label: item?.attributes.label || '',
    url: item?.attributes.url || '',
    target: item?.attributes.target || 'self',
    external: item?.attributes.external || false,
    logo: item?.attributes.logo || null,
    meta: item.meta || {},
  } as AkNavigationItemLink);

export const getRelatedLinks = (related: AkNavMenuRelated, meta?: AkNavMenuMeta) => {
  const topSectionLinks = [];
  const bottomSectionLinks = [];
  if (related) {
    for (const key in related) {
      const link = related[key];
      const { label, url, target, external, logo } = link;
      const linkData: AkNavigationItemLink = {
        label,
        logo,
        url,
        target,
        external,
      } as unknown as AkNavigationItemLink;
      if (meta) {
        const relatedId = meta?.id_category || '';
        const relatedName = link.trackingEvent || '';
        linkData.meta = {
          depth: meta.depth + 1,
          position: meta.position || 0,
          id_category: relatedId,
          name_category: relatedName,
        };
      }
      if (key === 'self') {
        topSectionLinks.push(linkData);
      } else {
        bottomSectionLinks.push(linkData);
      }
    }
  }
  return {
    topSectionLinks,
    bottomSectionLinks,
  };
};

type groupType = {
  leftSectionGroup?: AkNavigationItemGroup;
  rightSectionGroup?: AkNavigationItemGroup;
};

export const getCategoriesGroup = (categories: AkNavMenuChild[] | AkNavigationItemLink[], parentEvent?: string): groupType => {
  const groups: groupType = {};
  let children: AkNavigationItemLink[] = [];
  categories.forEach((category, index) => {
    const { attributes, type, meta } = category;
    if (attributes.children) {
      children = attributes.children?.map((item) => {
        const name_category =
          index === 0 ? item.attributes?.relatedEntity : item.attributes?.trackingEvent || item.attributes?.relatedEntity;
        const id_category = item.attributes.relatedEntityId;
        const { external, label, meta, target, url, logo } = getCategoryItem(item as unknown as AkNavMenuItem);
        const childrenList = item.attributes.children
          ? item.attributes.children.map((child) => {
              const id_category = child.attributes.relatedEntityId;
              const name_category =
                child.attributes.relatedEntity === 'brand'
                  ? `${parentEvent} > ${child.attributes.trackingEvent}`
                  : child.attributes.relatedEntity;
              const { external, label, meta, target, url, logo } = getCategoryItem(child as unknown as AkNavMenuItem);
              return {
                logo,
                external,
                label,
                meta: {
                  ...meta,
                  id_category: id_category,
                  name_category: name_category,
                },
                target,
                url,
              };
            })
          : [];
        const related = getRelatedLinks(item.attributes.related, {
          ...meta,
          name_category: item.attributes.trackingEvent,
          id_category: item.attributes.relatedEntityId,
        });
        return {
          external,
          label,
          logo,
          target,
          url,
          meta: { ...meta, name_category, id_category },
          ...(item.attributes.children && {
            children: childrenList,
          }),
          ...(item.attributes.related && related),
        };
      });
    } else {
      children = categories.map((item) => {
        const { external, label, meta, target, url, logo } = getCategoryItem(item as unknown as AkNavMenuItem);
        return {
          external,
          logo,
          label,
          target,
          url,
          meta: {
            ...meta,
            name_category: item.attributes.relatedEntity,
            id_category: item.attributes.trackingEvent,
          },
        };
      });
    }
    if (index === 0) {
      groups.leftSectionGroup = {
        title: attributes.title,
        type: type,
        meta: meta,
        children: children,
      };
    } else if (attributes.children) {
      groups.rightSectionGroup = {
        title: attributes.title,
        type: type,
        meta: meta,
        children: children,
      };
    }
  });
  return groups;
};

export const prepareDataForAkNavBar = (menuData: AkNavMenuData[]): AkNavigationItem[] => {
  return menuData.map((menuItem) => {
    const { attributes, meta } = menuItem;
    const { label, url, target, external, related, children, trackingEvent, relatedEntity, relatedEntityId } = attributes;
    const { topSectionLinks, bottomSectionLinks } = related
      ? getRelatedLinks(related, { ...meta, name_category: '', id_category: relatedEntityId })
      : {
          topSectionLinks: undefined,
          bottomSectionLinks: undefined,
        };
    const { leftSectionGroup, rightSectionGroup } = children
      ? getCategoriesGroup(children, trackingEvent)
      : {
          leftSectionGroup: undefined,
          rightSectionGroup: undefined,
        };
    return {
      label,
      url,
      target,
      external,
      meta: { ...meta, name_category: relatedEntity || trackingEvent, id_category: relatedEntityId },
      topSectionLinks,
      bottomSectionLinks,
      leftSectionGroup,
      rightSectionGroup,
    } as AkNavigationItem;
  });
};
