export interface BrandDiscount {
  brand_id: number;
  brand_uuid: string;
  title: string;
  message: string | null;
  metadata: {
    discount_rate: number;
  };
}

export enum BrandDiscountContext {
  brandPage = 'brand_page',
  productPage = 'product_page',
  cartPage = 'cart_page',
  checkoutPage = 'checkout_page',
  checkoutConfirmationPage = 'checkout_confirmation_page',
}
