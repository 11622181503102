<template>
  <div
    v-if="isReady"
    ref="catalogRef"
    class="catalog-with-filters catalog"
    :class="{
      'catalog--no-padding': config.context === contextType.COLLECTION_PRODUCTS || config.context === contextType.BRAND,
    }"
    :style="cssVars"
  >
    <AisInstantSearch
      :search-client="searchClient"
      :index-name="indexName"
      :routing="routing"
      :middlewares="middlewares"
      :future="{
        preserveSharedStateOnUnmount: true,
      }"
    >
      <AisConfigure
        :hits-per-page.camel="60"
        :query-languages.camel="[lang]"
        :filters="filters"
        :restrict-searchable-attributes.camel="searchableAttribute"
        :attributes-to-retrieve.camel="attributesToRetrieve"
        :analytics-tags.camel="[`lang_${lang}`, `country_${userCountry}`]"
        :max-values-per-facet.camel="100"
      />
      <AisSearchBox
        class="ds-hidden"
        placeholder="string"
        submit-title="string"
        reset-title="string"
      />
      <template v-for="facet in config.Facets">
        <template v-if="'facets' in facet">
          <CatalogHiddenRefinement
            v-for="groupedFacet in facet.facets"
            :key="groupedFacet.uid"
            :facet="groupedFacet"
          />
        </template>
        <CatalogHiddenRefinement
          v-else
          :key="facet.uid"
          :facet="facet"
        />
      </template>
      <slot
        v-if="config.withBreadCrumb"
        name="breadcrumb"
        :catalog-state="aisState"
      >
        <CatalogBreadcrumb
          v-if="categories"
          :search-query="aisState.searchQuery.value"
          :categories="categories"
        />
      </slot>
      <div class="catalog__top-header">
        <slot
          name="topHeader"
          :catalog-state="aisState"
        />
      </div>
      <div
        class="searchContainer ds-block lg:ds-grid ds-grid-flow-col ds-gap-x-5 ds-auto-cols-auto lg:ds-auto-cols-max"
        style="grid-template-columns: auto 1fr"
      >
        <slot
          name="sidebar"
          :catalog-state="aisState"
        >
          <FilterSidebar
            v-if="config.withFilterSidebar"
            class="catalog__sidebar"
            :facets="config.Facets"
            :label-overrides="labelOverrides"
            :show-categories="Boolean(config.withCategoriesFilter && categories)"
            :show-view-filter="Boolean(config.withTabs)"
            :current-view="currentView"
            @view-changed="(view) => emit('update:currentView', view)"
          >
            <template #topSection>
              <SortsBy
                v-if="config.withSort"
                v-model:sortBy="sortBy"
                :current-sort="sortBy"
                class="ds-block lg:ds-hidden"
                :index-name="indexName"
              />
            </template>
          </FilterSidebar>
        </slot>
        <main class="catalog__main">
          <slot
            v-if="config.withTitle"
            name="header"
            :catalog-state="aisState"
            :track-brand-suggestion-clicked="trackBrandSuggestionClicked"
          />
          <div class="catalog__top-bar">
            <CatalogSearchInput
              v-if="config.withSearchInput"
              class="ds-flex-grow"
            />
            <QuickFiltersBar
              v-if="config.withQuickFilters"
              :facets="config.quickFilters"
              :label-overrides="labelOverrides"
            />
          </div>
          <div class="ds-flex ds-flex-col ds-gap-5">
            <div class="headSideBar">
              <ais-stats>
                <template #default="{ nbHits }">
                  {{ nbHits }} {{ $t('Results') }}
                </template>
              </ais-stats>
              <SortsBy
                v-if="config.withSort"
                v-model:sortBy="sortBy"
                :current-sort="sortBy"
                class="sortByDesktop"
                :index-name="indexName"
              />
            </div>
            <slot
              name="hits-header"
              :catalog-state="aisState"
            />
            <CatalogHits
              :key="route.fullPath"
              :config="config"
              :related-collection-insertions="relatedCollectionInsertions"
            >
              <template #noResults>
                <AisClearRefinements>
                  <template #default="{ refine }">
                    <SearchNoResult
                      class="ds-my-4"
                      data-testid="search-results-none"
                      :search-term="aisState.searchQuery.value"
                      :context="config.context"
                      :has-refinements="hasRefinements"
                      @click="refine"
                    />
                  </template>
                </AisClearRefinements>
              </template>
            </CatalogHits>
            <CatalogPagination
              :total-pages="config.maxPages"
              @page-change="handlePageChange"
            />
            <slot
              v-if="shouldDisplayFooter"
              name="hits-footer"
              :catalog-state="aisState"
            />
          </div>
        </main>
      </div>
      <footer>
        <slot
          v-if="shouldDisplayFooter"
          name="footer"
          :catalog-state="aisState"
        />
      </footer>
      <PortalTarget name="modal-target" />
    </AisInstantSearch>
  </div>
</template>

<script lang="ts">
import { PropType, toRefs, ref, computed, defineComponent, onBeforeMount, watch, watchEffect, nextTick } from 'vue';
import {
  searchClient as getSearchClient,
  Config,
  Context,
  CatalogGridInsertion,
  HistoryRouterManager,
  HistoryRouterProduct,
  HistoryRouterBrand,
  getAttributeToRetrieve,
  getDefaultFilter,
  getRestrictedSearchableAttributes,
  getLocations,
  type Location,
} from '@bc/discovery/domain/catalog';
import { InstantSearchRouterStateMapper } from '../services/instant-search-state-mapper';
import CatalogPagination from '@/components/catalog-pagination.vue';
import CatalogBreadcrumb from './catalog-breadcrumb.vue';
import CatalogSearchInput from './catalog-search-input.vue';
import CatalogHits from './catalog-hits.vue';
import { DiscoveryTrackingEvents } from '@bc/discovery/domain/tracking';
import StoreRootHelpers from '@/store/helpers';
import { useRoute, useRouter } from 'vue-router';
import useGlobals from '@/composables/use-globals';
import useCategories from '../composables/useCategories';
import { FilterSidebar, QuickFiltersBar, SortsBy } from '@bc/discovery/feature/filters';
import SearchNoResult from '../components/search-no-result/search-no-result.vue';
import { CatalogHiddenRefinement } from '@bc/discovery/ui/catalog';
import { useViewPort } from '@/composables/use-viewport';
import { PortalTarget } from 'portal-vue';
import { getCatalogTrackingHandlers } from '../services/tracking';
import useCatalogTrackingData from '../composables/use-catalog-tracking-data';
import { useEventBus } from '@bc/shared';
import { useLabels } from '../composables/use-labels';
import { useAisState } from '../composables/use-ais-state';
import { useCatalogTilesPerRow } from '../composables/use-catalog-tiles-per-row';
import { AisSearchBox, AisClearRefinements } from 'vue-instantsearch/vue3/es';
import { debounce } from 'lodash-es';

export default defineComponent({
  name: 'DiscoveryCatalog',
  components: {
    AisSearchBox,
    AisClearRefinements,
    CatalogPagination,
    CatalogBreadcrumb,
    CatalogSearchInput,
    CatalogHits,
    CatalogHiddenRefinement,
    FilterSidebar,
    QuickFiltersBar,
    PortalTarget,
    SortsBy,
    SearchNoResult,
  },
  props: {
    indexName: {
      type: String,
      required: true,
    },
    config: {
      type: Object as PropType<Config>,
      required: true,
    },
    brandId: {
      type: Number,
      default: null,
    },
    customFilter: {
      type: String,
      default: null,
    },
    valuePropositionOverride: {
      type: String,
      default: null,
    },
    basePath: {
      type: String,
      default: '',
    },
    currentView: {
      type: String,
      required: false,
      default: undefined,
    },
    relatedCollectionInsertions: {
      type: Object as PropType<CatalogGridInsertion>,
      default: undefined,
    },
    category: {
      type: Number,
      default: null,
    },
    queryOverride: {
      type: String,
      default: undefined,
    },
  },
  emits: ['update:trackingSectionId', 'update:currentView', 'update:catalogState'],
  setup(props, { emit }) {
    const { indexName, config, brandId, valuePropositionOverride, category } = toRefs(props);
    const { dimensions } = useViewPort();
    const { aisState, aisStateMiddleware } = useAisState();
    const hasRefinements = computed(() => Boolean(aisState.refinements.value.refinementCount));
    const catalogRef = ref<HTMLDivElement>(undefined);
    const contextType = Context;
    const filters = ref();
    const { lang } = useGlobals();
    const router = useRouter();
    const route = useRoute();
    const { user, userCountry, userCurrency, isStaff } = StoreRootHelpers.useGetters([
      'user',
      'userCountry',
      'userCurrency',
      'isStaff',
    ]);
    const { categories } = useCategories();

    const { eventBus } = useEventBus();
    const catalogTrackingData = useCatalogTrackingData({
      config,
      valueProposition: computed(() => valuePropositionOverride?.value ?? aisState.searchQuery.value),
    });
    const {
      filtersTracker,
      trackSectionCreated,
      trackTileProductClicked,
      trackTileBrandClicked,
      trackTileSponsoredProductClicked,
      trackProductSnippetBrandClicked,
      trackQuickAddToCartProductAdded,
      trackQuickAddToCartClicked,
      // TODO: this method should be moved to a search shell tracking module
      // for the time being, i'll pass it using a slot prop.
      trackBrandSuggestionClicked,
      trackRelatedCollectionClicked,
    } = getCatalogTrackingHandlers(catalogTrackingData);

    const locations = ref<Location[]>();

    const catalogTilesPerRow = useCatalogTilesPerRow();
    const searchClient = getSearchClient(props.config, undefined, catalogTilesPerRow, userCountry.value);

    const attributesToRetrieve = computed(() => getAttributeToRetrieve(config.value.template));
    const searchableAttribute = computed(() => getRestrictedSearchableAttributes(config.value.context));

    const historyRouterParams = {
      router,
      getCategories: () => categories.value,
      getLocations: () => locations.value,
      getLang: () => lang,
      getCurrency: () => userCurrency.value as string,
      urlManager: () => config.value.urlManager,
      getBasePath: () => props.basePath,
    };
    const routing = {
      router: new HistoryRouterManager(
        historyRouterParams,
        computed(() => config.value.template),
        new HistoryRouterProduct(historyRouterParams),
        new HistoryRouterBrand(historyRouterParams)
      ),
      stateMapping: new InstantSearchRouterStateMapper(() => indexName.value, props.queryOverride),
    };

    const isReady = computed(() => Boolean(config.value && categories.value && locations.value));
    const shouldDisplayFooter = computed(() => aisState.status.value === 'idle' && aisState.hits.value?.length > 0);
    const { labelOverrides } = useLabels(config, category, ref(lang));

    watchEffect(() => emit('update:trackingSectionId', catalogTrackingData.trackingSectionId.value));
    watchEffect(() => emit('update:catalogState', aisState));

    const isReadySectionTracking = computed(() => aisState.status.value === 'idle' && route.path);
    const isReadyFilterTracking = computed(() => aisState.status.value === 'idle' && route.fullPath);

    watch(
      isReadySectionTracking,
      debounce(() => {
        trackSectionCreated(aisState.refinements.value, aisState.pagination.value.page, aisState.hits.value);
      }, 500)
    );

    watch(
      isReadyFilterTracking,
      debounce(() => {
        filtersTracker(aisState.refinements.value);
      }, 500)
    );

    const sortBy = ref<string>();
    watchEffect(() => {
      const hashParams = new URLSearchParams(router.currentRoute.value.hash?.substring(1) ?? '');
      sortBy.value = hashParams.get('sortBy');
    });

    watchEffect(() => {
      filters.value = getDefaultFilter(
        {
          country: userCountry.value,
          brandId: brandId.value,
          user: user.value,
          categoryId: category.value,
          isStaff: isStaff.value,
          sortBy: sortBy.value,
        },
        config.value.context,
        props.customFilter
      );
    });

    onBeforeMount(async () => {
      const _locations = await getLocations();
      await nextTick();
      locations.value = _locations;
    });

    eventBus
      .on(DiscoveryTrackingEvents.TILE_PRODUCT_CLICKED, trackTileProductClicked)
      .on(DiscoveryTrackingEvents.TILE_BRAND_CLICKED, trackTileBrandClicked)
      .on(DiscoveryTrackingEvents.TILE_SPONSORED_PRODUCT_CLICKED, trackTileSponsoredProductClicked)
      .on(DiscoveryTrackingEvents.PRODUCT_SNIPPET_BRAND_CLICKED, trackProductSnippetBrandClicked)
      .on(DiscoveryTrackingEvents.QUICK_ADD_TO_CART_CLICKED, trackQuickAddToCartClicked)
      .on(DiscoveryTrackingEvents.QUICK_ADD_TO_CART_PRODUCT_ADDED, trackQuickAddToCartProductAdded)
      .on(DiscoveryTrackingEvents.RELATED_COLLECTION_CLICKED, trackRelatedCollectionClicked);

    const handlePageChange = () => {
      const pageHeaderHeight = (document.getElementsByClassName('ak-header')[0] as HTMLDivElement)?.offsetHeight ?? 0;
      catalogRef.value?.scrollIntoView();
      window.scrollBy(0, -pageHeaderHeight);
    };

    const cssVars = computed(() => {
      // The header offset is used to calculate the top spacing for the sticky filter bar.
      // Conditionally use the desktop or mobile header height instead of the complete <header> height to avoid
      // a re-render when the banner-topline is hidden on scroll.
      const headerOffset = dimensions.desktopHeaderRect?.height || dimensions.mobileHeaderRect?.height || 0;

      return {
        '--sticky-blocks-top-spacing': `${headerOffset}px`,
      };
    });

    return {
      aisState,
      catalogRef,
      isReady,
      emit,
      lang,
      userCountry,
      categories,
      searchClient,
      trackBrandSuggestionClicked,
      searchableAttribute,
      attributesToRetrieve,
      filters,
      routing,
      middlewares: [aisStateMiddleware],
      labelOverrides,
      contextType,
      handlePageChange,
      route,
      cssVars,
      shouldDisplayFooter,
      sortBy,
      hasRefinements,
    };
  },
});
</script>

<style lang="scss" scoped>
@use '@css/abstracts/design-tokens' as d;
@import '@css/vue-import';
.catalog {
  &__sidebar {
    top: var(--sticky-blocks-top-spacing);
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: theme('spacing.4');
  }

  &__top-header {
    margin-bottom: 40px;
  }

  &__top-bar {
    display: none;
    //spacing is splitted into margin and padding due to the sticky behavior
    // negate the gap defined by __main by 50% and use padding to fill the space instead
    z-index: 40;
    position: sticky;
    top: var(--sticky-blocks-top-spacing);
    background-color: theme('colors.white');
    gap: 1rem;
    align-items: center;
    @include d.lg() {
      display: flex;
    }
  }
  &--no-padding {
    padding: 0;
  }
  @include media-breakpoint-up(lg) {
    &__category-filter {
      width: 17rem;
    }
    &__sidebar {
      width: 20.4rem;
    }
  }
  .headSideBar {
    @apply ds-pt-5;
    @include d.lg() {
      @apply ds-flex;
    }
    @include d.md() {
      @apply ds-pt-0;
    }
    .sortByDesktop {
      @apply ds-ml-auto ds-hidden;
      @include media-breakpoint-up(lg) {
        @apply ds-block;
      }
    }
    .sortByMobile {
      @apply ds-ml-auto ds-block;
      @include d.md() {
        @apply ds-hidden;
      }
    }
  }
}
</style>
