import { simple } from 'instantsearch.js/es/lib/stateMappings';

export class InstantSearchRouterStateMapper {
  private readonly simpleMapping = simple();

  constructor(
    private readonly getIndexName: () => string,
    private readonly queryOverride?: string
  ) {}

  stateToRoute(uiState) {
    const route = { ...this.simpleMapping.stateToRoute(uiState)[this.getIndexName()] };
    const query = uiState[this.getIndexName()]?.query;
    if (this.queryOverride) {
      route['q'] = undefined;
      delete route.query;
    } else if (query) {
      route['q'] = query;
      delete route.query;
    }
    return route;
  }

  routeToState(routeState) {
    const state = { ...this.simpleMapping.routeToState(routeState) };
    if (state.page) {
      state.page = routeState.page;
    }
    if (this.queryOverride) {
      state.query = this.queryOverride;
    } else if (state.q) {
      state.query = routeState.q;
      delete state.q;
    }

    return {
      [this.getIndexName()]: state,
    };
  }
}
