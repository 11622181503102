<script lang="ts">
import { computed, defineComponent } from 'vue';
import useCategories from '../../composables/useCategories';
import { AkButton, AkParagraph } from '@ankorstore/design-system';

export default defineComponent({
  name: 'SelectedCategoryTitle',
  components: {
    AkButton,
    AkParagraph,
  },
  props: {
    selectedCategoryId: {
      type: [String, Number],
      default: undefined,
    },
  },
  emits: ['clicked'],
  setup(props, { emit }) {
    const { categories } = useCategories();
    const handleClicked = () => emit('clicked');
    const currentCategoryName = computed(() => {
      const category = categories.value?.[`${props.selectedCategoryId}`];

      if (!category) {
        return '';
      }

      return category.full_name;
    });

    return {
      handleClicked,
      currentCategoryName,
    };
  },
});
</script>

<template>
  <AkParagraph v-if="currentCategoryName && $isEnabled('RET-1880')">
    {{ $t('Showing results in the {category} category only.', { category: currentCategoryName }) }}
    <AkButton
      size="lg"
      link
      @click="handleClicked"
    >
      {{ $t('searchpage.suggestions.categories.reset') }}
    </AkButton>
  </AkParagraph>
</template>
