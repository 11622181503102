<template>
  <div class="negociated-price-form">
    <AkParagraph
      class="negociated-price-form__label"
      data-testid="negociatedPriceFormLabel"
    >
      {{ $t('bestpriceguarantee.negociatedprice.textarea.label') }}
    </AkParagraph>
    <template v-if="!conversationStarted">
      <div class="negociated-price-form__message">
        <AkTextarea
          v-model="comment"
          class="negociated-price-form__textarea"
          :rows="4"
          :placeholder="$t('public_price.form.comment.placeholder')"
          :maxlength="200"
        />
        <AkParagraph
          size="sm"
          class="negociated-price-form__legend"
        >
          {{ $t('bestPriceGuarantee.negociatedPrice.legend.lbl') }}
        </AkParagraph>
        <div class="negociated-price-form__actions">
          <AkButton
            size="lg"
            class="negociated-price-form__submit"
            symbol="telegram"
            :disabled="v$.$invalid"
            @click="submit"
          >
            {{ $t('bestpriceguarantee.negociatedprice.cta_submit') }}
          </AkButton>
        </div>
      </div>
    </template>
    <Chat
      v-else
      :id="brandId"
      type="brand"
      :name="brandName"
      :image="brandImage"
      :closable="false"
      origin="Product page"
      :with-padding="false"
      is-best-price-feedback
      data-testid="requestADiscountChat"
      :legend="$t('bestPriceGuarantee.negociatedPrice.legend.lbl')"
    />
  </div>
</template>
<script lang="ts">
import Chat from '@/components/popins/chat.vue';
import { conversationsMixin } from '@/mixins/conversations';
import { AkParagraph, AkTextarea } from '@ankorstore/design-system';
import { submitBpgRequest } from '@/modules/shared/best-price-guarantee/services/bpg-report-issue';
import { BpgIssueType, BpgReportIssueRequest } from '@/modules/shared/best-price-guarantee/types/types';
import { captureException } from '@/sentry';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from 'vuelidate/lib/validators';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NegociatedPriceForm',
  components: { AkParagraph, Chat, AkTextarea },
  mixins: [conversationsMixin],
  props: {
    brandId: {
      type: Number,
      required: true,
    },
    brandName: {
      type: String,
      required: true,
    },
    brandImage: {
      type: String,
      required: true,
    },
    productVariantId: {
      type: Number,
      required: true,
    },
  },
  emits: ['submitInitialMessage'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      conversationStarted: false,
      comment: null,
    };
  },
  methods: {
    async submit(): Promise<void> {
      const payload: BpgReportIssueRequest = {
        fields: { description: this.comment },
        type: BpgIssueType.private,
        productVariantId: this.productVariantId,
      };
      try {
        await submitBpgRequest(payload);
        this.conversationStarted = true;
        this.$emit('submitInitialMessage');
      } catch (e) {
        captureException(e);
      }
    },
  },
  validations: {
    comment: { required, maxLength: maxLength(200) },
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';

.negociated-price-form {
  @apply ds-text-left;
  &__label,
  &__legend {
    @apply ds-text-neutral-900;
  }
  &__label {
    @apply ds-mb-2;
  }
  &__textarea {
    @apply ds-mb-4;
  }
  &__actions {
    @apply ds-flex ds-justify-center;
  }
  &__history {
    @apply ds-bg-warm-white;
  }
}
</style>
