import { ESBrandHit as BrandHit } from '@bc/discovery';

export const getHighestDiscount = (brand: Pick<BrandHit, 'business_events_eligible' | 'business_events'>): number | undefined => {
  if (!brand?.business_events_eligible || !brand?.business_events) {
    return undefined;
  }

  const descending = (a: number, b: number) => b - a;

  const businessEvents = new Set(brand.business_events_eligible);
  const [highestDiscount] = Object.entries(brand.business_events)
    .filter(([eventId]) => businessEvents.has(parseInt(eventId)))
    .map(([, brandBusiness]) => (brandBusiness ? brandBusiness.discount : undefined))
    .sort(descending);

  return highestDiscount;
};
