<template>
  <form
    class="login-form"
    @submit.prevent="submit"
  >
    <div class="header">
      <p class="title">
        {{ $t('So happy to see you again!') }}
      </p>
      <p class="sub-title">
        {{ $t('Fill form to log in') }}
      </p>
    </div>

    <div class="content">
      <div
        v-if="loading"
        class="content-loader"
      >
        <LoaderIcon />
      </div>
      <div
        v-else
        class="form"
      >
        <div class="form-label-group">
          <input
            id="email"
            v-model="email"
            type="email"
            class="form-control hide-placeholder-on-focus"
            data-akt="login-email-input"
            data-testid="login-email-input"
            name="email"
            autocomplete="username"
            :placeholder="$t('E-Mail Address')"
            required
          />
          <label for="email">{{ $t('E-Mail Address') }}</label>
        </div>
        <div class="form-label-group password">
          <input
            id="password"
            v-model="password"
            type="password"
            class="form-control hide-placeholder-on-focus"
            data-akt="login-password-input"
            name="password"
            autocomplete="current-password"
            :placeholder="$t('Password')"
            required
          />
          <label for="password">{{ $t('Password') }}</label>
        </div>
        <div
          v-if="!loading"
          class="pwd"
        >
          <AkButton
            class="forgot-pwd"
            link
            size="md"
            data-testid="loginPasswordForgottenLink"
            @click.prevent="$emit('change-to-reset-password')"
          >
            {{ $t('Forgot password?') }}
          </AkButton>
        </div>
      </div>

      <div
        v-if="errorMessage"
        class="global-error"
      >
        {{ errorMessage }}
      </div>
      <AkButton
        class="button--extended ds-mt-3"
        size="xl"
        data-akt="login-submit-button"
        @click="submit"
      >
        {{ $t('Log in') }}
      </AkButton>
    </div>

    <div class="footer">
      <AkButton
        link
        data-akt="login-change-to-register-link"
        @click.prevent="$emit('change-to-register')"
      >
        {{ $t('No account yet?') }} {{ $t('Register now') }}
      </AkButton>
    </div>
  </form>
</template>

<script lang="ts">
import http from '@/services/api/http';
import { AkButton } from '@ankorstore/design-system';
import LoaderIcon from '@/components/loader-icon.vue';

import { defineComponent } from 'vue';
import { FeatureFlag, isEnabled } from '@/services/features';

export default defineComponent({
  name: 'LoginForm',
  components: { AkButton, LoaderIcon },
  props: {
    prefilledEmail: {
      type: String,
      required: false,
      default: '',
    },
    deviceId: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['change-to-register', 'change-to-reset-password', 'success', 'change-to-two-factor'],
  data: () => {
    return {
      email: '',
      password: '',
      errorMessage: '',
      loading: false,
    };
  },
  mounted() {
    this.email = this.prefilledEmail;
  },
  methods: {
    close() {
      this.$parent.$parent.hide();
    },
    submit() {
      this.$store.commit('SET_PROGRESS_BAR_STATE', 'loading');
      this.errorMessage = '';
      this.loading = true;
      const requestConfig = isEnabled(FeatureFlag.Ravelin)
        ? {
            headers: {
              'X-Device-Id': this.deviceId,
            },
          }
        : {};
      http()
        .post(
          '/auth/login',
          {
            email: this.email,
            password: this.password,
          },
          requestConfig
        )
        .then((response) => {
          this.loading = false;
          const twoFactorHeader = response.headers['x-aks-2fa-status'] ?? '';
          if (twoFactorHeader === 'required' || response.data?.two_factor) {
            this.$emit('change-to-two-factor');
          } else {
            this.$emit('success', response);
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response?.data?.errors?.email?.[0]) {
            this.errorMessage = error.response.data.errors.email[0];
          } else {
            this.errorMessage = this.$t('Cannot log in. Please refresh and retry!');
          }
        })
        .finally(() => {
          this.$store.commit('SET_PROGRESS_BAR_STATE', 'ending');
        });
    },
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';

.content-loader {
  min-height: 115px;
  @apply ds-flex;
  align-items: center;
}

.button {
  &__submit {
    @apply ds-text-base;
  }
}

.footer {
  @apply ds-flex ds-justify-center;
}
</style>
