<template>
  <AkTooltipInformation
    type="invert"
    symbol="truck"
    title="EORI number"
  >
    <AkIcon
      symbol="info-circle"
      size="md"
    />

    <template #container>
      <AkParagraph
        size="sm"
        class="ds-mb-0"
      >
        <i18n-t
          keypath="To continue your purchase you need to enter your EORI number or remove the items from your order. Customs authorities require retailers buying goods between EU / UK to have an EORI number to clear customs. More information can be found in our {0}."
          tag="span"
        >
          <a
            :href="$t('shipping_links_eori_number_overview')"
            rel="noopener noreferrer"
            target="_blank"
            class="ds-underline hover:ds-text-white"
          >
            {{ $t('FAQ') }}
          </a>
        </i18n-t>
      </AkParagraph>
    </template>
  </AkTooltipInformation>
</template>

<script lang="ts">
import { AkIcon, AkTooltipInformation, AkParagraph } from '@ankorstore/design-system';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconTooltipEori',
  components: { AkIcon, AkTooltipInformation, AkParagraph },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
</style>
