import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

const getCategoryIds = (route: RouteLocationNormalized): number[] => {
  const categories = Array.isArray(route.query.categories) ? route.query.categories : route.query.categories?.split(',') ?? [];

  return categories.map((category) => Number(category)).filter((category) => !Number.isNaN(category));
};

export const routes: RouteRecordRaw[] = [
  {
    path: '/collection/:name',
    name: 'collection',
    component: () => import('./pages/collection-browser.vue'),
    props: (route) => ({
      categories: getCategoryIds(route),
      name: route.params.name,
    }),
    meta: {
      scrollContainer: '#catalog-hits-tiles-container',
    },
  },
];
