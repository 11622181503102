import { useCategories } from '@bc/discovery/feature/catalog';
import usePrismic from '@/composables/use-prismic';
import { computed, provide, inject, ref, Ref, watch } from 'vue';
import { CategoriesTileData } from './types';
import i18nInstance from '@/services/i18n';
import { memoize } from 'lodash-es';

export const CategoriesTileDataKey = Symbol();
export const HasCategoriesTileDataKey = Symbol();

export const fetchPrismicCategoryImages = memoize(async (prismicClient) => {
  let prismicData = null;
  try {
    prismicData = (await prismicClient.getByUID('category-images', 'search-bar-category-images')).data.categories;
  } catch {
    // fail silently
  }
  return prismicData?.reduce((acc, curr) => {
    acc[curr.id] = curr.image.url;
    return acc;
  }, {});
});

const useCategoriesTileData = () => {
  const providedValues = {
    categoriesTileData: inject<Ref<CategoriesTileData>>(CategoriesTileDataKey, undefined),
    hasCategoriesTileData: inject<Ref<boolean>>(HasCategoriesTileDataKey, undefined),
  };

  const hasParentProvidedValues = Object.values(providedValues).every(Boolean);
  if (hasParentProvidedValues) {
    return providedValues;
  }

  const { categories } = useCategories();
  const { client } = usePrismic();
  const categoriesTileData: Ref<CategoriesTileData> = ref();
  const hasCategoriesTileData = computed(() => Boolean(categoriesTileData.value));
  watch(
    categories,
    async (categories) => {
      if (!categories) {
        return;
      }
      let tilesData;
      const prismicData = await fetchPrismicCategoryImages(client);
      if (prismicData) {
        tilesData = Object.values(categories)
          .filter((item) => item['level'] === 0)
          .map((item) => ({
            id: item.id,
            name: item.full_name,
            href: item.path,
            imageUrl: item.image,
          }));
        tilesData.unshift({
          id: null,
          name: i18nInstance.global.t('retailer.searchbar.categories.new.lbl'),
          href: 'shop/new',
          imageUrl: prismicData['new'],
        });
      }
      categoriesTileData.value = tilesData;
    },
    { immediate: true }
  );
  provide(CategoriesTileDataKey, categoriesTileData);
  provide(HasCategoriesTileDataKey, hasCategoriesTileData);
  return { hasCategoriesTileData, categoriesTileData };
};

export default useCategoriesTileData;
