export enum ReportsClickAction {
  CLICK_REPORT = 'click_report_button',
  CLICK_VALIDATE_REPORT_REASON = 'validate_report_reason',
  CLICK_VALIDATE_ADDITIONAL_INFO = 'validate_additional_information',
}

export type ReportsTrackingProperty = {
  brand_id: number;
  retailer_id: number;
  product_id?: number;
};
