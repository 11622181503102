import http from '@/services/api/http';
import { TaxNumberType } from '@/types/tax-number';
import { StrictUnion } from '@/types';

interface Params {
  page?: unknown;
  _fields?: string;
}

const jsonApiConfigs = {
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
};

export const deleteItemFromCart = async (brandId: number, itemId: number) => {
  const result = await http().delete(`/api/brands/${brandId}/cart/items/${itemId}`, {
    noProgressBar: true,
    apiCartCall: true,
  });

  return result?.data?.data;
};

export const updateItemQuantity = async (brandId: number, itemId: number, quantity: number) => {
  const result = await http().patch(
    `/api/brands/${brandId}/cart/items/${itemId}`,
    {
      quantity,
    },
    {
      noProgressBar: true,
      apiCartCall: true,
    }
  );

  return result?.data?.data;
};

export const addItemToCart = async (brandId: number, optionId: number, variantId: number, quantity: number) => {
  const result = await http().post(
    `/api/brands/${brandId}/cart/items`,
    {
      option_id: optionId,
      variant_id: variantId,
      quantity: quantity,
    },
    { noProgressBar: true, apiCartCall: true }
  );

  return result?.data?.data;
};

export const deletePromocode = async (checkoutId: number, promocodeId: number) => {
  return http().delete(`/api/checkout/${checkoutId}/promocodes/${promocodeId}`, {
    apiCartCall: true,
  });
};

export const addPromocode = async (checkoutId: number, code: string) => {
  return http()
    .post(
      `/api/checkout/${checkoutId}/promocodes`,
      {
        code,
      },
      { apiCartCall: true }
    )
    .catch((error) => error);
};

export const getReferralCode = async () => {
  return http()
    .get('/api/me/referral_code')
    .catch((error) => error);
};

export const applyCredit = async (amount: number) => {
  return http().post('/api/wallet/credit', { creditAmount: amount }, { apiCartCall: true });
};

export const getCheckout = async (checkoutId: number) => {
  const params: Params = {
    _fields:
      'id,free_shipping,billing_address{id,name,organisation_name,city,postal_code,street,country{id,name,iso_code,opened_to_retailers,opened_to_brands}},shipping_address{id,name,organisation_name,city,postal_code,street,country{id,name,iso_code,opened_to_retailers,opened_to_brands}},retailer_country,billing_items{id,billing_item_type,amount{amount,currency},amount_with_vat{amount,currency},amount_vat{amount,currency},label,vat_label},carts{id,vat_application_details,recargo_de_equivalencia_amount{amount,currency},minimum{above,amount{amount,currency}},shipping_is_restricted_by_eori,promocodes{id,code,amount{amount,currency},status},billing_items{id,billing_item_type,amount{amount,currency},amount_vat{amount,currency},amount_with_vat{amount,currency},label,vat_label},customs_shipping_fees,total_amount{amount,currency},items{id,quantity,applicable_shipping_fees,unit_multiplier,wholesale_price,multiplied_quantity,active,variant{id},option{id,unit_multiplier},product{images,link,name,unit_multiplier,unit_price{amount,currency}},amount_with_vat{amount,currency},amount{amount,currency},unit_multiplier,unit_price{amount,currency},amount_before_discount{amount,currency}},selected,saved_for_later,minimum{above,amount{amount,currency}},customs_shipping_fees,brand{id,name,link,event_special_discount,images,opened_countries,shipping_lead_time_details{name,minimum,maximum},minimum},shipping_lead_time},promocodes{id,code,amount{amount,currency},status,message},retailer_grand_total_amount_with_vat{amount,currency},total_amount_with_vat{amount,currency},total_amount_vat{amount,currency},total_amount{amount,currency}, extra_taxes, is_first_checkout',
  };

  const result = await http().get(`/api/checkout/${checkoutId}`, {
    noProgressBar: true,
    apiCartCall: true,
    firstInit: false,
    params,
  });

  return result?.data?.data;
};

export const getCarts = async () => {
  const result = await http().get(`/api/internal/cart`, {
    noProgressBar: true,
    apiCartCall: true,
    firstInit: false,
  });

  return result?.data?.data;
};

export const getCartsDetails = async (orderIds: number[] = []) => {
  const response = await http().get(`/api/carts`, {
    noProgressBar: true,
    params: {
      ids: orderIds.join(','),
    },
  });

  return response?.data?.data;
};

export interface CheckoutWithOrdersOptions {
  onlyMainOrders?: boolean;
}

export const getCurrentCheckout = async () => {
  const result = await http().get(`/api/checkout/current`, {
    noProgressBar: true,
    apiCartCall: true,
    firstInit: false,
  });

  return {
    ...result?.data?.data,
    carts: result?.data?.data.carts.map(({ applicable_shipping_fees, ...cart }) => ({
      ...cart,
      applicableFees: applicable_shipping_fees,
    })),
  };
};

export const getFloatingCart = async () => {
  try {
    const result = await http().get('/api/checkout/floating-cart', {
      apiCartCall: true,
    });

    return result?.data?.data;
  } catch (e) {
    return null;
  }
};

export const saveCartForLater = async (brandId: number, value: boolean) =>
  http().patch(
    `/api/brands/${brandId}/cart`,
    {
      saved_for_later: value,
    },
    { apiCartCall: true }
  );

export const removeRemoteBrand = async (brandId: number) => {
  http().delete(`/api/cart/order/${brandId}`);
};

export const sendTaxNumber = async (taxNumber: TaxNumberType, value: string) => {
  const result = await http().patch(taxNumber === TaxNumberType.VatNumber ? '/api/me/vat' : '/api/me/tax-number', {
    vat_number: taxNumber === TaxNumberType.VatNumber ? value : undefined,
    tax_number: taxNumber === TaxNumberType.TaxNumber ? value?.replace(/\s/g, '').toUpperCase() : undefined,
  });

  return result?.data?.data;
};

export type ProductUpdate = {
  option_id: number;
  quantity: number;
  product_variant_id?: number | null;
};

type WithItemId = {
  item_id: number;
  quantity: number;
  brand_id: number;
  product_variant_id?: number | null;
};

type WithOptionId = {
  option_id: number;
  quantity: number;
  brand_id: number;
  product_variant_id?: number | null;
};

// option_id and item_id are mutually exclusive
export type NewProductUpdate = StrictUnion<WithItemId | WithOptionId>;

export type CartItemUpdate = {
  order_item_id?: number;
  quantity: number;
};

export type NewBulkUpdate = StrictUnion<NewProductUpdate | CartItemUpdate>;
export type BulkUpdate = StrictUnion<ProductUpdate | CartItemUpdate>;

export const updateItemsBulk = async (updates: BulkUpdate[]) => {
  const result = await http().patch('/api/cart/items', {
    updates,
  });

  return result?.data?.data;
};

export const sendRecargoEnabled = async (recargoEnabled: boolean) => {
  const result = await http().patch('/api/me/recargo', {
    is_paying_recargo: recargoEnabled,
  });
  return result?.data.data;
};

export const getSavedForLater = async () => {
  const result = await http().get(`/api/cart/saved-for-later`);

  return result?.data?.data?.carts;
};

export const getOrderSummary = async () => {
  const result = await http().get(`/api/cart/summary`);
  return result?.data?.data;
};

export const getCartItemsCount = async (retailerUuid: string) => {
  const result = await http().get(`/api/internal/v1/cart/item-counter/${retailerUuid}`, jsonApiConfigs);
  return result?.data?.data?.attributes;
};
