<template>
  <div class="report-price">
    <AkButton
      size="lg"
      link
      data-testid="buttonPriceReport"
      @click="openPriceReport"
    >
      {{ $t('bestpriceguarantee.button') }}
    </AkButton>
    <AkModal
      ref="bestPriceGuaranteeModal"
      size="xl"
      @cancelled="resetForm()"
    >
      <div class="report-price__form">
        <AkAccountHeading2
          v-if="!issueCreated"
          class="report-price__title"
        >
          {{ $t('bestpriceguarantee.modal.title') }}
        </AkAccountHeading2>
        <AkSelect
          v-if="!issueCreated"
          v-model="option"
          class="report-price__select"
          :label="$t('bestpriceguarantee.option.label')"
          :placeholder="$t('global.form.select.placeholder')"
          :options="[
            { label: $t('bestpriceguarantee.option.publicpricereport'), value: 'public_price_report', disabled: false },
            { label: $t('bestpriceguarantee.option.privatepricereport'), value: 'private_price_report', disabled: false },
          ]"
        />
        <PublicPriceForm
          v-if="option === 'public_price_report'"
          :product-variant-id="productVariantId"
          @submit-initial-form="issueCreated = true"
        />
        <NegociatedPriceForm
          v-if="option === 'private_price_report'"
          :brand-id="brandId"
          :brand-name="brandName"
          :brand-image="brandImage"
          :product-variant-id="productVariantId"
          @submit-initial-message="issueCreated = true"
        />
      </div>
    </AkModal>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import NegociatedPriceForm from '@/modules/shared/best-price-guarantee/components/form/negociated-price-form.vue';
import PublicPriceForm from '@/modules/shared/best-price-guarantee/components/form/public-price-form.vue';
import { AkButton, AkModal, AkAccountHeading2, AkSelect } from '@ankorstore/design-system';

import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';

export default defineComponent({
  name: 'ReportPrice',
  components: { AkButton, AkModal, NegociatedPriceForm, PublicPriceForm, AkAccountHeading2, AkSelect },
  props: {
    brandId: {
      type: Number,
      required: true,
    },
    brandName: {
      type: String,
      required: true,
    },
    brandImage: {
      type: String,
      required: true,
    },
    productVariantId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      option: null,
      issueCreated: false,
    };
  },
  watch: {
    option() {
      this.trackFormSelection();
    },
  },
  methods: {
    trackFormSelection(): void {
      if (this.option !== null) {
        Analytics.track(
          new UserClick({
            component: 'report_bpg_price',
            action: this.option,
          })
        );
      }
    },
    openPriceReport(): void {
      Analytics.track(
        new UserClick({
          component: 'report_bpg_price',
          action: 'open_form',
        })
      );
      (this.$refs.bestPriceGuaranteeModal as DS['AkModal']).openModal();
    },
    resetForm(): void {
      this.option = null;
      this.issueCreated = false;
    },
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';
.report-price {
  &__form {
    @apply ds-text-left;
  }
  &__select {
    @apply ds-mb-4;
  }
  &__title {
    @apply ds-text-center;
  }
}
</style>
<style lang="scss">
.report-price {
  .ak-field-label {
    @apply ds-font-normal;
  }
}
</style>
