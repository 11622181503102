import { BrandRetailerRelation, JsonApiResponse, UpdateBrandRetailerRelationPayload } from '@/types/fulfillment';
import http from '@/services/api/http';
import { deserialize } from '@/services/utils/jsonapi-parser';

export const getBrandRetailerRelation = async (retailerId: string): Promise<JsonApiResponse<BrandRetailerRelation>> => {
  const response = await http().get(`/api/internal/v1/brands/retailers/${retailerId}`);
  return deserialize(response.data);
};

export const updateBrandRetailerRelation = async (retailerId: string, payload: UpdateBrandRetailerRelationPayload) => {
  return http().patch(`/api/internal/v1/brands/retailers/${retailerId}`, payload);
};
