import { set } from 'lodash-es';
import { Ref } from 'vue';

export interface IFormSubmitFunction {
  (IFormValues): Promise<void> | null;
}

export type TFormValue = string | number | boolean;

export interface IFormValuesObject {
  [fieldName: string]: TFormValue | IFormValuesObject;
}

export interface IFormErrors {
  [fieldName: string]: string | null;
}

export interface IFormUpdateValueEventPayload {
  name: string;
  value: TFormValue;
  groups: string[];
}

export interface IFormUpdateErrorEventPayload {
  name: string;
  error: TFieldValidateValue;
}

export type TFieldValidateValue = string | undefined;

export type TFieldValidateFunction = (value: TFormValue) => TFieldValidateValue | Promise<TFieldValidateValue>;

export class FormValuesObject {
  static initializeValue(valuesObject: Ref<IFormValuesObject>, name: string, value: TFormValue, groups?: string[]) {
    const valuesObjectClone = { ...valuesObject.value };
    let values = valuesObjectClone;
    for (const group of groups) {
      if (!values[group]) {
        set(values, group, {});
      }
      values = values[group] as IFormValuesObject;
    }
    if (!values[name]) {
      set(values, name, value);
    }
    valuesObject.value = valuesObjectClone;
  }
  static getValue(valuesObject: IFormValuesObject, name: string, groups?: string[]): TFormValue {
    let values = valuesObject;
    for (const group of groups) {
      if (!values[group]) {
        break;
      } else {
        values = values[group] as IFormValuesObject;
      }
    }
    return values[name] as TFormValue;
  }
  static setValue(valuesObject: Ref<IFormValuesObject>, name: string, value: TFormValue, groups?: string[]) {
    const valuesObjectClone = { ...valuesObject.value };
    let values = valuesObjectClone;
    for (const group of groups) {
      values = values[group] as IFormValuesObject;
    }
    values[name] = value;
    valuesObject.value = valuesObjectClone;
  }
}
