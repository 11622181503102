<template><div class="ds-hidden"></div></template>

<script lang="ts">
import { defineComponent } from 'vue';

const DELAY_TO_HIDE_OVERLAY = 200;
let timeoutIconOutId;
let timeoutOverlayOutId;

export default defineComponent({
  name: 'UserAccountOverlayController',
  data: () => ({
    userAccountOverlay: null,
    userAccountIcon: null,
    isCursorOverIcon: false,
    isCursorOverOverlay: false,
  }),
  watch: {
    isCursorOverIcon() {
      this.hideOverlay();
    },
    isCursorOverOverlay() {
      this.hideOverlay();
    },
  },
  mounted() {
    this.userAccountOverlay = document.querySelector('#header .my-account-dropdown');
    this.userAccountIcon = document.querySelector('#header .auth .nav-item.dropdown .my-account-button');
    this.userAccountOverlay?.addEventListener('mouseenter', this.handleMouseEnterOverlay);
    this.userAccountOverlay?.addEventListener('mouseleave', this.handleMouseLeaveOverlay);
    this.userAccountIcon?.addEventListener('mouseenter', this.handleMouseEnterIcon);
    this.userAccountIcon?.addEventListener('mouseleave', this.handleMouseLeaveIcon);
  },
  beforeUnmount() {
    clearTimeout(timeoutIconOutId);
    clearTimeout(timeoutOverlayOutId);
    this.userAccountOverlay?.removeEventListener('mouseenter', this.handleMouseEnterOverlay);
    this.userAccountOverlay?.removeEventListener('mouseleave', this.handleMouseLeaveOverlay);
    this.userAccountIcon?.removeEventListener('mouseenter', this.handleMouseEnterIcon);
    this.userAccountIcon?.removeEventListener('mouseleave', this.handleMouseLeaveIcon);
  },
  methods: {
    showOverlay() {
      if (this.userAccountOverlay) {
        (this.userAccountOverlay as HTMLElement).style.display = 'block';
      }
    },
    hideOverlay() {
      if (!this.isCursorOverIcon && !this.isCursorOverOverlay && this.userAccountOverlay) {
        (this.userAccountOverlay as HTMLElement).style.display = 'none';
      }
    },
    handleMouseEnterIcon() {
      clearTimeout(timeoutIconOutId);
      this.isCursorOverIcon = true;
      this.showOverlay();
    },
    handleMouseLeaveIcon() {
      timeoutIconOutId = setTimeout(() => {
        this.isCursorOverIcon = false;
      }, DELAY_TO_HIDE_OVERLAY);
    },
    handleMouseEnterOverlay() {
      clearTimeout(timeoutOverlayOutId);
      this.isCursorOverOverlay = true;
      this.showOverlay();
    },
    handleMouseLeaveOverlay() {
      timeoutOverlayOutId = setTimeout(() => {
        this.isCursorOverOverlay = false;
      }, DELAY_TO_HIDE_OVERLAY);
    },
  },
});
</script>
