import GlobalSelector from '@/composables/use-global-selectors/GlobalSelector';

type Page = 'global' | 'orderDetails' | 'replenishmentDetails';

const STATIC_ELEMENTS = {
  header: document.querySelector('#header'),
  headerMobile: document.querySelector('#headerMobile'),
};

export const globalSelectors = {
  global: {
    loginRegisterForm: () => new GlobalSelector('login-register-form', Object.values(STATIC_ELEMENTS)),
  },
  orderDetails: {
    cartMonoRetail: () => new GlobalSelector('cart-mono-retail', Object.values(STATIC_ELEMENTS)),
  },
  replenishmentDetails: {
    productTable: () => new GlobalSelector('replenishment-detail-product-table', Object.values(STATIC_ELEMENTS)),
  },
};

/**
 * This composable exposes refs and methods that can be used to interact
 * with shared global elements across the DOM. Each selector exposes their
 * global ID, which should be used in the markup to register the component,
 * and a few helpers to allow other components to interact with it. Note:
 * it is only possible to access the selectors after the component has mounted.
 * Calling any function from the setup method will result in a null reference error.
 */
export default function useGlobalSelectors(): { [K in Page]: { [K in string]: () => GlobalSelector } } {
  return globalSelectors;
}
