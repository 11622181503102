import { provide, inject, onUnmounted } from 'vue';
import { EventEmitter } from '@bc/shared';

type UseEventBusArgs = {
  scoped?: boolean;
  eventBusKey?: symbol;
};

/**
 * provide/inject an EventEmitter instance.
 *
 * @param eventBusKey - A Symbol identifier for this event bus.
 *   If not supplied, a default event bus will be provided/injected,
 *   which is shared across all components in the application.
 *   If supplied, an isolated event bus will be provided/injected.
 * @param scoped - A new instance of the event bus will be provided/injected
 *  for each component that requests it. Events wil bubble up to parent components
 *  if they are using the same event bus key.
 *  Set to false to use the parent event bus directly.
 *
 *  **BEWARE**
 *  Not using a scoped event bus can lead to unexpected behavior, particularly
 *  when `eventBus.map` and `eventBus.tryEmit` are used.
 *  Sibling components may share the same event bus instance and `map` each other's events.
 *  When using `tryEmit`, if a sibling component has registered a listener for the same
 *  event, the fallback will not be triggered.
 *
 * @returns eventBus
 */
const useEventBus = ({ eventBusKey = DefaultEventBusKey, scoped = true }: UseEventBusArgs = {}) => {
  const injectedValues = {
    eventBus: inject<EventEmitter>(eventBusKey, undefined),
  };
  if (!injectedValues.eventBus || scoped) {
    const eventBus = new EventEmitter(eventBusKey, injectedValues.eventBus);
    onUnmounted(() => eventBus.destroy());
    provide(eventBusKey, eventBus);

    return { eventBus };
  }
  return injectedValues;
};

export const DefaultEventBusKey = Symbol();
export default useEventBus;
