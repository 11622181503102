export interface OnboardingAttributes {
  completedAt: Date;
  createdAt: Date;
  status: OnboardingStatus;
  steps: BrandOnboardingStep[];
}

export enum OnboardingStatus {
  InProgress = 'in_progress',
  Completed = 'completed',
}

export interface BrandOnboardingStep {
  name: BrandOnboardingStepName;
  progress: number;
  isComplete: boolean;
}

export enum BrandOnboardingStepName {
  Company = 'company',
  Shipping = 'shippingSettings',
  Storefront = 'storefront',
  Catalog = 'catalog',
}
