export interface Touchpoints {
  top_bar?: TouchpointsContent;
  header_pill?: TouchpointsContent;
}

export interface TouchpointsContent {
  type: string;
  prismic_id: string;
}

export enum TouchpointsSlot {
  TopBar = 'top_bar',
  HeaderPill = 'header_pill',
}
