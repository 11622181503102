import { unref } from 'vue';
import { Router as VueRouter, RouteLocationNormalized } from 'vue-router';
import { isEmpty, omitBy } from 'lodash-es';
import { CreateHistoryRouterParams, Router } from './types';
import type { Filter, Location } from '../../types/filters';
import type { SearchRouteState } from '../../types/catalog';
import { URLManager } from '../url-manager/types';
import { SearchCategory } from '@/types/api/category';

type Range = { min: number; max: number };

export abstract class HistoryRouterBase implements Router<SearchRouteState> {
  protected readonly router: VueRouter;
  protected readonly getBasePath?: () => string;
  protected readonly getLang: () => string;
  protected readonly getCategories: () => Record<string, SearchCategory>;
  protected readonly getLocations: () => Location[];
  protected readonly getCurrency: () => string;
  protected readonly urlManager: () => URLManager;

  private removeAfterEach: () => void;
  private isDisposed = false;

  constructor(params: CreateHistoryRouterParams) {
    this.router = params.router;
    this.getBasePath = params.getBasePath;
    this.getLang = params.getLang;
    this.getCategories = params.getCategories;
    this.getLocations = params.getLocations;
    this.getCurrency = params.getCurrency;
    this.urlManager = params.urlManager;
  }

  public abstract read(): SearchRouteState;

  public createURL(routeState: SearchRouteState): string {
    const { path, hash } = this.currentRoute;
    const basePath = this.getBasePath?.() ?? path;
    const hashParams = this.getHashParams(this.router.currentRoute.value);
    return this.urlManager().createURL(
      {
        textQuery: routeState.q,
        selectedCategories: this.getSelectedCategories(routeState, this.getCategories()),
        selectedDiscounts: this.getSelectedFilters(routeState, 'discount'),
        selectedLocations: this.getSelectedLocations(routeState, this.getLocations()),
        selectedMadeIns: this.getSelectedFilters(routeState, 'made_in'),
        selectedMargins: this.getSelectedFilters(routeState, 'margin'),
        selectedValues: this.getSelectedFilters(routeState, 'tags'),
        selectedShipping: this.getSelectedFilters(routeState, 'shipping_lead_time'),
        currentPageIndex: routeState.page ? routeState.page - 1 : 0,
        retailPriceRange: this.getSelectedRetailPriceRange(routeState),
        selectedSorting: undefined,
        selectedView: hash?.includes('view=1') ? 1 : 0,
        selectedFilterBrands: this.getSelectedFilters(routeState, 'brand.name'),
        selectedLoyaltyOffer: this.getSelectedFilters(routeState, 'aks_plus'),
        attributes: this.getAttributeFilters(routeState),
        sortBy: hashParams.get('sortBy'),
      },
      basePath
    );
  }

  public write(routeState: SearchRouteState) {
    if (this.isDisposed) {
      return;
    }

    const url = this.createURL(routeState);
    if (this.currentRoute.fullPath !== url) {
      this.router.push(url);
    }
  }

  public onUpdate(cb: (route: SearchRouteState) => void) {
    if (!this.envHasWindow() || this.isDisposed) {
      return;
    }

    this.removeAfterEach = this.router.afterEach(() => {
      cb(this.read());
    });
  }

  public dispose() {
    this.removeAfterEach?.();
    this.isDisposed = true;
  }

  public envHasWindow() {
    return typeof window !== 'undefined';
  }

  protected removeEmptyValues(obj: Record<string, unknown>): Record<string, unknown> {
    return omitBy(obj, isEmpty);
  }

  protected getHashParams(route: RouteLocationNormalized) {
    return new URLSearchParams(route.hash?.substr(1) ?? '');
  }

  protected get currentRoute(): RouteLocationNormalized {
    return unref(this.router.currentRoute);
  }

  protected getAttributeRefinements(hashParams: URLSearchParams) {
    return this.getAttributeValues(
      hashParams,
      (key) => key.startsWith('attributes') && !key.endsWith('number') && !key.endsWith('boolean'),
      (value) => value.split(',')
    );
  }

  protected getAttributeRanges(hashParams: URLSearchParams) {
    return this.getAttributeValues(hashParams, (key) => key.startsWith('attributes') && key.endsWith('number'));
  }

  protected getAttributeToggles(hashParams: URLSearchParams) {
    return this.getAttributeValues(hashParams, (key) => key.startsWith('attributes') && key.endsWith('boolean'));
  }

  private getAttributeValues(
    hashParams: URLSearchParams,
    keyCondition: (key: string) => boolean,
    valueIterator: (value: string) => string | string[] = (value) => value
  ): Record<string, string | string[]> {
    return Object.fromEntries(
      Array.from(hashParams.entries())
        .filter(([key]) => keyCondition(key))
        .map(([key, value]) => [key, valueIterator ? valueIterator(value) : value.split(',')])
    );
  }

  private getValuesOfKey<T = string[]>(obj: Record<string, T>, keyName: string, defaultValue: T): T {
    return (
      Object.entries(obj ?? {})
        .filter(([key]) => key.includes(keyName))
        .map(([, value]) => value)
        .at(0) ?? defaultValue
    );
  }

  private getSelectedCategories(routeState: SearchRouteState, categories: Record<string, SearchCategory> = {}): SearchCategory[] {
    const categoryIds: string[] = this.getValuesOfKey(routeState.hierarchicalMenu, 'categories', []);

    return categoryIds.map((id) => categories[id]).filter(Boolean);
  }

  private getSelectedFilters(routeState: SearchRouteState, filterName: keyof SearchRouteState['refinementList']): Filter[] {
    const filterValues: string[] = this.getValuesOfKey(routeState.refinementList, filterName.toString(), []);

    return filterValues.map((id) => ({ id, label: id }));
  }

  private getSelectedLocations(routeState: SearchRouteState, locations: Location[] = []): Location[] {
    const locationIds: string[] = this.getValuesOfKey(routeState.refinementList, 'location', []);

    return locationIds.map((id) => locations.find((location) => location.id === id)).filter(Boolean);
  }

  private getSelectedRetailPriceRange(routeState: SearchRouteState): Range {
    const [min, max] = this.getValuesOfKey(routeState.range, 'retail_prices', '').split(':');
    const minValue = min ? parseInt(min, 10) : undefined;
    const maxValue = max ? parseInt(max, 10) : undefined;

    return { min: minValue, max: maxValue };
  }

  private getAttributeFilters(routeState: SearchRouteState): Filter[] {
    const attributes = [];

    if (routeState.refinementList) {
      attributes.push(...Object.entries(routeState.refinementList).filter(([key]) => key.includes('attributes')));
    }

    if (routeState.range) {
      attributes.push(...Object.entries(routeState.range).filter(([key]) => key.includes('attributes')));
    }

    if (routeState.toggle) {
      attributes.push(...Object.entries(routeState.toggle).filter(([key]) => key.includes('attributes')));
    }

    return attributes.map(([key, values]) => {
      const value = Array.isArray(values) ? values.join(',') : values;

      return { id: key, label: value };
    });
  }
}
