<template>
  <div>
    <TrackParcels v-if="showComponent('TrackParcels')" />
    <UploadPackaging
      v-show="showComponent('UploadPackaging')"
      @set-loader="setLoader"
    />
    <ParcelReception
      v-show="showComponent('ParcelReception')"
      @set-checked="setParcelReceptionReady"
      @set-loader="setLoader"
    />
    <ProductsCheckList v-show="showComponent('ProductsCheckList')" />
    <ProductsList
      v-if="getSelectedProducts.length"
      v-show="showComponent('ProductsList')"
      ref="productsListRef"
      :is-active="showComponent('ProductsList')"
      @set-loader="setLoader"
      @products-validation="productsDataChanged"
      @all-products-removed="backToCheckList"
    />
    <CaseActions
      :next-step-ready="nextStepReady()"
      :show-back-button="showBackButton"
      @set-loader="setLoader"
      @submit="$emit('submit')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import BaseItemsCase from '@/components/account/orders/order/order-retailer-reject/cases/base-items-case.vue';
import TrackParcels from '@/components/account/orders/order/order-retailer-reject/components/track-parcels.vue';
import UploadPackaging from '@/components/account/orders/order/order-retailer-reject/components/upload-packaging.vue';
import ParcelReception from '@/components/account/orders/order/order-retailer-reject/components/parcel-reception.vue';
import ProductsCheckList from '@/components/account/orders/order/order-retailer-reject/components/products-check-list.vue';
import ProductsList from '@/components/account/orders/order/order-retailer-reject/components/products-list.vue';
import CaseActions from '@/components/account/orders/order/order-retailer-reject/components/case-actions.vue';
import { CaseReasonStep } from '@/types/order-retailer-rejection';

export default defineComponent({
  name: 'MissingItems',
  components: {
    TrackParcels,
    UploadPackaging,
    ParcelReception,
    ProductsList,
    ProductsCheckList,
    CaseActions,
  },
  extends: BaseItemsCase,
  emits: ['submit'],
  data() {
    return {
      parcelReceptionReady: false,
    };
  },
  computed: {
    ...mapGetters('orderRetailerRejection', ['getParcels', 'showRevampedVersion']),
    showBackButton() {
      if (this.getCurrentStep.key === CaseReasonStep.OrderInfo && this.getParcels.length > 1) {
        return true;
      }

      return !(this.getCurrentStep.key === CaseReasonStep.TrackWithLink || this.getCurrentStep.key === CaseReasonStep.OrderInfo);
    },
  },
  methods: {
    showComponent(component: string): boolean {
      const currentKey = this.getCurrentStep.key;

      switch (component) {
        case 'TrackParcels':
          return currentKey === CaseReasonStep.TrackWithLink;
        case 'UploadPackaging':
          return !this.showRevampedVersion && (currentKey === CaseReasonStep.OrderInfo || currentKey === CaseReasonStep.Summary);
        case 'ParcelReception':
          return (
            this.showRevampedVersion && (currentKey === CaseReasonStep.ParcelReception || currentKey === CaseReasonStep.Summary)
          );
        case 'ProductsCheckList':
          return currentKey === CaseReasonStep.ProductsCheckList;
        case 'ProductsList':
          return currentKey === CaseReasonStep.ProductsList || currentKey === CaseReasonStep.Summary;
        default:
          return false;
      }
    },
    nextStepReady(): boolean {
      switch (this.getCurrentStep.key) {
        case CaseReasonStep.TrackWithLink:
          return true;
        case CaseReasonStep.OrderInfo:
          return !this.hasFormErrors;
        case CaseReasonStep.ParcelReception:
          return this.parcelReceptionReady;
        case CaseReasonStep.ProductsCheckList:
          return this.getSelectedProducts.length > 0;
        case CaseReasonStep.ProductsList:
          return this.productsDataReady;
        case CaseReasonStep.Summary:
          return this.productsDataReady && !this.hasFormErrors && !this.loading;
        default:
          return false;
      }
    },
    setParcelReceptionReady(value: boolean): void {
      this.parcelReceptionReady = value;
    },
  },
});
</script>
