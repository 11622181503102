<template>
  <div
    v-if="isVisible"
    :class="{
      'search-autocomplete-static-item': true,
      'search-autocomplete-static-item--purple': isR2BLink,
      'search-autocomplete-static-item--active': isActive,
    }"
    :data-testid="testId"
    @click="handleItemClick"
  >
    <AkIcon
      v-if="iconSymbol"
      size="md"
      :symbol="iconSymbol"
      class="search-autocomplete-static-item__icon"
    />
    <span
      class="search-autocomplete-static-item__label"
      data-testid="searchShowAllResults"
    >
      {{ formattedLabel }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { DEFAULT_ARROW_POSITION, R2B_LINK_ID, SHOW_ALL_ID } from '../store';
import { StaticItem } from '@bc/discovery/domain/search';
import { AkIcon } from '@ankorstore/design-system';
import SearchBarStoreHelpers from '../store/helpers';
import OffersStoreHelpers from '@/store/offers/helpers';
import { formatPrice } from '@/utilities/price';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SearchStaticItem',
  components: {
    AkIcon,
  },
  props: {
    item: {
      type: Object as PropType<StaticItem>,
      default: () => ({ name: '', id: '' }),
    },
    currentSelectionId: {
      type: [Number, String],
      default: DEFAULT_ARROW_POSITION,
    },
    isVisible: { type: Boolean },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { originalUserInput } = SearchBarStoreHelpers.useGetters(['originalUserInput']);
    const { r2bWalletCreditOffer } = OffersStoreHelpers.useGetters(['r2bWalletCreditOffer']);
    const isR2BLink = computed(() => props.item?.id === R2B_LINK_ID);
    const iconSymbol = computed(() => {
      if (props.item?.id === SHOW_ALL_ID) {
        return 'search';
      } else if (isR2BLink.value) {
        return 'megaphone';
      } else {
        return '';
      }
    });
    const testId = computed(() => (props.item?.id === SHOW_ALL_ID ? 'SearchBoxViewAllResults' : ''));
    const formattedLabel = computed(() => {
      let name = props.item?.name;
      if (isR2BLink.value) {
        name = t('Refer brands and get {REFERRAL_GIFT_AMOUNT}', {
          REFERRAL_GIFT_AMOUNT: formatPrice(r2bWalletCreditOffer.value?.amount, '$0'),
        });
      } else if (props.item?.id === SHOW_ALL_ID) {
        name = originalUserInput.value
          ? t('Show all results for : {search}', {
              search: originalUserInput.value,
            })
          : t('Show all results for this search');
      }
      return name;
    });
    const handleItemClick = () => {
      emit('click', props.item);
    };
    const isActive = computed(() => props.item?.id === props.currentSelectionId);
    return { handleItemClick, iconSymbol, testId, isActive, formattedLabel, isR2BLink };
  },
});
</script>

<style scoped lang="scss">
.search-autocomplete-static-item {
  @apply ds-w-full ds-flex ds-items-center ds-py-3 ds-px-4 ds-relative ds-text-left ds-cursor-pointer;
  transition: background-color ease-in-out 0.2s;

  &:last-child {
    @apply md:ds-rounded-b-md;
  }

  &__icon {
    @apply ds-pr-2;
  }

  &__label {
    flex: 1 2 auto;
  }

  &--active,
  &:hover {
    @apply ds-bg-neutral-300;
  }

  &--purple {
    @apply ds-text-discount ds-font-bold ds-text-xs ds-uppercase;

    &__icon {
      @apply ds-text-discount;
    }
  }
}
</style>
