<template>
  <div class="products-selection">
    <div class="products-selection__headings">
      <AkParagraph class="products-selection__title">
        {{ content.productsCheckListTitle }}
      </AkParagraph>
      <AkTooltipInformation
        trigger="hover"
        type="success"
        class="ds-text-neutral-900"
        :content="content.productsCheckListTooltip"
      >
        <AkIcon
          symbol="info-circle"
          size="md"
          class="ds-ml-1"
        />
      </AkTooltipInformation>
    </div>
    <div
      v-for="(product, index) in getProducts"
      :key="index"
      class="product"
    >
      <AkCheckbox
        :value="product.name"
        :checked="product.selected"
        class="product-checkbox ds-mt-1"
        data-testid="order-rejection-product-checkbox"
        @change="productSelected($event, product)"
      />
      <div>
        <AkParagraph
          class="product-name"
          :class="product.selected ? 'product-name__checked' : ''"
        >
          {{ product.name }}
        </AkParagraph>
        <AkParagraph class="ds-text-neutral-600">
          {{ getProductIdentifier(product) }}
        </AkParagraph>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { OrderProduct, CaseReason } from '@/types/order-retailer-rejection';

interface TooltipContent {
  productsCheckListTitle: string;
  productsCheckListTooltip: string;
}

export default defineComponent({
  name: 'ProductsCheckList',
  emits: ['products-checked'],
  computed: {
    ...mapGetters('orderRetailerRejection', ['getProducts', 'getSelectedProducts', 'getCaseReason', 'showNewOrderIssueFlow']),
    content() {
      let data = {} as TooltipContent;

      switch (this.getCaseReason) {
        case CaseReason.DamagedItems:
          data = {
            productsCheckListTitle: this.showNewOrderIssueFlow
              ? this.$t('Which items were damaged?')
              : this.$t('Please select the damaged items'),
            productsCheckListTooltip: this.$t('Select all items from your order that you consider damaged'),
          };
          break;
        case CaseReason.WrongItems:
          data = {
            productsCheckListTitle: this.showNewOrderIssueFlow
              ? this.$t('Which items were wrong?')
              : this.$t('Please select the wrong items'),
            productsCheckListTooltip: this.$t('Select all items from your order that you consider wrong'),
          };
          break;
        case CaseReason.MissingItems:
          data = {
            productsCheckListTitle: this.showNewOrderIssueFlow
              ? this.$t('Which items were missing?')
              : this.$t('Please select the missing items'),
            productsCheckListTooltip: this.$t('Select all items from your order that you consider missing'),
          };
          break;
        case CaseReason.DefectiveItems:
          data = {
            productsCheckListTitle: this.showNewOrderIssueFlow
              ? this.$t('Which items were defective?')
              : this.$t('Please select the defective items'),
            productsCheckListTooltip: this.$t('Select all items from your order that you consider defective'),
          };
          break;
      }

      return data;
    },
  },
  mounted() {
    if (this.getSelectedProducts.length > 0) {
      this.$emit('products-checked', true);
    }
  },
  methods: {
    ...mapActions('orderRetailerRejection', ['setProductProperty', 'clearProductData']),
    getProductIdentifier(product: OrderProduct): string {
      return `SKU ${product.sku}`;
    },
    productSelected(selected: boolean, product: OrderProduct): void {
      if (selected) {
        this.setProductProperty({
          product,
          property: 'selected',
          value: selected,
        });
      } else {
        this.clearProductData({ product });
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.products-selection {
  @apply ds-font-basic ds-mt-5;

  &__title {
    @apply ds-font-semibold ds-text-lg ds-text-primary ds-leading-none ds-mb-0;
  }

  &__headings {
    @apply ds-flex ds-items-center ds-mb-5;
  }
}

.product {
  @apply ds-flex ds-items-start ds-text-base;
}

:deep(.product-checkbox) {
  max-width: 25px;
}

.product-name {
  @apply ds-text-neutral-700 ds-overflow-hidden ds-mb-0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &__checked {
    @apply ds-font-semibold ds-text-neutral-900;
  }
}
</style>
