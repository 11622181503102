<template>
  <div
    v-if="product"
    class="popin-container"
  >
    <div class="product-block">
      <product-snippet
        class="product-snippet"
        :item="product"
        :with-quick-add-to-cart="false"
        :with-link-to-fp="true"
        :variant="selectedVariant"
        :with-new-badge="false"
      />
    </div>
    <AddToCart
      v-if="product && product.refreshed"
      class="add-to-cart"
      :product="product"
      :price-breakdown="priceBreakdown"
      :is-variants-atc="true"
      @add-to-cart="handleAddToCart"
      @update-item-cart="handleUpdateItemCart"
      @select-variant="selectedVariant = $event"
    />
    <LoaderIcon v-if="product && !product.refreshed" />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import { defineComponent } from 'vue';
import AddToCart from '@/components/cart/add-to-cart.vue';
import LoaderIcon from '@/components/loader-icon.vue';
import type Product from '@/types/product';
import type { PriceBreakdown, Variant } from '@/types/product';

type AddToCartPopinData = {
  selectedVariant: Variant | null;
};

export default defineComponent({
  name: 'AddToCartPopin',
  components: {
    AddToCart,
    LoaderIcon,
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
    priceBreakdown: {
      type: Object as () => PriceBreakdown,
      required: true,
    },
  },
  emits: ['addToCartVariant', 'updateItemCart'],
  data(): AddToCartPopinData {
    return {
      selectedVariant: null,
    };
  },
  computed: {
    ...mapGetters(['productById']),
    product(): Product {
      return this.productById(this.productId);
    },
  },
  mounted(): void {
    if (!this.product || !this.product.refreshed) {
      this.$store.dispatch('fetchProduct', {
        product_id: this.productId,
      });
    }
  },
  methods: {
    handleAddToCart(payload) {
      this.$emit('addToCartVariant', payload);
    },
    handleUpdateItemCart(payload) {
      this.$emit('updateItemCart', payload);
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.product-block {
  margin-bottom: 17px;
}
.popin-container {
  padding: 50px 35px;
  .product-snippet {
    width: 66%;
    padding: 0;
    margin: 0 auto;
  }
}
:deep(.wholesale-price) {
  @apply ds-w-full ds-flex ds-justify-center;
}
</style>
