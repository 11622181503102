import List, { RetailerProductLikeCount, WhishListProducts } from '@/types/wishList';
import http from './http';

export const updateWishList = (list: List): Promise<List> =>
  http()
    .patch(`/api/wishlists/${list.id}`, list)
    .then((response) => response.data);

export const updatePosition = ({ id, position }: List): Promise<List> =>
  http()
    .put(`/api/wishlists/${id}/position/${position}`)
    .then((response) => response.data);

export const markTooltipAsSeen = (): Promise<any> =>
  http()
    .put(`/api/wishlists/new-products-counter/mark-as-seen`)
    .then((response) => response.data?.data);

export const getRetailerProductLikeCount = (): Promise<RetailerProductLikeCount> =>
  http()
    .get(`/api/wishlists/new-products-counter`, {
      noProgressBar: true,
    })
    .then((response) => response.data?.data);

export const resetRetailerProductLikeCount = (): Promise<RetailerProductLikeCount> =>
  http()
    .put(`/api/wishlists/new-products-counter/reset`)
    .then((response) => response.data?.data);

export const UpdateAList = (
  listId: number,
  { page = 1, perPage = 12, sort = null } = {}
): Promise<{ products: WhishListProducts; meta }> =>
  http()
    .get(`/api/wishlists/${listId}/products`, {
      params: {
        sort,
        page,
        perPage,
      },
    })
    .then((response) => {
      const { data, meta } = response.data;

      return {
        products: data,
        meta,
      };
    });

export const fetchList = (): Promise<List[]> =>
  http()
    .get('/api/wishlists')
    .then((response) => response.data?.data);
