import { captureException } from '@/sentry';
import { Mutation } from '@/store/mutation-types';
import { patchFastPayment } from '@/services/api/payment-configuration';

export default {
  namespaced: true,
  actions: {
    async changeFastPayment({ commit }, isFastPaymentActive: boolean) {
      try {
        await patchFastPayment(isFastPaymentActive);
        commit(Mutation.UPDATE_FAST_PAYMENT, isFastPaymentActive, {
          root: true,
        });
      } catch (e) {
        captureException(e);
      }
    },
  },
};
