import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import type { PreorderProps } from './types';

const categorySlugPattern = '[A-Za-z-]+\\d+';

const toNumberWithDefault = (str: string, defaultValue = 0) => {
  const num = Number(str);
  return isNaN(num) ? defaultValue : num;
};

const getCurrentView = (to: RouteLocationNormalized): PreorderProps['view'] => {
  const searchParams = new URLSearchParams(to.hash?.substring(1));
  return toNumberWithDefault(searchParams.get('view')) === 1 ? 'brands' : 'products';
};

const getCategoryIds = (to: RouteLocationNormalized) => {
  if (!Array.isArray(to.params.categories)) {
    return [];
  }

  return to.params.categories
    .map((category) => {
      const categoryIdMatch = /\d+$/.exec(category);

      if (!categoryIdMatch?.length) {
        return undefined;
      }

      return Number(categoryIdMatch[0]);
    })
    .filter(Boolean);
};

export const routes: RouteRecordRaw[] = [
  {
    path: `/preorders/:categories(${categorySlugPattern})*`,
    name: 'preorders',
    component: () => import('./preorders-catalog.vue'),
    props: (to) => {
      return {
        categories: getCategoryIds(to),
        view: getCurrentView(to),
        basePath: '/preorders',
      }
    },
    meta: {
      scrollContainer: '#catalog-hits-tiles-container',
    },
  },
];
