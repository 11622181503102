import { Ref, onBeforeUnmount } from 'vue';
import { RouterScrollBehavior } from 'vue-router';
import { useSessionStorage } from '@/composables/use-storage';

// we do not restore position past 4 seconds
export const TEST_VIEWPORT_HEIGHT_TIMEOUT = 125;
export const TEST_VIEWPORT_HEIGHT_MAX_RETRIES = 32;

const matchNumber = /\d+/;
export const getCategoryId = (category: string) => Number.parseInt(matchNumber.exec(category)[0]);

export const getStorageKey = (type: 'height' | 'bannerShown', categories: number[]) =>
  `${{ height: 'HEIGHT_STORAGE_ITEM_KEY', bannerShown: 'BANNER_SHOWN_STORAGE_ITEM_KEY' }[type]}${categories.length ? '_' : ''}${
    categories.length ? categories.join('_') : 'ALL'
  }`;

// yes, we have lost all hope at this point
// long story short, this can't be fixed without extra refactor
// the product tiles render inconsistently making the page height different on every render (with the same data)
export const itIsAlmostEqualButDefinitelyNotTheSame = (oldHeight: number, newHeight: number) =>
  Math.abs(oldHeight - newHeight) < 100;

export const getIsBannerShown = () => {
  const bannerTopLine = document.querySelector('.banner-top-line');
  return bannerTopLine && window.getComputedStyle(bannerTopLine).display !== 'none';
};

export const getBannerOffset = () => {
  const bannerTopLine = document.querySelector('.banner-top-line');
  return bannerTopLine.clientHeight;
};

export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export const runAndRetryTest = async (test: () => boolean | Promise<boolean>, timeout = 500, maxRetries = 10): Promise<void> => {
  let retries = 0;
  return new Promise((resolve, reject) => {
    const run = async () => {
      if (retries === maxRetries) {
        reject(new Error('Max retries reached'));
        return;
      }
      await sleep(timeout);
      if (await test()) {
        resolve();
      } else {
        retries++;
        await run();
      }
    };
    run();
  });
};

export const useSetRestorePosition = (categories: Ref<number[]>) => {
  onBeforeUnmount(() => {
    const { setStorageItem } = useSessionStorage();
    setStorageItem(getStorageKey('bannerShown', categories.value), getIsBannerShown());
    setStorageItem(getStorageKey('height', categories.value), window.document.body.clientHeight);
  });
};

export const categoryPositionBehaviour: RouterScrollBehavior = async (to, from, savedPosition) => {
  if (to.name === from.name) {
    return undefined;
  }

  if (!['product', 'brand'].includes(String(from.name))) {
    return { top: 0, left: 0 };
  }

  let categories: number[] = [];
  if (Array.isArray(to?.params?.categories)) {
    categories = ((to?.params?.categories as string[]) ?? []).map((category) => getCategoryId(category));
  }

  const { getStorageItem } = useSessionStorage();
  const savedClientHeight = getStorageItem(getStorageKey('height', categories));
  const savedIsBannerShown = getStorageItem(getStorageKey('bannerShown', categories));
  const positionY = savedPosition.top + (savedIsBannerShown ? 0 : 40);

  const viewportHeightMatchesSavedValue = () =>
    itIsAlmostEqualButDefinitelyNotTheSame(savedClientHeight, window.document.body.clientHeight);

  try {
    await runAndRetryTest(viewportHeightMatchesSavedValue, TEST_VIEWPORT_HEIGHT_TIMEOUT, TEST_VIEWPORT_HEIGHT_MAX_RETRIES);
    await sleep(50); // required delay to avoid lazy loaded images loading indefinitely...
    return { top: positionY, left: savedPosition.left };
  } catch {
    return { top: 0, left: 0 };
  }
};
