<template>
  <div
    v-if="!isCheckoutPage && !isMobile"
    class="touchpoint-container"
  >
    <HeaderPill v-if="isHeaderPill" />
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';

import HeaderPill from '@/components/header/header-pill.vue';

import useIsCheckout from '@/composables/use-enclosed-checkout';

import { TouchpointsSlot } from '@/types/api/touchpoints';
import { useBreakpoints } from '@/modules/design-system-candidates';

export default defineComponent({
  name: 'TouchpointContainer',
  components: {
    HeaderPill,
  },
  props: {
    slotName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { isCheckoutPage } = useIsCheckout();
    const { isMobile } = useBreakpoints();

    const isHeaderPill = computed((): boolean => {
      return props.slotName === TouchpointsSlot.HeaderPill;
    });

    return {
      isCheckoutPage,
      isMobile,
      isHeaderPill,
    };
  },
});
</script>
