import { OFFERS_STORE_ID } from '@/store/offers';
import type { OffersState } from '@/store/offers';
import type { OffersGetters } from '@/store/offers/getters';
import type { OffersActions } from '@/store/offers/actions';
import type { OffersMutations } from '@/store/offers/mutations';

import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useGetters, useActions, useMutations } = createNamespacedHelpers<
  OffersState,
  OffersGetters,
  OffersActions,
  OffersMutations
>(OFFERS_STORE_ID);

export default {
  useGetters,
  useActions,
  useMutations,
};
