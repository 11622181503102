<template>
  <div>
    <LabelInfo
      v-if="showComponent('LabelInfo')"
      :title="$t('What is a defective item?')"
      :content="
        $t(
          'Any imperfection of an item due to a flaw in design or manufacturing, can be considered defective. Products with a nearing expiration date or non-compliant packaging can be considered as defective.'
        )
      "
    />
    <ProductsCheckList v-show="showComponent('ProductsCheckList')" />
    <ProductsList
      v-if="getSelectedProducts.length"
      v-show="showComponent('ProductsList')"
      ref="productsListRef"
      :is-active="showComponent('ProductsList')"
      :with-attachments="true"
      :with-description="true"
      @set-loader="setLoader"
      @products-validation="productsDataChanged"
      @all-products-removed="backToCheckList"
    />
    <CaseActions
      :next-step-ready="nextStepReady()"
      :show-back-button="showBackButton"
      @set-loader="setLoader"
      @submit="$emit('submit')"
    />
  </div>
</template>

<script lang="ts">
import BaseItemsCase from '@/components/account/orders/order/order-retailer-reject/cases/base-items-case.vue';
import LabelInfo from '@/components/account/orders/order/order-retailer-reject/components/label-info.vue';
import ProductsCheckList from '@/components/account/orders/order/order-retailer-reject/components/products-check-list.vue';
import ProductsList from '@/components/account/orders/order/order-retailer-reject/components/products-list.vue';
import CaseActions from '@/components/account/orders/order/order-retailer-reject/components/case-actions.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DefectiveItems',
  components: {
    LabelInfo,
    ProductsList,
    ProductsCheckList,
    CaseActions,
  },
  extends: BaseItemsCase,
  emits: ['submit'],
});
</script>
