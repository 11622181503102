<template>
  <div>
    <form class="login-form">
      <div class="header">
        <p class="title">
          {{ $t('platform.2fa.form.recovery.title') }}
        </p>
      </div>
      <div class="content">
        <div>
          <AkInput
            v-model="recoveryCode"
            :label="$t('platform.2fa.form.recovery.code.label')"
            type="password"
            class="mt-1 block w-full"
            required
          />
        </div>
        <div
          v-if="error"
          class="global-error"
        >
          {{ error }}
        </div>
        <div class="pwd">
          <AkButton
            class="forgot-pwd"
            link
            size="md"
            @click.prevent="$emit('change-to-two-factor')"
          >
            {{ $t('platform.2fa.switch.code') }}
          </AkButton>
        </div>
        <div class="flex items-center justify-end mt-4">
          <AkButton
            class="button--extended ds-mt-3"
            size="xl"
            @click="confirm2faWithRecoveryCode"
          >
            {{ $t('Confirm') }}
          </AkButton>
        </div>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { AkButton } from '@ankorstore/design-system';
import { confirmWithRecoveryCode } from '@/services/api/two-factor-authentification';

export default defineComponent({
  name: 'LoginTwoFactorRecovery',
  components: { AkButton },
  emits: ['change-to-two-factor'],
  setup() {
    const status = ref('login');
    const error = ref('');
    const recoveryCode = ref('');

    const confirm2faWithRecoveryCode = () => {
      confirmWithRecoveryCode(recoveryCode.value)
        .then(() => {
          error.value = '';
          window.location.href = '/';
        })
        .catch((err) => {
          error.value = err.response.data.message;
        });
    };
    return {
      confirm2faWithRecoveryCode,
      recoveryCode,
      error,
      status,
    };
  },
});
</script>
