import { TrackingProduct } from '@/types/tracking-product';

export type Option = {
  id: number;
  name: string;
};

export type ProductProperty = { option?: Option; quantity?: number } & Pick<
  TrackingProduct,
  'id' | 'name' | 'wholesalePrice' | 'position'
>;

export function createProductProperty({ id, name, wholesalePrice, position, option }: ProductProperty): ProductProperty {
  const product: ProductProperty = {
    id,
    name,
    wholesalePrice,
  };

  if (position !== undefined) {
    product.position = position;
  }

  if (option) {
    product.option = {
      id: option.id,
      name: option.name,
    };
  }
  return product;
}
