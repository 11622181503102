/* Import TinyMCE */
import tinymce from 'tinymce';

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default/icons.min.js';

/* Required TinyMCE components */
import 'tinymce/themes/silver/theme.min.js';
import 'tinymce/models/dom/model.min.js';

/* Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/oxide/skin.js';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.js';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.js';
import 'tinymce/skins/content/default/content.min.css';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/table';

import {onBeforeMount} from "vue";

const getGlobal = () => {
  return typeof window !== 'undefined' ? window : global;
}
const useTinymce = () => {
  onBeforeMount(() => {
    const glob = getGlobal();
    if (!glob.tinymce) {
      // this allows the tinymce wrapper to rely on the bundled & loaded tinymce instance instead of trying to load it
      // from CDN.
      // cf:
      // - https://github.com/tinymce/tinymce-vue/blob/main/src/main/ts/components/Editor.ts#L91-L103
      // - https://github.com/tinymce/tinymce-vue/blob/main/src/main/ts/TinyMCE.ts
      glob.tinymce = tinymce;
    }
  })
  return { tinymce };
}

export default useTinymce;
