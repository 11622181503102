import {
  ADD_PRODUCT_TO_ADS_LIST,
  CLEAR_CAMPAIGN_INFO,
  REMOVE_PRODUCT_FROM_ADS_LIST,
  SET_ADS_CAMPAIGNS,
  SET_ADS_LIST,
  UPDATE_CURRENT_CAMPAIGN,
  UPDATE_CURRENT_CAMPAIGN_STATUS,
} from '@bc/advertisement/feature/ads-dashboard/store/mutations-types';
import { AdInfo, AdsCampaignStatus, AdsTableItem, CampaignWizard } from '@bc/advertisement/domain';
import { initialState, InitialState } from '@bc/advertisement/feature/ads-dashboard/store/index';
import { ProductCollectionItem } from '@/types/account/product-collections';
import { addDate, TypeToAdd } from '@/services/date-locale';

export default {
  [SET_ADS_CAMPAIGNS]: (state: InitialState, { campaigns, campaignsMeta }) => {
    state.adsCampaigns.data = campaigns;
    state.adsCampaigns.meta = campaignsMeta;
  },
  [SET_ADS_LIST]: (state: InitialState, ads: AdInfo[]) => (state.adsList = ads),
  [CLEAR_CAMPAIGN_INFO]: (state: InitialState) => {
    state.campaignWizardInfo = {
      ...initialState.campaignWizardInfo,
      startDate: new Date(),
      endDate: addDate(new Date(), 27, TypeToAdd.Days),
    };
    state.adsList = initialState.adsList;
  },
  [UPDATE_CURRENT_CAMPAIGN]: (state: InitialState, campaignDetails: CampaignWizard) =>
    (state.campaignWizardInfo = { ...state.campaignWizardInfo, ...campaignDetails }),
  [UPDATE_CURRENT_CAMPAIGN_STATUS]: (state: InitialState, newStatus: AdsCampaignStatus) =>
    (state.campaignWizardInfo = { ...state.campaignWizardInfo, status: newStatus }),
  [ADD_PRODUCT_TO_ADS_LIST]: (state: InitialState, productItem: AdsTableItem) =>
    state.campaignWizardInfo.adsProducts.push(productItem),
  [REMOVE_PRODUCT_FROM_ADS_LIST]: (state: InitialState, productItem: AdsTableItem) => {
    const index = state.campaignWizardInfo.adsProducts.findIndex(
      (item: ProductCollectionItem) => item.product_id === productItem.product_id
    );

    state.campaignWizardInfo.adsProducts.splice(index, 1);
  },
};
