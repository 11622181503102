import { FilterBuilder } from './filter-builder';

export class ProductFilterBuilder {
  private builder: FilterBuilder;

  constructor() {
    this.builder = new FilterBuilder();
  }

  withBrandId(brandId: string | number) {
    this.builder.addFilter('brand.id', brandId, '=');

    return this;
  }

  withCollectionId(collectionId: string | number) {
    this.builder.addFilter('aks_custom__collection', collectionId, '=');

    return this;
  }

  withIsOutOfStock(isOutOfStock: boolean) {
    this.builder.addFilter('out_of_stock', isOutOfStock, '=');

    return this;
  }

  withSortBy(sortBy: 'latest:desc') {
    this.builder.addFilter('sorts', sortBy, '=');

    return this;
  }

  withOpenedCountrie(userCountry: string) {
    this.builder.addFilter('brand.opened_countries', userCountry, ':');

    return this;
  }

  withCategories(categories: string[]) {
    if (!categories?.length) {
      return this;
    }

    const categoryFilter = categories.join(' > ');
    const level = categories.length - 1;
    this.builder.addFilter(`catalog_hierarchical_categories.v3.lvl${level}`, categoryFilter);

    return this;
  }

  withNew(isNew: boolean) {
    this.builder.addFilter('is_new', isNew);

    return this;
  }

  build() {
    return this.builder.build();
  }
}
