import http from '@/services/api/http';
import {
  OrderProduct,
  OrderParcels,
  OrderItems,
  CaseReason,
  CaseReasonStep,
  CaseReasonStepInfo,
  ProductFile,
  FormFiles,
  OrderParcel,
  PackagingIssue,
  ParcelIssues,
  ParcelIssueOption,
  FormFileType,
  FileLinks,
  PackagingIssuesList,
  OrderIssueStatus,
  OrderProducts,
} from '@/types/order-retailer-rejection';
import { config } from '@/utilities/order-retailer-rejection/config';
import { TrackedPackage } from '@/types/order';
import { uploadOrderFile } from '@/services/order-rejection/order-rejection.service';
import { ProductsVariant } from '@/types/api/brand-create-order';
import { isEnabled } from '@/services/features';

export interface State {
  products: OrderProducts;
  parcels: OrderParcels;
  formFiles: FormFiles;
  currentStep: CaseReasonStepInfo;
  caseReason: CaseReason;
  showFinalPopin: boolean;
  description: string;
  contactLinkClicked: boolean;
  parcelIssueOptions: ParcelIssues;
  packagingIssues: PackagingIssuesList;
  packagingIssueDescription: string;
}

const initialState: State = {
  products: [],
  parcels: [],
  formFiles: [],
  currentStep: {
    stepNumber: 0,
    label: '',
    key: '',
  },
  caseReason: CaseReason.General,
  showFinalPopin: false,
  description: '',
  contactLinkClicked: false,
  parcelIssueOptions: {
    [ParcelIssueOption.ParcelExternalIssues]: false,
    [ParcelIssueOption.DeliveryReservation]: false,
  },
  packagingIssues: {
    [PackagingIssue.IrrelevantBoxSize]: false,
    [PackagingIssue.LackOfProtectiveMaterials]: false,
    [PackagingIssue.LackOfSeparators]: false,
    [PackagingIssue.Other]: false,
  },
  packagingIssueDescription: '',
};
export default {
  namespaced: true,

  state: initialState,

  actions: {
    async loadProducts(_, { orderId }: { orderId: number }): Promise<void> {
      return http()
        .get(`/api/orders/${orderId}`)
        .then((response) => {
          return response.data.data.items;
        });
    },
    setProducts({ commit, getters, rootGetters }, { items }: { items: OrderItems }): void {
      const products = [];

      if (getters.showNewOrderIssueFlow) {
        const orderIssue = rootGetters['legacyAccount/orderIssue'];
        const getItemProperty = rootGetters['legacyAccount/getItemProperty'];
        const skuList = orderIssue.products.map(function (item: ProductsVariant) {
          return item.sku;
        });

        items = items
          .filter((item) => skuList.includes(getItemProperty(item, 'sku')))
          .map((item) => {
            const orderIssueItem = orderIssue.products
              .filter((product: ProductsVariant) => product.sku === getItemProperty(item, 'sku'))
              .shift();
            commit(
              'legacyAccount/MUTATE_ITEM_PROPERTY',
              {
                item,
                property: 'multiplied_quantity',
                value: orderIssueItem.quantity,
              },
              { root: true }
            );
            return item;
          });
      }
      items.forEach((item) => {
        products.push({
          id: item.product?.id, // missing
          orderItemId: item.internalId,
          sku: item.orderedProduct?.data.sku,
          name: item.product?.name,  // missing
          originalName: item.orderedProduct?.data.name,
          quantity: '',
          originalQuantity: item.unitQuantity,
          selected: false,
          expanded: false,
          expandedOnce: false,
          saved: false,
          files: [],
          description: '',
          quantityError: '',
          descriptionError: '',
          filesErrors: [],
        });
      });

      commit('SET_PRODUCTS', {
        products,
      });
    },
    setParcels({ commit }, { items }: { items: TrackedPackage[] }): void {
      const parcels: OrderParcel[] = [];

      items.forEach((item) => {
        parcels.push({
          trackingLink: item.tracking_link,
          clicked: false,
        });
      });

      commit('SET_PARCELS', {
        parcels: parcels,
      });
    },
    setProductProperty(
      { commit },
      { product, property, value }: { product: OrderProduct; property: string; value: boolean }
    ): void {
      commit('SET_PRODUCT_PROPETRY', {
        product,
        property,
        value,
      });
    },
    removeProductFile({ commit }, { product, file }: { product: OrderProduct; file: ProductFile }): void {
      commit('REMOVE_PRODUCT_FILE', {
        product,
        file,
      });
    },
    setStep({ commit }, { step }: { step: CaseReasonStep }): void {
      commit('SET_CURRENT_STEP', {
        step,
      });
    },
    resetCaseData(
      { commit, getters, rootGetters, dispatch },
      { caseReason, orderId }: { caseReason: CaseReason; orderId: number }
    ): void {
      commit('RESET_CASE_DATA', {
        caseReason,
      });

      const getOrderItems = rootGetters['legacyAccount/orderItems'];
      const orderShipmentTrackedPackages = rootGetters['legacyAccount/orderShipmentTrackedPackages'];

      const itemsReasons = [CaseReason.DamagedItems, CaseReason.WrongItems, CaseReason.MissingItems, CaseReason.DefectiveItems];

      if (itemsReasons.includes(getters.getCaseReason)) {
        let items = [];

        if (getOrderItems.length) {
          items = getOrderItems;
        } else {
          dispatch('legacyAccount/fetchOrder', {
            orderId,
          });
        }

        if (items.length === 0) {
          dispatch('loadProducts', {
            orderId,
          });
        }

        dispatch('setProducts', {
          items,
        });
      }

      if (orderShipmentTrackedPackages.length) {
        dispatch('setParcels', {
          items: orderShipmentTrackedPackages,
        });
      }

      let step = CaseReasonStep.Initial;

      if (
        (caseReason === CaseReason.MissingItems && getters.getParcels.length > 1) ||
        (caseReason === CaseReason.NotReceived && getters.getParcels.length > 0)
      ) {
        step = CaseReasonStep.TrackWithLink;
      } else if (caseReason === CaseReason.NotReceived && getters.getParcels.length === 0) {
        step = CaseReasonStep.ContactBrand;
      } else if (
        [
          CaseReason.DamagedItems,
          CaseReason.WrongItems,
          CaseReason.MissingItems,
          CaseReason.DefectiveItems,
          CaseReason.NotReceived,
        ].includes(getters.getCaseReason)
      ) {
        step = CaseReasonStep.OrderInfo;
      }

      commit('SET_CURRENT_STEP', {
        step,
      });
    },
    prevStep({ commit, getters }): void {
      const currentStep = getters.getCurrentStep.key;
      const steps = Object.keys(getters.getConfig.steps);
      let index = steps.indexOf(currentStep);
      let prevStep = steps[--index];

      if (getters.getCaseReason === CaseReason.NotReceived) {
        if (getters.getParcels.length > 0) {
          prevStep = CaseReasonStep.TrackWithLink;
        } else {
          prevStep = CaseReasonStep.ContactBrand;
        }
      }

      commit('SET_CURRENT_STEP', {
        step: prevStep,
      });
    },
    nextStep({ commit, getters }, { submitCallback }: { submitCallback: () => void }): void {
      const currentStep = getters.getCurrentStep.key;

      if (currentStep === CaseReasonStep.Summary) {
        submitCallback();
      } else {
        const steps = Object.keys(getters.getConfig.steps);
        let index = steps.indexOf(currentStep);
        let nextStep = steps[++index];

        if (
          getters.getCaseReason === CaseReason.NotReceived &&
          (currentStep === CaseReasonStep.TrackWithLink || currentStep === CaseReasonStep.ContactBrand)
        ) {
          nextStep = CaseReasonStep.Summary;
        }

        commit('SET_CURRENT_STEP', {
          step: nextStep,
        });
      }
    },
    toggleFinalPopin({ commit }, { value }: { value: boolean }): void {
      commit('SET_FINAL_POPIN_VISIBILITY', {
        value,
      });
    },
    clearProductData({ commit }, { product }: { product: OrderProduct }): void {
      commit('CLEAR_PRODUCT_DATA', {
        product,
      });
    },
    async uploadFile({ rootGetters }, { file }: { file: File }): Promise<string> {
      const orderInternalId = rootGetters['legacyAccount/orderInternalId'];
      return uploadOrderFile(file, orderInternalId);
    },
    async uploadFormFile({ commit, dispatch }, { file, type }: { file: File; type: FormFileType }): Promise<string> {
      const link = await dispatch('uploadFile', {
        file,
      });

      if (link) {
        commit('ADD_FORM_FILE', {
          file,
          link,
          type,
        });
      }

      return link;
    },
    removeFormFile({ commit }, { file }: { file: File }): void {
      commit('REMOVE_FORM_FILE', {
        file,
      });
    },
    async uploadProductFile({ commit, dispatch }, { product, file }: { product: OrderProduct; file: File }): Promise<string> {
      const link = await dispatch('uploadFile', {
        file,
      });

      if (link) {
        commit('ADD_PRODUCT_FILE', {
          product,
          file,
          link,
        });
      }

      return link;
    },
    resetProductsFiles({ commit }): void {
      commit('RESET_PRODUCTS_FILES');
    },
    changeDescription({ commit }, { value }: { value: string }): void {
      commit('SET_DESCRIPTION', { value });
    },
    async escalateOrderIssue(_, { orderInternalId, data }: { orderInternalId: number; data: object }): Promise<void> {
      return http().post(`/api/orders/${orderInternalId}/order-issue/escalate`, data);
    },
    async escalateOrderIssueToAdjustQuantity(
      _,
      { orderInternalId, data }: { orderInternalId: number; data: object }
    ): Promise<void> {
      return http().post(`/api/orders/${orderInternalId}/issue/adjust-quantity`, data);
    },
    async adjustQuantity(_, { orderInternalId }: { orderInternalId: number }): Promise<void> {
      return http().post(`/api/orders/${orderInternalId}/issue/adjust-quantity`);
    },
    setParcelIssueOption({ commit }, { issueType, value }: { issueType: ParcelIssueOption; value: boolean }): void {
      commit('SET_PARCEL_ISSUE_OPTION', {
        issueType,
        value,
      });
    },
    setPackagingIssue({ commit }, { issueType, value }: { issueType: PackagingIssue; value: boolean }): void {
      commit('SET_PACKAGING_ISSUE', {
        issueType,
        value,
      });
    },
    setPackagingIssueDescription({ commit }, { value }: { value: string }): void {
      commit('SET_PACKAGING_ISSUE_DESCRIPTION', {
        value,
      });
    },
    async fetchOrderInfo({ dispatch }, { orderId }: { orderId: number }): Promise<void> {
      return dispatch('legacyAccount/fetchOrder', orderId, { root: true });
    },
    async closeOrderIssue({ dispatch }, { orderId }: { orderId: number }): Promise<void> {
      await http().post(`/api/orders/${orderId}/order-issue/close`);
      dispatch('fetchOrderInfo', { orderId });
    },
    async commitReshipment(
      { dispatch },
      { orderInternalId, orderId }: { orderInternalId: number; orderId: string }
    ): Promise<void> {
      await http().post(`/api/orders/${orderInternalId}/order-issue/commit-reshipment`);
      dispatch('fetchOrderInfo', { orderId });
    },
    async reshipOrder({ dispatch }, { orderId, orderInternalId }: { orderInternalId: number; orderId: string }): Promise<void> {
      await http().post(`/api/orders/${orderInternalId}/order-issue/reship`);
      dispatch('fetchOrderInfo', { orderId });
    },
    async confirmReception({ dispatch }, { orderId }: { orderId: number }): Promise<void> {
      await http().post(`/api/orders/${orderId}/order-issue/confirm-reception`);
      dispatch('fetchOrderInfo', { orderId });
    },
  },
  mutations: {
    SET_PRODUCTS(state: State, { products }: { products: OrderProducts }): void {
      state.products = products;
    },
    SET_PARCELS(state: State, { parcels }: { parcels: OrderParcels }): void {
      state.parcels = parcels;
    },
    SET_PRODUCT_PROPETRY(
      state: State,
      { product, property, value }: { product: OrderProduct; property: string; value: boolean }
    ): void {
      const index = state.products.indexOf(product);
      state.products[index][property] = value;
    },
    SET_PARCEL_PROPERTY(state: State, { parcel, property, value }: { parcel: OrderParcel; property: string; value: any }): void {
      const index = state.parcels.indexOf(parcel);
      state.parcels[index][property] = value;
    },
    ADD_PRODUCT_FILE(state: State, { product, file, link }: { product: OrderProduct; file: File; link: string }): void {
      const index = state.products.indexOf(product);
      //@ts-ignore
      state.products[index].files.push({
        file,
        link,
      });
    },
    REMOVE_PRODUCT_FILE(state: State, { product, file }: { product: OrderProduct; file: ProductFile }): void {
      const index = state.products.indexOf(product);
      const fileIndex = state.products[index].files.indexOf(file);
      state.products[index].files.splice(fileIndex, 1);
    },
    ADD_FORM_FILE(state: State, { file, link, type }: { file: File; link: string; type: FormFileType }): void {
      if (!state.formFiles) {
        state.formFiles = [];
      }

      state.formFiles.push({
        file,
        link,
        type,
      });
    },
    REMOVE_FORM_FILE(state: State, { file }: { file: File }): void {
      const result = state.formFiles.filter((item) => item.file === file).shift();
      const index = state.formFiles.indexOf(result);
      state.formFiles.splice(index, 1);
    },
    SET_CASE_REASON(state: State, { caseReason }: { caseReason: CaseReason }): void {
      state.caseReason = caseReason;
    },
    SET_CURRENT_STEP(state: State, { step }: { step: CaseReasonStep }): void {
      let stepNumber = 0,
        label = '',
        key = '';

      if (step !== CaseReasonStep.Initial) {
        const stepInfo = config[state.caseReason].steps[step];
        stepNumber = stepInfo.stepNumber;
        label = stepInfo.label;
        key = stepInfo.key;
      }

      state.currentStep = {
        stepNumber: stepNumber,
        label,
        key,
      };
    },
    RESET_CASE_DATA(state: State, { caseReason }: { caseReason: CaseReason }): void {
      state.products = [];
      state.formFiles = [];
      state.description = '';
      state.caseReason = caseReason;
      state.parcels = [];
      state.contactLinkClicked = false;
      state.packagingIssues = {
        [PackagingIssue.IrrelevantBoxSize]: false,
        [PackagingIssue.LackOfProtectiveMaterials]: false,
        [PackagingIssue.LackOfSeparators]: false,
        [PackagingIssue.Other]: false,
      };
      state.packagingIssueDescription = '';
    },
    SET_FINAL_POPIN_VISIBILITY(state: State, { value }: { value: boolean }): void {
      state.showFinalPopin = value;
    },
    CLEAR_PRODUCT_DATA(state: State, { product }: { product: OrderProduct }): void {
      const index = state.products.indexOf(product);
      const item = state.products[index];
      item.expanded = item.expandedOnce = item.selected = item.saved = false;
      item.quantity = '';
      item.quantityError = '';
      item.description = '';
      item.descriptionError = '';
      item.files = [];
      item.filesErrors = [];
    },
    RESET_PRODUCTS_FILES(state: State): void {
      for (let index = 0; index < state.products.length; index++) {
        const files = state.products[index].files;

        for (let j = 0; j < files.length; j++) {
          files[j].file = null;
        }
      }
    },
    SET_DESCRIPTION(state: State, { value }: { value: string }): void {
      state.description = value;
    },
    SET_CONTACT_LINK_CLICKED(state: State, { value }: { value: boolean }): void {
      state.contactLinkClicked = value;
    },
    SET_PARCEL_ISSUE_OPTION(state: State, { issueType, value }: { issueType: ParcelIssueOption; value: boolean }): void {
      state.parcelIssueOptions[issueType] = value;
    },
    SET_PACKAGING_ISSUE(state: State, { issueType, value }: { issueType: PackagingIssue; value: boolean }): void {
      state.packagingIssues[issueType] = value;
    },
    SET_PACKAGING_ISSUE_DESCRIPTION(state: State, { value }: { value: string }): void {
      state.packagingIssueDescription = value;
    },
  },
  getters: {
    getProducts: (state: State): OrderProducts => state.products,
    getSelectedProducts: (state: State): OrderProducts => state.products.filter((product) => product.selected),
    getSavedProducts: (state: State): OrderProducts => state.products.filter((product) => product.saved),
    getProductsWithQuantityError: (state: State): OrderProducts => state.products.filter((product) => product.quantityError),
    getProductsWithDescriptionError: (state: State): OrderProducts =>
      state.products.filter((product) => product.descriptionError),
    getProductsWithFilesErrors: (state: State): OrderProducts => state.products.filter((product) => product.filesErrors.length),
    getPackagingPhotos: (state: State, getters): FileLinks => {
      const packagingPhotos = state.formFiles
        .filter((file) => file.type === FormFileType.Packaging)
        .map((file) => {
          return file.link;
        }) as FileLinks;

      if (getters.showRevampedVersion) {
        const parcelIssueChecked = state.parcelIssueOptions[ParcelIssueOption.ParcelExternalIssues];
        return parcelIssueChecked ? packagingPhotos : null;
      }

      return packagingPhotos;
    },
    getRejectionFormFiles: (state: State): FormFiles => state.formFiles,
    getCaseReason: (state: State): CaseReason => state.caseReason,
    getCurrentStep: (state: State): CaseReasonStepInfo => state.currentStep,
    getConfig: (state: State): object | undefined => {
      return { ...config['common'], ...config[state.caseReason] };
    },
    canShowOrderInfo: (state: State): boolean =>
      state.caseReason === CaseReason.General ||
      (state.currentStep.stepNumber > 0 &&
        [CaseReasonStep.OrderInfo, CaseReasonStep.Summary, CaseReasonStep.TrackWithLink, CaseReasonStep.ContactBrand].includes(
          state.currentStep.key as CaseReasonStep
        )),
    canShowStepper: (state: State): boolean => state.currentStep.stepNumber > 0,
    canShowFinalPopin: (state: State): boolean => state.showFinalPopin,
    showAgreementCheckbox: (state, getters, _rootState, rootGetters): boolean => {
      const issueCaseVisibleToBrand = rootGetters['legacyAccount/issueCaseVisibleToBrand'];

      return state.currentStep.key === CaseReasonStep.Summary && issueCaseVisibleToBrand(getters.getCaseReason);
    },
    getParcels: (state: State): OrderParcels => state.parcels,
    getClickedParcels: (state: State): OrderParcels => state.parcels.filter((parcel) => parcel.clicked),
    getDescription: (state: State): string => state.description,
    isContactLinkClicked: (state: State): boolean => state.contactLinkClicked,
    getPackagingIssues: (state: State): PackagingIssuesList => state.packagingIssues,
    getPackagingIssuesList: (state: State) => {
      return Object.keys(state.packagingIssues).map((key) => {
        {
          const issue = {
            key,
            value: state.packagingIssues[key],
          };

          if (key === PackagingIssue.Other && state.packagingIssueDescription) {
            issue['text'] = state.packagingIssueDescription;
          }

          return issue;
        }
      });
    },
    getAllParcelIssueOptions: (state: State) => {
      return Object.keys(state.parcelIssueOptions).map((key) => {
        return {
          key,
          value: state.parcelIssueOptions[key],
        };
      });
    },
    isParcelIssueOptionsChecked:
      (state: State) =>
      (name: string): boolean => {
        return state.parcelIssueOptions[name];
      },
    showSectionsExpander: (state: State, getters): boolean =>
      state.currentStep.key === CaseReasonStep.Summary && getters.showRevampedVersion,
    showRevampedVersion: (state: State): boolean =>
      (state.caseReason === CaseReason.DamagedItems && isEnabled('damaged_items_form_v2')) ||
      (state.caseReason === CaseReason.MissingItems && isEnabled('missing_items_form_v2')),
    showNewOrderIssueFlow: (_state, _getters, _rootState, rootGetters): boolean => {
      const orderIssue = rootGetters['legacyAccount/orderIssue'];

      return orderIssue && orderIssue.status === OrderIssueStatus.Reshipped;
    },
  },
};
