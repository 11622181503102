<template>
  <Promocode
    ref="promoCode"
    :model="model"
    :is-revamp="isRevamp"
    :initial-value="liftPromoCode"
    @input="promocodeChanged"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Question } from '@/types/question';
import Promocode from '@/components/sign-up/common/promocode.vue';
import { mapGetters } from 'vuex';
import { State } from '@/types/state';

export default defineComponent({
  name: 'QuestionPromocode',
  components: { Promocode },
  props: {
    question: {
      type: Object as () => Question,
      required: true,
    },
    state: {
      type: Object as () => State,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    isRevamp: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['change'],
  computed: {
    ...mapGetters('signUp', ['liftPromoCode']),
  },
  methods: {
    promocodeChanged({ code }) {
      this.$emit('change', this.question.alias, {
        answer_id: this.question.id,
        content: code,
      });
    },
    async searchPromoCode() {
      return await (this.$refs.promoCode as InstanceType<typeof Promocode>).searchPromoCode();
    },
  },
});
</script>

<style scoped lang="scss">
.textarea-form {
  textarea {
    min-height: 70px;
    font-size: 14px;
  }
}
</style>
