import Analytics from '@/services/analytics';
import { OrderIssueClicked } from '@/services/analytics/events/order-issue-events';
import { OrderIssueClickedAction } from '@/services/analytics/properties/order-issue-property';
import { mapGetters, mapState } from 'vuex';
import { defineComponent } from 'vue';

export const orderIssueAnalyticsMixin = defineComponent({
  name: 'OrderIssueAnaluyticsMixin',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('orderRetailerRejection', [
      'getCaseReason',
      'getCurrentStep',
      'getSelectedProducts',
      'getSavedProducts',
      'getProductsWithQuantityError',
      'getProductsWithDescriptionError',
      'getProductsWithFilesErrors',
      'getPackagingPhotos',
      'getParcels',
      'getClickedParcels',
      'isContactLinkClicked',
    ]),
    ...mapState('legacyAccount', {
      order: 'orderDetail',
    }),
  },
  methods: {
    trackClickedAction(action: OrderIssueClickedAction): void {
      if (!this.$isEnabled('support_form_analytics') || this.loading) {
        return;
      }

      Analytics.track(
        new OrderIssueClicked({
          order_id: this.order.internalId,
          action: action,
          issue: this.getCaseReason,
          step: this.getCurrentStep.key,
          parcel_photo: Boolean(this.getPackagingPhotos),
          selected_products_count: this.getSelectedProducts.length,
          completed_products_count: this.getSavedProducts.length,
          products_with_quantity_error: this.getProductsWithQuantityError.length,
          products_with_description_error: this.getProductsWithDescriptionError.length,
          products_with_file_error: this.getProductsWithFilesErrors.length,
          parcel_links: this.getParcels.length,
          parcel_links_clicked: this.getClickedParcels.length,
          contact_link_clicked: this.isContactLinkClicked,
        })
      );
    },
  },
});
