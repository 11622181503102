import { formatInTimeZone, toDate } from 'date-fns-tz';
import { getLocalTimezone } from './date.timezone';
import { Duration, add, parse } from 'date-fns';

export class DateTimeFormats {
  /**
   * YYYY-MM-DD
   * example: 2014-10-25
   */
  public static readonly DateOnly = 'yyyy-MM-dd';

  /**
   * yyyy-MM-dd'T'HH:mm:ssXXX
   * example: 2014-10-25T10:46:20Z
   */
  public static readonly ISO = "yyyy-MM-dd'T'HH:mm:ssXXX";

  /**
   * yyyy-MM-dd'T'HH:mm:ss.SSSXXX
   * example: 2024-07-27T21:00:00.000Z
   */
  public static readonly ISOWithMilliseconds = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
  /**
   * yyyy-MM-dd HH:mm:ss zzzz
   * example with en-GB locale: 2014-10-25 10:46:20 Central European Summer Time
   */
  public static readonly Default = 'yyyy-MM-dd HH:mm:ss zzzz';
}

/**
 * @param dateStr string in format YYYY-MM-DD and ISO format
 * @returns specified Date in local time-zone or null
 */
export function parseDateIntoLocalDateTime(dateStr: string): Date {
  if (dateStr == null) {
    return null;
  }

  const isValidISODateStr = isValidISODate(dateStr);
  const isValidISOWithMillisecondsDateStr = isValidISOWithMillisecondsDate(dateStr);
  if (!isValidDateOnlyString(dateStr) && !isValidISODateStr && !isValidISOWithMillisecondsDateStr) {
    throw new Error(`Wrong format. Expected:${DateTimeFormats.DateOnly} Recieved: ${dateStr}`);
  }

  const localTimezone = getLocalTimezone();
  const localDate = toDate(dateStr, { timeZone: localTimezone });

  if (localDate instanceof Date && isNaN(localDate.getTime())) {
    return null;
  }

  return localDate;
}

/**
 * @param date Date assuming that it's in local time-zone
 * @returns specified Date in YYYY-MM-DD format
 */
export function formatToDateOnly(date: Date): string {
  if (date == null) {
    return null;
  }

  const localTimezone = getLocalTimezone();
  return formatInTimeZone(date, localTimezone, DateTimeFormats.DateOnly);
}
/**
 * @param dateStr string in format YYYY-MM-DD
 * @param duration The duration object. Contains the duration in the units specified by the
 * object.
 * Example: {
 *   years: 2,
 *   months: 9,
 *   weeks: 1,
 *   days: 7,
 *   hours: 5,
 *   minutes: 9,
 *   seconds: 30,
 * }
 * @returns result Date in YYYY-MM-DD format
 */
export function addDurationShortFomat(dateStr: string, duration: Duration): string {
  const parsedDate = parseDateIntoLocalDateTime(dateStr);
  const addedDate = add(parsedDate, duration);
  return formatToDateOnly(addedDate);
}

function isValidDateOnlyString(dateString: string): boolean {
  const regex = /^(19\d{2}|[2-9]\d{3})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
  return regex.test(dateString);
}

function isValidDate(dateString: string, format: string): boolean {
  const result = parse(dateString, format, new Date());
  return !(result instanceof Date && isNaN(result.getTime()));
}

function isValidISODate(dateString: string): boolean {
  return isValidDate(dateString, DateTimeFormats.ISO);
}

function isValidISOWithMillisecondsDate(dateString: string): boolean {
  return isValidDate(dateString, DateTimeFormats.ISOWithMilliseconds);
}
