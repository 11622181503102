<template>
  <section
    class="lift-page-header"
    :style="{
      backgroundImage: `linear-gradient(to bottom, rgba(0,0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${brandImg})`,
    }"
  >
    <div class="lift-page-header__login">
      <AkParagraph class="lift-page-header__text">
        {{ $t('Already have an account?') }}
      </AkParagraph>
      <AkButton
        size="lg"
        link
        color="white"
        class="lift-page-header__button"
        data-testid="header-login-button"
        @on-click="$emit('login-click')"
      >
        {{ $t('Login') }}
      </AkButton>
    </div>
    <div class="lift-page-header__list">
      <img
        class="lift-page-header__logo"
        src="/images/logo/logo-white.svg"
        alt="ankorstore"
      >
      <img
        alt="ankorstore_cross"
        src="/images/cross.svg"
        class="lift-page-header__cross"
      >
      <AkParagraph class="lift-page-header__brand-name">
        {{ brandName }}
      </AkParagraph>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LiftHeader',
  props: {
    brandName: {
      type: String,
      required: true,
    },
    brandImg: {
      type: String,
      required: true,
    },
    brandId: {
      type: Number,
      required: true,
    },
  },
  emits: ['login-click'],
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';

.lift-page-header {
  @apply ds-bg-primary ds-flex ds-flex-col ds-px-6 md:ds-px-8 ds-py-6 md:ds-items-end ds-items-center ds-bg-no-repeat ds-bg-cover;

  &__list {
    @apply ds-p-3 ds-flex ds-items-center ds-justify-center ds-w-full;
  }

  &__text {
    @apply ds-font-basic ds-mb-0 ds-text-white ds-whitespace-nowrap ds-mr-4;
  }

  &__brand-name {
    @apply ds-text-4xl ds-font-basic ds-mb-0 ds-text-white ds-font-bold ds-text-center ds-hidden md:ds-block;
  }

  &__button {
    @apply ds-text-white;
    height: fit-content;
  }

  &__logo {
    @apply ds-w-auto ds-mt-4 md:ds-mt-0;
    max-height: 30px;
  }

  &__cross {
    @apply ds-mx-2 ds-hidden md:ds-block;
    width: 20px;
  }

  &__login {
    @apply ds-flex ds-w-full md:ds-w-auto ds-justify-between ds-items-center;
  }
}
</style>
