<template>
  <div
    v-if="content"
    class="banner-event column-container ds-w-full"
  >
    <div class="ds-flex ds-h-full">
      <div class="ds-flex-col ds-hidden sm:ds-flex" />
      <div
        class="content--background ds-flex-grow"
        :style="backgroundStyle"
      >
        <div class="content ds-h-full">
          <prismic-rich-text
            v-if="content.title && content.title[0] && content.title[0].text !== ''"
            :field="content.title"
            class="content--title"
            :style="`color: ${content.title_color}`"
          />

          <prismic-rich-text
            v-if="content.description && content.description[0] && content.description[0].text !== ''"
            :field="content.description"
            class="content--description"
            :style="`color: ${content.description_color}`"
          />
        </div>
      </div>
      <div class="ds-flex-col ds-hidden sm:ds-flex" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { Banner1 } from './types';

export default defineComponent({
  name: 'Banner1',
  props: {
    content: {
      type: Object as () => Banner1,
      default: () => null,
    },
  },

  computed: {
    backgroundStyle(): { backgroundImage: string } {
      return { backgroundImage: `url('${this.content?.background?.url}')` };
    },
  },
});
</script>
<style lang="scss">
.banner-event {
  .content {
    @apply ds-flex-col ds-py-5 ds-px-6 ds-flex ds-justify-center ds-font-light;
  }

  strong {
    @apply ds-font-bold;
  }

  .content--title {
    @apply ds-text-2xl sm:ds-text-3xl;
  }

  .content--description {
    @apply ds-text-xl sm:ds-text-4xl ds-tracking-tight;
  }

  .content--background {
    @apply ds-bg-cover ds-bg-no-repeat ds-bg-center ds-text-center ds-flex-grow;
  }

  &.column-container {
    height: 240px;

    @apply ds-overflow-hidden;
  }
}
</style>
