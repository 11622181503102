import sanitizeHtml from 'sanitize-html';
import { nextTick } from 'vue'
/**
 * Converts markdown text to HTML.
 * The markdown can contain bullet lists, numbered lists, and new lines.
 *
 * @param text The markdown text to be converted to HTML.
 * @returns A HTML string
 *
 * @example
 * const description = getRichDescription("This is a
 *  description");
 * console.log(description);
 * // This is a<br/> description</p>
 * @example
 * const description = getRichDescription(`- item 1\n- item 2\n- item 3\n')
 * console.log(description);
 * // <ul><li>item 1</li><li>item 2</li><li>item 3</li></ul>
 * @example
 * const description = getRichDescription(`1. item 1\n2. item 2\n3. item 3\n')
 * console.log(description);
 * // <ol><li>item 1</li><li>item 2</li><li>item 3</li></ol>
 */

const bullet = '[-–\\+]';
const number = '(([0-9]+)[-–\\/\\)\\.])';
const rxItem = new RegExp(`^\\s*(${bullet}|${number}) (.*)$`, 'gm');
const rxNumItem = new RegExp(`^\\s*${number} (.*)$`, 'gm');

export const isListItem = (text: string): boolean => text?.match(rxItem) !== null;
export const isListItemNumeric = (text: string): boolean => text?.match(rxNumItem) !== null;

export const getList = (lines: string[], startIndex: number): string[] => {
  let i = startIndex;
  let line = lines[i];
  const tag = isListItemNumeric(line) ? 'ol' : 'ul';
  const output = [`<${tag}>`];
  while (line && isListItem(line)) {
    const attr = isListItemNumeric(line) ? ' data-bullet="$3"' : '';
    output.push(line.replace(rxItem, `<li${attr}>$4</li>`));
    line = lines[++i];
  }
  output.push(`</${tag}>`);
  return output;
};

export const getRichDescription = (text: string): string => {
  const lines = text.split('\n');
  const chunks = [];
  let linesToIgnore = 0;

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (!linesToIgnore && isListItem(line)) {
      const list = getList(lines, i);
      linesToIgnore = list.length - 2;
      chunks.push(list.join(''));
    } else {
      if (linesToIgnore === 0) {
        chunks.push(line);
      } else {
        linesToIgnore--;
      }
    }
  }

  // nl2br
  text = chunks.join('\n');
  text = text.trim();
  text = text.replaceAll(/\n/g, '<br/>');

  // sanitize
  text = sanitizeHtml(text, {
    allowedTags: ['br', 'ol', 'ul', 'li'],
    allowedAttributes: { li: ['data-bullet'] },
  });
  return text;
};

export const makeList = (textarea: HTMLTextAreaElement, numbered = false): void => {
  const { value: text } = textarea;
  const start = getLineStartAt(text, textarea.selectionStart);
  const end = getLineEndAt(text, textarea.selectionEnd);
  const extract = text.substring(start, end);
  const extractLines = extract.split('\n');
  const newExtract = extractLines
    .map((line, i) => {
      const shouldAddBullet = !isListItem(line);
      const bullet = numbered ? `${i + 1}.` : '-';
      return shouldAddBullet ? `${bullet} ${line}` : line;
    })
    .join('\n');

  const newText = text.substring(0, start) + newExtract + text.substring(end);
  const endOfLine = start + newExtract.length;
  textarea.value = newText;
  nextTick(() => {
    textarea.focus();
    textarea.selectionStart = endOfLine;
    textarea.selectionEnd = endOfLine;
  });
};

export const persistList = (textarea: HTMLTextAreaElement): void => {
  const { value, selectionStart } = textarea;
  const previousLine = getPreviousLineAt(value, selectionStart - 1);
  if (isListItem(previousLine)) {
    let bullet = '- ';
    if (isListItemNumeric(previousLine)) {
      const matches = previousLine.match(/^\n?(\d+)\..*$/);
      const number = matches && matches[1] ? parseInt(matches[1]) + 1 : 1;
      bullet = `${number}. `;
    }
    textarea.value = value.slice(0, selectionStart) + bullet + value.slice(selectionStart);
    const at = selectionStart + bullet.length;
    textarea.selectionStart = at;
    textarea.selectionEnd = at;
  }
};

export const getLineStartAt = (text: string, at: number): number => text.lastIndexOf('\n', at - 1) + 1;
export const getLineEndAt = (text: string, at: number): number => {
  const index = text.indexOf('\n', at);
  return index > -1 ? index : text.length;
};
export const getPreviousLineAt = (text: string, at: number): string => {
  let previousLineStart = text.lastIndexOf('\n', at - 1);
  if (previousLineStart === -1) {
    previousLineStart = 0;
  }
  let previousLineEnd = text.indexOf('\n', at - 1);
  if (previousLineEnd === -1) {
    previousLineEnd = text.length;
  }
  return text.substring(previousLineStart, previousLineEnd);
};
