import AnalyticsEvent from './analytics-event';
import { Product, Wishlist } from './segment-ecommerce-types';

export class ProductRemovedFromWishlistEvent extends AnalyticsEvent {
  public readonly name = 'Product Removed from Wishlist';

  constructor(public readonly properties: Wishlist & Product) {
    super();
  }
}
