<template>
  <div
    v-if="hasPendingActions(categories)"
    class="ak-badge"
    :class="{ 'ak-badge--global': categories.length === 0, 'ak-badge--top-left': isOnCornerLeft, 'ak-badge--absolute': absolute }"
  ></div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BadgePendingActions',
  props: {
    categories: {
      required: false,
      type: Array,
      default: () => [],
    },
    isOnCornerLeft: {
      required: false,
      default: false,
      type: Boolean,
    },
    absolute: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(['hasPendingActions']),
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.ak-badge {
  @apply ds-bg-warning ds-font-basic ds-text-white;
  font-size: 9px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  opacity: 0.9;
  height: 6px;
  width: 6px;
  &--absolute {
    transform: translateY(-50%);
    position: absolute;
    left: -8px;
    top: 50%;
  }
  &--global {
    z-index: 1;
    top: 2px;
    left: 3px;
    @media screen and (min-width: 1200px) {
      top: -6px;
      left: -7px;
    }
  }
  &--top-left {
    top: -6px;
    width: 15px;
    height: 15px;
    transform: none;
    @apply ds-border ds-border-solid ds-border-white;
  }
}
</style>
