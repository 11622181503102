/* eslint-disable @typescript-eslint/no-explicit-any */
import http from '@/services/api/http';
import i18n from '@/services/i18n';
import { Question, QuestionResponse } from '@/types/question';

export interface QualificationQuestionsPatchParams {
  responses: QuestionResponse[];
}

export interface QualificationQuestionModel {
  [key: string]: {
    content?: string;
    answer_id?: number;
  };
}

export const patchQualificationQuestionsFromModel = async (step: number, modal: QualificationQuestionModel): Promise<any> => {
  return patchQualificationQuestions(step, conversionFromModelToParams(modal));
};

export const getQualificationQuestions = async (step: number): Promise<{ data: Question[] }> => {
  return http()
    .get(`/api/qualification/step/${step}/v2`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.data && error.response.data.errors) {
        throw error.response.data.errors;
      } else {
        throw {
          global: i18n.global.t('An error occurred. Please retry.'),
        };
      }
    });
};

export const patchQualificationQuestions = async (step: number, params: QualificationQuestionsPatchParams): Promise<any> => {
  if (!params.responses.length) {
    return Promise.resolve({ data: null });
  }

  return http()
    .patch(`/api/me/retail/qualification/step/${step}`, params)
    .catch((error) => {
      if (error.response && error.response.data && error.response.data.errors) {
        if (error.response.data.message) {
          throw {
            global: error.response.data.message,
          };
        } else {
          throw error.response.data.errors;
        }
      } else {
        throw {
          global: i18n.global.t('An error occurred. Please retry.'),
        };
      }
    });
};

const conversionFromModelToParams = (model: any): QualificationQuestionsPatchParams => {
  const params = {
    responses: [],
  };

  for (const alias in model) {
    if (model[alias]) {
      params.responses.push({
        answers: (Array.isArray(model[alias]) ? model[alias] : [model[alias]]).map((answer) => {
          const mappedAnswer = {};
          if (answer.answer_id) {
            mappedAnswer['answer_id'] = answer.answer_id;
          }
          if (answer.content) {
            mappedAnswer['content'] = answer.content;
          }
          return mappedAnswer;
        }),
      });
    }
  }

  return params;
};
