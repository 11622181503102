import { computed, Ref } from 'vue';

export const useLocalSearch = <T extends { id: string | number }>(
  items: Ref<T[]>,
  search: Ref<string>,
  createSearchText: (item: T) => string
) => {
  return computed(() => {
    const searchTerm = search.value;
    if (!searchTerm) {
      return items.value;
    }

    const searchTerms = searchTerm.toLowerCase().split(' ');

    return items.value.filter((item) => {
      const searchText = createSearchText(item).toLowerCase();
      return searchTerms.some((term) => searchText.includes(term));
    });
  });
};
