<template>
  <div class="questions">
    <div
      v-if="isMobile || collapser"
      class="questions__section"
      :class="collapser ? 'questions__section--flat' : ''"
    >
      <AkExpander
        v-for="(question, i) in content.questions"
        :key="i"
        class="question question--bordered"
        :class="context === 'home' ? 'question--home' : ''"
        data-testid="faqMobileContent"
        @click="clickEvent"
      >
        <template #header>
          <AkParagraph
            v-if="collapser && size !== 'large'"
            class="question__title"
            :size="size"
          >
            {{ question.question }}
          </AkParagraph>
          <AkAccountHeading3
            v-else
            class="question__title"
          >
            {{ question.question }}
          </AkAccountHeading3>
        </template>
        <template #text>
          <prismic-rich-text
            :field="question.answer"
            class="question__description"
            :class="'question__description--' + size"
          />
        </template>
      </AkExpander>
    </div>
    <div
      v-else
      class="questions__section"
    >
      <div
        v-for="(question, i) in content.questions"
        :key="i"
        class="question"
      >
        <AkAccountHeading4 class="question__title">
          {{ question.question }}
        </AkAccountHeading4>
        <prismic-rich-text
          :field="question.answer"
          class="question__description"
          :class="'question__description--' + size"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AkAccountHeading3, AkAccountHeading4 } from '@ankorstore/design-system';
import AkExpander from '@/components/ak-expander.vue';
import { useBreakpoints } from '@/modules/design-system-candidates';
import { FaqInterface } from '@/types/faq';

export default defineComponent({
  name: 'FaqContent',
  components: {
    AkAccountHeading3,
    AkAccountHeading4,
    AkExpander,
  },
  props: {
    content: {
      type: Object as PropType<FaqInterface>,
      required: true,
    },
    collapser: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String as () => 'small' | 'base' | 'large',
      required: false,
      default: 'base',
    },
    context: {
      type: String as () => 'home' | 'default',
      required: false,
      default: 'default',
    },
  },
  emits: ['click-event'],
  setup(_, { emit }) {
    const { isMobile } = useBreakpoints();

    function clickEvent(): void {
      emit('click-event');
    }
    return {
      clickEvent,
      isMobile,
    };
  },
});
</script>
<style lang="scss" scoped>
.questions {
  @apply ds-mb-5;
  &:last-of-type {
    @apply ds-mb-8;
  }
  &__section {
    @apply ds-grid md:ds-grid-cols-2 ds-gap-2 md:ds-gap-6 ds-pt-4;
    &--flat {
      @apply ds-grid-cols-1 ds-gap-0;
    }
  }
}

.question {
  @apply ds-mb-4;
  &--bordered {
    @apply ds-border-t ds-border-solid ds-border-neutral-300 ds-pt-5;
  }
  &--home {
    &.question--bordered {
      @apply ds-border-neutral-500;
    }
  }
  &__title {
    @apply ds-mb-2 ds-text-left;
  }
  &__description {
    @apply ds-text-lg ds-text-neutral-700;
  }
}
</style>
<style lang="scss">
.question {
  &__description {
    &--base {
      p,
      li {
        @apply ds-text-base;
      }
    }
    &--small {
      p,
      li {
        @apply ds-text-sm;
      }
    }
    p {
      @apply ds-mb-2;
    }
    ul {
      @apply ds-pl-4 ds-my-2;
      li {
        @apply ds-list-disc;
      }
    }
  }
}
</style>
