<template>
  <AkAlert
    v-if="isDiscountInfoVisible"
    :title="title"
    :hide-icon="true"
    type="discount"
    class="discount-info"
    data-testid="discountInfo"
  >
    <div class="discount-info__content">
      <div class="discount-info__icon">
        <AkIcon
          symbol="patch-check"
          size="md"
        />
      </div>
      <AkParagraph
        v-if="brandDiscountTextWithAmount"
        size="sm"
      >
        {{ brandDiscountTextWithAmount }}
      </AkParagraph>
    </div>

    <div
      v-if="!!ankorstorePlusDiscountText"
      class="discount-info__content"
    >
      <div class="discount-info__icon">
        <AnkorstorePlusBadge />
      </div>
      <AkParagraph
        size="sm"
        data-testid="aks-discount-text"
      >
        {{ ankorstorePlusDiscountText }}
      </AkParagraph>
    </div>
  </AkAlert>
</template>

<script lang="ts">
import { AkAlert, AkIcon, AkParagraph } from '@ankorstore/design-system';
import { prismic } from '@/mixins/prismic';
import { defineComponent, PropType } from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { IBrandPageBrand } from '@/components/brand/brand.vue';
import AnkorstorePlusBadge from '@/modules/promote-engage/subscription-program/UI/ankorstore-plus-badge.vue';

export default defineComponent({
  name: 'DiscountInfo',
  components: { AkAlert, AkIcon, AkParagraph, AnkorstorePlusBadge },
  mixins: [prismic],
  props: {
    brand: { type: Object as PropType<IBrandPageBrand>, default: null },
  },
  computed: {
    ...mapState('discountInfo', ['brandPageDiscountContent']),
    ...mapGetters('offers', ['ankorstorePlus']),
    haveBrandDiscount(): boolean {
      return !!this.brand?.event_special_discount;
    },
    brandDiscountTextWithAmount(): string {
      if (this.brandPageDiscountContent.brand_discount_text && this.haveBrandDiscount) {
        return this.brandPageDiscountContent.brand_discount_text
          .replace('{brand_name}', this.brand.name)
          .replace('{brand_discount}', `${this.brand.event_special_discount}`);
      }

      return null;
    },
    ankorstorePlusDiscountText(): string | null {
      if (this.brandPageDiscountContent?.ankorstore_discount_text && !!this.ankorstorePlus) {
        return this.brandPageDiscountContent?.ankorstore_discount_text;
      }

      return null;
    },
    isDiscountInfoEnabled(): boolean {
      return this.haveBrandDiscount;
    },
    isDiscountInfoVisible(): boolean {
      return !!(this.isDiscountInfoEnabled && this.brandPageDiscountContent);
    },
    title(): string {
      return this.brandPageDiscountContent?.discount_title;
    },
  },
  watch: {
    isDiscountInfoEnabled: {
      immediate: true,
      async handler(newValue) {
        if (newValue && !this.brandPageDiscountContent) {
          const { data } = await this.getContentByUid('brand_page_discount', 'brand-page-discounts', this.$lang);
          this.setBrandPageDiscountContent(data);
        }
      },
    },
  },
  methods: {
    ...mapActions('discountInfo', ['setBrandPageDiscountContent']),
  },
});
</script>

<style lang="scss" scoped>
.discount-info {
  @apply ds-w-full ds-rounded-md ds-p-3 ds-pl-4;
  &__content {
    @apply ds-flex ds-flex-row ds-items-start ds-gap-x-2 ds-mb-2;
  }
  i {
    @apply ds-leading-5;
    font-size: 1.25rem;
  }
}
</style>
