<!-- eslint-disable @intlify/vue-i18n/no-dynamic-keys -->
<template>
  <div>
    <div v-if="!isRevamp">
      <div class="form-group">
        <select
          :id="question.alias"
          v-model="currentValue.answer_id"
          :name="question.alias"
          class="form-control"
          :class="[answerRequiresContent ? '' : vuelidateFieldClassName(state[question.alias])]"
          data-testid="qualification-question-select"
          @change="(e) => onSelectChange(+e.target.value)"
        >
          <option
            selected
            disabled
            hidden
            :value="null"
          >
            {{ $t('Select an option') }}
          </option>
          <option
            v-for="answer in question.answers"
            :key="answer.id"
            data-testid="qualification-question-option"
            :value="answer.id"
          >
            {{ answer.label }}
          </option>
        </select>
      </div>
      <span
        v-if="
          state[question.alias] &&
            state[question.alias].$dirty &&
            state[question.alias].$invalid &&
            state[question.alias].required.$invalid
        "
        class="error-message"
        data-testid="questionSelectError"
      >{{ $t('This field is required') }}</span>
    </div>
    <div v-else>
      <AkAutocomplete
        v-model="currentValue.answer_id"
        :options="options"
        :placeholder="$t('Select an option')"
        data-testid="qualification-question-select"
        :has-error="state[question.alias].$error"
        :errors="getErrors"
        :reset-btn-visible="false"
        @change="onSelectChange"
      />
    </div>
    <div
      v-if="!isRevamp && answerRequiresContent"
      class="form-group form-vertical"
    >
      <input
        v-model="extraContent"
        data-testid="qualification-question-extra"
        :name="`${question.alias}-${currentValue.answer_id}`"
        :class="['form-control', vuelidateFieldClassName(state[`${question.alias}-${currentValue.answer_id}`])]"
        :placeholder="$t('Specify...')"
        type="text"
        @change="onContentChange"
      />
      <span
        v-if="hasErrorExtra"
        class="error-message"
        data-testid="qualification-question-error"
      >{{ $t('This field is required') }}</span>
    </div>
    <div v-else-if="answerRequiresContent">
      <AkInput
        v-model="extraContent"
        class="ds-mt-5"
        data-testid="qualification-question-extra"
        :name="`${question.alias}-${currentValue.answer_id}`"
        :has-error="hasErrorExtra"
        :errors="getErrors"
        :placeholder="$t('Specify...')"
        type="text"
        @change="onContentChange"
      />
    </div>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { defineComponent } from 'vue';
import { vuelidateFieldClassName } from '@/utilities/fieldClassName';
import { Answer, Question } from '@/types/question';
import { State } from '@/types/state';
import useVuelidate from '@vuelidate/core';
import { extraContentAnswers } from '@/components/sign-up/questions/constants';
import { AkInput, AkAutocomplete } from '@ankorstore/design-system';

export default defineComponent({
  name: 'QuestionSelect',
  components: {
    AkInput,
    AkAutocomplete,
  },
  props: {
    question: {
      type: Object as () => Question,
      required: true,
    },
    state: {
      type: Object as () => State,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    isRevamp: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['change'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      currentAnswer: { id: null } as Answer,
      extraContent: '',
      currentValue: this.model,
    };
  },
  computed: {
    getErrors() {
      return this.state[this.question.alias].$error ? this.$t('This field is required') : [];
    },
    hasErrorExtra(): boolean {
      return (
        this.state[this.question.alias] &&
        this.state[this.question.alias].$dirty &&
        this.state[this.question.alias].extraContentRequired === false
      );
    },
    answerRequiresContent(): boolean {
      return extraContentAnswers.includes(this.currentAnswer?.answer_type);
    },
    options() {
      return this.question.answers.map(({ id, label }) => {
        return {
          value: id,
          label,
        };
      });
    },
  },
  watch: {
    model() {
      this.currentValue = this.model;
    },
    currentValue({ answer_id }) {
      const answer = this.question?.answers.find((item) => item.id === answer_id);
      if (answer) {
        this.currentAnswer = {
          id: answer_id,
          label: answer.label,
          answer_type: answer.answer_type,
          alias: this.question.alias,
          prefilled_content: answer.prefilled_content,
        };
      }
    },
  },
  methods: {
    onSelectChange(id: number, content?: string): void {
      this.extraContent = content || '';
      this.$emit('change', this.question.alias, {
        answer_id: id,
        content: this.extraContent,
      });
    },
    onContentChange({ target }): void {
      this.extraContent = target.value;
      this.onSelectChange(this.currentValue.answer_id, this.extraContent);
    },
    vuelidateFieldClassName,
  },
});
</script>
<style scoped lang="scss">
.form-group {
  @apply ds-mb-1;
  select {
    z-index: 2;
    position: relative;
    background-color: transparent;
    font-size: 14px;
    height: 44px;
  }

  input {
    @apply ds-h-6;
  }
}

.form-control.is-invalid {
  background-position: right calc(0.375em + 1rem) center;
}
</style>
