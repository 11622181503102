import http from '@/services/api/http';
import i18n from '@/services/i18n';
import { RetailerReview } from '@/types/retailer-review';
import { CountryIsoCode } from '@/types/country';

export const getRetailerReviews = async (isoCode?: CountryIsoCode): Promise<RetailerReview[]> => {
  const params = isoCode
    ? {
        'filter[country][isoCode]': [isoCode],
      }
    : null;
  return http()
    .get('/api/internal/v1/testimonials', {
      params,
    })
    .then(({ data }) => {
      return data?.data;
    })
    .catch((error) => {
      if (error?.response?.data?.errors) {
        throw error.response.data.errors;
      } else {
        throw {
          global: i18n.global.t('An error occurred. Please retry.'),
        };
      }
    });
};
