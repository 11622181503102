/**
 * NOTE: This module is going to be deprecated soon in favor of the new carts and checkout modules.
 * @deprecated
 */
import { TaxNumber } from '@/types/tax-number';
import { Cart, CartTracking, Checkout, NeededInformations } from '@/types/cart';
import actions from '@/store/cart/actions';
import mutations from '@/store/cart/mutations';
import getters from '@/store/cart/getters';
import { BulkUpdate } from '@/services/api/cart';
import { BrandDiscount } from '@/types/api/brand-discount';
import { getCurrency } from '@/services/metas/currency';

export interface CartState {
  apiCallCount: number;
  bulkUpdates: BulkUpdate[];
  brandsDiscounts: {
    [index: string]: BrandDiscount;
  };
  businessValidationStep: NeededInformations;
  checkout: Checkout;
  carts?: Cart[];
  checkoutFormErrors: {
    recargo: string[];
    taxNumber: string[];
    companyId: boolean;
    eori: string[];
  };
  checkoutFormSuccess: {
    eori: boolean;
  };
  checkoutStartLoadingTime?: number;
  eori: string;
  hokodoCompany: string | null;
  isCartButtonLoading: boolean;
  isCartLoading: boolean;
  isHokodoLoading: boolean;
  isTotalsLoading: boolean;
  itemsCounts: {
    main: number;
    savedForLater: number;
  };
  isSavedForLaterDisplayed?: boolean;
  lastPromocodeCallResult: {
    code: string;
    errorMessage: string;
  };
  recargoEnabled: boolean;
  savedForLater?: Cart[];
  taxNumber: TaxNumber;
  tracking: CartTracking;
}

const createInitialState = () => {
  const initialState: CartState = {
    checkout: {
      type: 'checkout',
      id: null,
      status: 'unsubmitted',
      billing_items: [],
      retailer_grand_total_amount_with_vat: {
        amount: 0,
        currency: getCurrency(),
      },
      total_amount_with_vat: {
        amount: 0,
        currency: getCurrency(),
      },
      total_amount_vat: {
        amount: 0,
        currency: getCurrency(),
      },
      total_amount: { amount: 0, currency: getCurrency() },
      free_shipping_minimum_amount: {
        amount: 30000,
        currency: getCurrency(),
      },
      amount_left_for_free_shipping: {
        amount: 30000,
        currency: getCurrency(),
      },
      free_shipping: false,
      regular_shipping_fees_amount: {
        amount: 2000,
        currency: getCurrency(),
      },
      carts: [],
      firstInit: true,
      is_first_checkout: false,
      extra_taxes: {
        recargo_de_equivalencia: null,
      },
    },
    apiCallCount: 0,
    tracking: {
      minimumReachedEventsSent: [],
    },
    businessValidationStep: NeededInformations.None,
    taxNumber: {
      type: null,
      value: '',
      isSkipped: false,
      isValid: false,
    },
    isHokodoLoading: false,
    hokodoCompany: null,
    isCartButtonLoading: false,
    checkoutFormErrors: {
      recargo: [],
      taxNumber: [],
      companyId: false,
      eori: [],
    },
    checkoutFormSuccess: {
      eori: false,
    },
    isCartLoading: false,
    bulkUpdates: [],
    isTotalsLoading: true,
    recargoEnabled: null,
    eori: '',
    lastPromocodeCallResult: {
      code: '',
      errorMessage: '',
    },
    checkoutStartLoadingTime: 0,
    brandsDiscounts: {},
    itemsCounts: {
      main: 0,
      savedForLater: 0,
    },
    savedForLater: [],
    isSavedForLaterDisplayed: false,
  };

  return initialState;
};

export default {
  namespaced: true,
  state: createInitialState(),
  actions,
  mutations,
  getters,
};
