import { Currency } from 'dinero.js';

export enum InvoiceStatus {
  PENDING = 'payment pending',
  FAILED = 'payment failed',
  PAID = 'paid',
  PRO_FORMA = 'pro_forma',
  PROCESSING = 'payment_processing',
}

export enum BillingTabs {
  list = 'list',
  setup = 'setup',
}

export enum ServiceType {
  FULFILLMENT = 'fulfillment',
  ADS = 'ads',
}

export type ApiServiceInvoice = {
  id: string;
  invoiceNumber: string;
  invoicedAt: Date;
  totalAmount: number;
  totalVatAmount: number;
  totalAmountWithVat: number;
  currency: Currency;
  status: string;
  content: string;
  /**
   * The invoice period in the format 'MM-YYYY'
   */
  invoicePeriod: string;
  serviceType: ServiceType;
};

export type FulfillmentInvoice = {
  name: string;
  id: string;
  issuedOn: Date;
  status: InvoiceStatus;
  total: number;
  currency: Currency;
  // base64 encoded pdf
  content: string;
  serviceType: string;
};

export type PaymentServiceInitPayload = Record<string, never>;

export type SEPAMandateInfo = {
  /**
   * Name on account
   */
  name: string;
  /**
   * Email Address
   */
  email: string;
  /**
   * IBAN Number
   */
  IBAN: string;
};

export enum PaymentServiceMandateStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  PROCESSING = 'processing',
}

export enum PaymentServiceMandateType {
  MULTI_USER = 'multi_use',
  SINGLE_USE = 'single_use',
}

export enum PaymentServiceCustomerType {
  BRAND = 'brand',
  RETAILER = 'retailer',
}

export type PaymentServiceProviderCustomer = {
  aksCustomerId: string;
  aksCustomerType: PaymentServiceCustomerType;
  customerId: string;
  setupIntentId: string;
  paymentServiceProvider: string;
  email: string;
  description: string;
  name: string;
  mandateType: PaymentServiceMandateType;
  mandateStatus: PaymentServiceMandateStatus;
};

export type SetupIntent = {
  clientSecret: string;
  ibanLast4: string;
};
