import http from '@/services/api/http';
export default class BrandTracking {
  public static track(brandId: number, productId?: number) {
    let body = {};
    if (productId) {
      body = {
        product_id: productId,
      };
    }

    return http()
      .post(`/api/brands/${brandId}/tracking`, body)
      .catch(() => {
        // fail silently
      });
  }
}
