<template>
  <ais-current-refinements
    v-if="transformCheckboxItemsFns.size > 0"
    :transform-items="transformRefinementItems"
  >
    <template #default="{ items }">
      <QuickFilters :filters="items">
        <template #refinement="{ filter }">
          <CustomAisCheckbox
            :attribute="filter.name"
            :sort-by="defaultSort"
            :transform-items="transformCheckboxItemsFns.get(filter.name)"
            :limit="6"
            :filter="filter"
          />
        </template>
        <template #priceRange="{ filter }">
          <CustomAisPriceRangeFilter :filter="filter" />
        </template>
      </QuickFilters>
    </template>
  </ais-current-refinements>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { AisCurrentRefinements } from 'vue-instantsearch/vue3/es';
import { QuickFilters } from '@bc/discovery/ui/filters';
import CustomAisCheckbox from './custom-ais-checkbox/custom-ais-checkbox.vue';
import CustomAisPriceRangeFilter from './custom-ais-price-range-filter.vue';
import { Facet } from '@bc/discovery/domain/catalog';
import type { Item } from '@bc/discovery/ui/filters';
import type { Refinement } from '../types/refinement';
import type { LabelOverrides } from '../types/label-overrides';
import { createFilters } from '../utils/create-filters';
import { overrideFilterLabels } from '../utils/override-labels';

const props = withDefaults(
  defineProps<{
    facets: Facet[];
    labelOverrides: LabelOverrides;
  }>(),
  {
    labelOverrides: () => ({}),
  }
);

// A map of facet names to functions that transform the items for that facet.
// This is required for AIS to avoid reactivity issues.
type FacetName = string;
const transformCheckboxItemsFns = ref<Map<FacetName, (items: Item[]) => Item[]>>(new Map());

const createTransformCheckboxItems = (facet: Facet) => (items: Item[]) =>
  overrideFilterLabels(facet, props.labelOverrides, items);

watchEffect(() => {
  transformCheckboxItemsFns.value = new Map(props.facets.map((facet) => [facet.name, createTransformCheckboxItems(facet)]));
});

const transformRefinementItems = (items: Refinement[]) => createFilters(props.facets, items);
const defaultSort = ['name:asc', 'count:desc'];
</script>
