<template>
  <AkSteps
    :steps="steps"
    :current-step="currentStep"
  />
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';
import { AkSteps } from '@ankorstore/design-system';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Stepper',
  components: {
    AkSteps,
  },
  props: {
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const steps = computed(() => [
      {
        title: t('registerForm.step.1.title'),
        completed: props.currentStep > 1,
      },
      {
        title: t('registerForm.step.2.title'),
        completed: props.currentStep > 2,
      },
      {
        title: t('registerForm.step.3.title'),
        completed: props.currentStep > 3,
      },
      {
        title: t('registerForm.step.4.title'),
        completed: props.currentStep > 4,
      },
    ]);
    return {
      steps,
    };
  },
});
</script>
