export enum OriginProperty {
  BrandPage = 'brand-page',
  AccountLift = 'account-lift',
  OrderPage = 'orders',
  Network = 'network',
  OrderDetailPage = 'order-details',
  ProductPage = 'product',
  SignUp = 'sign-up',
  Messages = 'Messages Page',
  Reviews = 'Reviews Brand',
  PreparedOrderPage = 'prepared-order',
  LiftPage = 'Lift Page',
  HomePage = 'Home Page',
  HomePageV2 = 'Home Page V2',
}
