<template>
  <div class="modal-error">
    <div class="header">
      <p class="title">
        <span class="title__icon">
          <AkIcon
            symbol="exclamation-triangle"
            size="md"
          />
        </span>
        {{ title ? title : $t('Sorry...') }}
      </p>
      <p class="sub-title">
        {{ message }}
      </p>
    </div>

    <div class="content">
      <button
        class="button button-black"
        type="button"
        @click="closeModal()"
      >
        {{ $t('Close') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import { AkIcon } from '@ankorstore/design-system';

export default defineComponent({
  name: 'ModalErrorPopin',
  components: {
    AkIcon,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
  emits: ['close'],
  computed: {
    ...mapGetters(['retailer']),
  },
  methods: {
    closeModal(): void {
      this.$emit('close');
    },
  },
});
</script>
<style scoped lang="scss">
@import '@css/vue-import';

.title {
  @apply ds-flex ds-flex-col;
}
</style>
