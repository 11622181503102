import AnalyticsEvent from './analytics-event';
import { CartLoading } from './segment-ecommerce-types';

export class CartViewedEvent2 extends AnalyticsEvent {
  public readonly name = 'Cart Viewed';

  constructor(public readonly properties: CartLoading) {
    super();
  }
}
