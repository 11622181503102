<template>
  <div
    class="ak-badge ak-badge--aks"
    :class="classes"
  >
    <img
      class="image"
      src="/images/logo/ankorstore-plus.svg"
      :alt="$t('ankorstorePlus.program.name')"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'DiscountAkplusBadge',
  props: {
    size: {
      type: String,
      default: 'xs',
      validator: function (value: string) {
        return ['xs', 'sm', 'base'].indexOf(value) !== -1;
      },
    },
  },
  setup(props) {
    const classes = computed(() => {
      return {
        [`ak-badge--${props.size}`]: true,
      };
    });

    return {
      classes,
    };
  },
});
</script>
<style lang="scss" scoped>
.ak-badge {
  @apply ds-flex ds-items-center;
  &--aks {
    background-color: rgb(213, 252, 82);
  }
  &--xs {
    @apply ds-h-5 ds-px-2 ds-rounded-sm;
    .image {
      @apply ds-h-2;
    }
  }
  &--sm {
    @apply ds-h-6 ds-px-3 ds-rounded-md;
    .image {
      @apply ds-h-3;
    }
  }
  &--base {
    height: 40px;
    padding: 14px;
    .image {
      @apply ds-h-3;
    }
  }
  .image {
    @apply ds-block;
  }
}
</style>
