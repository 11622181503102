<script setup lang="ts">
import { computed } from 'vue';
import { Cart } from '@/types/cart';
import { useStore } from '@/composables/use-store';
import BrandItem from '@/components/cart/floating-cart/brand-item.vue';
import { formatPrice } from '@/utilities/price';

const props = defineProps<{
  cart: Cart;
  isSelected: boolean;
}>();

const emit = defineEmits(['changeCurrentBrand']);

const store = useStore();

const retailerShippingFees = computed(() => store.getters['cart/retailerShippingFees'](props.cart));
const amountLeftForFreeShipping = computed(() => {
  if (retailerShippingFees.value?.franco_setting?.franco_amount?.amount > 0) {
    const amountLeft = retailerShippingFees.value?.franco_setting?.franco_amount?.amount - props.cart?.total_amount?.amount;
    if (amountLeft > 0) {
      return {
        amount: amountLeft,
        currency: retailerShippingFees.value?.franco_setting?.franco_amount?.currency,
      };
    }
    return null;
  }
  return null;
});
const shouldApplyCountryRestrictions = computed(() => store.getters.shouldApplyCountryRestrictions as boolean);
const userCountry = computed(() => store.getters.userCountry as string);

const changeCurrentBrand = (brand) => {
  emit('changeCurrentBrand', brand);
};
</script>

<template>
  <template v-if="isSelected">
    <BrandItem
      :cart="cart"
      :user-country="userCountry"
      :should-apply-country-restrictions="shouldApplyCountryRestrictions"
    />
  </template>
  <template v-else>
    <router-link
      class="brand-link"
      event=""
      :to="cart.brand.link"
      @click.prevent="changeCurrentBrand(cart.brand)"
    >
      <BrandItem
        :cart="cart"
        :user-country="userCountry"
        :should-apply-country-restrictions="shouldApplyCountryRestrictions"
      />
    </router-link>
  </template>
  <div
    v-if="!!cart && !!retailerShippingFees"
    data-testid="shippingFeeMessage"
    class="shipping-fee-message"
  >
    <AkParagraph
      v-if="!!amountLeftForFreeShipping"
      data-testid="shippingFeeUnlockMessage"
      size="xs"
    >
      {{
        $t('retailer.floatingCart.shippingFee.unlock.lbl', {
          amount: formatPrice(amountLeftForFreeShipping),
        })
      }}
    </AkParagraph>
    <AkParagraph
      v-else
      data-testid="shippingFeeApplicableMessage"
      size="xs"
    >
      {{ $t('retailer.floatingCart.shippingFee.applicable.lbl') }}
    </AkParagraph>
  </div>
</template>

<style scoped lang="scss">
.shipping-fee-message {
  @apply ds-text-info ds-text-xs ds-mb-2 ds-pl-7;
}
</style>
