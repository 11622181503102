export interface LiftPageProperty {
  type: string;
  brand_id?: string;
}

export function createLiftPageProperty({ type, brand_id }: LiftPageProperty): LiftPageProperty {
  return {
    type,
    brand_id,
  };
}
