import { computed, Ref, ref, watchEffect } from 'vue';
import { getCatalogConfig } from '../services/catalog-config/catalog-config-service';
import { Context, type Config } from '@bc/discovery/domain/catalog';
import getIndexName, { IndexName } from '@/services/analytics/algolia/index-name';
import type { AkLocale } from '@/types/base';

type UseCatalogContentParams = {
  context: Ref<Context>;
  selectedCategoryId: Ref<number>;
  rootCategoryId?: Ref<number>;
  lang: Ref<AkLocale>;
};

export const useCatalogContent = ({ context, selectedCategoryId, lang, rootCategoryId }: UseCatalogContentParams) => {
  const indexName = computed(() => {
    const indexMap = {
      [Context.SEARCH]: getIndexName(IndexName.Products),
      [Context.CATEGORY_PRODUCTS]: getIndexName(IndexName.Products),
      [Context.CATEGORY_BRANDS]: rootCategoryId?.value
        ? getIndexName(IndexName.BrandsSortByCategoryRanking, rootCategoryId.value)
        : getIndexName(IndexName.Brands),
      [Context.NEW_PRODUCTS]: getIndexName(IndexName.Products),
      [Context.NEW_BRANDS]: getIndexName(IndexName.Brands),
      [Context.PREORDER_PRODUCTS]: getIndexName(IndexName.Products),
      [Context.PREORDER_BRANDS]: getIndexName(IndexName.Brands),
      [Context.WHOLESALE]: getIndexName(IndexName.Products),
    };

    return indexMap[context.value];
  });

  const config = ref<Config>();

  watchEffect(async () => {
    config.value = await getCatalogConfig(context.value, selectedCategoryId.value, lang.value);
  });

  return {
    indexName,
    config,
  };
};
