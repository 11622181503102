<template>
  <input
    id="wishlist-input-editor"
    ref="wishlistInputEditor"
    v-model="inputValue"
    maxlength="40"
    :required="true"
    class="input-create-list"
    data-testid="wishlistListsListTitleInput"
    @keyup.enter="setTitle($event)"
  />
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'WishlistListEditor',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['set-title'],
  setup(props, { emit }) {
    const inputValue = ref(props.title);

    const wishlistInputEditor = ref(null);

    function setTitle(event) {
      emit('set-title', event);
    }

    onMounted(() => {
      wishlistInputEditor.value.focus();
    });

    return {
      inputValue,
      setTitle,
      wishlistInputEditor,
    };
  },
});
</script>
