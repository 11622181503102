import AnalyticsEvent from './analytics-event';
import { Product } from './segment-ecommerce-types';

export class ProductListViewedEvent extends AnalyticsEvent {
  public readonly name = 'Product List Viewed';

  constructor(
    public readonly properties: {
      list_id: string;
      category: string;
      products: Product[];
    }
  ) {
    super();
  }
}
