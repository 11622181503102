import { saveStorepage } from '@/services/api/me';
import { Brand } from '@/types/api/brand';

export default {
  namespaced: true,
  state: {
    cancelEditStorepageTrigger: null,
  },
  mutations: {
    SET_TRIGGER(state) {
      // setting a timestamp to trigger the watch inside components
      state.cancelEditStorepageTrigger = Date.now();
    },
  },
  actions: {
    storepageSaveRequested(): void {},
    cancelEditStorepage({ commit }): void {
      commit('SET_TRIGGER');
    },
    async saveStorepage({ commit }, { image_squared, image_rounded, image_large, story }) {
      const data = {
        image_squared,
        image_rounded,
        image_large,
        story,
      };
      return saveStorepage(data).then((brand: Brand) => {
        commit('REPLACE_BRAND', brand, { root: true });
        return brand;
      });
    },
  },
  getters: {},
};
