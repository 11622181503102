<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({ name: 'Expander' });
</script>
<script lang="ts" setup>
import { Ref, ref, computed, onMounted, onUnmounted } from 'vue';
import { AkButton } from '@ankorstore/design-system';
import { bottomPaddingCollapsed, bottomPaddingExpanded, roundingBuffer } from './constants';

const props = defineProps<{
  contractedVisibleBlocksCount?: number;
  initializeExpanded?: boolean;
}>();

const emit = defineEmits<{
  (e: 'toggled', isExpanded: boolean): void;
}>();

const contentContainer = ref<Ref<HTMLDivElement>>(undefined);
const isExpanded = ref(props.initializeExpanded ?? false);
const heightCollapsed = ref(0);
const heightExpanded = ref(0);
const resizeObserver = ref<ResizeObserver>(undefined);

const showExpandButton = computed(() => {
  const childrenCount = contentContainer.value?.children.length ?? 0;
  return childrenCount > props.contractedVisibleBlocksCount;
});

const toggleExpanded = () => {
  isExpanded.value = !isExpanded.value;
  emit('toggled', isExpanded.value);
};

const calculateSizes = () => {
  const contractedVisibleBlocksCount = props.contractedVisibleBlocksCount ?? 2;
  const childrenHeights = Array.from(contentContainer.value.children).map((childElement) => {
    if (childElement instanceof HTMLElement) {
      const style = window.getComputedStyle(childElement);
      const height = childElement.offsetHeight;
      const marginTop = parseInt(style.marginTop, 10);
      const marginBottom = parseInt(style.marginBottom, 10);
      return height + marginTop + marginBottom;
    }
    return 0;
  });

  heightCollapsed.value =
    // height of the initial blocks to show
    childrenHeights.slice(0, contractedVisibleBlocksCount).reduce((a, b) => a + b, 0) +
    // reserved space for the button (2rem, half height of the button container)
    (showExpandButton.value ? bottomPaddingCollapsed : 0) +
    // add two pixels as buffer for rounding errors
    roundingBuffer;

  heightExpanded.value =
    // total height of all blocks in the default slot
    childrenHeights.reduce((a, b) => a + b, 0) +
    // reserved space for the button (4rem, full height of the button container)
    (showExpandButton.value ? bottomPaddingExpanded : 0) +
    // add two pixels as buffer for rounding errors
    roundingBuffer;
};

onMounted(() => {
  resizeObserver.value = new ResizeObserver(calculateSizes);
  resizeObserver.value.observe(contentContainer.value);
});

onUnmounted(() => {
  resizeObserver.value.disconnect();
});

defineExpose({
  // We might need to re-call this from outside (e.g. when the breakpoint changes)
  calculateSizes,
  contentContainer,
});
</script>

<template>
  <div
    :class="{
      expander: true,
      'expander--expanded': isExpanded,
    }"
    :style="`
      --expander-height-collapsed: ${heightCollapsed}px;
      --expander-height-expanded: ${heightExpanded}px;
      --expander-padding-bottom-collapsed: ${bottomPaddingCollapsed}px;
      --expander-padding-bottom-expanded: ${bottomPaddingExpanded}px;
    `"
  >
    <div
      ref="contentContainer"
      data-testid="expander-content-container"
    >
      <slot></slot>
    </div>
    <div
      v-if="showExpandButton"
      class="expander__button-container"
      data-testid="expander-button-container"
    >
      <slot
        name="button"
        :toggle-expanded="toggleExpanded"
        :is-expanded="isExpanded"
      >
        <ak-button
          link
          type="button"
          @click="toggleExpanded"
        >
          <slot
            name="buttonContent"
            :is-expanded="isExpanded"
          ></slot>
        </ak-button>
      </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.expander {
  $base_class: &;
  transition: height 0.33s ease-in-out;
  position: relative;
  height: var(--expander-height-collapsed);
  overflow: hidden;
  &__button-container {
    transition: height 0.33s ease-in-out;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: var(--expander-padding-bottom-collapsed);
    background: linear-gradient(to top, white, white 2rem, transparent);
  }
  &--expanded {
    height: var(--expander-height-expanded);
    #{$base_class}__button-container {
      height: var(--expander-padding-bottom-expanded);
    }
  }
}
</style>
