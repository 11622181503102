export enum TabAvailable {
  PublicApps = 'public_apps',
  PrivateApps = 'private_apps',
}

export enum IntegrationType {
  SHOPIFY = 'SHOPIFY',
  SHOPIFYSALESCHANNEL = 'SHOPIFYSALESCHANNEL',
  WOOCOMMERCE = 'WOOCOMMERCE',
  WOOCOMMERCEPLUGIN = 'WOOCOMMERCEPLUGIN',
  ODOO = 'ODOO',
  SELLSY = 'SELLSY'
}

export enum IntegrationStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export interface IntegrationPayload {
  integrationType: IntegrationType;
  accessToken: string;
  integrationId: string;
  orderTags: string[];
  configuration: IntegrationConfiguration;
}

export enum OrderTagsErrorCodes {
  EMPTY_TAG = 'empty_tag',
  REPEATED_TAG = 'repeated_tag',
  MAX_TAGS_EXCEEDED = 'max_tags_exceeded',
  MAX_TAG_LENGTH_EXCEEDED = 'max_tag_length_exceeded',
}

export type IntegrationConfiguration = {
  order_default_financial_status: string;
  inventory_synchronization: boolean;
  catalog_synchronization: boolean;
  catalog_continuous_synchronization: boolean;
};

export enum IntegrationAvailableOrderStatus {
  authorized = 'authorized',
  paid = 'paid',
  partiallyPaid = 'partially_paid',
  pending = 'pending',
}

export enum IntegrationSyncTypes {
  stock = 'integrationStockSync',
  inventory = 'integrationInventorySync',
  catalogue = 'catalogueImport',
  price = 'cataloguePriceSync',
}

export interface IntegrationSyncProps {
  title: string;
  description: string;
  name: string;
  selectedOption: boolean;
  isLoading: boolean;
  missingAccessScopes: string[];
  showSuccessAlert: boolean;
  type: IntegrationSyncTypes;
}

export interface CatalogueImportProps {
  pricingStrategy: string;
  inputMultiplier: number;
  priceMultiplier: number;
  showModal: boolean;
  b2bCalculatedPrice: number;
  missingAccessScopes: string[];
  isLoading: boolean;
  showSuccessAlert: boolean;
  isPriceSync: boolean;
}

export interface CataloguePriceProps {
  title: string;
  description: string;
  name: string;
  selectedOption: boolean;
  type: IntegrationSyncTypes;
  showModal: boolean;
  missingAccessScopes: string[];
  isLoading: boolean;
  showSuccessAlert: boolean;
}

export enum CatalogueImportPricingStrategy {
  wholesale = 'wholesale',
  retail = 'retail',
}

export interface Permission {
  title: string;
  permissions: string[];
}
