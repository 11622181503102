<template>
  <div
    :class="{
      'popin-container': true,
      'popin-full-page': showFullPage,
    }"
    data-testid="login-popin-container"
  >
    <div
      v-if="showFullPage"
      class="popin-full-page__header"
    >
      <img
        class="logo-black"
        src="/images/logo/logo-black.svg"
        alt="ankorstore_logo"
      />
    </div>
    <div class="popin">
      <div class="popin__content">
        <div class="popin__img-col modal-img">
          <div class="header">
            <img
              class="logo-black"
              src="/images/logo/logo-black.svg"
              width="315"
            />
            <img
              class="logo-white"
              src="/images/logo/logo-white.svg"
              width="315"
            />
            <p>{{ $t('Commerce reinvented. Inspired stores.') }}</p>
          </div>

          <div
            v-if="content"
            class="content"
          >
            <div
              v-for="(point, index) in content.bullet_points"
              :key="index"
            >
              <AkIcon
                symbol="check"
                size="md"
                class="popin-list__bullet"
              />
              <p class="ds-text-left">
                {{ point.text }}
              </p>
            </div>
          </div>
          <div
            v-if="!inPage"
            class="footer"
          >
            <p>
              {{
                $t('login.footer.copyright.lbl', {
                  brand: 'Ankorstore x Eulenschnitt',
                })
              }}
            </p>
          </div>
        </div>
        <div class="popin__form-col modal-body">
          <LoginForm
            v-if="form === RetailerFormType.Login"
            :prefilled-email="email"
            :device-id="deviceId"
            @success="success"
            @change-to-register="onChangeToRegister"
            @change-to-two-factor="form = RetailerFormType.TwoFactor"
            @change-to-reset-password="form = RetailerFormType.ResetPassword"
          />
          <ResetPasswordForm
            v-else-if="form === RetailerFormType.ResetPassword"
            @change-to-login="form = RetailerFormType.Login"
          />
          <LoginTwoFactor
            v-else-if="form === RetailerFormType.TwoFactor"
            @change-to-two-factor-recovery="form = RetailerFormType.TwoFactorRecovery"
          />
          <LoginTwoFactorRecovery
            v-else-if="form === RetailerFormType.TwoFactorRecovery"
            @change-to-two-factor="form = RetailerFormType.TwoFactor"
          />
          <ResetPasswordInputForm
            v-else-if="form === RetailerFormType.ResetPasswordInput"
            @change-to-login="form = RetailerFormType.Login"
          />
          <SignUp
            v-else-if="!isEnabled(FeatureFlag.RegistrationFunnelRevamp)"
            :device-id="deviceId"
            :form-type="form"
            :analytic-props="analyticProps"
            @success="success"
            @redirect="switchForm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Analytics from '@/services/analytics';
import { FeatureFlag } from '@/services/features';
import { RetailerFormType } from '@/types/retailer-form-type';
import { LiftPromocode } from '@/types/order';
import { SignUpProps } from '@/services/analytics/properties/sign-up-property';
import LoginForm from '@/components/login-form.vue';
import LoginTwoFactor from '@/components/two-factor-form.vue';
import LoginTwoFactorRecovery from '@/components/two-factor-recovery-form.vue';
import ResetPasswordForm from '@/components/reset-password-form.vue';
import ResetPasswordInputForm from '@/components/reset-password-input-form.vue';
import SignUp from '@/components/sign-up/sign-up.vue';
import { AkIcon } from '@ankorstore/design-system';
import { getSourceByPath } from '@/services/analytics/events/home/source-property';
import { mapGetters } from 'vuex';
import { SignUpEventNames } from '@/services/analytics/events/sign-up/common/event-names';
import SignUpEvent from '@/services/analytics/events/sign-up/sign-up-event';
import { loginPopinMixin } from '@/mixins/login-popin';
import { prismic } from '@/mixins/prismic';
import { defineComponent, PropType } from 'vue';
import useGlobalSelectors from '@/composables/use-global-selectors/use-global-selectors';
import { getDeviceId } from '@/services/fraud-tracker';
import { isEnabled } from '@/services/features';

export default defineComponent({
  name: 'LoginPopin',
  components: {
    SignUp,
    LoginForm,
    ResetPasswordForm,
    ResetPasswordInputForm,
    AkIcon,
    LoginTwoFactor,
    LoginTwoFactorRecovery,
  },
  mixins: [loginPopinMixin, prismic],
  props: {
    liftCode: {
      type: Object as () => LiftPromocode,
      required: false,
      default: null,
    },
    brandName: {
      type: String,
      required: false,
      default: '',
    },
    brandId: {
      type: String,
      required: false,
      default: '',
    },
    view: {
      type: String,
      required: false,
      default: RetailerFormType.Login,
    },
    inPage: {
      type: Boolean,
      default: false,
    },
    showFullPage: {
      type: Boolean,
      default: false,
    },
    isReminded: {
      type: Boolean,
      default: false,
    },
    successCallback: {
      type: Function as PropType<() => void>,
      required: false,
      default: (event) => {
        /*
            If connected as a brand => redirect to my-account
            If connected as a candidate brand => redirect to brand onboarding
            If connected as a retailer => Reload (show catalogue)
          */
        if (event.redirectUrl) {
          window.location.href = event.redirectUrl;
          return;
        }
        if ((window.location.pathname === '/' || window.location.pathname === '/login') && event?.data?.brand) {
          const candidateBrandOnboardingLink = event?.data?.brand?.candidate?.onboarding_link;
          const hasBrandAdminRole = event?.data?.user?.roles?.includes('brand_admin') ?? false;
          const redirect = candidateBrandOnboardingLink || (hasBrandAdminRole ? '/account/network' : '/');
          window.location.href = redirect;
        } else {
          window.location.reload();
        }
      },
    },
  },
  setup() {
    const {
      global: { loginRegisterForm },
    } = useGlobalSelectors();
    return {
      id: loginRegisterForm().id,
    };
  },
  data(): {
    form: RetailerFormType;
    content;
    liftBrandId: string;
    RetailerFormType: typeof RetailerFormType;
    FeatureFlag: typeof FeatureFlag;
  } {
    return {
      form: this.view,
      content: null,
      liftBrandId: '',
      RetailerFormType: RetailerFormType,
      FeatureFlag: FeatureFlag,
      deviceId: '',
    };
  },
  computed: {
    ...mapGetters('signUp', ['isAnkorstart', 'email', 'liftBrandUrl']),
    analyticProps(): SignUpProps {
      return {
        is_personalized: isEnabled(FeatureFlag.SignUpPersonalize) && !!this.liftBrandId,
        is_reminded: this.isReminded,
        source: getSourceByPath(),
        is_opening_soon: this.isAnkorstart,
      };
    },
  },
  beforeUnmount() {
    if (
      [
        RetailerFormType.Vat,
        RetailerFormType.RetailerQualification,
        RetailerFormType.RetailerQualificationCategories,
        RetailerFormType.SecondStep,
        RetailerFormType.Categories,
      ].includes(this.form)
    ) {
      this.success();
    }
  },
  async created() {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email') ?? '';
    this.$store.commit('signUp/SET_FORM_VALUES', { email });
    const promocode = params.get('liftCode') || this.liftCode?.code || '';
    this.liftBrandId = params.get('liftBrandId') || this.brandId || '';
    if (promocode) {
      this.$store.dispatch('signUp/setInitialPromocode', {
        promocode,
        brandName: this.brandName,
        liftAmount: this.liftCode?.amount,
      });
    }
    this.content = await this.getContent();
    this.deviceId = await getDeviceId();
  },
  methods: {
    success(event = {}) {
      this.successCallback({
        redirectUrl: this.liftBrandUrl,
        ...event,
      });
    },
    close() {
      this.$parent.$parent?.hide();
    },
    redirectToFullScreen(formValues) {
      const registerParams = new URLSearchParams({
        step: RetailerFormType.Register,
        email: formValues.email,
        ...(this.liftBrandId ? { liftBrandId: this.liftBrandId } : {}),
        ...(this.liftCode?.code ? { liftCode: this.liftCode?.code } : {}),
      });
      const currentParams = new URLSearchParams(window.location.search);
      currentParams.forEach((value, key) => registerParams.append(key, value));
      window.location.href = `/register?${registerParams}`;
    },
    switchForm(formType, formValues = {}) {
      if (formType === RetailerFormType.Register) {
        this.redirectToFullScreen(formValues);
        return;
      }
      this.form = formType;
      this.$store.commit('signUp/SET_FORM_VALUES', formValues);
    },
    async getContent() {
      return this.getSingle('login_popin').then(({ data }) => data);
    },
    onChangeToRegister() {
      this.form = RetailerFormType.EmailOnlySignUp;
      Analytics.track(
        new SignUpEvent(SignUpEventNames.NoAccountYetEvent, {
          type: 'Click',
          ...this.analyticProps,
        })
      );
    },
    isEnabled,
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.popin {
  &__content {
    @apply ds-flex;
  }

  &__img-col,
  &__form-col {
    @apply ds-flex-1;
  }

  &__form-col {
    @apply ds-text-left;
  }

  &__img-col {
    @apply ds-hidden md:ds-flex ds-text-primary ds-bg-no-repeat ds-bg-cover ds-flex-col;
    background: url(/images/modal/background-signup-modal.png) 0 0;
    min-height: 568px;
  }
}

.popin-container {
  @apply ds-bg-white ds-relative;

  .popin-list__bullet {
    @apply ds-leading-5;
  }

  .popin-content {
    @apply ds-overflow-y-auto;
  }

  &.popin-full-page {
    @apply ds-bg-warm-white;
  }
}

.logo-black {
  @apply ds-w-10 ds-mx-auto ds-pb-4;
}

.popin-full-page {
  @apply ds-fixed ds-top-0 ds-left-0 ds-right-0 ds-bottom-0 ds-overflow-y-auto;
  .popin-full-page__header {
    @apply ds-mt-0 ds-pt-3 ds-flex ds-bg-white;
  }
  .logo-black {
    @apply ds-ml-4 md:ds-ml-6 ds-pb-3 ds-w-full;
    max-width: 142px;
    @media screen and (min-width: 768px) {
      max-width: 253px;
    }
  }

  &__header {
    @apply ds-mt-6 ds-w-full md:ds-flex ds-justify-center ds-border-b-2 ds-border-neutral-300 ds-hidden ds-border-solid;
  }

  .modal-img {
    @apply ds-hidden;
  }

  .popin__content {
    @apply ds-items-center ds-justify-center;
  }

  .modal-form {
    @apply ds-max-w-md;
  }
}
</style>
<style lang="scss">
.popin-full-page {
  .modal-body {
    @apply ds-bg-warm-white;
  }
}
</style>
