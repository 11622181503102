<template>
  <AkAlert
    :title="$t('retailer.reimbursement.title')"
    icon="truck"
    type="info"
  >
    {{ $t('retailer.reimbursement.description') }}
  </AkAlert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AkAlert } from '@ankorstore/design-system';

export default defineComponent({
  name: 'AlertInternationalShippingReimbursement',
  components: { AkAlert },
});
</script>
