import { flow } from 'lodash-es';
import http from '@/services/api/http';
import Product, { Variant, Option, ProductHit, FormattedProduct } from '@/types/product';
import { Product as APIProduct } from '@/types/api/product';
import { Item } from '@/types/cart';
import { ProductsVariant } from '@/types/api/brand-create-order';
import { Amount } from '@/types/amount';
import { Currency } from 'dinero.js';
import ProductDraft from '@/types/account/account-products/catalog-integration/product-integration';
import { isPast } from 'date-fns';
import { isEnabled } from '@/services/features';

export enum StockStatus {
  InStock = 'in_stock',
  LowInStock = 'low_in_stock',
  OutOfStock = 'out_of_stock',
}

export const getProductOptionsConfig = (): Promise<any> => {
  return http()
    .get('/api/me/brand/products/variants-presets')
    .then((response) => response.data?.data);
};

export const isVariantOutOfStock = (variant: Variant | Option | ProductsVariant): boolean => {
  //@ts-ignore mismatch for stock type
  const isVariantAlwaysInStock = variant?.stock?.is_always_in_stock;
  if (isVariantAlwaysInStock) {
    return false;
  }

  //@ts-ignore mismatch for stock type
  const availableQuantity = variant?.stock?.available_quantity;
  const unitMultiplier = variant?.unit_multiplier;
  if (availableQuantity < unitMultiplier) {
    return true;
  }

  const outOfStockLegacy = variant?.out_of_stock;
  const outOfStock = !isVariantAlwaysInStock && availableQuantity <= 0;
  return variant?.stock ? outOfStock : outOfStockLegacy;
};

export const isVariantLowInStock = (variant: Variant | Option): boolean => {
  return !variant?.stock?.is_always_in_stock && variant?.stock?.status === StockStatus.LowInStock;
};

export const getVariantQuantity = (variant: Variant | Option): number => variant?.stock?.available_quantity;

export const getMaxQuantity = (availableQuantity: number, selectedQuantity: number, unitMultiplier: number) => {
  const maxQuantity = selectedQuantity * unitMultiplier * 2;

  // if the product is always in stock
  if (!availableQuantity || (availableQuantity > 100 && selectedQuantity < 100)) {
    return Math.max(100, maxQuantity);
  } else if (availableQuantity <= 100) {
    return availableQuantity;
  } else {
    return Math.min(availableQuantity, maxQuantity);
  }
};

export const getListValuesForSelectInput = (alreadyInCart: boolean, maxLength: number, unitMultiplier: number): number[] => {
  const values = {} as number[];

  for (let i = alreadyInCart ? 0 : 1; i * unitMultiplier <= maxLength; i++) {
    values[i] = i * unitMultiplier;
  }

  return values;
};

export const isProductMultiple = (product: Product | ProductHit): boolean => {
  return product.variants?.length > 1;
};

export const isProductOutOfStock = (product: Product | ProductHit | ProductDraft): boolean => {
  // the explicit typing can be removed when the options are cleared from the repository, however right now it's
  // necessary due to a TypeScript bug: it doesn't recognise this is type-safe and inferred type is not enough.
  // see open issue and suggested workaround: https://github.com/microsoft/TypeScript/issues/44373#issuecomment-1405744410
  const variants: Array<Variant | Option> = product.variants?.length ? product.variants : product.options;
  return variants.every((variant) => isVariantOutOfStock(variant));
};

export const isProductLowInStock = (product: Product | ProductHit): boolean => {
  // the explicit typing can be removed when the options are cleared from the repository, however right now it's
  // necessary due to a TypeScript bug: it doesn't recognise this is type-safe and inferred type is not enough.
  // see open issue and suggested workaround: https://github.com/microsoft/TypeScript/issues/44373#issuecomment-1405744410
  const variants: Array<Variant | Option> = product.variants?.length ? product.variants : product.options;
  return variants.every((v) => isVariantLowInStock(v));
};

export const getFirstAvailableVariant = (product: Product | ProductHit): Variant | Option => {
  return product.variants.find((variant) => !isVariantOutOfStock(variant));
};

export const getMainVariant = (variants: Variant[]) => {
  return variants?.find(({ out_of_stock, options }) => !out_of_stock && options?.length)?.options[0];
};

export const getVariantValuesByOptionName = (variants: Variant[], optionName: string) => {
  const filteredOptionsValues = variants
    .map((variant) => variant.options.find(({ name }) => name === optionName)?.value)
    .filter(Boolean); // removed undefined values
  const uniqValues = new Set(filteredOptionsValues);
  return [...uniqValues];
};

export const getFirstProductVariantImage = (
  product: Product | ProductHit,
  selectedVariant: Variant = product.variants?.[0]
): string | undefined => {
  return selectedVariant?.images?.[0] ?? product.images[0];
};

export const getQuantityOptions = (item: Item) => {
  const selected = item.quantity;
  const availableQuantity = item.option.stock?.available_quantity;
  const unitMultiplier = item.option.unit_multiplier;
  const maxQuantity = getMaxQuantity(availableQuantity, selected, unitMultiplier);
  const values = [];

  for (let i = 0; i * unitMultiplier <= maxQuantity; i++) {
    const option = i * unitMultiplier;
    values[i] = {
      label: option,
      value: i,
    };
  }

  return values;
};

export const isMultipleProduct = (variants: Variant[]): boolean => {
  const hasDefaultVariant = variants.length === 1 && variants[0].options.length === 0;
  return !hasDefaultVariant && variants.length > 0;
};

const zeroAmount = (currency: Currency): Amount => ({
  amount: 0,
  currency: currency,
});

const multiplyAmount = (value: Amount, quantity: number): Amount => ({
  ...value,
  amount: value.amount * quantity,
});

export const calculateTotalPrice = (price: Amount, quantity: number, unitMultiplier: number) => {
  if (!quantity || !unitMultiplier) {
    return zeroAmount(price.currency);
  }

  const multiplyByQuantity = (value: Amount) => multiplyAmount(value, quantity);
  const multiplyByMultiplier = (value: Amount) => multiplyAmount(value, unitMultiplier);
  const calculateTotal = flow(multiplyByQuantity, multiplyByMultiplier);

  return calculateTotal(price);
};

export const getOutOfStockVariantsIds = (products: Product[]): number[] => {
  return products
    .filter((product) => isProductOutOfStock(product) && product.variants?.length)
    .map((product) => product.variants[0].id);
};

export const isPreorderDateExpired = (product: Product | FormattedProduct): boolean => {
  if (product.variants[0].available_at === null) {
    return false;
  }
  const availableAt = new Date(product.variants[0].available_at);

  return isPast(availableAt);
};

export const isPreorder = (product: Product | ProductHit | APIProduct): boolean => {
  if (!isEnabled('oxp-1484-r2')) {
    return false;
  }

  return product?.available_at ? !!product?.available_at : !!product?.variants?.some((variant: Variant) => variant?.available_at);
};

export const isPreorderActual = (product: Product | ProductHit): boolean => {
  let availableAt: Date;
  const currentDate = new Date();

  if (!isProductOutOfStock(product) && product?.available_at) {
    availableAt = new Date(parseInt(product.available_at) * 1000);
  } else {
    availableAt = new Date(
      product.variants.find((variant) => !isVariantOutOfStock(variant) && variant?.available_at).available_at
    );
  }

  return currentDate.getTime() <= availableAt.getTime();
};

export const getPreorderAvailableAt = (product: Product | ProductHit): string | Date => {
  let availableAt: string | Date = '';

  if (product?.available_at) {
    availableAt = new Date(parseInt(product.available_at) * 1000);
  } else {
    availableAt = product.variants.find((variant) => variant?.available_at)?.available_at;
  }

  return availableAt;
};
