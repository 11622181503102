<template>
  <div class="ak-progress-bar-wrapper">
    <ProgressBar
      v-if="progressBar !== 'hide'"
      @click="onClick"
    />
  </div>
</template>

<script lang="ts">
import ProgressBar from '@/components/progress-bar.vue';

import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ProgressBarWrapper',
  components: {
    ProgressBar: ProgressBar,
  },
  setup() {
    const store = useStore();
    const progressBar = computed(() => store.state.progressBar);
    const onClick = () => {
      store.dispatch('endProgressBar');
    };
    return { onClick, progressBar };
  },
});
</script>
