import { R2BOfferDiscount, R2BOfferPromocode, R2BOfferType, R2BOfferWalletCredit } from '@/types/r2b';
import { B2REligibility } from '@/services/b2r';
import { OffersState } from '@/store/offers/index';
import { WelcomeVoucherEligibility } from '@/services/welcome-voucher';
import { ProductDiscount } from '@/types/product';
import { OfferProgramEligibility, OfferProgramType, OfferType } from '@/modules/promote-engage/services/offer';
import { BusinessEventPeriode } from '@/types/business-event';

const offersGetters = {
  r2bPromocodeOffer(state: OffersState): R2BOfferPromocode {
    return state.r2b.offers_config?.offers?.find((offer) => offer.type === R2BOfferType.Promocode) as R2BOfferPromocode;
  },
  r2bWalletCreditOffer(state: OffersState): R2BOfferWalletCredit {
    return state.r2b.offers_config?.offers?.find((offer) => offer.type === R2BOfferType.WalletCredit) as R2BOfferWalletCredit;
  },
  r2bDiscountOffer(state: OffersState): R2BOfferDiscount {
    return state.r2b.offers_config?.offers.find((offer) => offer.type === R2BOfferType.Discount) as R2BOfferDiscount;
  },
  b2rEligibility(state: OffersState): B2REligibility {
    return state.b2r.eligibility;
  },
  b2rEligibilityFetched(state: OffersState): boolean {
    return state.b2r.eligibilityFetched;
  },
  welcomeOffer(state: OffersState): WelcomeVoucherEligibility {
    return state.welcomeOffer.eligibility;
  },
  welcomeOfferEligibilityFetched(state: OffersState): boolean {
    return state.welcomeOffer.eligibilityFetched;
  },
  areOffersFetched(state: OffersState): boolean {
    return state.offerEligibility.eligibilityFetched;
  },
  ankorstorePlus: (state: OffersState): OfferProgramEligibility =>
    state.offerEligibility.eligibility.find(({ type }) => type === OfferProgramType.AnkorstorePlus) ?? null,
  ankorstorePlusDiscount(_state: OffersState, getters): OfferProgramEligibility {
    return getters.ankorstorePlus?.offers.find((offer) => offer.type === OfferType.Discount);
  },
  freeTrialInfo(_state: OffersState, getters): OfferProgramEligibility {
    return getters.ankorstorePlus.offers.find((offer) => offer.type === OfferType.FreeTrial);
  },
  extendedPaymentTerms(_state: OffersState, getters): OfferProgramEligibility {
    return getters.ankorstorePlus.offers.find((offer) => offer.type === OfferType.ExtendedPaymentTerms);
  },
  appliedDiscount(state: OffersState): ProductDiscount {
    const blackFridayEvent = state.events.find((item) => item.name === 'Black Friday');

    if (blackFridayEvent) {
      return {
        origin: 'black friday',
        percentage: blackFridayEvent.business_event_period_offers[0]?.offer?.percentage,
      };
    }
    return null;
  },
  haveBrandDiscountActivated(state: OffersState): BusinessEventPeriode {
    return state.events.find((item) => item.has_brand_event_page && item.with_discount_live);
  },
  haveBrandDiscountEventLive(state: OffersState): boolean {
    return state.events.some((item) => item.has_brand_event_page && item.with_discount_live);
  },
};

export default offersGetters;
export type OffersGetters = typeof offersGetters;
