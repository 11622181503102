<template>
  <div class="pill">
    <div class="tooltip__trigger">
      <RouterLink
        v-if="$route"
        :to="{ name: programNameRoute }"
      >
        <template #default="{ href, navigate }">
          <a
            :href="href"
            @click="
              trackToLink(trackPillIcon);
              navigate($event);
            "
          >
            <AnkorstorePlusBadgeSquare data-testid="pillLink" />
          </a>
        </template>
      </RouterLink>
      <a
        v-else
        :href="`/${programNameRoute}`"
        @click="trackToLink(trackPillIcon)"
      >
        <AnkorstorePlusBadgeSquare data-testid="pillLink" />
      </a>
      <AkIcon
        class="symbol"
        symbol="chevron-down"
      />
    </div>
    <div class="tooltip">
      <div class="tooltip-box">
        <div class="tooltip-box__header">
          <img
            class="ankorstore-logo"
            src="/images/logo/logo-black.svg"
            :alt="t('global.ankorstore.title')"
          />
          <AnkorstorePlusBadgeSquare
            class="aks-badge"
            size="medium"
          />
        </div>
        <div class="tooltip-box__container">
          <template v-if="ankorstorePlus.optIn">
            <AkParagraph size="sm">
              {{ t('ankorstorePlusPill.content.optIn') }}
            </AkParagraph>
          </template>
          <template v-else>
            <AkParagraph size="sm">
              {{ t('ankorstorePlusPill.content') }}
            </AkParagraph>
          </template>
        </div>
        <div class="tooltip-box__action">
          <RouterLink
            v-if="$route"
            :to="{ name: programNameRoute }"
          >
            <template #default="{ href, navigate }">
              <a
                data-testid="subscriptionProgramRouterLink"
                :href="href"
                @click="
                  trackToLink(trackPillLink);
                  navigate($event);
                "
              >
                <AkButton>
                  {{ ctaLabel }}
                </AkButton>
              </a>
            </template>
          </RouterLink>
          <a
            v-else
            data-testid="subscriptionProgramLink"
            :href="`/${programNameRoute}`"
            @click="trackToLink(trackPillLink)"
          >
            <AkButton>
              {{ ctaLabel }}
            </AkButton>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AkButton } from '@ankorstore/design-system';
import { defineComponent, ref, onMounted, computed } from 'vue';
import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';
import { SectionCreatedEvent } from '@/services/analytics/events/section_created.event';
import AnkorstorePlusBadgeSquare from '@/modules/promote-engage/subscription-program/UI/ankorstore-plus-badge-square.vue';
import { BannerName } from '@/types/banner';
import { useStore } from '@/composables/use-store';
import { OfferProgramEligibility } from '@/modules/promote-engage/services/offer';
import { useI18n } from 'vue-i18n';
import {
  trackActionName,
  trackSectionName,
} from '@/modules/promote-engage/subscription-program/types/subscription-program-types';
import { toValue } from 'vue';

export default defineComponent({
  name: 'ProgramPill',
  components: {
    AkButton,
    AnkorstorePlusBadgeSquare,
  },
  props: {
    programNameRoute: {
      type: String,
      required: true,
    },
  },
  setup() {
    const showBox = ref(false);

    const store = useStore();

    const { t } = useI18n();

    const ankorstorePlus = computed((): OfferProgramEligibility => {
      return store.getters['offers/ankorstorePlus'];
    });

    const trackPillIcon = computed((): trackActionName => {
      return toValue(ankorstorePlus).optIn ? trackActionName.CLICk_HEADER_SUBSCRIBER : trackActionName.CLICK_PILL;
    });

    const trackPillLink = computed((): trackActionName => {
      return toValue(ankorstorePlus).optIn ? trackActionName.CLICK_LINK_SUBSCRIBER : trackActionName.CLICK_LINK;
    });

    const ctaLabel = computed((): string => {
      if (toValue(ankorstorePlus).optIn) {
        return t('ankorstorePlus.cta.optedIn');
      } else {
        return toValue(ankorstorePlus).freeTrialEligible ? t('ankorstorePlus.cta.nonOptIn') : t('ankorstorePlus.cta.optIn');
      }
    });

    function trackSectionCreated(): void {
      Analytics.track(
        new SectionCreatedEvent({
          banner_name: [BannerName.SubscriptionProgramPill],
          action: toValue(ankorstorePlus).optIn
            ? trackSectionName.SHOW_SUBSCRIPTION_PROGRAM_PILL_SUBSCRIBER
            : trackSectionName.SHOW_SUBSCRIPTION_PROGRAM_PILL,
        })
      );
    }

    function trackToLink(actionName: trackActionName): void {
      Analytics.track(
        new UserClick({
          component: BannerName.SubscriptionProgramPill,
          action: actionName,
        })
      );
    }

    onMounted(() => {
      trackSectionCreated();
    });

    return {
      ankorstorePlus,
      ctaLabel,
      showBox,
      t,
      trackToLink,
      trackPillIcon,
      trackPillLink,
    };
  },
});
</script>
<style lang="scss" scoped>
.pill {
  @apply ds-relative;
  &:hover {
    .symbol {
      @apply ds-rotate-180;
    }
    .tooltip-box {
      @apply ds-block;
    }
  }
}
.tooltip__trigger {
  @apply ds-flex ds-gap-1 ds-items-center ds-cursor-pointer;
}
.symbol {
  @apply ds-text-neutral-500;
}

.tooltip {
  @apply ds-absolute ds-z-10 ds-right-0 ds-pt-4;
}

.ankorstore-logo {
  max-width: 112px;
}

.tooltip-box {
  @apply ds-p-5 ds-bg-warm-white ds-rounded-md ds-hidden ds-border ds-border-solid ds-border-neutral-300;
  min-width: 280px;
  &__header,
  &__container {
    @apply ds-mb-4;
  }
  &__header {
    @apply ds-flex ds-items-center ds-gap-2;
  }
  &__container {
    @apply ds-text-neutral-900 ds-text-left;
  }
  &__action {
    @apply ds-flex;
  }
}

.aks-badge {
  min-width: 66px;
}
</style>
