import AnalyticsEvent from './analytics-event';
import { ProductProperty, createProductProperty } from '../properties/product-property';

export default class ProductUnlikedEvent extends AnalyticsEvent {
  public readonly name = 'Product Unliked';
  public properties: {
    product: ProductProperty;
  };

  constructor({ id, name, wholesalePrice }: ProductProperty) {
    super();
    this.properties = {
      product: createProductProperty({ id, name, wholesalePrice }),
    };
  }
}
