<template>
  <div
    v-if="review"
    class="ds-pl-4 ds-pr-4 ds-self-start ds-flex ds-flex-1 ds-flex-col ds-h-full"
  >
    <div class="review-retailer ds-mb-2">
      <span class="ds-font-bold">{{ retailerName }}</span>
      {{ retailerFrom }}
    </div>
    <span class="review-submission-date">
      {{ $t('Submitted on') }}
      <span class="ds-font-bold">{{ submissionDate }}</span>
    </span>

    <ReviewPreviewer
      :review="review"
      :show-brand-answer="false"
      class="ds-py-5"
    />

    <AkForm
      v-model="form"
      class="ds-flex ds-flex-col ds-flex-1"
      @submit="submit"
    >
      <AkInputField
        name="answer"
        input-type="textarea"
        class="answer-text"
        :max-length="500"
        :placeholder="responsePlaceholder"
        :validate="
          (value) => {
            if (!value.length) {
              return $t('This field is mandatory');
            }
          }
        "
      />

      <span class="ds-flex-auto"></span>

      <template #submit="submitProps">
        <AkButton
          type="button"
          size="xl"
          color="primary"
          class="ds-my-4 publish-button ds-self-end"
          :disabled="submitProps.disabled"
          @click="submitProps.handleSubmit"
        >
          {{ responseSubmitText }}
        </AkButton>
      </template>
    </AkForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { formatDate } from '@/services/date-locale';
import ReviewPreviewer from '@/components/review/review-previewer.vue';
import AkInputField from '@/components/review/AkForm/AkInputField/ak-input-field.vue';
import AkForm from '@/components/review/AkForm/ak-form.vue';
import { Review } from '@/components/review/types';
import { ResponseDrawerType } from '@/store/reviews';

export default defineComponent({
  name: 'BrandResponseForm',
  components: { AkForm, ReviewPreviewer, AkInputField },
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  emits: ['publish', 'report'],
  data() {
    return {
      form: {
        answer: '',
      },
    };
  },
  computed: {
    ...mapGetters('reviews', ['currentReview', 'responseDrawerType']),
    ...mapGetters('drawer', ['closeRequested']),
    answer(): string {
      return this.form.answer;
    },
    review(): Review {
      return this.currentReview;
    },
    retailerName(): string {
      return this.currentReview?.retailer?.first_name;
    },
    retailerFrom(): string {
      return `${this.$t('From').toLowerCase()} ${this.currentReview?.retailer?.country}`;
    },
    responsePlaceholder(): string {
      if (this.responseDrawerType === ResponseDrawerType.public) {
        return this.$t('@@retailer_xp.reviews.brand_response.placeholder');
      } else {
        return this.$t('@@retailer_xp.reviews.brand_report.placeholder');
      }
    },
    responseSubmitText(): string {
      if (this.responseDrawerType === ResponseDrawerType.public) {
        return this.$t('@@retailer_xp.reviews.brand_response.post');
      } else {
        return this.$t('@@retailer_xp.reviews.brand_report.post');
      }
    },
    submissionDate(): string {
      return formatDate(this.currentReview.created_at, 'PPPp');
    },
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(newValue: any, oldValue: any) {
        this.setPreventClose(newValue?.answer !== '');

        if (newValue?.answer === '' && oldValue?.answer !== '') {
          if (this.responseDrawerType === ResponseDrawerType.public) {
            this.clearMessage(this.currentReview.id);
          } else {
            this.clearReport(this.currentReview.id);
          }
        }
      },
    },
    closeRequested() {
      if (!this.closeRequested) {
        return;
      }

      if (this.responseDrawerType === ResponseDrawerType.public) {
        this.saveMessage({
          reviewId: this.currentReview.id,
          message: this.form.answer,
        });
      } else {
        this.saveReport({
          reviewId: this.currentReview.id,
          message: this.form.answer,
        });
      }

      this.closeDrawer();
    },
  },
  created(): void {
    this.form.answer = this.message;
  },
  methods: {
    ...mapActions('reviews', ['saveMessage', 'saveReport', 'clearMessage', 'clearReport']),
    ...mapActions('drawer', ['setPreventClose', 'closeDrawer']),
    submit() {
      if (this.responseDrawerType === ResponseDrawerType.public) {
        this.$emit('publish', this.form.answer);
      } else {
        this.$emit('report', this.form.answer);
      }
    },
  },
});
</script>

<style lang="scss">
.review-retailer {
  font-size: 1rem;
}

.review-submission-date {
  font-size: 0.75rem;
}

.answer-text {
  textarea {
    @apply ds-border-neutral-500;

    padding-right: 0.75rem !important;
    height: 9rem;
    resize: none !important;
  }
}

.publish-button {
  width: calc(100% - 2rem);
  @apply sm:ds-w-1/3;
}
</style>
