import AnalyticsEvent from '../analytics-event';
import { EoriWarningInteraction } from '../segment-ecommerce-types';

export class EoriNavigatedToAddEvent extends AnalyticsEvent {
  public readonly name = 'EORI Warning Clicked Add';

  constructor(public readonly properties: EoriWarningInteraction) {
    super();
  }
}
