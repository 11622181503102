import { getCurrentInstance, toRef, Ref } from 'vue';
import type { Router, RouteLocationNormalized } from 'vue-router';

// The official Vue Router implementation returns a reactive object, not a reference.
export const useRoute = (): Ref<RouteLocationNormalized> => {
  return toRef(getCurrentInstance().proxy, '$route');
};

export const useRouter = (): Router => {
  return getCurrentInstance().proxy.$router;
};
