// https://developer.ravelin.com/libraries-and-sdks/ravelinjs/integration/#bundles
import Ravelin from 'ravelinjs/core+promise';
import { FeatureFlag, isEnabled } from '@/services/features';
import { getGlobalConfig } from '@/services/global-config';

export const getDeviceId = async () => {
  if (!isEnabled(FeatureFlag.Ravelin)) {
    return '';
  }

  // https://developer.ravelin.com/libraries-and-sdks/ravelinjs/integration/#var-ravelin--new-ravelincfg-object
  const ravelin = new Ravelin({
    key: getGlobalConfig().ravelin?.api_public_key || '',
    cookieDomain: getGlobalConfig().ravelin?.cookie_domain || '',
  });

  // https://developer.ravelin.com/libraries-and-sdks/ravelinjs/integration/#ravelincoreid-promisestring
  return await ravelin.core.id();
};
