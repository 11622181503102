import { Ref, ref, watch } from 'vue';

type ItemId = string;

export const useOpenClosed = (ids?: Ref<ItemId[]>) => {
  const state = ref<Record<ItemId, boolean>>({});
  const updateOpenClosedState = (id: ItemId, isOpen: boolean) => {
    state.value = { ...state.value, [id]: isOpen };
  };
  const isOpen = (id: ItemId) => Boolean(state.value[id]);
  const open = (id: ItemId) => updateOpenClosedState(id, true);
  const close = (id: ItemId) => updateOpenClosedState(id, false);
  const openAll = () => Object.keys(state.value).forEach(open);
  const closeAll = () => Object.keys(state.value).forEach(close);
  const toggle = (id: ItemId) => updateOpenClosedState(id, !isOpen(id));

  if (ids) {
    watch(ids, (newIds) => newIds?.forEach((id) => (state.value[id] = isOpen(id))), { immediate: true });
  }

  return {
    isOpen,
    open,
    close,
    openAll,
    closeAll,
    toggle,
  };
};
