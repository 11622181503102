<template>
  <AkParagraph class="ds-mb-2 ds-text-neutral-900">{{ filter.label }}</AkParagraph>
  <div class="multiselect-widget">
    <AkMultiSelect
      :disabled="!filter.values.length"
      :label="filter.placeholder || $t('Select')"
      :value="selectedValue"
      :placeholder="filter.placeholder || $t('Select')"
      ::no-elements-message="$t('brand.productManagement.attributes.empty')"
      :options="filter.values"
      tag-color="accent-alt"
      @update:model-value="setValue"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { AkParagraph, AkMultiSelect } from '@ankorstore/design-system';
import { DropdownWidgetComponent } from '../../types/filter.types';
import { ContactFilterType, ValueContactFilter } from '../../types/api.types';

export default defineComponent({
  name: 'MultiSelectWidget',
  components: {
    AkParagraph,
    AkMultiSelect,
  },
  props: {
    filter: {
      type: Object as PropType<DropdownWidgetComponent>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<ValueContactFilter>,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const selectedValue = ref(props?.modelValue?.value ?? []);
    const { filter } = props;
    const setValue = (value: string[]) => {
      emit('update:modelValue', {
        type: ContactFilterType.Value,
        name: filter.attributeName,
        value,
      });
    };

    return { setValue, selectedValue };
  },
});
</script>
<style scoped lang="scss">
  .multiselect-widget {
    @apply ds-px-4 ds-pb-1;
  }
</style>
