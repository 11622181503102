<template>
  <span :role="role">
    <input
      :id="inputId"
      :value="modelValue"
      tabindex="0"
      class="ds-sr-only"
      :disabled="readOnly"
      type="range"
      min="0"
      :max="max"
      :step="precision"
      :name="inputId"
    />
    <label
      ref="rootRef"
      class="ds-inline-flex ds-relative ds-m-0"
      :for="inputId"
      @mousemove="handleMouseOver"
      @mouseleave="handleMouseLeave"
      @click="handleClick"
    >
      <span
        v-for="(item, index) in items"
        :key="index"
        class="star-container ds-relative ds-transform-gpu ds-origin-center"
        :class="{ 'hover:ds-scale-125': !readOnly }"
      >
        <slot name="empty-icon">
          <AkIcon
            symbol="star"
            size="md"
          />
        </slot>

        <span
          class="ds-absolute ds-overflow-hidden ds-top-0 ds-left-0"
          :style="{ width: item.width }"
        >
          <slot name="icon">
            <AkIcon
              symbol="star-fill"
              size="md"
            />
          </slot>
        </span>
      </span>
    </label>
  </span>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AkIcon } from '@ankorstore/design-system';
import { v4 as uuid } from 'uuid';

function getDecimalPrecision(num: number) {
  const decimalPart = num.toString().split('.')[1];
  return decimalPart ? decimalPart.length : 0;
}

function roundValueToPrecision(value: number, precision: number) {
  const nearest = Math.round(value / precision) * precision;

  return Number(nearest.toFixed(getDecimalPrecision(precision)));
}

interface Item {
  width: string;
}

interface Data {
  ratingOnHover: number | null;
  inputId: string;
}

export default defineComponent({
  name: 'AkRating',
  components: { AkIcon },
  props: {
    name: {
      type: String as PropType<string>,
      default: undefined,
    },
    precision: {
      type: Number as PropType<number>,
      default: 1,
    },
    max: {
      type: Number as PropType<number>,
      default: 5,
    },
    readOnly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    direction: {
      type: String as PropType<'ltr' | 'rtl'>,
      default: 'ltr',
    },
    modelValue: {
      type: Number as PropType<number>,
      default: 0,
    },
  },
  emits: ['update:modelValue'],
  data(): Data {
    return {
      ratingOnHover: null,
      inputId: `input-${this.name ?? uuid()}`,
    };
  },
  computed: {
    role(): string | null {
      return this.readOnly ? 'img' : null;
    },
    items(): Item[] {
      return Array.from(Array(this.max), (_, index) => {
        const scores = this.ratingOnHover ?? this.modelValue;
        const isFilled = scores > index;
        const isPartiallyFilled = scores < index + 1;

        let filledWidth = 0;

        if (isFilled) {
          if (isPartiallyFilled) {
            // If the value is between two stars, we need to calculate the width of the filled star
            // based on the value of the current star and the next star.
            filledWidth = +(scores % 1).toFixed(2) * 100;
          } else {
            filledWidth = 100;
          }
        }

        return {
          width: `${filledWidth}%`,
        };
      });
    },
  },

  methods: {
    handleMouseOver(event: { clientX: number }) {
      if (this.readOnly) {
        return;
      }
      const rootNode = this.$refs.rootRef as HTMLLabelElement;
      const { max, precision, direction } = this;
      const { right, left, width } = rootNode.getBoundingClientRect();

      const percent = direction === 'ltr' ? 1 - (right - event.clientX) / width : 1 - (event.clientX - left) / width;

      const newRatingValue = roundValueToPrecision(max * percent + precision / 2, precision);

      this.ratingOnHover = Math.max(Math.min(newRatingValue, max), 0);
    },
    handleMouseLeave() {
      if (this.readOnly) {
        return;
      }
      this.ratingOnHover = null;
    },
    handleClick() {
      if (this.readOnly || this.ratingOnHover === null) {
        return;
      }
      this.$emit('update:modelValue', this.ratingOnHover);
    },
  },
});
</script>
<style lang="scss" scoped>
input:focus + label {
  @apply ds-ring-2;
}

.star-container {
  @apply ds-text-ratings;

  &:not(:last-child) {
    @apply ds-mr-1;
  }
}
</style>
