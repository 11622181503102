export const SET_R2B_OFFERS = 'SET_R2B_OFFERS';
export const SET_R2B_OFFERS_FETCHED = 'SET_R2B_OFFERS_FETCHED';
export const SET_B2R_ELIGIBILITY = 'SET_B2R_ELIGIBILITY';
export const SET_B2R_ELIGIBILITY_FETCHED = 'SET_B2R_ELIGIBILITY_FETCHED';
export const SET_WELCOME_OFFER_ELIGIBILITY = 'SET_WELCOME_OFFER_ELIGIBILITY';
export const SET_WELCOME_OFFER_ELIGIBILITY_FETCHED = 'SET_WELCOME_OFFER_ELIGIBILITY_FETCHED';
export const SET_BUSINESS_EVENTS = 'SET_BUSINESS_EVENTS';
export const SET_SUM_UP_ELIGIBILITY = 'SET_SUM_UP_ELIGIBILITY';
export const SET_SUM_UP_ELIGIBILITY_FETCHED = 'SET_SUM_UP_ELIGIBILITY_FETCHED';
export const SET_OFFERS_ELIGIBILITY = 'SET_OFFERS_ELIGIBILITY';
export const SET_OFFERS_ELIGIBILITY_FETCHED = 'SET_OFFERS_ELIGIBILITY_FETCHED';
