import { HistoryRouterBase } from './history-router-base';
import { URLParameterKey } from '../url-manager/url-manager-category';
import { SearchRouteState } from '../../types/catalog';

export class HistoryRouterProduct extends HistoryRouterBase {
  read() {
    const hashParams = this.getHashParams(this.currentRoute);
    const categories = this.urlManager().getCategoriesFromRoute(this.getCategories(), this.currentRoute);
    const locations = this.urlManager().getLocationsFromRoute(this.getLocations(), this.currentRoute);

    const hierarchicalMenu = this.removeEmptyValues({
      'catalog_hierarchical_categories.v3.lvl0': categories.map((category) => `${category.id}`),
    });
    const attributeRefinements = this.getAttributeRefinements(hashParams);
    const refinementList = this.removeEmptyValues({
      'brand.location': locations.map((location) => location.id),
      'brand.name': hashParams.get(URLParameterKey.Brands)?.split(',') ?? [],
      margin: hashParams.get(URLParameterKey.Margin)?.split(',') ?? [],
      [`made_in.${this.getLang()}`]: hashParams.get(URLParameterKey.MadeIn)?.split(',') ?? [],
      [`tags.${this.getLang()}`]: hashParams.get(URLParameterKey.Values)?.split(',') ?? [],
      'brand.shipping_lead_time': hashParams.get(URLParameterKey.Shipping)?.split(',') ?? [],
      discount: hashParams.get(URLParameterKey.Discount)?.split(',') ?? [],
      'brand.aks_plus': hashParams.get(URLParameterKey.loyaltyOffer)?.split(',') ?? [],
      ...attributeRefinements,
    });
    const retailPriceRangeMin = hashParams.get(URLParameterKey.retailPriceRangeMin) ?? '';
    const retailPriceRangeMax = hashParams.get(URLParameterKey.retailPriceRangeMax) ?? '';
    const attributeRanges = this.getAttributeRanges(hashParams);
    const attributesToggle = this.getAttributeToggles(hashParams);

    const range = this.removeEmptyValues({
      [`retail_prices.${this.getCurrency()}`]:
        retailPriceRangeMin || retailPriceRangeMax ? `${retailPriceRangeMin}:${retailPriceRangeMax}` : '',
      ...attributeRanges,
    });
    const toggle = this.removeEmptyValues(attributesToggle);
    const textQuery = this.currentRoute.query.q?.toString() ?? '';
    const page = parseInt(this.currentRoute.query.p?.toString() ?? '');

    const routeState: SearchRouteState = {
      ...this.removeEmptyValues({
        hierarchicalMenu,
        refinementList,
        range,
        toggle,
      }),
    };

    if (textQuery) {
      routeState.q = textQuery;
    }

    if (page) {
      routeState.page = page;
    }

    return routeState;
  }
}
