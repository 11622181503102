import { ref, Ref, inject, provide } from 'vue';
import { isEqual, isObject, debounce } from 'lodash-es';
import { InstantSearch } from 'instantsearch.js';
import type { AisState, Refinements, RefinementsWithCount, Pagination } from '../types/ais-state';

export const calculateFacetRefinementCount = (refinements: Refinements) => {
  if (!refinements) {
    return 0;
  }

  const facetRefinements = [
    refinements.disjunctiveFacetsRefinements,
    refinements.hierarchicalFacetsRefinements,
    refinements.facetsRefinements,
  ];
  const appliedFacetRefinements = facetRefinements.reduce((accumulator, facetRefinement) => {
    const facetList = Object.values(facetRefinement);
    const appliedRefinementCount = facetList.reduce((accumulator, facetRefinement) => {
      if (facetRefinement.length) {
        ++accumulator;
      }
      return accumulator;
    }, 0);
    return accumulator + appliedRefinementCount;
  }, 0);

  const numericRefinements = Object.values(refinements.numericRefinements);
  const appliedNumericRefinements = numericRefinements.reduce((accumulator, numericRefinement) => {
    const isRefinementApplied = Object.values(numericRefinement).reduce((isApplied, operatorList) => {
      return isApplied || Boolean(operatorList.length);
    }, false);
    if (isRefinementApplied) {
      ++accumulator;
    }
    return accumulator;
  }, 0);

  return appliedFacetRefinements + appliedNumericRefinements;
};

export const extractRefinements = (instance: InstantSearch): RefinementsWithCount => {
  if (!instance?.helper?.state) {
    return {
      disjunctiveFacetsRefinements: {},
      hierarchicalFacetsRefinements: {},
      numericRefinements: {},
      facetsRefinements: {},
      refinementCount: 0,
    };
  }
  const { disjunctiveFacetsRefinements, hierarchicalFacetsRefinements, numericRefinements, facetsRefinements } =
    instance.helper.state;
  const refinements = { disjunctiveFacetsRefinements, hierarchicalFacetsRefinements, numericRefinements, facetsRefinements };
  Object.keys(refinements).forEach((key) => {
    if (isObject(refinements[key])) {
      refinements[key] = JSON.parse(JSON.stringify(refinements[key]));
    }
  });
  const refinementCount = calculateFacetRefinementCount(refinements);
  return { ...refinements, refinementCount };
};

export const extractPagination = (instance: InstantSearch): Pagination => {
  const pagination = {
    page: undefined,
    totalPages: undefined,
    totalHits: undefined,
    isFirstPage: false,
    isLastPage: false,
  };
  const instanceValues = instance?.renderState[instance?.indexName].pagination;
  if (instanceValues) {
    pagination.page = instanceValues.currentRefinement;
    pagination.totalPages = instanceValues.nbPages;
    pagination.totalHits = instanceValues.nbHits;
    pagination.isFirstPage = instanceValues.isFirstPage;
    pagination.isLastPage = instanceValues.isLastPage;
  }
  return pagination;
};

export const retrieveProperties = (instance: InstantSearch) => {
  const error = instance?.error;
  const status = instance?.status;
  const hits = instance?.renderState?.[instance.indexName]?.hits?.hits;
  const searchQuery = instance?.helper?.state?.query;
  const refinements = extractRefinements(instance);
  const pagination = extractPagination(instance);
  refinements.filters = instance?.helper?.state?.filters;
  return { error, status, hits, searchQuery, refinements, pagination };
};

export const assignIfDifferent = <T>(newValue: T, oldValueRef: Ref<T>) => {
  if (!isEqual(newValue, oldValueRef.value)) {
    oldValueRef.value = newValue;
  }
};

const aisInjectKey = Symbol('aisState');
export const useAisState = () => {
  const injectedAisState = inject<{
    aisState: AisState;
    aisStateMiddleware: ({ instantSearchInstance }: { instantSearchInstance: InstantSearch }) => { subscribe: () => void };
  }>(aisInjectKey, undefined);

  if (!injectedAisState) {
    const aisState = Object.freeze({
      status: ref<InstantSearch['status']>(),
      error: ref<InstantSearch['error']>(),
      hits: ref<InstantSearch['helper']['lastResults']['hits']>(),
      searchQuery: ref<string>(),
      refinements: ref<RefinementsWithCount>({
        disjunctiveFacetsRefinements: {},
        hierarchicalFacetsRefinements: {},
        numericRefinements: {},
        facetsRefinements: {},
        refinementCount: 0,
        filters: '',
      }),
      pagination: ref<Pagination>(),
    });

    const updateAisState = (instance: InstantSearch) => {
      if (!instance.status) {
        return;
      }
      const properties = retrieveProperties(instance);
      Object.entries(properties).forEach(([key, value]) => {
        assignIfDifferent(value, aisState[key]);
      });
    };

    const aisStateMiddleware = ({ instantSearchInstance }: { instantSearchInstance: InstantSearch }) => {
      return {
        subscribe() {
          instantSearchInstance.on(
            'render',
            debounce(() => updateAisState(instantSearchInstance), 150)
          );
        },
      };
    };

    provide(aisInjectKey, { aisState, aisStateMiddleware });
    return { aisState, aisStateMiddleware };
  }

  return injectedAisState;
};
