<template>
  <div
    class="addition-form"
    @keyup.enter="notifyChanged()"
  >
    <div
      v-if="withBusinessName"
      class="addition-form-row"
    >
      <div class="addition-form-row__column">
        <AkInput
          id="business_name"
          v-model="business_name"
          :label="$t('Business name')"
          :disabled="disabled"
          :errors="soleTraderDetailsErrors['business.name']"
          type="text"
        />
      </div>
    </div>
    <div class="addition-form-row">
      <AkParagraph
        class="addition-form__description"
        size="sm"
      >
        <i18n-t
          keypath="Please enter information about the {0} of the company"
          class="addition-form-row__column form-group"
        >
          <strong>{{ $t('owner') }}</strong>
        </i18n-t>
      </AkParagraph>
    </div>
    <div class="addition-form-row">
      <div class="addition-form-row__column addition-form-row__column--twice">
        <AkInput
          id="address_first_name"
          v-model="first_name"
          :label="$t('First name')"
          :disabled="disabled"
          :errors="soleTraderDetailsErrors['owner_address.name.first_name']"
          type="text"
        />
      </div>
      <div class="addition-form-row__column addition-form-row__column--twice">
        <AkInput
          id="address_last_name"
          v-model="last_name"
          :label="$t('Last name')"
          :disabled="disabled"
          :errors="soleTraderDetailsErrors['owner_address.name.last_name']"
          type="text"
        />
      </div>
    </div>
    <div class="addition-form-row">
      <div class="addition-form-row__column">
        <label class="label-revamp">{{ $t('Birth date') }}</label>
        <input
          id="birthdate"
          v-model="owner_birthdate"
          v-mask="'##/##/####'"
          :disabled="disabled"
          class="form-control input-rewamp"
          type="text"
          :class="{ error: soleTraderDetailsErrors.owner_birthdate }"
        />
        <div
          v-for="(error, index) in soleTraderDetailsErrors.owner_birthdate"
          :key="`birthdate_error_${index}`"
          class="error"
        >
          {{ error }}
        </div>
      </div>
    </div>
    <div class="addition-form-row">
      <AkParagraph
        size="sm"
        class="addition-form__description"
      >
        <i18n-t
          keypath="Please enter the owner's {0} address"
          tag="div"
        >
          <strong>{{ $t('residential') }}</strong>
        </i18n-t>
      </AkParagraph>
    </div>
    <div class="addition-form-row">
      <div class="addition-form-row__column">
        <AkInput
          id="address_street"
          v-model="street"
          :label="$t('Street')"
          :disabled="disabled"
          :errors="soleTraderDetailsErrors['owner_address.street']"
          type="text"
        />
      </div>
    </div>
    <div class="addition-form-row">
      <div class="addition-form-row__column addition-form-row__column--twice">
        <AkInput
          id="address_postal_code"
          v-model="postal_code"
          :label="$t('Postal code')"
          :disabled="disabled"
          :errors="soleTraderDetailsErrors['owner_address.postal_code']"
          type="text"
        />
      </div>
      <div class="addition-form-row__column addition-form-row__column--twice">
        <AkInput
          id="address_city"
          v-model="city"
          :label="$t('City')"
          :disabled="disabled"
          :errors="soleTraderDetailsErrors['owner_address.city']"
          type="text"
        />
      </div>
    </div>
    <div class="addition-form-row">
      <div
        v-if="getFormattedCountries"
        class="addition-form-row__column"
      >
        <AkAutocomplete
          v-model="country_id"
          :options="countries"
          :label="$t('Country')"
          data-testid="countrySelector"
          :disabled="disabled"
          :reset-btn-visible="false"
          :errors="soleTraderDetailsErrors['owner_address.country_id']"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import { isEnabled, FeatureFlag } from '@/services/features';
import { AsyncScript, initScript } from '@/modules/shared/services/load-async-script';
import { defineComponent } from 'vue';
import { toAddress } from '@/modules/shared/utils/tooling';
import { CountryLight } from '@/types/country';
import { AkInput, AkAutocomplete } from '@ankorstore/design-system';
export default defineComponent({
  name: 'FormSoleTrader',
  components: { AkInput, AkAutocomplete },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    withBusinessName: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['changed'],
  data() {
    return {
      formattedCountries: [],
      autocomplete: null,
      autocompleteListener: null,
    };
  },
  computed: {
    ...mapGetters('global/countries', ['getOrderedNoTopCountries', 'getOrderedTopCountries']),
    ...mapGetters('account/soleTrader', ['soleTraderDetailsErrors', 'soleTrader']),
    owner_birthdate: {
      get: function () {
        return this.soleTrader?.owner_birthdate;
      },
      set: function (value) {
        this.setSoleTraderDetail({ owner_birthdate: value });
      },
    },
    business_name: {
      get: function () {
        return this.soleTrader?.business?.name;
      },
      set: function (value) {
        this.setSoleTraderDetail({ business_name: value });
      },
    },
    first_name: {
      get: function () {
        return this.soleTrader?.owner_address?.first_name;
      },
      set: function (value) {
        this.setSoleTraderDetail({ first_name: value });
      },
    },
    last_name: {
      get: function () {
        return this.soleTrader?.owner_address?.last_name;
      },
      set: function (value) {
        this.setSoleTraderDetail({ last_name: value });
      },
    },
    street: {
      get: function () {
        return this.soleTrader?.owner_address?.street;
      },
      set: function (value) {
        this.setSoleTraderDetail({ street: value });
      },
    },
    postal_code: {
      get: function () {
        return this.soleTrader?.owner_address?.postal_code;
      },
      set: function (value) {
        this.setSoleTraderDetail({ postal_code: value });
      },
    },
    city: {
      get: function () {
        return this.soleTrader?.owner_address?.city;
      },
      set: function (value) {
        this.setSoleTraderDetail({ city: value });
      },
    },
    country_id: {
      get: function () {
        return this.soleTrader?.owner_address?.country_id;
      },
      set: function (value) {
        this.setSoleTraderDetail({ country_id: value });
      },
    },
    getFormattedCountries(): CountryLight[][] {
      return [
        [
          ...this.getOrderedTopCountries.map(({ id, name }) => ({
            label: name,
            value: id,
          })),
        ],
        [
          ...this.getOrderedNoTopCountries.map(({ id, name }) => ({
            label: name,
            value: id,
          })),
        ],
      ];
    },
    countries(): CountryLight[] {
      return this.getOrderedTopCountries.concat(this.getOrderedNoTopCountries).map(({ id, name }) => ({
        label: name,
        value: id,
      }));
    },
  },
  watch: {
    topCountriesOrder(countries): void {
      if (isEnabled(FeatureFlag.RegisterGeodataVerification)) {
        initScript(AsyncScript.GOOGLE_MAP, () => {
          this.autocomplete = new google.maps.places.Autocomplete(this.$refs.address_street as HTMLInputElement, {
            componentRestrictions: { country: countries },
            fields: ['address_components', 'geometry'],
            types: ['address'],
          });

          this.autocompleteListener = this.autocomplete.addListener('place_changed', this.fillInAddress);
        });
      }
    },
  },
  created(): void {
    this.$store.dispatch('global/countries/fetchCountries');
  },
  methods: {
    ...mapActions('account/soleTrader', ['setSoleTraderDetail']),
    notifyChanged(): void {
      this.$emit('changed');
    },
    fillInAddress(): void {
      const place = this.autocomplete.getPlace();

      const address = toAddress(place, (countryShortName) => {
        return this.$store.state.global.countries.countries[countryShortName];
      });

      this.street = address.street;
      this.postal_code = address.postal_code;
      this.country_id = address.country?.id;
      this.city = address.city;
    },
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';

input,
option,
select {
  font-size: 13px;
}

input:disabled,
select:disabled {
  @apply ds-text-neutral-700;
}

div.error {
  @apply ds-text-error;
  margin-top: 5px;
}

.form-group {
  flex-direction: column;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 8px 8px;
}

input.error {
  @apply ds-border ds-border-solid ds-border-error;
}

.modal-body form .content input {
  @apply ds-border ds-border-solid ds-border-neutral-300;
  padding: 8px;
}

.addition-form {
  .addition-form-row {
    @apply ds-mb-5;
  }
  &__description {
    @apply ds-text-neutral-900;
  }
}

.addition-form-row {
  @apply ds-gap-4 lg:ds-flex;
  &__column {
    @apply ds-w-full;
    &--twice {
      @apply ds-w-full lg:ds-w-1/2;
    }
  }
}

#birthdate.input-rewamp {
  @apply ds-rounded-md ds-border-neutral-500 ds-px-3;
  min-height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.label-revamp {
  @apply ds-mb-2 ds-block;
}
</style>
