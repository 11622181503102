<template>
  <div
    class="cart-button ds-relative ds-h-full ds-font-basic ds-whitespace-normal ds-m-0 xl:ds-ml-4 xl:ds-pl-2 ds-pt-4 ds-pb-0 xl:ds-p-0"
  >
    <component
      :is="isClientRoutingEnabled() ? 'router-link' : 'a'"
      v-bind="cartButtonProperties()"
      :class="{
        'cart-button__link': true,
        'ds-relative': true,
      }"
      data-testid="retailerCartLink"
      @click="triggerLoadingFeedback"
    >
      <div class="cart-button__icon ds-relative ds-inline-block">
        <AkIcon
          symbol="bag"
          size="md"
        />
        <BadgeCountArticles />
      </div>
    </component>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import BadgeCountArticles from '@/components/cart/badge-count-articles.vue';
import { withBreakpoints } from '@/modules/design-system-candidates';
import Analytics from '@/services/analytics';
import { HeaderCartClickEvent } from '@/services/analytics/events/cart/cart-clicks-event';
import { defineComponent } from 'vue';
import { hasRoute } from '@/router-util';

export default defineComponent({
  name: 'RetailerCartButton',
  components: { BadgeCountArticles },
  mixins: [withBreakpoints],

  computed: {
    ...mapGetters('headerBadges', ['getProductBadgesCount']),
  },
  methods: {
    cartButtonProperties() {
      return this.isClientRoutingEnabled() ? { to: '/carts' } : { href: '/carts' };
    },
    isClientRoutingEnabled(): boolean {
      const regexCheckoutThankYouPage = /checkout\/(\d){1,}\/thanks/g;
      return hasRoute(this.$router, regexCheckoutThankYouPage);
    },
    triggerLoadingFeedback(): void {
      Analytics.track(new HeaderCartClickEvent(this.getProductBadgesCount));
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.cart-button {
  $root: &;
  &:hover :not(&__link--loading) &__icon,
  &:hover :not(&__link--loading) &__text {
    @apply ds-text-neutral-700;
  }
  &__icon {
    &:deep(.icon-bag) {
      font-size: 1.25rem;
    }
  }
}
</style>
