/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateBrandsFrancoSettingsCorridorEnum,
  CreateBrandsFrancoSettingsData,
  CreateBrandsFrancoSettingsTypeEnum,
  Failure,
  GetApiInternalV1BrandsBrandIdData,
  GetApiInternalV1BrandsBrandIdParams,
  GetBrandRetailerData,
  GetBrandsCompanyData,
  GetBrandsShippingSettingsData,
  ListBrandsFrancoData,
  ListBrandsFrancoParams,
  ListBrandsFrancoSettingsData,
  PatchApiInternalV1BrandsBrandIdStatusEnum,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Brands<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Get Brand details
   *
   * @tags Brands
   * @name GetApiInternalV1BrandsBrandId
   * @summary Get Brand details
   * @request GET:/api/internal/v1/brands/{brandId}
   * @response `200` `GetApiInternalV1BrandsBrandIdData` Single Brand resource response
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   */
  getApiInternalV1BrandsBrandId = ({ brandId, ...query }: GetApiInternalV1BrandsBrandIdParams, params: RequestParams = {}) =>
    this.request<GetApiInternalV1BrandsBrandIdData, Failure>({
      path: `/api/internal/v1/brands/${brandId}`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description Updates Brand details
   *
   * @tags Brands
   * @name PatchApiInternalV1BrandsBrandId
   * @summary Update Brand details
   * @request PATCH:/api/internal/v1/brands/{brandId}
   * @response `200` `void`
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `422` `Failure`
   * @response `500` `Failure`
   */
  patchApiInternalV1BrandsBrandId = (
    brandId: string,
    data: {
      status?: PatchApiInternalV1BrandsBrandIdStatusEnum;
      /** @format date */
      holidayStartDate?: string;
      /** @format date */
      holidayEndDate?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<void, Failure>({
      path: `/api/internal/v1/brands/${brandId}`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Gets Brand's company details
   *
   * @tags Brands
   * @name GetBrandsCompany
   * @summary Get Brand's company
   * @request GET:/api/internal/v1/brands/company/{companyId}
   * @response `200` `GetBrandsCompanyData` Single company resource response
   * @response `406` `Failure`
   * @response `415` `Failure`
   */
  getBrandsCompany = (companyId: string, params: RequestParams = {}) =>
    this.request<GetBrandsCompanyData, Failure>({
      path: `/api/internal/v1/brands/company/${companyId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Updates Brand's company details
   *
   * @tags Brands
   * @name UpdateBrandsCompany
   * @summary Update Brand's company
   * @request PATCH:/api/internal/v1/brands/company/{companyId}
   * @response `200` `void`
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `422` `Failure`
   * @response `500` `Failure`
   */
  updateBrandsCompany = (
    companyId: string,
    data: {
      founded_in_year: number;
      company_name: string;
      company_invoice_description: string;
      vat_exemption: boolean;
      vat_number?: string;
      tax_number?: string;
      is_sole_trader?: boolean;
      sole_trader_number?: string;
      above_intracomm_threshold?: boolean | null;
    },
    params: RequestParams = {}
  ) =>
    this.request<void, Failure>({
      path: `/api/internal/v1/brands/company/${companyId}`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Returns Franco and fee for a given list of brands. This endpoint can be used only by retailers.
   *
   * @tags Franco
   * @name ListBrandsFranco
   * @summary Returns Franco and fee for a given list of brands.
   * @request GET:/api/internal/v1/brands/franco
   * @response `200` `ListBrandsFrancoData` Collection of Franco resources
   * @response `400` `Failure`
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  listBrandsFranco = (query: ListBrandsFrancoParams, params: RequestParams = {}) =>
    this.request<ListBrandsFrancoData, Failure>({
      path: `/api/internal/v1/brands/franco`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description List the Franco settings of a brand This endpoint can be used only by brands.
   *
   * @tags Franco, Franco Settings
   * @name ListBrandsFrancoSettings
   * @summary List the Franco settings of a brand
   * @request GET:/api/internal/v1/brands/franco-settings
   * @response `200` `ListBrandsFrancoSettingsData` Collection of Franco Settings resources
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  listBrandsFrancoSettings = (params: RequestParams = {}) =>
    this.request<ListBrandsFrancoSettingsData, Failure>({
      path: `/api/internal/v1/brands/franco-settings`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description If the values changes for the given corridor, it saves a new Franco Settings entry and returns a 201 status code. If the values are identical, it only returns the existing entry with a 200 status code. This endpoint can be used only by brands.
   *
   * @tags Franco, Franco Settings
   * @name CreateBrandsFrancoSettings
   * @summary Save a new Franco Settings entry
   * @request POST:/api/internal/v1/brands/franco-settings
   * @response `200` `CreateBrandsFrancoSettingsData` Franco Settings resource
   * @response `201` `void`
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `422` `Failure`
   * @response `500` `Failure`
   */
  createBrandsFrancoSettings = (
    data: {
      data: {
        type?: CreateBrandsFrancoSettingsTypeEnum;
        attributes: {
          corridor: CreateBrandsFrancoSettingsCorridorEnum;
          amount: number;
          fee: number;
        };
      };
    },
    params: RequestParams = {}
  ) =>
    this.request<CreateBrandsFrancoSettingsData, Failure>({
      path: `/api/internal/v1/brands/franco-settings`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Gets Brand's shipping settings
   *
   * @tags Brands
   * @name GetBrandsShippingSettings
   * @summary Get Brand's shipping settings
   * @request GET:/api/internal/v1/brands/shipping-settings/{shippingSettingsId}
   * @response `200` `GetBrandsShippingSettingsData` Single shipping settings resource response
   * @response `406` `Failure`
   * @response `415` `Failure`
   */
  getBrandsShippingSettings = (shippingSettingsId: string, params: RequestParams = {}) =>
    this.request<GetBrandsShippingSettingsData, Failure>({
      path: `/api/internal/v1/brands/shipping-settings/${shippingSettingsId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Updates Brand's shipping settings
   *
   * @tags Brands
   * @name UpdateBrandsShippingSettings
   * @summary Update Brand's shipping settings
   * @request PATCH:/api/internal/v1/brands/shipping-settings/{shippingSettingsId}
   * @response `200` `void`
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `422` `Failure`
   * @response `500` `Failure`
   */
  updateBrandsShippingSettings = (
    shippingSettingsId: string,
    data: {
      publicAddress: {
        street: string;
        postalCode: string;
        city: string;
        countryCode: string;
      };
      shippingAddress: {
        street: string;
        postalCode: string;
        city: string;
        countryCode: string;
      };
      billingAddress: {
        street: string;
        postalCode: string;
        city: string;
        countryCode: string;
      };
      eoriNumber: string;
      lucidNumber: string;
      averageLeadTime: string;
      /** List of country iso codes */
      whitelistCountries?: null | any[];
    },
    params: RequestParams = {}
  ) =>
    this.request<void, Failure>({
      path: `/api/internal/v1/brands/shipping-settings/${shippingSettingsId}`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Returns brand retailer relation
   *
   * @tags Brands
   * @name GetBrandRetailer
   * @summary Get Retailer relation
   * @request GET:/api/internal/v1/brands/retailers/{retailerId}
   * @response `200` `GetBrandRetailerData` Brand Retailer relation
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  getBrandRetailer = (retailerId: string, params: RequestParams = {}) =>
    this.request<GetBrandRetailerData, Failure>({
      path: `/api/internal/v1/brands/retailers/${retailerId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * @description Update brand retailer relation
   *
   * @tags Brands
   * @name UpdateBrandRetailer
   * @summary Update Brand Retailer relation
   * @request PATCH:/api/internal/v1/brands/retailers/{retailerId}
   * @response `200` `void`
   * @response `401` `Failure`
   * @response `403` `Failure`
   * @response `404` `Failure`
   * @response `406` `Failure`
   * @response `415` `Failure`
   * @response `500` `Failure`
   */
  updateBrandRetailer = (
    retailerId: string,
    data: {
      data?: any;
    },
    params: RequestParams = {}
  ) =>
    this.request<void, Failure>({
      path: `/api/internal/v1/brands/retailers/${retailerId}`,
      method: 'PATCH',
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
