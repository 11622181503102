import { addYears, addMonths, addDays, endOfToday, addHours, isAfter, parseISO } from 'date-fns';

type TExpirationData = {
  years?: number;
  months?: number;
  days?: number;
  hours?: number;
};

class WindowStorageItem {
  value: unknown;
  constructor(value: unknown) {
    this.value = value;
  }
}

class WindowStorageItemWithExpiration extends WindowStorageItem {
  expirationDate: string;
  constructor(value: unknown, expiration?: TExpirationData | 'eod') {
    super(value);
    if (expiration) {
      let expirationDate;
      if ('eod' === expiration) {
        expirationDate = endOfToday();
      } else {
        expirationDate = new Date();
        [
          { period: 'years', fn: addYears },
          { period: 'months', fn: addMonths },
          { period: 'days', fn: addDays },
          { period: 'hours', fn: addHours },
        ].forEach(({ period, fn }) => {
          if (expiration[period]) {
            expirationDate = fn(expirationDate, expiration[period]);
          }
        });
      }
      this.expirationDate = expirationDate.toISOString();
    }
  }
}

class ClientStorage {
  storage: Storage;
  constructor(storage) {
    this.storage = storage;
  }
  _getStorageItem(key: string): WindowStorageItemWithExpiration | undefined {
    return JSON.parse(this.storage.getItem(key));
  }
  get(key: string) {
    return this._getStorageItem(key)?.value;
  }
}

export class SessionStorage extends ClientStorage {
  constructor() {
    super(window.sessionStorage);
  }
  set(key: string, value: unknown) {
    this.storage.setItem(key, JSON.stringify(new WindowStorageItem(value)));
  }
  remove(key: string) {
    this.storage.removeItem(key);
  }
}

export class LocalStorage extends ClientStorage {
  constructor(autoRemoveAllExpired = false, autoRemoveExpiredKeys?: string[]) {
    super(window.localStorage);
    if (autoRemoveAllExpired) {
      [...Array(this.storage.length)]
        .map((_value, index) => {
          const key = this.storage.key(index);
          return {
            key,
            hasExpired: this.hasExpired(key),
          };
        })
        .forEach((item) => {
          item.hasExpired && this.remove(item.key);
        });
    } else if (Array.isArray(autoRemoveExpiredKeys)) {
      autoRemoveExpiredKeys.forEach((itemKey) => {
        this.remove(itemKey, true);
      });
    }
  }
  set(key: string, value: unknown, expiration?: TExpirationData | 'eod') {
    this.storage.setItem(key, JSON.stringify(new WindowStorageItemWithExpiration(value, expiration)));
  }
  hasExpired(key: string) {
    const expirationDate = this._getStorageItem(key)?.expirationDate;
    return expirationDate && isAfter(new Date(), parseISO(expirationDate));
  }
  remove(key: string, onlyIfExpired?: boolean) {
    if (onlyIfExpired && !this.hasExpired(key)) {
      return;
    }
    this.storage.removeItem(key);
  }
}

function useStorage(type) {
  const storage = new { session: SessionStorage, local: LocalStorage }[type]();
  const api = Object.assign(
    {
      getStorageItem: storage.get.bind(storage),
      setStorageItem: storage.set.bind(storage),
      removeStorageItem: storage.remove.bind(storage),
    },
    type === 'local'
      ? {
          hasExpired: storage.hasExpired.bind(storage),
        }
      : {}
  );
  return Object.freeze(api);
}

export function useLocalStorage() {
  return useStorage('local');
}

export function useSessionStorage() {
  return useStorage('session');
}
