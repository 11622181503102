import http from '@/services/api/http';
import i18n from '@/services/i18n';
import { CategoryResult, PopularSearch, ServiceResponse, SuggestionServiceApiParams } from './types';
import { memoize } from 'lodash-es';
import { FeatureFlag, getVariant } from '@/services/features';

export const getSuggestionServiceBaseUrl = (): string => {
  const suggestionsService = getVariant(FeatureFlag.SuggestionsService);
  return suggestionsService == 'off' ? window.location.origin : suggestionsService;
};

export const getSuggestionsUrl = (params: SuggestionServiceApiParams) => {
  const url = new URL(`${getSuggestionServiceBaseUrl()}/api/suggestions/v1`);
  url.searchParams.set('locale', i18n.global.locale);

  if (params.query) {
    url.searchParams.set('query', params.query);
  }

  if (params.filters) {
    params.filters.forEach((filter) => {
      url.searchParams.append(`filters`, filter);
    });
  }

  if (params.country) {
    url.searchParams.set('country', params.country.toLowerCase());
  }

  return url.href;
};

export const __fetchAll = async ({
  query = '',
  country,
  filters = ['brands', 'popularSearches', 'boutiques'],
}: SuggestionServiceApiParams): Promise<ServiceResponse> => {
  const response = await http().get(getSuggestionsUrl({ query, filters, country }), {
    noProgressBar: true,
  });
  return response?.data?.data;
};

export const __fetchPopularSearches = async ({
  query = '',
  country,
}: Omit<SuggestionServiceApiParams, 'filters'>): Promise<Omit<PopularSearch, 'id' | 'link'>[]> => {
  const response = await http().get(getSuggestionsUrl({ query, filters: ['popularSearches'], country }), {
    noProgressBar: true,
  });
  return response?.data?.data?.popularSearches;
};

export const __fetchCategories = async ({
  query = '',
  country,
}: Omit<SuggestionServiceApiParams, 'filters'>): Promise<Omit<CategoryResult, 'id'>[]> => {
  const response = await http().get(getSuggestionsUrl({ query, filters: ['categories'], country }), {
    noProgressBar: true,
  });
  return response?.data?.data?.categories;
};

export const fetchAll = memoize(__fetchAll);
export const fetchPopularSearches = memoize(__fetchPopularSearches);
export const fetchCategories = memoize(__fetchCategories);
