import { getBrowserId } from '@/services/features';

const removeNonAsciiChars = (str: string) => str?.replace(/[\u{0080}-\u{FFFF}]/gu, '');

const getSectionId = (args: { sectionType: string; valueProposition: string }) => {
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);

  return window.btoa(
    removeNonAsciiChars(
      JSON.stringify({
        section_type: args.sectionType,
        value_proposition: args.valueProposition,
        nb_hit: today,
        browser_id: getBrowserId(),
      })
    )
  );
};

export default getSectionId;
