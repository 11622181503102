import i18n from '@/services/i18n';
import http from '@/services/api/http';
import { deserialize, JsonApiParamsObject, serialiseParams } from '@/services/utils/jsonapi-parser';
import { post, patch, get, del } from '@/services/fulfillment/utilities';
import { MasterOrder, NafoCustomer } from '@/types/order';

export const getRejectionLabelTranslation = (label) => {
  switch (label) {
    case 'Order not received':
    case 'notReceived':
      return i18n.global.t('Order not received');
    case 'Order with damaged products':
    case 'damagedItems':
      return i18n.global.t('Order with damaged products');
    case 'Order with wrong products':
    case 'wrongItems':
      return i18n.global.t('Order with wrong products');
    case 'Order with missing products':
    case 'missingItems':
      return i18n.global.t('Order with missing products');
    case 'Order with defective products':
    case 'defectiveItems':
      return i18n.global.t('Order with defective products');
    default:
      // TODO: Careful! This case won't permit to Lokalise to generate translations for these keys,
      //  however it permits to show the english version at least in case we didn't add manually the keys on Lokalise
      // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
      return i18n.global.t(label);
  }
};

/**
 * Get the master order uuid by the internal order id
 * @param id - string
 * @returns {Promise<string>}
 */
export const getMasterOrderUuidByInternalOrderId: (id: string) => Promise<string> = async (id: string): Promise<string> => {
  const data = await http().get(`/api/internal/v1/ordering/orders/-actions/get-master-order-uuid-by-internal-order-id/${id}`);

  return deserialize(data.data).data.masterOrderUuid;
};

type MasterOrderRelations = 'orderShipping' | 'orderIssue' | 'brandRetailerRelationship';
type MasterOrderFieldParam = {
  [K in 'orders' | MasterOrderRelations]?: string[];
};
export type GetMasterOrderParams = {
  include?: MasterOrderRelations[];
  fields?: MasterOrderFieldParam;
};

interface GetMasterOrderResponse {
  data: MasterOrder;
  jsonapi: {
    version: string;
  };
}

export const getMasterOrder = async (
  id: string,
  fetchParams?: JsonApiParamsObject<'orders' | 'orderShipping' | 'orderIssue' | 'brandRetailerRelationship'>,
  fetchOptions?: { noProgressBar: boolean }
): Promise<GetMasterOrderResponse> => {
  if (Number.isInteger(+id)) {
    id = await getMasterOrderUuidByInternalOrderId(id);
  }
  const response = await http().get<GetMasterOrderResponse>(`/api/internal/v1/ordering/orders/${id}`, {
    params: serialiseParams(fetchParams),
    noProgressBar: fetchOptions?.noProgressBar,
  });

  return deserialize(response.data);
};

export const postNafoCustomer = async (payload: Omit<NafoCustomer, 'id'>): Promise<void> => {
  return post('/api/internal/v1/ordering/external-customers', payload);
};

export const patchNafoCustomer = async (customerId: string, payload: Omit<NafoCustomer, 'id'>): Promise<void> => {
  return patch(`/api/internal/v1/ordering/external-customers/${customerId}`, payload);
};

export const getNafoCustomer = async (customerId: string): Promise<NafoCustomer> => {
  return get(`/api/internal/v1/ordering/external-customers/${customerId}`);
};

export const searchNafoCustomer = async (searchParam: string): Promise<NafoCustomer[]> => {
  return await get(`/api/internal/v1/ordering/external-customers?search-term=${searchParam}`);
};

export const deleteNafoCustomer = async (customerId: string): Promise<void> => {
  return await del(`/api/internal/v1/ordering/external-customers/${customerId}`);
};
