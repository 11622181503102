import { Amount } from '@/types/amount';
import { PaginationItem } from './pagination';

export interface InvitedStatus {
  id: string | number;
  status: InvitedStatusValue;
  reason: string;
  gift_amount: Amount;
  brand_id: number | null;
  website_url: string;
  created_at: string;
  updated_at: string;
}

export interface R2BStatus {
  total_gift_amount: Amount;
  brands_invited: InvitedStatus[];
  brands_pagination: PaginationItem[];
  statuses: InvitedStatusValue[];
}

export interface R2BOfferConfiguration {
  type: 'r2bOfferConfiguration';
  id: number;
  name: string;
  start_date: Date;
  end_date: Date;
  is_default: boolean;
  offers: R2BOffers[];
}

export type R2BOffers = R2BOfferDiscount | R2BOfferPromocode | R2BOfferWalletCredit;

export enum R2BOfferType {
  Discount = 'discount',
  Promocode = 'promocode',
  WalletCredit = 'wallet_credit',
}

export type DurationType = 'days' | 'months' | 'years';

interface R2BOffer {
  id: number;
  name: string;
  duration: number | null;
  duration_type: DurationType;
}

export interface R2BOfferDiscount extends R2BOffer {
  type: R2BOfferType.Discount;
  percentage: number;
}

export interface R2BOfferPromocode extends R2BOffer {
  type: R2BOfferType.Promocode;
  amount: Amount;
}

export interface R2BOfferWalletCredit extends R2BOffer {
  type: R2BOfferType.WalletCredit;
  amount: Amount;
}

export interface GetStatusParameters {
  url: string | null;
  status: InvitedStatusValue | null;
}

export enum InvitedStatusValue {
  Waiting = 'waiting_list',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Pending = 'pending',
  Progress = 'in_progress',
  Referred = 'already_referred',
  Expired = 'expired',
}

export enum R2bSubmissionFormStatus {
  Input = 'input',
  Submitted = 'submitted',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum StatusReasonsMessages {
  duplicate = 'duplicate',
  not_qualified = 'unqualified',
  lead_unqualified = 'unqualified',
  lead_bad_contact_info = 'failedContacting',
  lead_unresponsive = 'failedContacting',
  lead_not_interested = 'uninterested',
  lead_do_not_contact = 'uninterested',
  opportunity_closed_lost = 'uninterested',
  brand_live = 'brandLive',
  brand_already_referred = 'brandAlreadyReferred',
  already_live = 'brandAlreadyLive',
  already_onboarding = 'brandAlreadyOnboarding',
  joined_through_another_retailer = 'brandJoinedWithOtherRetailer',
  brand_under_review = 'brandUnderReview',
  brand_contacted = 'brandContacted',
  brand_is_onboarding = 'brandIsOnboarding',
  brand_not_interested = 'brandNotInterested',
  brand_unresponsive = 'brandUnresponsive',
  products_not_supported = 'productsNotSupported',
  ankorstore_criterias = 'ankorstoreCriterias',
  country_not_supported = 'countryNotSupported',
  not_successful = 'notSuccessful',
}
