import type { AxiosInstance } from 'axios';
import type { Store } from 'vuex';

export const configureHttp = (http: AxiosInstance, store: Store<unknown>) => {
  http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  // Handling progress bar and the fact that for some api call we don't want to progress bar to pop
  // if you don't want the progress bar add noProgressBar: true to your axios call in your get/post/patch/delete...
  // example:
  //       return window.axios
  //         .post(`/api/checkout/promocodes`, {code}, {'noProgressBar': true});
  globalThis.axiosApiCount = 0;
  http.interceptors.request.use((config) => {
    if (!config.noProgressBar) {
      if (!globalThis.axiosApiCount && store) {
        store.commit('SET_PROGRESS_BAR_STATE', 'loading');
      }

      globalThis.axiosApiCount++;
    }

    if (config.apiCartCall) {
      if (store) {
        store.commit('cart/CART_API_CART_CALL_INCREMENT');
      }
    }

    return config;
  });
  http.interceptors.response.use(
    (response) => {
      if (!response.config.noProgressBar) {
        globalThis.axiosApiCount--;
        if (!globalThis.axiosApiCount && store) {
          store.commit('SET_PROGRESS_BAR_STATE', 'ending');
        }
      }

      if (response?.config?.apiCartCall) {
        if (store) {
          store.commit('cart/CART_API_CART_CALL_DECREMENT');
        }
      }

      return response;
    },
    (error) => {
      if (error?.response?.config?.noProgressBar !== true) {
        globalThis.axiosApiCount--;
        if (!globalThis.axiosApiCount && store) {
          store.commit('SET_PROGRESS_BAR_STATE', 'ending');
        }
      }

      if (error?.response?.config?.apiCartCall) {
        if (store) {
          store.commit('cart/CART_API_CART_CALL_DECREMENT');
        }
      }

      return Promise.reject(error);
    }
  );
  // END OF Handling progress bar
};
