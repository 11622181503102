<script lang="ts">
import { imgCdnUrl } from '@bc/shared';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BrandLogo',
  props: {
    logo: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    responsive: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    srcSet(): string {
      const srcSet = [];

      if (this.responsive) {
        srcSet.push(`${imgCdnUrl(this.logo, '48')} 48w`);
      }

      srcSet.push(`${imgCdnUrl(this.logo, '72')} 72w`);

      return srcSet.join(', ');
    },
    sizes(): string {
      const sizes = [];

      if (this.responsive) {
        sizes.push(`(max-width: 640px) 48px`);
      }

      sizes.push(`72px`);

      return sizes.join(', ');
    },
    src(): string {
      return imgCdnUrl(this.logo, '72');
    },
  },
});
</script>
<template>
  <div
    :class="[
      'brand-logo__container',
      'ds-w-max ds-rounded-full ds-overflow-hidden',
      'ds-border-solid ds-border-neutral-300 ds-border',
    ]"
  >
    <img
      loading="lazy"
      :sizes="sizes"
      :srcset="srcSet"
      :src="src"
      :alt="alt"
    >
  </div>
</template>

<style lang="scss" scoped>
.brand-logo__container {
  width: 72px;
  height: 72px;

  @media (max-width: 640px) {
    width: 48px;
    height: 48px;
  }
}
</style>
