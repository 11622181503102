<script lang="ts">
import { defineComponent } from 'vue';
import { AisCurrentRefinements } from 'vue-instantsearch/vue3/es';
import SelectedCategoryTitle from './selected-category-title.vue';
import { extractSelectedCategoryId, deselectCategory } from './utils';
import { type Item } from './types';

export default defineComponent({
  name: 'SelectedCategoryTitleAis',
  components: {
    AisCurrentRefinements,
    SelectedCategoryTitle,
  },
  emits: ['categoryDeselected'],
  setup(_props, { emit }) {
    const categoryAttributes = [
      'catalog_hierarchical_categories.v3.lvl0',
      'catalog_hierarchical_categories.v3.lvl1',
      'catalog_hierarchical_categories.v3.lvl2',
    ];

    const handleDeselectClicked = (items: Item[]) => {
      emit('categoryDeselected');
      deselectCategory(items);
    };

    return {
      categoryAttributes,
      extractSelectedCategoryId,
      handleDeselectClicked,
    };
  },
});
</script>

<template>
  <ais-current-refinements :included-attributes="categoryAttributes">
    <template #default="{ items }">
      <SelectedCategoryTitle
        :selected-category-id="extractSelectedCategoryId(items)"
        @clicked="handleDeselectClicked(items)"
      />
    </template>
  </ais-current-refinements>
</template>
