import Analytics from '@/services/analytics';
import { CatalogFiltersEvent } from '@/services/analytics/events/catalog-filters/catalog-filters.event';
import { CatalogFilterEventName } from '@/services/analytics/properties/catalog-filters-property';
import Product, { ProductHit } from '@/types/product';
import {
  BrandHit,
  NormalTileType,
  ProductTile,
  BrandTile,
  ESProductHit,
  ESSponsoredProductHit,
} from '@bc/discovery/domain/catalog';
import {
  trackProductHitsUpdated,
  trackBrandHitsUpdated,
  trackBrandClickedInProductTileSegment,
} from './catalog-browser-tracking';

import { Config, SnippetTemplate, Context } from '@bc/discovery/domain/catalog';
import { isEqual } from 'lodash-es';
import { ProductProperty } from '@/services/analytics/properties/product-property';
import { BrandProperty } from '@/services/analytics/properties/brand-property';
import { ProductQuickAddToCartEvent } from '@/services/analytics/events/product-quick-added-to-cart.event';
import UserClick from '@/services/analytics/events/user-click.event';
import { CatalogTrackingData } from '../composables/use-catalog-tracking-data';
import type { RefinementsWithCount, FacetRefinement, NumericRefinement } from '../types/ais-state';
import { FacetRefinementNames } from '../constants/facet-refinement-names';
import { SearchParameters } from 'algoliasearch-helper';
import { DeepReadonly } from 'vue';
import { getNativeAdClickOrigin, SponsoredProductTile, trackProductClickWithAardvarkService } from '@bc/advertisement';
import { CatalogueType, TrackingService } from '@bc/discovery/domain/tracking';
import { SearchCategory } from '@/types/api/category';

type FacetRefinementWithType = { name: string; value: SearchParameters.FacetList; type: FacetRefinementNames };
type NumericRefinementWithType = { name: string; value: SearchParameters.OperatorList; type: FacetRefinementNames };
type RefinementWithType = FacetRefinementWithType | NumericRefinementWithType;

type TrackingDisjunctiveOrHierarchicalFacetRefinement = {
  name: string;
  value: string[] | number[];
};

type TrackingNumericRefinement = {
  name: string;
  value: { min?: number; max?: number };
};

type RefinementItem = TrackingDisjunctiveOrHierarchicalFacetRefinement | TrackingNumericRefinement;

export const contextComponentMap: Record<Exclude<Context, Context.PREORDER_PRODUCTS | Context.PREORDER_BRANDS>, string> = {
  [Context.BRAND]: 'brand_page',
  [Context.SEARCH]: 'search_page',
  [Context.COLLECTION_PRODUCTS]: 'collection_products_page',
  [Context.COLLECTION_BRANDS]: 'collection_brands_page',
  [Context.CATEGORY_PRODUCTS]: 'category_page',
  [Context.CATEGORY_BRANDS]: 'category_page',
  [Context.NEW_PRODUCTS]: 'new',
  [Context.NEW_BRANDS]: 'new',
  [Context.WHOLESALE]: 'wholesale',
};

export const catalogTypeMap: Record<Exclude<Context, Context.PREORDER_PRODUCTS | Context.PREORDER_BRANDS>, CatalogueType> = {
  [Context.BRAND]: CatalogueType.BrandSearch,
  [Context.SEARCH]: CatalogueType.Search,
  [Context.COLLECTION_PRODUCTS]: CatalogueType.Collection,
  [Context.COLLECTION_BRANDS]: CatalogueType.Collection,
  [Context.CATEGORY_PRODUCTS]: CatalogueType.Category,
  [Context.CATEGORY_BRANDS]: CatalogueType.Category,
  [Context.NEW_PRODUCTS]: CatalogueType.New,
  [Context.NEW_BRANDS]: CatalogueType.New,
  [Context.WHOLESALE]: CatalogueType.Wholesale,
};

const mapDisjunctiveRefinement = (
  refinement: FacetRefinementWithType,
  trackingName: string
): TrackingDisjunctiveOrHierarchicalFacetRefinement => ({
  name: trackingName,
  value: refinement.value,
});

// There's a discrepancy between the way the API returns the value for
// hierarchical refinements and the way it's supposed to be as per AIS docs.
//
// * We are getting an array with one element that contains the full path separated by '>'
//
// We should (?) be getting algoliasearchHelperSearchParameters.HierarchicalFacet
//
// type HierarchicalFacet = {
//   name: string;
//   attributes: string[];
//   separator?: string;
//   rootPath?: string | null;
//   showParentLevel?: boolean;
// };
const mapHierarchicalRefinement = (
  refinement: FacetRefinementWithType,
  trackingName: string
): TrackingDisjunctiveOrHierarchicalFacetRefinement => ({
  name: trackingName,
  value: refinement.value[0]?.split('>').map((value) => value.trim?.()) ?? [],
});

const mapNumericRefinement = (refinement: NumericRefinementWithType, trackingName: string): TrackingNumericRefinement => ({
  name: trackingName,
  value: {
    min: refinement.value['>=']?.[0] as number,
    max: refinement.value['<=']?.[0] as number,
  },
});

const mapRefinement = (refinement: RefinementWithType, trackingName: string): RefinementItem => {
  switch (refinement?.type) {
    case FacetRefinementNames.DisjunctiveFacetsRefinements:
      return mapDisjunctiveRefinement(refinement as FacetRefinementWithType, trackingName);
    case FacetRefinementNames.HierarchicalFacetsRefinements:
      return mapHierarchicalRefinement(refinement as FacetRefinementWithType, trackingName);
    case FacetRefinementNames.NumericRefinements:
      return mapNumericRefinement(refinement as NumericRefinementWithType, trackingName);
    default:
      return { name: trackingName, value: [] };
  }
};

export const mapRefinements = (config: DeepReadonly<Config>, refinementsWithCount: RefinementsWithCount) => {
  const refinements = Object.entries(refinementsWithCount)
    .filter((item): item is [FacetRefinementNames, FacetRefinement | NumericRefinement] => item[0] !== 'refinementsCount')
    .reduce<RefinementWithType[]>((accumulator, [refinementType, refinement]) => {
      Object.entries(refinement).forEach(([name, value]) => {
        if (refinementType === FacetRefinementNames.NumericRefinements) {
          accumulator.push({ name, value: value as NumericRefinement, type: refinementType });
        } else {
          accumulator.push({ name, value: value as FacetRefinement, type: refinementType });
        }
      });
      return accumulator;
    }, [] as RefinementWithType[]);

  const facets = config.Facets?.filter((facet) => facet.display).flatMap((facet) => ('facets' in facet ? facet.facets : facet));
  return facets.map((facet) => {
    const refinement = refinements.find(({ name }) => name === facet.name);
    return mapRefinement(refinement, facet.trackingName);
  });
};

const findFirstChangedFilter = (currentRefinements: RefinementItem[], previousRefinements: RefinementItem[]) => {
  if (!previousRefinements) {
    return undefined;
  }
  return currentRefinements.find((currentRefinement) => {
    const previousRefinement = previousRefinements.find(
      (previousRefinement) => previousRefinement.name === currentRefinement.name
    );
    return !previousRefinement || !isEqual(currentRefinement.value, previousRefinement.value);
  });
};

export const _createFiltersTracker = (catalogTrackingData: CatalogTrackingData) => {
  let previousRefinements: RefinementItem[];

  return (refinements: RefinementsWithCount) => {
    const trackingRefinements = mapRefinements(catalogTrackingData.config.value, refinements);
    const changedRefinement = findFirstChangedFilter(trackingRefinements, previousRefinements);

    // Avoid dispatching an event with no filters applied when page loads.
    if (refinements.refinementCount || previousRefinements) {
      Analytics.track(
        new CatalogFiltersEvent({
          name: CatalogFilterEventName.FilterApplied,
          properties: {
            filter: changedRefinement?.name,
            filters: trackingRefinements,
            filtersQuery: refinements.filters,
          },
        })
      );
    }

    previousRefinements = trackingRefinements;
  };
};

const isSponsoredProductTile = (hit: ProductHit) => 'ad' in hit && hit.ad;

export const mapProductHits = (hits: ProductHit[]): Array<ProductTile | SponsoredProductTile> =>
  hits.map((hit) => ({
    type: isSponsoredProductTile(hit) ? NormalTileType.SponsoredProduct : NormalTileType.Product,
    product: hit,
    id: hit.id,
  }));

export const mapBrandHits = (hits: BrandHit[]): BrandTile[] =>
  hits.map((hit) => ({
    type: NormalTileType.Brand,
    brand: hit,
    id: hit.id,
  }));

export const _trackProductSection = (
  catalogTrackingData: CatalogTrackingData,
  trackingRefinements: RefinementItem[],
  page: number,
  hits: ProductHit[]
) => {
  trackProductHitsUpdated({
    valueProposition: {
      value_proposition: catalogTrackingData.valueProposition.value,
      value_proposition_id: catalogTrackingData.valuePropositionId?.value,
    },
    catalogueType: catalogTrackingData.catalogType.value,
    view: 0,
    pageIndex: page,
    sectionId: catalogTrackingData.trackingSectionId.value,
    filters: trackingRefinements,
    // This is a partial object of SearchCategory, but it's enough for the event.
    // trackProductHitsUpdated is expecting a SearchCategory object, but it only uses full_name.
    filterState: { selectedCategories: [{ full_name: catalogTrackingData.selectedCategoryName?.value } as SearchCategory] },
    tiles: mapProductHits(hits),
  });
};

export const _trackBrandSection = (
  catalogTrackingData: CatalogTrackingData,
  trackingRefinements: RefinementItem[],
  page: number,
  hits: BrandHit[]
) => {
  trackBrandHitsUpdated({
    valueProposition: {
      value_proposition: catalogTrackingData.valueProposition.value,
      value_proposition_id: catalogTrackingData.valuePropositionId?.value,
    },
    catalogueType: catalogTrackingData.catalogType.value,
    view: 1,
    pageIndex: page,
    sectionId: catalogTrackingData.trackingSectionId.value,
    filters: trackingRefinements,
    tiles: mapBrandHits(hits),
  });
};

export const getCatalogTrackingHandlers = (catalogTrackingData: CatalogTrackingData) => {
  const filtersTracker = _createFiltersTracker(catalogTrackingData);

  const trackSectionCreated = (refinements: RefinementsWithCount, page: number, hits: ProductHit[] | BrandHit[]) => {
    const trackingRefinements = mapRefinements(catalogTrackingData.config.value, refinements);

    const isProductSection = catalogTrackingData.config.value.template === SnippetTemplate.PRODUCTS;
    const isBrandSection = catalogTrackingData.config.value.template === SnippetTemplate.BRANDS;
    if (isProductSection) {
      _trackProductSection(catalogTrackingData, trackingRefinements, page, hits as ProductHit[]);
    }

    if (isBrandSection) {
      _trackBrandSection(catalogTrackingData, trackingRefinements, page, hits as BrandHit[]);
    }
  };

  const trackBrandSuggestionClicked = async (trackingData: { brandId: number }) => {
    const payload = {
      component: catalogTrackingData.component.value,
      action: 'brand_suggestion_clicked_top_page',
      itemType: 'brand',
      id_section: catalogTrackingData.trackingSectionId.value,
      brandId: trackingData.brandId,
    };
    await Analytics.track(new UserClick(payload));
  };

  const trackTileProductClicked = async (trackingData: { page: number; product: ESProductHit; position: number }) => {
    const payload = {
      component: catalogTrackingData.component.value,
      action: 'product_clicked',
      itemType: 'product',
      id_section: catalogTrackingData.trackingSectionId.value,
      page_number: trackingData.page,
      productId: trackingData.product.id,
      position: trackingData.position,
    };
    await Analytics.track(new UserClick(payload));
  };

  const trackTileBrandClicked = async (trackingData: { page: number; brandId: number; position: number }) => {
    const payload = {
      component: catalogTrackingData.component.value,
      action: 'brand_clicked',
      itemType: 'brand',
      id_section: catalogTrackingData.trackingSectionId.value,
      page_number: trackingData.page,
      brandId: trackingData.brandId,
      position: trackingData.position,
    };
    await Analytics.track(new UserClick(payload));
  };

  const trackTileSponsoredProductClicked = async (trackingData: {
    position: number;
    page: number;
    eventTarget: Element;
    product: ESSponsoredProductHit;
  }) => {
    await Analytics.track(
      new UserClick({
        component: 'native_ads',
        action: 'product_clicked',
        itemType: 'product',
        productId: trackingData.product.id,
        position: trackingData.position,
        page_number: trackingData.page,
        id_section: catalogTrackingData.trackingSectionId.value,
      })
    );
    const adId = trackingData.product.aardvarkServeId || trackingData.product.ad.metadata.ad_id;
    await trackProductClickWithAardvarkService(adId, getNativeAdClickOrigin(trackingData.eventTarget));
  };

  const trackProductSnippetBrandClicked = async (trackingData: {
    brandId: number;
    position: number;
    page: number;
    brandTags: string[];
    isSponsoredTile: boolean;
    product: Product | (ProductHit & { categoryName?: string }) | (Product & { isChecked: boolean });
  }) => {
    const payload = {
      component: trackingData.isSponsoredTile ? 'native_ads' : catalogTrackingData.component.value,
      brandId: trackingData.brandId,
      position: trackingData.position,
      page: trackingData.page,
      brandTags: trackingData.brandTags,
      id_section: catalogTrackingData.trackingSectionId.value,
      productId: trackingData.product.id,
    };
    await trackBrandClickedInProductTileSegment(payload);
  };

  const trackQuickAddToCartClicked = async (trackingData: {
    page: number;
    productId: number;
    position: number;
    sectionId: string;
    isSponsoredTile: boolean;
    component: string;
  }) => {
    const payload = {
      component: trackingData.component ?? (trackingData.isSponsoredTile ? 'native_ads' : catalogTrackingData.component.value),
      action: 'quick_add_to_cart_clicked',
      itemType: 'product',
      id_section: catalogTrackingData.trackingSectionId.value,
      page_number: trackingData.page,
      productId: trackingData.productId,
      position: trackingData.position,
    };
    await Analytics.track(new UserClick(payload));
  };

  const trackQuickAddToCartProductAdded = async (trackingData: {
    productProperty: ProductProperty;
    brandProperty: BrandProperty;
    page: number;
    quantity: number;
  }) => {
    const payload: [ProductProperty, BrandProperty, number, number, string] = [
      trackingData.productProperty,
      trackingData.brandProperty,
      trackingData.page,
      trackingData.quantity,
      catalogTrackingData.trackingSectionId.value,
    ];
    await Analytics.track(new ProductQuickAddToCartEvent(...payload));
  };

  const trackRelatedCollectionClicked = async (trackingData: { collectionId: string; itemType: string }) => {
    TrackingService.trackRelatedCollectionClicked(
      catalogTrackingData.trackingSectionId.value,
      trackingData.itemType,
      trackingData.collectionId
    );
  };

  return {
    filtersTracker,
    trackSectionCreated,
    trackBrandSuggestionClicked,
    trackTileProductClicked,
    trackTileBrandClicked,
    trackTileSponsoredProductClicked,
    trackProductSnippetBrandClicked,
    trackQuickAddToCartClicked,
    trackQuickAddToCartProductAdded,
    trackRelatedCollectionClicked,
  };
};
