import { computed, reactive, ref, Ref, watch } from 'vue';
import { Location, ParentItem, Item, isCountry } from '@bc/discovery/domain/catalog';

const createItem = (location: Location, selected = false): Item =>
  reactive({
    id: location.id,
    label: location.name,
    selected,
    count: location.count,
  });

export const useCountryItems = (
  locations: Ref<Location[]>,
  locationFacetCounts: Ref<Record<string, number>>,
  selectedLocations: Ref<Location[]>
) => {
  const countries = ref<ParentItem[]>();

  watch(
    [locations, selectedLocations],
    ([newLocations, newSelectedLocations]) => {
      const locationMap = new Map(newLocations.map((location) => [location.id, location]));

      const isSelected = (id: string) => Boolean(newSelectedLocations?.find((location) => id === location.id));

      countries.value = newLocations
        .filter((location) => isCountry(location))
        .map((location) => ({
          ...createItem(location, isSelected(location.id)),
          children: location.children.map((childId) => createItem(locationMap.get(childId), isSelected(childId))),
        }));
    },
    { immediate: true }
  );

  const allLocationIdsFromFacetCounts = reactive(new Set());

  watch(
    locationFacetCounts,
    (newLocationFacetCounts) => Object.keys(newLocationFacetCounts).forEach((key) => allLocationIdsFromFacetCounts.add(key)),
    { immediate: true }
  );

  const countryItems = computed<ParentItem[]>(() => {
    return countries.value
      .filter((country) => allLocationIdsFromFacetCounts.has(country.id))
      .map((country) => ({
        ...country,
        children: country.children.filter((child) => allLocationIdsFromFacetCounts.has(child.id)),
      }));
  });

  return {
    countryItems,
  };
};
