<template>
  <div class="ds-overflow-hidden">
    <AkIcon
      :class="{
        'big-icon': true,
        'ds-text-success': !hasError,
        'ds-text-error-700': hasError,
      }"
      :symbol="iconSymbol"
    />
    <AkHeading3>{{ resultHeading }}</AkHeading3>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AkIcon, AkHeading3 } from '@ankorstore/design-system';

export default defineComponent({
  name: 'ReportSubmitResultStep',
  components: { AkIcon, AkHeading3 },
  props: {
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    resultHeading(): string {
      return this.hasError ? this.$t('@@ret_core_discovery.defaultError') : this.$t('@@ret_core_discovery.resultHeading.success');
    },
    iconSymbol(): string {
      return this.hasError ? 'exclamation-triangle' : 'check-circle';
    },
  },
});
</script>

<style scoped lang="scss">
.big-icon {
  font-size: 40px !important;
  line-height: 1 !important;
}
</style>
