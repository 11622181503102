import { ProductHit, Option, Category, Variant, EditedProductImage } from '@/types/product';
import { getHighestDiscount as getHighestDiscountFromBrand } from './brand';
import { ESBrandHit as BrandHit } from '@bc/discovery';
import { Amount } from '@/types/amount';
import { sanitiseShapeProperties } from '@/utilities/product/shape-properties';
import http from '@/services/api/http';
import { formatISO } from 'date-fns';

export const createProductRequestImages = (images: Array<EditedProductImage | string>) => {
  if (!images) {
    return null;
  }

  return images.map((image) => {
    if (typeof image === 'object' && typeof image.filename === 'object') {
      return {
        ...image,
        filename: image.filename.key,
      };
    }
    return image;
  });
};

const sanitiseCategories = (categories: Category[]): Category['id'][] =>
  categories.filter((categorie) => categorie.id !== undefined).map((categorie) => categorie.id);

const toProductApiPayload = (brandId: number, product: ProductDto) => {
  const {
    name,
    hs_code,
    description,
    categories,
    retail_price,
    original_wholesale_price,
    unit_multiplier,
    made_in,
    vat_rate,
    discount_rate,
    options,
    variants,
    tags,
    properties,
    images,
    product_type_id,
    attributes,
  } = product;

  const productApiPayload = {
    name,
    hs_code,
    original_description: description,
    brand_id: brandId,
    unit_multiplier,
    vat_rate: Number(vat_rate) ? Number(vat_rate) : 0,
    discount_rate: Number(discount_rate) ? Number(discount_rate) : 0,
    options,
    variants: variants.map((variant) => {
      const images = createProductRequestImages(variant.images);
      const shape_properties = sanitiseShapeProperties(variant.shape_properties);
      return {
        ...variant,
        images,
        shape_properties,
        available_at: variant.available_at ? formatISO(new Date(variant.available_at), { representation: 'date' }) : null,
      };
    }),
    images: createProductRequestImages(images),
    categories: categories ? sanitiseCategories(categories) : null,
    tags: tags.filter(({ checked }) => checked).map(({ id }) => id),
    retail_price: retail_price.amount,
    original_wholesale_price: original_wholesale_price.amount,
    ...(made_in && { made_in_country_id: made_in }),
    ...Object.fromEntries(
      properties.filter((prop) => prop.original_content !== null).map((prop) => [prop.key, prop.original_content])
    ),
    product_type_id,
    attributes,
  };

  if (!product.is_discount_editable) {
    delete productApiPayload.discount_rate;

    productApiPayload.variants.forEach((variant) => {
      delete variant.price.discount_rate;
    });
  }

  return productApiPayload;
};

export type ProductDto = {
  name: string;
  hs_code: string;
  description: string;
  categories: Category[];
  retail_price: Amount;
  original_wholesale_price: Amount;
  wholesale_price: Amount;
  unit_multiplier: number;
  made_in: string;
  vat_rate: number;
  discount_rate: number;
  options: Option[];
  variants: Variant[];
  tags: Array<{ id: number; checked: boolean }>;
  properties: Array<{ key: string; original_content: string }>;
  images: EditedProductImage[];
  product_type_id: number;
  product_attributes: { [key: string]: string };
  is_discount_editable: boolean;
};

export const updateProduct = async (productId: number, brandId: number, product: ProductDto): Promise<unknown> => {
  const cleanedUpProduct = toProductApiPayload(brandId, product);

  const response = await http().put(`/api/me/brand/products/${productId}`, cleanedUpProduct);

  return response.data?.data;
};

export const createProduct = async (brandId: number, product: ProductDto): Promise<{ id: number }> => {
  const cleanedUpProduct = toProductApiPayload(brandId, product);

  const response = await http().post(`/api/me/brand/products`, cleanedUpProduct);

  return response.data?.data;
};

export const getHighestDiscount = (productHit: Pick<ProductHit, 'brand'>): number | undefined => {
  if (!productHit?.brand?.business_events) {
    return undefined;
  }

  const brandHit: Pick<BrandHit, 'business_events_eligible' | 'business_events'> = {
    business_events: productHit.brand.business_events,
    business_events_eligible: Object.keys(productHit.brand.business_events)
      .map((id) => parseInt(id, 10))
      .filter((id) => !isNaN(id)),
  };

  return getHighestDiscountFromBrand(brandHit);
};
