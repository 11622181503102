<template>
  <li v-if="!item.fakeItemForFrontFeedback">
    <button
      v-if="!isCheckout"
      class="remove"
      data-testid="cartItemRemove"
      :disabled="item.id === null"
      @click="deleteItem()"
    >
      <AkIcon
        symbol="x"
        size="md"
      />
    </button>
    <div
      v-if="!item.active && cart.brand.isOpenedInUserCountry && !isOutOfStock"
      class="item__inactive-warning"
    >
      {{ $t('This item is not available because it has been deactivated by the brand.') }}
    </div>
    <div
      v-if="isOutOfStock"
      class="item__inactive-warning"
    >
      {{ $t('This item is out of stock') }}
    </div>
    <div
      class="item__container"
      :class="{ 'item__container--disabled': !item.active }"
    >
      <router-link
        v-if="useRouterLink"
        :to="item.product.link"
        @click.prevent="followProductLink"
      >
        <img
          class="image-product"
          :src="imgCdnUrlFill(item.product.images[0], expanded ? '100' : '56')"
        />
      </router-link>
      <a
        v-else
        :href="item.product.link"
        @click.prevent="followProductLink"
      >
        <img
          class="image-product"
          :src="imgCdnUrlFill(item.product.images[0], expanded ? '100' : '56')"
        />
      </a>
      <div
        class="item__body"
        data-testid="cartItemBody"
      >
        <div class="infos">
          <div class="infos__content">
            <a
              v-if="item.active && !useRouterLink"
              class="item-name"
              :href="item.product.link"
              @click.prevent="followProductLink"
            >
              {{ getItemName }}
            </a>
            <router-link
              v-else-if="item.active && useRouterLink"
              class="item-name"
              :to="item.product.link"
            >
              {{ getItemName }}
            </router-link>
            <span v-else>
              {{ getItemName }}
            </span>
            <span
              v-if="expanded && getOptionName"
              class="option-name"
              >{{ $t('Option: {option}', { option: getOptionName }) }}</span
            >
          </div>
        </div>
        <div class="price">
          <div
            class="quantity"
            data-testid="cartItemQuantity"
          >
            <label>{{ $t('Quantity:') }}&nbsp;</label>
            <SelectInput
              v-if="!isCheckout && item.id !== null"
              ref="select-quantity"
              v-model="quantity"
              class="small-select"
              :list="listValuesForSelect(quantity)"
              :disabled="!item.active"
            />
            <div
              v-else
              class="quantity_loading"
            >
              <span>{{ quantity * getItem?.unit_multiplier }} </span>
              <LoaderIcon
                v-if="!isCheckout"
                class="loading-icon"
                :small="true"
              />
            </div>
          </div>
          <div class="ds-flex ds-flex-col price-values">
            <AkParagraph
              v-if="item.amount.amount !== 0 && specialDiscount"
              size="sm"
              class="item-total-price--discounted ds-text-primary"
            >
              <strike>{{ $root.formatPrice(item.amount_before_discount) }}</strike>
            </AkParagraph>
            <AkParagraph
              v-if="item.amount.amount !== 0"
              size="sm"
              class="item-total-price ds-mb-0 ds-text-primary"
            >
              {{ $root.formatPrice(item.amount) }}
            </AkParagraph>
          </div>
        </div>
      </div>
    </div>
  </li>
  <li
    v-else
    class="item__body"
  >
    <LoaderIcon />
  </li>
</template>

<script lang="ts">
import { LinkRoutingMixin } from '@/mixins/link-routing';
import { imgCdnUrlFill } from '@bc/shared';
import SelectInput from '@/components/select-input.vue';
import LoaderIcon from '@/components/loader-icon.vue';
import { AkIcon, AkParagraph } from '@ankorstore/design-system';

import { defineComponent } from 'vue';
import { getListValuesForSelectInput, getMaxQuantity, isVariantOutOfStock } from '@/services/product-variants';

import { createNamespacedHelpers } from 'vuex-composition-helpers';

const { useActions: useCartActions } = createNamespacedHelpers('cart');

export default defineComponent({
  name: 'CartItem',
  components: { LoaderIcon, SelectInput, AkIcon, AkParagraph },
  mixins: [LinkRoutingMixin],
  props: {
    cart: {
      type: Object,
      required: true,
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    useRouterLink: {
      type: Boolean,
      default: false,
    },
    specialDiscount: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { deleteItem: deleteCartItem, updateItemQuantity: updateCartItemQuantity } = useCartActions([
      'deleteItem',
      'updateItemQuantity',
    ]);
    return { deleteCartItem, updateCartItemQuantity, imgCdnUrlFill };
  },
  computed: {
    getItem() {
      return this.item.variant ? this.item.variant : this.item.option;
    },
    getItemName() {
      return this.expanded ? this.item.product.name : this.getItem.name;
    },
    getOptionName() {
      return this.getItem.options?.flatMap((x) => x.value).join(', ') || this.getItem.variation?.name;
    },
    isOutOfStock() {
      return isVariantOutOfStock(this.getItem);
    },
    quantity: {
      get() {
        return this.item.quantity;
      },
      set(quantity) {
        if (quantity == 0) {
          return this.deleteItem();
        }

        this.updateCartItemQuantity({
          brand: this.cart.brand,
          item: this.item,
          quantity,
        });
      },
    },
  },
  methods: {
    deleteItem() {
      if (this.item.id === null) {
        return;
      }

      this.deleteCartItem({
        brand: this.cart.brand,
        item: this.item,
      });
    },
    listValuesForSelect(selected): number[] {
      const availableQuantity = this.getItem.stock?.available_quantity;

      return getListValuesForSelectInput(
        true,
        getMaxQuantity(availableQuantity, selected, this.item.option?.unit_multiplier),
        this.item.option?.unit_multiplier
      );
    },
  },
});
</script>
<style lang="scss" scoped>
.loading-icon {
  margin: 0 5px;
}
.quantity_loading {
  display: flex;
  & > span,
  & > .loading-icon {
    display: inline-block;
  }
}

.item-name {
  @apply ds-text-sm;
}
</style>
