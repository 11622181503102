<template>
  <div
    v-if="isVisible"
    class="badge-bpg"
  >
    <template v-if="withTooltip">
      <AkTooltipInformation
        trigger="hover"
        placement="bottom"
        type="success"
        auto-hide
        symbol="info-circle"
        :title="$t('Bpg.tooltip.title')"
        :close-button="false"
        :show="false"
      >
        <template #container>
          <i18n-t keypath="Bpg.tooltip.content">
            <span
              class="ak-link"
              @click="goToLink(getGlobalConfig().language_config.faq_best_price_guarantee)"
              >{{ $t('Bpg.tooltip.content.link') }}</span
            >
          </i18n-t>
        </template>
        <AkIcon
          class="badge-bpg__icon"
          symbol="patch-check-fill"
          :size="size"
        />
      </AkTooltipInformation>
    </template>
    <template v-else>
      <AkIcon
        class="badge-bpg__icon"
        symbol="patch-check-fill"
        :size="size"
      />
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { BPGContext } from '@/types/badges/bpg/bpg';
import { AkTooltipInformation, AkIcon } from '@ankorstore/design-system';
import UserClick from '@/services/analytics/events/user-click.event';
import Analytics from '@/services/analytics';
import { getGlobalConfig } from '@/services/global-config';

export default defineComponent({
  name: 'BadgeBPG',
  components: {
    AkTooltipInformation,
    AkIcon,
  },
  props: {
    withTooltip: {
      type: Boolean,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    context: {
      type: String as PropType<BPGContext>,
      required: true,
    },
    isPriceDiscounted: {
      type: Boolean,
    },
  },
  computed: {
    symbol(): string {
      switch (this.context) {
        case BPGContext.brandPage: {
          return 'patch-check-fill';
        }
        case BPGContext.productPage: {
          return 'patch-check-fill';
        }
        default: {
          return 'patch-check';
        }
      }
    },
    size(): 'sm' | 'md' | 'lg' {
      switch (this.context) {
        case BPGContext.brandPage: {
          return 'md';
        }
        case BPGContext.productPage: {
          return 'md';
        }
        default: {
          return 'sm';
        }
      }
    },
  },
  methods: {
    goToLink(url) {
      Analytics.track(
        new UserClick({
          component: 'bpg_icon',
          action: 'click_faq',
        })
      );
      window.open(url, '_blank');
    },
    getGlobalConfig,
  },
});
</script>
<style lang="scss" scoped>
.badge-bpg {
  width: fit-content;
  .trigger {
    @apply ds-flex;
  }
  &__icon {
    @apply ds-text-ratings;

    &--small {
      font-size: 0.938rem;
    }
  }
}

.ak-link {
  @apply ds-text-white;
}
.brand-content__name-container {
  .badge-bpg__icon {
    font-size: 15px;
  }
}
</style>
