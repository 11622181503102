import AnalyticsEvent from '@/services/analytics/events/analytics-event';
import { ReportsClickAction, ReportsTrackingProperty } from '@/services/analytics/properties/reports-property';

const REPORT_BUTTON = 'report_button';
const ACTION_NAME = 'User Click';

interface ReportsTrackingClickProperty extends ReportsTrackingProperty {
  component: string;
  action: ReportsClickAction;
}

export interface ReportPopinClickEventPayload {
  payload: ReportsTrackingProperty;
}

class ReviewClicksEvent extends AnalyticsEvent {
  public readonly name = ACTION_NAME;
  public properties: ReportsTrackingClickProperty;

  constructor(action: ReportsClickAction, { payload }: { payload: ReportsTrackingProperty }) {
    super();
    this.properties = {
      ...payload,
      action,
      component: REPORT_BUTTON,
    };
  }
}

export class ReportClickEvent extends ReviewClicksEvent {
  constructor(payload) {
    super(ReportsClickAction.CLICK_REPORT, payload);
  }
}

export class ValidateReportReasonsClickEvent extends ReviewClicksEvent {
  constructor(payload) {
    super(ReportsClickAction.CLICK_VALIDATE_REPORT_REASON, payload);
  }
}

export class ValidateReportAdditionalInfoClickEvent extends ReviewClicksEvent {
  constructor(payload) {
    super(ReportsClickAction.CLICK_VALIDATE_ADDITIONAL_INFO, payload);
  }
}
