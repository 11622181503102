export enum Comparator {
  EQ = 'EQ',
  NOT_EQ = 'NOT_EQ',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  SUPERIOR = 'SUPERIOR',
  INFERIOR = 'INFERIOR',
}

export enum LogicalOperator {
  AND = 'AND',
  OR = 'OR',
}
