<template>
  <div class="content-editor">
    <editor
      v-bind="$attrs"
      :id="identifier"
      licence-key="gpl"
      :init="getConfig"
      :initial-value="placeholder"
      :value="originalContent"
      :menubar="menubar"
      :model-value="content"
      :disabled="disabled"
      @update:model-value="update"
    />
  </div>
</template>

<script setup lang="ts">
import Editor from '@tinymce/tinymce-vue';
import {useTinymce} from "../../composables";
import {computed, onBeforeUnmount, onMounted, ref, watch} from "vue";

const props = defineProps({
  value: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    required: false,
    default: null,
  },
  originalContent: {
    type: String,
    required: false,
    default: null,
  },
  identifier: {
    type: String,
    required: true,
  },
  height: {
    type: String,
    required: true,
  },
  menubar: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['contentUpdated']);

const content = ref(props.originalContent ?? props.placeholder ?? '');

useTinymce();

const getConfig = computed(() => ({
  height: props.height,
  menubar: props.menubar,
  relative_urls: false,
  remove_script_host: false,
  plugins: [
    'preview', 'code', 'fullscreen', 'link', 'codesample', 'pagebreak', 'nonbreaking', 'insertdatetime', 'advlist', 'lists', 'charmap', 'emoticons',
  ],
  toolbar: [
    'undo redo | bold italic underline | alignleft aligncenter alignright | forecolor',
  ],
  content_css: false,
  skin: false,
  content_style:
    '@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap) body { font-family: Poppins; }',
  font_formats:
    'Arial=arial,helvetica,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica;  Poppins=poppins; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;',
}));

watch(
  () => props.originalContent,
  (val) => {
    content.value = val ?? props.placeholder;
  }
);

const update = function (value: string) {
  content.value = value;
  emit('contentUpdated', value);
};

const focusinTox = function (event) {
  //based on tiny.cloud/docs/integrations/bootstrap/
  if (
    window.jQuery(event.target).closest('.tox-tinymce, .tox-dialog, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root')
      .length
  ) {
    event.stopImmediatePropagation();
  }
};

const setContent = (value: string) => {
  content.value = value ?? props.placeholder;
};

onMounted(() => {
  document.addEventListener('focusin', focusinTox, true);
});

onBeforeUnmount(() => {
  document.removeEventListener('focusin', focusinTox, true);
});

defineExpose({
  setContent,
});
</script>
