export const keepParameters = (): void => {
  // Get parameters from current URL
  const params = new URLSearchParams(window.location.search);
  // Get links pointing to my.ankorstore.com
  const links = document.querySelectorAll("[href*='my.ankorstore.com']") as NodeListOf<HTMLAnchorElement>;

  links.forEach((link: HTMLAnchorElement) => {
    if (params.has('p')) {
      params.delete('p'); // Remove "p" => pagination from parameters
    }

    link.href += params.toString() === '' ? '' : '?' + params.toString();
  });
};
