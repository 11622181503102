export enum MeasurementCategories {
  Surface = 'surface',
  Volume = 'volume',
  Weight = 'weight',
  Dimension = 'dimension',
}

export enum MeasurementRepresentationCategories {
  Capacity = 'capacity',
  Dimension = 'dimension',
  Weight = 'weight',
}

type MeasurementCode = string;

export enum MeasurementTypes {
  Imperial = 'imperial',
  Metric = 'metric',
}

export interface MeasurementUnit {
  category: MeasurementCategories;
  code: MeasurementCode;
  display_name: Record<string, string>;
  type: MeasurementTypes;
}

export interface ShapeProperties {
  capacity_unit: MeasurementCode;
  capacity: number;
  dimensions_unit: MeasurementCode;
  height: number;
  length: number;
  width: number;
  weight_unit: MeasurementCode;
  weight: number;
}
