<template>
  <div class="navbar navbar-expand-lg">
    <AkNavigation
      v-if="!isCheckoutPage"
      :items="items"
      :use-router-link="isRouterLink"
      @item-clicked="trackNavigation"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AkNavMenuData, AkNavigation, AkNavMenuExtended } from '@ankorstore/design-system';
import { prepareDataForAkNavBar } from '@bc/discovery/domain/navigation-menu';
import { TrackingService } from '@bc/discovery/domain/tracking';
import useIsCheckout from '@/composables/use-enclosed-checkout';

export default defineComponent({
  name: 'MainNav',
  components: {
    AkNavigation,
  },
  props: {
    navigationData: {
      type: Array as PropType<AkNavMenuData[]>,
      required: true,
    },
  },
  setup() {
    const { isCheckoutPage } = useIsCheckout();

    return {
      isCheckoutPage,
    };
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    isRouterLink() {
      if (!this.$router || !this.$route || !this.$route.path) {
        return false;
      }
      const currentPath = this.$route.path;
      const resolvedPath = this.$router?.resolve(currentPath);
      return resolvedPath?.matched?.length > 0 && currentPath !== '/login' && currentPath !== '/register';
    },
  },
  async mounted() {
    this.items = prepareDataForAkNavBar(this.navigationData);
  },
  methods: {
    trackNavigation(item: AkNavMenuExtended): void {
      TrackingService.trackNavigation(item);
    },
  },
});
</script>
