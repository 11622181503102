import { provide, inject, computed, Ref } from 'vue';
import { CatalogueType, TrackingService } from '@bc/discovery/domain/tracking';
import { Config } from '@bc/discovery/domain/catalog';
import { contextComponentMap, catalogTypeMap } from '../services/tracking';
import { getSectionId } from '@bc/shared';

export const ConfigKey = Symbol('config');
export const ComponentKey = Symbol('component');
export const TrackingSectionIdKey = Symbol('trackingSectionId');
export const ValuePropositionKey = Symbol('valueProposition');
export const CatalogTypeKey = Symbol('catalogType');
export const ValuePropositionIdKey = Symbol('valuePropositionId');
export const CategoryIdsKey = Symbol('category-ids');
export const SelectedCategoryNameKey = Symbol('selectedCategoryName');

type UseCatalogTrackingDataArgs = Partial<{
  config: Ref<Config>;
  valueProposition: Ref<string>;
  valuePropositionId: Ref<number>;
  selectedCategoryIds: Ref<number[]>;
  selectedCategoryName: Ref<string>;
  sectionType: Ref<string>;
}>;
type UseCatalogTrackingDataReturn = {
  config: Ref<Config>;
  component: Ref<string>;
  trackingSectionId: Ref<string>;
  valueProposition: Ref<string>;
  catalogType: Ref<CatalogueType>;
  valuePropositionId?: Ref<number>;
  selectedCategoryIds?: Ref<number[]>;
  selectedCategoryName?: Ref<string>;
};

const useCatalogTrackingData = (args: UseCatalogTrackingDataArgs = {}): UseCatalogTrackingDataReturn => {
  const { config, valueProposition, valuePropositionId, selectedCategoryIds, selectedCategoryName } = args;
  const component = computed(() => contextComponentMap[config.value.context]);

  const catalogType = computed(() => catalogTypeMap[config.value.context]);
  const sectionType = computed(() => args.sectionType?.value ?? TrackingService.evalSectionType(catalogType.value));
  const valuePropositionIdWrapper = valuePropositionId ? computed(() => valuePropositionId.value) : undefined;
  const trackingSectionId = computed(() =>
    getSectionId({
      valueProposition: valueProposition.value,
      sectionType: sectionType.value,
    })
  );

  const providedValues = new Map<symbol, Ref>([
    [ConfigKey, inject(ConfigKey, config)],
    [ComponentKey, inject(ComponentKey, component)],
    [TrackingSectionIdKey, inject(TrackingSectionIdKey, trackingSectionId)],
    [ValuePropositionKey, inject(ValuePropositionKey, valueProposition)],
    [CatalogTypeKey, inject(CatalogTypeKey, catalogType)],
    [ValuePropositionIdKey, inject(ValuePropositionIdKey, valuePropositionIdWrapper)],
    [CategoryIdsKey, inject(CategoryIdsKey, selectedCategoryIds)],
    [SelectedCategoryNameKey, inject(SelectedCategoryNameKey, selectedCategoryName)],
  ]);

  if (providedValues.get(ConfigKey) === undefined) {
    throw new Error('Config is required');
  }

  providedValues.forEach((value, key) => provide(key, value));

  return {
    config: providedValues.get(ConfigKey) as Ref<Config>,
    component: providedValues.get(ComponentKey) as Ref<string>,
    trackingSectionId: providedValues.get(TrackingSectionIdKey) as Ref<string>,
    valueProposition: providedValues.get(ValuePropositionKey) as Ref<string>,
    catalogType: providedValues.get(CatalogTypeKey) as Ref<CatalogueType>,
    valuePropositionId: providedValues.get(ValuePropositionIdKey) as Ref<number>,
    selectedCategoryIds: providedValues.get(CategoryIdsKey) as Ref<number[]>,
    selectedCategoryName: providedValues.get(SelectedCategoryNameKey) as Ref<string>,
  };
};

export type CatalogTrackingData = ReturnType<typeof useCatalogTrackingData>;
export default useCatalogTrackingData;
