import type { NormalTileType, Tile } from '@bc/discovery/';
import { ProductHit } from '@/types/product';

export interface SponsoredProductTile extends Tile {
  type: NormalTileType.SponsoredProduct;
  product?: ProductHit;
}

export interface NativeAdsServeDeserializedResponse {
  data: NativeAdsServeData[];
  meta: unknown;
}

export interface NativeAdsServeData {
  type: string;
  id: string;
  request: Request;
  result: Result;
  subType: string;
}

interface Request {
  data: Data;
}

interface Data {
  type: string;
  id: string;
  filter: string;
  lang: string;
  limit: number;
  multipleOf: number;
  searchTerm: string;
  subType: string;
}

interface Result {
  status: number;
  took: number;
  timedOut: boolean;
  items?: ProductNativeAdEntity[] | null;
}

export interface ProductNativeAdEntity {
  serveId: string;
  doc: ProductHit;
}

interface AttributesPayload {
  attributes: {
    filter?: string;
    limit: number;
    search_term?: string;
    subType: string;
    type: string;
    lang: string;
    multipleOf?: number;
  };

  id: string;
  type: string;
}

export interface NativeAdsServePayload {
  data: AttributesPayload[];
}

export enum NativeAdClickOrigin {
  Product = 'product',
  BrandName = 'brand_name',
  QuickAddToCart = 'quick_add_to_cart',
  AddToFavourites = 'add_to_favourites',
  WholesalePrice = 'wholesale_price',
}

export interface TracClickPayload {
  data: {
    relationships: { item: { data: { id: string; type: string } } };
    attributes: { type: string; origin: NativeAdClickOrigin };
    type: string;
  };
}
