import http from '@/services/api/http';

export const searchForCompany = async (vatNumber: string) => {
  const result = await http().post('/api/me/retail/hokodo_company/search', {
    tax_number: vatNumber,
  });

  return result?.data?.data;
};

export const sendCompanyId = async (companyId: string) => {
  const result = await http().patch('/api/me/retail/hokodo_company', {
    hokodo_company_id: companyId,
  });

  return result?.data?.data;
};
