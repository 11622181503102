<template>
  <div class="account-logo">
    <img
      v-if="logoUrl.length"
      :class="['account-logo__image', `account-logo__${size}`]"
      :src="`https://${$cdn}${logoUrl}?h=${size}&dpr=2&auto=format,compress`"
      :alt="brand.name"
    />

    <div
      v-else
      :class="['account-logo__placeholder', `account-logo__${size}`]"
    >
      {{ brandInitials }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapGetters } from 'vuex';

export type AccountLogoSize = '40' | '60';

export default defineComponent({
  name: 'AccountLogo',
  props: {
    size: {
      type: String as PropType<AccountLogoSize>,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['brand']),
    brandInitials(): string {
      return this.brand.name
        .split(' ')
        .map((word: string) => this.getFirstValidCharacter(word))
        .filter((char: string) => char.length)
        .slice(0, 2)
        .join('');
    },
    logoUrl(): string {
      /**
       * Loose equality is preferred here to also check against undefined
       */
      if (this.brand?.images?.rounded == null) {
        return '';
      }

      return this.brand.images.rounded;
    },
  },
  methods: {
    /**
     * Gets first valid character of a string. Valid character is defined
     * by a regex which matches all alpha numeric characters with a wide enough
     * range of unicode to also include accented characters.
     *
     * @param str - string to be checked
     * @returns character if found or empty string
     * @internal
     */
    getFirstValidCharacter(str: string): string {
      for (const c of str) {
        if (/^[a-z0-9\u00C0-\u024F\u1E00-\u1EFF]/i.test(c)) {
          return c;
        }
      }
      return '';
    },
  },
});
</script>

<style lang="scss" scoped>
.account-logo {
  &__image {
    @apply ds-rounded-full;
  }

  &__placeholder {
    @apply ds-capitalize ds-text-success ds-font-semibold ds-text-base ds-rounded-full ds-flex ds-items-center ds-justify-center  ds-bg-warm-white;
  }

  &__40 {
    width: 40px;
    height: 40px;
  }

  &__60 {
    width: 60px;
    height: 60px;
  }
}
</style>
