export interface ReportReason {
  id: number;
  title: string;
}

export interface ReportReasonResponse {
  data: ReportReason[];
}

export interface ReportPayload {
  reasons: number[];
  description: string;
  reportable_type: string;
  reportable_id: number;
}

export interface SendReportResponse {
  message?: string;
  errors?: { [reason: string]: string };
  success?: boolean;
}

export interface ReportFormData {
  reasons: ReportReason[];
  description: string;
}

export enum ReportPopinType {
  BRAND = 'brand',
  PRODUCT = 'product',
}

export enum ReportPopinStep {
  ISSUE_SELECTION = 0,
  ISSUE_MESSAGE = 1,
  ISSUE_SUBMIT_RESULT = 2,
}
