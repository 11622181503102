<template>
  <div class="brand-key-resume">
    <div class="brand-key-resume__header">
      <AkHeading4 class="list__title">
        {{ $t('brand.properties.list.title', { name: brand.name }) }}
      </AkHeading4>
      <ReportPopin
        v-if="userIsRetailer"
        :brand-id="brand.id"
      />
    </div>
    <AkParagraph
      v-if="showMinimumOrder"
      size="sm"
      class="list__paragraph"
      data-testid="minimumOrder"
    >
      <span>{{ $t('Minimum') }}&nbsp;</span>
      <span>{{ formatPrice(brand.minimum, '$0') }}</span>
    </AkParagraph>

    <div
      v-if="brand.retailer_will_require_eori"
      class="properties__tooltip-warning"
      data-testid="retailerRequiresEori"
    >
      <AkParagraph>
        {{ $t('EORI number required') }}
      </AkParagraph>
      <IconTooltipEori />
    </div>

    <ul class="point-list">
      <li
        v-if="brand.display_address"
        class="point-list__item"
      >
        <AkParagraph size="sm">
          {{ $t('Brand location') }} {{ brand.display_address.city }}, {{ brand.display_address.region }},
          {{ brand.display_address.country }}
        </AkParagraph>
      </li>
      <li
        v-for="(item, key, index) in brand.properties"
        :key="'property-' + key"
        :data-testid="'property-' + index"
        class="point-list__item"
      >
        <AkParagraph size="sm">
          {{ key }} {{ item }}
        </AkParagraph>
      </li>
    </ul>

    <div
      v-if="brand.instagram_username"
      data-testid="instagram"
      class="instagram"
    >
      <a
        :href="'https://www.instagram.com/' + brand.instagram_username + '/'"
        target="_blank"
      >
        <AkParagraph size="sm">{{ $t('Instagram') }}</AkParagraph>
        <div class="instagram__link">
          <AkIcon
            symbol="instagram"
            size="md"
          />
          <AkParagraph size="sm">
            <template v-if="brand.instagram_followers">
              {{
                $t('{count} followers', {
                  count: brand.instagram_followers,
                })
              }}
            </template>
            <template v-else>
              {{ brand.instagram_username }}
            </template>
          </AkParagraph>
        </div>
      </a>
    </div>

    <div class="badges">
      <template v-if="brand.tags && brand.tags.length">
        <AkBadge
          v-for="(tag, key) in brand.tags"
          :key="'tag-' + key"
          class="badge--tags"
          color="grey-light"
          size="xs"
          :content="tag"
        />
      </template>
      <template v-if="brand.made_in && brand.made_in.length">
        <AkBadge
          v-for="(country, key) in brand.made_in"
          :key="'made_in' + key"
          class="badge--made-in"
          color="grey-light"
          size="xs"
          :content="$t('Made in {country}', { country })"
        />
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';

import { AkBadge, AkParagraph } from '@ankorstore/design-system';
import IconTooltipEori from '@/components/icon-tooltips/icon-tooltip-eori/icon-tooltip-eori.vue';
import ReportPopin from '@/components/brand/report-popin/report-popin.vue';

import usePrice from '@/composables/use-price';
import { useStore } from '@/composables/use-store';
import { FeatureFlag, isEnabled } from '@/services/features';

export default defineComponent({
  name: 'BrandKeyResume',
  components: {
    AkBadge,
    AkParagraph,
    IconTooltipEori,
    ReportPopin,
  },
  props: {
    brand: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { formatPrice } = usePrice();

    const userIsRetailer = computed(() => {
      return store.getters.userIsRetailer;
    });

    const showMinimumOrder = computed(() => {
      return props.brand.accepted_at && !isEnabled(FeatureFlag.EnableNewBusinessModel);
    });

    return {
      showMinimumOrder,
      userIsRetailer,
      formatPrice,
    };
  },
});
</script>
<style lang="scss" scoped>
.brand-key-resume {
  .badges {
    @apply ds-flex ds-gap-2 ds-flex-wrap;
  }
  &__header {
    @apply ds-hidden md:ds-flex ds-justify-between ds-items-center ds-gap-2;
  }
}
.point-list {
  @apply ds-pl-3 ds-mb-3;
  &__item {
    @apply ds-list-disc ds-mb-2;
  }
}

.list {
  &__title {
    @apply ds-mb-3 ds-hidden md:ds-block;
  }
  &__paragraph {
    @apply ds-mb-2;
  }
}

.instagram {
  @apply ds-mb-3;
  a {
    @apply ds-flex ds-gap-1 ds-items-center;
  }
  &__link {
    width: fit-content;
    @apply ds-flex ds-gap-1 ds-items-center;
    p {
      @apply ds-underline;
    }
  }
}
</style>
