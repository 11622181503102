<template>
  <div>
    <div class="product-type__info">
      <AkParagraph
        size="sm"
        class="product-type__info-title"
      >
        {{ $t('brand.account.products.form.productType.faq.info') }}
      </AkParagraph>
      <a
        class="product-type__info-link"
        :href="$t('brand.account.products.banner.link')"
        target="_blank"
      >{{ $t('brand.account.products.form.productType.faq.link') }}
        <img
          class="product-type__info-icon"
          src="/images/share.svg"
        />
      </a>
    </div>
    <AkInputDropdown
      :label="label"
      :errors="errors"
      :model-value="searchValue"
      :options="treeView ? [] : filteredOptions"
      icon-left="search"
      :placeholder="$t('brand.account.products.form.productType.placeholder')"
      :dropdown-max-height="`${popinHeight}px`"
      dropdown-option-class="ds-border-b ds-border-solid ds-border-neutral-300 ds-p-4"
      class="product-type"
      :class="{ 'product-type--unselected': !selectedValue }"
      :sticky-bottom-area="!treeView"
      @update:model-value="onInput"
      @select-option="onSelectOption"
      @escape="onEscape"
    >
      <template #optionItem="{ option }">
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="product-type__name"
          :class="{ 'product-type__name--matched': searchSubstr(option.name, searchValue) }"
          v-html="getHighlightedHtml(option.name)"
        ></div>
        <div class="ds-text-sm ds-text-neutral-700 ds-text-left">
          {{ option.context }}
        </div>
      </template>
      <template #bottomArea>
        <ProductTypeTreeSelect
          v-if="treeView"
          @change="selectOptionById($event)"
        />
        <div
          v-else
          class="ds-p-4 ds-bg-neutral-300 ds-font-bold ds-flex ds-items-center"
        >
          <AkIcon
            symbol="info"
            size="sm"
          /><div class="ds-pl-2">
            <i18n-t
              v-if="searchValue"
              keypath="brand.productManagement.productType.noResultsHint"
              tag="span"
            >
              <span
                class="ds-underline ds-cursor-pointer"
                @click.stop="treeView = true"
              >{{ $t('brand.productManagement.productType.switchToTree') }}</span>
            </i18n-t>
            <i18n-t
              v-else
              keypath="brand.productManagement.productType.emptySearchInputHint"
              tag="span"
            >
              <span
                class="ds-underline ds-cursor-pointer"
                @click.stop="treeView = true"
              >{{ $t('brand.productManagement.productType.switchToTree') }}</span>
            </i18n-t>
          </div>
        </div>
      </template>
    </AkInputDropdown>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { AkInputDropdown } from '@ankorstore/design-system';
import { highlightTextByQuery } from '@/utilities/string';
import { debounce } from 'lodash-es';
import { ProductTypeSuggestionAttributes, ProductTypeSuggestionItem } from '../../types/product-types';
import { getProductTypesList } from '../../services/api';
import { captureException } from '@/sentry';
import ProductTypeTreeSelect from './product-type-tree-select.vue';

interface ProductTypeOption extends ProductTypeSuggestionAttributes {
  id: number;
  highlighted?: boolean;
}

type ComponentDataType = {
  filteredOptions: ProductTypeOption[];
  productTypes: ProductTypeSuggestionItem[];
  selectedValue: ProductTypeOption;
  searchValue: string;
  treeView: boolean;
};

const SEARCH_DEBOUNCE_TIME = 300;
const TREE_POPIN_HEIGHT = 900;
const SEARCH_POPIN_HEIGHT = 360;

export default defineComponent({
  name: 'ProductType',
  components: {
    AkInputDropdown,
    ProductTypeTreeSelect,
  },
  props: {
    modelValue: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  data(): ComponentDataType {
    return {
      filteredOptions: [],
      productTypes: [],
      selectedValue: null,
      searchValue: '',
      treeView: false,
    };
  },
  computed: {
    options(): ProductTypeOption[] {
      return this.productTypes.map((item) => ({
        id: item.id,
        name: item.attributes.name,
        context: item.attributes.context,
        searchString: item.attributes.searchString,
      }));
    },
    popinHeight(): number {
      return this.treeView ? TREE_POPIN_HEIGHT : SEARCH_POPIN_HEIGHT;
    },
  },
  watch: {
    modelValue(newValue) {
      if (this.options?.length && newValue) {
        this.selectOptionById(newValue);
      }
      if (!newValue) {
        this.selectOption(null);
      }
    },
    selectedValue(newValue) {
      this.$emit('update:modelValue', newValue?.id || null);
    },
  },
  async created() {
    try {
      this.productTypes = await getProductTypesList();
      this.selectOptionById(this.modelValue);
    } catch (e) {
      captureException(e);
    }
  },
  methods: {
    selectOptionById(id: number): void {
      const selectedOption = this.options.find((item) => item.id === id);
      this.searchValue = selectedOption?.name || '';
      selectedOption ? this.selectOption(selectedOption) : this.selectOption(null);
    },
    getHighlightedHtml(str: string): string {
      return highlightTextByQuery({
        content: str,
        query: this.searchValue,
      });
    },
    onInput(value: string): void {
      this.treeView = false;
      this.selectOption(null);
      this.searchValue = value;
      this.debouncedSearch();
    },
    debouncedSearch: debounce(function (this: Vue) {
      this.filteredOptions = this.search(this.searchValue);
    }, SEARCH_DEBOUNCE_TIME),
    searchSubstr(source: string, search: string): boolean {
      if (!source || !search) {
        return false;
      }
      //searching for substring at the beginning of the words in a sentence
      const regex = new RegExp('(^|\\s)' + search, 'i');
      return regex.test(source);
    },
    search(query: string): ProductTypeOption[] {
      return query
        ? this.options
            .map((option) => {
              const isMatched = this.searchSubstr(option.searchString, query);
              if (!isMatched) {
                return null;
              }
              return isMatched && option;
            })
            .filter(Boolean)
        : [];
    },
    onSelectOption(option: ProductTypeOption): void {
      this.selectOption(option);
      this.searchValue = this.selectedValue?.name || '';
    },
    selectOption(option: ProductTypeOption): void {
      if (option) {
        this.selectedValue = { ...option };
        const options = this.filteredOptions?.length ? this.filteredOptions : this.search(option.name);
        this.filteredOptions = options.map((item) => ({
          ...item,
          highlighted: item.id === option.id,
        }));
      } else {
        this.selectedValue = null;
        this.filteredOptions = this.filteredOptions.map((item) => ({
          ...item,
          highlighted: false,
        }));
      }
    },
    onEscape(): void {
      this.searchValue = this.selectedValue?.name || '';
    },
  },
});
</script>
<style lang="scss" scoped>
.product-type__info {
  @apply ds-mb-3;
  &-title {
    @apply ds-mr-1 ds-inline;
  }
  &-link {
    @apply ds-underline ds-mr-1 ds-inline;
  }
  &-icon {
    @apply ds-inline;
  }
}

.product-type__name {
  @apply ds-mb-2 ds-text-neutral-900 ds-font-normal ds-text-left;
}

.product-type--unselected .product-type__name--matched {
  @apply ds-text-primary ds-font-bold;

  .highlightTextByQueryClass {
    @apply ds-text-neutral-900 ds-font-normal;
  }
}
</style>
