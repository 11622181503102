import { B2REligibility } from '@/services/b2r';
import http from '@/services/api/http';
import { captureLoyaltyException } from '@/sentry/helper';

export type SumUpEligibility = B2REligibility;

export const offerEligibility = async (offerId: string): Promise<SumUpEligibility> => {
  if (!offerId) {
    return {
      status: false,
    };
  }

  try {
    const result = await http().get(`/api/me/offers/${offerId}/eligibility`);

    if (result?.data?.data) {
      return {
        status: result.data.data.status,
        discount: result.data.data.discount,
        endDate: result.data.data.end_date ? new Date(result.data.data.end_date) : null,
      };
    }

    return {
      status: false,
    };
  } catch (e) {
    if (e?.response?.status !== 503) {
      captureLoyaltyException(e);
    }

    return {
      status: false,
    };
  }
};
