<template>
  <div>
    <QuestionPreferenceGroup
      v-for="category in categories"
      :key="category.id"
      :category="category"
      :question="question"
      :state="state"
      :model="model"
      @change="onCategoryUpdate"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Question } from '@/types/question';
import { State } from '@/types/state';
import { getProductCategories } from '@/services/product-category/product-category';
import QuestionPreferenceGroup from './question-preference-group.vue';
import { ProductCategory } from '@/types/product-category';
import { prismic } from '@/mixins/prismic';
import _ from 'lodash-es';

export default defineComponent({
  name: 'QuestionPreferences',
  components: { QuestionPreferenceGroup },
  mixins: [prismic],
  props: {
    question: {
      type: Object as () => Question,
      required: true,
    },
    state: {
      type: Object as () => State,
      required: true,
    },
    model: {
      type: [Object, Array],
      required: true,
    },
  },
  emits: ['change'],
  data() {
    return {
      categories: [] as ProductCategory[],
    };
  },
  async created() {
    this.categories = await this.getCategories();
  },
  methods: {
    onCategoryUpdate(alias, value) {
      this.$emit('change', alias, value);
    },
    async getCategories(): Promise<ProductCategory[]> {
      const categories = (await getProductCategories()).tree.children;
      //@ts-ignore
      const images = (await this.getSingle('homepage')).data.c_categories;
      return _.filter(_.merge(_.keyBy(categories, 'id'), _.keyBy(images, 'category_id')), 'category_id');
    },
  },
});
</script>
