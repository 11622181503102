import { trackProductRemovedFromWishlist } from './analytics';
import http from '@/services/api/http';

const initialState = {};

export default {
  namespaced: true,
  state: initialState,
  modules: {},
  actions: {
    async addLikedProductToDefaultWishlist({ dispatch, commit }, { products }) {
      commit('wishlist/LIKE_PRODUCT', null, { root: true });

      const {
        data: { data },
      } = await http().post(`/api/wishlists/default/products`, {
        products: products,
      });

      return dispatch('wishlist/refreshWishlist', data, { root: true });
    },
    async removeUnlikedProductFromWishlist({ dispatch, commit, rootGetters }, { productId }) {
      commit('wishlist/UNLIKE_PRODUCT', null, { root: true });

      const {
        data: { data },
      } = await http().delete(`/api/wishlists/all/products/${productId}`);

      const list = rootGetters['wishlist/getListWithProduct'](productId) ?? {
        id: null,
        name: null,
      };

      trackProductRemovedFromWishlist(list, [productId]);

      return dispatch('wishlist/refreshWishlist', data, { root: true });
    },
  },
  mutations: {},
  getters: {},
};
