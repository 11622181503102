import Cookies, { CookieAttributes } from 'js-cookie';

export default class Cookie {
  static get(cookieName: string) {
    return Cookies.get(cookieName);
  }

  static set(cookieName: string, value, options?: CookieAttributes) {
    if (typeof value === 'object' && value !== null) {
      value = JSON.stringify(value);
    }
    return Cookies.set(cookieName, value, options);
  }

  static delete(cookieName: string, options?: CookieAttributes) {
    return Cookies.remove(cookieName, options);
  }
}
