import Analytics from '@/services/analytics';
import {
  ApiHeroSection,
  ApiSection,
  CollectionGroup,
  CollectionGroupBannerLeftSection,
  CollectionGroupBannerTopSection,
  CollectionGroupBannerLeftCta,
} from '../types/api-response';
import { HeroSection } from '../types/hero-section';
import { H1Section } from '../types/h1-section';
import { H2Section } from '../types/h2-section';
import { Section } from '../types/section';
import * as api from './api';
import { Card } from '../types/card';
import { CollectionType } from '../types/collection';
import UserClick from '@/services/analytics/events/user-click.event';
import { ProductHit } from '@/types/product';
import type { BrandHit } from '@bc/discovery/domain/catalog';
import { CollectionSection } from '../types/collection-section';
import { SectionCreatedEvent } from '@/services/analytics/events/section_created.event';
import { RichTextItem } from '@/types/prismic';
import { convert } from 'url-slug';
import { NavSection } from '../types/nav-section';
import { H3Section } from '../types/h3-section';
import { memoize } from 'lodash-es';
import { SeasonalSection } from '../types/seasonal';
import { CollectionService } from '@bc/discovery/domain/collection';
import { SectionType } from '@bc/discovery/domain/tracking';
import { getSectionId } from '@bc/shared';

const createParagraph = (paragraph: string | RichTextItem): RichTextItem => {
  if (typeof paragraph === 'string') {
    return {
      type: 'paragraph',
      text: paragraph,
      spans: [],
    };
  }

  return paragraph;
};

const createSection1 = (section: CollectionGroupBannerLeftSection): H1Section => ({
  ...section,
  description: section.description?.map(createParagraph),
  cards: section.collections.map(CollectionService.createCard),
});

const createSection2 = (section: CollectionGroupBannerTopSection): H2Section => ({
  ...section,
  description: section.description?.map(createParagraph),
  cards: section.collections.map(CollectionService.createCard),
});

const createSection3 = (section: CollectionGroup): H3Section => ({
  ...section,
  cards: section.collections.map(CollectionService.createCard),
});

const createSectionSeasonal = (section: CollectionGroupBannerLeftCta): SeasonalSection => ({
  ...section,
  description: section.description?.map(createParagraph),
  collections: section.collections.map(CollectionService.createCard),
});

const createHeroSection = (section: ApiHeroSection): HeroSection => ({
  ...section,
  description: section.description?.map(createParagraph),
});

const createSection = (section: ApiSection): Section => {
  if (section.type === 'collection_group_banner_left') {
    return createSection1(section);
  }

  if (section.type === 'collection_group_banner_top') {
    return createSection2(section);
  }

  if (section.type === 'hero') {
    return createHeroSection(section);
  }

  if (section.type === 'collection_group') {
    return createSection3(section);
  }

  if (section.type === 'collection_group_banner_left_cta') {
    return createSectionSeasonal(section);
  }

  return section;
};

type NavigableSection = Exclude<ApiSection, CollectionSection>;
const isNavigableSection = (section: ApiSection): section is NavigableSection => section.type !== 'collection';

const createNavSection = (sections: ApiSection[]): NavSection => ({
  type: 'nav',
  items: sections
    .slice(1) // Skip the first section. It does not require an anchor
    .filter(isNavigableSection)
    .map((section) => {
      return {
        title: section.title,
        anchor: convert(section.title),
        get url() {
          return `#${this.anchor}`;
        },
      };
    })
    .filter(Boolean),
});

export const getBoutique = memoize(async (name: string) => {
  try {
    const boutique = await api.getBoutique(name);

    const sections = boutique.sections.map(createSection);

    // Insert nav section after the first section
    sections.splice(1, 0, createNavSection(boutique.sections));

    return {
      sections,
      sectionId: getSectionId({ valueProposition: name, sectionType: 'boutique' }),
    };
  } catch ({ response }) {
    throw new Error(response);
  }
});

export const trackBoutiqueSectionCreated = (sectionId: string, name: string) => {
  Analytics.track(
    new SectionCreatedEvent({
      id_section: sectionId,
      section_type: SectionType.Event,
      value_proposition: `${name}_boutique`.toLowerCase(),
      page_number: 1,
    })
  );
};

export const trackCollectionClicked = (sectionId: string, item: CollectionSection | Card) => {
  const name = 'uuid' in item ? item.uuid : item.collection.uuid;

  const itemTypes = {
    [CollectionType.Product]: 'product_collection',
    [CollectionType.Brand]: 'brand_collection',
  };

  let itemType;
  if ('collection' in item) {
    itemType = itemTypes[CollectionService.createCollectionType(item.collection)];
  } else if ('collectionType' in item) {
    itemType = itemTypes[item.collectionType];
  }

  Analytics.track(
    new UserClick({
      component: 'event_page',
      action: 'collection_clicked',
      itemType,
      id_section: sectionId,
      name,
    })
  );
};

export const trackProductTypeClicked = (sectionId: string, item: H2Section['productTypes'][number]) => {
  Analytics.track(
    new UserClick({
      component: 'event_page',
      action: 'product_type_clicked',
      itemType: 'product_type',
      id_section: sectionId,
      name: item.title,
    })
  );
};

export const trackCollectionProductClicked = (
  sectionId: string,
  section: CollectionSection,
  product: ProductHit,
  position: number
) => {
  Analytics.track(
    new UserClick({
      component: 'event_page',
      action: 'product_clicked',
      itemType: 'product',
      id_section: sectionId,
      productId: product.id,
      name: section.collection.name,
      position,
    })
  );
};

export const trackCollectionBrandClicked = (sectionId: string, section: CollectionSection, brand: BrandHit, position: number) => {
  Analytics.track(
    new UserClick({
      component: 'event_page',
      action: 'brand_clicked',
      itemType: 'brand',
      id_section: sectionId,
      brandId: brand.id,
      name: section.collection.name,
      position,
    })
  );
};
