import Product from '@/types/product';
import { getSelectedProducts } from '@/services/api/brand-me';
import { memoize } from 'lodash-es';
import { FulfillableItem, FulfillableItemWithProductData } from '@/types/fulfillment';
import { findProductVariantById } from '@/services/fulfillment/utilities';

export const getRelatedProductsInfo = async (productVariantUuids: string[], MAX_PRODUCT_PER_PAGE = 50): Promise<Product[]> => {
  if (productVariantUuids.length === 0) {
    return Promise.resolve([]);
  }

  let currentPage = 0;
  let ids = [];
  const productCalls = [];
  // We fetch the products page by page to not flood our server
  do {
    ids = productVariantUuids.slice(currentPage * MAX_PRODUCT_PER_PAGE, (currentPage + 1) * MAX_PRODUCT_PER_PAGE);

    if (ids.length === 0) {
      continue;
    }
    productCalls.push(getSelectedProducts([], ids));

    // Increase page number
    currentPage++;
  } while (ids.length === MAX_PRODUCT_PER_PAGE); // Continue to fetch until we get all the ids we need

  return (await Promise.all(productCalls)).flat();
};

/** Lightweight cache to prevent too many calls if the products IDs are the unchanged */
export const getRelatedProductsInfoMemoized = memoize(getRelatedProductsInfo, (ids) => JSON.stringify(ids.sort()));

export const matchFulfillmentItems = async (
  items: Array<FulfillableItem>,
  useCache = false
): Promise<FulfillableItemWithProductData[]> => {
  const productIds = items.map(({ productVariantId }) => productVariantId);
  const productInfos = await (useCache ? getRelatedProductsInfoMemoized : getRelatedProductsInfo)(productIds);

  // Using flatmap to remove unsuccessful match
  return items.flatMap((item) => {
    // We found a product that have the provided variant id
    const productSelected = productInfos.find(({ variants }) => findProductVariantById(variants, item.productVariantId));

    // No matching found
    if (!productSelected) {
      return [];
    }

    return {
      availableQuantity: item.availableQuantity,
      barcode: item.barcode,
      itemImg: productSelected.images[0],
      itemName: item.productName,
      sku: item.sku,
      unitsPerBatch: item.unitsPerBatch,
      fulfillmentItemId: item.id,
      expectedStockForDays: item.expectedStockForDays,
      status: item.status,
      expiryTracked: item.expiryTracked,
      stockStatus: item.stockStatus,
    };
  });
};
