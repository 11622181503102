type Value = string | number | boolean;
type Operator = ':' | '=' | '>' | '<' | '>=' | '<=' | '!=';

export class FilterBuilder {
  private filters: string[];
  private invalidValues: Value[] = [undefined, null, ''];

  constructor() {
    this.filters = [];
  }

  public addFilter(key: string, value: Value, operator: Operator = ':') {
    const filter = this.createFilter(key, value, operator);
    this.filters.push(filter);
    return this;
  }

  public build() {
    return this.filters.filter(Boolean).join(' AND ');
  }

  private createFilter(key: string, value: Value, operator: Operator) {
    if (this.invalidValues.includes(value)) {
      return '';
    }

    const formattedValue = typeof value === 'string' ? `"${value}"` : value;

    return `${key}${operator}${formattedValue}`;
  }
}
