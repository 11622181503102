<template>
  <AkBadge
    :content="$t('EORI Required')"
    symbol="exclamation-triangle"
    size="xs"
    color="red"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AkBadge } from '@ankorstore/design-system';

export default defineComponent({
  name: 'BadgeEoriRequired',
  components: {
    AkBadge,
  },
});
</script>
