import AnalyticsEvent from './analytics-event';
import { ProductProperty, createProductProperty } from '../properties/product-property';
import { BrandProperty, createBrandProperty } from '../properties/brand-property';
import { CartProperty, createCartProperty } from '../properties/cart-property';

export default class ProductQuantityInCartChangedEvent extends AnalyticsEvent {
  public readonly name = 'Product Quantity In Cart Changed';
  public properties: {
    product: ProductProperty;
    quantity: number;
    brand: BrandProperty;
    cart: CartProperty;
  };

  constructor(product: ProductProperty, quantity: number, brand: BrandProperty, cart: CartProperty) {
    super();
    this.properties = {
      product: createProductProperty(product),
      quantity: quantity,
      brand: createBrandProperty(brand),
      cart: createCartProperty(cart),
    };
  }
}
