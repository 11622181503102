import { fr, de, da, es, it, nl, sv, enGB } from 'date-fns/locale';
import { addBusinessDays, addDays, addMonths, addYears, format, parseISO } from 'date-fns';
import i18n from '@/services/i18n';

enum Language {
  FR = 'fr',
  DE = 'de',
  DA = 'da',
  ES = 'es',
  IT = 'it',
  NL = 'nl',
  EN = 'en',
  SV = 'sv',
}

const localesMap: Map<Language, { locale: Locale; code: string; longYearSize: number; shortYearSize: number }> = new Map([
  [Language.FR, { locale: fr, code: 'fr-FR', longYearSize: 5, shortYearSize: 5 }],
  [Language.DE, { locale: de, code: 'de-DE', longYearSize: 5, shortYearSize: 5 }],
  [Language.DA, { locale: da, code: 'da', longYearSize: 5, shortYearSize: 5 }],
  [Language.ES, { locale: es, code: 'es', longYearSize: 8, shortYearSize: 5 }],
  [Language.IT, { locale: it, code: 'it', longYearSize: 5, shortYearSize: 5 }],
  [Language.NL, { locale: nl, code: 'nl', longYearSize: 5, shortYearSize: 5 }],
  [Language.EN, { locale: enGB, code: 'en-GB', longYearSize: 5, shortYearSize: 5 }],
  [Language.SV, { locale: sv, code: 'sv', longYearSize: 5, shortYearSize: 5 }],
]);

export enum TypeToAdd {
  Days = 'days',
  BusinessDays = 'businessDays',
  Months = 'months',
  Years = 'years',
}

export const formatDate = (date: string | Date, stringFormat: string, lang = i18n.global.locale): string => {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  const locale = localesMap.get(lang as Language)?.locale ?? enGB;
  return format(parsedDate, stringFormat, {
    locale,
  });
};

export const formatShortDate = (date: Date, monthLength: 'long' | 'short' = 'long', lang = i18n.global.locale): string => {
  const dateFormat = monthLength === 'long' ? 'PPP' : 'PP';
  const formattedDate = formatDate(date, dateFormat, lang);
  const locale = localesMap.get(lang as Language) ?? localesMap.get(Language.EN);
  const yearSize = monthLength === 'long' ? locale.longYearSize : locale.shortYearSize;

  return formattedDate.substring(0, formattedDate.length - yearSize);
};

/**
 *
 * @param date The date to convert
 * @param toAdd A format available in the list https://date-fns.org/v2.3.0/docs/format
 * @param typeToAdd
 * @returns A formated date
 */
export const addDate = (date: string | Date, toAdd: number, typeToAdd: TypeToAdd): Date => {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  switch (typeToAdd) {
    case TypeToAdd.Days:
      return addDays(parsedDate, toAdd);
    case TypeToAdd.Months:
      return addMonths(parsedDate, toAdd);
    case TypeToAdd.Years:
      return addYears(parsedDate, toAdd);
    case TypeToAdd.BusinessDays:
      return addBusinessDays(parsedDate, toAdd);
  }
};

/**
 * Get the list of months in the year with format MMMMM. Ex. "January", "February"
 * @param lang
 * @returns {string[]}
 */
export const getMonthList = (lang = i18n.global.locale): string[] => {
  const locale: Locale = localesMap.get(lang as Language)?.locale ?? enGB;
  const monthsArray = [];

  for (let i = 0; i < 12; i++) {
    monthsArray.push(format(new Date(2000, i, 1), 'MMMM', { locale }));
  }

  return monthsArray;
};
