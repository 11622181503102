<template>
  <div
    v-if="regions.length"
    class="home-bloc-c mb-4"
  >
    <div
      ref="wrapper"
      class="wrapper"
    >
      <AkHeading1 class="home-bloc-c-title">
        {{ content.title }}
      </AkHeading1>
      <div class="region-brands">
        <div
          v-for="region in regions.slice(0, 5)"
          :key="region.id"
          :class="{ 'ds-order-first': region.isUserCountry }"
          class="region-brands__snippet"
        >
          <a
            class="brand"
            :href="region.url"
            @click="trackLink(region.label)"
          >
            <figure>
              <div class="region-brands__mask">
                <img
                  :src="region.image"
                  :alt="region.label"
                  class="region-brands__image"
                />
              </div>

              <figcaption class="region-brands__caption">
                {{
                  region.isTitleOverride
                    ? region.label
                    : $t('home.regionbrands.description', {
                        label: region.label,
                      })
                }}
              </figcaption>
            </figure>
          </a>
        </div>
        <div
          v-if="content.other_country"
          class="region-brands__snippet"
        >
          <a
            class="brand"
            href="/shop/all#location=c157%2Cc22%2Cc215%2Cc15%2Cc61%2Cc178%2Cc75%2Cc86%2Cc177%2Cc107%2Cc129%2Cc35%2Cc70%2Cc123%2Cc182%2Cc203%2Cc101%2Cc202%2Cc59%2Cc56%2Cc138&view=1"
            @click="trackLink('All')"
          >
            <figure>
              <div class="region-brands__mask">
                <img
                  :src="content.other_country.url"
                  class="region-brands__image"
                />
              </div>
            </figure>

            <figcaption class="region-brands__caption">
              {{
                content.other_country_title_override
                  ? content.other_country_title_override
                  : $t('home.regionbrands.description', {
                      label: $t('home.regionbrands.all'),
                    })
              }}
            </figcaption>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { getLocations, URLManagerCategory, type Filter } from '@bc/discovery';
import Analytics from '@/services/analytics';
import RegionBrandsSection from '@/services/analytics/events/home/region-brands';
import { RegionCard } from '@/types/region-brand';
import { defineComponent } from 'vue';
import { RegionBrandContent } from '@/types/home/home';

export default defineComponent({
  name: 'RegionBrands',
  props: {
    content: {
      type: Object as () => RegionBrandContent,
      required: true,
    },
  },
  data() {
    return {
      locationsMap: new Map(),
    };
  },
  computed: {
    ...mapGetters(['userCountry']),
    regions(): RegionCard[] {
      const regions = [];
      this.content.country.forEach(({ h_title_override: title_override, h_iso_code: iso_code, h_image: image }) => {
        const location = this.locationsMap.get(iso_code);
        if (location) {
          regions.push({
            image: image.url,
            id: location.id,
            label: title_override ? title_override : location.name,
            isUserCountry: location.iso_code === this.userCountry,
            isTitleOverride: title_override ? true : false,
            url: this.getFormattedUrl({
              id: location.id,
              label: location.name,
            }),
          });
        }
      });
      return regions;
    },
  },
  async mounted() {
    const data = await getLocations();
    const locations = new Map();
    data.forEach((location) => {
      if (location.iso_code) {
        locations.set(location.iso_code, location);
      }
    });
    this.locationsMap = locations;
  },
  methods: {
    getFormattedUrl(props: Filter) {
      return props.id
        ? `/shop/new#${URLManagerCategory.createFilterParam(URLManagerCategory.URLParameterKey.Location, [props])}`
        : '';
    },
    async trackLink(label: string) {
      await Analytics.track(new RegionBrandsSection(label));
    },
  },
});
</script>
<style scoped lang="scss">
.home-bloc-c {
  @apply ds-mt-7;
}
.home-bloc-c-title {
  @apply ds-mb-6;
}
.region-brands {
  @apply ds-grid ds-grid-cols-2 sm:ds-grid-cols-3 lg:ds-grid-cols-6 ds-gap-5;
  &__image {
    @apply ds-transition-all ds-ease-in;
  }
  &__caption {
    @apply ds-font-bold ds-text-center ds-text-base ds-py-5 ds-px-4;
  }
  &__mask {
    @apply ds-overflow-hidden ds-rounded-t-md;
  }
}
.region-brands__snippet {
  @apply ds-rounded-md ds-bg-warm-white;
  &:hover {
    .region-brands__image {
      @apply ds-scale-110;
    }
  }
}
</style>
