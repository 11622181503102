import VScrollLock from 'v-scroll-lock';
import InstantSearch from 'vue-instantsearch/vue3/es';
import { ComponentLibrary } from '@ankorstore/design-system';
import PortalVue from 'portal-vue';
import { createPrismic } from '@prismicio/vue';
import VueTelInput from '@ankorstore/vue-tel-input';
import '@ankorstore/vue-tel-input/vue-tel-input.css';
import config from '@/config.json';
import popperjs from 'popper.js';

export default {
  install(app) {
    window.Popper = popperjs;

    app.use(VScrollLock);
    app.use(
      createPrismic({
        endpoint: config.prismicApiEndpoint,
      })
    );
    app.use(InstantSearch);
    app.use(ComponentLibrary);
    app.use(VueTelInput);
    // app.use(PortalVue);

    app.use(PortalVue, {
      portalName: false,
      portalTargetName: false,
    });
  },
};
