<template>
  <OrderSummaryBlock
    :title="title || $t('@@reorder.order_summary.fees_and_taxes.title')"
    @open="open"
  >
    <template #amount>
      {{ totalFeesAndTaxes }}
    </template>
    <template #content>
      <div
        v-if="ankorstorePlusOffer"
        class="ankorstore-plus-promotion"
        data-testid="ankorstorePlusPromotion"
      >
        <template v-if="ankorstorePlusMemberSeeBeneficial">
          <AnkorstorePlusBadge />
          <i18n-t
            keypath="cart.fees.ankorstorePlus.optin"
            tag="p"
          >
            <template #link>
              <router-link :to="{ name: 'ankorstore-plus' }">
                <template #default="{ href, navigate }">
                  <a
                    :href="href"
                    @click="
                      sendTracking('click_more_info_program');
                      navigate($event);
                    "
                  >
                    <AnkorstorePlusTitle is-link /></a>
                </template>
              </router-link>
            </template>
          </i18n-t>
        </template>
        <template v-if="ankorstorePlusEligibleSeeBeneficial">
          <AnkorstorePlusBadge />
          <i18n-t
            keypath="cart.fees.ankorstorePlus.optout"
            tag="p"
          >
            <template #link>
              <router-link :to="{ name: 'ankorstore-plus-signup' }">
                <template #default="{ href, navigate }">
                  <a
                    :href="href"
                    @click="
                      sendTracking('click_more_info_program');
                      navigate($event);
                    "
                  >
                    <AnkorstorePlusTitle is-link /></a>
                </template>
              </router-link>
            </template>
          </i18n-t>
        </template>
      </div>
      <template v-if="visibleBillingItems.includes('shipping')">
        <li
          v-for="item in cartShippingBillingItems"
          :key="item.id"
          data-testid="shippingSection"
          class="fees-line"
        >
          <BillingItemProgramTouchpoint
            :item-type="item.billing_item_type"
            :price="item.amount"
          >
            <div class="ds-w-full ds-flex ds-justify-between">
              <div class="shipping-fees">
                <span>{{ item.label }}</span>
                <AkTooltipInformation
                  v-if="item.hasShippingFeeTooltip"
                  data-testid="retailerShippingFeesTooltip"
                  class="retailer-shipping-fees-tooltip"
                  placement="right"
                  trigger="hover"
                  type="success"
                  symbol="telegram"
                  :content="$t('retailer.orderSummary.shippingfees.tooltip.content')"
                  :title="$t('retailer.orderSummary.shippingfees.tooltip.title')"
                >
                  <AkIcon
                    class="ds-text-neutral-900"
                    data-testid="retailerShippingFeesInfo"
                    symbol="info-circle"
                    size="md"
                  />
                </AkTooltipInformation>
              </div>

              <OrderSummaryBlockAmount>
                <span
                  v-if="shouldDisplayShippingFeesDiscount(item)"
                  :data-testid="`shippingFeesAmountBeforeDiscount-${item.billing_item_type}`"
                  class="ds-line-through ds-mr-2"
                >
                  {{ formatPrice(item.offer_details.amount_before_discount) }}
                </span>
                <span>+</span>
                <span :data-testid="`shippingFeeTotal-${item.billing_item_type}`">
                  {{ formatPrice(item.amount) }}
                </span>
              </OrderSummaryBlockAmount>
            </div>
          </BillingItemProgramTouchpoint>
        </li>
      </template>
      <li
        v-if="visibleBillingItems.includes('productsVat')"
        class="fees-line"
        data-testid="productsVatSection"
      >
        <div class="ds-w-full ds-flex ds-justify-between">
          <span class="ds-flex ds-items-center">{{ $t('@@reorder.order_summary.fees_and_taxes.vat_on_orders') }}</span>
          <OrderSummaryBlockAmount>
            <span>+</span>
            {{ formatPrice(productsVAT) }}
          </OrderSummaryBlockAmount>
        </div>
      </li>
      <li
        v-if="visibleBillingItems.includes('recargo') && formattedRecargoAmount"
        class="fees-line"
        data-testid="recargoSection"
      >
        <div class="ds-w-full ds-flex ds-justify-between">
          <span>{{ $t('@@reorder.order_summary.fees_and_taxes.recargo') }}</span>
          <OrderSummaryBlockAmount>
            <span>+</span>
            {{ formattedRecargoAmount }}
          </OrderSummaryBlockAmount>
        </div>
      </li>
      <li
        v-if="visibleBillingItems.includes('shippingVat')"
        class="fees-line"
        data-testid="shippingVatSection"
      >
        <div class="ds-w-full ds-flex ds-justify-between">
          <span>{{ $t('@@reorder.order_summary.fees_and_taxes.vat_on_shipping_fees') }}</span>

          <OrderSummaryBlockAmount>
            <span>+</span>
            {{ formatPrice(shippingFeesVAT) }}
          </OrderSummaryBlockAmount>
        </div>
      </li>
    </template>
  </OrderSummaryBlock>
</template>
<script lang="ts">
import { defineComponent, computed, toRef } from 'vue';
import { AkTooltipInformation, AkIcon } from '@ankorstore/design-system';
import OrderSummaryBlock from '@/modules/carts/order-summary/order-summary-block.vue';
import OrderSummaryBlockAmount from '@/modules/carts/order-summary/order-summary-block-amount.vue';
import { BillingItemType } from '@/types/billing-item';
import Analytics from '@/services/analytics';
import { ShowTaxDetailsClick } from '@/services/analytics/events/reorder/carts-page/show-tax-details-click.event';
import UserClick from '@/services/analytics/events/user-click.event';
import BillingItemProgramTouchpoint from '@/modules/promote-engage/touchpoints/billing-item-program-touchpoint.vue';
import AnkorstorePlusBadge from '@/modules/promote-engage/subscription-program/UI/ankorstore-plus-badge.vue';
import AnkorstorePlusTitle from '@/modules/promote-engage/subscription-program/UI/ankorstore-plus-title.vue';
import { OfferProgramEligibility } from '@/modules/promote-engage/services/offer';
import { Amount } from '@/types/amount';
import { capturePurchasingException } from '@/sentry/helper';
import { isEnabled, FeatureFlag } from '@/services/features';
import { useStore } from 'vuex';
import { formatPrice, sumPrices } from '@/utilities/price';
import { getCurrency } from '@/services/metas/currency';
import { isBillingItemShippingFee } from '@/utilities/shipping/shipping-fees';

export default defineComponent({
  name: 'FeesAndTaxes',
  components: {
    AkIcon,
    AkTooltipInformation,
    BillingItemProgramTouchpoint,
    OrderSummaryBlock,
    OrderSummaryBlockAmount,
    AnkorstorePlusBadge,
    AnkorstorePlusTitle,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    visibleBillingItems: {
      type: Array,
      default: () => ['shipping', 'productsVat', 'recargo', 'shippingVat'],
    },
    displaySelected: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const visibleBillingItems = toRef(props, 'visibleBillingItems');
    const store = useStore();
    const cartStore = computed(() => store.state.cart);
    const retailerStore = computed(() => store.state.retailer);
    const defaultAmount = { amount: 0, currency: getCurrency() };
    const shippingFeeRegularAmount = cartStore.value.checkout.regular_shipping_fees_amount;

    const createAmountFromBillingItems = (items, amountKey, currency) => {
      return {
        amount: items.reduce((total, item) => total + item[amountKey].amount, 0),
        currency,
      };
    };

    const ankorstorePlusOffer = computed((): OfferProgramEligibility | null => {
      return store.getters['offers/ankorstorePlus'];
    });

    const isFreeShipping = computed((): boolean => {
      return cartStore.value.checkout.free_shipping;
    });

    const ankorstorePlusMemberSeeBeneficial = computed((): boolean => {
      return ankorstorePlusOffer.value ? ankorstorePlusOffer.value.optIn && amountLeftForFreeShipping.value.amount > 0 : false;
    });

    const ankorstorePlusEligibleSeeBeneficial = computed((): boolean => {
      return ankorstorePlusOffer.value ? !ankorstorePlusOffer?.value?.optIn && !isFreeShipping.value : false;
    });

    const amountLeftForFreeShipping = computed((): Amount => {
      return store.getters['cart/getAmountLeftForFreeShipping'];
    });

    const shippingFeesAmount = computed(() => {
      return visibleBillingItems.value.includes('shipping') && shippingFeesBillingItem.value
        ? shippingFeesBillingItem.value.amount
        : defaultAmount;
    });

    const shippingFeesAmountBeforeDiscount = computed(() => {
      if (isFreeShipping.value) {
        return shippingFeeRegularAmount;
      }

      if (shippingFeesBillingItem.value?.offer_details?.amount_before_discount) {
        return shippingFeesBillingItem.value.offer_details.amount_before_discount;
      }

      return null;
    });

    const shippingFeesBillingItem = computed(() => {
      return cartStore.value.checkout.billing_items.find((billingItem) => {
        return billingItem.billing_item_type === BillingItemType.shippingFees;
      });
    });

    const cartShippingBillingItemsAmount = computed(() => {
      const cartShippingBillingItemsAmounts = cartShippingBillingItems.value.map((item) => item.amount);
      try {
        return visibleBillingItems.value?.includes('shipping')
          ? sumPrices(cartShippingBillingItemsAmounts, getCurrency())
          : defaultAmount;
      } catch (e) {
        capturePurchasingException(e);
        return defaultAmount;
      }
    });

    const cartBrands = computed(() => {
      //filter selected items for display of fees in checkout
      const selectedCarts = props.displaySelected
        ? cartStore.value.checkout.carts
        : cartStore.value.checkout.carts.filter((item) => item.selected);
      return selectedCarts.map((cart) => {
        return {
          ...cart,
          billing_items: cart.billing_items.map((item) => ({
            ...item,
            hasShippingFeeTooltip: shouldDisplayShippingFeesTooltip(cart),
          })),
        };
      });
    });

    const cartShippingBillingItems = computed(() => {
      return cartBrands.value.flatMap((cart) => cart.billing_items).filter((item) => isBillingItemShippingFee(item));
    });

    const billingItems = computed(() => {
      return cartBrands.value.flatMap((cart) => cart.billing_items).concat(cartStore.value.checkout.billing_items);
    });

    const shippingBillingItems = computed(() => {
      return billingItems.value.filter((item) => item.billing_item_type === BillingItemType.shippingFees);
    });

    const shippingFeesVAT = computed(() => {
      try {
        return visibleBillingItems.value?.includes('shippingVat')
          ? sumPrices(
              [
                createAmountFromBillingItems(shippingBillingItems.value, 'amount_vat', getCurrency()),
                createAmountFromBillingItems(cartShippingBillingItems.value, 'amount_vat', getCurrency()),
              ],
              getCurrency()
            )
          : defaultAmount;
      } catch (e) {
        capturePurchasingException(e);
        return defaultAmount;
      }
    });

    const productsVAT = computed(() => {
      return visibleBillingItems.value.includes('productsVat') ? cartStore.value.checkout.total_amount_vat : defaultAmount;
    });

    const hasVATAppliedOnProduct = computed(() => {
      return productsVAT.value.amount > 0;
    });

    const recargoAmounts = computed(() => {
      return visibleBillingItems.value.includes('recargo')
        ? cartBrands.value.map((c) => c?.recargo_de_equivalencia_amount ?? defaultAmount)
        : [defaultAmount];
    });

    const formattedRecargoAmount = computed(() => {
      if (!store.state.cart.checkout?.extra_taxes) {
        return false;
      }
      return cartStore.value.checkout?.extra_taxes?.recargo_de_equivalencia;
    });

    const totalFeesAndTaxes = computed(() => {
      try {
        return formatPrice(
          sumPrices(
            [
              productsVAT.value ?? defaultAmount,
              shippingFeesVAT.value ?? defaultAmount,
              ...recargoAmounts.value,
              shippingFeesAmount.value ?? defaultAmount,
              cartShippingBillingItemsAmount.value ?? defaultAmount,
            ],
            getCurrency()
          )
        );
      } catch (e) {
        capturePurchasingException(e);
        return defaultAmount;
      }
    });

    const shouldDisplayShippingFeesTooltip = (cart) => {
      return hasRetailerShippingFees(cart) && cart.brand?.is_not_eligible_for_ak_plus_free_shipping;
    };

    const shouldDisplayShippingFeesDiscount = (item) => {
      return item?.offer_details && item?.offer_details?.amount_before_discount?.amount !== item?.amount?.amount;
    };

    const hasRetailerShippingFees = (cart) => {
      return (
        cart.billing_items?.filter(
          (item) => item.billing_item_type === BillingItemType.retailerShippingFees && item.amount?.amount
        ).length > 0
      );
    };

    const open = () => {
      Analytics.track(
        new ShowTaxDetailsClick({
          id_checkout: cartStore.value.checkout.id,
          id_retailer: retailerStore.value.id,
        })
      );
    };

    const sendTracking = (action: string) => {
      Analytics.track(
        new UserClick({
          component: 'billing_item_program_touchpoint',
          action: action,
        })
      );
    };

    return {
      productsVAT,
      formattedRecargoAmount,
      cart: cartStore,
      shippingFeesAmount,
      shippingFeesAmountBeforeDiscount,
      isFreeShipping,
      ankorstorePlusOffer,
      ankorstorePlusMemberSeeBeneficial,
      ankorstorePlusEligibleSeeBeneficial,
      shippingFeesVAT,
      totalFeesAndTaxes,
      recargoAmounts,
      amountLeftForFreeShipping,
      cartShippingBillingItems,
      cartShippingBillingItemsAmount,
      open,
      sendTracking,
      hasVATAppliedOnProduct,
      BillingItemType,
      formatPrice,
      FeatureFlag,
      isEnabled,
      shouldDisplayShippingFeesDiscount,
      shouldDisplayShippingFeesTooltip,
    };
  },
});
</script>
<style lang="scss" scoped>
.ankorstore-plus-promotion {
  @apply ds-flex ds-gap-2  ds-mb-5 ds-text-sm;
}

.fees-line {
  @apply ds-flex ds-flex-col ds-mb-5 ds-w-full ds-text-neutral-900;
  &:last-of-type {
    @apply ds-mb-0;
  }
}
.shipping-fees {
  @apply ds-flex ds-items-center;
}

.retailer-shipping-fees-tooltip {
  @apply ds-text-sm;
  &__title {
    @apply ds-flex ds-flex-row ds-gap-2;
  }
}
</style>
