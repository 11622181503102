export enum CatalogueType {
  Category = 'category',
  Search = 'search',
  BrandSearch = 'brand',
  BusinessEvent = 'event',
  Collection = 'collection',
  New = 'new',
  Wholesale = 'wholesale',
}

export enum SectionType {
  Category = 'category',
  Search = 'search',
  Brand = 'brand',
  Event = 'event',
  ConnectedHp = 'connected_hp',
  ProductCollection = 'product_collection',
  Collection = 'collection',
  RecoCarousel = 'reco_carousel',
  Fulfillment = 'brand_fulfillment',
  New = 'new',
  Wholesale = 'wholesale',
}

export enum NavigationMethodType {
  Menu = 'navigation_menu',
  Filter = 'navigation_category_filter',
  Tile = 'navigation_category_tile',
}

export enum DiscoveryTrackingEvents {
  // catalog-tile
  TILE_PRODUCT_CLICKED = 'tile-product--clicked',
  TILE_BRAND_CLICKED = 'tile-brand--clicked',
  TILE_EVENT_CLICKED = 'tile-event--clicked',
  TILE_SPONSORED_PRODUCT_CLICKED = 'tile-sponsored-product--clicked',
  // product-snippet
  QUICK_ADD_TO_CART_CLICKED = 'quick-add-to-cart--clicked',
  QUICK_ADD_TO_CART_PRODUCT_ADDED = 'quick-add-to-cart--product-added',
  PRODUCT_SNIPPET_PRODUCT_CLICKED = 'product-snippet-product--clicked',
  PRODUCT_SNIPPET_BRAND_CLICKED = 'product-snippet--brand--clicked',
  // recommended-brand-banner
  RECOMMENDED_BRAND_BANNER_CREATED = 'recommended-brand-banner--created',
  RECOMMENDED_BRAND_BANNER_CLICKED = 'recommended-brand-banner--clicked',
  // discovery-catalog
  RELATED_COLLECTION_CLICKED = 'related-collection--clicked',
  // category-browser
  CATEGORY_FILTER_CLICKED = 'category-filter--clicked',
}
