export const configureGa = () => {
  window.ga =
    window.ga ||
    function (...args: unknown[]) {
      window.ga.q = window.ga.q || [];
      window.ga.q.push(args);
    };
  window.ga.l = +new Date();
  window.ga('create', 'UA-139272779-1', 'auto');
  window.ga('require', 'eventTracker');
  window.ga('require', 'outboundLinkTracker');
  window.ga('require', 'urlChangeTracker');
};
