import { Amount } from './amount';

// TODO: Remove duplicated property, check which one is safe to remove
export interface BillingItem {
  amount: Amount;
  amount_vat: Amount;
  amountVat?: Amount;
  vatLabel?: string;
  amount_with_vat: Amount;
  offer_details?: {
    amount_before_discount?: Amount;
    offer_name?: string;
    percentage?: number;
  };
  billing_item_type?: BillingItemType;
  display_vat: boolean;
  label: string;
  vat_rate: number;
  id?: number;
  type?: BillingItemType;
  vat_label?: string;
  vat_shifted?: boolean;
}

export enum BillingItemType {
  promocode = 'promocode',
  shippingFees = 'shipping_fees',
  legacyShippingFees = 'legacy_shipping_fees',
  ankorstoreFees = 'ankorstore_fees',
  brandShippingFeesRefund = 'brand_shipping_fees_refund',
  brandFlatShippingFees = 'brand_flat_shipping_fees',
  brandShippingFees = 'brand_shipping_fees',
  paymentMethodDiscount = 'payment_method_discount',
  legacyPaymentMethodDiscount = 'legacy_payment_method_discount',
  eventDiscount = 'event_discount',
  offerDiscount = 'offer_discount',
  brandFastPayment = 'brand_fast_payment',
  wallet = 'wallet',
  retailerShippingFees = 'retailer_shipping_fees',
  brandPaymentFees = 'brand_payment_fees',
  brandPlatformFees = 'brand_platform_fees',
  brandNewCustomerFees = 'brand_new_customer_fees',
}
