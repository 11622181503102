<template>
  <div
    v-if="brands.length"
    class="ds-mb-8 ds-mt-7"
  >
    <div
      ref="wrapper"
      class="wrapper"
    >
      <AkHeading3>
        {{ content.e_title }}
      </AkHeading3>
      <div class="featured-brands">
        <BrandSnippet
          v-for="brand in brands"
          :key="brand.id"
          :brand="brand"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { prismic } from '@/mixins/prismic';
import { getFeaturedBrands } from '@/services/api/featured-brands';
import BrandSnippet from '@/components/brand/brand-snippet.vue';
import { captureException } from '@/sentry';
import { defineComponent } from 'vue';
interface PrismicData {
  e_title: string;
}
export default defineComponent({
  name: 'FeaturedBrands',
  components: {
    BrandSnippet,
  },
  mixins: [prismic],
  data() {
    return {
      brands: [],
      content: {} as PrismicData,
    };
  },
  async mounted() {
    getFeaturedBrands().then((data) => (this.brands = data));
    this.getSingle('homepage')
      .then((document) => {
        this.content = document?.data as PrismicData;
      })
      .catch((error) => {
        captureException(error);
      });
  },
});
</script>
<style scoped lang="scss">
.featured-brands {
  @apply ds-w-full ds-grid ds-grid-cols-2 md:ds-grid-cols-4 lg:ds-grid-cols-5 ds-gap-x-5 ds-p-0 ds-mt-6;
  grid-template-rows: auto auto;
}
</style>
