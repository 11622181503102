<template>
  <div
    v-if="items.length"
    :class="{
      'search-autocomplete-section': true,
      'search-autocomplete-section--no-separator': $isEnabled('RET-1653') && type === 'history',
    }"
  >
    <div
      v-if="label"
      class="search-autocomplete-section__label"
    >
      {{ label }}
    </div>
    <ul class="search-autocomplete-section__items">
      <SearchAutocompleteSectionItem
        v-for="item in items"
        :key="`autocomplete-item-${item.id}`"
        data-testid="SearchBoxResultItem"
        :item="item"
        :query="query"
        :is-selected="item.id === currentSelectionId"
        :highlight="shouldHighlightResults"
      />
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import SearchAutocompleteSectionItem from './search-autocomplete-section-item.vue';
import { DEFAULT_ARROW_POSITION } from '../store';
import { DynamicResults } from '@bc/discovery/domain/search';

export default defineComponent({
  name: 'SearchAutocompleteSection',
  components: { SearchAutocompleteSectionItem },
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String as PropType<keyof DynamicResults>,
    },
    items: {
      type: Array as PropType<DynamicResults[]>,
      default: () => [],
    },
    query: { type: String, default: '' },
    shouldHighlightResults: { type: Boolean, default: true },
    currentSelectionId: {
      type: [Number, String],
      default: DEFAULT_ARROW_POSITION,
    },
  },
});
</script>

<style scoped lang="scss">
.search-autocomplete-section {
  &:not(&--no-separator):not(:last-child):after {
    @apply ds-bg-neutral-300 ds-mx-4 ds-absolute;
    content: '';
    height: 1px;
    left: 0;
    right: 0;
  }
  &__label {
    @apply ds-text-left ds-text-sm ds-mx-4 ds-my-2;
  }
  &__items {
    @apply ds-m-0 ds-p-0 ds-block;
  }
}
</style>
