<template>
  <div class="brand-properties-lines">
    <BrandHolidayBannerInner
      v-if="shouldShowHolidayModeBanner && hideDeliveryIfPreorder"
      class="container"
      data-testid="brandHolidayBanner"
      :brand-holiday-date-range="{start_date: props.brand.holiday_mode.start_date, end_date: props.brand.holiday_mode.end_date}"
    />
    <div
      v-if="shouldShowPromisedDeliveryDate && hideDeliveryIfPreorder"
      class="container"
      data-testid="promisedDeliveryDate"
    >
      <div class="container__icon">
        <AkIcon
          symbol="box-seam"
          size="md"
        />
      </div>
      <div class="container__main">
        <div class="title">
          <AkParagraph size="sm">
            <strong>{{ t('brand.properties.promisedDeliveryDate.orderToday') }}</strong>
            {{ t('brand.properties.promisedDeliveryDate.forDelivery') }}
            <strong>
              {{ t('brand.properties.promisedDeliveryDate.by') }} {{ formatDate(promisedDeliveryDate, 'd MMMM') }}
            </strong>
          </AkParagraph>
        </div>
        <div class="tag">
          <AkParagraph
            class="no-wrap"
            weight="bold"
          >
            {{ t('global.new') }}
          </AkParagraph>
        </div>
      </div>
    </div>
    <div
      v-if="shouldShowShippingLeadTimeDetails && hideDeliveryIfPreorder"
      class="container"
      data-testid="shippingLeadTimeDetails"
    >
      <div class="container__icon">
        <AkIcon
          symbol="truck"
          size="md"
        />
      </div>
      <div class="container__main">
        <div class="title">
          <i18n-t keypath="brand.properties.shippingLeadTimes.description">
            <template #delay>
              <strong>
                <i18n-t keypath="brand.properties.shippingLeadTimes.delay">
                  <template #minimum>
                    <strong>{{ brand.shipping_lead_time_details.minimum }}</strong>
                  </template>
                  <template #maximum>
                    <strong>{{ brand.shipping_lead_time_details.maximum }}</strong>
                  </template>
                </i18n-t>
              </strong>
            </template>
          </i18n-t>
        </div>
        <div
          v-if="longDistanceShippingFee !== null"
          data-testid="longDistanceShippingFee"
          class="description"
        >
          <AkParagraph size="sm">
            {{
              t('brand.properties.shippingFees.longFees', {
                amount: longDistanceShippingFee.amount,
                country: brand.addresses.shipping.country.name,
              })
            }}
          </AkParagraph>
        </div>
      </div>
    </div>
    <div
      v-if="isPreorder && (firstPreorderProductAvailableAt || hasPreorderProducts) && isEnabled('oxp-1484-r2')"
      class="container"
      data-testid="preorderOffer"
    >
      <div class="container__icon">
        <AkIcon
          symbol="calendar-date"
          size="md"
        />
      </div>
      <div
        v-if="userCurrency === 'EUR'"
        class="container__main"
      >
        <div
          v-if="availableAt !== null"
          class="brand-properties-lines__title"
        >
          <!-- Product page -->
          <template v-if="!isEnabled('oxp-1484-r3')">
            <i18n-t
              keypath="brand.properties.preorder.availability"
              tag="p"
            >
              <template #availableAt>
                <strong>{{ availableAt }}</strong>
              </template>
            </i18n-t>
            <i18n-t
              v-if="!newMovEnabled"
              keypath="brand.properties.preorder.minimumValue"
              tag="p"
            >
              <template #brandName>
                <strong>{{ brand.name }}</strong>
              </template>
              <template #minimumValue>
                <strong>{{ formatPrice(franco, '$0') }}</strong>
              </template>
            </i18n-t>
          </template>
          <template v-else>
            <i18n-t
              v-if="!newMovEnabled"
              keypath="brand.properties.preorder.minimumValue"
              tag="p"
            >
              <template #brandName>
                <strong>{{ brand.name }}</strong>
              </template>
              <template #minimumValue>
                <strong>{{ formatPrice(franco, '$0') }}</strong>
              </template>
            </i18n-t>
            <i18n-t
              keypath="cart.preorder.item.preorderDate"
              tag="p"
            >
              <template #preorderDate>
                <strong>{{ availableAt }}</strong>
              </template>
            </i18n-t>
          </template>
        </div>
        <div
          v-else
          class="brand-properties-lines__title"
        >
          <!-- Brand catalog page -->
          <template v-if="!isEnabled('oxp-1484-r3')">
            <i18n-t keypath="brand.properties.preorder.generalAvailability">
              <template #feature>
                <strong class="ds-capitalize">{{ t('brand.properties.preorder.feature') }}</strong>
              </template>
            </i18n-t>
          </template>
          <template v-else>
            <i18n-t
              keypath="brand.properties.preorder.generalAvailabilityExtended"
              tag="p"
            >
              <template #firstAvailableAt>
                <strong>{{ formatDate(firstPreorderProductAvailableAt, 'PPP') }}</strong>
              </template>
            </i18n-t>
            <i18n-t
              v-if="franco && !newMovEnabled"
              keypath="brand.properties.preorder.simpleMinimumValue"
              tag="p"
            >
              <template #minimumValue>
                <strong>{{ formatPrice(franco, '$0') }}</strong>
              </template>
            </i18n-t>
          </template>
        </div>
      </div>
      <div
        v-else
        class="container__main"
      >
        <div
          class="brand-properties-lines__title"
        >
          <i18n-t keypath="brand.properties.preorder.unavailableInYourCountry">
            <template #feature>
              <strong>{{ t('brand.properties.preorder.feature') }}</strong>
            </template>
          </i18n-t>
        </div>
      </div>
      <div class="tag">
        <AkParagraph weight="bold">
          {{ t('global.beta') }}
        </AkParagraph>
      </div>
    </div>
    <div
      v-if="shouldShowFirstOrder"
      class="container"
      data-testid="firstOrderDetails"
    >
      <div class="container__icon">
        <AkIcon
          symbol="telegram"
          size="md"
        />
      </div>
      <div class="container__main">
        <div class="title">
          <AkParagraph size="sm">
            <!-- eslint-disable @intlify/vue-i18n/no-v-html -->
            <span
              v-html="
                t('brand.properties.firstOrderNotFree.description', {
                  fee: formatPrice({ amount: franco.maxFee, currency: franco.currency }),
                  franco: formatPrice(franco, '$0'),
                  minimum: formatPrice(brand.minimum, '$0'),
                })
              "
            />
          </AkParagraph>
        </div>
        <div class="tag">
          <AkParagraph
            class="no-wrap"
            weight="bold"
          >
            {{ t('global.new') }}
          </AkParagraph>
        </div>
      </div>
    </div>

    <div
      v-if="shouldShowFirstOrderFreeShipping"
      class="container"
      data-testid="firstOrderDetailsFreeShipping"
    >
      <div class="container__icon">
        <AkIcon
          symbol="ankorstore-logo"
          size="md"
        />
      </div>
      <div class="container__main">
        <div class="title">
          <AkParagraph size="sm">
            <i18n-t keypath="brand.properties.firstOrder.description">
              <template #minimum>
                {{ formatPrice(brand.minimum, '$0') }}
              </template>
              <template #bold>
                <strong>
                  {{ t('brand.properties.firstOrder.name') }}
                </strong>
              </template>
            </i18n-t>
          </AkParagraph>
        </div>
        <div class="tag">
          <AkParagraph
            class="no-wrap"
            weight="bold"
          >
            {{ t('global.new') }}
          </AkParagraph>
        </div>
      </div>
    </div>

    <div
      v-if="shouldShowAksBrandNonEligibleFirstOrder"
      class="container"
      data-testid="firstOrderAksDetailsFreeShipping"
    >
      <div class="container__icon">
        <AkIcon
          symbol="ankorstore-logo"
          size="md"
        />
      </div>
      <div class="container__main">
        <div class="title">
          <AkParagraph size="sm">
            <!-- eslint-disable @intlify/vue-i18n/no-v-html -->
            <span
              v-html="
                t('brand.properties.firstOrderNonEligibleBrandAks.description', {
                  minimum: formatPrice(brand.minimum, '$0'),
                  franco: formatPrice(franco, '$0'),
                })
              "
            />
          </AkParagraph>
        </div>
        <div class="tag">
          <AkParagraph
            class="no-wrap"
            weight="bold"
          >
            {{ t('global.new') }}
          </AkParagraph>
        </div>
      </div>
    </div>

    <div
      v-if="(shouldShowRepeatOrder || shouldShowAksBrandNonEligibleRepeatOffer) && hideDeliveryIfPreorder"
      class="container"
      data-testid="repeatOrderDetails"
    >
      <div class="container__icon">
        <AkIcon
          symbol="telegram"
          size="md"
        />
      </div>
      <div class="container__main">
        <div class="title">
          <AkParagraph size="sm">
            <i18n-t keypath="brand.properties.repeatOrder.description">
              <template #bold>
                <strong>
                  <i18n-t keypath="brand.properties.repeatOrder.introduction">
                    <template #francoAmount>
                      {{ formatPrice(franco, '$0') }}
                    </template>
                  </i18n-t>
                </strong>
              </template>
              <template #francoAmount>
                {{ formatPrice(franco, '$0') }}
              </template>
              <template #fee>
                {{ formatPrice({ amount: franco.fee, currency: franco.currency }) }}
              </template>
              <template #minimum>
                {{ formatPrice(brand.minimum, '$0') }}
              </template>
            </i18n-t>
          </AkParagraph>
        </div>
        <div class="tag">
          <AkParagraph
            class="no-wrap"
            weight="bold"
          >
            {{ t('global.new') }}
          </AkParagraph>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { AkIcon, AkParagraph } from '@ankorstore/design-system';
import {BrandHolidayBannerInner} from "@bc/brands";
import usePrice from '@/composables/use-price';
import { useStore } from '@/composables/use-store';
import { isEnabled, FeatureFlag } from '@/services/features';
import { getBrandsFrancos } from '@/services/api/franco';
import { getPromisedDeliveryDate } from '@/services/api/promised-delivery-date';
import { formatDate } from '@/services/date-locale';

import { Brand } from '@/types/api/brand';
import { ShippingFee } from '@/types/shipping/shipping-fees';
import { Franco } from '@/types/api/franco';
import { captureException } from '@/sentry';
import useI18n from '@/composables/use-i18n';
import Analytics from '@/services/analytics';
import { SectionCreatedEvent } from '@/services/analytics/events/section_created.event';
import StoreRootHelpers from '@/store/helpers';

const props = withDefaults(
  defineProps<{
    brand: Brand;
    productId: number;
    longDistanceShippingFee?: ShippingFee | null;
    isOptin?: boolean;
    isPreorder?: boolean;
    availableAt?: string | null;
  }>(),
  {
    productId: () => null,
    longDistanceShippingFee: () => null,
    isOptin: () => false,
    isPreorder: () => false,
    availableAt: () => null,
  }
);

const store = useStore();
const { formatPrice } = usePrice();
const { t } = useI18n();
const { userCurrency } = StoreRootHelpers.useGetters(['userCurrency']);

const franco = ref<Franco | null>(null);
const promisedDeliveryDate = ref<string>(null);

const userIsRetailer = computed(() => store.getters.userIsRetailer);
const newMovEnabled = computed(() => store.getters['cartPreorder/newMovEnabled']);

const shouldShowFirstOrder = computed(
  () =>
    isEnabled(FeatureFlag.EnableNewBusinessModel) &&
    franco.value !== null &&
    props.brand.is_discovery &&
    !props.brand.is_eligible_for_discovery_free_shipping &&
    !props.isOptin
);
const shouldShowFirstOrderFreeShipping = computed(() => {
  return (
    isEnabled(FeatureFlag.EnableNewBusinessModel) &&
    franco.value !== null &&
    props.brand.is_eligible_for_discovery_free_shipping &&
    !props.isOptin
  );
});
const shouldShowRepeatOrder = computed(
  () => isEnabled(FeatureFlag.EnableNewBusinessModel) && !props.brand.is_discovery && !props.isOptin && franco.value !== null
);
const shouldShowAksBrandNonEligibleRepeatOffer = computed(() => {
  return (
    isEnabled(FeatureFlag.EnableNewBusinessModel) &&
    franco.value !== null &&
    props.isOptin &&
    props.brand.is_not_eligible_for_ak_plus_free_shipping &&
    !props.brand.is_discovery
  );
});

const shouldShowAksBrandNonEligibleFirstOrder = computed(() => {
  return (
    isEnabled(FeatureFlag.EnableNewBusinessModel) &&
    franco.value !== null &&
    props.isOptin &&
    props.brand.is_discovery &&
    props.brand.is_not_eligible_for_ak_plus_free_shipping
  );
});
const shouldShowHolidayModeBanner = computed(() => {
  return isEnabled(FeatureFlag.HolidayMode) && !!props.brand.holiday_mode?.enabled;
});

const shouldShowPromisedDeliveryDate = computed(() => {
  return isEnabled('promised_delivery_date') && promisedDeliveryDate.value && !shouldShowHolidayModeBanner.value;
});
const shouldShowShippingLeadTimeDetails = computed(() => {
  return !isEnabled('promised_delivery_date') && props.brand.shipping_lead_time_details && !shouldShowHolidayModeBanner.value;
});
// TODO remove redundant field, when backend be updated
const hasPreorderProducts = computed(() => props.brand.has_preorder_products);
const firstPreorderProductAvailableAt = computed(() => props.brand.first_preorder_product_available_at);
const hideDeliveryIfPreorder = computed(() => {
  return !props.isPreorder || (props.isPreorder && props.availableAt === null);
});

const trackPromisedDeliveryDate = () => {
  Analytics.track(
    new SectionCreatedEvent({
      component: 'PDD',
      value_proposition: promisedDeliveryDate.value,
      ...(props.productId
        ? {
            id_product: [
              {
                id: props.productId,
                position: 0,
              },
            ],
          }
        : {
            id_brand: [
              {
                id: props.brand.id,
                position: 0,
              },
            ],
          }),
    })
  );
};

onMounted(async () => {
  if (userIsRetailer.value) {
    if (isEnabled('promised_delivery_date')) {
      getPromisedDeliveryDate(props.brand.uuid)
        .then((data) => {
          promisedDeliveryDate.value = data?.attributes?.deliveryDate;
          trackPromisedDeliveryDate();
        })
        .catch((e) => {
          captureException(e);
        });
    }

    try {
      const [response] = await getBrandsFrancos([props.brand.uuid]);
      franco.value = response;
    } catch (e) {
      captureException(e);
    }
  }
});
</script>

<style lang="scss" scoped>
.brand-properties-lines {
  @apply ds-bg-warm-white;
  @apply ds-mb-2;
  border-radius: 12px;
  .container {
    @apply ds-flex ds-gap-2 ds-p-4 lg:ds-px-5 ds-flex-row ds-items-center ds-border-b ds-border-solid ds-border-neutral-300;
    &:last-of-type {
      @apply ds-border-b-0;
    }
    &__main {
      @apply ds-flex-1 ds-flex ds-justify-between ds-gap-2;
    }
    &__icon {
      @apply ds-rounded-full ds-bg-white ds-flex ds-items-center ds-w-6 ds-h-6 ds-justify-center ds-text-info ds-self-start;
    }
  }
  .tag {
    @apply ds-text-info;
  }
}
</style>
<style lang="scss">
.brand-properties-lines {
  .title,
  &__title {
    strong {
      @apply ds-font-bold;
    }
  }
}

.no-wrap {
  text-wrap: nowrap;
}
</style>
