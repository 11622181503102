import type { SegmentTrackingFilter } from '@bc/discovery';

export enum CatalogFilterEventName {
  FilterPanelClicked = 'Filter Panel Clicked',
  FilterApplied = 'Filter Applied',
}

export enum CatalogFilterFilterName {
  BrandLocation = 'brand_location',
  Margin = 'margin',
  Value = 'value',
  Price = 'price',
  Sorting = 'sorting',
  Brands = 'brands',
  FilterBrands = 'filter_brands',
  MadeIns = 'Made_in',
  Shipping = 'shipping',
  AllFilters = 'all_filters',
  Category = 'category',
  Discount = 'discount',
  LoyaltyOffer = 'loyalty_offer',
}

export type CatalogFiltersEventProperties = {
  filter: string;
  active?: boolean;
  filters?: SegmentTrackingFilter<string | number | { min?: number; max?: number }>[];
  filtersQuery?: string;
};
