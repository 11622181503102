import AnalyticsEvent from './analytics-event';
import { NotificationsPanelStatus, NotificationsPanelChangeSource } from '../properties/notifications-property';

export class NotificationsPanelEvent extends AnalyticsEvent {
  public readonly name = 'Notification Panel Toggled';
  public properties: {
    status: NotificationsPanelStatus;
    source: NotificationsPanelChangeSource;
  };

  constructor(status: NotificationsPanelStatus, source: NotificationsPanelChangeSource) {
    super();
    this.properties = {
      status,
      source,
    };
  }
}
