import Analytics from '@/services/analytics';
import { CatalogueType, TrackingService } from '@bc/discovery/domain/tracking';
import { SectionCreatedEvent, SectionItemType } from '@/services/analytics/events/section_created.event';
import {
  FilterState,
  AnyTile,
  BrandTile,
  CatalogBrowserView,
  NormalTileType,
  ProductTile,
  SegmentTrackingFilter,
  TrackProductHitsUpdatedEvent,
  RangeValue,
} from '@bc/discovery/domain/catalog';
import { ProductListViewedEvent } from '@/services/analytics/events/product-list-viewed.event';
import UserClick from '@/services/analytics/events/user-click.event';
import type { IdPosition } from '@bc/shared';

const getLastCategory = (filterState: FilterState) =>
  // get the last category in the array
  [...filterState.selectedCategories].pop();

interface TrackParams {
  brandId?: string | number;
  productId?: string | number;
  position: number;
  page: number;
  id_section: string;
  brandTags?: string[];
  component: string;
}

export const trackBrandClickedInProductTileSegment = ({
  brandId,
  page,
  position,
  id_section,
  component,
  brandTags,
  productId,
}: TrackParams): Promise<void> => {
  return Analytics.track(
    new UserClick({
      component,
      action: 'brand_clicked',
      itemType: 'product',
      brandId,
      id_section,
      position,
      brandTags,
      page_number: page,
      productId,
    })
  );
};

export const createValueProposition = (
  catalogueType: CatalogueType,
  filterState: FilterState,
  meta: { title?: string }
): { value_proposition?: string; value_proposition_id?: string | number } => {
  if (catalogueType === CatalogueType.Category) {
    const lastCategory = getLastCategory(filterState);
    return {
      value_proposition_id: lastCategory?.id,
      value_proposition: lastCategory?.full_name ?? meta.title,
    };
  }

  if (catalogueType === CatalogueType.Search || catalogueType === CatalogueType.BusinessEvent) {
    return { value_proposition: meta.title ?? '' };
  }

  return {};
};

export const trackProductHitsUpdated = ({
  valueProposition,
  catalogueType,
  view,
  pageIndex,
  sectionId,
  filters,
  filterState,
  tiles,
}: TrackProductHitsUpdatedEvent): void => {
  const isProductTile = (item: { position: number; tile: AnyTile }): item is { position: number; tile: ProductTile } =>
    item.tile.type === NormalTileType.Product || item.tile.type === NormalTileType.SponsoredProduct;
  const idProduct: IdPosition[] = tiles
    .map((tile, index) => ({ position: index, tile }))
    .filter(isProductTile)
    .map(({ position, tile }) => ({
      id: Number(tile.product.id),
      position,
      type: tile.type === NormalTileType.Product ? 'organic' : 'sponsored',
    }));
  trackSectionCreated({
    idBrand: [],
    idProduct,
    valueProposition,
    catalogueType,
    view,
    pageIndex,
    sectionId,
    filters,
  });
  Analytics.track(
    new ProductListViewedEvent({
      list_id: undefined,
      category: filterState?.selectedCategories?.map(({ full_name }) => full_name).join(' > '),
      products: tiles
        .map((tile, index) => ({ position: index + 1, tile }))
        .filter(isProductTile)
        .map(({ position, tile }) => ({
          position,
          brand: tile.product.brand?.name,
          name: tile.product.name as string,
          product_id: tile.product.id,
        })),
    })
  );
};

export const trackBrandHitsUpdated = ({
  valueProposition,
  catalogueType,
  view,
  pageIndex,
  sectionId,
  filters,
  tiles,
}: {
  valueProposition: Record<string, string | number>;
  catalogueType: CatalogueType;
  view: CatalogBrowserView;
  pageIndex: number;
  sectionId: string;
  filters: SegmentTrackingFilter<string | number | RangeValue>[];
  tiles: AnyTile[];
}): void => {
  const isBrandTile = (item: { position: number; tile: AnyTile }): item is { position: number; tile: BrandTile } =>
    item.tile.type === NormalTileType.Brand;
  const idBrand = tiles
    .map((tile, index) => ({ position: index, tile }))
    .filter(isBrandTile)
    .map(({ position, tile }) => ({
      id: Number(tile.id),
      position,
    }));
  trackSectionCreated({
    idBrand: idBrand,
    idProduct: [],
    valueProposition,
    catalogueType,
    view,
    pageIndex,
    sectionId,
    filters,
  });
};

export const trackSectionCreated = ({
  idBrand,
  idProduct,
  valueProposition,
  catalogueType,
  view,
  pageIndex,
  sectionId,
  filters,
}: {
  idBrand: IdPosition[];
  idProduct: IdPosition[];
  valueProposition: Record<string, string | number>;
  catalogueType: CatalogueType;
  view: CatalogBrowserView;
  pageIndex: number;
  sectionId: string;
  filters: SegmentTrackingFilter<string | number | RangeValue>[];
}): void => {
  Analytics.track(
    new SectionCreatedEvent({
      section_type: TrackingService.evalSectionType(catalogueType),
      itemtype: {
        [CatalogBrowserView.Products]: SectionItemType.Product,
        [CatalogBrowserView.Brands]: SectionItemType.Brand,
      }[view],
      page_number: pageIndex + 1,
      value_proposition: valueProposition.value_proposition as string,
      value_proposition_id: valueProposition.value_proposition_id as number,
      id_product: idProduct,
      id_brand: idBrand,
      id_section: sectionId,
      filters,
    })
  );
};
