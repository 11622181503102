import AnalyticsEvent from './analytics-event';
import { OrderCompletedProperties } from './segment-ecommerce-types';

export class OrderCompletedEvent extends AnalyticsEvent {
  public readonly name = 'Order Completed';

  constructor(public readonly properties: OrderCompletedProperties) {
    super();
  }
}
