<template>
  <div
    data-testid="qatc"
    :class="{
      QATC: true,
      [NativeAdClickOrigin.QuickAddToCart]: true,
      'QATC--with-feedback': willShowFeedback || isShowingFeedback,
    }"
  >
    <AkButton
      :class="{ QATC__button: true, 'ds-absolute ds-w-full': !mobile }"
      data-testid="qatc-button"
      :disabled="isShowingFeedback"
      outlined
      color="white"
      multiline
      @click="clickHandler"
    >
      <AkIcon
        class="ds-pb-0.5 ds-mr-0.5"
        :symbol="isShowingFeedback ? 'check' : 'plus'"
        size="sm"
      />
      <span v-if="!mobile">{{ buttonLabel }}</span>
    </AkButton>
    <AkModal
      v-if="$isEnabled('oxp-1484-r3')"
      ref="preorderArrivedPopup"
      size="md"
      data-testid="preorderArrivedPopup"
      @cancelled="handlePopupClose"
    >
      <PreorderArrivedPopup @popup-close="handlePopupClose" />
    </AkModal>
  </div>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash-es';
import Analytics from '@/services/analytics';
import { ProductQuickAddToCartEvent } from '@/services/analytics/events/product-quick-added-to-cart.event';
import { createProductProperty } from '@/services/analytics/properties/product-property';
import { defineComponent, PropType, ref } from 'vue';
import { BrandProperty } from '@/services/analytics/properties/brand-property';
import Product, { Option, ProductHit } from '@/types/product';
import { AkIcon } from '@ankorstore/design-system';
import { OptionsEntity } from '@/types/api/product';
import { CanAddFirstVariantToCartUseCase, DiscoveryTrackingEvents } from '@bc/discovery';
import { useEventBus } from '@bc/shared';
import { NativeAdClickOrigin } from '@bc/advertisement';
import { isPreorder } from '@/services/product-variants';
import PreorderArrivedPopup from '@/components/popins/preorder-arrived-popup.vue';
import Cookie from '@/utilities/cookies/cookies';
import { COOKIE_NAME } from '@/types/cookies';

interface QuickAddToCartData {
  item: {
    option: Partial<OptionsEntity | Option>;
    brand: BrandProperty;
    quantity: number;
    preorderQuantity: number;
  };
  hasVariants: boolean;
  itemsToAddNext: number;
  willShowFeedback: boolean;
  feedbackTimeoutID: number;
}

export const CLICK_DEBOUNCE_TIME = 550;
export const FEEDBACK_TIME = 2000;

export default defineComponent({
  name: 'QuickAddToCart',
  components: {
    PreorderArrivedPopup,
    AkIcon,
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: false,
      default: undefined,
    },
    pageNumber: {
      type: Number,
      required: false,
      default: undefined,
    },
    product: {
      type: Object as PropType<Product | ProductHit>,
      required: true,
    },
    parentSectionId: {
      type: String,
      default: undefined,
    },
    parentComponent: {
      type: String as PropType<string>,
      default: null,
    },
    isSponsored: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['clicked', 'clickedAddToCart'],
  setup() {
    const { eventBus } = useEventBus();
    const preorderArrivedPopup = ref(null);

    const handlePopupClose = () => {
      Cookie.set(COOKIE_NAME.PREORDER_ARRIVED_POPUP_SNOOZED, { expires: 365 });
      (preorderArrivedPopup.value as DS['AkModal']).close();
    };

    return { eventBus, NativeAdClickOrigin, preorderArrivedPopup, handlePopupClose };
  },
  data(): QuickAddToCartData {
    return {
      item: {
        option: { unit_multiplier: 1, name: '' },
        brand: null,
        quantity: 0,
        preorderQuantity: 0,
      },
      hasVariants: null,
      itemsToAddNext: 0,
      willShowFeedback: false,
      feedbackTimeoutID: null,
    };
  },
  computed: {
    ...mapGetters({
      productByOptionId: 'productByOptionId',
      itemByOptionId: 'cart/itemByOptionId',
      itemByVariantId: 'cartPreorder/itemByVariantId',
    }),
    buttonLabel(): string {
      return this.isShowingFeedback
        ? this.$tc(
            '{count} item added to cart! | {count} item added to cart! | {count} items added to cart!',
            this.itemsToAddNext * this.product.unit_multiplier
          )
        : this.$tc(
            'Quick add of {count} product | Quick add of {count} product | Quick add of {count} products',
            this.product.unit_multiplier
          );
    },
    isShowingFeedback(): boolean {
      return Number.isInteger(this.feedbackTimeoutID);
    },
    productFromStore(): Product {
      return this.$store.getters['productById'](this.product.id);
    },
  },
  watch: {
    product: {
      handler: function () {
        this.item.brand = this.product.brand;
        const variant = this.product.variants[0];
        this.item.option = {
          ...this.product.options.find(({ sku }) => sku === variant?.sku),
          unit_multiplier: this.product.unit_multiplier,
          name: this.product.name as string,
        };
        this.item.quantity =
          this.itemByOptionId(this.item.option?.id) !== null ? parseInt(this.itemByOptionId(this.item.option.id).quantity) : 0;
        this.item.preorderQuantity =
          this.$isEnabled('oxp-1484-r3') && this.itemByVariantId(this.productFromStore?.variants[0]?.uuid) !== null
            ? parseInt(this.itemByVariantId(this.productFromStore?.variants[0]?.uuid).quantity)
            : 0;
        this.hasVariants = Boolean(this.product.variants.length > 1);
      },
      immediate: true,
    },
  },
  created() {
    this.debouncedExecuteUpdateActions = debounce(this.executeUpdateActions, CLICK_DEBOUNCE_TIME);
  },
  unmounted() {
    this.feedbackTimeoutID && window.clearTimeout(this.feedbackTimeoutID);
  },
  methods: {
    ...mapActions({
      addToCartAction: 'cart/addToCart',
      updateItemQuantityAction: 'cart/updateItemQuantity',
      addToPreorderCartAction: 'cartPreorder/addToCart',
      updatePreorderItemQuantityAction: 'cartPreorder/updateItemQuantity',
    }),
    trackClick() {
      this.eventBus.emit(DiscoveryTrackingEvents.QUICK_ADD_TO_CART_CLICKED, {
        page: this.pageNumber,
        productId: this.product.id,
        position: this.index,
        sectionId: this.parentSectionId,
        isSponsoredTile: this.isSponsored,
        component: this.parentComponent,
      });
    },
    clickHandler() {
      this.willShowFeedback = true;
      this.item.quantity += 1;
      this.item.preorderQuantity += 1;
      this.itemsToAddNext += 1;
      this.debouncedExecuteUpdateActions();
      this.trackClick();
      this.$emit('clicked', { product: this.product });
    },
    async executeUpdateActions() {
      if (this.hasVariants) {
        this.clearFeedbackState();
        this.$emit('clickedAddToCart', { product: this.product });
        return;
      }

      const canAddToCart = await new CanAddFirstVariantToCartUseCase(this.$store).execute(this.product, this.item.quantity);

      if (!canAddToCart) {
        return;
      }

      this.displayFeedback();
      if (
        isPreorder(this.product) &&
        this.$isEnabled('oxp-1484-r3') &&
        (!this.productFromStore || !this.productFromStore?.variants[0]?.uuid)
      ) {
        await this.refreshProduct();
      }
      if (
        this.itemByOptionId(this.item.option?.id) ||
        (isPreorder(this.product) &&
          this.$isEnabled('oxp-1484-r3') &&
          this.itemByVariantId(this.productFromStore?.variants[0]?.uuid))
      ) {
        return this.executeUpdateQuantityAction();
      }
      await this.executeAddToCartAction();
      this.trackUpdate();
    },
    displayFeedback() {
      this.feedbackTimeoutID = window.setTimeout(this.clearFeedbackState, FEEDBACK_TIME);
    },
    clearFeedbackState() {
      this.willShowFeedback = false;
      this.itemsToAddNext = 0;
      this.feedbackTimeoutID = null;
    },
    executeUpdateQuantityAction() {
      if (isPreorder(this.product) && this.$isEnabled('oxp-1484-r3')) {
        const { brand } = this.productFromStore;

        this.updatePreorderItemQuantityAction({
          brand,
          item: this.itemByVariantId(this.productFromStore?.variants[0]?.uuid),
          quantity: this.item.preorderQuantity,
        });
      } else {
        const { brand, quantity } = this.item;

        this.updateItemQuantityAction({
          brand,
          item: this.itemByOptionId(this.item.option?.id),
          quantity,
        });
      }
    },
    async executeAddToCartAction() {
      if (isPreorder(this.product) && this.$isEnabled('oxp-1484-r3')) {
        const variant = this.productFromStore.variants[0];
        const quantity = this.item.preorderQuantity;

        await this.addToPreorderCartAction({ variant, quantity });
        if (!Cookie.get(COOKIE_NAME.PREORDER_ARRIVED_POPUP_SNOOZED)) {
          (this.preorderArrivedPopup as DS['AkModal']).openModal();
        }
      } else {
        const { brand, option, quantity } = this.item;
        const { variants = [] } = this.product;
        const [variant] = variants;

        await this.addToCartAction({ brand, option, variant, quantity, noTrack: true });
      }
    },
    trackUpdate(): void {
      const { brand, option } = this.item;
      const properties = {
        productProperty: createProductProperty({
          id: this.product.id,
          name: this.product.name as string,
          wholesalePrice: this.product.wholesale_price,
          position: this.index,
          option: { id: option.id, name: option.name as string },
        }),
        brandProperty: brand,
        page: this.pageNumber,
        quantity: this.item.quantity * this.item.option.unit_multiplier,
      };
      const fallbackTracking = () => {
        Analytics.track(
          new ProductQuickAddToCartEvent(
            properties.productProperty,
            properties.brandProperty,
            properties.page,
            properties.quantity,
            this.parentSectionId
          )
        );
      };
      this.eventBus.tryEmit(DiscoveryTrackingEvents.QUICK_ADD_TO_CART_PRODUCT_ADDED, fallbackTracking, properties);
    },

    async refreshProduct(): Promise<void> {
      await this.$store.dispatch('fetchProduct', {
        product_id: this.product.id,
      });
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.QATC {
  &__button {
    z-index: $z-index-catalog-card-items;
    transition: transform 0.025s;
    &:disabled {
      opacity: 100 !important;
    }
    &:active:not(:disabled) {
      @apply ds-bg-warm-white;
    }
  }
}
</style>
