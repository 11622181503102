import http from '@/services/api/http';
import {
  EmailCampaign,
  EmailCampaignType,
  EmailCampaignPagination,
  EmailCampaignRecipientPagination,
} from '@/types/email-campaign';

export const getPaginatedEmailCampaigns = async (page?: number, perPage?: number): Promise<EmailCampaignPagination> => {
  const { data } = await http().get('/api/me/brand/campaigns', {
    params: {
      page: page || 1,
      perPage: perPage || 25,
    },
  });

  return data;
};

export const getEmailCampaign = async (id: number): Promise<EmailCampaign> => {
  const { data } = await http().get(`/api/me/brand/campaigns/${id}`);
  return data?.data;
};

export const getEmailCampaignPreview = async (id: number): Promise<HTMLElement> => {
  const { data } = await http().get(`/api/me/brand/campaigns/${id}/preview`);
  return data;
};

interface ValidateUserCredentialsResponse {
  email: string;
  brand: {
    name: string;
    id: string;
  };
}
export const validateUserCredentials = async (email: string, brand_id: string): Promise<ValidateUserCredentialsResponse> => {
  const { data } = await http().post('/api/campaign-preferences/validate', {
    email,
    brand_id,
  });

  return data?.data;
};

export const updateCampaignPreferences = async (email: string, type: EmailCampaignType, brand_id?: string): Promise<void> => {
  const { data } = await http().post('/api/campaign-preferences/update', {
    email,
    type,
    brand_id,
  });

  return data?.data;
};

export const getPaginatedEmailRecipients = async (
  id: number,
  page?: number,
  perPage?: number
): Promise<EmailCampaignRecipientPagination> => {
  const { data } = await http().get(`/api/me/brand/campaigns/${id}/recipients-list`, {
    params: {
      page: page || 1,
      perPage: perPage || 25,
    },
  });

  return data;
};
