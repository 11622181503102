import { getCurrentInstance, h, render, ref, readonly, nextTick, onUnmounted } from 'vue';
import { memoize } from 'lodash-es';
import DrawerWrapper from './drawer-wrapper.vue';
import { AkDrawerProps, DrawerContentSlot } from './types';
import { documentScrollDisabler } from '@bc/shared/util';

/**
 * Dynamically display content in a drawer that can be opened and closed.
 * @example
 * Basic usage with a <div> element:
 * ```vue
 * <script setup>
 * import { h, onMounted } from 'vue';
 *
 * const drawer = useDrawer();
 *
 * onMounted(() => {
 *   drawer.openDrawer(() => h('div', 'Hello, world!'));
 * });
 * </script>
 * ```
 *
 * Basic usage with a component:
 * ```vue
 * <script setup>
 * import { h, onMounted } from 'vue';
 * import MyProductComponent from './MyProductComponent.vue';
 * import { useDrawer } from './use-drawer';
 *
 * const drawer = useDrawer();
 * const props = { productId: 123 };
 *
 * onMounted(() => {
 *  drawer.openDrawer(() => h(MyProductComponent, props));
 * });
 * </script>
 */
export const useDrawer = memoize(() => {
  const globalAppContext = getCurrentInstance()?.appContext;
  const div = document.createElement('div');
  const isOpen = ref(false);
  const closeDrawerFn = ref<() => void>();

  const { disableScroll, enableScroll } = documentScrollDisabler();

  const renderDrawer = (content: DrawerContentSlot, props?: Partial<AkDrawerProps>) => {
    const drawerVNode = h(
      DrawerWrapper,
      {
        ...props,
        'onUpdate:modelValue': (value: boolean) => {
          value ? disableScroll() : enableScroll();
          isOpen.value = value;
        },
      },
      { default: content }
    );

    drawerVNode.appContext = globalAppContext;

    render(drawerVNode, div);

    return {
      open: () => drawerVNode.component.exposed.open(),
      close: () => drawerVNode.component.exposed.close(),
    };
  };

  /**
   * Open the drawer with the provided content and options to customise the drawer.
   *
   * @example
   * ```vue
   * <script setup>
   * ...
   *
   * drawer.openDrawer({ right: true, width: '400px'});
   *
   * </script>
   */
  const openDrawer = (content: DrawerContentSlot, props?: Partial<AkDrawerProps>) => {
    const { open, close } = renderDrawer(content, props);

    closeDrawerFn.value = close;
    nextTick(() => open());

    return publicApi;
  };

  /**
   * Close the drawer. This will remove the drawer from the DOM.
   */
  const closeDrawer = () => {
    nextTick(() => {
      closeDrawerFn.value?.();
      render(null, div);
    });

    return publicApi;
  };

  onUnmounted(() => {
    enableScroll();
  });

  const publicApi = {
    openDrawer,
    closeDrawer,
    isOpen: readonly(isOpen),
  };

  return publicApi;
});
