import http from '@/services/api/http';
import { PromisedDeliveryDate } from '@/types/api/promised-delivery-date';
import qs from 'qs';

export const getPromisedDeliveryDate = async (brandUuid: string): Promise<PromisedDeliveryDate> => {
  const url = `/api/internal/v1/ordering/promise-delivery-dates/${brandUuid}`;

  return http()
    .get(url)
    .then(({data}) => {
      return data?.data;
    })
}

export const getPromisedDeliveryDates = async (brandUuids: string[]): Promise<PromisedDeliveryDate[]> => {
  const url = `/api/internal/v1/ordering/promise-delivery-dates`;
  const params = {
    filter: { brandId: brandUuids },
  };

  return http()
    .get(url, {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    })
    .then(({data}) => {
      return data?.data;
    })
}
