import { FETCH_OFFERS_AND_EVENTS, FETCH_R2B_OFFER_CONFIG } from '@/store/offers/action-types';
import { ActionContext } from 'vuex';
import { getOfferConfig } from '@/services/api/r2b';
import { getB2REligibility } from '@/services/b2r';
import { OffersState } from '@/store/offers/index';
import {
  SET_B2R_ELIGIBILITY,
  SET_B2R_ELIGIBILITY_FETCHED,
  SET_BUSINESS_EVENTS,
  SET_OFFERS_ELIGIBILITY,
  SET_OFFERS_ELIGIBILITY_FETCHED,
  SET_R2B_OFFERS,
  SET_R2B_OFFERS_FETCHED,
  SET_SUM_UP_ELIGIBILITY,
  SET_SUM_UP_ELIGIBILITY_FETCHED,
  SET_WELCOME_OFFER_ELIGIBILITY,
  SET_WELCOME_OFFER_ELIGIBILITY_FETCHED,
} from '@/store/offers/mutation-types';
import { welcomeOfferEligibility } from '@/services/welcome-voucher';
import { getGlobalEvents } from '@/services/api/business-events';
import { offerEligibility } from '@/services/offer-eligibility';
import { offerProgrammesV1 } from '@/modules/promote-engage/services/offer';

const offersActions = {
  [FETCH_R2B_OFFER_CONFIG]: async ({ commit, state, rootGetters }: ActionContext<OffersState, any>): Promise<void> => {
    if (!state.r2b.offers_config_fetched) {
      const result = await getOfferConfig(rootGetters.userIsRetailer);

      commit(SET_R2B_OFFERS, result);
      commit(SET_R2B_OFFERS_FETCHED, true);
    }
  },
  [FETCH_OFFERS_AND_EVENTS]: async ({ commit, state, rootGetters }) => {
    if (!state.b2r.eligibilityFetched) {
      getB2REligibility(rootGetters.userIsRetailer).then((result) => {
        commit(SET_B2R_ELIGIBILITY_FETCHED, true);
        commit(SET_B2R_ELIGIBILITY, result);
      });
    }

    if (!state.welcomeOffer.eligibilityFetched) {
      welcomeOfferEligibility(rootGetters.userIsRetailer).then((result) => {
        commit(SET_WELCOME_OFFER_ELIGIBILITY_FETCHED, true);
        commit(SET_WELCOME_OFFER_ELIGIBILITY, result);
      });
    }

    if (!state.sumUp.eligibilityFetched && rootGetters.userIsRetailer) {
      offerEligibility('sumup').then((result) => {
        commit(SET_SUM_UP_ELIGIBILITY_FETCHED, true);
        commit(SET_SUM_UP_ELIGIBILITY, result);
      });
    }

    if (!state.offerEligibility.eligibilityFetched && rootGetters.userIsRetailer) {
      await offerProgrammesV1()
        .then((result) => {
          commit(SET_OFFERS_ELIGIBILITY, result);
          commit(SET_OFFERS_ELIGIBILITY_FETCHED, true);
        })
        .catch(() => {
          commit(SET_OFFERS_ELIGIBILITY, []);
          commit(SET_OFFERS_ELIGIBILITY_FETCHED, true);
        });
    }

    await getGlobalEvents().then(async (events) => {
      await commit(SET_BUSINESS_EVENTS, events);
    });
  },
};

export default offersActions;
export type OffersActions = typeof offersActions;
