<template>
  <div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IntersectionObserver',
  props: {
    root: {
      type: HTMLElement,
      default: null,
    },
    rootMargin: {
      type: String,
      default: '0px',
    },
    threshold: {
      type: Number,
      default: 0,
    },
  },
  emits: ['change'],
  data() {
    return {
      observer: null,
    };
  },
  mounted() {
    const options = {
      root: this.root,
      rootMargin: this.rootMargin,
      threshold: this.threshold,
    };
    this.observer = new IntersectionObserver((entries) => {
      this.$emit('change', entries[0], this.unobserve);
    }, options);
    this.observer.observe(this.$el);
  },
  beforeUnmount() {
    if (this.observer) {
      this.unobserve();
      this.observer = null;
    }
  },
  methods: {
    unobserve() {
      this.observer.unobserve(this.$el);
    },
  },
});
</script>
