export const SignUpEventNames = {
  EmailSubmittedEvent: 'Sign Up Pop Up Email Submitted',
  EmailViewedEvent: 'Sign Up Pop Up Email Viewed',
  EmailMandatoryEvent: 'Sign Up Pop Up Email Mandatory Warning',

  AlreadyAccountEvent: 'Sign Up Already Account Event',
  AlreadyAccountClickedEvent: 'Sign Up Pop Up Already Account Clicked',
  NoAccountYetEvent: 'No Account Event',

  GeneralInfoSubmittedEvent: 'Sign Up Pop Up General Info Submitted',
  GeneralInfoViewedEvent: 'Sign Up Pop Up General Info Viewed',
  GeneralInfoMandatoryEvent: 'Sign Up Pop Up General Info Mandatory Warning',

  AboutRetailerSubmittedEvent: 'Sign Up Pop Up About Your Business Submitted',
  AboutRetailerViewedEvent: 'Sign Up Pop Up About Your Business Viewed',
  AboutRetailerMandatoryEvent: 'Sign Up Pop Up About Your Business Mandatory Warning',

  ActivityPreferencesSubmittedEvent: 'Sign Up Pop Up Preferences Submitted',
  ActivityPreferencesViewedEvent: 'Sign Up Pop Up Preferences Viewed',
  ActivityPreferencesMandatoryEvent: 'Sign Up Pop Up Preferences Mandatory Warning',

  CompanyNumberSubmittedEvent: 'Sign Up Pop Up Company Number Submitted',
  CompanyNumberViewedEvent: 'Sign Up Pop Up Company Number Viewed',
  CompanyNumberMandatoryEvent: 'Sign Up Pop Up Company Number Mandatory Warning',

  CompanyNumberLaterEvent: 'Sign Up Pop Up Company Number Confirm Later',
  CompanyNumberSoleTraderBack: 'Sign Up Pop Up Company Number Sole Trader Go Back',
  CompanyNumberSoleTrader: 'Sign Up Pop Up Company Number Sole Trader',

  CompanySearchSuggestionClickedEvent: 'Sign Up Company Search Suggestion Clicked',
  SuggestedCompanyAddressChangedEvent: 'Sign Up Suggested Company Address Changed',

  LiftPageCompletedRegisteredEvent: 'Lift Page Completed Register',
  RetailerSignupCompletedEvent: 'Sign Up Completed',

  SubcategoriesSubmittedEvent: 'Sign Up Pop Up Subcategories Submitted',
  SubcategoriesViewedEvent: 'Sign Up Pop Up Subcategories Viewed',
  SubcategoriesMandatoryEvent: 'Sign Up Pop Up Subcategories Mandatory Warning',

  CongratsB2RCTAEvent: 'Sign Up Pop Up Congrats B2R CTA',
  CongratsB2RViewedEvent: 'Sign Up Pop Up Congrats B2R Viewed',

  NotRetailerViewedEvent: 'Sign Up Pop Up Sorry Viewed',

  RequestInformationShownEvent: 'Sign Up Request Information Modal Shown',
  RequestInformationClosedEvent: 'Sign Up Request Information Modal Closed',
  RequestInformationSkippedEvent: 'Sign Up Request Information Modal Skipped',
  RequestInformationInformationEvent: 'Sign Up Request Information Modal Continued',
} as const;

type Keys = keyof typeof SignUpEventNames;
export type SignUpEventNameType = (typeof SignUpEventNames)[Keys];
