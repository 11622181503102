import { InvitedStatus, R2BStatus, GetStatusParameters, R2BOfferConfiguration } from '@/types/r2b';
import http from './http';
import { memoize } from 'lodash-es';

export const formatUrl = (url: string): string => {
  if (!url) {
    return '';
  }

  if (!url?.startsWith('http')) {
    return `https://${url}`;
  }

  return url;
};

export const submitUrl = (url: string): Promise<InvitedStatus> =>
  http()
    .post(`/api/me/retailer/referral/brand`, { url })
    .then((response) => response.data?.data as InvitedStatus);

export const getStatus = (params: GetStatusParameters): Promise<R2BStatus> => {
  const url = params.url || '/api/me/retailer/referral/brands';
  return http()
    .get(url, {
      params: { status: params.status },
    })
    .then((response) => response.data?.data as R2BStatus);
};

export const getOfferConfig = memoize((isRetailer = false) => {
  return isRetailer
    ? http()
        .get('/api/me/r2b_offer_configuration')
        .then((response) => response.data?.data as R2BOfferConfiguration)
    : null;
});
