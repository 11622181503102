import http from './http';
import { ProductsPagination } from '@/types/api/product-pagination';
import Product from '@/types/product';

export const getProducts = async (params = {}): Promise<ProductsPagination> =>
  http()
    .get(`/api/me/brand/products`, { ...params, noProgressBar: true })
    .then((response) => response.data);

export const getProduct = async (id: number): Promise<Product> =>
  http()
    .get(`/api/products/${id}`)
    .then((response) => response.data.data);

export const updateProductCategories = (payload): Promise<{ data: Product[] }> =>
  http()
    .post(`/api/me/brand/products/mass-action`, payload)
    .then((response) => response.data);

export const getSelectedProducts = async (productIds: number[], productVariantUuids: string[] = []): Promise<Product[]> => {
  const url = '/api/me/brand/products';
  const params = {
    'filters[product_variant_ids]': productIds.join(),
    'filters[product_variant_uuids]': productVariantUuids.join(),
  };

  const response = await http().get(url, { params });

  return response.data.data;
};

/**
 * Get selected products info by single uuid or multiple uuids
 * @param {(string|string[])} uuid
 * @returns {Promise<Product[]>}
 */
export const getSelectedProductsVariantByUuid = async (uuid: string | string[]): Promise<Product[]> => {
  const url = '/api/me/brand/products';
  const params = {
    'filters[product_variant_uuids]': Array.isArray(uuid) ? uuid.join(',') : uuid,
  };

  const data = await http().get(url, { params });

  return data.data.data;
};

/**
 * Get products info list by providing its uuids
 * @param {string[]} uuids
 * @returns {Promise<Product[]>}
 */
export const getProductListByUuid = async (uuids: string[]): Promise<Product[]> => {
  const params = {
    'filters[product_uuids]': uuids.join(),
  };

  return http()
    .get('/api/me/brand/products', { params })
    .then((response) => response.data.data);
};

export const isOrderedBrand = async (brandId: number): Promise<boolean> =>
  http()
    .get(`/api/brands/${brandId}/ordered`)
    .then((response) => response.data?.data?.ordered ?? false);

export const updateBrandProductCount = async (count: number): Promise<void> =>
  http().put(`/api/me/brand/product-count`, { product_count: count });
