<template>
  <div
    class="brand-snippet ds-h-full"
    :class="{
      'ds-text-left': $isEnabled('rcb-467'),
      'ds-border-neutral-300 ds-border ds-border-solid ds-rounded-md ds-overflow-hidden ds-shadow-sm': true,
    }"
  >
    <a
      class="brand"
      :class="{ 'coming-soon': !brand.active }"
      :href="brandLink"
      data-testid="button-brand"
      @click.prevent="handleClick"
      @mouseover="updateCurrentCategoryKey()"
    >
      <figure>
        <div class="image-container ds-z-0 ds-w-full">
          <div
            v-if="!brand.active"
            class="coming-soon-overlay"
          >
            <AkIcon
              symbol="clock-history"
              size="md"
            />
            <span class="coming-soon-title">{{ $t('Coming soon') }}</span>
            <ComingSoonAlertButton
              v-if="userIsRetailer"
              :id="brand.id"
            />
          </div>

          <template v-if="!$isEnabled('rcb-476')">
            <ImageLazyload
              class="brand-image ds-aspect-square"
              :src="imgCdnUrl(brand.images.squared, '400')"
              :alt="brand.name"
            />
            <div
              v-if="brand.active && brand.highlighted_products"
              class="popin-teaser"
            >
              <template
                v-for="product in getHighlightedProducts(brand.highlighted_products)"
                :key="product.id"
              >
                <ImageLazyload
                  importance="low"
                  :src="imgCdnUrlCrop(product.image, '200')"
                  :alt="brand.name"
                />
              </template>
            </div>
          </template>

          <AkCarousel
            v-else
            :nb-items="1"
            :dots="true"
            :overlay-controls="true"
            :dots-alt="true"
            start-displaying-controls-at="sm"
            class="ds-w-full"
          >
            <img
              class="brand-image ds-aspect-square"
              loading="lazy"
              :src="imgCdnUrl(brand.images.squared, '400')"
              :alt="brand.name"
            />
            <template
              v-for="product in brandHighlightedProducts"
              :key="product.id"
            >
              <img
                loading="lazy"
                :src="imgCdnUrlCrop(product.image, '400')"
                :alt="brand.name"
              />
            </template>
          </AkCarousel>
          <FollowBrandButton
            v-if="brand.active"
            :brand-id="brand.id"
          />
        </div>

        <BrandSnippetContent
          v-if="$isEnabled('rcb-466')"
          :name="displayBrandName"
          :logo="brand.images?.rounded"
          :featured="featured"
          :brand-tags="brand.brand_tags"
          :last-order-date="brand.metadata?.last_order_date"
          :user-is-retailer="userIsRetailer"
        >
          <template
            v-if="brand.has_new_products || (brand.discount && brand.discount > 0) || showLastOrdered"
            #badges
          >
            <BrandMetaBadges
              :brand="brand"
              :show-last-ordered="showLastOrdered"
            />
            <BrandOfferBadges :discount="brand.discount" />
          </template>
        </BrandSnippetContent>

        <figcaption
          v-else
          class="ds-relative"
          :class="{ 'is-featured': featured }"
        >
          <span
            :class="{
              'brand-name': $isEnabled('rcb-467'),
            }"
            data-testid="brand-name"
            >{{ brand.name }}</span
          >
          <span class="minimum">
            <template v-if="shouldDisplayMinimum(brand)">
              {{
                checkBrandMinimum()
                  ? $t('Minimum: {minimum}', {
                      minimum: $root.formatPrice(brand.minimum, '$0'),
                    })
                  : $t('No minimum!')
              }}</template
            >
            <span
              v-if="brand.has_new_products"
              class="newness"
            >
              {{ $t('New Items!') }}
            </span>
          </span>
          <span
            v-if="!featured && brand.shipping_lead_time && brand.shipping_lead_time.includes('less_than_48')"
            class="shipping-delay"
            >⚡️ {{ $t('Usually shipped in less than 48h') }}
          </span>
        </figcaption>
      </figure>
    </a>
  </div>
</template>

<script lang="ts">
import { imgCdnUrl, imgCdnUrlCrop } from '@bc/shared';

import ComingSoonAlertButton from '@/components/coming-soon-alert-button.vue';
import { LinkRoutingMixin } from '@/mixins/link-routing';
import { mapGetters } from 'vuex';
import { sitewideOfferMixin } from '@/mixins/sitewide-offer';
import { getSidewideOfferContext } from './get-sidewide-offer-context';
import BrandSnippetContent from '@/components/brand/brand-snippet-content.vue';
import FollowBrandButton from '@/components/follow-brand.vue';
import BrandMetaBadges from '@/components/brand/brand-meta-badges.vue';
import BrandOfferBadges from '@/components/brand/brand-offer-badges.vue';
import { truncate } from 'lodash-es';
import AkCarousel from '@/components/ak-carousel.vue';
import { defineComponent, PropType } from 'vue';
import type { ESBrandHit as BrandHit } from '@bc/discovery';
import ImageLazyload from '@/components/global/image-lazyload.vue';
import { OrderedBrand } from '@/services/reorder/reorder';
import { SponsoredBrand } from '@bc/advertisement';

export default defineComponent({
  name: 'BrandSnippet',
  components: {
    AkCarousel,
    BrandMetaBadges,
    BrandOfferBadges,
    FollowBrandButton,
    ComingSoonAlertButton,
    BrandSnippetContent,
    ImageLazyload,
  },
  mixins: [LinkRoutingMixin, sitewideOfferMixin],
  props: {
    brand: {
      type: Object as PropType<BrandHit | OrderedBrand | SponsoredBrand>,
      required: true,
    },
    featured: {
      type: Boolean,
      required: false,
      default: false,
    },
    cols: {
      type: Number,
      required: false,
      default: null,
    },
    sm: {
      type: Number,
      required: false,
      default: null,
    },
    md: {
      type: Number,
      required: false,
      default: null,
    },
    lg: {
      type: Number,
      required: false,
      default: null,
    },
    xl: {
      type: Number,
      required: false,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    showLastOrdered: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['clicked', 'liked'],
  setup() {
    return { imgCdnUrl, imgCdnUrlCrop };
  },
  data() {
    return {
      currentUrl: window.location.href.split('/shop')[1]
        ? window.location.href.split('/shop')[1].match(/^(?:\/(.*?))?(?:\/(.*?))?(?:\?(.*))?$/)
        : undefined,
    };
  },
  computed: {
    ...mapGetters(['user', 'retailer']),
    ...mapGetters(['userIsRetailer']),
    displayBrandName(): string {
      return truncate(this.brand.name, { length: 34 });
    },
    sitewideOfferContext: getSidewideOfferContext,
    offerDiscount(): string {
      return this.sitewideOfferContent['brand-tile-offer-badge']?.content;
    },
    brandLink(): string {
      return this.category ? `${this.brand.link}#${this.category}` : this.brand.link;
    },
    brandHighlightedProducts(): [] {
      return this.brand.active && this.brand.highlighted_products
        ? this.getHighlightedProducts(this.brand.highlighted_products)
        : [];
    },
  },
  methods: {
    checkBrandMinimum() {
      return this.brand.minimum.amount > 0;
    },
    shouldDisplayMinimum(brand) {
      if (
        (!this.user &&
          window.ankorstore_utm &&
          window.ankorstore_utm.utm_source &&
          window.ankorstore_utm.utm_source === 'facebook' &&
          window.ankorstore_utm.utm_content === 'franco') ||
        this.featured
      ) {
        return false;
      }

      return brand.active;
    },
    getHighlightedProducts(items) {
      if (items[this.getCategoryKey()]) {
        return items[this.getCategoryKey()];
      }
      return items.all;
    },
    getCategoryKey() {
      const result = this.currentUrl;

      if (!result) {
        return 'all';
      }

      const category = parseInt(result[0].split('-').pop(), 10);

      if (!category) {
        return 'all';
      }
      return 'category_' + category;
    },
    updateCurrentCategoryKey() {
      if (this.currentUrl == undefined) {
        return 'all';
      } else {
        return (this.currentUrl = window.location.href.split('/shop')[1].match(/^(?:\/(.*?))?(?:\/(.*?))?(?:\?(.*))?$/));
      }
    },
    handleClick(event: MouseEvent): void {
      this.$emit('clicked', { brand: this.brand });
      if (this.brand.active) {
        this.followBrandLink(event);
      }
    },
    handleLiked(): void {
      this.$emit('liked', { brand: this.brand });
    },
  },
});
</script>

<style lang="scss">
@import '@css/vue-import';
@import '@css/components/_brand_snippet';
.brand-snippet {
  // Ugly hack to round images corner radius on images in Safari
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.image-container {
  aspect-ratio: 1/1;
}
</style>
