<template>
  <div
    :class="{ 'form-label-group': !isRevamp }"
    data-testid="question-website-url"
  >
    <input
      v-if="!isRevamp"
      v-model.trim="currentModel"
      class="form-control hide-placeholder-on-focus"
      data-testid="register-website-input"
      :name="question.alias"
      :placeholder="$t('Website (optional)')"
      @change="(event) => handleChanges(event, question.id)"
    />
    <AkInput
      v-else
      v-model="currentModel"
      data-testid="register-website-input"
      :name="question.alias"
      :label="$t('Website (optional)')"
      :has-error="state[question.alias].$error"
      :required="question.required"
      :placeholder="$t('retailer.registration.question.website.placeholder')"
      @change="(event) => handleChanges(event, question.id)"
    />
    <label
      v-if="!isRevamp"
      class="ds-pointer-events-none"
      data-testid="question-item-website-label"
    >{{ question.label }}</label>
    <span
      v-if="state[question.alias] && state[question.alias].$dirty && state[question.alias].format.$invalid"
      data-testid="questionWebsiteError"
      class="error-message"
    >
      {{ $t('A valid website address is required') }}
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { Question } from '@/types/question';
import { vuelidateFieldClassName } from '@/utilities/fieldClassName';
import { State } from '@/types/state';

export default defineComponent({
  name: 'QuestionWebsite',
  props: {
    question: {
      type: Object as () => Question,
      required: true,
    },
    state: {
      type: Object as () => State,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    isRevamp: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      currentModel: '',
    };
  },
  watch: {
    model({ content }) {
      this.currentModel = content;
    },
  },
  methods: {
    handleChanges({ target }, id): void {
      this.$emit('change', this.question.alias, {
        answer_id: id,
        content: target.value,
      });
    },
    vuelidateFieldClassName,
  },
});
</script>
