export default interface Country {
  id: number;
  created_at?: string;
  updated_at?: string;
  iso_code: CountryIsoCode;
  calling_code: number;
  opened_to_retailers: boolean;
  opened_to_brands: boolean;
  name: string;
  type: 'country';
}

export type CountryLight = Omit<Country, 'created_at' | 'updated_at'>;

export interface Countries {
  [index: string]: CountryLight;
}

export type CountryIsoCode =
  | 'DA'
  | 'DE'
  | 'GB'
  | 'ES'
  | 'FI'
  | 'FR'
  | 'IT'
  | 'NL'
  | 'SV'
  | 'CH'
  | 'BE'
  | 'AT'
  | 'LU'
  | 'IE'
  | 'SE'
  | 'CY'
  | 'GR'
  | 'MT'
  | 'SI'
  | 'LV'
  | 'LT'
  | 'SK'
  | 'EE'
  | 'PT'
  | 'DK'
  | 'PL'
  | 'RO'
  | 'BG'
  | 'HR'
  | 'HU';

export enum CountryIso {
  France = 'FR',
  Germany = 'DE',
  Spain = 'ES',
  Italy = 'IT',
}
