import { toUnit } from '@/utilities/price';
import { isEnabled } from '@/services/features';
import { Cart2 } from '@/modules/cart-2/types';
import { BrandDiscount } from '@/types/api/brand-discount';
import { StockStatus } from '@/services/product-variants';
import { PromisedDeliveryDate } from '@/types/api/promised-delivery-date';
import { Cart } from '@/types/cart';
export const calculatePercentageMinimumReached = ({ totalAmount, minAmount }) => {
  const total = toUnit(totalAmount);
  const minimum = toUnit(minAmount);
  if (total >= minimum) {
    return 100;
  }
  return (total * 100) / minimum;
};

export const showInternationalShippingReimbursement = (cart, retailer): boolean => {
  return (
    isEnabled('international_shipping_messages') &&
    ((cart?.brand?.country?.iso_code === 'GB' && retailer?.country?.iso_code !== 'GB') ||
      (cart?.brand?.country?.iso_code !== 'GB' && retailer?.country?.iso_code === 'GB')) &&
    cart.minimum.above
  );
};

export const getBrandIdsFromCart = (cart: Cart2): string[] => {
  return cart?.itemAggregates?.data?.map((aggregate) => aggregate.brand?.data?.id);
};

export const getDiscountByBrandUuid = (brandId: string | number, cartBrandsDiscounts: BrandDiscount[]): BrandDiscount => {
  return cartBrandsDiscounts?.find((cartBrand) => {
    return cartBrand.brand_uuid === brandId;
  });
};

export const getDiscountByBrandId = (brandId: string | number, cartBrandsDiscounts: BrandDiscount[]): BrandDiscount => {
  return cartBrandsDiscounts?.find((cartBrand) => {
    return cartBrand.brand_id === brandId;
  });
};

export const getBrandLogoPath = (logoImagePath: string): string => {
  return `/brands/rounded/${logoImagePath}`;
};

export const getBrandsFromCart = (cart: Cart2, promisedDeliveryDates: PromisedDeliveryDate[] = []): object[] => {
  return cart.itemAggregates?.data?.map((aggregate) => {
    return {
      brand_uuid: aggregate?.brand?.data?.id,
      product_in_cart_brand_level: aggregate?.itemCount,
      amountBrandBeforeDiscount: aggregate?.totalAmountBeforeDiscount,
      amountBrandAfterDiscount: aggregate?.totalAmountAfterDiscount,
      promised_delivery_date: promisedDeliveryDates.find(item => item.attributes?.brandId === aggregate?.brand?.data?.id)?.attributes?.deliveryDate,
      products: aggregate?.items?.data?.map((purchasingListItem) => {
        const product = {
          brand_uuid: aggregate?.brand?.data?.id,
          product_uuid: purchasingListItem.productID,
          ...purchasingListItem,
        };
        delete product.productID;

        return product;
      }),
    };
  });
};

export const getAllOutOfStockProducts = (cart: Cart2): string[] =>
  cart?.itemAggregates?.data?.flatMap((aggregate) =>
    aggregate?.items?.data
      ?.filter(({ purchasableItem }) => purchasableItem.data.availability === StockStatus.OutOfStock)
      .map((item) => item?.purchasableItem?.data?.id)
  );

export const getBrandsFromCheckout = (aboveCarts: Cart[], promisedDeliveryDates: PromisedDeliveryDate[] = []): object[] => {
  return aboveCarts.map((cart) => {
    return {
      brand_id: cart?.brand?.id,
      product_in_cart_brand_level: cart?.items?.reduce((count, product) => count + product.multiplied_quantity, 0),
      amountBrandBeforeDiscount: cart?.total_amount_before_discount,
      amountBrandAfterDiscount: cart?.total_amount,
      promised_delivery_date: promisedDeliveryDates.find(item => item.attributes?.brandId === cart?.brand?.uuid)?.attributes?.deliveryDate,
      products: cart?.items?.map((purchasingListItem) => {
        return {
          brand_id: cart?.brand?.id,
          product_id: purchasingListItem?.product?.id,
          ...purchasingListItem,
        };
      }),
    };
  });
};

