export enum Mutation {
  STORE_PRODUCT = 'STORE_PRODUCT',
  STORE_PRODUCTS = 'STORE_PRODUCTS',
  REPLACE_PRODUCT_LIKES = 'REPLACE_PRODUCT_LIKES',
  REPLACE_BRAND_LIKES = 'REPLACE_BRAND_LIKES',
  ADD_PRODUCT_LIKE = 'ADD_PRODUCT_LIKE',
  ADD_BRAND_LIKE = 'ADD_BRAND_LIKE',
  REMOVE_PRODUCT_LIKE = 'REMOVE_PRODUCT_LIKE',
  REMOVE_BRAND_LIKE = 'REMOVE_BRAND_LIKE',
  REPLACE_BRAND = 'REPLACE_BRAND',
  REPLACE_RETAILER = 'REPLACE_RETAILER',
  SET_CATEGORIES = 'SET_CATEGORIES',
  UPDATE_BUSINESS = 'UPDATE_BUSINESS',
  UPDATE_VAT_INFO = 'UPDATE_VAT_INFO',
  UPDATE_VAT_NUMBER = 'UPDATE_VAT_NUMBER',
  HIDE_REQUESTED_INFORMATION = 'HIDE_REQUESTED_INFORMATION',
  SHOW_SIGNUP = 'SHOW_SIGNUP',
  UPDATE_FAST_PAYMENT = 'UPDATE_FAST_PAYMENT',
}
