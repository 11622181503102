import { partition as lodashPartition } from 'lodash-es';

type Predicate<T> = (item: T, index: number) => boolean;

const withIndex = <T>(predicate: Predicate<T>) => {
  let index = 0;
  return (item: T) => predicate(item, index++);
};

/**
 * Lodash partion, but with an index parameter for the predicate.
 * https://lodash.com/docs/4.17.15#partition
 */
export const partition = <T>(items: T[], predicate: Predicate<T>) => lodashPartition(items, withIndex(predicate));
