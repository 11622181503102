import { DynamicMetaTagName, GlobalMetaTagName, InitialStateConfig, StateMetaTagName } from "@/services/initial-state";
import { captureException } from "@sentry/browser";

export const isV2MetaActivate = () => !!globalThis.document.querySelector("#meta_v2");

export const getInjectedContent = <T>(name: GlobalMetaTagName | StateMetaTagName | DynamicMetaTagName) : null | T => {
  try {
    // Read the raw content
    // Using attribute selector here because we have dot in the id
    // For example state.user will become #injected_state.user which is interpreted as select an element with id injected_state and class user
    let content = isV2MetaActivate() ? globalThis.document.querySelector(`[id='injected_${name}']`)?.textContent : globalThis.document.querySelector(`meta[name='${name}']`)?.getAttribute("content");
    content = content?.trim();
    if (!content) {
      return null;
    }
    const isEncoded = InitialStateConfig[name];
    if (!isV2MetaActivate() && isEncoded) {
      // In v1 we have base64 encoded content
      content = globalThis.atob(content);
    }

    if (isEncoded) {
      return JSON.parse(content);
    }

    return content as T;
  } catch (err) {
    captureException(new Error(`Error while parsing meta tag ${name}`, {
      cause: err
    }))
    return null;
  }

};
