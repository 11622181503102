<template>
  <Banner1
    v-if="banner1"
    ref="banner1"
    :content="banner1"
  />
  <Banner2
    v-else-if="banner2"
    ref="banner2"
    :content="banner2"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Banner1 from './banner-1.vue';
import Banner2 from './banner-2.vue';
import { Banner1 as Banner1Type, Banner2 as Banner2Type, BannerType, CatalogueBanner1 } from './types';

export default defineComponent({
  name: 'CatalogueBanner',
  components: {
    Banner1,
    Banner2,
  },
  props: {
    content: {
      type: Object as () => CatalogueBanner1,
      default: () => null,
    },
  },
  data() {
    return {
      BannerType,
    };
  },
  computed: {
    banner1(): Banner1Type {
      if (!this.isType(BannerType.Banner1)) {
        return undefined;
      }

      return this.content.primary as Banner1Type;
    },
    banner2(): Banner2Type {
      if (!this.isType(BannerType.Banner2)) {
        return undefined;
      }

      return this.content.primary as Banner2Type;
    },
  },
  methods: {
    isType(type: BannerType): boolean {
      return this.content?.slice_type === type;
    },
  },
});
</script>
