<template>
  <ais-search-box :class-names="{ 'ais-SearchBox': 'catalog__search-box' }">
    <template #default="{ currentRefinement, refine = () => {} }">
      <AkInput
        type="search"
        icon-left="search"
        :model-value="currentRefinement"
        :placeholder="$t('Search by SKU or product name')"
        @input="customRefine(refine, $event)"
      />
    </template>
  </ais-search-box>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { debounce } from 'lodash-es';

export default defineComponent({
  name: 'CatalogSearchInput',
  setup() {
    const customRefine = debounce((refine, searchTerm) => {
      refine(searchTerm || undefined);
    }, 500);
    return { customRefine };
  },
  expose: ['customRefine'],
});
</script>

<style scoped lang="scss">
.catalog__search-box {
  max-width: 350px;
}
</style>
