import { Sort } from '@/types/sorting';
import { Client, ClientSort } from '@/types/clients';
import { Meta, Links } from '@/types/pagination';
import { CustomerOrigin, LiftEligibility } from '@/types/network/customer';
import { BrandSegment } from '@bc/brands';

interface State {
  tabClients: {
    data: Client[];
    meta: Meta;
    links: Links;
    currentPage: number;
    clientsSort: ClientSort;
    clientsOrigin: CustomerOrigin[];
    liftEligibility: LiftEligibility[];
    isLoading: boolean;
    searchTerm: string;
    segment: string;
    segments: BrandSegment[];
  };
}

const initialState: State = {
  tabClients: {
    data: [],
    meta: {
      current_page: null,
      from: null,
      last_page: null,
      path: null,
      per_page: null,
      to: null,
      total: null,
      count_by_origin: null,
    },
    links: {
      first: null,
      last: null,
      next: null,
      prev: null,
    },
    currentPage: 1,
    clientsSort: {
      col: 'shop_name',
      order: Sort.Asc,
    },
    clientsOrigin: [],
    liftEligibility: [],
    isLoading: false,
    searchTerm: null,
    segment: null,
    segments: [],
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    sort({ commit, dispatch, state }, col: string): Promise<null> {
      const order =
        state.tabClients.clientsSort.col === col
          ? state.tabClients.clientsSort.order === Sort.Desc
            ? Sort.Asc
            : Sort.Desc
          : Sort.Desc;

      commit('SET_SORTING', {
        col,
        order,
      });
      commit('account/contactManagement/SET_CONTACTS_CURRENT_PAGE', 1, { root: true });
      return dispatch('account/contactManagement/fetchContacts', null, { root: true });
    },
    setSearchTerm({ commit, dispatch }, term: string) {
      commit('SET_SEARCH_TERM', term);
      commit('account/contactManagement/SET_CONTACTS_SEARCH_TERM', term, { root: true });
      return dispatch('account/contactManagement/changePage', 1, { root: true });
    },
    clearSearchTerm({ commit, dispatch }) {
      commit('CLEAR_SEARCH_TERM');
      commit('account/contactManagement/SET_CONTACTS_SEARCH_TERM', '', { root: true });
      return dispatch('account/contactManagement/changePage', 1, { root: true });
    },
  },
  mutations: {
    SET_CLIENTS_DATAS(state, data): void {
      state.tabClients.data = data;
    },
    SET_CLIENTS_LINKS(state, data): void {
      state.tabClients.links = data;
    },
    SET_CLIENTS_PAGINATION(state, data): void {
      state.tabClients.meta = data;
    },
    SET_CLIENTS_CURRENT_PAGE(state, page): void {
      state.tabClients.currentPage = parseInt(page);
    },
    SET_CLIENT_DISCOUNT(state, { value, clientId }: { value: number; clientId: number }): void {
      state.tabClients.data = state.tabClients.data.map((client) => {
        if (client.id === clientId) {
          return {
            ...client,
            brand_discount: {
              ...client.brand_discount,
              value,
            },
          };
        } else {
          return client;
        }
      });
    },
    REMOVE_CLIENT_DISCOUNT(state, { clientId }: { clientId: number }): void {
      state.tabClients.data = state.tabClients.data.map((client) => {
        if (client.id === clientId) {
          return {
            ...client,
            brand_discount: {
              ...client.brand_discount,
              value: null,
            },
          };
        } else {
          return client;
        }
      });
    },
    DELETE_CONTACT(state, { clientUuid }: { clientUuid: string }): void {
      state.tabClients.data = state.tabClients.data.filter((client) => client.uuid !== clientUuid);
    },
    SET_SORTING(state, { col, order }: { col: string; order: Sort }): void {
      state.tabClients.clientsSort = {
        col,
        order,
      };
    },
    SET_SEGMENTS(state, segments: BrandSegment[]): void {
      state.tabClients.segments = segments;
    },
    SET_CLIENTS_ON_LOADING(state, isLoading): void {
      state.tabClients.isLoading = isLoading;
    },
    SET_SEARCH_TERM(state, term: string): void {
      state.tabClients.searchTerm = term;
    },
    CLEAR_SEARCH_TERM(state): void {
      state.tabClients.searchTerm = null;
    },
  },
  getters: {
    getClients: (state: State): Client[] => state.tabClients.data,
    getPagination: (state: State): Meta => state.tabClients.meta,
    getSegments: (state: State): BrandSegment[] => state.tabClients.segments,
    getLinks: (state: State): Links => state.tabClients.links,
    getCurrentPage: (state: State): number => state.tabClients.currentPage,
    getFormattedSorting: (state: State): string[] =>
      Object.entries(state.tabClients.clientsSort).map(([col, clientsSort]) => `${col}:${clientsSort}`),
    getSortClients: (state: State): ClientSort => state.tabClients.clientsSort,
    isLoading: (state: State): boolean => state.tabClients.isLoading,
  },
};
