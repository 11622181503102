import { Image, RichTextItem } from '@/types/prismic';

export enum BannerType {
  Banner1 = 'banner_1',
  Banner2 = 'banner_2',
}

export interface ImageWithMobile extends Image {
  mobile: Image;
}

export interface Banner1 {
  title: RichTextItem[];
  title_color: string;
  description: RichTextItem[];
  description_color: string;
  background: ImageWithMobile;
  background_color: string;
}

export interface Banner2 {
  background_image: ImageWithMobile;
  foreground_image_left: ImageWithMobile;
  foreground_image_right: ImageWithMobile;
  foreground_image_center: ImageWithMobile;
}

export interface CatalogueBanner1 {
  slice_type: BannerType;
  primary: Banner1 | Banner2;
}
