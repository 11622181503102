import AnalyticsEvent from '../analytics-event';
import { createLiftPageProperty, LiftPageProperty } from '../../properties/lift-page-property';

export default class LiftPageSignUpClickedEvent extends AnalyticsEvent {
  public readonly name = 'Lift Page Sign Up Clicked';
  public properties: {
    liftPage: LiftPageProperty;
  };

  constructor(liftPage: LiftPageProperty) {
    super();
    this.properties = {
      liftPage: createLiftPageProperty(liftPage),
    };
  }
}
