<template>
  <div
    class="product-counter"
    @click.stop.prevent
  >
    <div
      class="product-counter__minus"
      :class="{ disabled: isDecreaseDisabled }"
      @click="$emit('decrease')"
    >
      <span
        class="product-counter__minus-icon"
        :class="{ 'product-counter__minus-icon--disabled': isDecreaseDisabled }"
      >
      </span>
    </div>
    <div class="product-counter__count">
      {{ quantityMultiplied }}
    </div>
    <AkIcon
      class="product-counter__plus"
      :class="{ disabled: isIncreaseDisabled }"
      symbol="plus"
      size="md"
      @click="$emit('increase')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductCounter',
  props: {
    quantityMultiplied: {
      type: Number,
      required: true,
      default: 0,
    },
    isDecreaseDisabled: {
      type: Boolean,
      default: false,
    },
    isIncreaseDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['decrease', 'increase'],
});
</script>

<style lang="scss" scoped>
.product-counter {
  @apply ds-flex ds-border ds-border-solid ds-border-neutral-700 ds-h-6 ds-w-full sm:ds-w-[138px] ds-rounded-full ds-justify-between ds-items-center;
  &__minus {
    @apply ds-flex ds-items-center ds-justify-center ds-h-full ds-cursor-pointer ds-px-1.5 ds-min-w-[32px];
    &:hover {
      @apply ds-bg-neutral-300 ds-rounded-full;
    }
  }
  &__minus-icon {
    @apply ds-block ds-bg-primary ds-w-[14px] ds-h-[2px];

    &--disabled {
      @apply ds-bg-neutral-700;
    }
  }
  &__plus {
    @apply ds-flex ds-items-center ds-justify-center ds-h-full ds-cursor-pointer ds-px-1.5 ds-min-w-[32px];
    &:hover {
      @apply ds-bg-neutral-300 ds-rounded-full;
    }
  }
  &__count {
    @apply ds-text-base ds-font-basic ds-overflow-hidden;
  }
}

.disabled {
  @apply ds-text-neutral-700 ds-pointer-events-none ds-text-neutral-700;
}
</style>
