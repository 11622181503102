export const syncLanguageSwitcherUrls = (urlPath: string, isUserAuthenticated: boolean): void => {
  const languageLinks: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('#language-switch-dropdown a');
  let url = null;
  let backUrl = null;
  const backUrlParamName = 'back-url';
  languageLinks.forEach((languageLinkTag) => {
    url = new URL(languageLinkTag.href);
    if (isUserAuthenticated && url.searchParams.has(backUrlParamName)) {
      backUrl = new URL(url.searchParams.get(backUrlParamName));
      url.searchParams.set(backUrlParamName, `${backUrl.origin}${urlPath}`);
      languageLinkTag.href = url.toString();
    } else {
      languageLinkTag.href = `${url.origin}${urlPath}`;
    }
  });
};
