<template>
  <div class="phone-input">
    <vue-tel-input
      :key="defaultCountry"
      v-model="value.phonenumber"
      :input-options="inputOptions"
      :auto-format="true"
      :default-country="defaultCountry"
      :disabled-fetching-country="!!defaultCountry"
      :preferred-countries="preferredCountries"
      :class="error ? 'error' : ''"
      @on-input="inputChanged"
      @validate="validateChanged"
      @country-changed="countryCheck"
    />
    <template v-if="withHiddenInputs">
      <input
        v-model="value.phonenumber"
        type="text"
        class="hidden-input"
        tabindex="-1"
        :name="name"
        :required="required"
        :pattern="error ? '' : '.*'"
      >
      <input
        v-model="value.country"
        type="text"
        class="hidden-input"
        tabindex="-1"
        :name="name + '_country_code'"
        :required="required"
        :pattern="error ? '' : '.*'"
      >
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VueTelInput } from '@ankorstore/vue-tel-input';

interface Country {
  name: string;
  iso2: string;
  dialCode: string;
  priority: number;
  areaCodes: null;
}

interface TelInputData {
  country: Country;
  formatted: string;
  countryCode: string;
  valid: boolean;
  number: string;
}

export default defineComponent({
  name: 'PhoneInput',
  compatConfig: {
    COMPONENT_V_MODEL: false,
  },
  components: {
    VueTelInput,
  },
  props: {
    initial: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    preferredCountries: {
      type: Array,
      default: () => [],
    },
    defaultCountry: {
      type: String,
      required: true,
    },
    withHiddenInputs: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change', 'validation'],
  data: () => {
    return {
      isValid: false,
      error: false,
      value: {
        phonenumber: '',
        country: null,
      },
      phone: {},
    };
  },
  computed: {
    inputOptions() {
      return {
        name: this.name + '_formatted',
        placeholder: this.$t('Phone number'),
      };
    },
  },
  methods: {
    validateChanged(phoneObj: TelInputData) {
      this.inputChanged(null, phoneObj);
    },
    inputChanged(_formattedNumber: null | string, phoneObj: TelInputData) {
      if (!phoneObj) {
        return;
      }

      this.$emit('validation', phoneObj.valid);
      this.value.country = phoneObj.country ?? this.defaultCountry;

      if (!phoneObj.formatted) {
        this.value.phonenumber = '';
        this.error = false;
        return;
      }

      this.error = !phoneObj.valid;

      this.$emit('change', {
        value: phoneObj.number,
        countryCode: this.value.country,
      });
    },
    countryCheck(countryObj: Country) {
      this.value.country = countryObj.iso2;
      this.$emit('change', {
        value: this.value.phonenumber,
        countryCode: this.value.country,
      });
    },
  },
});
</script>
