<script lang="ts">
import { mapGetters, mapActions } from 'vuex';
import { CaseReasonStep } from '@/types/order-retailer-rejection';
import BaseCase from '@/components/account/orders/order/order-retailer-reject/cases/base-case.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseItemsCase',
  extends: BaseCase,
  data() {
    return {
      productsDataReady: false,
      uploadPackagingRef: 'UploadPackaging',
      productsListRef: 'ProductsList',
    };
  },
  computed: {
    ...mapGetters('orderRetailerRejection', ['getCaseReason', 'getCurrentStep', 'getSelectedProducts']),
  },
  methods: {
    ...mapActions('orderRetailerRejection', ['setStep']),
    showComponent(component: string): boolean {
      let showComponent = false;
      const currentKey = this.getCurrentStep.key;

      switch (component) {
        case 'TrackParcels':
          if (currentKey === CaseReasonStep.TrackWithLink) {
            showComponent = true;
          }
          break;
        case 'LabelInfo':
          if (currentKey === CaseReasonStep.OrderInfo) {
            showComponent = true;
          }
          break;
        case 'UploadPackaging':
          if (currentKey === CaseReasonStep.OrderInfo || currentKey === CaseReasonStep.Summary) {
            showComponent = true;
          }
          break;
        case 'ProductsCheckList':
          if (currentKey === CaseReasonStep.ProductsCheckList) {
            showComponent = true;
          }
          break;
        case 'ProductsList':
          if (currentKey === CaseReasonStep.ProductsList || currentKey === CaseReasonStep.Summary) {
            showComponent = true;
          }
          break;
      }

      return showComponent;
    },
    nextStepReady(): boolean {
      let stepReady = false;

      switch (this.getCurrentStep.key) {
        case CaseReasonStep.TrackWithLink:
          stepReady = true;
          break;
        case CaseReasonStep.OrderInfo:
          stepReady = !this.hasFormErrors;
          break;
        case CaseReasonStep.ProductsCheckList:
          stepReady = this.getSelectedProducts.length > 0;
          break;
        case CaseReasonStep.ProductsList:
          stepReady = this.productsDataReady;
          break;
        case CaseReasonStep.Summary:
          stepReady = this.productsDataReady && !this.hasFormErrors && !this.loading;
          break;
      }

      return stepReady;
    },
    nextButtonClick(): void {
      if (this.getCurrentStep.key === CaseReasonStep.ProductsCheckList) {
        const uploadPackaging = this.$refs[this.uploadPackagingRef];
        // @ts-ignore
        uploadPackaging?.setFileUploaderErrors();
      } else if (this.getCurrentStep.key === CaseReasonStep.Summary) {
        const productsList = this.$refs[this.productsListRef];
        // @ts-ignore
        productsList?.clearProductsFileErrors();
      }
    },
    productsDataChanged(value: boolean): void {
      this.productsDataReady = value;
    },
    backToCheckList(): void {
      this.setStep({ step: CaseReasonStep.ProductsCheckList });
    },
  },
});
</script>
