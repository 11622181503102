<template>
  <div
    :class="[
      'search-autocomplete__suggestions',
      'ds-z-20',
      'ds-flex',
      'ds-flex-col',
      'ds-relative',
      'ds-bg-white',
      'ds-w-full',
      'ds-overflow-y-auto',
      'md:ds-shadow-md',
      'md:ds-rounded-md',
      'xl:ds-absolute',
    ]"
  >
    <CategoryTilesContainer v-if="shouldShowCategoryTiles">
      <template #default="{ categoriesTileData }">
        <CategoryTiles
          class="ds-p-4"
          :categories-tile-data="categoriesTileData"
          :columns="isMobile ? 2 : 4"
          @tile-clicked="handleCategoryTileClicked"
        />
      </template>
    </CategoryTilesContainer>
    <template v-else>
      <SearchAutocompleteSection
        v-for="(_value, key) in resultsDynamic"
        :key="key"
        :label="sectionAttributes[key].label"
        :items="results[key]"
        :query="searchInput"
        :current-selection-id="currentSelectionId"
        :data-testid="sectionAttributes[key].testId"
        :should-highlight-results="shouldHighlightResults"
      />
    </template>
    <SearchStaticItem
      v-for="(_, name) in resultsStatic"
      :key="name"
      :class="{
        'search-autocomplete__suggestions-r2bLink': isR2BLink(results[name]),
      }"
      :is-visible="!!results[name]"
      :current-selection-id="currentSelectionId"
      :item="results[name]"
      :query="searchInput"
      @click="handleStaticItemClick"
    />
  </div>
</template>

<script lang="ts">
import SearchAutocompleteSection from './search-autocomplete-section.vue';
import SearchAutocompleteStaticItem from './search-autocomplete-static-item.vue';
import { defineComponent, computed } from 'vue';
import { DEFAULT_ARROW_POSITION, R2B_LINK_ID, SHOW_ALL_ID } from '../store';
import CategoryTilesContainer from './category-tiles-container.vue';
import { CategoryTiles } from '@bc/discovery/ui/category-tiles';
import { useBreakpoints } from '@/modules/design-system-candidates';

import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';
import { useI18n } from 'vue-i18n';
import SearchBarStoreHelpers from '../store/helpers';

export default defineComponent({
  name: 'SearchAutocompleteSuggestions',
  components: {
    SearchAutocompleteSection,
    SearchStaticItem: SearchAutocompleteStaticItem,
    CategoryTiles,
    CategoryTilesContainer,
  },
  props: {
    searchInput: { type: String, default: '' },
    currentSelectionId: {
      type: [Number, String],
      default: DEFAULT_ARROW_POSITION,
    },
  },
  emits: ['click-see-all-results', 'click-r2b-link'],
  setup(_props, { emit }) {
    const { t } = useI18n();
    const SECTION_ATTRIBUTES = {
      trending: {
        label: undefined,
        testId: 'SearchBoxTrendingSearches',
      },
      brands: {
        label: t('Brands'),
        testId: 'SearchBoxSuggestionBrands',
      },
      history: {
        label: undefined,
        testId: 'SearchBoxHistory',
      },
      categories: {
        label: undefined,
        testId: 'SearchBoxCategories',
      },
      suggestions: {
        label: '',
        testId: '',
      },
      boutiques: {
        label: t('Events'),
        testId: 'SearchBoxBoutiques',
      },
    };

    const { isMobile } = useBreakpoints();
    const { results, resultsDynamic, resultsStatic, originalUserInput } = SearchBarStoreHelpers.useGetters([
      'results',
      'resultsDynamic',
      'resultsStatic',
      'originalUserInput',
    ]);
    const shouldShowCategoryTiles = computed(
      () =>
        !(
          resultsDynamic.value.history?.length ||
          resultsDynamic.value.suggestions?.length ||
          resultsDynamic.value.categories?.length ||
          resultsDynamic.value.brands?.length ||
          resultsDynamic.value.boutiques?.length
        )
    );
    const shouldHighlightResults = computed(() => Boolean(originalUserInput.value));
    const isR2BLink = (item) => item?.id === R2B_LINK_ID;
    const handleStaticItemClick = (item): void => {
      if (item?.id === SHOW_ALL_ID) {
        emit('click-see-all-results');
      } else if (isR2BLink(item)) {
        emit('click-r2b-link');
      }
    };
    const handleCategoryTileClicked = async (categoryId: number) => {
      await Analytics.track(
        new UserClick({
          component: 'search bar',
          action: 'click_lvl1_category_suggestion',
          id_category: categoryId || null,
        })
      );
    };
    return {
      isMobile,
      sectionAttributes: SECTION_ATTRIBUTES,
      shouldHighlightResults,
      results,
      resultsDynamic,
      resultsStatic,
      shouldShowCategoryTiles,
      isR2BLink,
      handleStaticItemClick,
      handleCategoryTileClicked,
      originalUserInput,
    };
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.search-autocomplete__suggestions {
  min-height: 1rem;

  &-r2bLink {
    &:before {
      @apply ds-bg-neutral-300 ds-mx-4 ds-absolute;
      content: '';
      height: 1px;
      top: 0;
      left: -16px;
      right: -16px;
    }
  }
}
</style>
