import countries from './countries';
import measurementUnits from './measurement-units';

const initialState = {};

export default {
  namespaced: true,
  state: initialState,
  modules: {
    countries,
    measurementUnits,
  },
  actions: {},
  mutations: {},
  getters: {},
};
