import AnalyticsEvent from '@/services/analytics/events/analytics-event';
import {
  ConcreteCartsPageEventProperties,
  CartsPageEventProperties,
  CartsPageEventAction,
  CartsPageEventName,
  CartsPageComponents,
} from '@/services/analytics/events/reorder/carts-page/types';

export class ShowOrderSummaryDetailsClick extends AnalyticsEvent {
  public name: CartsPageEventName = CartsPageEventName.User_Click;
  public properties: CartsPageEventProperties;

  constructor(properties: ConcreteCartsPageEventProperties) {
    super();

    this.properties = {
      ...properties,
      component: CartsPageComponents.OrderSummary,
      action: CartsPageEventAction.Click_Display_Order_Summary_Details,
    };
  }
}
