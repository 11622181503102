import AnalyticsEvent from './analytics-event';

export class UserClick extends AnalyticsEvent {
  public readonly name: string;

  constructor(
    public readonly properties: {
      component: string;
      action: string;
      value?: number[];
      /** If true, the component property will be added to the event name. */
      shouldNameIncludeComponent?: boolean;
      [key: string]: unknown;
    }
  ) {
    super();

    this.name = this.properties.shouldNameIncludeComponent ? `UserClick_${this.properties.component}` : 'User Click';
  }
}

export default UserClick;
