<template>
  <ShownObserver :callback="sectionViewed">
    <div
      v-if="reviews.length"
      class="ds-bg-warm-white ds-flex ds-flex-col ds-items-center ds-pt-8 ds-pb-6 ds-px-2"
    >
      <AkHeading3 class="ds-mb-6">
        {{ $t('More than 300,000 retailers trust ankorstore') }}
      </AkHeading3>
      <div class="ds-w-full ds-px-4 ds-max-w-7xl">
        <AkCarousel
          :arrows="false"
          :dots="true"
          :nb-items="itemCount"
          dots-color="neutral-600"
          @changed="sendAnalytics"
        >
          <div
            v-for="item in reviews"
            :key="item.name"
            data-testid="review-item"
          >
            <RetailerReviewItem :item="item.attributes" />
          </div>
        </AkCarousel>
      </div>
    </div>
  </ShownObserver>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AkCarousel from '@/components/ak-carousel.vue';
import RetailerReviewItem from './retailer-review-item.vue';
import { getRetailerReviews } from '@/services/api/retailer-reviews';
import { RetailerReview } from '@/types/retailer-review';
import { mapGetters } from 'vuex';
import Analytics from '@/services/analytics';
import RetailerReviewsSection from '@/services/analytics/events/home/retailer-reviews';
import ShownObserver from '@/components/global/shown-observer.vue';

export default defineComponent({
  name: 'RetailerReviews',
  components: {
    AkCarousel,
    RetailerReviewItem,
    ShownObserver,
  },
  data() {
    return {
      reviews: [] as RetailerReview[],
      itemCount: 0,
    };
  },
  computed: {
    ...mapGetters(['userCountry']),
  },
  mounted() {
    window.addEventListener('resize', this.updateItemCount);
  },
  unmounted() {
    window.removeEventListener('resize', this.updateItemCount);
  },
  async created() {
    this.updateItemCount();
    this.reviews = await getRetailerReviews(this.userCountry);
    if (!this.reviews.length) {
      this.reviews = await getRetailerReviews();
    }
  },
  methods: {
    updateItemCount() {
      this.itemCount = window.innerWidth <= 1000 ? null : 2;
    },
    sectionViewed() {
      Analytics.track(new RetailerReviewsSection('Viewed'));
    },
    sendAnalytics() {
      Analytics.track(new RetailerReviewsSection('Clicked'));
    },
  },
});
</script>
