<template>
  <transition name="fade">
    <div
      v-show="showPopin"
      class="gdpr-container"
    >
      <p>
        {{ $t('This site uses cookies. By continuing to browse the site, you are agreeing to our use of cookies.') }}
        <a
          :href="getGlobalConfig().language_config.cookies_policy_link"
          target="_blank"
          class="ak-link"
          rel="noopener"
        >{{ $t('Find out more') }}</a>
      </p>
      <button
        @click="acceptGDPR()"
        @enter="acceptGDPR()"
      >
        <AkIcon
          symbol="x"
          size="md"
        />
      </button>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getGlobalConfig } from '@/services/global-config';

export default defineComponent({
  name: 'GDPRToast',
  data() {
    return {
      showPopin: false,
    };
  },
  mounted() {
    this.showPopin = !window.localStorage.getItem('gdpr');
  },
  methods: {
    acceptGDPR() {
      window.localStorage.setItem('gdpr', 'true');
      this.showPopin = !window.localStorage.getItem('gdpr');
    },
    getGlobalConfig,
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.gdpr-container {
  @apply ds-fixed ds-text-white ds-gap-2 ds-flex ds-items-start ds-justify-between ds-p-3;
  bottom: 13px;
  font-size: 14px;
  max-width: 1000px;
  left: 50%;
  transform: translate(-50%, 0);
  width: calc(100% - 32px);
  background: rgba(black, 0.8);
  border-radius: 4px;
  z-index: 1001;

  &.hide {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    width: 80%;
  }

  .ak-link {
    @apply ds-text-white;
  }

  p {
    margin-bottom: 0;
  }

  button {
    background: transparent;
    border: none;
    padding: 0;
    position: relative;
    line-height: 0;

    &:after {
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px;
      cursor: pointer;
    }
  }

  .icon-cross {
    @apply ds-text-white;
    font-size: 20px;
    position: relative;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
