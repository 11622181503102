<template>
  <Badge v-if="getProductLikeCount.count > 0">
    {{ getProductLikeCount.count }}
  </Badge>
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import Badge from '../badge.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductLikesBadge',
  components: { Badge },
  computed: {
    ...mapGetters('wishlist', ['getProductLikeCount']),
    count(): string {
      const count = this.getProductLikeCount.count;
      return count <= 99 ? count : '99+';
    },
  },
  created() {
    this.fetchRetailerProductLikeCount();
  },
  methods: {
    ...mapActions('wishlist', ['fetchRetailerProductLikeCount']),
  },
});
</script>
