import { deserialise as kitsuDeserialize } from 'kitsu-core';

export interface JsonApiParamsObject<RelationKeys extends string> {
  include?: RelationKeys[];
  fields?: {
    [K in RelationKeys]?: string[];
  };
}

/**
 * Deserializes a JsonApi response. The purpose of this wrapper is
 * to decouple JsonApi deserialization from a specific library
 * @param data - JsonApi response to deserialize
 * @returns - Deserialized data.
 */
export function deserialize(data) {
  return kitsuDeserialize(data);
}

/**
 * Will convert a type-safe object representing the required parameters
 * of a JSON API query into a format which can be passed to the server. Note:
 * will only support the `include` and `fields` params at the moment.
 * */
export const serialiseParams = <RelationKeys extends string>(
  objParams?: JsonApiParamsObject<RelationKeys>
): { [k in `fields[${RelationKeys}]`]?: string } & { include: string } => {
  if (!objParams) {
    return {} as any;
  }

  const params = {};

  if (objParams) {
    if (Array.isArray(objParams.include)) {
      params['include'] = objParams.include.join(',');
    }

    if (objParams.fields) {
      Object.keys(objParams.fields).forEach((entityName) => {
        params[`fields[${entityName}]`] = objParams.fields[entityName].join(',');
      });
    }
  }

  return params as any;
};
