export default class GoogleMapsAutocomplete {
  placesAutocomplete;
  country;
  placeService;
  sessionToken;

  constructor() {
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    this.placesAutocomplete = new google.maps.places.AutocompleteService();
    this.placeService = new google.maps.places.PlacesService(document.createElement('div'));
  }

  private internalCallback = (resolve, reject) => (response, status) => {
    if (status !== google.maps.places.PlacesServiceStatus.OK) {
      return reject(status);
    } else {
      return resolve(response);
    }
  };

  getResults(input: string) {
    return new Promise((resolve, reject) => {
      this.placesAutocomplete.getPlacePredictions(
        {
          input,
          componentRestrictions: { country: this.country },
          types: ['address'],
          sessionToken: this.sessionToken,
        },
        this.internalCallback(resolve, reject)
      );
    });
  }
  getPlace(placeId: string) {
    return new Promise((resolve, reject) => {
      this.placeService.getDetails(
        {
          placeId,
          fields: ['formatted_address', 'address_components', 'geometry'],
          sessionToken: this.sessionToken,
        },
        this.internalCallback(resolve, reject)
      );
    });
  }
  setCountryRestriction(countryRestriction: string | string[]) {
    if (typeof countryRestriction !== 'string' && countryRestriction.length > 5) {
      throw new Error("You can't assign more than 5 countries into google maps filter");
    }
    this.country = countryRestriction;
  }
}
