import type { RouterOptions } from 'vue-router';
import { boutiqueScrollBehaviour, categoryPositionBehaviour } from '@bc/discovery';
import { scrollContainerBehavior } from '@bc/shared';

type ScrollBehavior = RouterOptions['scrollBehavior'];

export const scrollBehavior: ScrollBehavior = (to, from, savedPosition) => {
  const scrollContainerPosition = scrollContainerBehavior(to, from, savedPosition);
  if (scrollContainerPosition) {
    return scrollContainerPosition;
  }

  const boutiqueScroll = boutiqueScrollBehaviour(to, from, savedPosition);
  if (boutiqueScroll) {
    return boutiqueScroll;
  }

  if (['category', 'new', 'catalogEvent'].includes(to.name as unknown as string)) {
    return categoryPositionBehaviour(to, from, savedPosition);
  }

  const y = window.scrollY;
  if (to.name === from.name) {
    return { left: 0, top: y };
  }
  if (!savedPosition) {
    return { top: 0, left: 0 };
  }

  return new Promise((resolve) => {
    setTimeout(() => resolve(savedPosition), 100);
  });
};
