import { Countries, CountryLight } from '@/types/country';
import http from '@/services/api/http';

export interface CountriesState {
  countries: Countries;
  countriesOrder: string[];
  topCountriesOrder: string[];
}

const initialState: CountriesState = {
  countries: {} as Countries,
  countriesOrder: [],
  topCountriesOrder: [],
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async fetchCountries({ commit }) {
      const {
        data: { countries, top_countries },
      }: { data: { countries: CountryLight[]; top_countries: CountryLight[] } } = await http().get('/api/countries');
      commit('SET_COUNTRIES', {
        countries: Object.fromEntries(countries.map((country) => [country.iso_code, country])),
        order: countries.map(({ iso_code }) => iso_code),
      });
      commit('SET_TOP_COUNTRIES', top_countries);
    },
  },
  mutations: {
    SET_TOP_COUNTRIES(state: CountriesState, top_countries: string[]): void {
      state.topCountriesOrder = [...top_countries];
    },
    SET_COUNTRIES(state: CountriesState, { countries, order }: { countries: Countries; order: string[] }): void {
      state.countries = { ...countries };
      state.countriesOrder = [...order];
    },
  },
  getters: {
    getOrderedCountries: (state: CountriesState): CountryLight[] =>
      state.countriesOrder.map((isoCode) => state.countries[isoCode]),
    getOpenedRetailerCountries: (state: CountriesState): Countries =>
      Object.fromEntries(Object.entries(state.countries).filter(([, country]) => country.opened_to_retailers)),
    getOpenedCountriesBrand: (state: CountriesState): Countries =>
      Object.fromEntries(Object.entries(state.countries).filter(([, country]) => country.opened_to_brands)),
    getOrderedOpenedRetailerCountries: (state: CountriesState, getters): CountryLight[] =>
      state.countriesOrder
        .filter((isoCode) => Object.keys(getters.getOpenedRetailerCountries).includes(isoCode))
        .map((isoCode) => getters.getOpenedRetailerCountries[isoCode]),
    getOrderedOpenedBrandCountries: (state: CountriesState, getters): CountryLight[] =>
      state.countriesOrder
        .filter((isoCode) => Object.keys(getters.getOpenedCountriesBrand).includes(isoCode))
        .map((isoCode) => getters.getOpenedCountriesBrand[isoCode]),

    getTopCountries: (state: CountriesState): Countries =>
      Object.fromEntries(state.topCountriesOrder.map((isoCode) => [isoCode, state.countries[isoCode]])),
    getOrderedTopCountries: (state: CountriesState): CountryLight[] =>
      state.topCountriesOrder.map((isoCode) => state.countries[isoCode]),

    getNoTopCountries: (state: CountriesState): Countries =>
      Object.fromEntries(Object.entries(state.countries).filter(([isoCode]) => !state.topCountriesOrder.includes(isoCode))),
    getOrderedNoTopCountries: (state: CountriesState): CountryLight[] =>
      state.countriesOrder
        .filter((isoCode) => !state.topCountriesOrder.includes(isoCode))
        .map((isoCode) => state.countries[isoCode]),

    getCountriesWithoutRetailersOpened: (state: CountriesState): Countries =>
      Object.fromEntries(Object.entries(state.countries).filter(([, country]) => !country.opened_to_retailers)),
    getAlphaOrderedCountries: (_state, getters): CountryLight[] =>
      getters.getOrderedCountries.sort((a, b) => (a.name > b.name ? 1 : -1)),
  },
};
