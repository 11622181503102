import AnalyticsEvent from './analytics-event';
import { ConversationProperty, createConversationProperty } from '../properties/conversation-property';
import { Conversation } from '@/types/conversation';

export default class MessageSentEvent extends AnalyticsEvent {
  public readonly name = 'Message Sent';
  public properties: {
    conversation: ConversationProperty;
  };

  constructor(message: Conversation) {
    super();
    this.properties = {
      conversation: createConversationProperty(message),
    };
  }
}
