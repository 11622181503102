<template>
  <div class="popin">
    <div class="popin-header">
      <h2>{{ $t('Email preview') }}</h2>
    </div>
    <div class="popin-container">
      <div class="email">
        <div class="email-from">
          <span> {{ campaign.from }}</span>
        </div>
        <div
          v-if="campaign.object"
          class="email-object"
        >
          <span>{{ campaign.object }}</span>
        </div>
      </div>
      <div
        class="campaign-content"
        :class="{ 'campaign-content--center': centered }"
      >
        <CreateCampaignsEmailHeader :images="brand.images" />
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="campaign-body"
          v-html="campaign.body"
        ></div>
        <!-- eslint-enable vue/no-v-html -->
        <div class="ds-flex ds-justify-center">
          <a
            :href="brand.link"
            target="_blank"
          >
            <AkButton>
              {{ campaign.cta_text }}
            </AkButton>
          </a>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="campaign-footer"
          v-html="campaign.footer"
        ></div>
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { AkButton } from '@ankorstore/design-system';
import CreateCampaignsEmailHeader from '@/components/account/campaigns/create-campaigns-email-header.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PreviewCampaign',
  components: { AkButton, CreateCampaignsEmailHeader },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    centered: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['user', 'brand']),
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';

.email {
  margin-bottom: 30px;
  &-from,
  &-object {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 20px;
    @apply ds-border-b ds-border-solid ds-border-neutral-300;
  }
  &-from {
    @apply ds-text-neutral-700;
    margin-bottom: 20px;
  }
  &-black {
    @apply ds-text-primary;
  }
}

.popin {
  padding-top: 20px;
  @include media-breakpoint-up(lg) {
    padding-top: 0;
  }
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    h2 {
      @apply ds-font-basic;
      font-size: 24px;
      line-height: 24px;
      @include media-breakpoint-up(lg) {
        font-size: 35px;
        line-height: 35px;
      }
      margin-bottom: 0;
    }
    i {
      @apply ds-text-white;
      font-size: 24px;
      @include media-breakpoint-up(lg) {
        font-size: 30px;
      }
    }
  }
  &-container {
    padding: 30px;
    @apply ds-bg-white;
  }
}

.campaign {
  &-content--center {
    text-align: center;
  }
  &-body,
  &-img {
    margin-bottom: 30px;
  }
  &-img,
  &-button {
    text-align: center;
  }
  &-footer {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}
</style>
<style lang="scss">
.modal-preview-campaign {
  .modal-body,
  .modal-content {
    background: none;
  }
}
.campaign {
  &-body {
    ul {
      padding-left: 18px;
      list-style-type: disc;
    }
  }
}
</style>
