<template>
  <OrderSummaryBlock
    :title="title || $t('Sub-total excl. tax')"
    @open="open"
  >
    <template #amount>
      <ItemTotalPrice
        :before-discount="rawTotalBeforeDiscount"
        :after-discount="rawTotal"
        text-size="sm"
      />
    </template>
    <template #content>
      <CartSummaryItems :carts="carts" />
    </template>
  </OrderSummaryBlock>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import OrderSummaryBlock from '@/modules/carts/order-summary/order-summary-block.vue';
import { Cart } from '@/types/cart';
import ItemTotalPrice from '@/modules/cart-2/item-total-price.vue';
import Analytics from '@/services/analytics';
import { ShowOrderSummaryDetailsClick } from '@/services/analytics/events/reorder/carts-page/show-order-summary-details-click.event';
import { useStore } from '@/composables/use-store';
import usePrices from '@/composables/use-price';
import { BrandDiscount } from '@/types/api/brand-discount';
import CartSummaryItems from '@/modules/carts/cart-summary/cart-summary-items.vue';

interface OrderTotalProps {
  carts: Cart[];
}

export default defineComponent({
  name: 'OrderTotal',
  components: {
    CartSummaryItems,
    OrderSummaryBlock,
    ItemTotalPrice,
  },
  props: {
    carts: {
      type: Array as PropType<Cart[]>,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
  },

  setup(props: OrderTotalProps) {
    const store = useStore();
    const { formatPrice } = usePrices();

    const cartStore = computed(() => store.state.cart);
    const retailerStore = computed(() => store.state.retailer);

    const brandsDiscounts = computed<{ [key: number]: BrandDiscount }>(() => store.getters['cart/getPersonalDiscounts']);
    const rawTotal = computed(() => {
      return props.carts.reduce(
        (acc, cart) => {
          return {
            amount: acc.amount + cart.total_amount.amount,
            currency: cart.total_amount.currency,
          };
        },
        { amount: 0, currency: store.state.currency }
      );
    });
    const rawTotalBeforeDiscount = computed(() => {
      return props.carts.reduce(
        (acc, cart) => {
          return {
            amount: acc.amount + (cart.total_amount_before_discount?.amount ?? cart.total_amount.amount),
            currency: cart.total_amount.currency,
          };
        },
        { amount: 0, currency: store.state.currency }
      );
    });

    const open = () => {
      Analytics.track(
        new ShowOrderSummaryDetailsClick({
          id_checkout: cartStore.value.checkout?.id,
          id_retailer: retailerStore.value?.id,
        })
      );
    };

    return { rawTotal, brandsDiscounts, open, formatPrice, rawTotalBeforeDiscount };
  },
});
</script>
