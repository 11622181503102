export enum RetailerFormType {
  Login = 'login',
  Register = 'register',
  EmailOnlySignUp = 'email-only-signup',
  ResetPassword = 'reset-password',
  TwoFactor = 'two-factor',
  TwoFactorRecovery = 'two-factor-recovery',
  ResetPasswordInput = 'reset-password-input',
  RetailerQualification = 'retailer-qualification',
  RetailerQualificationCategories = 'retailer-qualification-categories',
  SecondStep = 'second-step-form',
  Categories = 'categories',
  ReferredConfirmed = 'referred-confirmed',
  Vat = 'vat',
  EarlyVerification = 'early-verification',
}
