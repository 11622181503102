import { Meta } from './pagination';
import Product from './product';
import { Sort } from './sorting';

export type WhishListProducts = Array<Product>;

export default interface List {
  default: boolean;
  id: number;
  name: string;
  position: number;
  products?: WhishListProducts;
  meta?: Meta;
  type: string;
  editable?: boolean;
  productsChecked?: Array<number>;
  collapsed: boolean;
  sort: {
    column: 'addedAt' | 'brand';
    direction: Sort;
  };
  status?: Status;
  products_count?: number;
}

export interface Lists {
  [listId: string]: List;
}

export interface RetailerProductLikeCount {
  readonly count: number;
  readonly should_display_tooltip: boolean;
  readonly last_seen_at: string;
}

export const enum Status {
  pending = 'pending',
  success = 'success',
  failed = 'failed',
}
