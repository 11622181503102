interface RecoId {
  id: number;
  position: number;
}

export enum snippetType {
  product = 'product',
  brand = 'brand',
}

export enum EventName {
  ViewTile = 'view_tile',
}

export interface TrackClickItem {
  brandId?: number;
  productId?: number;
  position: number;
  itemtype: snippetType;
  action?: string;
}

export interface BaseRecommendationTracking {
  id_section: string;
  id_algo: RecommendationAlgoType;
  itemtype: snippetType;
  value_proposition: string;
}

export interface SectionCreatedTracking extends BaseRecommendationTracking {
  section_type: ImpressionElement;
  value_proposition_id?: '';
  id_brand: RecoId[];
  id_product: RecoId[];
  position_component: number;
}

export interface ViewTileTracking extends BaseRecommendationTracking {
  section_type: ImpressionElement;
  userId: number;
  event: EventName;
  page_path: string;
  id_brand?: RecoId;
  id_product?: RecoId;
  position: number;
}

export interface ClickTracking extends TrackClickItem {
  id_section: string;
  component: string;
  action: string;
}

export enum ImpressionElement {
  recoWidget = 'reco_carousel',
  sponsoredCarousel = 'sponsored_carousel',
  sponsoredCarouselOff = 'sponsored_carousel_off',
}

export enum RecommendationAlgoType {
  brandYouMayLike = 1,
  productsYouMayLike = 2,
  brandsCloseToYourArea = 3,
  ProductsSameBrands = 8,
  productsCrossbrands = 9,
  productsCategories = 10,
  newBrandsCategory = 13,
}

export interface AdditionalLikeTrackingData {
  id_section?: string;
  component?: string;
  itemtype?: string;
  id_product?: string;
  position?: number;
  id_brand?: string;
}
