import AnalyticsEvent from '@/services/analytics/events/analytics-event';
import { ClickTracking } from '@/types/analytics/recommendation-tracking';

export class ClickEvent extends AnalyticsEvent {
  public readonly name = 'User Click';
  public properties: ClickTracking;

  constructor(properties: ClickTracking) {
    super();

    this.properties = { ...properties };
  }
}
