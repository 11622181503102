export enum ShipmentMethod {
  SHIP_VIA_ANKORSTORE_NEW = 'ankorstore',
  SHIP_VIA_ANKORSTORE = 'label',
  SHIP_BY_MYSELF = 'custom',
  FULFILL_WITH_ANKORSTORE = 'fulfill',
}

/**
 * As the payload can now contain references to the old and new enums for ankorstore shipping, use this
 * comparison function to make assertions.
 * */
export const isShippingViaAnkorstore = (method: ShipmentMethod | 'default') =>
  method !== 'default' ? [ShipmentMethod.SHIP_VIA_ANKORSTORE_NEW, ShipmentMethod.SHIP_VIA_ANKORSTORE].includes(method) : false;

export const isShippingByMyself = (method: ShipmentMethod | 'default') => ShipmentMethod.SHIP_BY_MYSELF === method;
