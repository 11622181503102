import { SearchCategory } from '@/types/api/category';
import type { URLManager } from '../services/url-manager/types';

export type ConfigDefinition = {
  template: SnippetTemplate;
  eventTile?: boolean;
  name: Context;
  layout: layoutRef[];
  filters: facetRef[];
  quickFilters: facetRef[];
  urlManager: URLManager;
  maxPages?: number;
};

export interface SearchRouteState {
  category?: SearchCategory;
  subcategory?: SearchCategory;
  q?: string;
  page?: number;
  hierarchicalMenu?: Record<string, string[]>;
  refinementList?: Record<string, string[]>;
  range?: Record<string, string>;
  toggle?: Record<string, string>;
  sortBy?: string;
}

export enum FacetsType {
  hierarchical = 'hierarchical',
  refinement = 'refinement',
  priceRange = 'priceRange',
  hierarchicalMultiSelect = 'hierarchicalMultiSelect',
  numeric = 'numeric',
  boolean = 'boolean',
  color = 'color',
}

export enum SnippetTemplate {
  PRODUCTS = 'products',
  BRANDS = 'brands',
}

export enum layoutRef {
  withTabs = 'withTabs',
  withBreadCrumb = 'withBreadCrumb',
  withTitle = 'withTitle',
  withCategoriesFilter = 'withCategoriesFilter',
  withSearchInput = 'withSearchInput',
  withBrandSuggestion = 'withBrandSuggestion',
  withSimilarProductsDrawer = 'withSimilarProductsDrawer',
  withCategoryTiles = 'withCategoryTiles',
  withFilterSidebar = 'withFilterSidebar',
  withQuickFilters = 'withQuickFilters',
  withSort = 'withSort',
}

export enum facetRef {
  brand = 'brand',
  tags = 'tags',
  location = 'location',
  locationBrand = 'locationBrand',
  made_in = 'made_in',
  margin = 'margin',
  marginBrand = 'marginBrand',
  shipping_lead_time = 'shipping_lead_time',
  shipping_lead_timeBrand = 'shipping_lead_timeBrand',
  retail_price = 'retail_price',
  category = 'category',
  discount = 'discount',
  aks_custom__loyalty_offers = 'aks_custom__loyalty_offers',
  aks_plus = 'aks_plus',
  aks_plus_brand = 'aks_plus_brand',
}

export enum Context {
  BRAND = 'brand',
  SEARCH = 'search',
  CATEGORY_PRODUCTS = 'category_products',
  CATEGORY_BRANDS = 'category_brands',
  NEW_PRODUCTS = 'new_products',
  NEW_BRANDS = 'new_brands',
  PREORDER_PRODUCTS = 'preorder_products',
  PREORDER_BRANDS = 'preorder_brands',
  COLLECTION_PRODUCTS = 'collection_products',
  COLLECTION_BRANDS = 'collection_brands',
  WHOLESALE = 'wholesale',
}

export enum CatalogView {
  PRODUCTS = 'products',
  BRANDS = 'brands',
}

export interface Facet {
  uid: string;
  name: string;
  placeHolder?: string;
  type: FacetsType;
  attributeType?: string;
  display: boolean;
  title: string;
  message: string;
  trackingName: string;
  value?: string;
  badgeType?: string;
  isOpen?: boolean;
  unit?: string;
  // Duplicated from AtributeDefinition. TODO: Unify the Facet and AttributeDefinition types
  choices?: Array<{ slug: string; name: string; data: null | { color?: string }; position: number }>;
}

export type FacetGroup = {
  uid: string;
  name: string;
  title: string;
  message: string;
  display: boolean;
  facets: Facet[];
};

export interface Config {
  context: Context;
  template: SnippetTemplate;
  withBreadCrumb: boolean;
  withTitle: boolean;
  withSearchInput: boolean;
  withCategoriesFilter: boolean;
  withTabs: boolean;
  withBrandSuggestion: boolean;
  withSimilarProductsDrawer: boolean;
  withCategoryTiles: boolean;
  withFilterSidebar: boolean;
  withQuickFilters: boolean;
  withSort?: boolean;
  Facets?: (Facet | FacetGroup)[];
  quickFilters: Facet[];
  urlManager: URLManager;
  eventTile: boolean;
  maxPages?: number;
}

export type InsertionSlot = {
  name: string;
  insertionRowIndex: number;
  insertionCellIndex: number;
  content: unknown;
};

export type CatalogGridInsertion = {
  status: 'inactive' | 'loading' | 'idle';
  slots: InsertionSlot[];
};
