import AnalyticsEvent from '../analytics-event';
import { CheckoutEoriBypassFailure } from '../segment-ecommerce-types';

export class EoriCheckoutBypassFailure extends AnalyticsEvent {
  public readonly name = 'EORI Checkout Bypass Failure';

  constructor(public readonly properties: CheckoutEoriBypassFailure) {
    super();
  }
}
