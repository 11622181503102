<template>
  <ul>
    <li class="divider" />
    <li>
      <a :href="links.orders">
        <span class="badge-holder">
          {{ $t('Orders') }}
          <BadgePendingActions :categories="['orders']" />
        </span>
      </a>
    </li>
    <li v-if="$isEnabled('OXP-1484')">
      <a :href="links.preorders">
        <span class="badge-holder">
          {{ $t('accountMenu.preorders') }}
        </span>
        <AkBadge
          :content="$t('Beta')"
          size="xs"
          color="blue"
          class="menu-badge--new"
        />
      </a>
    </li>
    <li>
      <a :href="links.reorder">
        <span class="badge-holder">
          {{ $t('Re-order') }}
        </span>
        <AkBadge
          :content="$t('New')"
          size="xs"
          color="green"
          class="menu-badge--new"
        />
      </a>
    </li>
    <li v-if="$isEnabled('payment_invoice_menu')">
      <a :href="links.checkouts">
        <span class="badge-holder">
          {{ $t('Payments & invoices') }}
          <BadgePendingActions :categories="['checkouts']" />
        </span>
      </a>
    </li>
    <li v-if="$isEnabled('messages')">
      <a :href="links.messages">
        <span class="badge-holder">
          {{ $t('Messages') }}
          <BadgePendingActions :categories="['messages']" />
        </span>
      </a>
    </li>
    <li v-if="$isEnabled('favorites_brand_menu')">
      <a :href="links.favoritebrands">
        {{ $t('Favorite brands') }}
      </a>
    </li>
    <li v-if="$isEnabled('wallet')">
      <a :href="links.wallet">
        {{ $t('Wallet') }}
      </a>
    </li>
    <li v-if="isSubscriptionProgramVisible">
      <a
        :href="links.subscriptionProgram"
        data-testid="subscriptionEntry"
      >
        {{ $t('ankorstorePlus.program.name') }}
        <AkBadge
          :content="$t('New')"
          size="xs"
          color="green"
          class="menu-badge--new"
        />
      </a>
    </li>
    <li v-else-if="ankorstoreProgramAvailable">
      <a
        :href="links.ankorstore_plus_signup"
        data-testid="signupAnkorstorePlusEntry"
      >
        {{ $t('ankorstorePlus.program.name') }}
        <AkBadge
          :content="$t('New')"
          size="xs"
          color="green"
          class="menu-badge--new"
        />
      </a>
    </li>
    <li v-if="$isEnabled('new_r2b')">
      <a
        :href="links.r2b"
        @click="suggestBrandLinkClicked"
      >
        {{ $t('Refer brands') }}
      </a>
    </li>
    <li>
      <a :href="links.privateOffers">
        {{ $t('My perks') }}
      </a>
    </li>
    <li v-if="$isEnabled('notifications_panel')">
      <a
        :href="links.notifications"
        target="_blank"
      >
        <span class="badge-holder">
          {{ $t('Email preferences') }}
          <BadgePendingActions :categories="['notifications']" />
        </span>
      </a>
    </li>
    <li>
      <a :href="links.settings">
        <span class="badge-holder">
          {{ $t('Settings') }}
          <BadgePendingActions :categories="['settings']" />
        </span>
      </a>
    </li>
    <MenuMobileItemHelpCenter />
    <li class="divider" />
    <li>
      <a
        :href="links.logout"
        onclick="document.getElementById('logout-form').submit(); return false;"
        >{{ $t('Logout') }}</a
      >
    </li>
    <li class="divider" />
  </ul>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import Analytics from '@/services/analytics';
import { UserClick } from '@/services/analytics/events/user-click.event';
import BadgePendingActions from '@/components/account/badge-pending-actions.vue';
import { mapGetters } from 'vuex';
import MenuMobileItemHelpCenter from '@/components/header/menu-mobile-item-help-center.vue';
import { AkBadge } from '@ankorstore/design-system';

export default defineComponent({
  name: 'MenuMobileRetailer',
  components: {
    MenuMobileItemHelpCenter,
    BadgePendingActions,
    AkBadge,
  },
  props: {
    links: { type: Object, required: true },
  },
  computed: {
    ...mapGetters('offers', ['ankorstorePlus']),
    isSubscriptionProgramVisible(): boolean {
      return this.ankorstorePlus?.optIn || this.ankorstorePlus?.subscription?.status === 'trialing';
    },
    ankorstoreProgramAvailable(): boolean {
      return !!this.ankorstorePlus;
    },
  },
  methods: {
    suggestBrandLinkClicked(): void {
      Analytics.track(
        new UserClick({
          component: 'r2b_mobile_menu_my_referral',
          action: 'go_to_r2b_page',
        })
      );
    },
  },
});
</script>
