import { MultipleQueriesQuery } from '@algolia/client-search';
import { getIndexName, IndexName } from '@/services/analytics/algolia/index-name';
import { BrandHit, searchClient } from '@bc/discovery/domain/catalog';

const client = searchClient();

const getQuery = (
  collectionId: string,
  userCountry: string,
  indexName: IndexName.Products | IndexName.Brands
): MultipleQueriesQuery => {
  const countriesFilter = 'opened_countries';
  const attributesToRetrieve = [
    'id',
    'uuid',
    'active',
    'discount',
    'business_events_eligible',
    'business_events',
    'link',
    'images.squared',
    'images.large',
    'images.rounded',
    'name',
    'active',
    'highlighted_products',
    'minimum',
    'opened_countries',
    'has_new_products',
    'shipping_lead_time',
    'inserted_at',
    'brand_tags',
  ];
  return {
    indexName: getIndexName(indexName),
    params: {
      filters: `(aks_custom__collection=${collectionId}) AND (${countriesFilter}:"${userCountry}")`,
      attributesToRetrieve,
      hitsPerPage: 20,
      page: 0,
    },
  };
};

export const getBrandCollectionHits = async (_lang: string, collectionId: string, userCountry: string) => {
  const query = getQuery(collectionId, userCountry, IndexName.Brands);
  return client.search<BrandHit>([query]).then((response) => response.results.flatMap((result) => result.hits));
};
