import AnalyticsEvent from '@/services/analytics/events/analytics-event';
import { createProductProperty, ProductProperty } from '@/services/analytics/properties/product-property';
import { BrandProperty, createBrandProperty } from '@/services/analytics/properties/brand-property';

export interface ReorderProductCardTrackingData {
  parentComponent: string;
  lbValueProposition?: string;
  sectionId?: string;
  index?: number;
}

export enum ReorderProductCardActions {
  PRODUCT_CLICKED = 'product_clicked',
  ADD_TO_CART_CLICKED = 'add_to_cart_clicked',
  QUICK_ADD_TO_CART_CLICKED = 'quick_add_to_cart_clicked',
  SELECT_VARIANT_CLICKED = 'select_variant_clicked',
}

export class ProductQuickAddToCartEvent extends AnalyticsEvent {
  public readonly name = 'Product Quick Added to Cart';
  public properties: {
    product: ProductProperty;
    position: number;
    id_section?: string;
    brand: BrandProperty;
  };

  constructor(product: ProductProperty, position: number, sectionId: string, brand: BrandProperty) {
    super();
    this.properties = {
      product: createProductProperty(product),
      position: position,
      id_section: sectionId,
      brand: createBrandProperty(brand),
    };
  }
}
