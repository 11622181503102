export enum TaxNumberType {
  VatNumber = 'vat_number',
  TaxNumber = 'tax_number',
}

export type TaxNumber = {
  type: TaxNumberType | null;
  value: string;
  isSkipped: boolean;
  isValid: boolean;
  vatExemption?: boolean;
};
