import { OriginProperty } from '@/services/analytics/properties/origin-property';

enum Path {
  LiftPage = 'lift',
  HomePage = '',
  HomePageV2 = 'landing',
}

export function getSourceByPath() {
  for (const key in Path) {
    if (window.location.pathname.split('/')[1] === Path[key]) {
      return OriginProperty[key];
    }
  }
}
