import { getBestPriceEnrollmentStatus, postBestPriceEnrollmentStatus } from '@/modules/brand-account/services/settings.service';
import { BrandBestPriceEnrollmentPayload, BrandBestPriceEnrollmentStatus } from '@/modules/brand-account/types/settings';

export interface State {
  bpg_enrollment_status: {
    value: BrandBestPriceEnrollmentStatus;
    loaded: boolean;
    loading: boolean;
    error: Error;
  };
}

export const createInitialState = (): State => ({
  bpg_enrollment_status: {
    value: null,
    loaded: false,
    loading: false,
    error: null,
  },
});

export default {
  namespaced: true,
  state: createInitialState(),
  mutations: {
    SET_BPG_ENROLLMENT_STATUS(state: State, pbgStatus: BrandBestPriceEnrollmentStatus) {
      state.bpg_enrollment_status.value = pbgStatus;
      state.bpg_enrollment_status.loading = false;
      state.bpg_enrollment_status.loaded = true;
    },
    SET_BPG_ENROLLMENT_STATUS_ERROR(state: State, error: Error) {
      state.bpg_enrollment_status.loading = false;
      state.bpg_enrollment_status.error = error;
    },
    SET_BPG_ENROLLMENT_STATUS_LOADING(state: State) {
      state.bpg_enrollment_status.loading = true;
      state.bpg_enrollment_status.error = null;
    },
  },
  actions: {
    loadBpgEnrollmentStatus: ({ commit }) => {
      commit('SET_BPG_ENROLLMENT_STATUS_LOADING');
      return getBestPriceEnrollmentStatus()
        .then((res) => {
          commit('SET_BPG_ENROLLMENT_STATUS', res);
        })
        .catch((error) => {
          commit('SET_BPG_ENROLLMENT_STATUS_ERROR', error);
        });
    },
    updatePbgEnrollmentStatus: ({ commit }, payload: BrandBestPriceEnrollmentPayload) => {
      commit('SET_BPG_ENROLLMENT_STATUS_LOADING');
      return postBestPriceEnrollmentStatus(payload)
        .then((res) => {
          commit('SET_BPG_ENROLLMENT_STATUS', res);
        })
        .catch((error) => {
          commit('SET_BPG_ENROLLMENT_STATUS_ERROR', error);
        });
    },
  },
  getters: {
    getBpgEnrollmentStatus: (state: State): BrandBestPriceEnrollmentStatus => state.bpg_enrollment_status.value,
    isBpgEnrollmentStatusLoaded: (state: State): boolean => state.bpg_enrollment_status.loaded,
    isBpgEnrollmentStatusLoading: (state: State): boolean => state.bpg_enrollment_status.loading,
    bpgEnrollmentStatusError: (state: State): Error => state.bpg_enrollment_status.error,
  },
};
