export interface PageInfo {
  name: string;
  category?: string;
  properties?: {
    brand_slug?: string;
    product_slug?: string;
  };
}

type PageInfoFn<R> = (location: Pick<Location, 'pathname'>) => R;
const brandPageRegex = /^\/brand\/([a-z0-9-]+-[0-9]+)$/;

export const isBrandPage: PageInfoFn<boolean> = (location) => brandPageRegex.test(location.pathname);

export const getBrandProperties: PageInfoFn<Pick<PageInfo['properties'], 'brand_slug'>> = (location) => {
  const [, brand_slug] = brandPageRegex.exec(location.pathname);

  return { brand_slug };
};

const productPageRegex = /^\/brand\/([a-z0-9-]+-[0-9]+)\/([a-z0-9-]+-[0-9]+)$/;
export const isProductPage: PageInfoFn<boolean> = (location) => productPageRegex.test(location.pathname);

export const getProductProperties: PageInfoFn<Pick<PageInfo['properties'], 'brand_slug' | 'product_slug'>> = (location) => {
  const [, brand_slug, product_slug] = productPageRegex.exec(location.pathname);

  return { brand_slug, product_slug };
};

export const getPageInfo: PageInfoFn<PageInfo | null> = (location) => {
  if (isBrandPage(location)) {
    return {
      name: 'Brand',
      properties: getBrandProperties(location),
    };
  }

  if (isProductPage(location)) {
    return {
      name: 'Product',
      properties: getProductProperties(location),
    };
  }

  return null;
};
