import { getGlobalConfig } from './global-config';
import { changeUser, initialize } from '@braze/web-sdk';
import Store from '@/store/index';

/**
 * This wrapper around the initialize method is only here to provide all
 * the init options in the same place.
 * NOTE: this is only exported so that it can be tested, there is no need to
 * call `initializeBraze` outside of this file.
 */
export function initializeBraze() {
  const {
    braze: { api_key, base_url },
  } = getGlobalConfig();
  const user = Store.getters.user;
  const canInitialize = !!api_key && !!base_url && !!user;

  if (canInitialize) {
    initialize(api_key, {
      baseUrl: base_url,
      enableLogging: false,
    });

    changeUser(user.id);
  }
}

export {
  destroy,
  getCachedContentCards,
  hideContentCards,
  openSession,
  requestContentCardsRefresh,
  showContentCards,
  subscribeToContentCardsUpdates,
} from '@braze/web-sdk';
