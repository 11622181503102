/**
 * Flatten an array of items with children.
 *
 * @param items An array with children to flatten
 * @param getChildItems Extract teh child items from an item
 * @returns A flat array of items
 */
export function flatten<T>(items: T[], getChildItems: (item: T) => T[]) {
  return items.flatMap((item) => {
    const childItems = getChildItems(item);

    if (!childItems?.length) {
      return item;
    }

    return [item, ...flatten(childItems, getChildItems)];
  });
}
