export enum CollectionType {
  'Product' = 'product',
  'Brand' = 'brand',
}

export type Collection = {
  uuid: string;
  reference: string;
  name: string;
  image: string;
  counts: {
    product?: number;
    brand?: number;
  };
};
