<template>
  <ais-pagination :total-pages="totalPages">
    <template #default="{ currentRefinement, pages, nbPages, isFirstPage, isLastPage, refine }">
      <div
        v-show="!(isFirstPage && isLastPage)"
        class="pagination"
      >
        <template v-if="!(isFirstPage && isLastPage)">
          <div class="pagination__nav pagination__nav--prev">
            <a
              :href="isFirstPage ? '' : getUrl(currentRefinement - 1)"
              :class="[{ 'pagination-disabled': isFirstPage }]"
              :disabled="isFirstPage"
              @click.prevent="!isFirstPage && displayPage(refine, currentRefinement - 1)"
            >
              <span class="pagination__btn-text pagination__btn-text--full">{{ $t('Previous') }}</span>
              <span class="pagination__btn-text pagination__btn-text--small">&lt;&lt;</span>
            </a>
          </div>

          <ul class="pagination-pages">
            <!-- Display the : "1 ..." if first page is not 1 -->
            <template v-if="pages[0] > 0">
              <li>
                <a
                  :href="getUrl(0)"
                  @click.prevent="displayPage(refine, 0)"
                >1</a>
              </li>

              <li v-if="pages[0] == 2">
                <!-- We don't want to print 1 ... 3 but 1 2 3 so there is a special condition here-->

                <a
                  :href="getUrl(1)"
                  @click.prevent="displayPage(refine, 1)"
                >2</a>
              </li>

              <li v-else-if="pages[0] > 2">
                <span class="pagination__ellipsis">...</span>
              </li>
            </template>

            <li
              v-for="page in pages"
              :key="page"
            >
              <template v-if="page === currentRefinement">
                <span class="pagination-selected">
                  {{ page + 1 }}
                </span>
              </template>

              <template v-else>
                <a
                  :href="getUrl(page)"
                  @click.prevent="pages.length > 1 && displayPage(refine, page)"
                >{{ page + 1 }}</a>
              </template>
            </li>

            <!-- Display the : "... 17" -->
            <template v-if="nbPages > pages[pages.length - 1] + 1">
              <li v-if="nbPages == pages[pages.length - 1] + 3">
                <!-- We don't want to print 15 ... 17 but 15 16 17 so there is a special condition here-->
                <a
                  :href="getUrl(pages[pages.length - 1] + 1)"
                  @click.prevent="displayPage(refine, pages[pages.length - 1] + 1)"
                >{{ pages[pages.length - 1] + 2 }}</a>
              </li>

              <li v-else-if="nbPages > pages[pages.length - 1] + 2">
                <span class="pagination__ellipsis">...</span>
              </li>

              <li>
                <template v-if="nbPages - 1 === currentRefinement">
                  <span class="pagination-selected">
                    {{ nbPages }}
                  </span>
                </template>

                <template v-else>
                  <a
                    :href="getUrl(nbPages - 1)"
                    @click.prevent="displayPage(refine, nbPages - 1)"
                  >{{ nbPages }}</a>
                </template>
              </li>
            </template>
          </ul>

          <div class="pagination__nav pagination__nav--next">
            <a
              :href="isLastPage ? '' : getUrl(currentRefinement + 1)"
              :class="{ 'pagination-disabled': isLastPage || pages.length === 1 }"
              :disabled="isLastPage || pages.length === 1"
              @click.prevent="!(isLastPage || pages.length === 1) && displayPage(refine, currentRefinement + 1)"
            >
              <span class="pagination__btn-text pagination__btn-text--full">{{ $t('Next') }}</span>
              <span class="pagination__btn-text pagination__btn-text--small">&gt;&gt;</span>
            </a>
          </div>
        </template>
      </div>
    </template>
  </ais-pagination>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'CatalogPagination',
  props: {
    totalPages: {
      type: Number,
      default: undefined,
    },
    overrideRefine: {
      type: Function as PropType<(k: number) => void>,
      default: null,
    },
  },
  emits: ['page-change'],
  methods: {
    displayPage(refine, pageIndex) {
      if (this.overrideRefine) {
        this.overrideRefine(pageIndex);
      } else {
        refine(pageIndex);
      }
      this.$emit('page-change', { currentPage: pageIndex });
    },
    getUrl(page) {
      let url = `${this.$route.path}?p=${page + 1}`;
      if (this.$route?.query?.q) {
        url += `&q=${this.$route.query.q}`;
      }
      return url;
    },
  },
});
</script>
