import http from '@/services/api/http';
import { captureBrandsException } from '@/sentry/helper';
import {
  type ContactsSearchResponse,
  type PersonalisedDiscountResponse,
  type ContactsSearchParams,
  type ContactsExportParams,
  type BrandSegment,
} from '../types/api.types';
import type { AxiosResponse } from 'axios';
import { FiltersResponse } from '../types/api.types';
import { memoize } from 'lodash-es';
import { deserialize } from '@/services/utils/jsonapi-parser';

const ROOT_API_PATH = '/api/internal/v1/network/contacts';

const jsonApiConfigs = {
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
};

export const getContacts = async ({
  searchQuery = '',
  facets = [],
  filters = {},
  sort = {},
  currentPage = 1,
  size = 100,
} = {}): Promise<ContactsSearchResponse> => {
  const apiPayload: ContactsSearchParams = {
    data: {
      type: 'search-params',
      attributes: {
        facets,
        filters: Object.values(filters) || [],
        sort,
        page: {
          size,
          current: currentPage,
        },
      },
    },
  };

  if (searchQuery) {
    apiPayload.data.attributes.query = { term: searchQuery };
  }

  const apiRoute = `${ROOT_API_PATH}/search`;

  try {
    const response: AxiosResponse<ContactsSearchResponse> = await http().post(apiRoute, apiPayload, jsonApiConfigs);

    return response.data;
  } catch (error) {
    captureBrandsException(error, [
      { label: 'component', value: 'contact-management/api' },
      { label: 'action', value: 'getContacts' },
    ]);
    return Promise.reject(error);
  }
};

export const getFilters = memoize(async (): Promise<FiltersResponse> => {
  const apiRoute = `${ROOT_API_PATH}/search/widgets`;
  try {
    const response: AxiosResponse<FiltersResponse> = await http().get(apiRoute, jsonApiConfigs);

    return response.data;
  } catch (error) {
    captureBrandsException(error, [
      { label: 'component', value: 'contact-management/api' },
      { label: 'action', value: 'getFilters' },
    ]);
    return Promise.reject(error);
  }
});

export const deleteContact = async (contactId: string): Promise<void> => {
  try {
    const response: AxiosResponse = await http().delete(`${ROOT_API_PATH}/${contactId}`);
    return response.data;
  } catch (error) {
    captureBrandsException(error, [
      { label: 'component', value: 'contact-management/api' },
      { label: 'action', value: 'deleteContact' },
    ]);
    return Promise.reject(error);
  }
};

export const deleteContacts = async (payload: Record<'id', string>[]): Promise<Blob> => {
  try {
    const apiRoute = `${ROOT_API_PATH}/delete`;
    const apiPayload = { contacts: payload };

    const response: AxiosResponse<Blob> = await http().post(apiRoute, apiPayload, jsonApiConfigs);
    return response.data;
  } catch (error) {
    captureBrandsException(error, [
      { label: 'component', value: 'contact-management/api' },
      { label: 'action', value: 'deleteContacts' },
    ]);
    return Promise.reject(error);
  }
};

export const exportContacts = async ({ searchQuery = '', filters = {} } = {}): Promise<Blob> => {
  const apiPayload: ContactsExportParams = {
    data: {
      type: 'contact-export',
      attributes: {
        filters: typeof filters === 'object' && filters !== null ? Object.values(filters) : [],
      },
    },
  };

  if (searchQuery) {
    apiPayload.data.attributes.query = { term: searchQuery };
  }

  const apiRoute = `${ROOT_API_PATH}/exports`;

  try {
    const { data } = await http().post(apiRoute, apiPayload, jsonApiConfigs);
    return Promise.resolve(data);
  } catch (error) {
    captureBrandsException(error, [
      { label: 'component', value: 'contact-management/api' },
      { label: 'action', value: 'exportContacts' },
    ]);
    return Promise.reject(error);
  }
};

export const getBrandSegments = async (): Promise<BrandSegment[]> => {
  try {
    const { data }: AxiosResponse<{ data: BrandSegment[] }> = await http().get(`/api/me/brand/campaigns/segments`);
    return Promise.resolve(data?.data);
  } catch (error) {
    captureBrandsException(error, [
      { label: 'component', value: 'contact-management/api' },
      { label: 'action', value: 'getBrandSegments' },
    ]);
    return Promise.reject(error);
  }
};

export const setDiscount = async (value: number, contactIds: string[]): Promise<PersonalisedDiscountResponse> => {
  try {
    const response: AxiosResponse<PersonalisedDiscountResponse> = await http().post(
      `/api/internal/v1/network/contacts/apply-personalised-discount`,
      {
        data: {
          type: 'personalised-discount-bulk-request',
          attributes: {
            contactIds,
            value,
          },
        },
      },
      {
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }
    );

    return deserialize(response.data).meta;
  } catch (error) {
    captureBrandsException(error, [
      { label: 'component', value: 'contact-management/api' },
      { label: 'action', value: 'setDiscount' },
    ]);
    return Promise.reject(error);
  }
};

export const removeDiscount = async (contactIds: string[]): Promise<void> => {
  try {
    const response: AxiosResponse<PersonalisedDiscountResponse> = await http().post(
      `/api/internal/v1/network/contacts/apply-personalised-discount`,
      {
        data: {
          type: 'personalised-discount-bulk-request',
          attributes: {
            value: 0,
            contactIds,
          },
        },
      },
      {
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }
    );

    return deserialize(response.data).meta;
  } catch (error) {
    captureBrandsException(error, [
      { label: 'component', value: 'contact-management/api' },
      { label: 'action', value: 'removeDiscount' },
    ]);
    return Promise.reject(error);
  }
};
