import i18n from '@/services/i18n';
import { getSimpleIndexName, IndexName } from '@/services/analytics/algolia/index-name';
import { ItemResult } from '@bc/discovery';
import { LanguageIsoCode } from '@/types/base';
import { getCategories } from '@/services/metas/categories';

interface LevelCategory {
  readonly value: string;
  readonly count: number;
}

const hierarchicalCategoriesPrefix = 'catalog_hierarchical_categories.v3.lvl';

export const getLevelCategory = ({ item, level = 0 }: { item: ItemResult; level?: 0 | 1 }): LevelCategory[] => {
  const key = getSimpleIndexName(IndexName.Products);
  return item?.[key]?.facets?.exact_matches?.[`${hierarchicalCategoriesPrefix}${level}`];
};

export const getFormattedCategoryName = ({
  item,
  level = 0,
  fullName = false,
}: {
  item: ItemResult;
  lang?: LanguageIsoCode;
  level?: 0 | 1;
  fullName?: boolean;
}): string => {
  const categories = getCategories();
  if ('hasInAll' in item && item.hasInAll) {
    return '';
  } else {
    const categoryId = getLevelCategory({ item, level })?.[0]?.value?.split('>')[level] ?? '';
    const categoryFullName = categories?.idToName?.[parseInt(categoryId)];
    const categoryName = fullName ? categoryFullName : categoryFullName?.split('>')[level]?.trim();
    return categoryName ? `${i18n.global.t('in {categoryName}', { categoryName })}` : '';
  }
};

export const hasLevelCategory = ({ item, level }: { item: ItemResult; level?: 0 | 1 }): boolean => {
  return !!getLevelCategory({ item, level })?.length;
};

export const getCategoryId = ({ item, level = 0 }: { item: ItemResult; level?: 0 | 1 }): string => {
  const categoryLevelValues: string[] = getLevelCategory({
    item,
    level,
  })?.[0].value?.split('>');
  return categoryLevelValues?.[level]?.trim();
};

export const makeLevelCategoryLink = ({ q, item, level = 0 }: { q: string; item: ItemResult; level?: 0 | 1 }): string => {
  if (!item) {
    return `/search?q=${encodeURIComponent(q)}`;
  }
  const categoryId = getLevelCategory({ item, level: 1 })[0]?.value.split('>')[0].trim();

  if (!categoryId) {
    return `/search?q=${encodeURIComponent(q)}`;
  }
  const pathPrefix = `?categories`;
  let path = `${pathPrefix}=${categoryId}`;

  if (level === 1) {
    const subCategoryId = getLevelCategory({ item, level: 1 })[0]?.value.split('>')[1].trim();
    path += `,${subCategoryId}`;
  }
  return `/search${path}&q=${encodeURIComponent(q)}`;
};

export const makeLevelCategoryLinkOld = ({ q, item, level = 0 }: { q: string; item: ItemResult; level?: 0 | 1 }): string => {
  const categoryId = getLevelCategory({ item, level: 1 })?.[0]?.value.split('>')[0].trim();
  const pathPrefix = `hierarchicalMenu[${hierarchicalCategoriesPrefix}0]`;

  if (categoryId) {
    const pathToCategory = `${pathPrefix}[0]=${categoryId}`;
    let pathToSubcategory;

    if (level === 1) {
      const subCategoryId = getLevelCategory({
        item,
        level: 1,
      })[0]
        ?.value.split('>')[1]
        .trim();
      pathToSubcategory = `${pathPrefix}[1]=${subCategoryId}`;
    }
    const prefixedPathToSubcategory = `&${pathToSubcategory}`;
    return `/search?${pathToCategory}${pathToSubcategory ? prefixedPathToSubcategory : ''}&q=${encodeURIComponent(q)}`;
  } else {
    return `/search?q=${encodeURIComponent(q)}`;
  }
};
