export const documentScrollDisabler = () => {
  let styleElement: HTMLStyleElement;
  let styleIndex: number;
  let currentPosition: number;

  const disableScroll = () => {
    if (styleElement) {
      return;
    }
    styleElement = document.createElement('style');
    currentPosition = window.scrollY;
    document.head.appendChild(styleElement);
    styleIndex = styleElement.sheet.insertRule(
      `body.scroll-disabled { overflow: hidden; position: fixed; width: 100%; top: -${currentPosition}px }`
    );
    document.body.classList.add('scroll-disabled');
  };

  const enableScroll = () => {
    if (!styleElement) {
      return;
    }
    document.body.classList.remove('scroll-disabled');
    window.scrollTo({ top: currentPosition });
    styleElement.sheet.deleteRule(styleIndex);
    document.head.removeChild(styleElement);
    styleElement = undefined;
  };

  return {
    disableScroll,
    enableScroll,
  };
};
