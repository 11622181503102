import { AlgoliaHit } from 'instantsearch.js';
import { RouteRecordRaw, RouteMeta } from 'vue-router';
import { CatalogueBanner1 } from '@bc/discovery/domain/events';
import { ProductHit } from '@/types/product';
import { PrismicSpecialTile1, PromotionTileType, SpecialTile } from '@/modules/promote-engage/promotion-tile/types';
import { Brand } from '@/types/api/brand';
import { SponsoredProductTile } from '@bc/advertisement';
import { CatalogueType } from '@bc/discovery/domain/tracking';
import { FilterState } from './filters';

export interface BrandCatalogue {
  name: string;
  slug: string;
  path: string;
  meta: RouteMeta;
  redirectURL?: string;
  indexName: string;
  indexNameProduct: string;
  bannerContent: CatalogueBanner1;
  tiles: PrismicSpecialTile1[];
}

export interface BrandCatalogueConfig {
  name: string;
  path: string;
  meta: RouteMeta;
  pathPrefix: string;
  indexOverride?: string;
  indexOverrideProduct?: string;
  customFilter?: string;
  title?: string;
  bannerContent?: CatalogueBanner1;
  beforeEnter?: RouteRecordRaw['beforeEnter'];
  tiles?: Array<SpecialTile>;
  allowBrowsingByProducts?: boolean;
  allowSorting?: boolean;
}

export enum NormalTileType {
  Brand = 'brand',
  Product = 'product',
  SponsoredProduct = 'sponsored-product',
}

export type TileType = NormalTileType | PromotionTileType;

export interface Tile {
  type: TileType;
  id: string | number;
}

type BusinessEventId = number;

export type BrandHit = AlgoliaHit<
  Pick<Brand, 'id' | 'name' | 'link' | 'images' | 'opened_countries' | 'active' | 'minimum' | 'brand_tags'> & {
    has_new_products: boolean;
    highlighted_products: Record<
      string,
      {
        id: number;
        image: string;
      }
    >;
    metadata: MetaDataBrandHit;
    discount: number;
    shipping_lead_time: string[];
    business_events_eligible: BusinessEventId[];
    inserted_at: string;
    business_events: Record<
      BusinessEventId,
      {
        id: number;
        discount: number;
        ranking: number;
      }
    >;
  }
>;

export interface MetaDataBrandHit {
  last_order_date?: Date;
}

export interface BrandTile extends Tile {
  type: NormalTileType.Brand;
  brand: BrandHit;
}

export interface ProductTile extends Tile {
  type: NormalTileType.Product;
  product: ProductHit;
}

export type AnyTile = SpecialTile | BrandTile | ProductTile | SponsoredProductTile;

export enum CatalogBrowserView {
  Products,
  Brands,
}

export enum CatalogBrowserSortings {
  new = 'new',
  featured = 'featured',
}

export interface CatalogContent {
  info_only: string;
  h1: string;
  header_text: string;
  pre_footer_text: PreFooterText[];
  meta_title: string;
  meta_description: string;
}

export enum PrefooterTextType {
  Heading2 = 'heading2',
  Paragraph = 'paragraph',
}
export interface PreFooterText {
  value: string;
  type: PrefooterTextType;
  text: string;
}

export interface SegmentTrackingFilter<T> {
  name: string;
  value: T | T[];
}

export interface TrackProductHitsUpdatedEvent {
  valueProposition: Record<string, string | number>;
  catalogueType: CatalogueType;
  view: CatalogBrowserView;
  pageIndex: number;
  sectionId: string;
  filters: SegmentTrackingFilter<unknown>[];
  filterState: Pick<FilterState, 'selectedCategories'>;
  tiles: AnyTile[];
}
