<template>
  <div class="ak-carousel ds-relative">
    <div
      v-if="title || arrows"
      class="ak-carousel__controls"
      :class="{
        'ds-absolute ds-h-full ds-w-full': overlayControls,
      }"
    >
      <AkHeading2
        v-if="title"
        class="ak-carousel__title"
      >
        {{ title }}
      </AkHeading2>
      <div
        v-if="arrows && max > 0"
        class="ds-space-x-2 ds-p-2"
        :class="[
          {
            'ds-ml-auto': !overlayControls,
            'ak-carousel__arrow--visible': overlayControls,
          },
          startDisplayingControlsAt ? `ds-hidden ${startDisplayingControlsAt}:ds-flex` : 'ds-flex',
        ]"
      >
        <div
          class="ak-carousel-prev"
          :class="prevClasses"
          @click.stop.prevent="prev()"
        >
          <AkIcon
            symbol="chevron-left"
            size="md"
          />
        </div>
        <div
          class="ak-carousel-next"
          role="button"
          :class="nextClasses"
          @click.stop.prevent="next()"
        >
          <AkIcon
            symbol="chevron-right"
            size="md"
          />
        </div>
      </div>
    </div>

    <div
      ref="wrapper"
      :class="[{ 'ds-rounded-md': rounded }, scrollable ? 'ds-overflow-auto' : 'ds-overflow-hidden']"
      style="scroll-behavior: smooth"
    >
      <div
        ref="slider"
        class="ak-carousel__items-container"
        :style="sliderStyle"
        @transitionstart="busy = true"
        @transitionend="busy = false"
      >
        <slot></slot>
      </div>
    </div>

    <div
      v-if="dots && pages.length > 1"
      class="ak-carousel__indicator-container"
      :class="[
        {
          'ak-carousel__indicator-container--with-controls': overlayControls,
        },
        startDisplayingControlsAt ? `ds-hidden ${startDisplayingControlsAt}:ds-flex` : 'ds-flex',
      ]"
    >
      <div
        v-for="(page, i) in pages"
        :key="i"
        :class="[
          `ak-carousel__indicator ds-bg-${dotsColor}`,
          {
            'ds-opacity-60': currentPage !== i,
          },
          currentPage === i && !dotsAlt ? 'ds-w-6' : 'ds-w-2.5',
        ]"
        @click.stop.prevent="goTo(page * itemsToShow)"
      ></div>
    </div>
  </div>
</template>
<script lang="ts">
/**
 * @deprecated, please use the AkCarousel from the DS
 */
import { defineComponent } from 'vue';
import { debounce } from 'lodash-es';

export default defineComponent({
  name: 'AkCarousel',
  props: {
    title: {
      type: String,
      default: null,
    },
    nbItems: {
      type: Number,
      default: null,
    },
    arrows: {
      type: Boolean,
      default: true,
    },
    dots: {
      type: Boolean,
      default: false,
    },
    dotsAlt: {
      type: Boolean,
      default: false,
    },
    overlayControls: {
      type: Boolean,
      default: false,
    },
    startDisplayingControlsAt: {
      type: String,
      default: null,
    },
    dotsColor: {
      type: String,
      default: 'white',
    },
    interval: {
      type: Number,
      default: 0,
    },
    guideHorizontalScroll: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['changed'],
  data() {
    const breakpoints = {
      default: 1,
      md: 2,
      lg: 4,
    };

    return {
      busy: false,
      windowWidth: 0,
      index: 0,
      items: [],
      breakpoints,
      itemWidth: 0,
      currentPage: 0,
      baseNavClasses: [
        'ds-rounded ds-rounded-full ds-border ds-border-solid',
        'ds-flex ds-items-center ds-justify-center ak-carousel__arrow',
        'ds-transition ds-duration-300 ds-ease-out',
        'ds-bg-white',
      ],
      navClass: 'ds-cursor-pointer ds-border-neutral-300 ds-text-black',
      navClassDisabled: 'ds-cursor-default ds-border-neutral-300 ds-text-neutral-500',
      intervalId: null,
    };
  },
  computed: {
    prevClasses(): string[] {
      return [...this.baseNavClasses, this.canPrev ? this.navClass : this.navClassDisabled];
    },
    nextClasses(): string[] {
      return [...this.baseNavClasses, this.canNext ? this.navClass : this.navClassDisabled];
    },
    count(): number {
      return this.items.length;
    },
    wrapper(): HTMLDivElement {
      return this.$refs.wrapper as HTMLDivElement;
    },
    slider(): HTMLDivElement {
      return this.$refs.slider as HTMLDivElement;
    },
    sliderStyle() {
      const x = !this.scrollable ? -(this.index * this.itemWidth) : 0;
      return { transform: `translate3d(${x}px, 0, 0)` };
    },
    scrollable(): boolean {
      return this.breakpoint !== 'lg';
    },
    breakpoint(): string {
      const { windowWidth: w } = this;
      const { screens } = this.$theme;
      if (w >= screens.lg) {
        return 'lg';
      }
      if (w >= screens.md) {
        return 'md';
      }
      return 'default';
    },
    itemsToShow(): number {
      return this.nbItems ?? this.breakpoints[this.breakpoint];
    },
    max(): number {
      return this.count - this.itemsToShow;
    },
    canPrev(): boolean {
      return this.index > 0;
    },
    canNext(): boolean {
      return this.index < this.max;
    },
    pages() {
      return Array.from(Array(Math.ceil(this.count / this.itemsToShow)).keys());
    },
    hasAutomaticCycle(): boolean {
      return !!this.interval && this.count > 1;
    },
  },
  mounted() {
    this.items = Array.from(this.slider.querySelectorAll(':scope > *'));
    window.addEventListener('resize', debounce(this.update, 250));
    if (this.guideHorizontalScroll) {
      (this.$refs.wrapper as HTMLElement).addEventListener('wheel', debounce(this.manageScrollXAction, 400));
    }
    this.update();
    this.initializeAutomaticCycle();
  },
  updated() {
    this.update();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.update);
    if (this.hasAutomaticCycle && this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  },
  methods: {
    onScroll() {
      const x = this.wrapper.scrollLeft;
      this.index = ~~(x / this.itemWidth);
    },
    manageScrollXAction(event) {
      event.preventDefault();
      if (event.deltaX > 0) {
        this.next();
      } else {
        this.prev();
      }
    },
    update() {
      const width = this.$el.clientWidth;
      this.windowWidth = window.innerWidth;
      this.itemWidth = width / this.itemsToShow;
      this.items.forEach((el) => (el.style.width = `${this.itemWidth}px`));
      this.slider.style.width = Math.ceil(this.items.length * this.itemWidth) + 'px';

      if (!this.scrollable) {
        this.wrapper.scrollLeft = 0;
      }
    },
    goTo(index: number) {
      if (this.busy) {
        return;
      }

      let newIndex = Math.max(0, index);
      newIndex = Math.min(this.max, index);
      this.currentPage = index / this.itemsToShow;
      this.index = newIndex;
      if (this.scrollable) {
        this.wrapper.scrollLeft = newIndex * this.itemWidth;
      }
      this.$emit('changed');
    },
    prev() {
      if (this.canPrev) {
        this.goTo(this.index - 1);
      }
    },
    next() {
      if (this.canNext) {
        this.goTo(this.index + 1);
      }
    },
    initializeAutomaticCycle(): void {
      if (this.hasAutomaticCycle) {
        this.intervalId = setInterval(() => {
          if (this.index === this.count - 1) {
            this.goTo(0);
          } else {
            this.next();
          }
        }, this.interval);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@css/vue-import';

.ak-carousel {
  &__title {
    @apply ds-mr-auto ds-flex ds-p-2 ds-font-bold ds-text-2xl;
  }

  &__controls {
    @apply ds-flex ds-items-center ds-mb-2;
    z-index: $z-index-carousel-controls;
  }

  &__arrow {
    width: 2.5rem;
    height: 2.5rem;
    &--visible {
      @apply ds-w-full ds-justify-between ds-transition-opacity ds-duration-500 ds-ease-in-out ds-opacity-0 hover:ds-opacity-100;
    }
  }

  &__items-container {
    @apply ds-flex ds-transition-all ds-duration-500 ds-ease-in-out ds-h-full;
  }

  &__indicator-container {
    @apply ds-w-full ds-flex ds-justify-center ds-mt-4;
    &--with-controls {
      @apply ds-absolute ds-z-10 ds-inset-x-0 ds-bottom-5;
    }
  }

  &__indicator {
    @apply ds-mx-1 ds-rounded-full ds-h-2.5 ds-border-solid ds-border ds-border-neutral-300 ds-cursor-pointer ds-transition-all;
  }
}
</style>
