<template>
  <div class="lift-page-usp">
    <i18n-t
      keypath="Why shop us through {0}?"
      class="md:ds-text-4xl ds-text-2xl ds-font-bold ds-text-center ds-z-10 ds-mb-6 md:ds-mb-9"
    >
      <span class="ds-text-info">{{ $t('ankorstore') }}</span>
    </i18n-t>
    <div class="ds-flex ds-justify-around ds-w-full md:ds-px-8 ds-mb-6 ds-flex-wrap">
      <div class="lift-page-usp__value-proposition home-category-overlay-1">
        <AkParagraph class="ds-text-xl md:ds-text-2xl ds-font-semibold ds-mb-4">
          {{ $t('Outstanding product selection') }}
        </AkParagraph>
        <AkParagraph>{{ $t('Get recommendations tailored for your store out of 2 million products') }}</AkParagraph>
      </div>
      <img
        src="/images/lift/usp_arrow.svg"
        alt=""
        class="lift-page-usp__usp-arrow"
      />
      <div class="lift-page-usp__value-proposition home-category-overlay-0">
        <AkParagraph class="ds-text-xl md:ds-text-2xl ds-font-semibold ds-mb-4">
          <template v-if="isEnabled(FeatureFlag.EnableNewBusinessModel)">
            {{ $t('liftPage.freeShipping.title', [$root.formatPrice(minimumOrderAmount, '$0')]) }}
          </template>
          <template v-else>
            {{ $t('Minimum orders of {0}', [$root.formatPrice(minimumOrderAmount, '$0')]) }}
          </template>
        </AkParagraph>
        <AkParagraph>
          <template v-if="isEnabled(FeatureFlag.EnableNewBusinessModel)">
            {{ $t('liftPage.freeShipping.description') }}
          </template>
          <template v-else>
            {{ $t('Available on all curated brands, along free-shipping') }}
          </template>
        </AkParagraph>
      </div>
      <img
        src="/images/lift/usp_arrow.svg"
        alt=""
        class="lift-page-usp__usp-arrow"
      />
      <div class="lift-page-usp__value-proposition home-category-overlay-3">
        <AkParagraph class="ds-text-xl md:ds-text-2xl ds-font-semibold ds-mb-4">
          {{ $t('Buy now, pay later') }}
        </AkParagraph>
        <AkParagraph>{{ $t('Up to 60 days payment terms') }}</AkParagraph>
      </div>
    </div>
    <img
      alt=""
      src="/images/lift/star.png"
      class="lift-page-usp__star"
    />
    <img
      alt=""
      src="/images/lift/ceramic_vase.png"
      class="lift-page-usp__ceramic_vase"
    />
    <img
      alt=""
      src="/images/lift/gerland_lights.png"
      class="lift-page-usp__gerland_lights"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import { isEnabled, FeatureFlag } from '@/services/features';
export default defineComponent({
  name: 'LiftUsp',
  props: {
    minimumOrderAmount: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      FeatureFlag,
      isEnabled,
    };
  },
});
</script>
<style lang="scss" scoped>
.lift-page-usp {
  @apply ds-flex ds-flex-col ds-items-center ds-mb-8 ds-relative ds-px-4;

  &__value-proposition {
    @apply ds-flex ds-flex-col ds-justify-center ds-items-center ds-bg-warm-white ds-px-4 ds-pb-7 ds-text-center ds-z-10 ds-mb-8;
    padding-top: 100px;
    width: 248px;

    @media (max-width: 768px) {
      padding-top: 70px;
    }
  }

  &__usp-arrow {
    @apply lg:ds-inline ds-hidden ds-h-auto;
    max-width: 106px;

    &:first-of-type {
      transform: scaleY(-1);
    }
  }

  &__star {
    @apply ds-absolute md:ds--bottom-5 md:ds-left-2/4 md:ds-ml-8 ds-ml-10 md:ds-h-auto ds-top-2/3 ds--mt-9 md:ds--mt-0 ds-h-10 ds-z-10 md:ds-z-0;
  }

  &__gerland_lights {
    @apply ds-absolute ds-left-0 md:ds--top-4 md:ds-h-auto ds-h-10 ds-top-9;
  }

  &__ceramic_vase {
    @apply ds-absolute ds-right-0 ds--top-4 md:ds-h-auto ds-h-9;
  }

  &__ankorstore-logo {
    @apply ds-h-8 md:ds-my-7 ds-my-4;
  }
}
</style>
