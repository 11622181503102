import { defineComponent } from 'vue';

export const LinkRoutingMixin = defineComponent({
  name: 'LinkRoutingMixin',
  methods: {
    async followLink(event: Event): Promise<string | undefined> {
      if (!(event.currentTarget instanceof HTMLAnchorElement)) {
        return undefined;
      }

      const linkUrl = new URL(event.currentTarget.href);

      if (!this.$router || linkUrl.origin !== window.location.origin) {
        return (window.location.href = linkUrl.href);
      }

      const route = `${linkUrl.pathname}${linkUrl.search}${linkUrl.hash}`;
      await this.$router.push(route);
      return route;
    },
    followProductLink(event: Event) {
      return this.followLink(event);
    },
    followBrandLink(event: Event) {
      return this.followLink(event);
    },
  },
});
