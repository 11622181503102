export type ProductTypeAttributesItem = {
  id: string;
  label: string;
  inputHint?: string;
  inputTip?: string;
  required: boolean;
  type: ProductTypeAttributesTypes;
  options: {
    label: string;
    value: string;
  };
};
export type ProductTypeAttributesData = {
  id: string;
  attributes: ProductTypeAttributes;
};

export type ProductTypeAttributeValue = {
  name: string;
  value: string;
};

export type ProductTypeAttributes = {
  attributeType: ProductTypeAttributesTypes;
  requirement: ProductTypeAttributesRequirement;
  label: string;
  inputHint?: string;
  inputTip?: string;
  options: {
    label: string;
    value: string;
  };
};

export enum ProductTypeAttributesTypes {
  MULTISELECT = 'multi-select',
  SELECT = 'single-select',
}

export enum ProductTypeAttributesRequirement {
  MANDATORY = 'mandatory',
  OPTIONAL = 'optional',
}
