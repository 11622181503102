import AnalyticsEvent from './analytics-event';
import { ProductProperty, createProductProperty } from '../properties/product-property';
import { BrandProperty, createBrandProperty } from '../properties/brand-property';
import { CartProperty, createCartProperty } from '../properties/cart-property';

export default class ProductRemovedFromCartEvent extends AnalyticsEvent {
  public readonly name = 'Product Removed from Cart';
  public properties: {
    product: ProductProperty;
    brand: BrandProperty;
    cart: CartProperty;
  };

  constructor(product: ProductProperty, brand: BrandProperty, cart: CartProperty) {
    super();
    this.properties = {
      product: createProductProperty(product),
      brand: createBrandProperty(brand),
      cart: createCartProperty(cart),
    };
  }
}
