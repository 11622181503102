<template>
  <div
    v-if="showSocialProof"
    class="social-proof"
  >
    <AkParagraph
      class="social-proof__title"
      size="lg"
      weight="bold"
    >
      {{ content.title }}
    </AkParagraph>

    <section class="social-proof__articles">
      <SocialProofLine :items="topLine" />
      <SocialProofLine :items="bottomLine" />
    </section>

    <section class="social-proof__articles-mobile">
      <SocialProofElement
        v-for="(item, index) in mobileItems"
        :key="index"
        :item="item"
      />

      <AkButton
        link
        @click="seeAllClicked"
      >
        {{ seeMoreItemsText }}
      </AkButton>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';

import SocialProofLine from '@/components/home/social-proof-line.vue';
import SocialProofElement from '@/components/home/social-proof-element.vue';

import useGlobals from '@/composables/use-globals';
import { useI18n } from 'vue-i18n';

export interface SocialProofContent {
  title: string;
  items: SocialProofItem[];
}

interface SocialProofItem {
  href: string;
  image: string;
  description: string;
}

const MAX_MOBILE_ELEMENTS = 3;

export default defineComponent({
  name: 'SocialProof',
  components: {
    SocialProofLine,
    SocialProofElement,
  },
  props: {
    content: {
      type: Object as () => SocialProofContent,
      required: true,
    },
  },
  setup(props) {
    const { lang } = useGlobals();
    const { t } = useI18n();

    const maxMobileElements = ref(MAX_MOBILE_ELEMENTS);

    const isGerman = computed(() => lang === 'de');

    const bottomLine = computed((): SocialProofItem[] => {
      if (props.content) {
        const items = props.content.items;

        return items.slice(Math.ceil(items.length / 2));
      }

      return [];
    });

    const mobileItems = computed((): SocialProofItem[] => {
      if (props.content) {
        return props.content.items.slice(0, maxMobileElements.value);
      }

      return [];
    });

    const topLine = computed((): SocialProofItem[] => {
      if (props.content) {
        const items = props.content.items;

        return items.slice(0, Math.ceil(items.length / 2));
      }

      return [];
    });

    const seeMoreItemsText = computed(() => {
      if (maxMobileElements.value === MAX_MOBILE_ELEMENTS) {
        return t('promoteAndEngage.socialProof.seeMore');
      } else {
        return t('promoteAndEngage.socialProof.seeLess');
      }
    });

    const showSocialProof = computed(() => {
      return isGerman.value && props.content;
    });

    function seeAllClicked(): void {
      maxMobileElements.value =
        maxMobileElements.value === MAX_MOBILE_ELEMENTS ? props.content.items.length : MAX_MOBILE_ELEMENTS;
    }

    return {
      bottomLine,
      mobileItems,
      seeMoreItemsText,
      showSocialProof,
      topLine,
      seeAllClicked,
    };
  },
});
</script>

<style lang="scss">
.social-proof {
  @apply ds-flex-col ds-bg-warm-white ds-p-6 ds-flex;

  &__title {
    @apply ds-self-center ds-mb-7;
  }

  &__articles {
    @apply ds-hidden lg:ds-flex ds-flex-col;
  }

  &__articles-mobile {
    @apply ds-flex lg:ds-hidden ds-flex-col ds-items-center ds-gap-4;
  }
}
</style>
