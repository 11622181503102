<template>
  <div
    v-if="pdd"
    class="pdd"
    :class="{ 'pdd--row': !col, 'pdd--justified-right': right }"
    data-testid="promisedDeliveryDate"
  >
    <AkIcon
      symbol="box-seam"
      size="md"
      class="pdd__icon"
    />
    <div class="pdd__info">
      <div class="pdd__info-title-container">
        <span class="pdd__info-title">{{ t('promisedDeliveryDate.message.orderToday') }}</span>
        <span class="test">{{ t('promisedDeliveryDate.message.forDelivery') }}</span>
      </div>
      <div class="pdd__info-date">
        {{ t('promisedDeliveryDate.message.by') }} {{ date }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, computed, toRefs, unref } from 'vue';
import { formatDate } from '@/services/date-locale';
import useI18n from '@/composables/use-i18n';

defineOptions({
  name: 'PromisedDeliveryDate',
});

const props = defineProps<{
  brandUuid: string;
  col?: boolean;
  right?: boolean;
  promisedDeliveryDate?: string;
}>();

const { brandUuid, col, right, promisedDeliveryDate } = toRefs(props);
const { t } = useI18n();
const promisedDeliveryDates = inject('promisedDeliveryDates');
const pdd = computed(() => {
  return (
    promisedDeliveryDates?.value?.find((pdd) => pdd.attributes?.brandId === unref(brandUuid))?.attributes?.deliveryDate ||
    promisedDeliveryDate.value
  );
});

const date = computed(() => {
  return formatDate(pdd.value, 'd MMMM');
});
</script>

<style lang="scss" scoped>
.pdd {
  $baseClass: &;
  @apply ds-flex;
  &__icon {
    @apply ds-text-info ds-mr-1;
  }
  &__info {
    @apply ds-mt-1.5;
  }
  &__info-title {
    @apply ds-font-semibold;
  }
  &__info-date {
    @apply ds-font-semibold ds-text-left;
    @media screen and (min-width: 1044px) {
      @apply ds-text-right;
    }
  }
  &--justified-right {
    @media screen and (min-width: 391px) {
      @apply ds-justify-end ds-pr-3;
    }
  }
  &--row {
    @apply ds-flex ds-border-b ds-border-neutral-500 ds-border-solid ds-items-start ds-pl-1.5 ds-pb-2.5 ds-mb-2.5;
    @media screen and (min-width: 391px) {
      @apply ds-items-center;
    }
    #{ $baseClass }__icon {
      @apply ds-text-info ds-mr-1.5;
    }
    #{ $baseClass }__info {
      @apply ds-flex ds-flex-col ds-mt-0.5;
      @media screen and (min-width: 391px) {
        @apply ds-flex-row;
      }
    }
    #{ $baseClass }__info-date {
      @apply ds-font-semibold ds-text-left;
    }
    #{ $baseClass }__info-title-container {
      @apply ds-mr-1;
    }
    #{ $baseClass }--justified-right {
      @media screen and (min-width: 391px) {
        @apply ds-justify-end ds-pr-3;
      }
    }
  }
}
</style>
