<template>
  <AkAlert
    :title="title"
    :icon="icon"
    type="discount"
  >
    {{ body }}
  </AkAlert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AkAlert } from '@ankorstore/design-system';

export default defineComponent({
  name: 'BannerSitewideOffer',
  components: { AkAlert },
  props: {
    title: { type: String, default: '' },
    body: { type: String, default: '' },
    icon: { type: String, default: 'patch-check-fill' },
  },
});
</script>
