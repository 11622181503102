export enum BannerName {
  ABTestingSurveyBanner = 'abtesting_survey_banner',
  CampaignsInvitationBanner = 'campaigns_invitation_banner',
  NotFulfillableBanner = 'not_fulfillable_warning',
  TopBar = 'top_bar',
  SubscriptionProgramPill = 'subscription_program_pill',
}

export enum BannerProposition {
  MyNetworkReferralBanner = 'mynetwork_referral_banner',
}
