import Vue, { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Analytics from '@/services/analytics';
import CustomerEmailPrefilled from '@/services/analytics/events/customer-email-prefilled-event';
import { RetailerFormType } from '@/types/retailer-form-type';
import { LiftPromocode } from '@/types/order';
import { globalSelectors } from '@/composables/use-global-selectors/use-global-selectors';

interface LoginPopinData {
  email?: string;
  brandId?: string;
  brandName?: string;
  liftCode?: LiftPromocode;
  isReminded?: boolean;
}

export interface LoginPopinProps {
  view: RetailerFormType;
  data?: LoginPopinData;
  successCallback?: () => void;
  email?: string;
}

type LoginPopinWrapperType = typeof Vue & {
  openModal: (props: LoginPopinProps) => void;
};

export const loginPopinMixin = defineComponent({
  name: 'LoginPopinMixin',
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('shop', ['brandById']),
  },
  methods: {
    async fetchBrandData(brandId: string) {
      await this.$store.dispatch('shop/fetchBrand', {
        brand_id: brandId,
      });
    },
    async openLoginPopin(view: RetailerFormType, data: LoginPopinData = {}, successCallback?: () => void) {
      const dataLocal = data || {};
      const { email = '', isReminded = false } = dataLocal;
      const params = new URLSearchParams(window.location.search);
      const brandId = dataLocal.brandId || params.get('liftBrandId');

      // If form already displayed (e.g. as part of the blade template, do not trigger modal - just redirect to the page)
      if (globalSelectors.global.loginRegisterForm()?.getElement()) {
        if (view === RetailerFormType.Login) {
          window.location.href = '/login';
        } else if (view === RetailerFormType.Register) {
          window.location.href = '/register';
        }
        return;
      }

      if (view === RetailerFormType.Register) {
        await this.$store.dispatch('showSignup');
        if (email?.length > 0) {
          await Analytics.track(new CustomerEmailPrefilled(email));
        }
      }
      let brandName, liftCode;
      if (brandId) {
        if (!this.brandById(brandId)) {
          await this.fetchBrandData(brandId);
        }
        brandName = this.brandById(brandId).name;
        liftCode = this.brandById(brandId).lift_promocode;
      }
      const loginPopinWrapper = (this.$refs.loginPopinWrapper || this.$root.$refs.loginPopinWrapper) as LoginPopinWrapperType;
      if (!loginPopinWrapper) {
        return;
      }
      loginPopinWrapper.openModal({
        view: (view === RetailerFormType.Register && !params.get('step')
          ? RetailerFormType.EmailOnlySignUp
          : view) as RetailerFormType,
        successCallback,
        data: {
          email,
          brandId,
          isReminded,
          brandName,
          liftCode,
        },
      });
    },
  },
});
