import { Facet, FacetGroup, FacetsType } from '../../types/catalog';
import { AttributeDefinition, AttributeDefinitionGroup, AttributeType } from '../../types';
import { getAttributes } from '../api/api';
import { isEnabled } from '@/services/features';

export const getAttributeFacets = async (
  categoryId: string | number,
  lang: string,
  type: 'products' | 'brands'
): Promise<(Facet | FacetGroup)[]> => {
  if (!categoryId || !lang || type === 'brands' || !isEnabled('RET-3075')) {
    return [];
  }

  const attributes = await getAttributes(categoryId.toString(), lang);

  return convertAttributeToFacet(attributes);
};

const convertAttributeType = (type: AttributeType): FacetsType => {
  switch (type) {
    case 'number':
      return FacetsType.numeric;
    case 'boolean':
      return FacetsType.boolean;
    case 'color':
      return isEnabled('RET-2832') ? FacetsType.color : FacetsType.refinement;
    case 'string':
    default:
      return FacetsType.refinement;
  }
};

export const convertAttributeToFacet = (attributes: AttributeDefinition[]): (Facet | FacetGroup)[] => {
  return attributes.map(mapAttributeToFacet);
};

const mapAttribute = (attribute: AttributeDefinition): Facet => {
  const facet: Facet = {
    display: true,
    message: attribute.description,
    name: attribute.slug,
    placeHolder: '',
    title: attribute.name,
    trackingName: attribute.slug,
    type: convertAttributeType(attribute.type),
    attributeType: attribute.type,
    uid: attribute.slug,
  };

  if ('unit' in attribute && attribute.unit !== null) {
    facet.unit = attribute.unit;
  }

  if ('choices' in attribute && attribute.choices.length > 0) {
    facet.choices = attribute.choices;
  }

  return facet;
};

const mapAttributeGroup = (attribute: AttributeDefinitionGroup): FacetGroup => {
  return {
    display: true,
    message: attribute.description,
    name: attribute.slug,
    title: attribute.name,
    uid: attribute.slug,
    facets: attribute.attributes.map(mapAttribute),
  };
};

export const mapAttributeToFacet = (attribute: AttributeDefinition): Facet | FacetGroup => {
  return attribute.type === 'group' ? mapAttributeGroup(attribute) : mapAttribute(attribute);
};
