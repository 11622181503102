<template>
  <div class="special-tile-2-container">
    <a
      class="special-tile-2-background"
      :href="ctaLink"
      :style="backgroundStyle"
      @click="$emit('click')"
    >
      <img
        v-if="foregroundImage"
        :src="foregroundImage.url"
        :alt="foregroundImage.alt"
        :width="foregroundImage.dimensions.width"
        :height="foregroundImage.dimensions.height"
        class="special-tile-2-foreground"
      />
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { SpecialTileGlobal } from '@/modules/promote-engage/promotion-tile/types';

export default defineComponent({
  name: 'SpecialTile2',
  props: {
    tile: {
      type: Object as PropType<SpecialTileGlobal>,
      required: true,
    },
  },
  emits: ['click'],
  setup(props) {
    const ctaLink = computed(() => props.tile?.path);
    const foregroundImage = computed(() => props.tile?.primary?.foreground_image);
    const backgroundStyle = computed(() => ({
      '--bg-image': props.tile?.primary?.background_image ? `url('${props?.tile.primary?.background_image.url}')` : '',
    }));

    return {
      ctaLink,
      foregroundImage,
      backgroundStyle,
    };
  },
});
</script>

<style lang="scss" scoped>
.special-tile-2-container {
  @apply ds-cursor-pointer ds-box-border ds-rounded-md ds-overflow-hidden;
  flex: 0 0 100%;
  max-width: 100%;
  height: 100%;
}

.special-tile-2-background {
  @apply ds-w-full ds-h-full ds-flex ds-items-center ds-justify-center ds-bg-no-repeat ds-bg-cover;
  background-image: var(--bg-image, none);
}

.special-tile-2-foreground {
  @apply ds-object-contain;
}
</style>
