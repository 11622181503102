<template>
  <div class="numeric-range-filter">
    <AkInput
      v-model="currentMin"
      :min="rangeMin"
      :max="rangeMax"
      :placeholder="`${rangeMin}`"
      :disabled="disabled"
      :value="currentMin"
      type="number"
      class="numeric-range-filter__input"
    >
      <template #right>
        {{ unit }}
      </template>
    </AkInput>
    <span>{{ $t('retailer.catalog.filter.numericRange.lbl') }}</span>
    <AkInput
      v-model="currentMax"
      type="number"
      :min="rangeMin"
      :max="rangeMax"
      :placeholder="`${rangeMax}`"
      :disabled="disabled"
      :value="currentMax"
      class="numeric-range-filter__input"
    >
      <template #right>
        {{ unit }}
      </template>
    </AkInput>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { AkInput } from '@ankorstore/design-system';
import type { Range } from './types';

defineOptions({ name: 'NumericRangeFilter' });

const model = defineModel<Range>({ required: true });

const props = withDefaults(
  defineProps<{
    unit: string;
    range: Range;
    disabled?: boolean;
    multiplier?: number;
  }>(),
  {
    disabled: false,
    multiplier: 1,
  }
);

const rangeMin = computed(() => props.range.min / props.multiplier);

const rangeMax = computed(() => props.range.max / props.multiplier);

const currentMin = computed({
  get: () => model.value?.min / props.multiplier,
  set: (value: number) => {
    model.value = { ...model.value, min: value && value !== rangeMax.value ? value * props.multiplier : props.range.min };
  },
});
const currentMax = computed({
  get: () => model.value?.max / props.multiplier,
  set: (value: number) => {
    model.value = { ...model.value, max: value && value !== rangeMin.value ? value * props.multiplier : props.range.max };
  },
});
</script>

<style scoped lang="scss">
.numeric-range-filter {
  display: flex;
  gap: theme('spacing.2');
  align-items: center;
  &__input {
    @apply ds-w-full;
  }
}
</style>
