<template>
  <CustomAisRefinementList
    v-if="filter"
    v-bind="$attrs"
    :attribute="filter.name"
    :transform-items="transformItems"
  >
    <template #default="{ items, refine }">
      <ColorFilter
        :items="items"
        :class="{
          'refinement-list__container': true,
          'refinement-list__container--expanded': true,
        }"
        @item-clicked="(item) => refine(item.value)"
      />
    </template>
  </CustomAisRefinementList>
</template>

<script lang="ts" setup>
import CustomAisRefinementList from '../custom-ais-refinement-list.vue';
import { ColorFilter, Item, ColorItem } from '@bc/discovery/ui/filters';
import { Facet } from '@bc/discovery/domain/catalog';
import { LabelOverrides } from '../../types/label-overrides';
import { overrideFilterLabels } from '../../utils/override-labels';

defineOptions({ name: 'CustomAisColorFilter' });

const props = defineProps<{
  filter: Facet;
  labelOverrides: LabelOverrides;
}>();

const transformItems = (items: Item[]): ColorItem[] =>
  overrideFilterLabels(props.filter, props.labelOverrides, items).map((item) => ({
    ...item,
    color: props.filter.choices?.find((choice) => choice.slug === item.value)?.data?.color,
  }));
</script>
