import { set, get } from 'lodash-es';
import { AsyncScript, AsyncScriptConfigs } from './scripts';

/**
 * The idea of this is to inject third-party scripts as they needed so we can decrease our loading time of the application
 * call the `initScript` with the name of the service you want to init and the callback to call when the third-party API is ready
 * Some third-party scripts provided a way to have our callback called at the right moment when their API is ready
 * Given the case when they don't provide use a way to call the callback we will rely to the onLoad event of the script
 **/
function isScriptReady(serviceName: AsyncScript): boolean {
  return get(window, `scriptStatus.${serviceName}`, false);
}

/**
 * This function will be called when the third-party API is ready
 * We use a global map to keep track which API is ready
 * Another map is used to keep track of the queue
 * The queue is very useful in the case mutlple component using the same third party script so we don't have to worry which one in the page need to be loaded first to inject the script correctly
 **/
function initScript(scriptKey: AsyncScript, callback) {
  function loadScriptCallbacks() {
    set(window, `scriptStatus.${scriptKey}`, true);
    get(window, `scriptCallbacks.${scriptKey}`, []).forEach((scriptCallback) => scriptCallback());
  }
  if (isScriptReady(scriptKey)) {
    callback();
    return;
  }
  /**
   * We create the dynamic script element and insert it to document head
   * Based on our configuration the callback will be handled differently
   */
  const existingScript = document.getElementById(scriptKey);
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = AsyncScriptConfigs[scriptKey].url;
    script.id = scriptKey;
    if (AsyncScriptConfigs[scriptKey].hasGlobalCallback) {
      set(window, `initFunction.${scriptKey}`, () => {
        // Clean up the map when we finished
        loadScriptCallbacks();
        delete window.initFunction[scriptKey];
      });
    } else {
      script.onload = loadScriptCallbacks;
    }
    document.body.appendChild(script);
  }
  const callbackQueue = get(window, `scriptCallbacks.${scriptKey}`, []);
  set(window, `scriptCallbacks.${scriptKey}`, [...callbackQueue, callback]);
}

export { initScript, isScriptReady, AsyncScript };
