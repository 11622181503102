import AnalyticsEvent from './analytics-event';
import { Wishlist } from './segment-ecommerce-types';

export class ProductWishlistCreatedEvent extends AnalyticsEvent {
  public readonly name = 'Product Wishlist Created';

  constructor(public readonly properties: Wishlist) {
    super();
  }
}
