<template>
  <div
    class="case-actions"
    :class="showAgreementCheckbox && !showRevampedVersion ? 'case-actions--with-border' : 'ds-mt-5'"
  >
    <AkCheckbox
      v-if="showAgreementCheckbox"
      class="case-actions__agreed-checkbox"
      value=""
      :checked="agreedWithConditions"
      @change="setAgreementSign($event)"
    >
      {{
        $t(
          `I acknowledge this report will be shared with the brand to improve their customers' experience, and I will no longer be able to edit it.`
        )
      }}
    </AkCheckbox>
    <div
      class="case-actions__buttons"
      :class="nextButtonAsLink ? 'ds-mt-5' : 'ds-mt-6'"
    >
      <AkButton
        v-if="showBackButton"
        :disabled="loading"
        size="lg"
        link
        class="ds-mr-6"
        @click="handlePrevStep"
      >
        {{ $t('Back') }}
      </AkButton>
      <AkButton
        :link="nextButtonAsLink"
        :disabled="disableNextBtn"
        size="lg"
        :loading="loading"
        data-testid="orderRejectionFormNextBtn"
        @click="handleNextStep"
      >
        {{ nextBtnLabel }}
      </AkButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import { CaseReasonStep } from '@/types/order-retailer-rejection';
import { OrderIssueClickedAction } from '@/services/analytics/properties/order-issue-property';
import { orderIssueAnalyticsMixin } from '@/components/account/orders/order/order-retailer-reject/mixins/order-issue-analytics';

export default defineComponent({
  name: 'CaseActions',
  mixins: [orderIssueAnalyticsMixin],
  props: {
    nextStepReady: {
      type: Boolean,
      required: true,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['set-loader', 'next-button-click', 'submit'],
  data() {
    return {
      agreedWithConditions: false,
    };
  },
  computed: {
    ...mapGetters('orderRetailerRejection', [
      'showAgreementCheckbox',
      'showRevampedVersion',
      'showNewOrderIssueFlow',
      'getCurrentStep',
      'getCaseReason',
    ]),
    ...mapGetters('legacyAccount', [
      'issueCaseAllowedToBeSendToBrand',
    ]),
    ...mapState('legacyAccount', {
      order: 'orderDetail',
    }),
    nextBtnLabel(): string {
      switch (this.getCurrentStep.key) {
        case CaseReasonStep.Summary:
          if (this.showNewOrderIssueFlow) {
            return this.$t('Send to Customer Service');
          } else if (this.issueCaseAllowedToBeSendToBrand(this.getCaseReason)) {
            return this.$t('orderIssue.rejectionForm.submit.cta');
          }

          return this.$t('Send to Customer Service');
        case CaseReasonStep.TrackWithLink:
          return this.$t('My issue is not resolved');
        case CaseReasonStep.ContactBrand:
          return this.$t('My issue is not resolved');
        default:
          return this.$t('Next');
      }
    },
    nextButtonAsLink(): boolean {
      return this.getCurrentStep.key === CaseReasonStep.TrackWithLink || this.getCurrentStep.key === CaseReasonStep.ContactBrand;
    },
    disableNextBtn(): boolean {
      if (!this.showAgreementCheckbox) {
        return !this.nextStepReady;
      }

      return !(this.nextStepReady && this.agreedWithConditions);
    },
  },
  methods: {
    ...mapActions('orderRetailerRejection', ['setStep', 'prevStep', 'nextStep', 'resetProductsFiles']),
    handleNextStep(): void {
      const action =
        this.getCurrentStep.key === CaseReasonStep.Summary ? OrderIssueClickedAction.SUBMIT : OrderIssueClickedAction.NEXT;

      this.$emit('next-button-click');
      this.trackClickedAction(action);
      this.nextStep({
        submitCallback: async () => {
          this.setLoader(true);
          this.resetProductsFiles();
          this.$emit('submit');

          // @ts-ignore
          this.$store._vm.$on('loading', (loading) => {
            this.setLoader(loading);
          });
        },
      });
    },
    setLoader(value: boolean): void {
      this.loading = value;
      this.$emit('set-loader', value);
    },
    handlePrevStep(): void {
      this.trackClickedAction(OrderIssueClickedAction.BACK);
      this.prevStep();
    },
    setAgreementSign(value: boolean): void {
      this.agreedWithConditions = value;
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.case-actions {
  @apply ds-flex ds-flex-col;

  &__buttons {
    @apply ds-flex ds-justify-center ds-w-full;
  }

  &--with-border {
    @apply ds-border-t ds-border-solid ds-border-neutral-600 ds-pt-5;

    @include media-breakpoint-down(lg) {
      @apply ds-mt-2;
    }
  }
}

:deep(.case-actions__agreed-checkbox.ak-checkbox) {
  @apply ds-text-base;
}

:deep(.case-actions__agreed-checkbox .ak-checkbox__sidelabel) {
  @apply ds-top-1;
}

:deep(.case-actions .ak-checkbox input:checked ~ .ak-checkbox__sidelabel) {
  @apply ds-bg-info;
}
</style>
