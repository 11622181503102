import AnalyticsEvent from './analytics-event';

export default class CustomerEmailPrefilled extends AnalyticsEvent {
  public readonly name = 'Customer Email Prefilled';
  public properties: Record<string, string>;

  constructor(email) {
    super();
    this.properties = {
      email: email,
    };
  }
}
