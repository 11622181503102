<template>
  <div
    ref="wrapper"
    class="ds-relative"
  >
    <div
      v-if="!isMobile && canPrev"
      data-testid="recommendationsCarouselNavPrev"
      :class="prevNavClasses"
      @click="scrollToPreviousPageDebounced"
    >
      <AkIcon
        symbol="arrow-left"
        size="md"
      />
    </div>
    <div
      v-if="!isMobile && canNext"
      data-testid="recommendationsCarouselNavNext"
      :class="nextNavClasses"
      @click="scrollToNextPageDebounced"
    >
      <AkIcon
        symbol="arrow-right"
        size="md"
      />
    </div>
    <div
      ref="scroller"
      :class="{
        scroller: true,
        'ds-overflow-hidden': isDesktop,
        'ds-overflow-y-visible ds-overflow-x-scroll': !isDesktop,
      }"
      style="scroll-behavior: smooth"
    >
      <div
        ref="slider"
        data-testid="recommendationsCarouselSlider"
        class="slider ds-inline-flex ds-w-max"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { AkIcon } from '@ankorstore/design-system';
import { CLIENT_DEVICE, withBreakpoints } from '@/modules/design-system-candidates';
import { debounce } from 'lodash-es';
import {
  DEBOUNCE_TIME,
  ITEM_PREVIEW_RATIO,
  ITEM_SPACING,
  ITEM_SPACING_MOBILE,
  MAX_ITEM_PREVIEW_RATIO,
  MAX_ITEMS,
} from './constants';
import { RecommendationAlgoType } from '@/types/analytics/recommendation-tracking';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'RecommendationsCarousel',
  components: { AkIcon },
  mixins: [withBreakpoints],
  props: {
    title: {
      type: String,
      default: null,
    },
    algorithm: {
      type: Number as PropType<RecommendationAlgoType>,
      default: null,
    },
  },
  data() {
    return {
      items: [],
      currentItem: 0,
      targetItem: 0,
      currentPage: 0,
      targetPage: 0,
      itemWidth: 0,
      sliderPadding: 0,
    };
  },
  computed: {
    wrapper(): HTMLDivElement {
      return this.$refs.wrapper as HTMLDivElement;
    },
    scroller(): HTMLDivElement {
      return this.$refs.scroller as HTMLDivElement;
    },
    slider(): HTMLDivElement {
      return this.$refs.slider as HTMLDivElement;
    },
    onScrollDebounced(): () => void {
      return debounce(this.onScroll, DEBOUNCE_TIME);
    },
    scrollToPreviousPageDebounced(): () => void {
      return debounce(this.scrollToPreviousPage, DEBOUNCE_TIME);
    },
    scrollToNextPageDebounced(): () => void {
      return debounce(this.scrollToNextPage, DEBOUNCE_TIME);
    },
    maxItemsPerPage(): number {
      return this.algorithm === RecommendationAlgoType.productsCategories
        ? MAX_ITEMS.categories[this.currentDevice]
        : MAX_ITEMS.default[this.currentDevice];
    },
    previewRatio(): number {
      return Math.min(ITEM_PREVIEW_RATIO[this.currentDevice], MAX_ITEM_PREVIEW_RATIO);
    },
    previewWidth(): number {
      return Math.floor(this.itemWidth * this.previewRatio);
    },
    totalItems(): number {
      return this.items.length;
    },
    lastPage(): number {
      return Math.floor(this.totalItems / this.maxItemsPerPage);
    },
    baseNavClasses(): string[] {
      return [
        'arrow',
        'ds-bg-white ds-cursor-pointer ds-absolute ds-z-10',
        'ds-flex ds-items-center ds-justify-center',
        'ds-transition ds-duration-300 ds-ease-out',
        'ds-text-neutral-800 ds-shadow-lg',
      ];
    },
    prevNavClasses(): string[] {
      return [...this.baseNavClasses, 'ds-left-0'];
    },
    nextNavClasses(): string[] {
      return [...this.baseNavClasses, 'ds-right-0'];
    },
    canPrev(): boolean {
      return this.currentItem > 0;
    },
    canNext(): boolean {
      return this.currentItem < this.totalItems - this.maxItemsPerPage;
    },
  },
  mounted() {
    this.items = Array.from(this.slider.querySelectorAll(':scope > *'));
    window.addEventListener('resize', this.initialize);
    this.scroller.addEventListener('scroll', this.onScrollDebounced);
    this.initialize();
  },
  unmounted() {
    window.removeEventListener('resize', this.initialize);
    this.scroller.removeEventListener('scroll', this.onScrollDebounced);
  },
  methods: {
    initialize() {
      this.itemWidth = Math.floor(this.wrapper.clientWidth / (this.maxItemsPerPage + this.previewRatio));
      this.items.forEach((item, idx, array) => {
        let itemWidth = this.itemWidth;
        if (idx === array.length - 1) {
          itemWidth -= ITEM_SPACING;
          item.style.paddingRight = '0px';
        } else {
          item.style.paddingRight =
            this.currentDevice === CLIENT_DEVICE.mobile ? `${ITEM_SPACING_MOBILE}px` : `${ITEM_SPACING}px`;
        }
        item.style.width = item.style.minWidth = `${itemWidth}px`;
      });
      this.scrollToItem(this.currentItem);
    },
    onScroll() {
      const maxPreviewSize = this.itemWidth * MAX_ITEM_PREVIEW_RATIO;
      const samplePosition = this.scroller.scrollLeft + maxPreviewSize;
      this.currentItem = Math.floor(samplePosition / this.itemWidth);
    },
    getPagePreviewOffset(page): number {
      const pageInRange = Math.max(0, Math.min(page, this.lastPage));
      if (pageInRange === 0) {
        return 0;
      } else if (pageInRange === this.lastPage) {
        return this.previewWidth;
      } else {
        return Math.floor(this.previewWidth / 2);
      }
    },
    scrollToItem(item: number) {
      let targetItem = Math.max(0, item);
      const remainingItems = this.totalItems - item;
      if (this.maxItemsPerPage > remainingItems) {
        targetItem -= this.maxItemsPerPage - remainingItems;
      }
      this.targetItem = targetItem;
      if (this.targetItem > this.currentItem) {
        this.targetPage = this.currentPage + 1;
      } else if (this.targetItem < this.currentItem) {
        this.targetPage = this.currentPage - 1;
      }
      this.scroller.scrollLeft = Math.floor(targetItem * this.itemWidth) - this.getPagePreviewOffset(this.targetPage);
      this.currentPage = this.targetPage;
    },
    scrollToPreviousPage() {
      if (this.canPrev) {
        this.scrollToItem(this.currentItem - this.maxItemsPerPage);
      }
    },
    scrollToNextPage() {
      if (this.canNext) {
        this.scrollToItem(this.currentItem + this.maxItemsPerPage);
      }
    },
  },
});
</script>

<style scoped lang="scss">
@use 'sass:math';
.arrow {
  $width: 6rem;
  $height: 5rem;
  top: calc(50% - (#{$height} / 2));
  width: $width;
  height: $height;
  & > i {
    font-size: math.div($width, 3);
  }
}
.slider {
  @apply ds-transition-opacity;
  &:deep(> *:hover .image__wrapper) {
    @apply ds-opacity-75;
  }
  &:deep(> * > *) {
    @apply ds-mb-0 ds-h-full ds-w-full;
  }
  &:deep(> * .image__wrapper) {
    min-height: unset !important;
  }
  &:deep(> * .brand) {
    @apply ds-h-full;
  }

  &:deep(> * .brand figure) {
    @apply ds-h-full ds-flex ds-flex-col;
  }

  &:deep(> * .brand figure figcaption) {
    @apply ds-flex-1;
  }
}
</style>
