<template>
  <div class="brand-description">
    <div
      class="brand-title ds-flex ds-flex-wrap"
      data-testid="brandTitleTxt"
    >
      <h1>{{ brand.name }}</h1>

      <div class="badge-list-container">
        <BadgesList
          size="sm"
          :badge-infos="discounts"
        />
        <AkBadge
          v-if="shouldShowFirstOrderBadge"
          data-testid="firstOrderBadge"
          color="blue-alt"
          symbol="ankorstore-logo"
          :content="$t('badge.firstOrder.label')"
        />
      </div>
    </div>

    <template v-if="haveReviewsAndStatistics">
      <div
        class="rating-block"
        data-testid="brand-rating"
      >
        <div class="rating-block__stars">
          <a @click="emitRatingsClicked">
            <AkRating
              :model-value="overallRating"
              :read-only="true"
            />
          </a>
        </div>
        (<a
          class="rating-block__amount"
          @click="emitRatingsClicked"
        >{{ totalReviewCount }}</a>)
      </div>
    </template>

    <DiscountInfo
      v-if="isEnabled('RPE-124')"
      class="ds-w-full sm:ds-w-3/5 ds-my-4"
      :brand="brand"
    />

    <PersonalisedDiscountBanner
      v-if="userIsRetailer && discount"
      class="personalised-discount"
      :discount="discount"
    />
    <div
      class="brand-infos"
      :class="{ 'brand-infos--retailer': userIsRetailer && !!ankorstorePlus }"
    >
      <div
        class="brand-properties"
        data-testid="brand-properties"
      >
        <BrandPropertiesBlock
          :long-distance-shipping-fee="longDistanceShippingFee"
          :brand="brand"
        />

        <BannerSitewideOffer
          v-if="sitewideOfferContent['brand-page-offer-banner']"
          class="ds-mb-3"
          v-bind="sitewideOfferContent['brand-page-offer-banner']"
        />
      </div>

      <BrandKeyResume :brand="brand" />

      <div
        v-if="brand.story"
        class="brand-story"
        :class="{ 'show-all-text': brand.story.length <= 620 }"
      >
        <div
          v-if="brand.story_auto_translate"
          class="description_translate"
        >
          <span class="ak-translation">
            <AkIcon symbol="globe" />
            <a @click="showOriginalText = !showOriginalText">
              <span class="ak-translation__label"><template v-if="!showOriginalText">{{ $t('See in original language') }} </template><template v-else>{{ $t('See translation') }}</template>
              </span>
            </a>
          </span>
        </div>
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="!showOriginalText"
            key="Story"
          >
            <RichDescription
              v-if="isEnabled('bxs-322')"
              :text="brand.story"
            />
            <Nl2Br
              v-else
              tag="div"
              :text="brand.story"
              class="story-text"
            />
          </div>
          <div
            v-else-if="showOriginalText"
            key="originalStory"
          >
            <RichDescription
              v-if="isEnabled('bxs-322')"
              :text="brand.story_original_content"
            />
            <Nl2Br
              v-else
              tag="div"
              :text="brand.story_original_content"
              class="story-text"
            />
          </div>
        </transition>
        <ReadMore :brand="brand">
          <a class="button-more">{{ $t('Read more') }}</a>
        </ReadMore>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed, ref, onMounted } from 'vue';
import { AkIcon } from '@ankorstore/design-system';

import { sitewideOfferMixin } from '@/mixins/sitewide-offer';

import DiscountInfo from '@/modules/promote-engage/discount-info/discount-info.vue';
import PersonalisedDiscountBanner from '@/modules/carts/brand-banners/personalised-discount-banner.vue';
import BrandPropertiesBlock from '@/components/brand/brand-properties-block.vue';
import BannerSitewideOffer from '@/components/banners/banner-sitewide-offer.vue';
import RichDescription from '@/modules/rich-description/rich-description.vue';
import ReadMore from '@/components/read-more.vue';
import BadgesList from '@/modules/promote-engage/touchpoints/badges-list.vue';
import AkRating from '@/components/review/AkRating/AkRating.vue';
import { ReviewCriteria, ReviewRatings } from '@/components/review/types';
import { IBrandPageBrand } from '@/components/brand/brand.vue';
import Nl2Br from '@/components/global/nl2br.vue';
import BrandKeyResume from '@/components/brand/brand-key-resume.vue';

import { isEnabled, FeatureFlag } from '@/services/features';
import { getPersonalDiscount } from '@/services/api/brand-discount';
import { BadgeConfiguration } from '@/modules/promote-engage/touchpoints/services/badges-list.service';

import { BrandDiscountContext } from '@/types/api/brand-discount';
import { ShippingFee } from '@/types/shipping/shipping-fees';
import { BillingItemType } from '@/types/billing-item';

import { useStore } from '@/composables/use-store';

export default defineComponent({
  name: 'BrandDescription',
  components: {
    AkIcon,
    AkRating,
    BadgesList,
    BannerSitewideOffer,
    BrandKeyResume,
    BrandPropertiesBlock,
    DiscountInfo,
    Nl2Br,
    PersonalisedDiscountBanner,
    RichDescription,
    ReadMore,
  },
  mixins: [sitewideOfferMixin],
  props: {
    brand: {
      type: Object as PropType<IBrandPageBrand>,
      required: true,
    },
    totalReviewCount: {
      type: Number,
      default: 0,
    },
    haveReviewsAndStatistics: {
      type: Boolean,
      required: true,
    },
    averageReview: {
      type: Object as PropType<ReviewRatings>,
      required: false,
      default: null,
    },
  },
  emits: ['ratingsClicked'],
  setup(props, { emit }) {
    const store = useStore();

    const appliedDiscount = computed(() => store.getters['offers/appliedDiscount']);

    const userIsRetailer = computed(() => store.getters.userIsRetailer);

    const isUserAuthenticated = computed(() => store.getters.isUserAuthenticated);

    const retailer = computed(() => store.getters.retailer);

    const ankorstorePlus = computed(() => store.getters['offers/ankorstorePlus']);

    const discount = ref(null);

    const showOriginalText = ref(false);

    const overallRating = computed((): number => {
      return parseFloat(props.averageReview[ReviewCriteria.OVERALL]?.toFixed(1));
    });

    const shouldShowFirstOrderBadge = computed((): boolean => {
      return (
        isEnabled(FeatureFlag.EnableNewBusinessModel) &&
        props.brand.is_eligible_for_discovery_free_shipping &&
        !ankorstorePlus.value?.optIn
      );
    });

    const specialDiscount = computed((): number => {
      return props.brand.event_special_discount;
    });

    const discounts = computed((): BadgeConfiguration[] => {
      const discounts = [];

      if (props.brand.event_special_discount) {
        discounts.push(props.brand.event_special_discount);
      }

      if (appliedDiscount.value) {
        discounts.push(appliedDiscount.value.percentage);
      }

      return [
        {
          priority: 1,
          discounts,
          color: 'purple-full',
        },
      ];
    });

    function emitRatingsClicked() {
      emit('ratingsClicked');
    }

    const longDistanceShippingFee = computed((): ShippingFee => {
      return props.brand.applicable_shipping_fees?.find(({ name }) => name === BillingItemType.longDistanceFee);
    });

    onMounted(async () => {
      if (isUserAuthenticated.value) {
        await getPersonalDiscount(BrandDiscountContext.brandPage, [props.brand.id]).then((personalDiscounts) => {
          if (personalDiscounts && personalDiscounts.length > 0) {
            discount.value = personalDiscounts[0];
          }
        });
      }
    });

    return {
      appliedDiscount,
      ankorstorePlus,
      discount,
      discounts,
      isUserAuthenticated,
      longDistanceShippingFee,
      overallRating,
      retailer,
      specialDiscount,
      shouldShowFirstOrderBadge,
      showOriginalText,
      userIsRetailer,
      emitRatingsClicked,
      isEnabled,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';

.brand-description {
  .rating-block {
    @apply ds-flex ds-items-center;

    &__stars {
      @apply ds-cursor-pointer ds-mr-2;

      label {
        @apply ds-cursor-pointer;
      }
    }

    &__amount {
      @apply ds-cursor-pointer ds-underline;
      margin-top: 0.15rem;
    }
  }

  &__reliable-container {
    @apply ds-flex md:ds-flex-col ds-justify-center ds-items-center;
  }

  &__reliable-icon {
    @apply ds-bg-primary ds-text-accent-alt ds-rounded-xl ds-flex ds-justify-center ds-items-center;
    width: 24px;
    height: 24px;
  }
}

.discount-bf {
  @apply ds-my-3;
  max-width: 800px;
}

.personalised-discount {
  @apply ds-my-4;
}

.description_translate {
  .ak-translation {
    @apply ds-text-primary ds-flex ds-items-center ds-gap-1 ds-mb-2;
    &__label {
      @apply ds-text-xs;
    }
    i {
      font-size: 20px;
    }
  }

  a {
    @apply ds-underline ds-cursor-pointer;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.brand-tags {
  &__label {
    @apply ds-text-center;
  }
}

.brand-bpg {
  @apply ds-flex ds-flex-col ds-items-center ds-gap-1;
  &__label {
    @apply ds-mb-0;
  }
}

.ak-tags {
  @apply ds-mt-3;
  font-size: 0;
  order: 1;

  &:before {
    content: none !important;
  }

  .ak-tag {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.badge-list-container {
  @apply ds-flex ds-gap-1 ds-flex-wrap;
}
</style>
