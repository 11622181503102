<template>
  <div class="lift-page-products">
    <RecommendationsWidget
      v-if="products.length"
      class="ds-mt-4 ds-overflow-x-hidden"
      :label="t('Explore our products')"
      :algorithm="1"
      :items="products"
      :item-number="productListNumber"
      :min-number-records-to-show="1"
      widget-proposition="Explore our products"
    />
    <div class="ds-flex ds-flex-col ds-relative md:ds-pt-0 ds-pt-2 ds-pl-2 ds-mb-2">
      <AkParagraph class="ds-font-semibold md:ds-mt-6">
        {{
          t('Ships orders within {minimum} to {maximum} business days', {
            minimum: shippingLiftTime.minimum,
            maximum: shippingLiftTime.maximum,
          })
        }}
      </AkParagraph>
      <AkParagraph>{{ t('Minimum orders of {0}', formatPrice(minimumOrderAmount, '$0')) }}</AkParagraph>
      <AkParagraph
        v-if="shouldShowFreeShipping"
        data-testid="nbmFreeShippingBulletPoint"
      >
        {{ $t('liftProducts.bulletPoint.freeShipping') }}
      </AkParagraph>
      <AkParagraph v-else>
        {{ t('Free shipping with a {0} minimum checkout', formatPrice(freeShippingMinimumAmount, '$0')) }}
      </AkParagraph>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed, onMounted } from 'vue';

import { RecommendationsWidget } from '@bc/discovery';

import { isEnabled } from '@/services/features';
import { formatPrice } from '@/utilities/price';
import { useI18n } from 'vue-i18n';

import { Product } from '@/types/api/product';
import { FeatureFlag } from '@/services/features';

export default defineComponent({
  name: 'LiftProducts',
  components: {
    RecommendationsWidget,
  },
  props: {
    products: {
      type: Array as PropType<Product[]>,
      default: () => [],
    },
    minimumOrderAmount: {
      type: Object,
      required: true,
    },
    shippingLiftTime: {
      type: Object as () => { minimum: number; maximum: number },
      required: true,
    },
    freeShippingMinimumAmount: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const productListNumber = computed((): number => {
      return window.innerWidth < 767 ? 1 : 3;
    });

    const shouldShowFreeShipping = computed(() => {
      return isEnabled(FeatureFlag.EnableNewBusinessModel);
    });

    onMounted(() => {
      props.products.forEach((product) => {
        product.link += `?liftBrandId=${product.brand.id}`;
      });
    });

    return {
      productListNumber,
      shouldShowFreeShipping,
      formatPrice,
      t,
    };
  },
});
</script>
<style lang="scss" scoped>
.lift-page-products {
  @apply ds-max-w-6xl md:ds-mx-auto ds-px-4 sm:ds-px-8 ds-py-6 ds-bg-warm-white ds-mb-9 ds-mx-4 ds-shadow-lg;
}
</style>
<style lang="scss">
.lift-page-products {
  .recommendations-widget__product-snippet.product-snippet .product {
    @apply ds-bg-white;
  }
}
</style>
