import { saveEoriNumber } from '@/services/api/me';
import { Mutation } from '@/store/mutation-types';
import { Business } from '@/types/api/business';
import { VatInfo, VatPatch } from '@/types/vat-info';
import { patchVatInfo, sendVatNumber } from '@/services/api/vat-info';

export enum ActionName {
  UpdateEoriNumber = 'updateEoriNumber',
  UpdateVatInfo = 'updateVatInfo',
  SetVatNumber = 'setVatNumber',
}
export const actions = {
  [ActionName.UpdateEoriNumber]: async ({ commit }, eoriNumber: string): Promise<Business> => {
    const business = await saveEoriNumber(eoriNumber?.toUpperCase());

    commit(Mutation.UPDATE_BUSINESS, { business }, { root: true });

    return business;
  },
  [ActionName.UpdateVatInfo]: async ({ commit }, vatInfo: VatInfo): Promise<any> => {
    const vatInfoResult = await patchVatInfo(vatInfo);
    vatInfo.vatInfoUpdated = vatInfoResult?.data?.data.vat_info_updated;
    commit(Mutation.UPDATE_VAT_INFO, vatInfo, {
      root: true,
    });

    return vatInfoResult;
  },
  [ActionName.SetVatNumber]: async ({ commit }, vatNumber: VatPatch): Promise<any> => {
    const vatNumberResult = await sendVatNumber(vatNumber);

    commit(Mutation.UPDATE_VAT_NUMBER, vatNumber, {
      root: true,
    });

    return vatNumberResult;
  },
};
