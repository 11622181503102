import {
  PaymentServiceCustomerType,
  PaymentServiceInitPayload,
  PaymentServiceProviderCustomer,
  SetupIntent,
} from '@/types/fulfillment-invoice';
import { get, patch, post } from '@/services/fulfillment/utilities';

/**
 * Get information of payment service customer
 * A payment service customer is a resource represent the information of a brand / customer in our bounded with a context of a payment service provider
 *
 * @returns the current customer information, null if brand haven't initialized their setup process
 *
 * @throws {JSONApiError}
 */
export async function getPaymentServiceCustomer(
  aksCustomerId: string,
  aksCustomerType: PaymentServiceCustomerType = PaymentServiceCustomerType.BRAND
): Promise<PaymentServiceProviderCustomer | null> {
  return get<PaymentServiceProviderCustomer>(
    `/api/internal/v1/invoicing/payment-service-provider-customers/${aksCustomerId}?aksCustomerType=${aksCustomerType}`
  );
}

export function initSEPASetup(): Promise<PaymentServiceProviderCustomer> {
  return post<PaymentServiceInitPayload, PaymentServiceProviderCustomer>(
    '/api/internal/v1/invoicing/payment-service-provider-customers',
    {}
  );
}

export function setMandatePending(aksCustomerId: string): Promise<SetupIntent> {
  return patch(`/api/internal/v1/invoicing/payment-service-provider-customers/${aksCustomerId}`, {});
}
