<template>
  <AkDrawer
    :is-open="opened"
    :overlay="false"
    right
    :width="fullWidth ? '100%' : '40%'"
    class="filters-drawer"
    @update:open="open"
    @update:close="close"
  >
    <div class="filters-drawer__main">
      <AkHeading4 class="ds-mb-2">
        {{ $t('brands.contactManagement.filtersDrawer.title') }}
      </AkHeading4>
      <AkParagraph size="sm">
        {{ $t('brands.contactManagement.filtersDrawer.subTitle') }}
      </AkParagraph>
      <div class="ds-mt-5">
        <ActiveFilters />
      </div>
      <template
        v-for="item in filterComponents"
        :key="item.id"
      >
        <div
          v-if="item.values || item.attributes || item.type === WidgetTypes.ToggleWidget"
          class="filters-drawer__section"
        >
          <component
            :is="returnComponent(item.type)"
            :ref="item.type"
            :filter="item"
            :model-value="selectedFilters[item.attributeName]"
            @update:model-value="setSelectedFilter(item.attributeName, $event)"
          />
        </div>
      </template>
    </div>
    <div
      class="filters-drawer__bottom"
    >
      <AkButton
        size="md"
        @click="submitFilters"
      >
        {{ $t('brands.contactManagement.filtersDrawer.apply') }}
      </AkButton>
    </div>
  </AkDrawer>
</template>

<script lang="ts">
import { defineComponent, ref, computed, type ComputedRef, type Component } from 'vue';
import { useStore } from '@/composables/use-store';
import { useBreakpoints } from '@/modules/design-system-candidates';
import { debounce } from 'lodash-es';
import { AkParagraph, AkHeading4, AkButton, AkDrawer } from '@ankorstore/design-system';
import { type ContactFilter, ContactFilterType } from '../../types/api.types';
import ActiveFilters from '../active-filters/active-filters.vue';
import RangeWidget from './range-widget.vue';
import DropdownWidget from './dropdown-widget.vue';
import MultiSelectWidget from './multi-select-widget.vue';
import ListWidget from './list-widget.vue';
import ToggleWidget from './toggle-widget.vue';
import Analytics from '@/services/analytics';
import { BrandClickedEvent } from '@/services/analytics/events/brand-clicked.event';
import { BrandClickAction } from '@/types/analytics/brand-click-event';
import { WidgetTypes } from '../../types/filter.types';

export default defineComponent({
  name: 'FiltersDrawer',
  components: {
    RangeWidget,
    DropdownWidget,
    MultiSelectWidget,
    ListWidget,
    ToggleWidget,
    ActiveFilters,
    AkParagraph,
    AkHeading4,
    AkButton,
    AkDrawer,
  },
  setup() {
    const { isMobile, isXlMobile } = useBreakpoints();
    const store = useStore();

    const opened = ref(false);
    const filterComponents = computed(() => store.getters['account/contactManagement/getFiltersComponents']);

    const fullWidth = computed(() => isXlMobile.value || isMobile.value);
    const selectedFilters = computed(() => store.getters['account/contactManagement/getFiltersState']);
    const selectedFiltersStateForAnalytics = computed(() => store.getters['account/contactManagement/getFiltersStateForAnalytics']);
    const brandId: ComputedRef<number> = computed(() => store.getters['brand']?.id);
    const DEBOUNCE_TIME = 350;

    const close = () => {
      opened.value = false;
    };

const returnComponent = (component: WidgetTypes): Component | null => {

  switch (component) {
    case WidgetTypes.RangeWidget:
      return RangeWidget;
    case WidgetTypes.DropdownWidget:
      return DropdownWidget;
    case WidgetTypes.MultiSelectWidget:
      return MultiSelectWidget;
    case WidgetTypes.ListWidget:
      return ListWidget;
    case WidgetTypes.ToggleWidget:
      return ToggleWidget;
  }
  return null;
}

    const open = () => {
      opened.value = true;
    };

    const setSelectedFilter = (id: string, value: ContactFilter) => {
      if (value.type === ContactFilterType.Range && !value.min && !value.exclusiveMax) {
        updateFilter(id, null);
        return;
      }
      if (value.type === ContactFilterType.Value && !value.value?.length) {
        updateFilter(id, null);
        return;
      }
      if (value.type === ContactFilterType.Boolean && value.value === undefined) {
        updateFilter(id, null);
        return;
      }
      updateFilter(id, value);
    };

    const updateFilter = debounce((id, value) => {
      Analytics.track(
        new BrandClickedEvent({
          brand_id: brandId.value,
          action: BrandClickAction.BrandApplyFilters,
          value:  selectedFiltersStateForAnalytics.value,
        })
      );
      store.dispatch('account/contactManagement/setFilter', [id, value]);
    }, DEBOUNCE_TIME);

    const submitFilters = async () => {
      close();
    };

    return { open, close, opened, filterComponents, selectedFilters, setSelectedFilter, submitFilters, fullWidth, returnComponent, WidgetTypes, selectedFiltersStateForAnalytics };
  },
});
</script>

<style>
.filters-drawer {
  z-index: 999!important;
}

.ak-drawer__content {
  @apply ds-flex ds-flex-col ds-h-screen ds-justify-between;
}

.filters-drawer__main {
  @apply ds-p-6 ds-mb-auto;
}

.filters-drawer__section {
  @apply ds-pt-3 ds-mt-5 ds-border-solid ds-border-neutral-300 ds-border-t;
}

.filters-drawer__bottom {
  @apply ds-text-right ds-p-5;
}
</style>
