<template>
  <div
    v-if="isEligibleForAnkorstorePlus"
    class="banner-aks"
    data-testid="bannerAnkorstorePlus"
  >
    <AkHeroBanner
      :title="title"
      :description="t('thankYou.bannerAnkorstorePlus.description')"
      :sub-label="t('thankYou.bannerAnkorstorePlus.subLabel')"
      :btn-label="t('thankYou.bannerAnkorstorePlus.btnLabel')"
      background-url="/images/thank-you/aks-banner-thank-you.jpg"
      @click="redirectToAksSignup"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import { AkHeroBanner } from '@ankorstore/design-system';
import { OfferProgramEligibility } from '@/modules/promote-engage/services/offer';

import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';

import useCurrency from '@/composables/use-currency';
import usePrice from '@/composables/use-price';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'BannerAnkorstorePlus',
  components: {
    AkHeroBanner,
  },
  props: {
    shippingFees: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const { $currency } = useCurrency();

    const { formatPrice } = usePrice();

    const store = useStore();

    const ankorstorePlus = computed((): OfferProgramEligibility | null => store.getters['offers/ankorstorePlus']);

    const title = computed((): string => {
      return t('thankYou.bannerAnkorstorePlus.title', {
        amount: formatPrice({ amount: props.shippingFees, currency: $currency.value }),
      });
    });

    const isEligibleForAnkorstorePlus = computed((): boolean => {
      return !!ankorstorePlus.value && !ankorstorePlus.value.optIn && ankorstorePlus.value.freeTrialEligible;
    });

    function redirectToAksSignup(): void {
      tracking();
      window.location.href = '/ankorstore-plus-signup';
    }

    function tracking(): void {
      Analytics.track(
        new UserClick({
          component: 'banner_ankorstore_plus',
          action: 'click_join_plus_thank_you_page',
        })
      );
    }

    return {
      isEligibleForAnkorstorePlus,
      title,
      redirectToAksSignup,
      t,
    };
  },
});
</script>
<style lang="scss" scoped>
.banner-aks {
  @apply ds-container ds-mx-auto;
}
</style>
