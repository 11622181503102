import AnalyticsEvent from './analytics-event';
import { Product } from './segment-ecommerce-types';

export class ProductViewedEvent extends AnalyticsEvent {
  public readonly name = 'Product Viewed';

  constructor(public readonly properties: Product) {
    super();
  }
}

export class ProductsViewedEvent extends AnalyticsEvent {
  public readonly name = 'Products Viewed';
  public properties: { products: Product[] };

  constructor({ properties }: { properties: { products: Product[] } }) {
    super();
    this.properties = properties;
  }
}
