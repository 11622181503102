export enum BpgIssueType {
  public = 'public_price_report',
  private = 'private_price_report',
}

export enum BpgIssueStatus {
  open = 'open',
  resolved = 'resolved',
  closed = 'closed',
}

export enum BpgIssueResolution {
  pending = 'pending',
  fixed = 'fixed',
  wontfix = 'wontfix',
  cannotfix = 'cannotfix',
}

export enum BpgCloseOwner {
  brand = 'brand',
  ankorstore = 'ankorstore',
}

export enum BpgPublicPriceReportSource {
  brandWebsite = 'brand_website',
  brandCatalog = 'brand_catalog',
  orderchamp = 'orderchamp',
  faire = 'faire',
  other = 'other',
}

export interface BpgFields {
  description: string;
  source?: BpgPublicPriceReportSource;
  link?: string;
}

export interface BpgReportIssueRequest {
  type: BpgIssueType;
  fields: BpgFields;
  productVariantId: number;
  attachments?: string[];
}

export interface BpgReportIssueResponse {
  id: string;
  type: string;
  attributes: BpgReportAttribute;
  relationships: BpgRelationShips;
}

export interface BpgReportAttribute {
  type: BpgIssueType;
  createdAt: Date;
  closedAt: Date;
  closeBy: BpgCloseOwner | null;
  status: BpgIssueStatus;
  resolution: BpgIssueResolution;
  resolvedByBrand: boolean;
  fields: object;
  attachments: BpgIssueAttachement;
}

export interface BpgIssueAttachement {
  id: string;
  type: string;
  name: string;
  path: string;
  url: string;
}

export interface BpgRelationShips {
  brand: object;
  productVariant: object;
  retailer: object;
}
