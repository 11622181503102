<template>
  <div class="not-retailer">
    <div class="not-retailer__header">
      <AkHeading3>
        {{
          $t(
            'Unfortunately, we’re unable to proceed with your application because the platform is only available to retail stores.'
          )
        }}
      </AkHeading3>
    </div>
    <div class="not-retailer__content">
      <AkParagraph>
        {{ $t('Thank you for applying to buy on Ankorstore, we wish you all the best in the development of your business.') }}
      </AkParagraph>
      <AkParagraph>{{ $t('Thank you for understanding.') }}</AkParagraph>
      <AkParagraph>{{ $t('The Ankorstore team') }}</AkParagraph>
      <AkButton
        size="lg"
        data-testid="goBackButton"
        class="back-button"
        @click="goBack"
      >
        {{ $t('global.goBack') }}
      </AkButton>
    </div>
  </div>
</template>

<script lang="ts">
import Analytics from '@/services/analytics';
import NotRetailerBackEvent from '@/services/analytics/events/sign-up/not-retailer-back-event';
import { OriginProperty } from '@/services/analytics/properties/origin-property';
import { defineComponent } from 'vue';
import { AkButton, AkParagraph, AkHeading3 } from '@ankorstore/design-system';

export default defineComponent({
  name: 'NotRetailer',
  components: {
    AkButton,
    AkParagraph,
    AkHeading3,
  },
  props: {
    isAnkorstart: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['redirect'],
  methods: {
    goBack() {
      Analytics.track(new NotRetailerBackEvent({ type: 'Click', is_opening_soon: this.isAnkorstart }, OriginProperty.SignUp));
      this.$emit('redirect');
    },
  },
});
</script>

<style scoped lang="scss">
.back-icon {
  position: relative;
  top: -5px;
  font-size: 22px;
}

.not-retailer {
  @apply ds-bg-white ds-p-5;
  display: flex;
  flex-direction: column;
  height: 100%;
  &__header {
    @apply ds-text-center ds-my-3;
  }

  &__title {
    font-size: 18px;
    margin-bottom: 5px;
  }
  &__content {
    @apply ds-text-center ds-gap-4 ds-flex ds-flex-col;
  }
}

.back-button {
  @apply ds-mx-auto ds-block ds-mt-5;
}
</style>
