export interface OverviewData {
  impressionsCount: number;
  clicksCount: number;
  billableClicksCount: number;
  costAmount: number;
  gmvAmount: number;
  wishlistCount: number;
  newBuyersCount: number;
  type: 'brand-statistic-totals';
  id: string;
}

export interface TimeSeriesData {
  type: 'brand-statistics-time-series';
  id: string;
  date: string;
  impressionsCount: number;
  clicksCount: number;
  billableClicksCount: number;
  costAmount: number;
  gmvAmount: number;
}

export enum IntervalEnum {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
}
