<template>
  <AkBanner
    v-if="discount && discount.title"
    class="personalised-discount__banner"
    data-testid="discount"
    color="primary"
    :title="discount.title"
    image="/images/personalised-discount/personalised-discount.svg"
    :closable="false"
  >
    <template v-if="discount.message">
      {{ discount.message }}
    </template>
  </AkBanner>
</template>
<script lang="ts">
import { BrandDiscount } from '@/types/api/brand-discount';
import { AkBanner } from '@ankorstore/design-system';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'PersonalisedDiscountBanner',
  components: {
    AkBanner,
  },
  props: {
    discount: {
      type: Object as PropType<BrandDiscount>,
      required: true,
    },
  },
});
</script>
<style lang="scss">
@import '@css/vue-import';

.personalised-discount__banner {
  &.ak-banner {
    @apply ds-items-center;
    .ak-banner__image {
      @apply ds-block;
      max-width: 60px;
      @include media-breakpoint-up(md) {
        max-width: 120px;
      }
    }
  }
}
</style>
