import { ActionContext } from 'vuex';
import { getBrandOnboardingInfo } from '../services/api';
import { OnboardingAttributes, BrandOnboardingStep, OnboardingStatus } from '../types';

export interface BrandOnboardingState {
  onboarding_attributes: OnboardingAttributes;
  onboarding_attributes_fetched: boolean;
}

export const initialState: BrandOnboardingState = {
  onboarding_attributes: {
    completedAt: null,
    createdAt: null,
    status: OnboardingStatus.InProgress,
    steps: [],
  },
  onboarding_attributes_fetched: false,
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    fetchBrandOnboardingAttributes: async (
      { commit, state, rootGetters }: ActionContext<BrandOnboardingState, unknown>,
      { force = false }
    ): Promise<void> => {
      if ((!state.onboarding_attributes_fetched || force) && rootGetters.userIsCandidateBrand) {
        const response = await getBrandOnboardingInfo(rootGetters.brand.uuid);
        commit('SET_ONBOARDING_ATTRIBUTES', response);
        commit('SET_ONBOARDING_ATTRIBUTES_FETCHED');
      }
    },
  },
  mutations: {
    SET_ONBOARDING_ATTRIBUTES(state: BrandOnboardingState, value: OnboardingAttributes): void {
      state.onboarding_attributes = value;
    },
    SET_ONBOARDING_ATTRIBUTES_FETCHED(state: BrandOnboardingState): void {
      state.onboarding_attributes_fetched = true;
    },
  },
  getters: {
    onboardingSteps(state: BrandOnboardingState): { [key: string]: BrandOnboardingStep } {
      return state.onboarding_attributes?.steps?.reduce((obj, step) => {
        return {
          ...obj,
          [step.name]: step,
        };
      }, {});
    },
  },
};
