<template>
  <AkPill
    v-if="hasWalletBalance"
    :content="$root.formatPrice(wallet.balance_amount, '$0,0')"
    color="accent"
    text-color="accent-alt"
  />
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

export default defineComponent({
  name: 'BadgeWalletBalance',
  computed: {
    ...mapState('wallet', ['wallet']),
    ...mapGetters('wallet', ['hasWalletBalance']),
  },
  created() {
    this.fetchWalletBalance();
  },
  methods: {
    ...mapActions('wallet', ['fetchWalletBalance']),
  },
});
</script>
