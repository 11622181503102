import http from '@/services/api/http';
import { captureException } from '@/sentry';
import { Meta } from '@/types/pagination';
import { IReOrderItem, IReOrderProductToAdd, OrderedProduct } from '@/types/re-order';
import { Checkout } from '@/types/cart';
import { Brand } from '@/types/api/brand';
import type { ESBrandHit as BrandHit } from '@bc/discovery';

export interface ReorderBrandsParams {
  search?: string;
  sortBy?: string;
  perPage?: number;
  page?: number;
  includeHasNewProducts?: boolean;
  'filters[brand][active]'?: boolean;
  'include[business_events]'?: boolean;
}
export interface ReorderBrandsParamsV2 {
  search?: string;
  sortBy?: string;
  perPage?: number;
  page?: number;
  filters?: string;
}
export interface ProductPreview {
  name: string;
  picture: string;
  link: string;
}

export type OrderedBrand = Brand &
  Pick<BrandHit, 'business_events_eligible' | 'business_events'> & {
    image_rounded: string;
    products_count: number;
    orders_count: number;
    products_preview: ProductPreview[];
    blacklisted: boolean;
    has_new_products: boolean;
    last_ordered: string;
  };

export interface Paginated<T> {
  data: T;
  meta: Meta;
  links: string[];
}

interface OrderedItemsFromBrand {
  brand: OrderedBrand;
  products: OrderedProduct[];
}

export const DEFAULT_PARAMS: ReorderBrandsParamsV2 = {
  sortBy: 'most_frequently',
  perPage: 10,
  page: 1,
};

export const getOrderedBrandsV2 = async (params: ReorderBrandsParamsV2 = DEFAULT_PARAMS): Promise<Paginated<OrderedBrand[]>> => {
  try {
    const response = await http().get('/api/reordering/v2/brands', {
      params,
    });
    return response.data;
  } catch (e) {
    captureException(e);
    return null;
  }
};

export const getOrderedItemsFromBrand = async (
  brandId: number,
  params: ReorderBrandsParamsV2 = DEFAULT_PARAMS
): Promise<Paginated<OrderedItemsFromBrand>> => {
  try {
    const response = await http().get(`/api/reordering/v2/brands/${brandId}/ordered-items`, {
      params,
    });
    return response.data;
  } catch (e) {
    captureException(e);
    return null;
  }
};

export const getAllProductsFromBrandV2 = async (
  brandId: number,
  params: ReorderBrandsParamsV2 = DEFAULT_PARAMS
): Promise<Paginated<OrderedItemsFromBrand>> => {
  try {
    const response = await http().get(`/api/reordering/v2/brands/${brandId}/products`, {
      params,
    });
    return response.data;
  } catch (e) {
    captureException(e);
    return null;
  }
};

export const addProductsToCart = async (products: IReOrderProductToAdd[]): Promise<Checkout> => {
  const result = await http().post('/api/cart/items', {
    items: products,
  });

  return result?.data?.data;
};

export const getItemsFromOrderId = async (orderId: number | string): Promise<IReOrderItem[]> => {
  const result = await http().get(`/api/orders/${orderId}/items`);

  return result?.data?.data as IReOrderItem[];
};
