export const DELETE_ITEM = 'deleteItem';
export const CHANGE_TAB = 'changeTab';
export const REFRESH_CHECKOUT = 'refreshCheckout';
export const UPDATE_ITEM_QUANTITY = 'updateItemQuantity';
export const TRACK_MINIMUM_REACHED = 'trackMinimumReached';
export const CALCULATE_CHECKOUT = 'calculateCheckout';
export const ADD_TO_CART = 'addToCart';
export const DELETE_PROMOCODE = 'deletePromocode';
export const GET_CHECKOUT = 'getCheckout';
export const ADD_PROMOCODE = 'addPromocode';
export const ADD_REFERRAL_PROMOCODE = 'addReferralPromocode';
export const APPLY_CREDIT = 'applyCredit';
export const GET_CURRENT_CHECKOUT = 'getCurrentCheckout';
export const GET_CURRENT_CHECKOUT_TOTALS = 'getCurrentCheckoutWithTotals';
export const TOGGLE_SAVE_CART_FOR_LATER = 'toggleSaveCartForLater';
export const SAVE_FOR_LATER = 'saveForLater';
export const PERSIST_SAVE_CART_FOR_LATER = 'persistSaveCartForLater';
export const REMOVE_LOCAL_CART = 'removeLocalCart';
export const REMOVE_REMOTE_BRAND = 'removeRemoteBrand';
export const SCROLL_TO_ERROR = 'scrollToError';
export const SET_TAX_NUMBER_ERRORS = 'setTaxNumberErrors';
export const CLEAR_TAX_NUMBER_ERRORS = 'clearTaxNumberErrors';
export const SET_COMPANY_ID_ERRORS = 'setCompanyIdErrors';
export const CLEAR_COMPANY_ID_ERRORS = 'clearCompanyIdErrors';
export const SET_TAX_NUMBER = 'setTaxNumber';
export const SEND_TAX_NUMBER = 'sendTaxNumber';
export const SET_BUSINESS_VALIDATION_STEP = 'setBusinessValidationStep';
export const SEND_SOLE_TRADER_DETAILS = 'sendSoleTraderDetails';
export const SEARCH_HOKODO_COMPANY = 'searchHokodoCompany';
export const SET_HOKODO_COMPANY_ID = 'setHokodoCompanyId';
export const SEND_HOKODO_COMPANY_ID = 'sendHokodoCompanyId';
export const PROCEED_TO_CHECKOUT = 'proceedToCheckout';
export const SET_TAX_NUMBER_TYPE = 'setTaxNumberType';
export const SET_PROVIDING_TAX_NUMBER_CHOICE = 'setProvidingTaxNumberChoice';
export const TOGGLE_SKIP_TAX_NUMBER = 'toggleSkipTaxNumber';
export const INIT_TAX_NUMBER_TYPE = 'initTaxNumberType';
export const SET_TAX_NUMBER_VALUE = 'setTaxNumberValue';
export const INIT_BILLING_WRAPPER_STATE = 'initBillingWrapperState';
export const VALIDATE_CART = 'validateCart';
export const CART_BULK_UPDATE = 'cartBulkUpdate';
export const UPDATE_BULK = 'cartUpdateBulk';
export const SEND_RECARGO_INFORMATION = 'sendRecargoInformation';
export const SEND_EORI_INFORMATION = 'sendEoriInformation';
export const SET_RECARGO_ERRORS = 'setRecargoErrors';
export const CHANGE_RECARGO_ENABLED = 'changeRecargoEnabled';
export const CHANGE_EORI = 'changeEori';
export const CLEAR_RECARGO_ERRORS = 'clearRecargoErrors';
export const CLEAR_EORI_ERRORS = 'clearEoriErrors';
export const SET_EORI_SUCCESS = 'setEoriSuccess';
export const SET_EORI_ERRORS = 'setEoriErrors';
export const SET_CART_BRAND_COUNTRY = 'setCartBrandCountry';
// NEW CARTS AND CHECKOUT API ACTIONS
export const SELECT_BRAND = 'selectBrand';
export const GET_BRANDS_DISCOUNTS = 'getBrandsDiscounts';
export const INIT_CART_PAGE = 'initCartPage';
export const GET_SAVED_FOR_LATER = 'getSavedForLater';
export const GET_ORDER_SUMMARY = 'getOrderSummary';
export const GET_ITEMS_COUNT = 'getItemsCount';
export const UPDATE_VAT_EXEMPTION = 'updateVatExemption';
