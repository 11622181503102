export default class GlobalSelector {
  public readonly id: string;
  private readonly staticHeaders: Element[];

  /**
   * Create a new instance of the GlobalSelector.
   * @constructor
   * @param id            - Used to identify the element in the DOM.
   * @param staticHeaders - An array of fixed headers that should be used to clculate an offset
   *                        when scrolling to this element.
   */
  constructor(id: string, staticHeaders: Element[]) {
    this.id = id;
    this.staticHeaders = staticHeaders;
  }

  /** returns this selectors element. */
  getElement(): Element {
    return document.querySelector(`#${this.id}`);
  }

  /**
   * Scroll the viewport to this element.
   * @param options - Specify an explicit padding, and any of the browser scrollTo options.
   */
  scrollTo(options: ScrollToOptions & { paddingTop?: number } = { paddingTop: 0 }) {
    const headerHeight = this.staticHeaders.reduce((totalHeight, header) => {
      // If header is visible in DOM, add its height to the offset value
      if ((header as HTMLElement).offsetParent !== null) {
        return totalHeight + header.getBoundingClientRect().height;
      }
      return totalHeight;
    }, 0);

    const element = this.getElement();

    if (element) {
      const top = element.getBoundingClientRect().y - headerHeight + window.scrollY - options.paddingTop;
      delete options.paddingTop;

      window.scrollTo({
        top,
        behavior: 'smooth',
        ...(options as ScrollToOptions),
      });
    }
  }
}
