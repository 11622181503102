<template>
  <div class="unread-count-badge">
    {{ badgeStr }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotificationsPanel',
  props: {
    unreadCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    badgeStr(): string {
      return this.unreadCount > 99 ? '99+' : this.unreadCount.toString();
    },
  },
});
</script>

<style lang="scss" scoped>
.unread-count-badge {
  @apply ds-absolute ds-top-0 ds-left-3 ds-right-auto md:ds-left-auto md:ds-right-3;
  @apply ds-bg-warning ds-bg-opacity-90 ds-text-white ds-border-white ds-border-solid ds-font-bold ds-text-center ds-border ds-rounded-full;
  @apply ds-select-none ds-cursor-pointer;
  font-size: 9px;
  height: 20px;
  width: 20px;
}
</style>
