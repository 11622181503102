<template>
  <div class="banner-message">
    <div class="banner-message__container">
      <div
        v-if="symbol"
        class="banner-message__icon"
        data-testid="bannerMessageIcon"
      >
        <AkIcon
          class="icon--rounded"
          :symbol="symbol"
        />
      </div>
      <div class="banner-message__message">
        <slot />
      </div>
    </div>
    <div
      v-if="isNew"
      class="banner-message__new"
    >
      <AkParagraph
        size="sm"
        weight="bold"
      >
        {{ $t('global.new') }}
      </AkParagraph>
    </div>
  </div>
</template>
<script lang="ts">
import { AkIcon, AkParagraph } from '@ankorstore/design-system';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BannerMessage',
  components: {
    AkIcon,
    AkParagraph,
  },
  props: {
    symbol: {
      type: String,
      default: null,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
<style lang="scss" scoped>
.banner-message {
  @apply ds-bg-warm-white ds-p-2 ds-mt-2 ds-mb-4 ds-flex ds-justify-between ds-gap-2 ds-flex-col-reverse md:ds-flex-row;
  border-radius: 12px;
  &__new {
    @apply ds-text-info;
  }
  &__container {
    @apply ds-flex ds-gap-2 ds-items-baseline ds-w-10/12;
  }
  &__icon {
    @apply ds-p-1 ds-text-info;
    i {
      @apply ds-flex ds-w-4 ds-text-center ds-bg-white ds-rounded-full ds-items-center ds-justify-center;
      width: 28px;
      height: 28px;
    }
  }
  &__message {
    @apply ds-self-start;
    padding-top: 2px;
  }
}
</style>
<style lang="scss">
.banner-message {
  &__message {
    p {
      @apply ds-mb-2 ds-inline;
    }
    strong {
      @apply ds-font-bold;
    }
    a {
      @apply ds-underline;
    }
  }
}
</style>
