import { CallbackFn, CallbackMapItem, CallbackMap, EventKey, EventDetail, TransformEventFn } from './types';

const matchSymbolDescription = /Symbol\((.*)\)/;

export class EventEmitter extends EventTarget {
  private readonly eventMap: Map<EventKey, CallbackMap>;
  private readonly transformers: Map<EventKey, TransformEventFn[]>;

  constructor(
    private readonly eventBusKey: symbol = Symbol('eventBus'),
    private readonly parent?: EventEmitter
  ) {
    super();
    this.eventMap = new Map();
    this.transformers = new Map();
  }

  private setCallbackMapItem(event: EventKey, callbackMapItem: CallbackMapItem) {
    if (!this.eventMap.get(event)) {
      this.eventMap.set(event, new Map());
    }
    this.eventMap.get(event).set(callbackMapItem.original, callbackMapItem);
  }

  private getCallbackMapItem(event: EventKey, callback: CallbackFn): CallbackMapItem {
    return this.eventMap.get(event)?.get(callback);
  }

  private removeCallbackMapItem(event: EventKey, callback: CallbackFn) {
    this.eventMap.get(event)?.delete(callback);
    if (!this.eventMap.get(event)?.size) {
      delete this.eventMap[event];
    }
  }

  private createOnCallbackMapItem(callback: CallbackFn): CallbackMapItem {
    return {
      original: callback,
      wrapper: (e: CustomEvent) => {
        this.debug(`Event handled: ${e.type}`, e.detail);
        callback(e.detail);
      },
    };
  }

  private createOnceCallbackMapItem(event: EventKey, callback: CallbackFn): CallbackMapItem {
    return {
      original: callback,
      wrapper: (e: CustomEvent) => {
        callback(e.detail);
        this.removeCallbackMapItem(event, callback);
      },
    };
  }

  private getListenerType(eventKey: EventKey): string {
    if (typeof eventKey === 'symbol') {
      const symbolDescription = matchSymbolDescription.exec(eventKey.toString())[1];
      if (symbolDescription) {
        return symbolDescription;
      }
    } else if (typeof eventKey === 'string') {
      return eventKey;
    }
    throw new TypeError('The eventKey provided is not valid');
  }

  private processTransformers(
    event: EventKey,
    detail: CustomEvent['detail']
  ): { processedEvent: EventKey; processedDetail: EventDetail } {
    let transformedEvent = event;
    let transformedDetail = detail;
    const eventTransformers = this.transformers.get(event);
    if (eventTransformers?.length) {
      ({ event: transformedEvent, detail: transformedDetail } = eventTransformers.reduce(
        (acc, transformer) => {
          const { event: newEvent, detail: newDetail } = transformer(acc.event, acc.detail);
          acc.event = newEvent ?? acc.event;
          acc.detail = newDetail ?? acc.detail;
          return acc;
        },
        { event, detail }
      ));
    }
    return { processedEvent: transformedEvent ?? event, processedDetail: transformedDetail ?? detail };
  }

  /* istanbul ignore next */
  private debug(msg: string, ...extraData: unknown[]): void {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.debug(`[EVENT BUS] {${String(this.eventBusKey)}} ${msg}`, ...extraData);
    }
  }

  on(event: EventKey, callback: CallbackFn): this {
    if (this.parent) {
      this.parent.on(event, callback);
      return this;
    }

    this.setCallbackMapItem(event, this.createOnCallbackMapItem(callback));
    this.addEventListener(this.getListenerType(event), this.getCallbackMapItem(event, callback).wrapper as EventListener);
    return this;
  }

  once(event: EventKey, callback: CallbackFn): this {
    if (this.parent) {
      this.parent.once(event, callback);
      return this;
    }

    this.setCallbackMapItem(event, this.createOnceCallbackMapItem(event, callback));
    this.addEventListener(this.getListenerType(event), this.getCallbackMapItem(event, callback).wrapper as EventListener, {
      once: true,
    });
    return this;
  }

  off(event: EventKey, callback: CallbackFn): this {
    if (this.parent) {
      this.parent.off(event, callback);
      return this;
    }

    const callbackMapItem = this.getCallbackMapItem(event, callback);
    if (callbackMapItem) {
      this.removeEventListener(this.getListenerType(event), callbackMapItem.wrapper as EventListener);
      this.removeCallbackMapItem(event, callback);
    }
    return this;
  }

  emit(event: EventKey, detail?: CustomEvent['detail']): this {
    this.debug(`Emitting event: ${String(event)}`, detail);

    const { processedEvent, processedDetail } = this.processTransformers(event, detail);

    const customEvent = new CustomEvent(this.getListenerType(processedEvent), { detail: processedDetail });

    this.dispatchEvent(customEvent);

    return this;
  }

  dispatchEvent(event: CustomEvent): boolean {
    this.debug(`Dispatching event: ${event.type}`, event.detail);
    const result = super.dispatchEvent(event);
    this.parent?.emit(event.type, event.detail);

    return result;
  }

  tryEmit(event: EventKey, fallbackFn: (detail: CustomEvent['detail']) => void, detail?: CustomEvent['detail']): this {
    if (this.has(event)) {
      this.emit(event, detail);
    } else {
      this.debug(`Event ${String(event)} has no listeners, falling back to provided function`);

      fallbackFn(detail);
    }
    return this;
  }

  map(event: EventKey, transformFn: TransformEventFn): this {
    if (!this.transformers.has(event)) {
      this.transformers.set(event, []);
    }
    this.debug(`Mapping event: ${String(event)}`);
    this.transformers.get(event)?.push((event, detail) => {
      this.debug(`Transforming event: ${String(event)}`, detail);
      return transformFn(event, detail);
    });
    return this;
  }

  events(): EventKey[] {
    return [...this.eventMap.keys()].reduce((acc: EventKey[], event: EventKey) => {
      acc.push(event);
      return acc;
    }, []);
  }

  listenerCount(event: EventKey): number {
    return this.eventMap.get(event)?.size || 0;
  }

  has(event: EventKey): boolean {
    return this.eventMap.has(event) || this.transformers.has(event) || this.parent?.has(event);
  }

  destroy(): void {
    this.eventMap.forEach((callbacks, event) => {
      callbacks.forEach((callbackMapItem) => {
        this.removeEventListener(this.getListenerType(event), callbackMapItem.wrapper as EventListener);
      });
    });
    this.eventMap.clear();
    this.transformers.clear();
  }
}
