<template>
  <div class="campaigns-setup">
    <AkHeading4 class="ds-mb-4">
      {{ $t('Set your campaign') }}
    </AkHeading4>
    <AkInput
      v-bind="$attrs"
      data-testid="campaignsName"
      name="name"
      type="text"
      class="ds-mb-4"
      :model-value="name"
      :label="$t('Name your campaign')"
      :placeholder="$t('New Collection Winter 2020 - FR')"
      :label-info="$t('account.brands.createCampaigns.name.tooltip')"
      :disabled="nameDisabled"
      :errors="clearError.name ? '' : errors?.fields?.name?.[0]"
      :required="true"
      @update:model-value="emit('update:name', $event)"
    />

    <div class="ds-relative">
      <AkInput
        v-bind="$attrs"
        data-testid="campaignsFrom"
        name="from"
        type="text"
        :model-value="from"
        :label="$t('account.brands.createCampaigns.senderName.label')"
        :errors="clearError.from ? '' : errors?.fields?.from?.[0]"
        :required="true"
        :maxlength="75"
        :disabled="fromDisabled"
        @update:model-value="emit('update:from', $event)"
      />
      <AkParagraph
        v-if="!!from"
        size="xs"
        data-testid="campaignsCharCounter"
        class="campaigns-setup__char-counter"
      >
        {{ charCounter }}
      </AkParagraph>
    </div>
  </div>
</template>
<script lang="ts">
export default defineComponent({
  compatConfig: { COMPONENT_V_MODEL: false },
});
</script>
<script setup lang="ts">
import { toRefs, computed, defineComponent, ref, watch } from 'vue';
import { AkHeading4, AkInput } from '@ankorstore/design-system';
import { FormErrors } from '../../types/create-campaigns.types';

export interface SetupModal {
  type: 'campaignName' | 'sendersName';
}

const props = withDefaults(
  defineProps<{
    errors?: FormErrors;
    name?: string;
    from?: string;
    nameDisabled?: boolean;
    fromDisabled?: boolean;
  }>(),
  {
    errors: null,
    name: null,
    from: null,
    nameDisabled: false,
    fromDisabled: false,
  }
);
const emit = defineEmits(['update:name', 'update:from']);

const { from, errors, name } = toRefs(props);
const clearError = ref({ name: false, from: false });
const charCounter = computed(() => {
  return (from.value?.length || 0) + '/75';
});

watch([name, from], ([newName, newFrom], [oldName, oldFrom]) => {
  if (!!newName && newName !== oldName) {
    clearError.value.name = true;
  } else {
    clearError.value.name = false;
  }
  if (!!newFrom && newFrom !== oldFrom) {
    clearError.value.from = true;
  } else {
    clearError.value.from = false;
  }
});
</script>

<style lang="scss" scoped>
.campaigns-setup {
  &__char-counter {
    @apply ds-absolute ds-right-0 ds-top-5 ds-p-4 ds-text-neutral-700;
  }
}
</style>
