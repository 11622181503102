<template>
  <div class="properties">
    <BrandPropertiesLines
      :brand="brand"
      :long-distance-fee="longDistanceShippingFee"
      :is-optin="isAnkorstorePlusOptin"
      :is-preorder="isEnabled('oxp-1484-index-pre-order-product')"
    />

    <BenefitsAnkorstorePlus
      v-if="perks && ankorstorePlus"
      data-testid="benefitsAnkorstorePlus"
      :content="perks"
      :is-optin="isAnkorstorePlusOptin"
      context="brand"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, ref, onMounted, unref } from 'vue';

import BenefitsAnkorstorePlus from '@/modules/promote-engage/touchpoints/benefits-ankorstore-plus.vue';
import BrandPropertiesLines from '@/components/brand/brand-properties-lines.vue';

import { isEnabled } from '@/services/features';
import { useStore } from '@/composables/use-store';
import { captureException } from '@/sentry';

import { OfferProgramEligibility } from '@/modules/promote-engage/services/offer';
import { ShippingFee } from '@/types/shipping/shipping-fees';
import { BenefitsAnkorsorePlus } from '@/types/touchpoints/benefits-ankorsore-plus';
import { getPerksBrand } from '@/modules/promote-engage/touchpoints/services/perks-ankorstore-plus';

export default defineComponent({
  name: 'BrandPropertiesBlock',
  components: {
    BenefitsAnkorstorePlus,
    BrandPropertiesLines,
  },
  props: {
    longDistanceShippingFee: {
      type: Object as () => ShippingFee,
      required: false,
      default: null,
    },
    brand: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    const perks = ref<BenefitsAnkorsorePlus | null>(null);

    const ankorstorePlus = computed((): OfferProgramEligibility | null => {
      return store.getters['offers/ankorstorePlus'];
    });

    const isAnkorstorePlusOptin = computed(() => ankorstorePlus.value?.optIn);

    const userIsRetailer = computed(() => store.getters['userIsRetailer']);

    onMounted(async () => {
      if (unref(userIsRetailer)) {
        try {
          perks.value = await getPerksBrand(props.brand.id);
        } catch (e) {
          captureException(e);
        }
      }
    });

    return {
      ankorstorePlus,
      perks,
      isEnabled,
      userIsRetailer,
      isAnkorstorePlusOptin,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';
.properties {
  &__tooltip-warning {
    @apply ds-text-warning ds-flex ds-items-center ds-mb-2 ds-gap-1;
  }
}
.title {
  @apply ds-mb-2;
}

.badges {
  @apply ds--mx-2;
}
</style>
