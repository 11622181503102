import { Facet, FacetGroup } from '@bc/discovery/domain/catalog';
import { Filter, FilterGroup } from '@bc/discovery/ui/filters';
import { Refinement } from '../types/refinement';
import { isEnabled } from '@/services/features';

export const isAttributeFilter = (filterName: string): boolean => filterName.startsWith('attributes.');
export const isNumericAttributeFilter = (filterName: string): boolean =>
  isAttributeFilter(filterName) && (filterName.endsWith('.number') || filterName.endsWith('.integer'));

export const createFilters = (items: (Facet | FacetGroup)[], refinements: Refinement[]): (Filter | FilterGroup)[] => {
  const createFilter = (facet: Facet): Filter => {
    const item = refinements.find((item) => item.attribute === facet.name);
    return {
      ...facet,
      count: item?.refinements?.length ?? 0,
      isAttributeFilter: isAttributeFilter(facet.name),
      isNumericAttributeFilter: isNumericAttributeFilter(facet.name),
      hasBetaLabel: isEnabled('RET-2997') && isAttributeFilter(facet.name),
    };
  };

  const createFilterGroup = (facetGroup: FacetGroup): FilterGroup => {
    let count = 0;
    let hasBetaLabel = false;
    const filters = facetGroup.facets.map((facet) => {
      const filter = createFilter(facet);
      hasBetaLabel = hasBetaLabel || filter.hasBetaLabel;
      count += filter.count;
      return filter;
    });
    const facetCopy = { ...facetGroup };
    delete facetCopy.facets;
    return {
      ...facetCopy,
      count,
      hasBetaLabel,
      filters,
    };
  };

  const mapItem = (item: Facet | FacetGroup): Filter | FilterGroup => {
    const facet = item as Facet;
    const facetGroup = item as FacetGroup;
    return 'facets' in item ? createFilterGroup(facetGroup) : createFilter(facet);
  };

  return items.filter((item) => item.display).map(mapItem);
};
