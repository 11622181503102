<template>
  <div
    v-if="currentFilter"
    class="list-widget__title"
  >
    <AkParagraph class="ds-text-neutral-900 ds-py-2">{{ currentFilter.label }}</AkParagraph>
    <AkButton
      v-if="hasSelectedValue"
      link
      symbol="x"
      @click="setValue(undefined)"
    >
      {{ $t('brands.contactManagement.filtersDrawer.clear') }}
    </AkButton>
  </div>
  <div
    v-if="currentFilter && currentFilter.values"
    class="list-widget"
  >
    <AkRadio
      v-for="item in currentFilter.values"
      :key="item.value"
      class="list-widget__item"
      :value="modelValue[item.value.toString()]"
      :is-checked="isChecked(item.value.toString())"
      :name="currentFilter.id"
      :text="item.label"
      @change="setValue(item.value.toString())"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from 'vue';
import { isEmpty } from 'lodash-es';
import { AkParagraph, AkRadio } from '@ankorstore/design-system';
import { ListWidgetComponent } from '../../types/filter.types';
import { ContactFilterType, ValueContactFilter } from '../../types/api.types';

export default defineComponent({
  name: 'ListWidget',
  components: {
    AkParagraph,
    AkRadio,
  },
  props: {
    filter: {
      type: Object as PropType<ListWidgetComponent>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<ValueContactFilter>,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const currentFilter = ref(props?.filter);
    const setValue = (item?: string) => {
      emit('update:modelValue', {
        type: ContactFilterType.Value,
        name: props.filter.attributeName,
        value: item ? [ item ] : [],
      });
    };

    const hasSelectedValue = computed(() => !isEmpty(props?.modelValue?.value));
    const isChecked = (value: string) => props?.modelValue?.value?.includes(value);

    return { setValue, currentFilter, isChecked, hasSelectedValue };
  },
});
</script>
<style scoped lang="scss">
.list-widget {
  @apply ds-px-4;
  &__title {
    @apply ds-flex ds-items-center ds-justify-between;
  }
  &__item {
    @apply ds-mb-2 #{!important};
  }
}
</style>
