<template>
  <div class="ds-flex ds-flex-col">
    <p
      class="title"
      data-testid="r2b-personalize-title"
    >
      {{ title }}
    </p>
    <p
      class="sub-title"
      data-testid="r2b-personalize-sub-title"
    >
      {{ subTitle }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'R2BTitlePersonalize',
  props: {
    liftCode: {
      type: String,
      required: false,
      default: '',
    },
    liftCodeAmount: {
      type: String,
      required: false,
      default: '',
    },
    brandName: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    title(): string {
      if (!this.liftCode) {
        return this.$t('Find your next bestsellers online');
      }

      if (this.brandName) {
        return this.$t('Shop {brandName} on Ankorstore', {
          brandName: this.brandName,
        });
      }

      return this.$t('Signup for free and find your next bestsellers.');
    },
    subTitle(): string {
      if (!this.liftCode) {
        return this.$t('Register for free - whether you already run your shop or plan to open it soon');
      }

      if (this.brandName) {
        return this.$t(
          `Join now to get {amount} off on our brand and {percent} off on +{brands}k brands for {discountDays} days.`,
          {
            amount: this.liftCodeAmount,
            discountDays: '60',
            percent: '20%',
            brands: '30',
          }
        );
      }
      return this.$t('Partner with Ankorstore to discover new brands and access better payment terms');
    },
  },
});
</script>
