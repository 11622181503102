import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { getMeasurementUnits } from '@/services/api/measurement-units';
import { MeasurementCategories, MeasurementUnit } from '@/types/product-shape';

export interface State {
  fetching: boolean;
  unitList: MeasurementUnit[];
}

export interface RootState {
  measurementUnits: State;
}

// #region getters
export enum GetterTypes {
  GET_UNITS_BY_CATEGORIES = 'getUnitsByCategories',
  CAPACITY_UNITS = 'capacityUnits',
  DIMENSION_UNITS = 'dimensionUnits',
  WEIGHT_UNITS = 'weightUnits',
}
export interface Getters {
  [GetterTypes.GET_UNITS_BY_CATEGORIES](state: State): (categories: MeasurementCategories[]) => MeasurementUnit[];
  [GetterTypes.CAPACITY_UNITS](state: State, getters: InitialisedGetters): MeasurementUnit[];
  [GetterTypes.DIMENSION_UNITS](state: State, getters: InitialisedGetters): MeasurementUnit[];
  [GetterTypes.WEIGHT_UNITS](state: State, getters: InitialisedGetters): MeasurementUnit[];
}

export type InitialisedGetters = {
  [getterName in keyof Getters]: ReturnType<Getters[getterName]>;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getUnitsByCategories:
    (state: State) =>
    (categories: MeasurementCategories[]): MeasurementUnit[] =>
      state.unitList.filter(({ category }) => categories.includes(category)),
  capacityUnits: (_state: State, getters: InitialisedGetters): MeasurementUnit[] =>
    getters.getUnitsByCategories([MeasurementCategories.Surface, MeasurementCategories.Volume]),
  dimensionUnits: (_state: State, getters: InitialisedGetters): MeasurementUnit[] =>
    getters.getUnitsByCategories([MeasurementCategories.Dimension]),
  weightUnits: (_state: State, getters: InitialisedGetters): MeasurementUnit[] =>
    getters.getUnitsByCategories([MeasurementCategories.Weight]),
};
// #endregion getters

// #region actions
type PartialActionContext = Partial<ActionContext<State, RootState>>;

export enum ActionTypes {
  FETCH_UNITS = 'fetchUnits',
}

export interface Actions {
  [ActionTypes.FETCH_UNITS]({ commit, state }: PartialActionContext): Promise<void>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.FETCH_UNITS]({ commit, state }): Promise<void> {
    if (state.fetching || state.unitList.length > 0) {
      return;
    }

    try {
      commit(MutationTypes.SET_FETCHING, true);
      const unitList = await getMeasurementUnits();
      commit(MutationTypes.SET_UNIT_LIST, unitList);
    } catch {
      commit(MutationTypes.SET_UNIT_LIST, []);
    } finally {
      commit(MutationTypes.SET_FETCHING, false);
    }
  },
};
// #endregion actions

// #region mutations
export enum MutationTypes {
  SET_FETCHING = 'SET_FETCHING',
  SET_UNIT_LIST = 'SET_UNIT_LIST',
}

export interface Mutations {
  [MutationTypes.SET_FETCHING](state: State, fetching: State['fetching']): void;
  [MutationTypes.SET_UNIT_LIST](state: State, unitList: State['unitList']): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_FETCHING](state: State, fetching: State['fetching']) {
    state.fetching = fetching;
  },
  [MutationTypes.SET_UNIT_LIST](state: State, unitList: State['unitList']) {
    state.unitList = unitList;
  },
};
// #endregion mutations

export const createInitialState = (): State => ({
  fetching: false,
  unitList: [],
});

const measurementUnitModule: Module<State, RootState> = {
  namespaced: true,
  state: createInitialState(),
  getters,
  actions,
  mutations,
};

export default measurementUnitModule;
