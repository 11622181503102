import { PreorderCartResponseMapped } from '@/types/cart-preorder';
import actions from '@/store/cart-preorder/actions';
import mutations from '@/store/cart-preorder/mutations';
import getters from '@/store/cart-preorder/getters';
import { getCurrency } from '@/services/metas/currency';
import { PreorderBulkUpdate } from '@/services/api/cart-preorder';

export interface CartState {
  preorderCart: PreorderCartResponseMapped;
  itemsCounts: {
    main: number;
  };
  bulkUpdates: PreorderBulkUpdate[];
  isTotalsLoading: boolean;
  isCartLoading: boolean;
  paymentTerms: string[];
}

const createInitialState = () => {
  const initialState: CartState = {
    preorderCart: {
      type: 'preorders-cart',
      id: null,
      total_amount_with_vat: {
        amount: 0,
        currency: getCurrency(),
      },
      grand_total_amount_with_vat: {
        amount: 0,
        currency: getCurrency(),
      },
      total_amount_vat: {
        amount: 0,
        currency: getCurrency(),
      },
      total_amount: {
        amount: 0,
        currency: getCurrency(),
      },
      carts: [],
      firstInit: true,
      brandsCountry: [],
    },
    itemsCounts: {
      main: 0,
    },
    bulkUpdates: [],
    isTotalsLoading: false,
    isCartLoading: false,
    paymentTerms: [],
  };

  return initialState;
};

export default {
  namespaced: true,
  state: createInitialState(),
  actions,
  mutations,
  getters,
};
