<script setup lang="ts">
import { AisRangeInput } from 'vue-instantsearch/vue3/es';
import { Filter, NumericRangeFilter } from '@bc/discovery/ui/filters';
import { createDebouncedRefine } from '../utils/debounced-refine';

defineOptions({ name: 'CustomAisNumericFilter' });
defineProps<{ filter: Filter }>();

const debouncedRefine = createDebouncedRefine();
</script>

<template>
  <AisRangeInput :attribute="filter.name">
    <template #default="{ currentRefinement, range, canRefine, refine }">
      <NumericRangeFilter
        :unit="filter.unit"
        :disabled="!canRefine"
        :range="range"
        :model-value="currentRefinement"
        @update:model-value="debouncedRefine(refine, $event)"
      />
    </template>
  </AisRangeInput>
</template>
