import jsonToFormData from 'json-form-data';
import i18n from '@/services/i18n';
import http from '@/services/api/http';

import DamagedItems from '@/components/account/orders/order/order-retailer-reject/cases/damaged-items.vue';
import WrongItems from '@/components/account/orders/order/order-retailer-reject/cases/wrong-items.vue';
import MissingItems from '@/components/account/orders/order/order-retailer-reject/cases/missing-items.vue';
import DefectiveItems from '@/components/account/orders/order/order-retailer-reject/cases/defective-items.vue';
import NotReceived from '@/components/account/orders/order/order-retailer-reject/cases/not-received.vue';

import { CaseReason } from '@/types/order-retailer-rejection';

export enum AvailableForms {
  retailerOrderIssue = 'retailer-order-issue',
  retailerManualValidationRequestedInformation = 'retailer-manual-validation-requested-information',
}

export enum AvailableReasons {
  payment = 'Payment',
  retailerOrderManagement = 'Retailer_Order Management',
  requestInformation = 'Requested information',
}

export enum AvailableSubReasons {
  retailerModifyOrder = 'Retailer_I would like to modify my order',
  retailerOrderIsLate = 'Retailer_My order is late',
  retailerDamagedItems = 'Retailer_I received damaged items',
  retailerItemsMissing = 'Retailer_Some of my order items are missing',
  retailerWrongItems = 'Retailer_I received wrong items',
  retailerDefectiveItems = 'Retailer_I received defective items',
}

export enum AvailableSources {
  retailer = 'Retailer',
  brand = 'Brand',
  both = 'Both',
}

// Please keep in mind that retailer rejection logic on BE is relying on the exact string value of the name.
// Please, contact @order-management-squad before modifying any of the topic names below.
export const TopicsMapping = [
  {
    name: 'Change my delivery address',
    formName: AvailableForms.retailerOrderIssue,
    subReason: AvailableSubReasons.retailerModifyOrder,
    caseReason: CaseReason.General,
  },
  {
    name: 'Commission Issue',
    formName: AvailableForms.retailerOrderIssue,
    subReason: AvailableSubReasons.retailerModifyOrder,
    caseReason: CaseReason.General,
  },
  {
    name: 'Order not received',
    formName: AvailableForms.retailerOrderIssue,
    subReason: AvailableSubReasons.retailerOrderIsLate,
    caseReason: CaseReason.NotReceived,
    component: NotReceived,
  },
  {
    name: 'Order with damaged products',
    formName: AvailableForms.retailerOrderIssue,
    subReason: AvailableSubReasons.retailerDamagedItems,
    caseReason: CaseReason.DamagedItems,
    component: DamagedItems,
  },
  {
    name: 'Order with missing products',
    formName: AvailableForms.retailerOrderIssue,
    subReason: AvailableSubReasons.retailerItemsMissing,
    caseReason: CaseReason.MissingItems,
    component: MissingItems,
  },
  {
    name: 'Order with defective products',
    formName: AvailableForms.retailerOrderIssue,
    subReason: AvailableSubReasons.retailerDefectiveItems,
    caseReason: CaseReason.DefectiveItems,
    component: DefectiveItems,
  },
  {
    name: 'Order with wrong products',
    formName: AvailableForms.retailerOrderIssue,
    subReason: AvailableSubReasons.retailerWrongItems,
    caseReason: CaseReason.WrongItems,
    component: WrongItems,
  },
];

interface State {
  forms: Record<string, any>;
  errors: object;
}

export const createInitialState = (): State => ({
  forms: {
    [AvailableForms.retailerOrderIssue]: null,
    [AvailableForms.retailerManualValidationRequestedInformation]: null,
  },
  errors: {},
});

export const actions = {
  fetchForm({ commit }, payload: { type: AvailableForms }): Promise<void> {
    const endpoint =
      payload.type !== AvailableForms.retailerManualValidationRequestedInformation
        ? `/api/support/forms/${payload.type}?locale_filter=1`
        : '/api/me/manual-validation-requested-information';
    return http()
      .get(endpoint)
      .then(({ data }) => {
        if (data?.data?.length > 0 || data?.data?.requestedInformation?.length > 0) {
          commit('SET_DATA_FORM', { data, type: payload.type });
        }
      });
  },
  SendSalesforceForm({ dispatch, commit }, { form }): Promise<void> {
    commit('SET_ERRORS', []);
    const formData = jsonToFormData(form, {
      initialFormData: new FormData(),
      showLeafArrayIndexes: true,
      includeNullValues: false,
    });

    return http()
      .post('/api/support/forms', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        dispatch('hidePopinWanted');
        if (form.form === AvailableForms.retailerManualValidationRequestedInformation) {
          dispatch('markFormAsFilled');
        }
      })
      .catch(({ response }) => {
        if (response.status == 413) {
          commit('SET_ERRORS', {
            global: i18n.global.t('The attachments are too big.'),
          });
        } else if (response.data.errors) {
          commit('SET_ERRORS', response.data.errors);
        } else {
          commit('SET_ERRORS', {
            global: i18n.global.t('An error occurred. Please retry.'),
          });
        }
      });
  },
  markFormAsFilled({ dispatch }): void {
    http()
      .patch('/api/me/manual-validation-requested-information')
      .then(() => {
        dispatch('hideRequestedInformation', null, { root: true });
      });
  },
  hidePopinWanted() {}, //Do nothing, the reload action is subcribed by component
  popinClosed() {},
};

export const mutations = {
  SET_DATA_FORM(state: State, data): void {
    state.forms[data.type] = data.data.data;
  },
  SET_ERRORS(state: State, errors) {
    state.errors = errors;
  },
};

export const getters = {
  getForm:
    (state: State) =>
    (type: AvailableForms): Record<any, string> => {
      return state.forms[type];
    },
  getErrors: (state: State) => state.errors,
  findTopic:
    () =>
    (name: string, formName: string): object | undefined => {
      return TopicsMapping.find((topic) => {
        return topic.name === name && topic.formName === formName;
      });
    },
};

export default {
  namespaced: true,
  state: createInitialState(),
  actions,
  mutations,
  getters,
};
