<template>
  <div>
    <div
      class="cover-image"
      :style="'background-image: url(' + getCdnImage('large') + ')'"
      data-testid="cover-image"
    >
      <img
        class="brand-logo"
        :alt="brand.name"
        :src="getCdnImage('rounded')"
        data-testid="brand-logo"
      />
      <div class="cover-image__container">
        <FollowBrand
          :brand-id="brand.id"
          size="lg"
        />
        <AkButton
          v-if="(!user || userIsRetailer) && $isEnabled('messages')"
          ref="modalbtn"
          size="lg"
          outlined
          symbol="chat"
          data-testid="contactButton"
          @click="openChat()"
        >
          {{ $t('Contact the brand') }}
        </AkButton>
      </div>
    </div>

    <AkModal
      ref="chatModal"
      size="2xl"
      :with-padding="false"
    >
      <Chat
        :id="brand.id"
        :type="brand.type"
        :name="brand.name"
        :image="brand.images.rounded"
        origin="brand-page"
        :closable="false"
      />
    </AkModal>
  </div>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';
import { defineComponent, PropType } from 'vue';
import { AkButton, AkModal } from '@ankorstore/design-system';
import FollowBrand from '@/components/follow-brand.vue';
import { IBrandPageBrand } from '@/components/brand/brand.vue';
import { loginPopinMixin } from '@/mixins/login-popin';
import { RetailerFormType } from '@/types/retailer-form-type';
import Analytics from '@/services/analytics';
import MessagePopinOpenedEvent from '@/services/analytics/events/message-popin-opened-event';
import { OriginProperty } from '@/services/analytics/properties/origin-property';
import Chat from '@/components/popins/chat.vue';

export default defineComponent({
  name: 'BrandHeader',
  components: {
    AkButton,
    AkModal,
    Chat,
    FollowBrand,
  },
  mixins: [loginPopinMixin],
  props: {
    brand: {
      type: Object as PropType<IBrandPageBrand>,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['user', 'userIsRetailer']),
  },
  methods: {
    getCdnImage(imageType: 'rounded' | 'large'): string {
      const size = imageType === 'large' ? 'w=1536' : 'h=158';
      return `https://${this.$cdn}${this.brand.images[imageType]}?auto=compress&fm=pjpg&${size}&dpr=2`;
    },
    openChat() {
      if (!this.userIsRetailer) {
        this.openLoginPopin(RetailerFormType.Register);
      } else {
        ((this.$refs.modalbtn as DS['AkButton']).$el as HTMLElement).blur();
        Analytics.track(
          new MessagePopinOpenedEvent(
            {
              id: this.brand.id,
              name: this.brand.name,
              type: 'brand',
            },
            OriginProperty.BrandPage
          )
        );
        (this.$refs.chatModal as DS['AkModal']).openModal();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';
</style>
