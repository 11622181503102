export const PREORDER_SET_TOTALS_LOADING = 'PREORDER_SET_TOTALS_LOADING';
export const PREORDER_RESET_BULK_UPDATES = 'PREORDER_RESET_BULK_UPDATES';
export const PREORDER_SET_CART_LOADING = 'PREORDER_SET_CART_LOADING';
export const PREORDER_REFRESH_CHECKOUT = 'PREORDER_REFRESH_CHECKOUT';
export const PREORDER_CALCULATE_BRANDS_TOTALS = 'PREORDER_CALCULATE_BRANDS_TOTALS';
export const PREORDER_CALCULATE_CHECKOUT = 'PREORDER_CALCULATE_CHECKOUT';
export const PREORDER_UPDATE_BULK = 'PREORDER_UPDATE_BULK';
export const PREORDER_DELETE_ITEM = 'PREORDER_DELETE_ITEM';
export const PREORDER_CHANGE_ITEM_QUANTITY = 'PREORDER_CHANGE_ITEM_QUANTITY';
export const PREORDER_SET_PAYMENT_TERMS = 'PREORDER_SET_PAYMENT_TERMS';
