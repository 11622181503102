import { OriginProperty } from '../../properties/origin-property';
import { createSignUpProperty, SignUpProperty } from '../../properties/sign-up-property';
import AnalyticsEvent from '../analytics-event';
import { SignUpEventNameType } from './common/event-names';

export default class SignUpEvent extends AnalyticsEvent {
  public name: string;
  public properties: {
    signUp: SignUpProperty;
    origin: OriginProperty;
  };

  constructor(name: SignUpEventNameType, signUp: SignUpProperty, origin: OriginProperty = OriginProperty.SignUp) {
    super();
    this.name = name;
    this.properties = {
      signUp: createSignUpProperty(signUp),
      origin,
    };
  }
}
