import i18n from '@/services/i18n';
import { Collection, CollectionType } from './types';
import { AkLocale } from '@/types/base';
import { ProductHit } from '@/types/product';
import { BrandHit } from '@bc/discovery/domain/catalog';
import { ProductSearchService } from '../product';
import * as api from './api';

const createCollectionSubtitle = (collection: Collection): string => {
  if ('product' in collection.counts) {
    return i18n.global.tc('{count} Product | {count} Product | {count} Products', collection.counts.product, {
      count: collection.counts.product,
    });
  } else if ('brand' in collection.counts) {
    return i18n.global.tc('{count} Brand | {count} Brand | {count} Brands', collection.counts.brand, {
      count: collection.counts.brand,
    });
  }

  return '';
};

export const createCollectionType = (collection: Collection): CollectionType => {
  if ('product' in collection.counts) {
    return CollectionType.Product;
  } else if ('brand' in collection.counts) {
    return CollectionType.Brand;
  }
  return undefined;
};

export const createCard = (collection: Collection) => ({
  title: collection.name,
  image: collection.image,
  subtitle: createCollectionSubtitle(collection),
  url: `/collection/${collection.reference}`,
  uuid: collection.uuid,
  collectionType: createCollectionType(collection),
  itemType: createCollectionType(collection),
});

export const getCollection = async (
  lang: AkLocale,
  collection: Collection,
  userCountry: string
): Promise<{ type: CollectionType; items: ProductHit[] | BrandHit[] }> => {
  const collectionType = createCollectionType(collection);
  try {
    if (collectionType === CollectionType.Product) {
      const { results } = await ProductSearchService.searchProducts([
        { lang, userCountry, page: 0, hitsPerPage: 20, collectionId: collection.reference },
      ]);
      return {
        items: results.flatMap((result) => result.hits),
        type: CollectionType.Product,
      };
    } else if (collectionType === CollectionType.Brand) {
      return {
        items: await api.getBrandCollectionHits(lang, collection.reference, userCountry),
        type: CollectionType.Brand,
      };
    }
  } catch {
    // fail silently
  }

  return { items: undefined, type: undefined };
};
