<template>
  <ItemsFilter
    :items="items"
    :show-as-columns="showAsColumns"
  >
    <template #default="{ item }">
      <AkRadio
        :name="radioGroupName"
        :disabled="false"
        :value="item.value"
        :checked="item.isRefined"
        @change="notifyChanged(item)"
      >
        <FilterLabel
          :label="item.label"
          :count="item.count"
        />
      </AkRadio>
    </template>
  </ItemsFilter>
</template>

<script lang="ts">
export default { name: 'RadioFilter' };
</script>
<script setup lang="ts">
import { getCurrentInstance } from 'vue';
import { AkRadio } from '@ankorstore/design-system';
import { Item, ItemsFilterProps } from './types';
import FilterLabel from './filter-label.vue';
import ItemsFilter from './items-filter.vue';

withDefaults(defineProps<ItemsFilterProps>(), {
  showAsColumns: false,
});

const radioGroupName = getCurrentInstance().uid;

const emit = defineEmits(['changed']);
const notifyChanged = (item: Item) => emit('changed', item);
</script>
