export type DiscountInfoContent = {
  discount_title: string;
  brand_discount_text: string;
  ankorstore_discount_text: string;
};

export interface DiscountInfoState {
  brandPageDiscountContent: DiscountInfoContent | null;
}

const initialState: DiscountInfoState = {
  brandPageDiscountContent: null,
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    setBrandPageDiscountContent({ commit }, content) {
      commit('SET_BRAND_PAGE_DISCOUNT_CONTENT', content);
    },
  },
  mutations: {
    SET_BRAND_PAGE_DISCOUNT_CONTENT(state: DiscountInfoState, brandPageDiscountContent): void {
      state.brandPageDiscountContent = brandPageDiscountContent;
    },
  },
  getters: {},
};
