import { BoutiqueConstants } from '@bc/discovery/feature/boutique';

const getElementPosition = (el: Element, offset: { x: number; y: number }) => {
  const docEl = document.documentElement;
  const docRect = docEl.getBoundingClientRect();
  const elRect = el.getBoundingClientRect();
  return {
    x: elRect.left - docRect.left - offset.x,
    y: elRect.top - docRect.top - offset.y,
  };
};

export const getBoutiqueSectionOffset = (boutiqueSectionId: string) => {
  let offset = 0;
  const element = document.getElementById(boutiqueSectionId);
  if (element) {
    const headerHeight = document.querySelector('header').getBoundingClientRect().height;
    const headerWithNavHeight = headerHeight + BoutiqueConstants.NAV_SECTION_HEIGHT;
    offset = getElementPosition(element, { x: 0, y: headerWithNavHeight }).y;
  }
  return offset;
};
