import { Retailer } from '@/types/api/retailer';
import { CartBrand } from '@/types/cart';
import { OfferConditionResolutionContext } from '@/types/offer';

export function getSidewideOfferContext(this: { retailer: Retailer; brand: CartBrand }): OfferConditionResolutionContext {
  return {
    retailer: this.retailer,
    relativeEntities: {
      brand: this.brand,
    },
  };
}
