export const adsCampaignListMockApiResponse = {
  data: [
    {
      type: 'ad-campaigns',
      id: '01886d70-fe1f-764b-9efb-b1ffe2bf1446',
      attributes: {
        name: 'Test Ad Campaign',
        startDate: '2023-01-01T15:40:12Z',
        endDate: '2023-01-31T15:40:12Z',
        budget: 10000,
      },
    },
    {
      type: 'ad-campaigns',
      id: '01886d70-fe1f-764b-9efb-b1ffe2bf1444',
      attributes: {
        name: 'Test Ad Campaign',
        startDate: '2023-03-01T15:40:12Z',
        endDate: '2023-05-31T15:40:12Z',
        budget: 12000,
      },
    },
  ],
};

export const adsCampaignListDeserializedMock = {
  data: [
    {
      type: 'ad-campaigns',
      id: '018afb3d-86dd-78a6-a18c-059a470a51e8',
      meta: { adsCount: 5 },
      budget: { amount: 12857, currency: 'EUR', formatted: '€128.57' },
      createdAt: '2023-10-04T15:10:28Z',
      endDate: new Date('2023-10-13T23:59:59'),
      externalBrandId: '1ee36b13-7d93-667c-b235-4e5b21c219fc',
      name: 'girzhooirggr',
      startDate: new Date('2023-10-04T23:59:59'),
      status: 'ACTIVE',
      updatedAt: '2023-10-05T05:09:50Z',
    },
    {
      type: 'ad-campaigns',
      id: '018afafe-0c6a-7720-b02b-525a5f61d722',
      meta: { adsCount: 5 },
      budget: { amount: 144000, currency: 'EUR', formatted: '€1,440.00' },
      createdAt: '2023-10-04T14:01:07Z',
      endDate: new Date('2023-11-29T23:59:59'),
      externalBrandId: '1ee2cae2-a4ee-68ec-9ec8-5e7cc880b94a',
      name: 'test',
      startDate: new Date('2023-10-04T23:59:59'),
      status: 'ACTIVE',
      updatedAt: '2023-10-05T05:09:50Z',
    },
  ],
  meta: {
    page: {
      total: 2,
    },
  },
};

export function newCampaignCreationPayload() {
  return {
    data: {
      type: 'ad-campaign-setups',
      attributes: {
        ads: [
          {
            entityId: 'b3f2c2e0-4b1a-4b1a-8b1a-4b1a4b1a4b1a',
            name: 'Sequin Teddy T-Shirt',
          },
        ],
        brandId: 'test-brand-uuid',
        budgetIntervalAmount: 18000,
        budgetInterval: 'WEEKLY', // as BudgetInterval.Weekly
        endDate: new Date('2023-02-02T23:59:59.999').toISOString(),
        legalAgreement: true,
        name: 'Test Campaign',
        startDate: new Date('2023-01-01T00:00:00').toISOString(),
      },
    },
  };
}

export const campaignCreationPayload = newCampaignCreationPayload();

export const campaignInfoMockResponse = {
  data: {
    type: 'string',
    id: 'test-uuid',
    attributes: {
      name: 'test-name',
      externalBrandId: 'string',
      createdAt: '2023-10-04T15:10:28Z',
      updatedAt: '2023-10-04T15:10:28Z',
      startDate: '2023-11-04T15:10:28Z',
      endDate: '2023-11-20T15:10:28Z',
      status: 'ACTIVE',
      budget: {
        amount: 4000,
        currency: 'EUR',
        formatted: '€ 900',
      },
    },
    meta: {
      adsCount: 15,
      additionalProp1: {},
    },
  },
  jsonapi: {
    version: 'string',
    meta: {
      additionalProp1: {},
    },
  },
};

export const campaignAdsMockResponse = {
  data: [
    {
      type: 'string',
      id: 'string',
      attributes: {
        name: 'string',
        adCampaignId: 'string',
        externalEntityId: '4359359',
        status: 'ACTIVE',
        indexStatus: 'ONLINE',
        type: 'PRODUCT',
        subType: 'NATIVE',
        startDate: 'string',
        endDate: 'string',
        budget: {
          amount: 0,
          currency: 'string',
          formatted: 'string',
        },
        costPer: {
          amount: 0,
          currency: 'string',
          formatted: 'string',
        },
        clickThroughRate: 0,
        totalClicks: 0,
        totalClicksRetailer: 0,
        totalClicksGuest: 0,
        totalImpressions: 0,
        totalImpressionsRetailer: 0,
        totalImpressionsGuest: 0,
        totalAddToCart: 0,
        totalCost: 0,
        totalCostCount: 0,
        currentCostPerTarget: 0,
        pacingFactor: 0,
        costType: 'CLICK',
        createdAt: 'string',
        updatedAt: 'string',
      },
      relationships: {
        adCampaign: {
          data: {
            type: 'string',
            id: 'string',
            attributes: {
              name: 'string',
              externalBrandId: 'string',
              createdAt: 'string',
              updatedAt: 'string',
              startDate: 'string',
              endDate: 'string',
              status: 'ACTIVE',
              budget: {
                amount: 0,
                currency: 'string',
                formatted: 'string',
              },
            },
            meta: {
              adsCount: 0,
              additionalProp1: {},
            },
          },
        },
      },
    },
  ],
  included: [
    {
      additionalProp1: {},
    },
  ],
  meta: {
    page: {
      total: 0,
    },
    additionalProp1: {},
  },
  jsonapi: {
    version: 'string',
    meta: {
      additionalProp1: {},
    },
  },
};
