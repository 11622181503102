<script lang="ts">
//@ts-nocheck
import { defineComponent, PropType } from 'vue';
import { createWidgetMixin, createSuitMixin } from 'vue-instantsearch/vue3/es';
import { Widget } from 'instantsearch.js';
import { connectRefinementList } from 'instantsearch.js/cjs/connectors';
import type { Option } from '../../types/option';
import { getIdsToRefine, getFacetCounts, getSelectedOptions } from '../../utils/options';

const connectHierarchicalMultiSelectConnector: typeof connectRefinementList = (renderFn, unmountFn) => {
  return (widgetParams) => {
    const refinementListConnector = connectRefinementList(renderFn, unmountFn)(widgetParams);

    return {
      ...refinementListConnector,
      getWidgetRenderState(renderOptions) {
        const { results, state, helper } = renderOptions;

        return {
          ...refinementListConnector.getWidgetRenderState(renderOptions),
          refine: (values: string[]) => {
            helper.clearRefinements(widgetParams.attribute);

            values.forEach((value) => helper.addDisjunctiveFacetRefinement(widgetParams.attribute, value));

            helper.search();
          },
          results,
          searchParameters: state,
        };
      },
    };
  };
};

export default defineComponent<Widget>({
  name: 'HierarchicalMultiSelect',
  mixins: [
    createSuitMixin({ name: 'HierarchicalMultiSelect' }),
    createWidgetMixin(
      { connector: connectHierarchicalMultiSelectConnector },
      {
        $$widgetType: 'ais.refinementList',
      }
    ),
  ],
  props: {
    attribute: {
      required: true,
      type: String,
    },
    options: {
      required: true,
      type: Array as PropType<Option[]>,
    },
  },
  computed: {
    widgetParams(): Record<string, string | number | boolean> {
      return {
        attribute: this.attribute,
      };
    },
    selectedOptions(): Option[] {
      return getSelectedOptions(this.options, this.attribute, this.state?.results);
    },
    facetCounts(): Record<string, number> {
      return getFacetCounts(this.attribute, this.state?.results);
    },
    optionsWithFacetCount(): Option[] {
      return this.options.map((option) => ({
        count: this.facetCounts[option.id],
        ...option,
      }));
    },
  },
  methods: {
    refine(options: Option[]): void {
      const idsToRefine = getIdsToRefine(options);
      this.state?.refine(idsToRefine);
    },
  },
});
</script>

<template>
  <div v-if="state">
    <slot
      :options="optionsWithFacetCount"
      :selected-options="selectedOptions"
      :facet-counts="facetCounts"
      :refine="refine"
    />
  </div>
</template>
