import { unref } from 'vue';
import {
  absolutePrice,
  amountPropertiesToUnit,
  amountToUnit,
  comparePrices,
  formatPrice,
  getCurrencySymbol,
  subtractPrices,
  sumPrices,
  toMinorUnitProperties,
  toUnit,
  getConvertedPrice as getConvertedPriceUtil,
} from '@/utilities/price';
import useCurrency from '@/composables/use-currency';
import { Amount } from '@/types/amount';
import useI18n from '@/composables/use-i18n';
import rootStoreHelpers from '@/store/helpers';

const usePrice = () => {
  const { getCurrencyRate } = rootStoreHelpers.useGetters(['getCurrencyRate']);
  const { locale: i18nLocale } = useI18n();
  const { $currency } = useCurrency();
  return {
    absolutePrice,
    amountPropertiesToUnit,
    amountToUnit,
    comparePrices,
    formatPrice: (price: Amount, format = '$0.00', locale: string = unref(i18nLocale)) => formatPrice(price, format, locale),
    subtractPrices,
    sumPrices: (prices: Amount[]) => sumPrices(prices, $currency.value),
    toMinorUnitProperties,
    toUnit,
    getCurrencySymbol,
    getConvertedPrice: async (price: Amount) =>
      await getConvertedPriceUtil(price, $currency.value, getCurrencyRate.value(price.currency, $currency.value)),
  };
};

export default usePrice;
