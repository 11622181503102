import http from './http';
import { VatInfo, VatPatch } from '@/types/vat-info';
import type { AxiosResponse } from 'axios';

export const patchVatInfo = (vatInfo: VatInfo): Promise<AxiosResponse> =>
  http().patch(`/api/me/vat-info`, {
    above_intracomm_threshold: vatInfo.aboveIntracommThreshold,
    vat_exemption: vatInfo.vatExemption,
  });

export const sendVatNumber = (vatInfo: VatPatch): Promise<void> => http().patch(`/api/me/vat`, vatInfo);
