export default {
  namespaced: true,
  state: {},
  actions: {
    addEnvVar({ commit }, obj) {
      commit('SAVE_ENV_VAR', obj);
    },
  },
  mutations: {
    SAVE_ENV_VAR(state, obj) {
      state[obj.key] = obj.value;
    },
  },
};
