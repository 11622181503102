import { BillingItem } from '@/types/billing-item';
import { Currency } from 'dinero.js';

export const goToThanksPage = (cartId: string): void => {
  window.location.href = `/checkout/${cartId}/thanks`;
};

export const createAmountFromBillingItems = (items: BillingItem[], key: string, currency: Currency) => {
  return {
    amount: items.reduce((total, item) => total + item[key].amount, 0),
    currency,
  };
};
