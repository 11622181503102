<script lang="ts">
import { PropType, defineComponent, computed } from 'vue';
import { AkHeading1, AkButton } from '@ankorstore/design-system';
import { HeroSection } from '../types/hero-section';
import { useBreakpoints } from '@/modules/design-system-candidates';
import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';

export default defineComponent({
  name: 'Hero',
  components: { AkHeading1, AkButton },
  props: {
    section: {
      type: Object as PropType<HeroSection>,
      required: true,
    },
    name: { type: String, required: true },
  },
  setup(props) {
    const { isDesktop } = useBreakpoints();
    const image = computed(() => {
      return isDesktop.value ? props.section.images[0].url : props.section.images[1].url;
    });

    const trackClick = async () => {
      await Analytics.track(
        new UserClick({
          component: props.name,
          action: 'banner_clicked',
          name: props.section.title,
        })
      );
    };
    return { image, trackClick };
  },
});
</script>

<template>
  <div
    class="heroBoutique"
    :style="'background-image:url(' + image + ')'"
  >
    <div class="heroBoutique__text">
      <AkHeading1 class="ds-mb-3 heroBoutique__text--mainTitle">
        {{ section.title }}
      </AkHeading1>
      <PrismicRichText
        class="ds-mb-5 heroBoutique__text--description"
        :field="section.description"
      />
      <router-link
        class="heroBoutique__button"
        :to="section.cta.url"
        @click="trackClick"
      >
        <AkButton
          tabindex="-1"
          size="lg"
          color="white"
        >
          {{ section.cta.title }}
        </AkButton>
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@css/vue-import';
.heroBoutique {
  background-position: center;
  background-size: cover;
  position: relative;
  text-align: center;
  max-height: 450px;
  height: 307px;
  @include media-breakpoint-up(md) {
    height: 450px;
  }
  &__background {
    min-height: 400px;
    @include media-breakpoint-up(xl) {
      min-height: 0;
    }
  }
  &__text {
    @apply ds-text-white ds-p-6;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    width: 100%;
    &--mainTitle {
      font-weight: 600;
      font-size: 28px;
      line-height: 28px;
      @include media-breakpoint-up(md) {
        font-size: 68px;
        line-height: 68px;
      }
    }
    &--description {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      @include media-breakpoint-up(md) {
        font-size: 32px;
        line-height: 32px;
      }
    }
  }
}
</style>
