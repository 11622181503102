export interface Answer {
  id: number;
  label: string;
  answer_type: AnswerType;
  alias: string;
  prefilled_content: string;
}

export interface Question {
  alias: string;
  id: number;
  label: string;
  placeholder?: string;
  question_type: QuestionType;
  required: boolean;
  disabled?: boolean;
  hasLabel?: boolean;
  isRegister: boolean;
  type?: string;
  answers?: Array<Answer>;
}

export interface QuestionResponse {
  answer_id: number;
  content: string;
}

export enum QuestionType {
  Free = 'free',
  SingleChoiceDropdown = 'single_choice_dropdown',
  CompanySearch = 'company_search',
  CountrySelect = 'country_select',
  Checkbox = 'checkbox',
  Radio = 'radio',
  Phone = 'phone',
  Password = 'password',
  Date = 'date',
  GoogleMapAutocomplete = 'google_map_autocomplete',
  Promocode = 'promocode',
  Website = 'website',
  MultipleChoiceOption = 'multiple_choice_option',
  Text = 'text',
  Number = 'number',
  Email = 'email',
  Preferences = 'preferences',
}

export enum AnswerType {
  Longtext = 'longtext',
  Text = 'text',
  Free = 'free',
  CategoryFirstLevel = 'category_lvl0',
  CompanySearch = 'company_search',
}
