<template>
  <div class="sortby">
    <div class="sortby__head">
      <AkAccountHeading4 class="ds-block lg:ds-hidden ds-mb-4 ds-mt-4">
        {{ $t('Sort by :') }}
      </AkAccountHeading4>
      <div class="ds-flex">
        <span class="ds-hidden lg:ds-block ds-text-neutral-700">{{ $t('Sort by :') }}</span>
        <span class="ds-hidden lg:ds-block ds-ml-1 ds-font-regular ds-underline ds-text-primary">{{ sortBy?.label }}</span>
        <AkIcon
          class="ds-hidden lg:ds-block ds-ml-1"
          symbol="chevron-down"
        />
      </div>
    </div>
    <div class="sortby__content">
      <div
        v-for="item in items"
        :key="item.value"
        :value="item.value"
        class="sortby__content-item"
        data-testid="filtersSortbyItem"
        :class="{ 'sortby__content-item--selected': item.value === sortBy?.value }"
        @click.prevent="navigateToView(item)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const items = ref([]);

onBeforeMount(() => {
  items.value = [
    { value: null, label: t('discovery.filters.sortby.relevance'), original_name: 'relevance' },
    { value: `latest:desc`, label: t('discovery.filters.sortby.latest'), original_name: 'latest' },
  ];
});

const props = defineProps<{ currentSort: string }>();

const sortBy = computed(() => {
  return items.value.find((item) => item.value === props.currentSort);
});

const emit = defineEmits(['update:sortBy']);

const navigateToView = (item) => {
  const hash = new URLSearchParams(route.hash.slice(1));
  hash.delete('sortBy');
  if (item.value) {
    hash.set('sortBy', item.value);
  }

  const newRoute = { ...route, ...{ hash: Array.from(hash).length ? `#${hash}` : '' } };
  emit('update:sortBy', item.value);
  return router.push(newRoute);
};
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.sortby {
  @apply ds-pb-4;
  &__content {
    @apply ds-text-neutral-700 ds-block ds-w-full ds-flex ds-bg-white;
    z-index: 9999;
    flex-wrap: wrap;
    &-item {
      @apply ds-py-2 ds-rounded-lg ds-p-4 ds-bg-white ds-border ds-border-solid ds-border-neutral-700;
      margin-right: 5px;
      &--selected {
        @apply ds-bg-accent-700 ds-text-white;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .sortby {
    @apply ds-relative ds-pb-0;
    &__content {
      @apply ds-hidden ds-absolute ds-m-5;
    }
    &:hover {
      .sortby__content {
        @apply ds-block ds-rounded-md ds-py-2 ds-m-0 ds-border ds-border-solid ds-border-neutral-300;
        &-item {
          @apply ds-m-0 ds-bg-white ds-border-none;
          border-radius: 0;
          padding: 10px 12px;
          cursor: pointer;
          &:hover {
            @apply ds-bg-neutral-300;
          }
          &--selected {
            @apply ds-bg-neutral-300 ds-text-neutral-700;
          }
        }
      }
    }
  }
}
</style>
