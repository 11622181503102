import Analytics from '@/services/analytics';
import { ProductAddedToWishlistEvent } from '@/services/analytics/events/product-added-to-wishlist.event';
import { ProductRemovedFromWishlistEvent } from '@/services/analytics/events/product-removed-from-wishlist.event';
import { ProductWishlistCreatedEvent } from '@/services/analytics/events/product-wishlist-created.event';
import { ProductWishlistDeletedEvent } from '@/services/analytics/events/product-wishlist-deleted.event';
import { ProductWishlistOrderChangedEvent } from '@/services/analytics/events/product-wishlist-order-changed.event';
import List from '@/types/wishList';

export const trackProductAddedToWishlist = (list: List, productIds: number[]) =>
  (productIds ?? []).forEach((product_id) =>
    Analytics.track(
      new ProductAddedToWishlistEvent({
        wishlist_id: list?.id,
        wishlist_name: list?.name,
        product_id,
      })
    )
  );

export const trackProductRemovedFromWishlist = (list: List, productIds: number[]) =>
  (productIds ?? []).forEach((product_id) =>
    Analytics.track(
      new ProductRemovedFromWishlistEvent({
        wishlist_id: list?.id,
        wishlist_name: list?.name,
        product_id,
      })
    )
  );

export const trackProductWishlistCreated = (list: List) =>
  Analytics.track(
    new ProductWishlistCreatedEvent({
      wishlist_id: list?.id,
      wishlist_name: list?.name,
    })
  );

export const trackProductWishlistDeleted = (list: List) =>
  Analytics.track(
    new ProductWishlistDeletedEvent({
      wishlist_id: list?.id,
      wishlist_name: list?.name,
    })
  );

export const trackProductWishlistOrderChanged = (list: List) =>
  Analytics.track(
    new ProductWishlistOrderChangedEvent({
      wishlist_id: list?.id,
      wishlist_name: list?.name,
    })
  );
