<template>
  <div class="ak-progress-bar">
    <div
      :class="{ 'ak-progress--ending': $store.state.progressBar === 'ending' }"
      class="ak-progress"
      :style="progressStyle"
    ></div>
  </div>
</template>

<script lang="ts">
import { mapActions } from 'vuex';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProgressBar',
  data() {
    return {
      current_progress: 0,
      step: 0.5,
      interval: null,
      progress: 0,
    };
  },
  computed: {
    progressStyle() {
      return {
        width: this.progress + '%',
      };
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.current_progress += this.step;
      this.progress = Math.round((Math.atan(this.current_progress) / (Math.PI / 2)) * 100 * 1000) / 1000;

      if (this.$store.state.progressBar === 'ending') {
        this.progress = 100;
        clearInterval(this.interval);

        setTimeout(() => {
          this.hideProgressBar();
        }, 350);

        return;
      }
    }, 100);
  },
  methods: {
    ...mapActions(['hideProgressBar']),
  },
});
</script>

<style lang="scss" scoped>
@import '@css/vue-import';

.ak-progress-bar {
  width: 100%;
}

.ak-progress {
  height: 3px;
  @apply ds-bg-primary;
  transition: all 300ms ease;
  width: 0;
}
</style>
