<template>
  <BannerCarousel
    :items="section.collections"
    :background-color="section.backgroundColor"
    :background-images="section.images"
    :left-block-size="leftBlockSize"
  >
    <template #default>
      <div
        :class="{
          'collection-banner__left-block-aligner': true,
          'collection-banner__left-block-aligner--reversed': !shouldDisplayCta,
        }"
      >
        <h2 class="collection-banner__title">
          {{ section.title }}
        </h2>
        <PrismicRichText
          :class="{
            'collection-banner__description': true,
            'collection-banner__description--bold': !shouldDisplayCta,
          }"
          :field="section.description"
        />
        <AkButton
          v-if="shouldDisplayCta"
          class="collection-banner__cta-button"
          color="white"
          @click="$emit('boutiqueClicked')"
        >
          <RouterLink :to="section.cta.url">
            {{ section.cta.title }}
          </RouterLink>
        </AkButton>
      </div>
    </template>

    <template #mobile-cta>
      <AkButton color="white">
        <RouterLink :to="section.cta.url">
          {{ section.cta.title }}
        </RouterLink>
      </AkButton>
    </template>

    <template #item="{ item }">
      <Card
        :card="item"
        @clicked="$emit('collectionClicked', item)"
      />
    </template>
  </BannerCarousel>
</template>

<script lang="ts">
import { computed, defineComponent, ref, PropType } from 'vue';
import { AkButton } from '@ankorstore/design-system';
import useBreakpoints from '@/modules/design-system-candidates/breakpoints/use-breakpoints';
import Card from './card.vue';
import { CollectionBanner } from './types';
import { BannerCarousel } from '@bc/discovery/ui/banner';
import { PrismicRichText } from '@prismicio/vue';
import { RouterLink } from 'vue-router';

export default defineComponent({
  name: 'CollectionBanner',
  components: { AkButton, Card, BannerCarousel, PrismicRichText, RouterLink },
  props: {
    section: {
      type: Object as PropType<CollectionBanner>,
      required: true,
    },
    leftBlockSize: {
      type: String as PropType<'column' | 'fixed'>,
      required: false,
      default: 'column',
    },
  },
  emits: ['collectionClicked', 'boutiqueClicked'],
  setup(props) {
    const { isMobile, isXlMobile, isTablet, isXlTablet, isDesktop, isXlDesktop } = useBreakpoints();

    const numberOfCard = computed(() => (isMobile.value ? 4 : 6));

    const cardProduct = ref(null);

    const shouldDisplayCta = computed(() => props.section.cta?.title && props.section.cta?.url);

    return {
      isMobile,
      isXlMobile,
      isTablet,
      isXlTablet,
      isDesktop,
      isXlDesktop,
      cardProduct,
      numberOfCard,
      shouldDisplayCta,
    };
  },
});
</script>

<style scoped lang="scss">
@use '@css/abstracts/design-tokens' as t;

.collection-banner {
  &__left-block-aligner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    padding: theme('spacing.5');

    @include t.lg {
      align-items: flex-start;
    }

    &--reversed {
      flex-direction: column-reverse;
    }
  }

  &__title {
    @include t.text('3xl');

    margin-bottom: theme('spacing.2');
    font-weight: 600;
    text-align: center;

    @include t.md {
      @include t.text('4xl');

      text-align: left;
    }
  }

  &__description {
    @include t.text('sm');

    &--bold {
      font-weight: 600;
    }
  }

  &__cta-button {
    margin-top: theme('spacing.7');
  }

  &__right-block {
    padding-top: theme('spacing.7');
    @include t.lg {
      width: 0;
      flex: 1 1 100%;
      padding: theme('spacing.7') 0;
    }
  }

  &__mobile-cta {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: theme('spacing.5');
    margin-bottom: theme('spacing.6');
  }
}
</style>
