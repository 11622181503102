<template>
  <AisHierarchicalMenu
    v-bind="$attrs"
    :transform-items="transformItems"
    :limit="100"
  >
    <template #default="{ items, refine, createURL }">
      <HierarchicalLinkFilter
        :items="items"
        :create-url="createURL"
        @item-clicked="handleItemClicked(refine, $event)"
      />
    </template>
  </AisHierarchicalMenu>
</template>

<script setup lang="ts">
import { AisHierarchicalMenu } from 'vue-instantsearch/vue3/es';
import { HierarchicalLinkFilter } from '@bc/discovery/ui/filters';
import { overrideLabels } from '../utils/override-labels';
import type { LabelOverrides } from '../types/label-overrides';
import type { Item } from '@bc/discovery/ui/filters';

const props = defineProps<{ labelOverrides: LabelOverrides }>();
const emit = defineEmits(['item-clicked']);

const transformItems = (items: Item[]) => {
  overrideLabels(props.labelOverrides, items);
  return items;
};

const handleItemClicked = (refine: (value: string) => void, item: Item) => {
  emit('item-clicked', item);
  refine(item.value);
};
</script>
