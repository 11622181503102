import AnalyticsEvent from './analytics-event';
import { Recipient, createRecipientProperty } from '../properties/conversation-property';
import { OriginProperty } from '../properties/origin-property';

export default class MessagePopinOpenedEvent extends AnalyticsEvent {
  public readonly name = 'Message Popin Opened';
  public properties: {
    recipient: Recipient;
    origin: OriginProperty;
  };

  constructor(recipient: Recipient, origin: OriginProperty) {
    super();
    this.properties = {
      recipient: createRecipientProperty(recipient),
      origin,
    };
  }
}
