<template>
  <div
    v-if="brand"
    ref="floatingCart"
    class="floating-cart"
    data-testid="floating-cart"
    :class="{
      'floating-cart-bottom': bottomPage,
      'floating-cart-bottom__close': !isMiniCartOpen && bottomPage && !hasAdditionalCost,
      'floating-cart-bottom__close--above': !isMiniCartOpen && bottomPage && hasAdditionalCost,
    }"
    :style="{ height: maxHeight }"
  >
    <transition name="congrat-fade">
      <div
        v-if="showCongratMessage"
        class="congratulation-wrapper"
        :style="{ bottom: congratBottom + 'px' }"
      >
        <div class="congratulation__text">
          <strong>{{ $t('Awesome!') }}</strong>
          <p>{{ $t('Minimum reached!') }}</p>
        </div>
      </div>
    </transition>
    <div
      ref="topPart"
      class="floating-cart__brands"
    >
      <div
        v-if="bottomPage"
        data-testid="button-display-cart"
        class="ds-px-4 ds-py-4 ds-flex ds-items-center ds-w-full ds-relative ds-cursor-pointer hover:ds-bg-neutral-300"
        @click="openMiniCart()"
      >
        <div>
          <RetailerCartButton />
        </div>
        <div class="ds-font-bold ds-ml-3">
          {{ $t('Your cart') }}
        </div>
        <div class="ds-ml-auto">
          <span
            v-if="!$isEnabled('OXP-1688')"
            class="ds-font-bold ds-mr-3"
          >{{ cartAmount }}</span>
          <AkIcon
            :symbol="symbol"
            size="md"
          />
        </div>
      </div>
      <ul class="carts">
        <!-- that first li is the case where you have no item in the cart -->
        <li
          v-if="!cart"
          class="expanded warn"
          :class="{ 'ds-bg-warm-white': bottomPage }"
        >
          <div class="cart-header ds-p-4">
            <div class="brand-item">
              <img :src="'https://' + $cdn + brand.images.rounded + '?auto=compress&fm=pjpg&h=40&dpr=2'">
              <div class="brand-name-container">
                <span class="brand-name">{{ brand.name }}</span>
                <div class="minimum-indication-wrapper">
                  <span
                    v-if="!brandIsOpenedInUserCountry(brand)"
                    class="minimum-indication"
                  >
                    {{ $t('Sorry this brand is not available in your country.') }}
                  </span>
                  <div
                    v-if="brand.event_special_discount"
                    class="cart-row ds-mb-1 ds-text-discount ds-text-xs ds-flex ds-flex-row"
                  >
                    <AkIcon
                      symbol="check2-circle"
                      size="sm"
                      class="ds-mr-1"
                    />
                    {{
                      $t('@@promote_and_engage.floating_cart.brand_discount_applied', {
                        brandExtraDiscount: brand.event_special_discount,
                      })
                    }}
                  </div>
                  <span
                    v-else
                    class="minimum-indication"
                  >
                    <i18n-t
                      keypath="Add {0} to reach the minimum"
                      tag="span"
                    >
                      {{ brandMinimum }}
                    </i18n-t>
                  </span>
                </div>
              </div>
              <AkIcon
                symbol="chevron-down"
                size="sm"
              />
            </div>
          </div>
          <PreorderBanner
            v-if="showPreorderBannerCurrentCart"
            data-testid="PreorderBannerCurrentCart"
          />
          <ul
            v-if="!isCartLoading && brandIsOpenedInUserCountry(brand)"
            class="cart-items"
          >
            <li>
              <p class="floating-cart__empty">
                {{ $t("You don't have any item for this brand yet.") }}
              </p>
            </li>
          </ul>
        </li>
        <li
          v-for="c in carts"
          :key="c.id"
          :class="{
            warn: !c.minimum.above,
            success: c.minimum.above,
            expanded: c.brand.id === brand.id,
          }"
          :data-testid="`cart-${c.id}`"
        >
          <div
            class="cart-header ds-p-4 ds-flex ds-flex-col"
            :class="{ 'ds-bg-warm-white': c.brand.id === brand.id && bottomPage }"
          >
            <FloatingCartBrand
              :cart="c"
              :is-selected="c.brand.id === brand.id"
              @change-current-brand="changeCurrentBrand"
            />
          </div>
          <PreorderBanner
            v-if="showPreorderBannerOnExistingCart(c)"
            data-testid="preorderCartNotification"
          />
          <ul
            v-if="cart && !isCartLoading && c.id === cart.id"
            class="cart-items"
            :class="{ 'cart-items__enable': c.brand.id === brand.id && bottomPage }"
          >
            <BannerReimbursement v-if="shouldShowInternationalShippingReimbursement" />
            <template v-if="c.items.length">
              <CartItem
                v-for="item in c.items"
                :key="item.id"
                :use-router-link="true"
                :cart="c"
                :item="item"
                :expanded="!!item.variant"
                :special-discount="brandHasDiscount"
              />
            </template>
            <li v-else>
              <p class="empty-cart-message">
                {{ $t("You don't have any item for this brand yet.") }}
              </p>
            </li>
          </ul>
        </li>
      </ul>
      <PreorderBanner
        v-if="showPreorderBannerNoCartExists"
        data-testid="preorderBannerNoCarts"
      />
    </div>
    <div class="floating-cart__breakdown">
      <OrderTotal
        :title="$t('retailer.floatingCart.aboveBrandsSection.title')"
        :carts="aboveCarts"
      />
      <FeesAndTaxes
        v-if="hasCarts"
        :title="$t('retailer.floatingCart.feesSection.title')"
        :visible-billing-items="['shipping']"
        :display-selected="true"
      />
      <Discounts :items="offerDiscountBillingItems" />

      <div v-if="!$isEnabled('OXP-1688')">
        <TotalsLoader v-show="isCalculatingTotal" />
        <div class="ds-flex ds-justify-between ds-px-4 ds-py-5 ds-font-bold ds-text-base">
          <div>
            {{ $t('retailer.floatingCart.total.label') }}
          </div>
          <div class="ds-flex">
            <div
              v-if="totalBeforeDiscount"
              class="cart-amount discounted ds-mr-2"
              data-testid="totalBeforeDiscount"
            >
              {{ totalBeforeDiscount }}
            </div>
            <div data-testid="total">
              {{ cartAmount }}
            </div>
          </div>
        </div>
      </div>
      <div class="floating-cart__actions">
        <router-link
          to="/carts"
          data-testid="floatingCartBasketLink"
          :class="{ 'cart-link--disabled': showCartButtonSpinner }"
          @click="triggerCartLoadingState"
        >
          <AkButton
            size="lg"
            class="ds-w-full"
            :loading="showCartButtonSpinner"
          >
            {{ $t('retailer.floatingCart.checkoutButton.text') }}
          </AkButton>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import TotalsLoader from '@/components/cart/totals-loader.vue';
import { AkButton, AkIcon } from '@ankorstore/design-system';
import { OrderTotal, FeesAndTaxes } from '@/modules/carts/order-summary/index';
import Discounts from '@/modules/carts/order-summary/discounts.vue';

import Analytics from '@/services/analytics';
import { FloatingCartClickEvent } from '@/services/analytics/events/cart/cart-clicks-event';
import { Cart } from '@/types/cart';
import { Amount, isAmountObject } from '@/types/amount';
import { BillingItemType } from '@/types/billing-item';
import { createAmountFromBillingItems } from '@/utilities/payment';
import CartItem from '@/components/cart/cart-item.vue';

import BannerReimbursement from '@/modules/carts/brand-banners/banner-reimbursement.vue';
import UserClick from '@/services/analytics/events/user-click.event';
import { capturePurchasingException } from '@/sentry/helper';
import { showInternationalShippingReimbursement } from '@/modules/cart-2/utils';
import { formatPrice, sumPrices } from '@/utilities/price';
import { getCurrency } from '@/services/metas/currency';
import { FeatureFlag, isEnabled } from '@/services/features';

import FloatingCartBrand from './floating-cartbrand.vue';
import PreorderBanner from '@/components/cart/preorder-banner.vue';
import { SET_CART_BRAND_COUNTRY } from '@/store/cart/action-types';

export default defineComponent({
  name: 'FloatingCart',
  components: {
    PreorderBanner,
    AkButton,
    BannerReimbursement,
    FloatingCartBrand,
    OrderTotal,
    FeesAndTaxes,
    Discounts,
    TotalsLoader,
    AkIcon,
    CartItem,
  },
  props: {
    bottomPage: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      totalBoxHeight: 0,
      showCongratMessage: false,
      scrollFooter: 0,
      congratBottom: 210,
      cartToDisplay: null,
      brandPristine: true,
      showCartButtonSpinner: false,
      timeoutIds: [],
      isMiniCartOpen: false,
      FeatureFlag,
    };
  },
  computed: {
    ...mapGetters('cart', [
      'getCartsForFloatingCart',
      'checkout',
      'cartByBrandId',
      'isTotalsLoading',
      'isCartLoading',
      'aboveCarts',
      'discountsBillingItems',
      'shippingBillingItems',
      'hasRetailerShippingFees',
    ]),
    ...mapGetters('cartPreorder', ['checkPreorderCartIsEmpty']),
    ...mapGetters('cartPreorder', { preorderCartByBrandId: 'cartByBrandId' }),
    ...mapGetters('shop', ['brandById']),
    ...mapGetters(['userCountry', 'shouldApplyCountryRestrictions', 'isEligibleLiftDayForFreeShipping']),
    ...mapGetters('headerBadges', ['getProductBadgesCount']),
    ...mapGetters('offers', ['b2rEligibility']),
    ...mapGetters(['retailer']),
    symbol() {
      return this.isMiniCartOpen ? 'chevron-down' : 'chevron-up';
    },
    maxHeight() {
      if (!this.bottomPage) {
        return '100%';
      }
      if (!this.isMiniCartOpen) {
        if (this.hasAdditionalCost) {
          return '156px';
        } else {
          return '135px';
        }
      }
      return window.innerHeight - document.querySelector('header[data-testid="header"]').getBoundingClientRect().height + 'px';
    },
    hasAdditionalCost(): boolean {
      return this.cart?.billing_items?.some(({ billing_item_type }) =>
        [BillingItemType.retailerShippingFees].includes(billing_item_type)
      );
    },
    brand() {
      if (this.$route && ['brand', 'product', 'brand-collection'].includes(this.$route.name) && this.$route.params.brand) {
        return this.brandById(parseInt(this.$route.params.brand.split('-').slice(-1)[0], 10));
      }
      return null;
    },
    brandMinimum() {
      if (typeof this.brand?.minimum?.amount === 'number') {
        return formatPrice(this.brand?.minimum);
      }
      return '';
    },
    brandHasDiscount(): boolean {
      return this.brand?.event_special_discount > 0;
    },
    cart() {
      return this.cartByBrandId(this.brand?.id);
    },
    cartAmount() {
      // see sentry issue https://sentry.io/organizations/ankorstore/issues/3667803047/?project=1459054&referrer=alerts-related-issues-issue-stream
      const currency = getCurrency() || 'EUR';
      if (typeof this.checkout?.total_amount === 'number') {
        return formatPrice({
          amount: this.checkout.total_amount,
          currency,
        });
      }

      if (!this.checkout || !isAmountObject(this.checkout.total_amount)) {
        return '';
      }

      return formatPrice(this.getTotalAmount());
    },
    isAbove(): boolean {
      return this.cart !== null && this.cart.minimum.above;
    },
    offerDiscountBillingItems() {
      return this.discountsBillingItems.filter((item) => item.billing_item_type === BillingItemType.offerDiscount);
    },
    isCalculatingTotal() {
      return this.isTotalsLoading;
    },
    carts(): Cart[] {
      return this.getCartsForFloatingCart(this.brand.id);
    },
    totalBeforeDiscount(): string {
      if (!this.checkout || !isAmountObject(this.checkout.total_amount)) {
        return '';
      }

      const cartTotal = this.getTotalAmount();
      const cartTotalExclDiscounts = this.getTotalAmount(false);

      if (cartTotal.amount === cartTotalExclDiscounts.amount) {
        return '';
      }

      return formatPrice(cartTotalExclDiscounts);
    },
    hasCarts(): boolean {
      return this.carts?.length > 0;
    },
    shouldShowInternationalShippingReimbursement() {
      return showInternationalShippingReimbursement(this.cart, this.retailer);
    },

    showPreorderBannerCurrentCart() {
      return (
        this.$isEnabled('oxp-1484-r2') &&
        this.$isEnabled('oxp-1484-r3') &&
        !this.checkPreorderCartIsEmpty &&
        !!this.preorderCartByBrandId(this.brand.uuid)
      );
    },
    showPreorderBannerNoCartExists() {
      return (
        this.$isEnabled('oxp-1484-r2') &&
        this.$isEnabled('oxp-1484-r3') &&
        !this.checkPreorderCartIsEmpty &&
        !this.preorderCartByBrandId(this.brand.uuid)
      );
    },
  },
  watch: {
    isAbove(newValue, oldValue) {
      if (this.brandPristine) {
        return;
      }
      if (newValue !== oldValue && newValue === true) {
        this.showCongratMessage = true;

        setTimeout(() => {
          this.showCongratMessage = false;
        }, 3000);
      }

      if (newValue !== oldValue && newValue === true && this.$refs.minimumIndication?.[0]) {
        setTimeout(() => {
          this.$refs.minimumIndication[0].classList.add('congrats');

          setTimeout(() => {
            this.$refs.minimumIndication[0].classList.remove('congrats');
          });
        }, 1);
      }
    },
    async checkout() {
      if (this.$refs.minimumIndication?.[0] && this.cart) {
        if (!this.cart.minimum.above && this.$refs.minimumIndication[0]) {
          if (this.brandPristine) {
            return;
          }
          const element = this.$refs.minimumIndication[0];
          setTimeout(() => {
            element.classList.add('shake');

            setTimeout(() => {
              element.classList.remove('shake');
            }, 600);
          }, 1);
        }
      }
    },
    cart: {
      handler(cart: Cart) {
        // NOTE: The brand country was previously being written to the cart object directly and causing an error.
        // It was changed to an action to avoid the error of writing to a readonly propety outside the Vuex store.
        // Is it necessary to set the brand country on the cart?
        if (cart) {
          this[SET_CART_BRAND_COUNTRY]({ cartId: cart.id, country: this.brand?.country });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.avoidCongratsMessageWhenCartChanges();
    window.addEventListener('resize', this.closeMinicart);

    // if we visit again a brand for which we had a cart in saved_for_later state, we change it.
    if (this.cart?.saved_for_later) {
      this.cartToDisplay = this.cart;
      this.persistSaveCartForLater({
        cart: this.cart,
        isGotoSavedForLater: false,
      });
    }
  },
  unmounted() {
    this.timeoutIds.forEach(clearTimeout);
  },
  methods: {
    ...mapActions('cart', ['persistSaveCartForLater', SET_CART_BRAND_COUNTRY]),
    showPreorderBannerOnExistingCart(currentCart: Cart): boolean {
      return (
        this.$isEnabled('oxp-1484-r2') &&
        this.$isEnabled('oxp-1484-r3') &&
        currentCart.brand.id === this.brand.id &&
        this.preorderCartByBrandId(this.brand.uuid)
      );
    },
    shouldDisplayRetailerShippingFees(cart: Cart) {
      return this.hasRetailerShippingFees(cart);
    },
    closeMinicart() {
      this.isMiniCartOpen = false;
    },
    openMiniCart() {
      this.isMiniCartOpen = !this.isMiniCartOpen;
      Analytics.track(
        new UserClick({
          component: 'minicart',
          action: this.isMiniCartOpen ? 'open' : 'close',
        })
      );
    },
    getTotalAmount(includeOffers = true): Amount {
      const emptyAmount = { amount: 0, currency: getCurrency() };
      const offerDiscountAmount = includeOffers
        ? createAmountFromBillingItems(this.offerDiscountBillingItems, 'amount', getCurrency())
        : emptyAmount;

      try {
        return sumPrices(
          [
            this.checkout.total_amount,
            offerDiscountAmount,
            createAmountFromBillingItems(this.shippingBillingItems, 'amount', getCurrency()),
          ],
          getCurrency()
        );
      } catch (e) {
        capturePurchasingException(e);
        return emptyAmount;
      }
    },
    brandIsOpenedInUserCountry(brand): boolean {
      if (!brand.opened_countries || !this.shouldApplyCountryRestrictions) {
        return true;
      }
      return brand.opened_countries?.includes(this.userCountry) ?? false;
    },
    changeCurrentBrand(brand) {
      this.avoidCongratsMessageWhenCartChanges();
      this.$store.commit('shop/STORE_BRAND', brand);
      this.$router.push(brand.link);
      (this.$refs.topPart as HTMLElement).scrollTo(0, 0);
      Analytics.track(
        new UserClick({
          component: 'minicart',
          action: 'navigate_to_brand',
        })
      );
    },
    avoidCongratsMessageWhenCartChanges() {
      this.brandPristine = true;

      setTimeout(() => {
        this.brandPristine = false;
      }, 1000);
    },
    triggerCartLoadingState() {
      Analytics.track(new FloatingCartClickEvent(this.getProductBadgesCount));

      this.$store.commit('cart/SET_CART_BUTTON_LOADING', true);
      this.showCartButtonSpinner = true;
    },
    formatPrice,
    isEnabled,
  },
});
</script>

<style scoped lang="scss">
.floating-cart {
  &__breakdown {
    @apply ds-bg-white ds-z-10 ds-w-full;
  }

  &__actions {
    @apply ds-p-4 ds-pt-0 ds-pb-5;
  }

  &__empty {
    font-size: 12px;
    padding: 0;
  }

  .cart-link--disabled {
    @apply ds-pointer-events-none ds-cursor-default;
  }
}

.congratulation-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 209px;
  background: rgba(white, 0.9);
  background-image: url('/images/confettis.svg');
  display: flex;
  align-items: center;
  justify-self: center;
  z-index: 3;

  .congratulation__text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    z-index: 2;
    opacity: 0;
    animation: fade-in-congrat 300ms 300ms forwards;
  }
}

.congrat-fade-enter-active {
  animation: fade-in-congrat 300ms forwards;
}

.congrat-fade-leave-active {
  animation: fade-in-congrat 300ms forwards reverse;

  .congratulation__text {
    opacity: 1;
    animation: fade-in-congrat 300ms reverse;
  }
}

@keyframes fade-in-congrat {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.9;
  }
}

.floating-cart-bottom {
  @apply ds-fixed ds-bottom-0 ds-right-0 ds-z-50 ds-shadow ds-border ds-max-w-md;
  height: auto;
  overflow-y: auto;
  max-height: 100vh;

  .cart-items__enable {
    @apply ds-bg-warm-white;
  }

  &__close {
    overflow: hidden;
  }
}
.shipping-fee-message {
  @apply ds-text-info ds-text-xs ds-mb-2 ds-pl-7;
}
</style>
