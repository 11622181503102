import { Category, Property, PropertyWithFormat, Variant } from '@/types/product';
import { Tag } from '@/services/metas/tag';
import { Amount } from '@/types/amount';
import Country from '@/types/country';
import { ShapeProperties } from '@/types/product-shape';
import { Option, DiscountType } from '@/types/product';
import { Currency } from 'dinero.js';
import { Brand } from '@/types/api/brand';

export enum StatisticsMissingFilter {
  all = 'all',
  category = 'category',
  casing = 'casing',
  vat_rate = 'vat_rate',
}

export enum IssueScope {
  integration = 'integration',
  internal_state = 'internal_state',
  external_state = 'external_state',
}

export enum IssueSeverity {
  error = 'error',
  warning = 'warning',
}

export interface IntegrationPlatform {
  name: string;
  image: string;
}

export interface IntegrationInternalState {
  field: string;
  message: string;
  reason: string;
  request_field: string;
  scope: IssueScope;
  severity: IssueSeverity;
}

export enum CatalogIntegrationType {
  GOOGLE_SPREADSHEET = 'google_spreadsheet',
  CSV_SPREADSHEET = 'csv_spreadsheet',
  SHOPIFY = 'shopify',
  XLSX_SPREADSHEET = 'xlsx_spreadsheet',
}

export type ProductVariantDraft = Omit<Variant, 'shape_properties'> & {
  shape_properties: ShapeProperties;
};

export default interface ProductDraft {
  uuid?: string;
  id?: number | string;
  name: string;
  categories: Category[];
  product_type_id: number;
  attributes: object;
  currency: Currency;
  unit_multiplier: number;
  retail_price: Amount;
  wholesale_price?: Amount;
  original_wholesale_price: Amount;
  discount_rate?: number;
  discount_type?: DiscountType | null;
  images: string[];
  description: string;
  properties: Property[];
  tags: string[];
  made_in: Country | number | null;
  variants: ProductVariantDraft[];
  vat_rate?: number;
  hs_code?: string;
  options: Option[];
  brand?: Brand;
  available_at?: string;
  internal_product_id?: number;
}

export interface ProductIntegration {
  id: number;
  uuid: string;
  type: CatalogIntegrationType;
  platform: IntegrationPlatform;
  status: IntegrationStatus;
  platform_product_id?: string;
  external_product_id?: number;
  internal_product_id?: number;
  internal_state: ProductDraft;
  issues: IntegrationInternalState[];
  product_updated_at: string;
  integrated_at: string;
  created_at: string;
  updated_at: string;
}

export interface ProductDraftIssues {
  [requestField: string]: string[];
}

export interface FormattedProductDraft extends Omit<ProductDraft, 'tags' | 'properties'> {
  uuid: string;
  tags: Tag[];
  properties: PropertyWithFormat[];
  errors: ProductDraftIssues;
}

export interface CatalogIntegrationSetting {
  images_google_folder_url: string;
  generated_google_spreadsheet: CatalogIntegrationGeneratedGoogleSpreadsheetSetting | null;
  empty_xlsx_download_link: string;
  created_at: string;
  updated_at: string;
}
export interface CatalogIntegrationGeneratedGoogleSpreadsheetSetting {
  instructions_sheet_url: string;
  data_sheet_url: string;
}

export enum Workflow {
  ACTIVATE = 'activate',
  UPDATE = 'update',
  IMPORT = 'import',
  REPLACE = 'replace',
}

export interface ExportCatalogue {
  id: number;
  uuid: string;
  brand_id: number;
  status: ExportCatalogueStatus;
  type: ExportType;
  output_format: string;
  google_spreadsheet_url: string;
  google_folder_url: string;
  filename: string;
  created_at: string;
  updated_at: string;
  export_started_at: string;
  export_finished_at: string;
}

export interface ImportPlayload {
  spreadsheet_url: string;
  drive_url: string;
  import_mode: string;
}

export interface GoogleBatchPayload {
  payload: {
    spreadsheet_url: string;
    drive_url: string;
  };
}

export interface CsvBatchPayload {
  payload: {
    spreadsheet_upload_file_id: string;
    mapping_id: string;
  };
}

export interface XlsxBatchPayload {
  payload: {
    spreadsheet_upload_file_id: string;
    mapping_id: string;
    file_spreadsheet_sheet?: number;
  };
}

export interface CategoryUuid {
  id: string;
}

export interface BulkEditDraftsPayload {
  filter: {
    productsUuids?: string[];
    noIssues?: boolean;
  };
  update: {
    categories?: CategoryUuid[];
    madeIn?: string;
    unitMultiplier?: number;
    vatRate?: number;
    productTypeId?: string;
    attributes?: { name: string; value: string }[];
  };
}

export interface BulkEditFields {
  productTypeId: string;
  attributes: {
    name: string;
    value: string;
  }[];
  madeIn: string;
  unitMultiplier: number;
  vatRate: number;
}
export type BulkEditAction = keyof Omit<BulkEditFields, 'productTypeAttributes'>;

export interface CatalogueIntegrationBatch {
  uuid: string;
  brand_id: number;
  type: BatchType;
  status: BatchStatus;
  fetched_count: number;
  imported_count: number;
  integrated_count: number;
  failed_count: number;
  created_count: number;
  updated_count: number;
  product_updated_count: number;
  product_created_count: number;
  total_count: number;
  created_at: string;
  updated_at: string;
}
export interface LastBatchStatus {
  completed: Array<string>;
  error: Array<string>;
  in_progress: Array<string>;
}

export enum BatchStatus {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  IMPORTED = 'imported',
  IMPORTING = 'importing',
  ERROR = 'error',
  INTEGRATED = 'integrated',
  FETCHING_PENDING = 'fetching:pending',
  FETCHING_IN_PROGRESS = 'fetching:in_progress',
  FETCHING_SUCCESSFUL = 'fetching:successful',
  FETCHING_FAILED = 'fetching:failed',
  FETCHING_ABORTED = 'fetching:aborted',
  IMPORTATION_IN_PROGRESS = 'importation:in_progress',
  IMPORTATION_SUCCESSFUL = 'importation:successful',
  IMPORTATION_FAILED = 'importation:failed',
  IMPORTATION_ABORTED = 'importation:aborted',
  INTEGRATION_PENDING = 'integration:pending',
  INTEGRATION_IN_PROGRESS = 'integration:in_progress',
  INTEGRATION_SUCCESSFUL = 'integration:successful',
  INTEGRATION_FAILED = 'integration:failed',
  INTEGRATION_ABORTED = 'integration:aborted',
}

export enum IntegrationStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  SUCCESSFUL = 'successful',
  ABORTED = 'aborted',
  ERROR = 'error',
}

export enum ExportCatalogueStatus {
  WAITING = 'waiting',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'finished',
  ERROR = 'failed',
}

export enum BatchType {
  GOOGLE = 'google_spreadsheet',
  CSV = 'csv_spreadsheet',
}

export interface BatchStatusTitle {
  in_progress: string;
  completed: string;
  error: string;
}

export enum BatchStatusType {
  ACTIVATE = 'activate',
  UPDATE = 'update',
  IMPORT = 'import',
  EXPORT = 'export',
}

export enum ExportType {
  SPREADSHEET = 'google_spreadsheet',
  CSV = 'csv',
}

export interface AllowedRateOption {
  label: string;
  value: number;
  disabled?: boolean;
}

export interface MappingItem {
  identifier: string;
  field?: string | number;
  missingValue?: string;
  emptyValue?: string;
  behavior?: string;
}

export interface SpreadsheetMappingPayload {
  name: string;
  mapping: MappingItem[];
}

export interface SpreadsheetMappingResponse {
  type: string;
  id: string;
  attributes: {
    name: string;
    configuration: MappingItem[];
  };
}

export enum ImportFileType {
  CSV = 'csv',
  XLSX = 'xlsx',
}
