import { captureEvent, captureException } from '@/sentry';
import http from '@/services/api/http';

import { deserialize } from '@/services/utils/jsonapi-parser';
import { rejectOrder } from '@/services/api/order';
import {
  BrandRetailerRelationshipType,
  EditedOrderItems,
  ExternalOrderTypeOptions,
  ItemsType,
  ItemUpdates,
  MasterOrderBillingItem,
  ORDER_AUTOMATION_MODAL_CONTEXT_SESSION_KEY,
  OrderAutomationModalContext,
  OrderDetail,
  OrderItemEntity,
  OrderStatuses,
} from '@/types/order';
import { isShippingViaAnkorstore, ShipmentMethod } from '@/types/shipping/shipment-method';
import { OrderBasicDetail } from '@/types/orders';
import { OrderBrandRejectionType } from '@/types/order-brand-rejection';
import { Params, State } from './type';
import { isEnabled } from '@/services/features';
import { getMasterOrder, GetMasterOrderParams, getMasterOrderUuidByInternalOrderId } from '@/services/order/order.service';
import { acceptPreorder, getPreorder, listPreorders, rejectPreorder } from '@/services/order/preorder.service';
import { getSelectedProductsVariantByUuid } from '@/services/api/brand-me';
import { OrderStatus } from '@/types/order-status';
import { BillingItemType } from '@/types/billing-item';
import { getFulfillmentOrdersByMasterOrderUuid } from '@/services/fulfillment/api';
import { captureFulfillmentException } from '@/sentry/helper';
import { useSessionStorage } from '@/composables/use-storage';
import { Preorder } from '@/components/account/preorders/preorder/types';
import { FulfillmentOrderShippedItem } from '@/types/fulfillment';
import { addDays, isBefore, startOfDay } from 'date-fns';

const initialState: State = {
  cancellationReasonFilter: null,
  orders: [],
  checkouts: [],
  orderDetail: null,
  fulfillmentOrderDetail: null,
  checkoutDetail: null,
  editedOrder: {},
  initialOrder: {},
  currentCheckoutPage: 1,
  checkoutPerPage: 5,
  checkoutLastPage: 1,
  checkoutFrom: 1,
  checkoutTo: 1,
  totalCheckoutItems: 0,
  checkoutLoading: false,
  currentOrderPage: 1,
  orderPerPage: 5,
  lastPage: 1,
  totalOrderItems: 0,
  statusFilter: null,
  priorityFilter: null,
  typeOfOrderFilter: null,
  typeOfExternalOrderFilter: null,
  orderLoading: false,
  affiliatedClients: [],
  currentAffiliatedClientsPage: 1,
  totalAffiliatedClients: 0,
  affiliatedClientsByPage: 5,
  showAlcoholWarning: false,
  searchTerm: '',
  countByStatus: {
    ankor_confirmed: null,
    invoiced: null,
  },
  countsByTypeOfOrder: {
    internal: null,
    external: null,
  },
  countOrderRestrictedByEORINumber: 0,
  //to be refactored after new router merge
  liked: {
    brands: {},
    products: {},
  },
  likedBrandsPagination: {
    loading: false,
    currentPage: 1,
    itemsPerPage: 24,
    totalItems: 0,
    itemsOrder: [],
    nbPage: 0,
    from: 1,
    to: 1,
    lastPage: 1,
  },
  likedProductsPagination: {
    loading: false,
    currentPage: 1,
    itemsPerPage: 36,
    totalItems: 0,
    itemsOrder: [],
  },
  orderItems: null,
  preorder: null,
  preorders: [],
};

const masterOrderFetchParams: GetMasterOrderParams = {
  include: ['orderShipping', 'orderIssue', 'brandRetailerRelationship'],
  fields: {
    orderShipping: ['shippingMethod', 'isAddingTrackingInformationPostponed', 'stockOrigin'],
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async fetchLikedBrands({ commit, state }) {
      if (!isEnabled('like_products_and_brands')) {
        return;
      }
      const params: Params = {};

      commit('TOGGLE_BRANDS_LOADING', true);

      if (state.likedBrandsPagination.currentPage) {
        params.page = state.likedBrandsPagination.currentPage;
      }

      const { data } = await http().get('/api/likes/brands', { params, noProgressBar: true });
      if (data.data.length === 0) {
        this['_vm'].$emit('no-datas', data.meta.current_page);
      }
      commit(
        'SET_LIKED_BRANDS',
        data.data.reduce((object, value) => Object.assign(object, { [value.id]: value }), {})
      );
      commit(
        'SET_BRANDS_ORDER',
        data.data.map((item) => item.id)
      );
      commit('SET_BRANDS_CURRENT_PAGE', data.meta.current_page);
      commit('SET_BRANDS_NB_PAGE', Math.ceil(data.meta.total / data.meta.per_page));
      commit('SET_BRANDS_PER_PAGE', data.meta.per_page);
      commit('SET_TOTAL_BRANDS_ITEMS', data.meta.total);
      commit('TOGGLE_BRANDS_LOADING', false);
      commit('SET_BRANDS_FROM', data.meta.from);
      commit('SET_BRANDS_TO', data.meta.to);
      commit('SET_BRANDS_LAST_PAGE', data.meta.last_page);
    },
    changeCurrentLikedBrandPage({ commit, dispatch }, page) {
      commit('SET_BRANDS_CURRENT_PAGE', page);
      dispatch('fetchLikedBrands');
    },
    fetchLikedProducts({ commit, state }) {
      if (!isEnabled('like_products_and_brands')) {
        return;
      }
      const params: Params = {};

      commit('TOGGLE_LIKED_PRODUCTS_LOADING', true);

      if (state.likedProductsPagination.currentPage) {
        params.page = state.likedProductsPagination.currentPage;
      }

      http()
        .get('/api/likes/products', { params, noProgressBar: true })
        .then(({ data }) => {
          if (data.data.length === 0) {
            this['_vm'].$emit('no-datas', data.meta.current_page);
          }
          commit(
            'SET_LIKED_PRODUCTS',
            data.data.reduce((object, value) => Object.assign(object, { [value.id]: value }), {})
          );

          commit(
            'SET_LIKED_PRODUCTS_ORDERS',
            data.data.map((item) => item.id)
          );

          commit('SET_LIKED_PRODUCTS_CURRENT_PAGE', data.meta.current_page);
          commit('SET_LIKED_PRODUCTS_PER_PAGE', data.meta.per_page);
          commit('SET_TOTAL_LIKED_PRODUCTS_ITEMS', data.meta.total);
          commit('TOGGLE_LIKED_PRODUCTS_LOADING', false);
        });
    },
    changeCurrentLikedProductPage({ commit, dispatch }, page) {
      commit('SET_LIKED_PRODUCTS_CURRENT_PAGE', page);
      dispatch('fetchLikedProducts');
    },
    removeLikedProducts({ commit, state }, id) {
      commit('UNSET_LIKED_PRODUCT', id);
      commit(
        'SET_LIKED_PRODUCTS_ORDERS',
        state.likedProductsPagination.itemsOrder.filter((item) => item != id)
      );
    },
    fetchCheckouts({ commit, state }) {
      const params: Params = {
        _fields:
          'id,status,submitted_at,created_at,payment_terms,payment_method_type,orders{id,status,status_details{title},brand{id,name,images},billing_items{id,amount{amount,currency},amount_vat{amount,currency}}},currency,retailer_grand_total_amount_with_vat{amount,currency},total_paid_amount{amount,currency}',
      };

      commit('TOGGLE_CHECKOUT_LOADING', true);

      if (state.currentCheckoutPage) {
        params.page = state.currentCheckoutPage;
      }

      http()
        .get(`/api/checkouts`, { params })
        .then(({ data }) => {
          commit('SET_USER_CHECKOUTS', Object.freeze(data.data));
          commit('SET_CHECKOUT_CURRENT_PAGE', data.meta.current_page);
          commit('SET_CHECKOUT_PER_PAGE', data.meta.per_page);
          commit('SET_TOTAL_CHECKOUT_ITEMS', data.meta.total);
          commit('SET_CHECKOUT_LAST_PAGE', data.meta.last_page);
          commit('SET_CHECKOUT_FROM', data.meta.from);
          commit('SET_CHECKOUT_TO', data.meta.to);
          commit('TOGGLE_CHECKOUT_LOADING', false);
        });
    },
    fetchCheckout({ commit, dispatch }, id) {
      commit('TOGGLE_CHECKOUT_LOADING', true);

      http()
        .get(`/api/checkouts/${id}`)
        .then(({ data }) => {
          dispatch('saveCheckoutDetail', Object.freeze(data.data));
          commit('TOGGLE_CHECKOUT_LOADING', false);
        });
    },
    changeCurrentCheckoutPage({ commit, dispatch }, page) {
      commit('SET_CHECKOUT_CURRENT_PAGE', page);
      dispatch('fetchCheckouts');
    },
    saveCheckoutDetail({ commit }, checkout) {
      commit('SET_CHECKOUT_DETAIL', checkout);
    },
    async fetchOrders({ commit, state, rootGetters }): Promise<void> {
      let params: Params;
      if (rootGetters.userIsRetailer) {
        params = {
          _fields:
            'id,submitted_at,status,status_details,brand{id,name},retailer_net_amount{amount,currency},item_count,tracking_link,brand_cancellation',
        };
      } else {
        params = {
          include: 'orderShipping,orderIssue',
          'fields[order-shipping]': 'shippingMethod,isAddingTrackingInformationPostponed,stockOrigin',
          'fields[order-issue]': 'trackingLink,willAddTrackingLater,status',
          'fields[orders]':
            'priorityTag,createdAt,deadlines,internalId,internalUuid,issue,rejection,retailer,status,synchronizations,totals,tracking,type,orderShipping,fulfillmentReference,orderIssue,metadata,amounts,externalOrderType',
        };
      }

      commit('TOGGLE_ORDER_LOADING', true);

      if (state.currentOrderPage) {
        params['page[offset]'] = (state.currentOrderPage - 1) * state.orderPerPage;
      }

      if (state.statusFilter) {
        params['filter[status_key]'] = state.statusFilter;
      }

      if (
        state.priorityFilter &&
        (state.statusFilter === null ||
          [OrderStatuses.PENDING_VALIDATION, OrderStatuses.TO_SHIP, OrderStatuses.RECEPTION_REFUSED].includes(state.statusFilter))
      ) {
        params['filter[priority_key]'] = state.priorityFilter;
      }

      if (state.typeOfOrderFilter) {
        params['filter[order_type]'] = state.typeOfOrderFilter;
      }

      if (state.typeOfExternalOrderFilter) {
        params['filter[external_order_type]'] = state.typeOfExternalOrderFilter;
      }

      if (
        state.cancellationReasonFilter &&
        (state.statusFilter === null || [OrderStatuses.CANCELLED].includes(state.statusFilter))
      ) {
        params['filter[brand_cancellation_key]'] = state.cancellationReasonFilter;
      }

      if (state.searchTerm) {
        params['filter[search_term]'] = state.searchTerm;
      }

      params['page[limit]'] = 15;

      return http()
        .get<{
          data: OrderBasicDetail[];
          meta: {
            page: {
              currentPage: number;
              perPage: number;
              lastPage: number;
              total: number;
            };
          };
        }>(`/api/internal/v1/ordering/orders`, { params })
        .then((response) => {
          const deserializedResponse = deserialize(response.data);
          const orders = deserializedResponse.data;
          const meta = deserializedResponse.meta;
          commit('SET_USER_ORDERS', Object.freeze(orders));
          commit('SET_ORDER_CURRENT_PAGE', meta.page.currentPage);
          commit('SET_ORDER_PER_PAGE', meta.page.perPage);
          commit('SET_LAST_PAGE', meta.page.lastPage);
          commit('SET_TOTAL_ORDER_ITEMS', meta.page.total);
          commit('SET_COUNTS_BY_STATUS', meta.count_by_status_key);
          commit('SET_COUNTS_BY_TYPE_OF_ORDER', meta.count_by_type_of_order);
          commit('TOGGLE_ORDER_LOADING', false);
        });
    },
    rejectOrder(
      { commit, dispatch },
      { id, internalId, rejectType }: { id: string; internalId: number; rejectType: OrderBrandRejectionType }
    ) {
      commit('TOGGLE_ORDER_LOADING', true);

      return rejectOrder(internalId, rejectType)
        .catch(captureException)
        .finally(() => {
          dispatch('fetchOrder', id);
        });
    },
    async fetchOrder({ commit, dispatch }, id) {
      commit('TOGGLE_ORDER_LOADING', true);
      try {
        const order = await getMasterOrder(id, masterOrderFetchParams, { noProgressBar: true });

        dispatch('saveOrderDetail', {
          order: Object.freeze(order.data),
          counter: 1,
        });
        commit('TOGGLE_ORDER_LOADING', false);
      } catch (e) {
        captureEvent(e);
        commit('TOGGLE_ORDER_LOADING', false);
      }
    },
    async fetchPreorder({ commit }, id) {
      try {
        const preorder = await getPreorder(id);
        commit('SET_PREORDER', preorder);
      } catch (e) {
        captureEvent(e);
      }
    },
    async acceptPreorder({ commit }, id) {
      try {
        const preorder = await acceptPreorder(id);
        commit('SET_PREORDER', preorder);
      } catch (e) {
        captureEvent(e);
      }
    },
    async rejectPreorder({ commit }, id) {
      try {
        const preorder = await rejectPreorder(id);
        commit('SET_PREORDER', preorder);
      } catch (e) {
        captureEvent(e);
      }
    },
    async fetchPreorders({ commit }) {
      try {
        const preorders = await listPreorders();
        commit('SET_PREORDERS', preorders);
      } catch (e) {
        captureEvent(e);
      }
    },
    async fetchFulfillmentOrder({ commit }, id) {
      try {
        const fulfillmentOrders = await getFulfillmentOrdersByMasterOrderUuid(id);

        if (fulfillmentOrders.data.length > 0) {
          commit('SET_FULFILLMENT_ORDER_DETAIL', fulfillmentOrders?.data?.[0]);
        }
      } catch (e) {
        captureFulfillmentException(e);
      }
    },
    async fetchOrderSilently({ dispatch }, { id: id, counter: counter }) {
      const order = await getMasterOrder(id, masterOrderFetchParams, { noProgressBar: true });

      dispatch('saveOrderDetail', {
        order: Object.freeze(order.data),
        counter: counter,
      });
    },
    changeCurrentOrderPage({ commit, dispatch }, page): Promise<void> {
      commit('SET_ORDER_CURRENT_PAGE', page);
      return dispatch('fetchOrders');
    },
    resetAllFilters({ commit, dispatch }) {
      commit('CLEAR_PRIORITY_FILTER');
      commit('CLEAR_TYPE_OF_ORDER_FILTER');
      commit('CLEAR_CANCELLATION_REASON_FILTER');
      dispatch('changeCurrentOrderPage', 1);
    },
    resetOrderFilters({ commit, dispatch }) {
      commit('CLEAR_ORDER_FILTERS');
      dispatch('changeCurrentOrderPage', 1);
    },
    resetCancellationReasonFilter({ commit, dispatch }) {
      commit('CLEAR_CANCELLATION_REASON_FILTER');
      dispatch('changeCurrentOrderPage', 1);
    },
    resetPriorityFilter({ commit, dispatch }) {
      commit('CLEAR_PRIORITY_FILTER');
      dispatch('changeCurrentOrderPage', 1);
    },
    resetTypeOfOrderFilter({ commit, dispatch }) {
      commit('CLEAR_TYPE_OF_ORDER_FILTER');
      dispatch('changeCurrentOrderPage', 1);
    },
    changeFilterOrder({ commit, dispatch }, filter) {
      commit('SET_ORDER_FILTER', filter);
      dispatch('changeCurrentOrderPage', 1);
    },
    changeCancellationReasonFilter({ commit, dispatch }, filter) {
      commit('SET_CANCELLATION_REASON_FILTER', filter);
      dispatch('changeCurrentOrderPage', 1);
    },
    changeFilterPriorityOrder({ commit, dispatch }, priority) {
      commit('SET_ORDER_PRIORITY_FILTER', priority);
      dispatch('changeCurrentOrderPage', 1);
    },
    changeFilterTypeOfOrder({ commit, dispatch }, {typeOfOrder, externalTypeOfOrder }) {
      commit('SET_ORDER_TYPE_FILTER', typeOfOrder);
      commit('SET_EXTERNAL_ORDER_TYPE_FILTER', externalTypeOfOrder);
      dispatch('changeCurrentOrderPage', 1);
    },
    async saveOrderDetail({ commit, dispatch, state }, { order, counter }) {
      // If deprecated order model is trying to be saved (as it might come from an endpoint that still uses deprecated order model), request new model
      if (Number.isInteger(order.id)) {
        try {
          const id = await getMasterOrderUuidByInternalOrderId(order.id);
          const newPayload = await getMasterOrder(id, masterOrderFetchParams, { noProgressBar: true });
          dispatch('saveOrderDetail', {
            order: Object.freeze(newPayload.data),
            counter: 1,
          });
          commit('TOGGLE_ORDER_LOADING', false);
        } catch (e) {
          captureEvent(e);
          commit('TOGGLE_ORDER_LOADING', false);
        }

        return;
      }
      counter = counter || 1;
      if (
        counter <= 6 &&
        (order.status?.name === 'received' ||
          (order.status?.name === 'brand_confirmed' && isShippingViaAnkorstore(order.orderShipping?.data.shippingMethod)))
      ) {
        // Wait for the invoice generation
        setTimeout(() => {
          // if we change of orderDetail, we don't want the dispatch to occur.
          if (state.orderDetail && state.orderDetail.id === order.id) {
            ++counter;
            dispatch('fetchOrderSilently', { id: order.id, counter: counter });
          }
        }, 5000 * counter);
      }
      commit('SET_ORDER_DETAIL', order);
      if (order.items) {
        commit('SET_ORDER_ITEMS', order.items);
        commit('SET_INITIAL_ORDER', order.items);
      }
    },
    editItemOfOrder({ commit }, itemOrder) {
      commit('SET_ITEM_QUANTITY', itemOrder);
    },
    cancelEditOrder({ commit, dispatch, state }) {
      commit('RESET_EDIT_ORDER');
      dispatch('reloadWanted', state.orderDetail.id);
    },
    saveEditedOrder({ commit, state, dispatch, getters }) {
      if (!state.orderLoading) {
        commit('TOGGLE_ORDER_LOADING', true);
        const order_items = Object.values(state.editedOrder as EditedOrderItems).map((item) => ({
          order_item_id: getters.getItemProperty(item, 'internalId'),
          quantity: item.batchQuantity,
        }));
        http()
          .patch(`/api/orders/${state.orderDetail.internalId}/items`, {
            order_items,
          })
          .then(({ data }) => {
            dispatch('saveOrderDetail', {
              order: Object.freeze(data.data),
              counter: 1,
            });
            dispatch('reloadWanted', state.orderDetail.id);
          })
          .finally(() => {
            commit('TOGGLE_ORDER_LOADING', false);
          });
      }
    },
    sendAction({ commit, dispatch }, { orderId, action, parameters, options }) {
      commit('TOGGLE_ORDER_LOADING', options?.toggleOrderLoading ?? true);
      const sessionStorage = useSessionStorage();
      let payload = { action };

      if (parameters) {
        payload = { ...parameters, ...payload };
      }

      return http()
        .post(`/api/orders/${orderId}/action`, payload)
        .then(async ({ data }) => {
          if (payload.action === 'buy_label') {
            // We track this in order to show proper modal after the page refresh
            sessionStorage.setStorageItem(ORDER_AUTOMATION_MODAL_CONTEXT_SESSION_KEY, OrderAutomationModalContext.ANKORSTORE);
          }
          if (payload.action === 'ship') {
            sessionStorage.setStorageItem(ORDER_AUTOMATION_MODAL_CONTEXT_SESSION_KEY, OrderAutomationModalContext.MYSELF);
          }
          if (payload.action === ShipmentMethod.FULFILL_WITH_ANKORSTORE) {
            sessionStorage.setStorageItem(ORDER_AUTOMATION_MODAL_CONTEXT_SESSION_KEY, OrderAutomationModalContext.FULFILLMENT);
          }

          // commit('SET_ORDER_AUTOMATION_MODAL_SHOW', { orderId, show: true});
          await dispatch('saveOrderDetail', { order: Object.freeze(data.data) });
          commit('TOGGLE_ORDER_LOADING', false);
          if (options?.reloadPage) {
            dispatch('reloadWanted', data.data.id);
          }
        });
    },
    fetchAffiliatedClients({ commit, state }) {
      const params: Params = {};

      commit('TOGGLE_ORDER_LOADING', true);

      if (state.currentAffiliatedClientsPage) {
        params.page = state.currentAffiliatedClientsPage;
      }

      http()
        .get(`/api/me/brand/affiliated-retailers`, { params })
        .then(({ data }) => {
          commit('SET_AFFILIATED_WITH_PAGINATION', {
            data: data.data,
            meta: data.meta,
          });
          commit('TOGGLE_ORDER_LOADING', false);
        });
    },
    changeCurrentAffiliationClientsPage({ commit, dispatch }, page) {
      commit('SET_AFFILIATED_CLIENT_CURRENT_PAGE', page);
      dispatch('fetchAffiliatedClients');
    },
    setSearchTerm({ commit, dispatch }, term: string) {
      commit('SET_SEARCH_TERM', term);
      return dispatch('changeCurrentOrderPage', 1);
    },
    clearSearchTerm({ commit, dispatch }) {
      commit('CLEAR_SEARCH_TERM');
      return dispatch('changeCurrentOrderPage', 1);
    },
    async fetchOrderItems({ getters, commit, dispatch }, id) {
      if (Number.isInteger(id)) {
        id = await getMasterOrderUuidByInternalOrderId(id);
      }
      const { data } = await http().get(`/api/internal/v1/ordering/orders/${id}/order-items?include=orderedProduct`);
      let items = deserialize(data).data;

      // extend items with item updates
      if (getters.orderHasUpdates) {
        const itemUpdates = getters.getOrderUpdate('itemUpdates');
        const itemsRemovedFromOrder: ItemUpdates[] = [];

        itemUpdates.forEach((itemUpdate: ItemUpdates) => {
          const itemToUpdate = items.find((item) => item.id === itemUpdate.itemId);
          if (itemToUpdate) {
            itemToUpdate.oldAmount = itemUpdate.oldAmount;
            itemToUpdate.oldMultipliedQuantity = itemUpdate.oldMultipliedQuantity;
            itemToUpdate.oldQuantity = itemUpdate.oldQuantity;
          } else {
            itemsRemovedFromOrder.push(itemUpdate);
          }
        });

        if (itemsRemovedFromOrder.length) {
          const orderItemsWithZeroQuantity = await dispatch('fetchInfoForRemovedItemsInOrder', itemsRemovedFromOrder);
          items = items.concat(orderItemsWithZeroQuantity);
        }
      }
      commit('SET_ORDER_ITEMS', items);
      commit('SET_INITIAL_ORDER', items);
    },
    async fetchInfoForRemovedItemsInOrder(_, itemsRemovedFromOrder: ItemUpdates[]) {
      const productsRemoved = await getSelectedProductsVariantByUuid(itemsRemovedFromOrder.map((item) => item.itemId));
      const itemsMissing: OrderItemEntity[] = [];

      productsRemoved.forEach((product) => {
        const currentItemToUpdate = itemsRemovedFromOrder.find((item) => item.itemId === product.variants[0].uuid);
        if (!currentItemToUpdate) {
          return;
        }
        const orderItem: OrderItemEntity = {
          type: ItemsType.ORDER_ITEMS,
          id: product.variants[0].uuid,
          orderedProduct: {
            data: {
              images: product.images,
              fulfillableId: product.variants[0].fulfillableId,
              name: product.name,
              options: product.variants[0].options,
              pageUrl: product.link,
              id: product.variants[0].uuid,
              type: product.type,
              sku: product.variants[0].sku,
            },
          },
          internalId: product.id,
          batchQuantity: 0,
          unitQuantity: 0,
          unitPrice: product.variants[0].price.wholesale_price,
          totalPrice: { amount: 0, currency: currentItemToUpdate.oldAmount.currency },
          oldAmount: currentItemToUpdate.oldAmount,
          oldMultipliedQuantity: currentItemToUpdate.oldMultipliedQuantity,
          oldQuantity: currentItemToUpdate.oldQuantity,
        };

        itemsMissing.push(orderItem);
      });

      return itemsMissing;
    },
    reloadWanted() {}, //Do nothing, the reload action is subscribed by component
  },
  mutations: {
    MUTATE_ITEM_PROPERTY(_, { item, property, value }) {
      item[property] = value;
    },
    TOGGLE_CHECKOUT_LOADING(state, value) {
      state.checkoutLoading = value;
    },
    REINIT_CHECKOUT(state) {
      state.checkoutDetail = null;
    },
    SET_CHECKOUT_CURRENT_PAGE(state, page) {
      state.currentCheckoutPage = page;
    },
    SET_CHECKOUT_LAST_PAGE(state, page) {
      state.checkoutLastPage = page;
    },
    SET_CHECKOUT_FROM(state, from) {
      state.checkoutFrom = from;
    },
    SET_CHECKOUT_TO(state, to) {
      state.checkoutTo = to;
    },
    SET_USER_CHECKOUTS(state, checkouts) {
      state.checkouts = checkouts;
    },
    SET_CHECKOUT_PER_PAGE(state, checkoutPerPage) {
      state.checkoutPerPage = checkoutPerPage;
    },
    SET_TOTAL_CHECKOUT_ITEMS(state, total) {
      state.totalCheckoutItems = total;
    },
    SET_CHECKOUT_DETAIL(state, checkout) {
      state.checkoutDetail = checkout;

      // whenever we update the order detail we also want to update the collection of order
      state.checkouts = [...state.checkouts.map((item) => (item.id !== checkout.id ? item : { ...item, ...checkout }))];
    },
    TOGGLE_ORDER_LOADING(state, value) {
      state.orderLoading = value;
    },
    REINIT_ORDER(state) {
      state.orderDetail = null;
    },
    SET_ORDER_CURRENT_PAGE(state, page) {
      state.currentOrderPage = page;
    },
    SET_USER_ORDERS(state: State, orders: OrderBasicDetail[]) {
      state.orders = orders;
    },
    SET_ORDER_PER_PAGE(state, orderPerPage) {
      state.orderPerPage = orderPerPage;
    },
    SET_LAST_PAGE(state, lastPage) {
      state.lastPage = lastPage;
    },
    SET_TOTAL_ORDER_ITEMS(state, total) {
      state.totalOrderItems = total;
    },
    SET_ORDER_FILTER(state, filter) {
      state.statusFilter = filter;
    },
    SET_CANCELLATION_REASON_FILTER(state, filter) {
      state.cancellationReasonFilter = filter;
    },
    SET_ORDER_PRIORITY_FILTER(state, filter) {
      state.priorityFilter = filter;
    },
    SET_ORDER_TYPE_FILTER(state, filter) {
      state.typeOfOrderFilter = filter;
    },
    SET_EXTERNAL_ORDER_TYPE_FILTER(state, filter) {
      state.typeOfExternalOrderFilter = filter;
    },
    SET_ORDER_DETAIL(state, order: OrderDetail) {
      state.orderDetail = order;
      // whenever we update the order detail we also want to update the collection of order
      state.orders = [...state.orders.map((item) => (item.id !== order.id ? item : { ...item, ...order }))];
    },
    SET_PREORDER(state, preorder: Preorder) {
      state.preorder = preorder;
    },
    SET_PREORDERS(state, preorders: Preorder[]) {
      state.preorders = preorders;
    },
    SET_FULFILLMENT_ORDER_DETAIL(state, filter) {
      state.fulfillmentOrderDetail = filter;
    },
    SET_COUNTS_BY_STATUS(state, counts) {
      state.countByStatus = counts;
    },
    SET_COUNTS_BY_TYPE_OF_ORDER(state, counts) {
      state.countsByTypeOfOrder = counts;
    },
    SET_COUNT_ORDER_RESTRICTED_BY_EORI_NUMBER(state, count) {
      state.countOrderRestrictedByEORINumber = count;
    },
    SET_AFFILIATED_CLIENT_CURRENT_PAGE(state, page) {
      state.currentAffiliatedClientsPage = page;
    },
    SET_AFFILIATED_WITH_PAGINATION(state, { data, meta }) {
      state.affiliatedClients = data;
      state.currentAffiliatedClientsPage = meta.current_page;
      state.totalAffiliatedClients = meta.total;
      state.affiliatedClientsByPage = meta.per_page;
    },
    SET_PRODUCT_CURRENT_PAGE(state, page) {
      state.currentProductPage = page;
    },
    SET_PRODUCT_ORGANIZATION_MODE(state, val) {
      state.productOrganizationMode = val;
    },
    SET_USER_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_PRODUCT_NEW_ORDER(state, products) {
      state.productsReorderingNotPersisted = products;
    },
    SET_PRODUCT_PER_PAGE(state, productPerPage) {
      state.productPerPage = productPerPage;
    },
    SET_TOTAL_PRODUCT_ITEMS(state, total) {
      state.totalProductItems = total;
    },
    SET_PRODUCT_DETAIL(state, order) {
      state.productDetail = order;
    },
    UPDATE_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_CHECKED_PRODUCTS(state, checked) {
      state.productsChecked = checked;
    },
    SET_SAVEBAR_DIRTY(state, value) {
      state.savebarDirty = value;
    },
    SET_SAVEBAR_TYPE(state, type) {
      state.saveBarType = type;
    },

    RESET_EDIT_ORDER(state) {
      state.editedOrder = { ...state.initialOrder };
    },
    SET_INITIAL_ORDER(state, items: OrderItemEntity[]) {
      items.forEach((item) => (state.initialOrder[item.id] = item));
      state.editedOrder = { ...state.initialOrder };
    },
    SET_ORDER_ITEMS(state, items: OrderItemEntity[]) {
      state.orderItems = items;
    },
    SET_ITEM_QUANTITY(state, itemOrder) {
      state.editedOrder[itemOrder.id] = { ...itemOrder };
    },
    SET_LIKED_BRANDS(state, brands) {
      state.liked.brands = brands;
    },
    SET_BRANDS_CURRENT_PAGE(state, page) {
      state.likedBrandsPagination.currentPage = page;
    },
    SET_BRANDS_PER_PAGE(state, itemsPerPage) {
      state.likedBrandsPagination.itemsPerPage = itemsPerPage;
    },
    SET_TOTAL_BRANDS_ITEMS(state, total) {
      state.likedBrandsPagination.totalItems = total;
    },
    SET_BRANDS_ORDER(state, itemsOrder) {
      state.likedBrandsPagination.itemsOrder = itemsOrder;
    },
    TOGGLE_BRANDS_LOADING(state, value) {
      state.likedBrandsPagination.loading = value;
    },
    SET_BRANDS_NB_PAGE(state, value) {
      state.likedBrandsPagination.nbPage = value;
    },
    SET_BRANDS_FROM(state, value) {
      state.likedBrandsPagination.from = value;
    },
    SET_BRANDS_TO(state, value) {
      state.likedBrandsPagination.to = value;
    },
    SET_BRANDS_LAST_PAGE(state, value) {
      state.likedBrandsPagination.lastPage = value;
    },
    SET_LIKED_PRODUCTS(state, products) {
      state.liked.products = products;
    },
    UNSET_LIKED_PRODUCT(state, id) {
      delete state.liked.products[id];
    },
    SET_TOTAL_LIKED_PRODUCTS_ITEMS(state, total) {
      state.likedProductsPagination.totalItems = total;
    },
    SET_LIKED_PRODUCTS_ORDERS(state, itemsOrder) {
      state.likedProductsPagination.itemsOrder = itemsOrder;
    },
    TOGGLE_LIKED_PRODUCTS_LOADING(state, value) {
      state.likedProductsPagination.loading = value;
    },
    SET_SHOW_ALCOHOL_WARNING(state, value) {
      state.showAlcoholWarning = value;
    },
    SET_SEARCH_TERM(state, term: string): void {
      state.searchTerm = term;
    },
    CLEAR_SEARCH_TERM(state: State): void {
      state.searchTerm = '';
    },
    CLEAR_ORDER_FILTERS(state: State): void {
      state.searchTerm = '';
      state.priorityFilter = null;
      state.typeOfOrderFilter = null;
      state.statusFilter = null;
    },
    CLEAR_PRIORITY_FILTER(state: State): void {
      state.priorityFilter = null;
    },
    CLEAR_TYPE_OF_ORDER_FILTER(state: State): void {
      state.typeOfOrderFilter = null;
    },
    CLEAR_CANCELLATION_REASON_FILTER(state: State): void {
      state.cancellationReasonFilter = null;
    },
    CLEAR_STATUS_FILTER(state: State): void {
      state.statusFilter = null;
    },
  },
  getters: {
    getOrders: (state: State): OrderBasicDetail[] => state.orders,
    getOrderDetail: (state) => state.orderDetail,
    getCheckouts: (state) => state.checkouts,
    isCheckoutLoading: (state) => state.checkoutLoading,
    getNumberOfBrandsLikedPagination: (state) => state.likedBrandsPagination.nbPage,
    getLikedBrands: (state) => state.liked.brands,
    getLikedBrandById: (_state, getters) => (id) => getters.getLikedBrands[id],
    getLikedBrandsIdsOrder: (state) => state.likedBrandsPagination.itemsOrder,
    getOrderedLikedBrands: (_state, getters) => getters.getLikedBrandsIdsOrder.map((id) => getters.getLikedBrandById(id)),
    isLikedBrandsLoading: (state) => state.likedBrandsPagination.loading,
    isOrderLoading: (state) => state.orderLoading,
    countByStatus: (state) => (status) => state.countByStatus[status] || 0,
    countsByTypeOfOrder: (state) => (status) => state.countsByTypeOfOrder[status] || 0,
    countOrderRestrictedByEORINumber: (state) => state.countOrderRestrictedByEORINumber || 0,
    getOrderEditedQuantity: (state) => (id) => {
      return state.editedOrder[id]?.batchQuantity;
    },
    isOrderEdited: (state) => JSON.stringify(state.editedOrder) !== JSON.stringify(state.initialOrder),
    isOrderWithFastPayment: (_state: State, getters): boolean => {
      return getters.orderBillingItems?.some((item) => item.type === 'brand_fast_payment');
    },
    orderStatusName: (state) => {
      return state.orderDetail?.status?.name;
    },
    orderStatusTitle: (state) => {
      return state.orderDetail?.status?.title;
    },
    orderStatusDetails: (state) => {
      return state.orderDetail?.status?.details;
    },
    orderShippingPickup(state) {
      const pickup = state.orderDetail?.shipping?.shipment?.pickup;

      if (pickup) {
        return {
          date: pickup.date,
          closeTime: pickup.closeTime,
          readyTime: pickup.readyTime,
        };
      }
      return null;
    },
    orderShippingFromAddress(state) {
      const address = state.orderDetail?.shipping?.shipment?.shipFromAddress;
      return {
        contactPerson: address.contactPerson.fullName,
        companyName: address.company,
        addressLine: address.address.addressLine,
        postalCode: address.address.postalCode,
        city: address.address.city,
        countryCode: address.address.countryCode,
      };
    },
    orderShowAlcoholWarning(state) {
      return state.orderDetail?.meta?.showAlcoholWarning;
    },
    orderShowManualInvoiceDownloadWarning(state) {
      return state.orderDetail?.meta?.orderShowManualInvoiceDownloadWarning;
    },
    orderIsInternationalShipping(state) {
      return state.orderDetail?.shipping?.isInternational;
    },
    orderDeadlineAccept: (state) => {
      return state.orderDetail?.deadlines?.toAccept;
    },
    orderDeadlineShipping: (state) => {
      return state.orderDetail?.deadlines?.toShip;
    },
    orderTrackingLink: (state) => {
      return state.orderDetail?.tracking?.trackingLink;
    },
    orderTrackingNumber: (state) => {
      return state.orderDetail?.tracking?.trackingNumber;
    },
    orderShippingProvider: (state) => {
      return state.orderDetail?.shipping?.shippingProvider;
    },
    orderShippedAt: (state) => {
      return state.orderDetail?.shipping?.shipment?.shippedAt;
    },
    isRetailerCancellationRequestOpened(state) {
      return state.orderDetail?.rejection?.isRetailerCancellationRequestOpened;
    },
    orderIsDiscovery(state) {
      return state.orderDetail?.isDiscovery;
    },
    isLiftOrder(state: State): boolean {
      return state.orderDetail?.brandRetailerRelationship?.data?.type === BrandRetailerRelationshipType.LIFT || false;
    },
    isMyNetworkOrder(state: State) {
      return state.orderDetail?.brandRetailerRelationship?.data?.type === BrandRetailerRelationshipType.NETWORK || false;
    },
    orderFranco(_, getters): MasterOrderBillingItem['francoSetting'] {
      const flatFee = getters.orderBillingItems.find(({ type }) => type === BillingItemType.brandFlatShippingFees);

      return flatFee ? getters.getBillingItemProperty(flatFee, 'francoSetting') : undefined;
    },
    orderIsAboveFranco(_, getters) {
      const franco = getters.orderFranco?.francoAmount;
      const orderTotal = getters.getOrderTotal('totalAmount');

      if (!franco) {
        return false;
      }

      return orderTotal.amount > franco.amount;
    },
    orderShippingCollectionMethod(state) {
      return state.orderDetail?.shipping?.shipmentEstimation?.collectionMethods;
    },
    orderHasCustomEstimationAvailable(state) {
      return !!state.orderDetail?.shipping?.shipmentEstimation;
    },
    getOrderBrandProperty:
      (state) =>
      (property, isPreorder = false) => {
        const brandData = isPreorder ? state.preorder?.brand.data : state.orderDetail?.brand;
        if (!brandData) {
          return null;
        }
        switch (property) {
          case 'id':
            return isPreorder ? brandData.internalId : brandData.id;
          case 'name':
            return brandData.name;
          case 'link':
            return brandData.link;
          case 'images':
            return isPreorder ? { rounded: brandData.logoImagePath } : brandData.images;
          case 'city':
            return brandData.display_address?.city;
        }
      },
    getOrderRetailerProperty:
      (state) =>
      (property, isPreorder = false) => {
        let retailerData;
        if (state.orderDetail.externalOrderType === ExternalOrderTypeOptions.ORDERPAY) {
          retailerData = state.orderDetail.metadata;
        } else {
          retailerData = isPreorder ? state.preorder?.retailer.data : state.orderDetail?.retailer;
        }

        if (!retailerData) {
          return null;
        }
        switch (property) {
          case 'name':
            return retailerData.name;
          case 'id':
            return retailerData.id;
          case 'uuid':
            return retailerData.uuid;
          case 'phone':
            return state.orderDetail.externalOrderType === ExternalOrderTypeOptions.ORDERPAY
              ? retailerData.contactPerson.phone
              : retailerData.phone_number;
          case 'isoCode':
            return retailerData.business?.country.iso_code;
          case 'email':
            return state.orderDetail.externalOrderType === ExternalOrderTypeOptions.ORDERPAY
              ? retailerData.contactPerson.email
              : retailerData.email;
          case 'firstName':
            return retailerData.business?.user_first_name || retailerData.name;
          case 'lastName':
            return retailerData.business?.user_last_name || '';
          case 'vatNumber':
            return retailerData.business?.vat_number;
          case 'taxNumber':
            return retailerData.business?.tax_number;
          case 'city':
            return retailerData.city;
          case 'storeUrl':
            return retailerData.store_url;
        }
      },
    fulfillmentOrderClientType(state) {
      return state.fulfillmentOrderDetail?.recipientType;
    },
    fulfillmentOrderShippedItems(state): FulfillmentOrderShippedItem[] {
      return state.fulfillmentOrderDetail?.shippedItems;
    },
    orderType(state) {
      return state.orderDetail?.type;
    },
    externalOrderType(state) {
      return state.orderDetail?.externalOrderType;
    },
    orderStockOrigin(state) {
      return state.orderDetail?.orderShipping?.data?.stockOrigin;
    },
    orderShippingMethod(state, _getters, _rootState, rootGetters) {
      return rootGetters.userIsRetailer
        ? state.orderDetail.shippingMethod
        : state.orderDetail?.orderShipping?.data?.shippingMethod;
    },
    orderHasUpdates(state) {
      return state.orderDetail?.updates;
    },
    orderShippingLabelGenerationError(state) {
      return state.orderDetail?.shipping?.hasShippingLabelGenerationError;
    },
    orderShippingFeesRefund(state) {
      return state.orderDetail?.shipping?.fees?.refundAmount;
    },
    orderTemporaryParcels(state) {
      return state.orderDetail?.shipping?.shipmentEstimation?.estimatedParcels;
    },
    orderCanBeShippedWithShippingLabels(state) {
      return !!state.orderDetail?.shipping?.availableShippingMethods?.find(
        (m) => m.name === 'ankorstore' && m.canBeUsed === true
      );
    },
    orderShippingLabelError(state) {
      return state.orderDetail?.shipping?.availableShippingMethods?.find((m) => m.name === 'ankorstore' && m.canBeUsed === false)
        ?.errors?.[0];
    },
    orderShippingAddress(state) {
      const res = {
        name: null,
        street: null,
        city: null,
        isoCode: null,
        organisationName: null,
        postalCode: null,
        countryName: null,
      };

      if (state.orderDetail.externalOrderType === ExternalOrderTypeOptions.ORDERPAY) {
        const { contactPerson, address } = state.orderDetail.metadata;
        res.name = contactPerson?.firstName + '  ' + contactPerson?.lastName;
        res.street = address?.addressLine;
        res.city = address?.city;
        res.isoCode = address?.countryCode;
        res.countryName = address?.countryCode;
        res.organisationName = address?.company;
        res.postalCode = address?.postalCode;
      } else {
        const { contactPerson, address, company } = state.orderDetail.shipping.shippingAddress;
        res.name = contactPerson?.fullName;
        res.street = address?.addressLine;
        res.city = address?.city;
        res.isoCode = address?.countryCode;
        res.countryName = address?.countryCode;
        res.organisationName = company;
        res.postalCode = address?.postalCode;
      }
      return res;
    },
    hasInvalidHSCode(_, getters): boolean {
      if (getters.orderIsInternationalShipping && OrderStatus.BrandConfirmed === getters.orderStatusName) {
        return getters.orderItems?.some(
          (item: object) => !getters.getItemProperty(item, 'hsCode') && getters.getItemProperty(item, 'unitQuantity') > 0
        );
      }
      return false;
    },
    orderInvoices(state) {
      type Invoice = {
        isGenerated: null;
        number: null;
        title: null;
        path: null;
      };
      let res: Invoice[] = [];
      res = state.orderDetail?.invoices?.map((invoice) => {
        return {
          title: invoice.title,
          number: invoice.number,
          path: invoice.path,
          isGenerated: invoice.isGenerated,
        };
      });
      return res || [];
    },
    orderReference(state, getters) {
      return getters.orderType === 'internal' ? state.orderDetail?.internalId : state.orderDetail?.reference;
    },
    orderFulfillmentReference(state) {
      return state.orderDetail?.fulfillmentReference;
    },
    orderCreationDate(state) {
      return state.orderDetail?.createdAt;
    },
    orderId(state) {
      return state.orderDetail?.id;
    },
    checkoutId(state) {
      return state.orderDetail.checkoutId;
    },
    orderItemCount(state) {
      return state.orderDetail.orderItemCount;
    },
    orderInternalId(state) {
      return state.orderDetail?.internalId;
    },
    orderFreeShippingEndDate(state) {
      return state.orderDetail?.freeShippingEndDate;
    },
    orderFreeShippingAvailable(_state, getters) {
      const isOrderSubmittedOnTime = isBefore(
        startOfDay(new Date(getters.orderCreationDate)),
        startOfDay(addDays(new Date(getters.orderFreeShippingEndDate), 1))
      );
      return !!(getters.orderFreeShippingEndDate && isOrderSubmittedOnTime);
    },
    orderIssue(state) {
      return state.orderDetail?.orderIssue?.data;
    },
    orderItems(state) {
      return state.orderItems;
    },
    orderPriorityTag(state) {
      return state.orderDetail?.priorityTag;
    },
    orderBillingItems(state) {
      return state.orderDetail?.billing?.billingItems;
    },
    orderCustomReference(state) {
      return state.orderDetail.customReference;
    },
    getItemProperty: () => (item, property) => {
      if (!item) {
        return;
      }
      switch (property) {
        case 'id':
          return item.id;
        case 'internalId':
          return item.internalId;
        case 'quantity':
          return item.batchQuantity;
        case 'orderItemId':
          return item.orderItemId;
        case 'productId':
          return item.orderedProduct.data?.id;
        case 'hsCode':
          return item.orderedProduct.data?.hsCode;
        case 'images':
          return item.orderedProduct.data?.images;
        case 'unitQuantity':
          return item.unitQuantity;
        case 'unitPrice':
          return item.unitPrice;
        case 'totalPrice':
          return item.totalPrice;
        case 'newQuantity':
          return item.newQuantity;
        case 'oldQuantity':
          return item.oldQuantity;
        case 'name':
          return item.orderedProduct.data?.name;
        case 'originalName':
          return item.orderedProduct.data?.originalName;
        case 'sku':
          return item.orderedProduct.data?.sku;
        case 'product':
          return item.orderedProduct;
        case 'link':
          return item.orderedProduct.data?.pageUrl;
        case 'oldMultipliedQuantity':
          return item.oldMultipliedQuantity;
        case 'oldAmount':
          return item.oldAmount;
        case 'fulfillableId':
          return item.orderedProduct?.data?.fulfillableId;
        case 'variation':
          return item.orderedProduct.data?.options[0]?.value;
      }
    },
    getOrderUpdate: (state, getters) => (update) => {
      if (!getters.orderHasUpdates) {
        return;
      }
      switch (update) {
        case 'oldTotalAmount':
          return state.orderDetail.updates.oldTotalAmount;
        case 'oldTotalAmountVat':
          return state.orderDetail.updates.oldTotalAmountVat;
        case 'oldTotalAmountWithVat':
          return state.orderDetail.updates.oldTotalAmountWithVat;
        case 'oldRecargoAmount':
          return state.orderDetail.updates.oldRecargoAmount;
        case 'itemUpdates':
          return state.orderDetail.updates.itemUpdates;
      }
    },
    getOrderTotal: (state) => (total) => {
      switch (total) {
        case 'totalAmount':
          return state.orderDetail?.totals?.totalAmount;
        case 'totalAmountVat':
          return state.orderDetail?.totals?.totalVatAmount;
        case 'totalAmountWithVat':
          return state.orderDetail?.totals?.totalAmountWithVat;
        case 'netAmount':
          return state.orderDetail?.totals?.netAmount;
        case 'retailerTotalAmountBeforeDiscount':
          return state.orderDetail?.totals?.retailerTotalAmountBeforeDiscount;
      }
    },
    getOrderVatInfo: (state) => (property) => {
      switch (property) {
        case 'isShifted':
          return state.orderDetail?.vat?.isShifted;
        case 'message':
          return state.orderDetail?.vat?.applicationDetails?.message;
        case 'link':
          return state.orderDetail?.vat?.applicationDetails?.link;
      }
    },
    getBillingItemProperty: () => (billingItem, property) => {
      switch (property) {
        case 'id':
          return billingItem.id;
        case 'amount':
          return billingItem.amount;
        case 'amountVat':
          return billingItem.amountVat;
        case 'label':
          return billingItem.label;
        case 'vatLabel':
          return billingItem.vatLabel;
        case 'isVatShifted':
          return billingItem.isVatShifted;
        case 'type':
          return billingItem.type;
        case 'displayVat':
          return billingItem.displayVat;
        case 'francoSetting':
          return billingItem.francoSetting;
      }
    },
    getOrderExtraTax: (state) => (tax) => {
      switch (tax) {
        case 'recargoDeEquivalencia':
          return state.orderDetail?.taxes?.find((t) => t.name === 'recargoDeEquivalencia')?.amount;
      }
    },
    orderRejectType(state) {
      return state.orderDetail?.rejection?.brandRejectType;
    },
    orderHasIssue(state) {
      return state.orderDetail?.issue?.current;
    },
    orderHasRevertedShippingLabels(state) {
      return state.orderDetail?.shipping?.hasRevertedShippingLabels;
    },
    orderShipment(state) {
      return state.orderDetail?.shipping?.shipment;
    },
    retailerCanOpenCancellationRequest(state) {
      return state.orderDetail.cancellation?.retailerCanOpenCancellationRequest;
    },
    orderShipmentTrackedPackages(state) {
      return state.orderDetail?.shipping?.shipment?.trackedPackages;
    },
    orderLabelFormat(state) {
      return state.orderDetail?.shipping?.shipment?.labelPrintingFormat;
    },
    issueCaseVisibleToBrand: (state) => (issueCase) => {
      return state.orderDetail?.meta?.casesVisibleToBrand?.includes(issueCase);
    },
    issueCaseAllowedToBeSendToBrand: (state) => (issueCase) => {
      return state.orderDetail?.meta?.casesAllowedToBeSendToBrand?.includes(issueCase);
    },
    publicAppSynch(state) {
      let syncData = state.orderDetail?.synchronizations;
      syncData = syncData?.find((sync) => sync.type === 'publicApp');
      return syncData;
    },
    hasPublicAppSynch(_state, getters) {
      return getters.publicAppSynch !== undefined;
    },
    publicAppSynchName(_state, getters) {
      return getters.publicAppSynch?.platformName;
    },
    publicAppSynchError(_state, getters) {
      return getters.publicAppSynch?.error;
    },
    orderWillAddTrackingLater(state) {
      return state.orderDetail?.orderShipping?.data.isAddingTrackingInformationPostponed;
    },
    availableRejectReasons(state) {
      return state.orderDetail?.rejection?.availableBrandRejectReasons;
    },
    availableShippingLabelRevertReasons(state) {
      return state.orderDetail?.shipping?.shipment?.labelRevertReasons;
    },
    preorderStatus(state) {
      return state.preorder?.status;
    },
    preorderId(state) {
      return state.preorder?.id;
    },
    preorderSubmittedAt(state) {
      return state.preorder?.submittedAt;
    },
    preorderBrandName(state) {
      return state.preorder?.brand.data.name;
    },
    preorderBrandLink(state) {
      return state.preorder?.brand.data.link;
    },
    getPreorderAmount: (state) => (amount) => state.preorder?.[amount],
    preorderRetailerData: (state) => state.preorder?.retailer.data,
    itemsByAvailableAt: (state) => {
      const items = state.preorder?.preorderItems.data || [];
      const groupedItems = items.reduce((acc, item) => {
        const availableAt = item.availableAt;
        const found = acc.find((group) => group[0].availableAt === availableAt);
        if (found) {
          found.push(item);
        } else {
          acc.push([item]);
        }
        return acc;
      }, []);
      return groupedItems;
    },
    preorders: (state) => state.preorders,
  },
};
