import { memoize } from 'lodash-es';
import http from '@/services/api/http';
import { Brand } from '@/types/api/brand';

export const getInternalLinkingRelatedBrands = memoize(async (brandId: number | string) => {
  const response = await http().get<{ data: Brand[] }>(`/api/internal-linking/brand/${brandId}/brand-related-brands`, {
    noProgressBar: true,
  });

  return response.data?.data ?? [];
});

export const getRelatedBrands = memoize(async (brandId: number | string) => {
  const response = await http().get<{ data: Brand[] }>(`/api/brand/${brandId}/related-brands`, {
    noProgressBar: true,
  });

  return response.data?.data ?? [];
});

export const getRelatedBrandsByCategoryId = memoize(async (categoryId: number) => {
  type Response = { data: Array<{ link: string; name: string }> };
  const response = await http().get<Response>(`/api/internal-linking/category/${categoryId}/related-brands`, {
    noProgressBar: true,
  });

  return response.data?.data ?? [];
});
