<template>
  <AkHeading4 class="filter-heading">
    <slot name="badgeBefore" />
    <slot name="title" />
    <slot name="tooltip" />
  </AkHeading4>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AkHeading4 } from '@ankorstore/design-system';
export default defineComponent({
  name: 'FilterHeading',
  components: { AkHeading4 },
});
</script>

<style scoped lang="scss">
@use '@css/abstracts/design-tokens' as d;
.filter-heading.filter-heading {
  // duplicated selector to increase specificity
  // so we can override DS styles
  @include d.text('lg');
  display: flex;
  align-items: center;
}
</style>
