import { productsCount } from '@/modules/cart-2/composables/use-counters';
import { getHeaderBadgesService } from '@/services/api/headerBadges';
import { HeaderBadges } from '@/types/header-badges';

interface State {
  headerBadges: HeaderBadges;
}

const initialState: State = {
  headerBadges: {
    totalProducts: null,
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async getHeaderBadges({ commit }): Promise<void> {
      const { totalProducts, ...headerBadges } = await getHeaderBadgesService();
      productsCount.value = totalProducts;
      commit('INIT_BADGES', headerBadges);
    },
  },
  mutations: {
    INIT_BADGES(state: State, headerBadges: HeaderBadges): void {
      state.headerBadges = headerBadges;
    },
  },
  getters: {
    getProductBadgesCount: (state: State): number => state.headerBadges.totalProducts,
  },
};
