import http from './http';
import { BrandDiscountContext, BrandDiscount } from '@/types/api/brand-discount';

export const getPersonalDiscount = async (context: BrandDiscountContext, brandIds: number[]): Promise<BrandDiscount[]> => {
  if (brandIds?.length > 0) {
    return http()
      .get(`/api/me/offers/brand-discount`, {
        params: { brand_ids: brandIds, context: context },
      })
      .then((response) => response.data);
  }
  return null;
};

export const getPersonalDiscountWithUuid = async (
  context: BrandDiscountContext,
  brandIds: string[]
): Promise<BrandDiscount[]> => {
  if (brandIds?.length > 0) {
    return http()
      .get(`/api/me/offers/brand-discount`, {
        params: { brand_uuids: brandIds, context: context },
      })
      .then((response) => response.data);
  }
  return null;
};
