import { CartState } from '@/store/cart';
import { Store } from 'vuex';
import { Item } from '@/types/cart';
import Product from '@/types/product';

export class CanAddFirstVariantToCartUseCase {
  constructor(private readonly store: Store<{ cart: CartState }>) {}

  /**
   * Check if a variant can be added to the current cart.
   *
   * @param variant - The variant to check. The stock of this variant will be checked.
   * @param cartItemQuantity - The cart item quantity to check.
   *
   * @returns A promise that resolves to true if the variant can be added to the cart, false otherwise.
   */
  async execute(product: Pick<Product, 'variants' | 'unit_multiplier'>, cartItemQuantity: number): Promise<boolean> {
    const variant = product?.variants?.[0];

    if (!variant) {
      return false;
    }

    await this.store.dispatch('cart/getCurrentCheckout');
    const cartItem = this.store.getters['cart/itemByVariantId'](variant.id) as Item;
    const isInCart = Boolean(cartItem);
    const unitMultiplier = variant.unit_multiplier ?? product.unit_multiplier;
    const hasEnoughStock =
      variant.stock.is_always_in_stock || variant.stock.available_quantity >= cartItemQuantity * unitMultiplier;

    return hasEnoughStock && (!isInCart || cartItem.quantity < cartItemQuantity);
  }
}
