import i18n from '@/services/i18n';
import { Context, Facet, facetRef, FacetsType } from '../../types/catalog';
import { config } from '../config';
import { getCurrency } from '@/services/metas/currency';
import { getLang } from '@/utilities/lang';

export function facets(context: Context, AksPlusElligible = false): Facet[] {
  const lang = getLang();
  return [
    {
      uid: facetRef.aks_plus,
      title: i18n.global.t('Ankorstore'),
      message: '',
      name: 'brand.aks_plus',
      trackingName: 'loyaltyOffer',
      placeHolder: '',
      type: FacetsType.refinement,
      display: AksPlusElligible ? config[context].filters.includes(facetRef.aks_plus) : false,
      badgeType: facetRef.aks_plus,
      isOpen: true,
    },
    {
      uid: facetRef.aks_plus_brand,
      title: i18n.global.t('Ankorstore'),
      message: '',
      name: 'aks_plus',
      trackingName: 'loyaltyOffer',
      placeHolder: '',
      type: FacetsType.refinement,
      display: AksPlusElligible ? config[context].filters.includes(facetRef.aks_plus_brand) : false,
      badgeType: facetRef.aks_plus,
      isOpen: true,
    },
    {
      uid: facetRef.discount,
      title: i18n.global.t('Discount'),
      message: '',
      name: facetRef.discount,
      trackingName: 'discount',
      placeHolder: '',
      type: FacetsType.refinement,
      display: config[context].filters.includes(facetRef.discount),
    },
    {
      uid: facetRef.locationBrand,
      title: i18n.global.t('Brand location'),
      name: 'brand.location',
      trackingName: 'location',
      message: i18n.global.t('Shop brands based on where they are located'),
      placeHolder: '',
      type: FacetsType.hierarchicalMultiSelect,
      display: config[context].filters.includes(facetRef.locationBrand),
    },
    {
      uid: facetRef.location,
      title: i18n.global.t('Brand location'),
      name: 'location',
      trackingName: 'location',
      message: i18n.global.t('Shop brands based on where they are located'),
      placeHolder: '',
      type: FacetsType.hierarchicalMultiSelect,
      display: config[context].filters.includes(facetRef.location),
    },
    {
      uid: facetRef.made_in,
      title: i18n.global.t('Made in'),
      name: 'made_in.' + lang,
      trackingName: 'madeIn',
      message: i18n.global.t('Shop high margin brands & products'),
      placeHolder: '',
      type: FacetsType.refinement,
      display: config[context].filters.includes(facetRef.made_in),
    },
    {
      uid: facetRef.brand,
      name: 'brand.name',
      trackingName: 'brands',
      placeHolder: i18n.global.t('Search for brands'),
      message: i18n.global.t('Shop product based on the brand products'),
      title: i18n.global.t('Brands'),
      type: FacetsType.refinement,
      display: config[context].filters.includes(facetRef.brand),
    },
    {
      uid: facetRef.tags,
      title: i18n.global.t('Values'),
      name: 'tags.' + lang,
      trackingName: 'values',
      message: i18n.global.t('Shop like-minded brands that share your values'),
      placeHolder: '',
      type: FacetsType.refinement,
      display: config[context].filters.includes(facetRef.tags),
    },
    {
      uid: facetRef.margin,
      title: i18n.global.t('Margin'),
      message: i18n.global.t('Shop high margin brands & products'),
      name: 'margin',
      trackingName: 'margin',
      placeHolder: '',
      type: FacetsType.refinement,
      display: config[context].filters.includes(facetRef.margin),
    },
    {
      uid: facetRef.marginBrand,
      title: i18n.global.t('Margin'),
      message: i18n.global.t('Shop high margin brands & products'),
      name: 'average_product_margin_ranges',
      trackingName: 'margin',
      placeHolder: '',
      type: FacetsType.refinement,
      display: config[context].filters.includes(facetRef.marginBrand),
    },
    {
      uid: facetRef.retail_price,
      title: i18n.global.t('Retail price range'),
      message: i18n.global.t('Shop products aligned to your store prices'),
      name: 'retail_prices.' + getCurrency(),
      trackingName: 'retailPriceRange',
      placeHolder: '',
      type: FacetsType.priceRange,
      display: config[context].filters.includes(facetRef.retail_price),
    },
    {
      uid: facetRef.shipping_lead_timeBrand,
      title: i18n.global.t('Shipping'),
      message: i18n.global.t('Find the brands that meet my deadlines'),
      name: 'shipping_lead_time',
      trackingName: 'shipping',
      placeHolder: '',
      type: FacetsType.refinement,
      display: config[context].filters.includes(facetRef.shipping_lead_timeBrand),
    },
    {
      uid: facetRef.shipping_lead_time,
      title: i18n.global.t('Shipping'),
      message: i18n.global.t('Find the brands that meet my deadlines'),
      name: 'brand.shipping_lead_time',
      trackingName: 'shipping',
      placeHolder: '',
      type: FacetsType.refinement,
      display: config[context].filters.includes(facetRef.shipping_lead_time),
    },
    {
      uid: facetRef.category,
      title: i18n.global.t('Categories'),
      message: '',
      name: 'catalog_hierarchical_categories.v3.lvl0',
      trackingName: 'categories',
      placeHolder: '',
      type: FacetsType.hierarchical,
      display: config[context].filters.includes(facetRef.category),
    },
  ];
}

export const getQuickFilters = (facetRefs: facetRef[], context: Context, AksPlusElligible: boolean) =>
  facets(context, AksPlusElligible).filter((facet) =>
    facetRefs.some((facetRef) => facet.uid === facetRef && facet.display === true)
  );
