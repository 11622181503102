export enum AsyncScript {
  GOOGLE_MAP = 'googleMaps',
}

export const AsyncScriptConfigs = {
  [AsyncScript.GOOGLE_MAP]: {
    url: `https://maps.googleapis.com/maps/api/js?key=${window.MAPS_PLACES_PUBLIC_ADDRESS_AUTOCOMPLETE_API_KEY}&libraries=places&callback=initFunction.googleMaps`,
    hasGlobalCallback: true,
  },
};
