import http from '@/services/api/http';
import { Brand } from '@/types/api/brand';

export const patchFastPayment = async (fastPaymentValue: boolean): Promise<Brand> => {
  const url = `/api/me/brand/fast-payment`;
  const payload = {
    enable_fast_payment: fastPaymentValue,
  };
  return await http().patch(url, payload);
};
