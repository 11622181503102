<script lang="ts">
import { defineComponent } from 'vue';
import Analytics from '@/services/analytics';
import { isEnabled } from '@/services/features';
import { loginPopinMixin } from '@/mixins/login-popin';
import { convertPriceMixins } from '@/mixins/price/convert';
import { formatPriceMixins } from '@/mixins/price/format';
import { helpCenterLinkAnalyticsMixin } from '@/mixins/help-center-link-analytics';

export default defineComponent({
  name: 'App',
  mixins: [loginPopinMixin, convertPriceMixins, formatPriceMixins, helpCenterLinkAnalyticsMixin],
  data: function () {
    return {
      previousScrollY: 0,
    };
  },
  mounted() {
    // Account pages are defining a router
    if (!this.$router && !window.location.pathname.startsWith('/account/')) {
      Analytics.page();
    }
  },
  created() {
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'auto';
    }
    if (isEnabled('like_products_and_brands')) {
      if (!this.$store.getters.productLikesAreInitialized) {
        this.$store.dispatch('fetchProductLikes');
      }
      if (!this.$store.getters.brandLikesAreInitialized) {
        this.$store.dispatch('fetchBrandLikes');
      }
    }
    if (this.$store.getters.user && this.$store.getters.userIsRetailer) {
      this.$store.dispatch('headerBadges/getHeaderBadges');
    }

    window.ga('send', 'pageview');
  },
});
</script>
