import { Mutation } from '@/store/drawer/mutation-types';

export enum DrawerComponentName {
  OrderReviewDrawer = 'OrderReviewDrawer',
  BrandResponseDrawer = 'BrandResponseDrawer',
}

export type DrawerSize = 'small' | 'medium' | 'large' | 'full';

const DEFAULT_OPTIONS = {
  size: 'small' as DrawerSize,
  isClipped: true,
  isPersistent: true,
};

const initialState: DrawerState = {
  drawerName: null,
  drawerOptions: DEFAULT_OPTIONS,
  hasEverBeenOpened: false,
  closeRequested: false,
  preventClose: false,
};

export interface DrawerPayload {
  name: DrawerComponentName;
  options?: DrawerOptions;
}

export interface DrawerLinkInfo {
  icon?: string;
  text: string;
  url: string;
}

export interface DrawerOptions {
  size?: DrawerSize;
  isClipped: boolean;
  isPersistent: boolean;
  title?: string;
  subtitle?: string;
  link?: DrawerLinkInfo;
}

export interface DrawerState {
  drawerName: DrawerComponentName | null;
  drawerOptions: DrawerOptions;
  hasEverBeenOpened: boolean;
  closeRequested: boolean;
  preventClose: boolean;
}

export default {
  namespaced: true,
  state: initialState,
  actions: {
    openDrawer({ commit }, payload: DrawerPayload) {
      commit(Mutation.SET_DRAWER, payload);
      commit(Mutation.SET_DRAWER_EVER_OPENED_STATE, true);
      commit(Mutation.SET_REQUEST_CLOSE, false);
      commit(Mutation.SET_PREVENT_CLOSE, false);
    },
    closeDrawer({ commit }) {
      commit(Mutation.SET_DRAWER, { name: null, options: DEFAULT_OPTIONS });
      commit(Mutation.SET_REQUEST_CLOSE, false);
      commit(Mutation.SET_PREVENT_CLOSE, false);
    },
    updateDrawerOptions({ commit }, payload: DrawerOptions) {
      commit(Mutation.SET_DRAWER_OPTIONS, payload);
    },
    setRequestClose({ commit }, value) {
      commit(Mutation.SET_REQUEST_CLOSE, value);
    },
    setPreventClose({ commit }, value) {
      commit(Mutation.SET_PREVENT_CLOSE, value);
    },
  },
  mutations: {
    [Mutation.SET_DRAWER](state: DrawerState, { name, options }: DrawerPayload) {
      state.drawerName = name;
      if (options) {
        state.drawerOptions = options;
      } else {
        state.drawerOptions = DEFAULT_OPTIONS;
      }
    },
    [Mutation.SET_DRAWER_EVER_OPENED_STATE](state: DrawerState, value: boolean) {
      state.hasEverBeenOpened = value;
    },
    [Mutation.SET_REQUEST_CLOSE](state: DrawerState, value: boolean) {
      state.closeRequested = value;
    },
    [Mutation.SET_PREVENT_CLOSE](state: DrawerState, value: boolean) {
      state.preventClose = value;
    },
    [Mutation.SET_DRAWER_OPTIONS](state: DrawerState, value: DrawerOptions) {
      state.drawerOptions = {
        ...state.drawerOptions,
        ...value,
      };
    },
  },
  getters: {
    isOpen: (state): boolean => !!state.drawerName,
    drawerOptions: (state): DrawerOptions => state.drawerOptions,
    isClipped: (state): DrawerOptions => state.drawerOptions.isClipped,
    isPersistent: (state): DrawerOptions => state.drawerOptions.isPersistent,
    drawerComponent: (state): DrawerComponentName | null => state.drawerName,
    hasEverBeenOpened: (state): boolean => state.hasEverBeenOpened,
    closeRequested: (state): boolean => state.closeRequested,
    preventClose: (state): boolean => state.preventClose,
  },
};
