import AnalyticsEvent from './analytics-event';
import { CartProperty, createCartProperty } from '../properties/cart-property';
import { BrandProperty, createBrandProperty } from '../properties/brand-property';

export default class CartReachedMinimumEvent extends AnalyticsEvent {
  public readonly name = 'Cart Reached Minimum';
  public properties: {
    cart: CartProperty;
    brand: BrandProperty;
  };

  constructor(cart: CartProperty, brand: BrandProperty) {
    super();
    this.properties = {
      cart: createCartProperty(cart),
      brand: createBrandProperty(brand),
    };
  }
}
