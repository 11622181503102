import AnalyticsEvent from './analytics-event';
import { Recipient, createRecipientProperty } from '../properties/conversation-property';

export default class MessageAbandonedEvent extends AnalyticsEvent {
  public readonly name = 'Message Abandoned';
  public properties: {
    recipient: Recipient;
    abandonedMessage: string;
  };

  constructor(recipient: Recipient, abandonedMessage: string) {
    super();
    this.properties = {
      recipient: createRecipientProperty(recipient),
      abandonedMessage,
    };
  }
}
