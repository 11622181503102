export const COOKIE_NAME = {
  NAVBAR_TOOLTIP_SNOOZED: 'navbar_tooltip_snoozed',
  PROMOTIONAL_INTEGRATION_BANNER: 'promotional_integration_banner',
  INTEGRATION_SETUP: 'integration_setup',
  SAVE_YOUR_CUSTOMERS_INFORMATION_CLOSED: 'save_your_customers_information_closed',
  SHOW_THANK_YOU_FOR_YOUR_PRE_ORDER: 'show_thank_you_for_your_pre_order',
  PREORDER_ARRIVED_POPUP_SNOOZED: 'preorder_arrived_popup_snoozed',
  AKS_BANNER_CLOSED: 'aks_banner_closed',
  PRODUCT_MENU_PREORDER_NEW_BADGE_SNOOZED: 'product_menu_preorder_new_snoozed_v2',
};
