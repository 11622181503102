import { Item } from '@/types/cart';
import { isVariantOutOfStock, StockStatus } from '@/services/product-variants';
import { Ref, ref } from 'vue';
import Product, { Variant, Option } from '@/types/product';
import { captureException } from '@/sentry';
import { createBackInStockAlert, getBackInStockAlerts, StockAlert } from '@/services/back-in-stock/back-in-stock';

import Analytics from '@/services/analytics';
import { ClickStockAlert } from '@/services/analytics/events/stock/click-stock-alert.event';

export function useStockStatus() {
  const productVariantStockAlert = ref<StockAlert>(null);
  const isStockAlertLoading = ref(false);
  const restockAlerts: Ref<StockAlert[]> = ref([]);

  const createStockAlert = async (productId, brandId, productVariantId) => {
    isStockAlertLoading.value = true;
    try {
      productVariantStockAlert.value = await createBackInStockAlert(productVariantId);

      Analytics.track(new ClickStockAlert(productId, brandId));
    } catch (e) {
      captureException(e);
    } finally {
      isStockAlertLoading.value = false;
    }
  };

  const fetchStockAlerts = async (variantIds: number[]) => {
    isStockAlertLoading.value = true;
    try {
      const alerts = await getBackInStockAlerts(variantIds);
      productVariantStockAlert.value = alerts?.[0] ?? null;
      if (alerts?.length) {
        restockAlerts.value = alerts;
      }
    } catch (e) {
      captureException(e);
    } finally {
      isStockAlertLoading.value = false;
    }
  };
  const getStockStatus = (item: Item): StockStatus => {
    const isOutOfStock = isVariantOutOfStock(item.variant ?? item.option);
    return isOutOfStock ? StockStatus.OutOfStock : StockStatus.InStock;
  };

  const checkAllStatuses = (optionOrVariants: Array<Variant | Option>, status: StockStatus): boolean =>
    !!optionOrVariants?.length && optionOrVariants?.every(({ stock }) => stock?.status === status);

  const allProductVariantsHaveStatus = (product: Product, status: StockStatus): boolean => {
    if (!product.variants?.length && !product.options?.length) {
      captureException(`Product with id ${product.id} has no variants nor options`);
    }
    return checkAllStatuses(product.variants, status) || checkAllStatuses(product.options, status) || false;
  };

  const areAllVariantsLowInStock = (product: Product): boolean => {
    return allProductVariantsHaveStatus(product, StockStatus.LowInStock);
  };

  return {
    productVariantStockAlert,
    isStockAlertLoading,
    restockAlerts,
    getStockStatus,
    areAllVariantsLowInStock,
    createStockAlert,
    fetchStockAlerts,
  };
}
