<script lang="ts">
import Card from '@/modules/cards/card.vue';
import { NormalTileType } from '@bc/discovery/domain/catalog';
import { defineComponent } from 'vue';
import { AkSkeleton } from '@ankorstore/design-system';

export default defineComponent({
  name: 'SkeletonCard',
  components: {
    Card,
    AkSkeleton,
  },
  props: {
    tileType: {
      type: String as () => NormalTileType,
      default: NormalTileType.Product,
      required: true,
    },
  },
  setup(props) {
    return {
      isProduct: props.tileType === NormalTileType.Product,
    };
  },
});
</script>

<template>
  <Card>
    <template #topContent>
      <AkSkeleton
        class="skeleton-image"
        :rounded="false"
      />
    </template>
    <template #bottomContent>
      <div class="ds-px-4 ds-py-20px ds-flex ds-flex-col">
        <AkSkeleton
          rounded
          class="ds-w-3/4 ds-h-4 ds-mb-2"
        />
        <AkSkeleton
          rounded
          class="ds-w-3/6 ds-h-4 ds-mb-2"
        />
        <AkSkeleton
          v-if="isProduct"
          rounded
          class="ds-w-2/6 ds-h-4 ds-mt-1 ds-mb-2"
        />
      </div>
    </template>
  </Card>
</template>

<style lang="scss" scoped>
.ds-py-20px {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.skeleton-image {
  aspect-ratio: 1/1;
}
</style>
