<!-- eslint-disable @intlify/vue-i18n/no-dynamic-keys -->
<template>
  <div class="pt-1">
    <AkRadio
      v-for="answer in question.answers"
      :key="`${answer.id}-radio`"
      v-model.lazy.trim="currentAnswer.alias"
      :text="$t(answer.label)"
      :disabled="answer.disabled || question.disabled"
      :value="answer.id.toString()"
      :name="question.alias"
      class="question-radio side-label"
      data-testid="question-radio-item"
      :class="vuelidateFieldClassName(state[question.alias])"
      @change="onRadioChange(question, answer)"
    />
    <span
      v-if="state[question.alias] && state[question.alias].$dirty && state[question.alias].required.$invalid"
      :class="[isRevamp ? 'ak-field-error' : 'error-message']"
    >{{ $t('This field is required') }}</span>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { defineComponent, PropType } from 'vue';
import { Answer, Question } from '@/types/question';
import { vuelidateFieldClassName } from '@/utilities/fieldClassName';
import { State } from '@/types/state';

export default defineComponent({
  name: 'QuestionRadio',
  props: {
    question: {
      type: Object as PropType<Question>,
      required: true,
    },
    state: {
      type: Object as PropType<State>,
      required: true,
    },
    isRevamp: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      currentAnswer: { alias: false },
    };
  },
  watch: {
    currentAnswer(answer: Answer): void {
      const { alias, id } = answer;
      this.$emit('change', alias, {
        answer_id: id,
      });
    },
  },
  methods: {
    onRadioChange(question: Question, answer: Answer): void {
      this.currentAnswer = {
        id: answer.id,
        label: answer.label,
        answer_type: answer.answer_type,
        alias: question.alias,
        prefilled_content: answer.prefilled_content,
      };
    },
    vuelidateFieldClassName,
  },
});
</script>

<style scoped lang="scss">
.question-radio {
  @apply ds-mb-2 ds-text-sm ds-text-neutral-700;
}
</style>
