<template>
  <AkButton
    v-if="user && $isEnabled('like_products_and_brands')"
    class="follow-brand-button"
    :size="size"
    outlined
    :symbol="icon"
    @click.stop.prevent="toggle"
  >
    {{ label }}
  </AkButton>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  name: 'FollowBrand',
  props: {
    brandId: { type: Number, required: true },
    size: {
      type: String as () => 'md' | 'lg' | 'xl',
      default: 'md',
      validator: function (value: string) {
        return ['md', 'lg', 'xl'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    ...mapGetters({ user: 'user', followedBrands: 'getLikedBrands' }),
    isFollowed(): boolean {
      return this.followedBrands?.includes(this.brandId);
    },
    icon(): string {
      return this.isFollowed ? 'check' : 'plus';
    },
    label(): string {
      return this.isFollowed ? this.$t('Following') : this.$t('Follow');
    },
  },
  methods: {
    ...mapActions(['likeBrand', 'unLikeBrand']),
    toggle(): void {
      this.isFollowed ? this.unLikeBrand({ id: this.brandId }) : this.likeBrand({ id: this.brandId });
    },
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';
.follow-brand-button {
  z-index: 5;
}
</style>
