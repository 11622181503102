<template>
  <div class="ak-header__headline ak-header__headline--green">
    <i18n-t
      keypath="Only {days} {nbdays} {hours} {nbhours} {minutes} {nbminutes} {seconds} {nbseconds} left to use your {totalamount} 60-day credit!"
      tag="p"
    >
      <template #nbdays>
        {{ $tc('day | day | days', days) }}
      </template>
      <template #nbhours>
        {{ $tc('hour | hour | hours', hours) }}
      </template>
      <template #nbminutes>
        {{ $tc('minute | minute | minutes', minutes) }}
      </template>
      <template #nbseconds>
        {{ $tc('second | second | seconds', seconds) }}
      </template>
      <template #days>
        <span class="ak-header__headline--date">{{ days }}</span>
      </template>
      <template #hours>
        <span class="ak-header__headline--date">{{ hours }}</span>
      </template>
      <template #minutes>
        <span class="ak-header__headline--date">{{ minutes }}</span>
      </template>
      <template #seconds>
        <span class="ak-header__headline--date">{{ seconds }}</span>
      </template>
      <template #totalamount>
        <span class="ak-header__headline--date">{{ $root.formatPrice(amount, '$0,0') }}</span>
      </template>
    </i18n-t>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BannerCreditEvent',
  props: {
    endDate: {
      type: String,
      required: true,
    },
    creditLeft: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      end: null,
      diff: 0,
      amount: null,
    };
  },
  computed: {
    days() {
      return Math.floor(this.diff / (3600 * 24));
    },
    hours() {
      return Math.floor((this.diff - this.days * 3600 * 24) / 3600);
    },
    minutes() {
      return Math.floor((this.diff - this.days * 3600 * 24 - this.hours * 3600) / 60);
    },
    seconds() {
      return Math.floor(this.diff - this.days * 3600 * 24 - this.hours * 3600 - this.minutes * 60);
    },
  },
  created() {
    this.amount = JSON.parse(this.creditLeft);
    this.end = Date.parse(this.endDate);
  },
  mounted() {
    this.computeDiff();
    setInterval(() => {
      this.computeDiff();
    }, 1000);
  },
  methods: {
    computeDiff() {
      this.diff = Math.round((this.end - new Date().getTime()) / 1000);
    },
  },
});
</script>
<style scoped lang="scss">
@import '@css/vue-import';
p {
  margin-bottom: 0;
  vertical-align: baseline;
}
</style>
