<template>
  <ExpandableFilter
    v-for="filter in getFiltersWithASlot(filters)"
    :key="filter.name"
    class="expandable-filters"
    :title="filter.title"
    :unit="filter.isNumericAttributeFilter ? filter.unit : null"
    :count="filter.count"
    :is-open="filter.isOpen ?? defaultOpen"
    :badge-type="filter.badgeType"
    :has-beta-label="filter.hasBetaLabel"
  >
    <template v-if="'filters' in filter">
      <div
        v-for="groupedFilter in getFiltersWithASlot(filter.filters)"
        :key="groupedFilter.uuid"
        class="expandable-filters__grouped-filter"
      >
        <AkParagraph
          class="expandable-filters__grouped-filter-title"
          weight="bold"
        >
          <span>{{ groupedFilter.title }}</span>
          <span
            v-if="groupedFilter.unit"
            class="expandable-filters__grouped-filter-title-unit"
          >
            {{ ` (${groupedFilter.unit})` }}
          </span>
        </AkParagraph>
        <slot
          :name="groupedFilter.type"
          :filter="groupedFilter"
        />
      </div>
    </template>
    <slot
      v-else
      :name="filter.type"
      :filter="filter"
    />
  </ExpandableFilter>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import ExpandableFilter from './expandable-filter.vue';
import { AkParagraph } from '@ankorstore/design-system';
import { Filter, FilterGroup } from './types';

export default defineComponent({
  name: 'ExpandableFilters',
  components: {
    ExpandableFilter,
    AkParagraph,
  },
  props: {
    filters: {
      type: Array as PropType<(Filter | FilterGroup)[]>,
      required: true,
    },
    defaultOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(_props, { slots }) {
    const getFiltersWithASlot = (filters: (Filter | FilterGroup)[]) =>
      filters.filter(
        (filter) =>
          // it's a filter with a slot
          ('type' in filter && filter.type in slots) ||
          // it's a filter group
          'filters' in filter
      );
    return { getFiltersWithASlot };
  },
});
</script>

<style scoped lang="scss">
.expandable-filters {
  &__grouped-filter {
    margin-bottom: 1rem;
  }
  &__grouped-filter-title {
    margin-bottom: 0.5rem;
    color: theme('colors.neutral.900');
  }
  &__grouped-filter-title-unit {
    font-weight: normal;
    color: theme('colors.neutral.700');
  }
}
</style>
