import { Ref } from 'vue';
import { OverviewData, TimeSeriesData } from '@bc/advertisement/domain';

export interface AdsChartsData {
  [GraphContentSlug.Impressions]: GraphData;
  [GraphContentSlug.Clicks]: GraphData;
  [GraphContentSlug.Revenue]: GraphData;
}

export enum GraphContentSlug {
  Impressions = 'impressions',
  Clicks = 'clicks',
  Revenue = 'revenue',
}

export enum AdsDashboardTabSlug {
  Overview = 'overview',
  Campaigns = 'campaigns',
}

interface GraphData {
  labels: string[];
  datasets: Dataset[];
}

interface Dataset {
  label: string;
  backgroundColor: string;
  data: number[];
}

export interface CardData {
  id: CardId;
  tooltipText: string;
  title: string;
  value: number;
}

export enum CardId {
  ImpressionsCount = 'impressionsCount',
  ClicksCount = 'clicksCount',
  Cost = 'costAmount',
  GmvAmount = 'gmvAmount',
  Roas = 'roas',
  NewBuyers = 'newBuyersCount',
  WishlistCount = 'wishlistCount',
  AddToCartCount = 'addToCartCount',
}

export interface AdsPerformanceGraphs {
  [GraphContentSlug.Impressions]: GraphData;
  [GraphContentSlug.Clicks]: GraphData;
  [GraphContentSlug.Revenue]: GraphData;
}

export interface AdsPerformance {
  performanceApiIsLoading: Ref<boolean>;
  overviewDataResult: Ref<OverviewData | null>;
  graphDataResult: Ref<AdsPerformanceGraphs | null>;
  error: Ref<null>;
  triggerPerformanceFetch: (
    brandId: string,
    startDate: Date,
    endDate: Date
  ) => Promise<{
    graphDataResponse: TimeSeriesData[];
    overViewResponse: OverviewData;
  }>;
}

export interface CampaignAdsPerformance extends AdsPerformance {
  statusChangeError: Ref<null>;
  triggerStopCampaign: () => Promise<void>;
  triggerPerformanceFetch: () => Promise<{
    overViewResponse: OverviewData;
    graphDataResponse: TimeSeriesData[];
    revenueGraphDataResponse: TimeSeriesData[];
  }>;
}

export interface BudgetConfig {
  value: number;
  isChecked: boolean;
  isDefault?: boolean;
}

export interface TimeRange {
  startDate: Date;
  endDate: Date;
}

export enum TimeRangeOption {
  LastMonth = 'last_month',
  Last3Months = 'last_3_month',
  LastYear = 'last_year',
  CustomRange = 'custom_range',
}

export enum CampaignEventType {
  CampaignCreated = 'CampaignCreated',
  CampaignEdited = 'CampaignEdited',
}
