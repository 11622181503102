import { Brand } from '../properties/brand';
import AnalyticsEvent from './analytics-event';
import { BrandClickOrigin, BrandClickAction } from '@/types/analytics/brand-click-event';

export class BrandClickedEvent extends AnalyticsEvent {
  public readonly name = 'Brand Clicked';

  constructor(
    public readonly properties: Brand & {
      origin?: BrandClickOrigin;
      action?: BrandClickAction;
      value?: any;
      clicked_value?: string;
    }
  ) {
    super();
  }
}
