export enum ImagesImportMethod {
  Url = 'Url',
  Upload = 'Upload',
}

export enum ImportScope {
  NewProductsOnly = 'NewProductsOnly',
  NewAndExistingProducts = 'NewAndExistingProducts',
}

export enum GsheetModalStepName {
  Setup = 'Setup',
  DataEntry = 'DataEntry',
}
