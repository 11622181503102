<template>
  <LikeProductButton
    v-if="user && $isEnabled('like_products_and_brands')"
    class="like-button"
    :class="NativeAdClickOrigin.AddToFavourites"
    ga-on="click"
    :ga-event-category="type"
    :ga-event-action="isLiked ? 'unlike' : 'like'"
    :ga-event-label="type + '/' + id"
    :title="isLiked ? $t('Unlike') : $t('Like')"
    :is-selected="isLiked"
    :is-focused="isFocused"
    @change="onChange"
  />
</template>

<script lang="ts">
import { mapGetters, mapActions } from 'vuex';

import { defineComponent, PropType } from 'vue';

// eslint-disable-next-line dependency-cruiser/errors
import LikeProductButton from '@bc/discovery/ui/product/like-product-button.vue';
import { AdditionalLikeTrackingData } from '@/types/analytics/recommendation-tracking';
import { NativeAdClickOrigin } from '@bc/advertisement';

export default defineComponent({
  name: 'LikeButton',
  components: { LikeProductButton },
  props: {
    type: {
      validator: function (value: string) {
        // Must be one of these values
        return ['product', 'brand'].indexOf(value) !== -1;
      },
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    additionalTrackingData: {
      type: Object as PropType<AdditionalLikeTrackingData>,
      default: null,
    },
    withTooltip: {
      type: Boolean,
      default: false,
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['unlike-item'],
  setup() {
    return { NativeAdClickOrigin };
  },
  computed: {
    ...mapGetters(['user', 'brandIsLiked', 'productIsLiked']),
    isLiked(): boolean {
      return this.type === 'product' ? this.productIsLiked(this.id) : this.brandIsLiked(this.id);
    },
  },
  methods: {
    ...mapActions(['likeProduct', 'unLikeProduct', 'likeBrand', 'unLikeBrand']),
    unLike() {
      this.$parent.$emit('unlike-item', this.id);
      return this.type === 'product' ? this.unLikeProduct({ id: this.id }) : this.unLikeBrand({ id: this.id });
    },
    like() {
      return this.type === 'product'
        ? this.likeProduct({
            id: this.id,
            additionalTrackingData: this.additionalTrackingData,
          })
        : this.likeBrand({ id: this.id });
    },
    onChange() {
      if (this.isLiked) {
        this.unLike();
      } else {
        this.like();
      }
    },
  },
});
</script>
