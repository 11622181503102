import { IdPosition } from '@bc/shared';
import AnalyticsEvent from '@/services/analytics/events/analytics-event';

export class ViewTile extends AnalyticsEvent {
  public readonly name = 'view_tile';

  constructor(
    public readonly properties: {
      page_number?: number;
      value_proposition?: string;
      value_proposition_id?: number;
      id_section?: string;
      id_algo?: string;
      id_product?: IdPosition[];
      id_brand?: IdPosition[];
      position?: number | number[];
      itemtype?: string;
      section_type?: string;
    }
  ) {
    super();
  }
}

export default ViewTile;
