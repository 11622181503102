import { ComputedRef } from 'vue';
import algoliaSearch, { type AlgoliaSearchOptions, type SearchClient } from 'algoliasearch/lite';
import { isEnabled } from '@/services/features';
import type { MultipleQueriesQuery, MultipleQueriesResponse, MultipleQueriesOptions } from '@algolia/client-search';
import type { RequestOptions } from '@algolia/transporter';
import { getGlobalEvents } from '@/services/api/business-events';
import { Config, Context, SnippetTemplate, facetRef } from '../../types/catalog';
import { BusinessEventPeriode } from '@/types/business-event';
import { getIndexName, IndexName } from '@/services/analytics/algolia/index-name';
import { http } from '@/services/api/http';

export const APPID = 'ankorstore';
export const SEARCHKEY = 'ankorstore';

export type ResultsCallback = <TResult>(
  requests: Readonly<MultipleQueriesQuery[]>,
  response: MultipleQueriesResponse<TResult>
) => void;

function amendFacetsStats<TResult>(response: MultipleQueriesResponse<TResult>) {
  response.results.forEach((result) =>
    Object.entries(result.facets_stats ?? {}).forEach(([facetName, facetStat]) => {
      // @ts-expect-error This is a hack to add the stats to the facet. AisRangeInput requires it.
      // https://github.com/algolia/instantsearch/blob/7b06c47ec9d901b5c2544c0edd793ee63208cfcb/packages/instantsearch.js/src/connectors/range/connectRange.ts#L354
      result.facets[facetName] = { stats: facetStat };
    })
  );

  return response;
}

async function getEventsWithLiveDiscounts() {
  const globalEvents = await getGlobalEvents();
  return globalEvents.filter((event) => event.with_discount_live);
}

export async function getTilesEvent() {
  const globalEvents = await getGlobalEvents();
  return globalEvents.filter((event) => event?.external_cms_content?.['catalogue-tile']).filter(Boolean);
}

async function amendDiscountFacets<TResult>(response: MultipleQueriesResponse<TResult>) {
  const eventsWithLiveDiscounts = await getEventsWithLiveDiscounts();

  if (!eventsWithLiveDiscounts.length) {
    return response;
  }

  return {
    results: response.results.map((result) => ({
      ...result,
      facets: {
        ...result.facets,
        [facetRef.discount]: {
          '1_10': Number.POSITIVE_INFINITY,
          '11_20': Number.POSITIVE_INFINITY,
          '21_30': Number.POSITIVE_INFINITY,
          '31_40': Number.POSITIVE_INFINITY,
          '41_*': Number.POSITIVE_INFINITY,
        },
      },
    })),
  };
}

export const removeAnkorstorePlusFacets = (requests, results, userCountry: string) => {
  if (userCountry !== 'FR') {
    if (requests[0].indexName === getIndexName(IndexName.Products)) {
      results.results.forEach((element) =>
        element.facets['brand.aks_plus'] ? delete element.facets['brand.aks_plus']['delivering_in_48h'] : ''
      );
    } else {
      results.results.forEach((element) =>
        element.facets['aks_plus'] ? delete element.facets['aks_plus']['delivering_in_48h'] : ''
      );
    }
  }
  return results;
};

const isFacetFilterArray = (facetFilters: MultipleQueriesQuery['params']['facetFilters']): facetFilters is string[][] =>
  Array.isArray(facetFilters) && facetFilters.every((filter) => Array.isArray(filter));

async function transformDiscountFilter(requests: readonly MultipleQueriesQuery[], config: Config) {
  const eventsWithLiveDiscounts = await getEventsWithLiveDiscounts();

  return requests.map((request) => {
    const isBrandIndex = config?.template === SnippetTemplate.BRANDS;
    if (isFacetFilterArray(request.params.facetFilters)) {
      const facetFilters = request.params.facetFilters.map((filters) =>
        filters.flatMap((filter) => {
          const [facetName, facetValue] = filter.split(':');

          if (facetName === facetRef.discount && eventsWithLiveDiscounts.length) {
            return eventsWithLiveDiscounts.map((event) =>
              isBrandIndex
                ? `business_events.${event.business_event_id}.discount.range:${facetValue}`
                : `brand.business_events.${event.business_event_id}.discount.range:${facetValue}`
            );
          }

          return filter;
        })
      );

      return {
        ...request,
        params: {
          ...request.params,
          facetFilters,
        },
      };
    } else {
      return request;
    }
  });
}

export const searchClient = (
  config?: Config,
  resultsCallback?: ResultsCallback,
  catalogTilesPerRow?: ComputedRef<number>,
  userCountry?: string
): SearchClient => {
  const client = algoliaSearch(APPID, SEARCHKEY, getAlgoliaSearchOptions());

  return {
    ...client,
    search: async <TResult>(requests: readonly MultipleQueriesQuery[], options: RequestOptions & MultipleQueriesOptions) => {
      let modifiedRequests = requests;
      let tiles: BusinessEventPeriode[] = [];

      if (config?.eventTile) {
        tiles = await getTilesEvent();
        modifiedRequests = await modifiedRequests.map((request) => ({
          ...request,
          params: {
            ...request.params,
            hitsPerPage: Math.max(request.params.hitsPerPage - tiles.length, 0),
          },
        }));
      }

      if (isAdsEnabled(config?.context, catalogTilesPerRow?.value)) {
        modifiedRequests = requests.map((request) => ({
          ...request,
          params: {
            ...request.params,
            'ads[rowCount]': catalogTilesPerRow.value,
            'ads[limit]': 12,
          },
        }));
      }
      modifiedRequests = await transformDiscountFilter(modifiedRequests, config);
      let results = await client.search<TResult>(modifiedRequests, options);
      results = amendFacetsStats(results);
      results = await amendDiscountFacets(results);

      if (resultsCallback) {
        resultsCallback(requests, results);
      }

      const firstResult = results.results[0];

      if ('hits' in firstResult && firstResult.hits.length && tiles.length) {
        // On subsequent reqeusts, the previously inserted event tiles are still present. We need to remove them before inserting them again.
        const firstResultHits = firstResult.hits.filter((hit) => !('external_cms_content' in hit));

        tiles.forEach((element) => {
          firstResultHits.splice(element.external_cms_content['catalogue-tile'][0].body[0].primary.position - 1, 0, element);
        });

        firstResult.hits = firstResultHits;
      }
      if (isEnabled('RET-2591') && Boolean(userCountry)) {
        results = await removeAnkorstorePlusFacets(requests, results, userCountry);
      }

      return results;
    },
  };
};

export const getAlgoliaSearchOptions = (): AlgoliaSearchOptions => {
  const host = window.location.host;

  return {
    hosts: [
      {
        url: isEnabled('mock_searchengine') ? `${host}/mocks/searchengine` : `${host}/api/v2`,
        protocol: 'https',
      },
    ],
    timeouts: {
      connect: 10,
      read: 10,
      write: 30,
    },
    requester: {
      send: async (request) => {
        try {
          const response = await http().request({
            ...request,
            // Prevent axios from parsing the response as JSON.
            // We want to keep it as a string because the Algolia client will parse it.
            transformResponse: [],
          });

          return {
            content: response.data,
            isTimedOut: false,
            status: response.status,
          };
        } catch (error) {
          return {
            content: error.response?.data,
            isTimedOut: error.code === 'ECONNABORTED',
            status: error.response?.status,
          };
        }
      },
    },
  };
};

const isAdsEnabled = (context: Context, catalogTilesPerRow: number) => {
  return [Context.CATEGORY_PRODUCTS, Context.SEARCH].includes(context) && isEnabled('RET-2772') && catalogTilesPerRow;
};
