import http from '@/services/api/http';
import { deserialize } from '@/services/utils/jsonapi-parser';
import { IntegrationConfiguration } from '@/types/integration';

export const patchIntegration: (
  id: string,
  orderTags: string[],
  configuration: IntegrationConfiguration | null
) => Promise<any> = async (id: string, orderTags: string[], configuration: IntegrationConfiguration | null) => {
  const url = `/api/internal/v1/rutter-integrations/${id}`;
  const payload = {
    data: {
      type: 'rutter-integrations',
      id,
      attributes: { orderTags, configuration },
    },
  };

  let response;

  try {
    response = await http().patch(url, payload, {
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
    });
  } catch (error) {
    return Promise.resolve(error.response.data);
  }

  response = deserialize(response.data);
  return response.data;
};

export const getActivePublicIntegration = async () => {
  const url = '/api/internal/v1/rutter-integrations';
  let response = await http().get(url);
  response = deserialize(response.data);
  return response.data;
};

export const listExternalIntegrations: () => Promise<{platform: string, status: string}[]> = async () => {
  const url = '/api/internal/v1/integration/external-integrations';
  let response = await http().get(url);
  response = deserialize(response.data);
  return response.data;
};

export const deactivateIntegration = async (integrationId) => {
  const url = `/api/internal/v1/rutter-integrations/${integrationId}`;
  return http().delete(url, {
    headers: {
      accept: 'application/vnd.api+json',
    },
  });
};

export const testShopifyOrder: () => Promise<string> = async () => {
  const url = '/api/internal/v1/rutter-integrations/send-test-order';
  const response = await http().post<{ orderUrl: string }>(url);
  return response.data.orderUrl;
};

export const checkShopUrl = async (platformName: string, platformUrl: string) => {
  const url = '/api/internal/v1/public-integrations/platform-verifications';
  let response = await http().post(
    url,
    {
      data: {
        type: 'platform-verifications',
        attributes: {
          platformName,
          platformUrl,
        },
      },
    },
    {
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
    }
  );
  response = deserialize(response.data);
  return response.data;
};

export const createShopifyIntegration: (
  platformUrl: string,
  adminAccessToken: string,
  apiSecretKey: string
) => Promise<unknown> = async (platformUrl: string, adminAccessToken: string, apiSecretKey: string) => {
  const url = '/api/internal/v1/rutter-integrations/store-shopify';

  let response = await http().post(url, null, {
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
    params: {
      platformUrl,
      adminAccessToken,
      apiSecretKey,
    },
  });

  response = deserialize(response.data);
  return response.data;
};
