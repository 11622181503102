export const RESET_ARROW_POSITION = 'resetArrowPosition';
export const SET_ARROW_POSITION_UP = 'setArrowPositionUp';
export const SET_ARROW_POSITION_DOWN = 'setArrowPositionDown';
export const SET_ARROW_POSITION = 'setArrowPosition';
export const SET_INITIAL_RESULTS = 'setInitialSearchResults';
export const SET_RESULTS_ELASTIC = 'setElasticSearchResults';
export const SET_RESULTS_IDS = 'setSearchResultsIds';
export const SET_RESULTS_VISIBILITY = 'setResultsVisibility';
export const SET_ORIGINAL_USER_INPUT = 'setOriginalUserSearchInput';
export const SET_HISTORICAL_FROM_LOCALSTORAGE = 'setHistoricalFromLocalstorage';
export const SET_RECENT_SEARCH_HISTORY = 'setRecentSearchHistory';
export const REMOVE_RECENT_SEARCH = 'removeRecentSearch';
export const SET_RECENT_SEARCH = 'setRecentSearch';
export const SET_SUGGESTED_CATEGORIES = 'setSuggestedCategories';
export const SET_POPULAR_SEARCHES = 'setSuggestedPopularSearches';
