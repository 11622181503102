<template>
  <div>
    <a
      v-if="selectedParent"
      class="ds-block ds-mb-4"
      :href="selectedParent.url"
      @click.prevent="emit('itemClicked', selectedParent)"
    >
      <FilterLabel
        :label="selectedParent.label"
        :count="selectedParent.count"
        :selected="selectedParent.isRefined"
      >
        &#60;
      </FilterLabel>
    </a>
    <ul class="ds-flex ds-flex-col ds-gap-4">
      <li
        v-for="item in itemsToDisplay"
        :key="item.label"
      >
        <a
          data-testid="categoryFilterLink"
          :href="item.url"
          @click.prevent="emit('itemClicked', item)"
        >
          <FilterLabel
            :label="item.label"
            :count="item.count"
            :selected="item.isRefined"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { Item, LinkItem } from './types';
import FilterLabel from './filter-label.vue';

const props = defineProps<{ items: Item[]; createUrl: (value: string) => string }>();
const emit = defineEmits(['itemClicked']);

const findSelectedParent = (items: Item[]): Item | undefined => {
  if (!items) {
    return undefined;
  }

  const selectedItem = items.find((item) => item.isRefined);

  if (!selectedItem) {
    return undefined;
  }

  const selectedChild = findSelectedParent(selectedItem?.data);

  if (!selectedChild) {
    return selectedItem;
  }

  return findSelectedParent([selectedChild]);
};

const createLinkItem = (item: Item): LinkItem => {
  if (!item) {
    return undefined;
  }

  return {
    ...item,
    url: props.createUrl(item.value),
    data: item.data?.map((child) => createLinkItem(child)),
  };
};

const selectedParent = computed(() => createLinkItem(findSelectedParent(props.items)));
const itemsToDisplay = computed<LinkItem[]>(() => {
  if (selectedParent.value) {
    return selectedParent.value.data;
  }

  return props.items.map(createLinkItem);
});
</script>
