export const changePosition = <T>(list: T[], item: T, newIndex: number): T[] => {
  const updatedList = [...list];

  const index = updatedList.indexOf(item);
  updatedList.splice(index, 1);
  updatedList.splice(newIndex, 0, item);

  return updatedList;
};

export default changePosition;
