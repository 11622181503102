import { getLabel } from '@bc/discovery/feature/catalog';
import type { Item, Filter } from '@bc/discovery/ui/filters';
import type { LabelOverrides } from '../types/label-overrides';

/**
 * Override the labels of the items with the label overrides.
 * This function will mutate the items.
 *
 * @param labelOverrides The label overrides.
 * @param items The items to override the labels of.
 *
 * @returns The original items array with the labels overridden.
 *
 * @example
 * const labelOverrides = {
 *   'foo': 'bar',
 *   'baz': 'qux',
 * };
 * const items = [
 *   { label: 'foo', count: 1 },
 *   { label: 'baz', count: 2 },
 * ];
 * const overriddenItems = overrideLabels(labelOverrides, items);
 * // overriddenItems = [
 * //   { label: 'bar', count: 1 },
 * //   { label: 'qux', count: 2 },
 * // ];
 *
 */
export const overrideLabels = (labelOverrides: LabelOverrides, items: Item[]) => {
  for (const item of items) {
    item.label = labelOverrides[item.label] ?? item.label;
    if (Array.isArray(item.data)) {
      overrideLabels(labelOverrides, item.data);
    }
  }

  return items;
};

export const overrideFilterLabels = (filter: Pick<Filter, 'uid'>, labelOverrides: LabelOverrides, items: Item[] = []) => {
  for (const item of items) {
    item.label = getLabel(labelOverrides, filter, item.label);
    if (Array.isArray(item.data)) {
      overrideFilterLabels(filter, labelOverrides, item.data);
    }
  }

  return items;
};
