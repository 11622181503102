import AnalyticsEvent from './analytics-event';
import { Checkout } from './segment-ecommerce-types';

export class CheckoutStartedEvent extends AnalyticsEvent {
  public readonly name = 'Checkout Started';

  constructor(public readonly properties: Checkout) {
    super();
  }
}
