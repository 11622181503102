<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { AkModal } from '@ankorstore/design-system';

import { loginPopinMixin } from '@/mixins/login-popin';
import { RetailerFormType } from '@/types/retailer-form-type';
import VatForm from '@/components/vat-form.vue';
import ModalErrorPopin from '@/components/modal-error-popin.vue';

export default defineComponent({
  name: 'SeePriceCta',
  components: {
    VatForm,
    ModalErrorPopin,
    AkModal,
  },
  mixins: [loginPopinMixin],
  data() {
    return {
      isModalTeleportEnabled: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'userIsRetailer', 'hasUnhandledCountry', 'retailer']),
    vatModal() {
      return this.$refs.vatModal as DS['AkModal'];
    },
    errorModal() {
      return this.$refs.errorModal as DS['AkModal'];
    },
  },
  methods: {
    openModal() {
      if (this.userIsRetailer && this.hasUnhandledCountry) {
        this.openErrorModal();
        return;
      }

      if (this.userIsRetailer) {
        this.openVatModal();
        return;
      }

      if (!this.user) {
        this.openLoginPopin(RetailerFormType.Register);
        return;
      }
    },
    async openVatModal() {
      await this.enableModalTeleport();
      this.vatModal.openModal();
    },
    async closeVatModal() {
      this.vatModal.close();
      await this.disableModalTeleport();
    },
    async openErrorModal() {
      await this.enableModalTeleport();
      this.errorModal.openModal();
    },
    async closeErrorModal() {
      this.errorModal.close();
      await this.disableModalTeleport();
    },

    /**
     * Enable/disable teleportation of the modals to the body.
     * This is necessary to control when the modals are created & destroyed and avoids unnecessary creation of
     * components when the modal is not visible.
     * `nextTick` is used to ensure the modal is created before attempting to open it and to allow it to close before destroying it.
     */
    async enableModalTeleport() {
      this.isModalTeleportEnabled = true;
      await this.$nextTick();
    },
    async disableModalTeleport() {
      await this.$nextTick();
      this.isModalTeleportEnabled = false;
    },
  },
});
</script>

<template>
  <slot :open-modal="openModal" />
  <Teleport
    v-if="isModalTeleportEnabled"
    to="body"
  >
    <AkModal
      ref="vatModal"
      size="xl"
    >
      <VatForm
        @success="closeVatModal"
        @close="closeVatModal"
      />
    </AkModal>
    <AkModal
      ref="errorModal"
      size="xl"
    >
      <ModalErrorPopin
        :message="$t('modalErrorPopin.message', { country: retailer.country.name })"
        @close="closeErrorModal"
      />
    </AkModal>
  </Teleport>
</template>
