<template>
  <div class="rating-breakdown ds-flex ds-flex-col ds-items-start ds-justify-center">
    <div class="ds-w-full">
      <p
        class="ds-text-base ds-mb-4 ds-font-normal md:ds-text-xl"
        data-testid="totalLabel"
      >
        {{ data.total }} {{ $tc('Review', data.total) }}
      </p>
      <div
        v-for="(label, index) of ratingLabels"
        :key="index"
        class="ds-flex ds-items-center"
        data-testid="rating"
        :class="{
          'ds-mb-4': index !== Object.keys(ratingLabels).length - 1,
        }"
      >
        <p
          class="ds-font-normal ds-text-sm ds-inline-block ds-m-0 ds-mr-1"
          data-testid="ratingLabel"
        >
          {{ label }}
        </p>
        <AkIcon
          symbol="star-fill"
          size="md"
          class="brand__text-gold ds-mr-2"
        />
        <AkProgressBar
          data-testid="ratingPercent"
          :value="getReviewPercentage(label)"
          fill-colour="var(--accent)"
        />
        <p
          data-testid="ratingValue"
          class="ds-w-3 ds-ml-4 ds-my-0 ds-text-sm md:ds-text-base"
        >
          {{ data[label] }}
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, toRefs, computed } from 'vue';

import AkProgressBar from '@/components/review/AkProgressBar/ak-progress-bar.vue';
import { AkIcon } from '@ankorstore/design-system';
import { ReviewsBreakdown } from '@/components/review/types';

export default defineComponent({
  name: 'RatingBreakdown',
  components: {
    AkProgressBar,
    AkIcon,
  },
  props: {
    data: {
      type: Object as PropType<ReviewsBreakdown>,
      required: true,
    },
  },
  setup(props) {
    const { data } = toRefs(props);

    const ratingLabels = computed(() => {
      return Object.keys(data.value)
        .filter((label) => label !== 'total')
        .sort((a, b) => b.localeCompare(a));
    });

    const getReviewPercentage = (label) => (data.value[label] / data.value.total) * 100;

    return {
      ratingLabels,
      getReviewPercentage,
    };
  },
});
</script>

<style>
.brand__text-gold {
  @apply ds-text-ratings;
}
</style>
