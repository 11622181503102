import { isEqual } from 'lodash-es';
import { onMounted, onUnmounted, reactive } from 'vue';

const getHeaderElement = () => document.querySelector('header');
const getFooterElement = () => document.querySelector('#footer');
const getDesktopHeaderElement = () => document.querySelector('#header');
const getMobileHeaderElement = () => document.querySelector('#headerMobile');

export const useViewPort = () => {
  const dimensions = reactive({
    headerHeight: '160px',
    /**
     * headerHeightTotal is the height of the first <header> element in the document.
     */
    headerElementHeight: '160px',
    contentHeight: '100%',
    footerTop: '0px',
    headerRect: <DOMRect>{ height: 0, width: 0, top: 0, left: 0, bottom: 0, right: 0, x: 0, y: 0, toJSON: () => {} },

    /**
     * desktopHeaderHeight is the height of the `#header` element in the document.
     */
    desktopHeaderElementHeight: '160px',
    desktopHeaderRect: <DOMRect>{ height: 0, width: 0, top: 0, left: 0, bottom: 0, right: 0, x: 0, y: 0, toJSON: () => {} },

    /**
     * mobileHeaderHeight is the height of the `#headerMobile` element in the document.
     */
    mobileHeaderElementHeight: '160px',
    mobileHeaderRect: <DOMRect>{ height: 0, width: 0, top: 0, left: 0, bottom: 0, right: 0, x: 0, y: 0, toJSON: () => {} },
  });

  const getFooterTop = () => {
    const footer = getFooterElement();
    if (!footer) {
      return 0;
    }

    const footerTop = footer.getBoundingClientRect().top - window.innerHeight;

    return Math.min(0, footerTop);
  };

  const calculateDimensions = () => {
    const header = getHeaderElement();

    const headerRect = header.getBoundingClientRect();
    const headerHeight = headerRect.height;
    const footerTop = getFooterTop();

    if (!isEqual(dimensions.headerRect?.toJSON(), headerRect?.toJSON())) {
      dimensions.footerTop = `${-footerTop}px`;
      dimensions.headerHeight = `${headerHeight}px`;
      dimensions.headerElementHeight = `${headerRect.height}px`;
      dimensions.contentHeight = `calc(100vh - ${headerHeight}px + ${footerTop}px)`;
      dimensions.headerRect = headerRect;
    }

    const desktopHeader = getDesktopHeaderElement();
    const desktopHeaderRect = desktopHeader?.getBoundingClientRect();
    if (dimensions.desktopHeaderRect.height !== desktopHeaderRect?.height) {
      dimensions.desktopHeaderElementHeight = `${desktopHeaderRect.height}px`;
      dimensions.desktopHeaderRect = desktopHeaderRect;
    }

    const mobileHeader = getMobileHeaderElement();
    const mobileHeaderRect = mobileHeader?.getBoundingClientRect();
    if (dimensions.mobileHeaderRect.height !== mobileHeaderRect?.height) {
      dimensions.mobileHeaderElementHeight = `${mobileHeaderRect.height}px`;
      dimensions.mobileHeaderRect = mobileHeaderRect;
    }
  };

  const resizeObserver = new ResizeObserver(calculateDimensions);

  onMounted(() => {
    calculateDimensions();
    window.addEventListener('scroll', calculateDimensions);
    window.addEventListener('resize', calculateDimensions);
    resizeObserver.observe(getHeaderElement());
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', calculateDimensions);
    window.removeEventListener('resize', calculateDimensions);
    resizeObserver.disconnect();
  });

  return {
    dimensions,
    calculateDimensions,
  };
};
