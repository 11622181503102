import AnalyticsEvent from './analytics-event';
import { ProductListFilter } from '@/services/analytics/events/segment-ecommerce-types';

export class DiscountStatusCTAClickedEvent extends AnalyticsEvent {
  public readonly name = 'thank_you_page_interested_in_repeat';
  public properties: ProductListFilter;

  constructor(props?: ProductListFilter) {
    super();
    this.properties = props;
  }
}
