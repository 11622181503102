export enum trackActionName {
  CLICK_PILL = 'click_pill',
  CLICk_HEADER_SUBSCRIBER = 'click_header_subscriber',
  CLICK_LINK = 'click_link',
  CLICK_LINK_SUBSCRIBER = 'click_link_subscriber',
}

export enum trackSectionName {
  SHOW_SUBSCRIPTION_PROGRAM_PILL = 'show_subscription_program_pill',
  SHOW_SUBSCRIPTION_PROGRAM_PILL_SUBSCRIBER = 'show_subscription_program_pill_subscriber',
}
