export enum SaveBarMode {
  Disabled = 'disabled',
  OrderEdit = 'order-edit',
  ProductsOrganization = 'products-organization',
  ProductEdit = 'product-edit',
  CreateCampaign = 'campaign-add',
  ProductDraftEdit = 'product-draft-edit',
  LinkedProductDraftEdit = 'linked-product-draft-edit',
  FulfillmentOrderCreate = 'external-order-create',
  StorePageEdit = 'storepage-edit',
}
