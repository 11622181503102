import http from '@/services/api/http';
import {
  isSavedProductCollection,
  NewProductCollection,
  PRODUCT_COLLECTION_TYPE,
  ProductCollection,
  ProductCollectionComputedProperties,
  ProductCollectionId,
  ProductCollectionItem,
  ProductCollectionItemList,
  ProductCollectionsList,
  ProductCollectionStatus,
  SavedOrNewProductCollection,
} from '@/types/account/product-collections';
import Product from '@/types/product.js';

// #region types
type ProductCollectionItemIdList = ProductCollectionItem['product_id'][];

type CreateProductCollectionPayload = Omit<NewProductCollection, ProductCollectionComputedProperties> & {
  item_ids: ProductCollectionItemIdList;
};

type UpdateProductCollectionPayload = Omit<ProductCollection, ProductCollectionComputedProperties> & {
  item_ids: ProductCollectionItemIdList;
};

export type GetProductCollectionResponse = {
  data: ProductCollection;
};

export type GetProductCollectionsListResponse = {
  data: ProductCollectionsList;
};

export type EditProductCollectionErrors = {
  [field in keyof CreateProductCollectionPayload]?: string[];
};

export type ProductWithUuid = Product & { uuid?: string };

export type SearchProductCollectionItemsResponse = {
  data: ProductWithUuid[];
};
// #endregion types

// #region constants
// Retrieving a product collection uses the public api as there are no sensitive
// data necessary during edit either.
const PUBLIC_API_PRODUCT_COLLECTIONS_URL = '/api/product-collections';

const BRAND_API_PRODUCT_COLLECTIONS_URL = '/api/me/brand/product-collections';

const BRAND_API_PRODUCT_URL = '/api/me/brand/products';

export const COVER_IMAGE_MAX_FILE_SIZE_IN_MEGABYTES = 1;

export const COVER_IMAGE_MAX_FILE_SIZE = COVER_IMAGE_MAX_FILE_SIZE_IN_MEGABYTES * 1024 * 1024;

// A reasonable limit set by the Product Manager:
// The value was set according how many collections can be displayed on the
// brands catalogue page without pagination.
export const PRODUCT_COLLECTION_MAXIMUM_COUNT = 36;
// #endregion constants

// #region builder methods
const buildPublicApiProductCollectionUrl = (productCollectionId: ProductCollectionId) =>
  `${PUBLIC_API_PRODUCT_COLLECTIONS_URL}/${productCollectionId}`;

const buildPublicApiProductCollectionFromBrandUrl = (brandId: string | number) =>
  `${PUBLIC_API_PRODUCT_COLLECTIONS_URL}?filters[brand_id]=${brandId}`;

const buildBrandApiProductCollectionUrl = (productCollectionId: ProductCollectionId) =>
  `${BRAND_API_PRODUCT_COLLECTIONS_URL}/${productCollectionId}`;

const buildProductCollectionItemIds = (items: ProductCollectionItemList): ProductCollectionItemIdList =>
  items.map(({ product_id }) => product_id);

const buildEditProductCollectionRequestPayload = (
  productCollection: SavedOrNewProductCollection
): UpdateProductCollectionPayload | CreateProductCollectionPayload => {
  const { cover_image, items, original_description, status, title, type } = productCollection;

  let requestPayload: CreateProductCollectionPayload = {
    cover_image,
    original_description,
    status,
    title,
    type,
    item_ids: buildProductCollectionItemIds(items),
  };

  if (isSavedProductCollection(productCollection)) {
    const { id, uuid } = productCollection;
    requestPayload = {
      ...requestPayload,
      id,
      uuid,
    } as UpdateProductCollectionPayload;
  }

  return requestPayload;
};

export const buildNewProductCollection = (): NewProductCollection => ({
  cover_image: '',
  description: '',
  item_count: 0,
  items: [],
  original_description: '',
  status: ProductCollectionStatus.Active,
  title: '',
  type: PRODUCT_COLLECTION_TYPE,
});
// #endregion builder methods

// #region service methods
export const createOrUpdateProductCollection = async (
  productCollection: SavedOrNewProductCollection
): Promise<EditProductCollectionErrors> => {
  const requestPayload = buildEditProductCollectionRequestPayload(productCollection);

  try {
    if (isSavedProductCollection(productCollection)) {
      const url = buildBrandApiProductCollectionUrl(productCollection.id);
      await http().put(url, requestPayload);
    } else {
      await http().post(BRAND_API_PRODUCT_COLLECTIONS_URL, requestPayload);
    }

    return {};
  } catch (e) {
    if (e.response?.data?.errors) {
      const errors: EditProductCollectionErrors = e.response.data.errors;
      return errors;
    }

    throw e;
  }
};

export const getProductCollection = async (productCollectionId: ProductCollectionId): Promise<ProductCollection> => {
  const url = buildPublicApiProductCollectionUrl(productCollectionId);
  const response = await http().get<GetProductCollectionResponse>(url);
  return response.data.data;
};

export const deleteProductCollection = async (productCollectionId: ProductCollectionId): Promise<void> => {
  const url = buildBrandApiProductCollectionUrl(productCollectionId);
  await http().delete(url);
};

export const getProductCollectionsList = async (): Promise<ProductCollectionsList> => {
  const url = BRAND_API_PRODUCT_COLLECTIONS_URL;
  const response = await http().get<GetProductCollectionsListResponse>(url);
  return response.data.data;
};

export const getProductCollectionsListFromBrand = async (brandId: string | number): Promise<ProductCollectionsList> => {
  const url = buildPublicApiProductCollectionFromBrandUrl(brandId);
  const response = await http().get<GetProductCollectionsListResponse>(url);
  return response.data.data;
};

export const searchProductCollectionItems = async (query: string): Promise<ProductCollectionItemList> => {
  const url = BRAND_API_PRODUCT_URL;
  const config = {
    params: {
      query,
    },
  };
  const response = await http().get<SearchProductCollectionItemsResponse>(url, config);

  const itemList = response.data.data.map((product) => {
    const { images, name, options, retail_price, variants } = product;
    const product_id = product.id;
    const product_uuid = product.uuid;
    return {
      images,
      name,
      options,
      product_id,
      product_uuid,
      retail_price,
      variants,
    };
  });

  return itemList;
};
// #endregion service methods
