<template>
  <div
    class="ds-grid ds-grid-cols-2 lg:ds-grid-cols-3 xl:ds-grid-cols-4 ds-gap-4 md:ds-gap-5"
    data-testid="catalog-grid"
  >
    <template v-if="hasError">
      <slot name="error" />
    </template>
    <template v-else-if="isSearching">
      <template
        v-for="i in 12"
        :key="i"
      >
        <slot name="skeleton" />
      </template>
    </template>
    <template v-else-if="Boolean(tiles.length)">
      <template
        v-for="(tile, index) in tiles"
        :key="index"
      >
        <template v-if="getInjectedSlots(index).length">
          <div
            v-for="slot in getInjectedSlots(index)"
            :key="slot.name"
            class="ds-col-span-2 lg:ds-col-span-3 xl:ds-col-span-4"
            data-testid="catalog-grid-injection"
            :data-testindex="index"
          >
            <slot :name="slot.name" />
          </div>
        </template>
        <div
          class="ds-h-full"
          data-testid="catalog-grid-tile"
        >
          <slot
            :tile="tile"
            :index="index"
          />
        </div>
      </template>
      <slot name="footer">
        <template
          v-for="(item, index) in slots.footer"
          :key="index + item.name"
        >
          <div
            v-if="item"
            class="ds-col-span-2 lg:ds-col-span-3 xl:ds-col-span-4"
          >
            <slot :name="item.name" />
          </div>
        </template>
      </slot>
    </template>
    <template v-else>
      <div class="ds-col-span-4">
        <slot name="noResults" />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
export default {
  name: 'CatalogGrid',
};
</script>

<script setup lang="ts">
import { ref, PropType, watchEffect } from 'vue';
import { AnyTile } from '@bc/discovery/domain/catalog';
import { partition } from 'lodash-es';
import { GridSlot, GridConfig } from './types';

const props = defineProps({
  tiles: {
    type: Array as PropType<AnyTile[]>,
    required: true,
  },
  tilesPerRow: {
    type: Number,
    required: true,
  },
  hasError: {
    type: Boolean,
    default: false,
  },
  isSearching: {
    type: Boolean,
    required: true,
  },
  config: {
    type: Object as PropType<GridConfig>,
    default: undefined,
    validator: (value: GridConfig) => {
      return typeof value === 'undefined' || Object.values(value).every((v) => v.row > 0);
    },
  },
});

const slots = ref<{ injection: GridSlot[]; footer: GridSlot[] }>({
  injection: [],
  footer: [],
});

watchEffect(() => {
  if (!props.config) {
    slots.value = {
      injection: [],
      footer: [],
    };
    return;
  }
  const allSlots = Object.entries(props.config).map(([key, value], index) => {
    return {
      name: key,
      index: props.tilesPerRow * (value.row - 1) - props.tilesPerRow * index,
    };
  });
  const [injection, footer] = partition(allSlots, (slot) => slot.index < props.tiles.length);
  slots.value = { injection, footer };
});

const getInjectedSlots = (index: number) => slots.value.injection.filter((slot) => slot.index === index);
</script>
