<template>
  <div class="public-price-form">
    <template v-if="!isSentSuccessfully">
      <AkAccountHeading4 class="public-price-form__heading">
        {{ $t('publicPrice.form.title') }}
      </AkAccountHeading4>
      <AkSelect
        v-model="source"
        class="public-price-form__form-field"
        :label="$t('publicPrice.form.option.label')"
        :placeholder="$t('global.form.select.placeholder')"
        :options="[
          { label: $t('publicPrice.form.option.website'), value: 'brand_website', disabled: false },
          { label: $t('publicPrice.form.option.catalog'), value: 'brand_catalog', disabled: false },
          { label: $t('publicPrice.form.option.orderchamp'), value: 'orderchamp', disabled: false },
          { label: $t('publicPrice.form.option.faire'), value: 'faire', disabled: false },
          { label: $t('publicPrice.form.option.other'), value: 'other', disabled: false },
        ]"
      />
      <AkTextarea
        v-model="description"
        class="public-price-form__form-field"
        :rows="4"
        :label="$t('publicPrice.form.comment.label')"
        :placeholder="$t('publicPrice.form.comment.placeholder')"
        :maxlength="800"
      />
      <AkInput
        v-model="link"
        class="public-price-form__form-field"
        :placeholder="$t('publicPrice.form.link.placeholder')"
        errors=""
        :label-extra="$t('global.form.optional')"
        :label="$t('publicPrice.form.link.label')"
        type="url"
      />
      <div class="public-price-form__attachement">
        <AkAccountHeading4>{{ $t('publicPrice.form.attachement.title') }}</AkAccountHeading4>
        <AkFileUploader
          ref="fileUploaderField"
          :unaccepted-type-message="$t('global.form.file.unvalid')"
          :primary-label="$t('global.form.file.label')"
          :secondary-label="$t('global.form.file.browse')"
          :types-label="$t('publicPrice.form.file.format')"
          :size-label="$t('global.form.file.size.6')"
          :max-file-quantity-message="$t('global.form.file.maxLimit')"
          :accepted-types="[
            'image/png',
            'image/jpeg',
            'application/pdf',
            'application/msword',
            'application/vnd.ms-excel',
            'application/rtf',
          ]"
          :above-max-file-size-message="$t('global.form.file.maxSize')"
          :multiple="true"
          @change="uploadFiles"
        />
      </div>
      <AkButton
        class="public-price-form__submit"
        size="lg"
        :disabled="!formValid"
        @click="submit()"
      >
        {{ $t('publicPrice.form.ctaSubmit') }}
      </AkButton>
    </template>
    <template v-else>
      <div class="public-price-form__result">
        <AkAccountHeading2>{{ $t('publicPrice.succes.title') }}</AkAccountHeading2>
        <AkParagraph>{{ $t('publicPrice.succes.description') }}</AkParagraph>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import {
  AkAccountHeading2,
  AkAccountHeading4,
  AkButton,
  AkParagraph,
  AkTextarea,
  AkSelect,
  AkInput,
  AkFileUploader,
} from '@ankorstore/design-system';
import { BpgIssueType, BpgReportIssueRequest } from '@/modules/shared/best-price-guarantee/types/types';
import { submitBpgRequest } from '@/modules/shared/best-price-guarantee/services/bpg-report-issue';
import { captureException } from '@/sentry';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from 'vuelidate/lib/validators';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PublicPriceForm',
  components: {
    AkSelect,
    AkParagraph,
    AkButton,
    AkAccountHeading2,
    AkAccountHeading4,
    AkTextarea,
    AkInput,
    AkFileUploader,
  },
  props: {
    productVariantId: {
      type: Number,
      required: true,
    },
  },
  emits: ['submitInitialForm'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      source: null,
      description: null,
      link: null,
      attachments: [],
      errors: [],
      isSentSuccessfully: false,
    };
  },
  computed: {
    formValid(): boolean {
      return this.errors.length == 0 && !this.v$.$invalid;
    },
  },
  methods: {
    uploadFiles(attachments): void {
      this.attachments = [];
      if (attachments?.length) {
        attachments = Array.from(attachments);
        for (const file of attachments) {
          this.attachments.push(file);
        }
      }
    },
    async submit(): Promise<void> {
      const payload: BpgReportIssueRequest = {
        fields: { description: this.description, link: this.link, source: this.source },
        attachments: this.attachments,
        type: BpgIssueType.public,
        productVariantId: this.productVariantId,
      };
      try {
        await submitBpgRequest(payload);
        this.isSentSuccessfully = true;
        this.$emit('submitInitialForm');
      } catch (e) {
        captureException(e);
      }
    },
  },
  validations: {
    source: { required },
    description: { required, maxLength: maxLength(800) },
    attachments: { required },
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';
.public-price-form {
  @apply ds-text-left;
  &__heading,
  &__form-field {
    @apply ds-mb-3;
  }
  &__attachement {
    @apply ds-mt-7;
  }
  &__submit {
    @apply ds-mx-auto ds-block ds-mt-4;
  }
  &__result {
    @apply ds-text-center;
  }
}
</style>
