<template>
  <div class="describe-isssue">
    <AkParagraph class="describe-isssue__title">
      {{ $t('Describe the issue') }}
    </AkParagraph>
    <AkTextarea
      no-resize
      :placeholder="$t('Write your description here')"
      :maxlength="getConfig.maxDescriptionLength"
      :has-error="errorMsg !== null"
      :value="getDescription"
      :errors="errorMsg"
      @input="descriptionChanged"
      @keydown.enter.shift.exact="newline"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default defineComponent({
  name: 'DescribeIssue',
  data() {
    return {
      errorMsg: null,
    };
  },
  computed: {
    ...mapGetters('orderRetailerRejection', ['getConfig', 'getDescription']),
  },
  methods: {
    ...mapActions('orderRetailerRejection', ['changeDescription']),
    descriptionChanged(value: string): void {
      this.errorMsg = value === '' ? this.$t('Please, fill the field') : null;
      this.changeDescription({ value });
    },
    newline(): void {
      let value = this.getDescription;
      value = `${value}\n`;
      this.changeDescription({ value });
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.describe-isssue {
  @apply ds-font-basic ds-text-neutral-900 ds-mb-5;

  &__title {
    @apply ds-mb-1.5;
  }
}
</style>
