import { memoize } from 'lodash-es';
import { getRegions } from '../api/api';
import { Location } from '../../types/filters';

const createCountryId = (countryId: number) => `c${countryId}`;
const createRegionId = (regionId: number) => `r${regionId}`;

export const isCountry = (item: { id: string }) => item?.id.startsWith('c');
export const isRegion = (item: { id: string }) => item?.id.startsWith('r');

export const getLocations = memoize(async (): Promise<Location[]> => {
  const { countries, regions } = await getRegions();

  const countryLocations: Location[] = Object.values(countries).map((country) => ({
    id: createCountryId(country.id),
    name: country.name,
    iso_code: country.iso_code,
    children: country.region_ids?.map(createRegionId) ?? [],
  }));

  const regionLocations: Location[] = Object.values(regions).map((region) => ({
    id: createRegionId(region.id),
    name: region.name,
    children: [],
    parent: createCountryId(region.country_id),
  }));

  return countryLocations.concat(...regionLocations);
});
