import Dinero, { Currency, DineroObject } from 'dinero.js';
import { priceFormatter } from '@/utilities/formatters';
import { isNumber } from 'lodash-es';
import { captureException } from '@/sentry';
import { Amount } from '@/types/amount';
import i18n from '@/services/i18n';

/**
 * Checks if the tuple is a number value tuple
 */
const isNumberValueTuple = <T>(tuple: [keyof T, unknown]): tuple is [keyof T, number] => typeof tuple[1] === 'number';

const mapNumberProperties = <T>(value: T, properties: (keyof T)[], mapper: (num: number) => number): T => ({
  ...value,
  ...Object.fromEntries(
    properties
      .map((key): [keyof T, unknown] => [key, value[key]])
      .filter(isNumberValueTuple)
      .map(([key, amount]) => [key, mapper(amount)])
  ),
});

export const getCurrencySymbol = (price: Dinero.DineroObject) => {
  const currencySymbols = {
    USD: '$', // US Dollar
    EUR: '€', // Euro
    CRC: '₡', // Costa Rican Colón
    GBP: '£', // British Pound Sterling
    ILS: '₪', // Israeli New Sheqel
    INR: '₹', // Indian Rupee
    JPY: '¥', // Japanese Yen
    KRW: '₩', // South Korean Won
    NGN: '₦', // Nigerian Naira
    PHP: '₱', // Philippine Peso
    PLN: 'zł', // Polish Zloty
    PYG: '₲', // Paraguayan Guarani
    THB: '฿', // Thai Baht
    UAH: '₴', // Ukrainian Hryvnia
    VND: '₫', // Vietnamese Dong
    SEK: 'SEK', // Swedish krona
  };

  return currencySymbols[Dinero(price).getCurrency()] ?? currencySymbols.EUR;
};

export const formatPrice = (price: Amount, format = '$0.00', locale: string = i18n.global.locale): string => {
  const priceOptions: Parameters<typeof priceFormatter>[0] = {
    precision: 2,
    ...price,
  };

  if (!Number.isInteger(priceOptions.amount)) {
    delete priceOptions.amount;
  }

  return priceFormatter(priceOptions, format, locale);
};

export const absolutePrice = (price: Amount): Amount => ({
  ...price,
  amount: Math.abs(price.amount),
});

export const subtractPrices = (prices: Amount[]): Dinero.DineroObject => {
  let subtractPrice: Dinero.Dinero = Dinero(prices[0]);

  prices.forEach((price: Amount, index) => {
    if (index === 0) {
      return;
    }
    subtractPrice = subtractPrice.subtract(Dinero(price));
  });

  return subtractPrice.toObject();
};

export const sumPrices = (prices: Amount[], currency: Dinero.Currency, precision = 2): Dinero.DineroObject => {
  let sumPrice: Dinero.Dinero = Dinero({
    amount: 0,
    currency,
    precision,
  });
  if (!prices.every((price) => price.currency === currency)) {
    const err = new Error('Not all prices are in the same currency', {
      cause: {
        prices: prices,
        currency: currency,
      },
    });
    captureException(err);
    throw err;
  }

  prices.forEach(({ amount }) => {
    sumPrice = sumPrice.add(
      Dinero({
        amount,
        currency,
        precision,
      })
    );
  });
  return sumPrice.toObject();
};

export const comparePrices = (ValueA: Dinero.DineroObject, ValueB: Dinero.DineroObject): boolean => {
  const valueA = Dinero(ValueA);
  const valueB = Dinero(ValueB);

  return valueA.hasSameCurrency(valueB) && valueA.hasSameAmount(valueB);
};

export const toMinorUnitProperties = <T extends object>(value: T, properties: Array<keyof T>, currency: Currency): T => {
  const multiplier = 10 ** Dinero({ currency }).getPrecision();

  return mapNumberProperties(value, properties, (amount) =>
    Math.round((isNumber(amount) && !isNaN(amount) ? amount : 0) * multiplier)
  );
};

export const amountToUnit = (amount: number, currency: Currency) => {
  if (typeof amount !== 'number') {
    return amount;
  }

  return Dinero({ amount, currency }).toUnit();
};

export const amountPropertiesToUnit = <T extends object>(value: T, properties: Array<keyof T>, currency: Currency) => {
  return mapNumberProperties(value, properties, (amount) => Dinero({ amount, currency }).toUnit());
};

export const toUnit = (price: Dinero.DineroObject) => {
  return Dinero(price).toUnit();
};

export const getConvertedPrice = async (
  price: Amount,
  targetCurrency: Currency,
  currencyRates: { date: Date; rates: Record<string, number> }
): Promise<DineroObject> => {
  const dinero =
    targetCurrency === price.currency
      ? Dinero(price)
      : await Dinero(price).convert(targetCurrency, {
          endpoint: Promise.resolve(currencyRates),
        });
  return dinero.toObject();
};

/**
 * Returns the minimum amount from an array of amounts
 * @param prices
 * @returns {Amount}
 */
export const getMinAmount = (prices: Amount[]): Amount => {
  const pricesInDinero = prices.map((price) => Dinero(price));

  return { amount: Dinero.minimum(pricesInDinero).getAmount(), currency: prices[0].currency };
};

/**
 * Returns the maximum amount from an array of amounts
 * @param prices
 * @returns {Amount}
 */
export const getMaxAmount = (prices: Amount[]): Amount => {
  const pricesInDinero = prices.map((price) => Dinero(price));

  return { amount: Dinero.maximum(pricesInDinero).getAmount(), currency: prices[0].currency };
};
