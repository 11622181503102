import i18n from '@/services/i18n';
import { VariantType } from '@/types/product';

export const getVariantTypeLabel = (type: string, length: number): string => {
  switch (type) {
    case VariantType.COLOR:
      return i18n.global.tc('productCard.availableColors.lbl', length);
    case VariantType.SIZE:
      return i18n.global.tc('productCard.availableSizes.lbl', length);
    case VariantType.OTHER:
    default:
      return i18n.global.tc('productCard.availableOptions.lbl', length);
  }
};
