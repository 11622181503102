import http from '@/services/api/http';
import { AkNavMenuData } from '@ankorstore/design-system';
import { memoize } from 'lodash-es';

const jsonApiConfigs = {
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
};

export const getMainNavigationMenu = memoize(async (): Promise<AkNavMenuData[]> => {
  const apiRoute = `/api/internal/v1/storefront/nav/main/items`;
  const response = await http().get(apiRoute, jsonApiConfigs);
  return response.data.data;
});
