<template>
  <div class="error-page">
    <Breadcrumb
      :links="[{ to: 'home', title: $t('Ankorstore') }]"
      :last="$t('Basket')"
    />
    <AkHeading3 class="title">
      {{ $t('Baskets above minimum order') }}
    </AkHeading3>
    <AkParagraph
      class="subtitle"
      size="sm"
    >
      {{ $t('You can order from the following brands') }}
    </AkParagraph>
    <div class="error-container">
      <AkHeading4 class="error-container__title">
        {{ $t('Basket not found') }}
      </AkHeading4>
      <AkParagraph class="error-container__paragraph">
        {{
          $t('Sorry for the inconvenience. Due to a server error we are currently unable to load your basket.')
        }}
      </AkParagraph>
      <AkParagraph class="error-container__paragraph">
        {{ $t('Please try again later.') }}
      </AkParagraph>
    </div>
  </div>
</template>
<script lang="ts">
//@ts-nocheck
import Breadcrumb from '@/components/breadcrumb.vue';
import { AkHeading3, AkParagraph, AkHeading4 } from '@ankorstore/design-system';

import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CartNotFound',
  components: {
    Breadcrumb,
    AkParagraph,
    AkHeading3,
    AkHeading4,
  },
});
</script>
<style lang="scss" scoped>
@import '@css/vue-import';
.error-page {
  @apply ds-h-screen ds-max-w-screen-2xl ds-mx-auto ds-px-6 ds-w-full;
}

.title {
  @apply ds-mb-2;
}

.subtitle {
  @apply ds-text-neutral-700 ds-mb-6;
}

.error-container {
  @apply ds-bg-white ds-border ds-border-solid ds-p-6 ds-border-neutral-300 ds-rounded-md ds-text-center ds-text-neutral-700 ds-bg-auto ds-bg-no-repeat;
  background-image: url('/images/error-page/empty-cart.png');
  background-position: bottom 36px center;
  min-height: 442px;
  &__title {
    @apply ds-text-lg ds-mb-4;
  }
  &__paragraph {
    @apply ds-mb-2;
  }
}
</style>
