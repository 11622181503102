export interface SignUpProperty extends SignUpProps {
  type: string;
  errors?: { [key: string]: any };
  version?: 1 | 2;
}
export interface SignUpProps {
  is_opening_soon?: boolean;
  is_personalized?: boolean;
  is_reminded?: boolean;
  is_prefilled?: boolean;
  source?: string;
}

export function createSignUpProperty({
  type,
  errors,
  is_opening_soon,
  is_personalized,
  is_reminded,
  is_prefilled,
}: SignUpProperty): SignUpProperty {
  return {
    type,
    errors,
    is_opening_soon,
    is_personalized,
    is_reminded,
    is_prefilled,
  };
}
