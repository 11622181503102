import { type ContactManagementState, type ContactSort } from '../types/store.types';
import { type ContactFilter, ContactFilterType } from '@bc/brands/domain/contact-management/types/api.types';

export default {
  SET_CONTACTS(state: ContactManagementState, data: ContactManagementState['contacts']['data']): void {
    state.contacts.data = data;
  },
  SET_CONTACTS_FACETS(state: ContactManagementState, data: ContactManagementState['contacts']['facets']): void {
    state.contacts.facets = data;
  },
  SET_DYNAMIC_FACETS(state: ContactManagementState, data: ContactManagementState['contacts']['facets']): void {
    state.contacts.dynamicFacets = data;
  },
  SET_CONTACTS_PAGE(state: ContactManagementState, data: ContactManagementState['contacts']['page']): void {
    state.contacts.page = data;
  },
  SET_CONTACTS_CURRENT_PAGE(state: ContactManagementState, page: number): void {
    state.searchParameters.page.current = page;
  },
  SET_CONTACTS_SEARCH_TERM(state: ContactManagementState, term: string): void {
    state.searchParameters.searchQuery = term;
  },
  SET_CONTACTS_SEGMENT_FILTER(state: ContactManagementState, segment: string): void {
    if (!segment) {
      delete state.searchParameters.filters.segment;
      return;
    }
    state.searchParameters.filters.segment = {
      type: ContactFilterType.Value,
      name: 'segment',
      value: [
        segment,
      ],
    };
  },
  SET_CONTACTS_FILTERS(state: ContactManagementState, filters: Record<string, ContactFilter>): void {
    state.searchParameters.filters = {...filters};
  },
  SET_CONTACT_FILTER(state: ContactManagementState, payload: [string, ContactFilter]): void {
    const [filterName, filter] = payload;
    if (!filter) {
      delete state.searchParameters.filters[filterName];
      return;
    }
    state.searchParameters.filters[filterName] = {...filter};
  },
  SET_SORTING(state: ContactManagementState, { col, order }: ContactSort): void {
    state.searchParameters.sort = {
      [col]: order,
    };
  },
  SET_FILTERS(state: ContactManagementState, data: ContactManagementState['filters']): void {
    state.filters = data;
  },
};
