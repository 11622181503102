import { SearchCategory } from '@/types/api/category';
import { partial } from 'lodash-es';
import { RouteLocationNormalized } from 'vue-router';
import type { URLParameterValues } from './types';
import type { Location, RangeValue, Filter } from '../../types/filters';
import { CatalogBrowserSortings, CatalogBrowserView } from '../../types/catalog-browser';
import { createCategoryRoute } from '../../utils/create-category-route';

export enum URLParameterKey {
  Page = 'p',
  MadeIn = 'made-in',
  Values = 'tags',
  Margin = 'margin',
  Shipping = 'shipping',
  Location = 'location',
  Brands = 'brands',
  Sorting = 'sort',
  View = 'view',
  retailPriceRangeMin = 'retailer-price-range-min',
  retailPriceRangeMax = 'retailer-price-range-max',
  textQuery = 'q',
  categories = 'categories',
  Discount = 'discount',
  loyaltyOffer = 'aks_plus',
  sortBy = 'sortBy',
}

export const createFilterParam = (name: string, filters: Filter[]) =>
  filters?.length ? `${name}=${encodeURIComponent(filters.map((filter) => filter.id).join(','))}` : '';

const getParamIds = (params: URLSearchParams, name: URLParameterKey) =>
  params.has(name) ? decodeURIComponent(params.get(name)).split(',') : [];

const getHashParams = (route: RouteLocationNormalized) => new URLSearchParams(route.hash?.substr(1) ?? '');

const createViewParameter = (parameterValues: URLParameterValues) => {
  if (parameterValues.selectedView === CatalogBrowserView.Brands) {
    return `${URLParameterKey.View}=${parameterValues.selectedView}`;
  }
  return null;
};

const createSortingParameter = (parameterValues: URLParameterValues) => {
  if (parameterValues.selectedSorting && parameterValues.selectedSorting === CatalogBrowserSortings.new) {
    return `${URLParameterKey.Sorting}=${parameterValues.selectedSorting}`;
  }
  return null;
};

export const createSortBy = (parameterValues: URLParameterValues) => {
  if (parameterValues.sortBy) {
    return `${URLParameterKey.sortBy}=${parameterValues.sortBy}`;
  }
  return null;
};

const getFilters = (hashParamName: URLParameterKey, filters: Filter[], route: RouteLocationNormalized) =>
  getParamIds(getHashParams(route), hashParamName).map((id) => filters.find((filter) => filter.id === id) ?? { id, label: id });

export const createAttributeParams = (attributes: Filter[] = []) => {
  return attributes.map((attribute) => `${attribute.id}=${attribute.label}`).join('&');
};

export function createURL(parameterValues: URLParameterValues, basePath: string): string {
  if (!parameterValues) {
    return '';
  }

  let url = basePath ?? '';

  if (parameterValues.selectedCategories?.length) {
    url = createCategoryRoute(basePath, parameterValues.selectedCategories);
  }

  if (parameterValues.currentPageIndex > 0) {
    url += `?${URLParameterKey.Page}=${parameterValues.currentPageIndex + 1}`;
  }

  const hashParams = [
    createFilterParam(URLParameterKey.MadeIn, parameterValues.selectedMadeIns),
    createFilterParam(URLParameterKey.Values, parameterValues.selectedValues),
    createFilterParam(URLParameterKey.Margin, parameterValues.selectedMargins),
    createFilterParam(URLParameterKey.Shipping, parameterValues.selectedShipping),
    createFilterParam(URLParameterKey.loyaltyOffer, parameterValues.selectedLoyaltyOffer),
    createFilterParam(
      URLParameterKey.Location,
      parameterValues.selectedLocations?.map((location) => ({
        id: location.id,
        label: location.id,
      }))
    ),
    createFilterParam(
      URLParameterKey.Brands,
      parameterValues.selectedFilterBrands?.map((brand) => ({
        id: brand.id,
        label: brand.id,
      }))
    ),
    createFilterParam(
      URLParameterKey.retailPriceRangeMin,
      typeof parameterValues.retailPriceRange?.min === 'number'
        ? [
            {
              id: parameterValues.retailPriceRange.min?.toString(),
              label: parameterValues.retailPriceRange.min?.toString(),
            },
          ]
        : []
    ),
    createFilterParam(
      URLParameterKey.retailPriceRangeMax,
      typeof parameterValues.retailPriceRange?.max === 'number'
        ? [
            {
              id: parameterValues.retailPriceRange.max?.toString(),
              label: parameterValues.retailPriceRange.max?.toString(),
            },
          ]
        : []
    ),
    createViewParameter(parameterValues),
    createSortingParameter(parameterValues),
    createFilterParam(URLParameterKey.Discount, parameterValues.selectedDiscounts),
    createSortBy(parameterValues),
    createAttributeParams(parameterValues.attributes),
  ]
    .filter(Boolean)
    .join('&');

  if (hashParams.length > 0) {
    url += `#${hashParams}`;
  }

  return url;
}

export function getCategoriesIds(selectedCategories: SearchCategory[]) {
  return selectedCategories.map((category) => category.id);
}

export const getCategoriesFromRoute = (
  categories: Record<string, SearchCategory>,
  route: RouteLocationNormalized
): SearchCategory[] => {
  const category = route.params?.category ?? route.params?.categories?.[0] ?? '';
  const subCategory = route.params?.subCategory ?? route.params?.categories?.[1] ?? '';
  const subSubCategory = route.params?.subSubCategory ?? route.params?.categories?.[2] ?? '';

  const getCategoryIdRegex = /^.+-(?<id>\d+)$/;
  const categoryId = parseInt(category.match(getCategoryIdRegex)?.groups?.id);
  const subCategoryId = parseInt(subCategory.match(getCategoryIdRegex)?.groups?.id);
  const subSubCategoryId = parseInt(subSubCategory.match(getCategoryIdRegex)?.groups?.id);

  return [categoryId, subCategoryId, subSubCategoryId].filter((id) => !isNaN(id)).map((id) => categories[id]);
};

export const getPageFromRoute = (route: RouteLocationNormalized) =>
  parseInt((route.query[URLParameterKey.Page] as string) ?? '1', 10) - 1;

export const getViewFromRoute = (route: RouteLocationNormalized): CatalogBrowserView => {
  const [view] = getParamIds(getHashParams(route), URLParameterKey.View);
  return view ? parseInt(view, 10) : undefined;
};

export const getSortingFromRoute = (route: RouteLocationNormalized): CatalogBrowserSortings => {
  const sort = getParamIds(getHashParams(route), URLParameterKey.Sorting);
  return sort[0] as CatalogBrowserSortings;
};

export const getSortBy = (route: RouteLocationNormalized) => {
  const sortBy = getParamIds(getHashParams(route), URLParameterKey.sortBy);
  return sortBy;
};

export const getLocationsFromRoute = (locations: Location[], route: RouteLocationNormalized) =>
  getParamIds(getHashParams(route), URLParameterKey.Location).map((id) => locations.find((location) => location.id === id));

export const getBrandsFromRoute = partial(getFilters, URLParameterKey.Brands);

export const getShippingFromRoute = partial(getFilters, URLParameterKey.Shipping);

export const getMarginsFromRoute = partial(getFilters, URLParameterKey.Margin);

export const getValuesFromRoute = partial(getFilters, URLParameterKey.Values);

export const getMadeInsFromRoute = partial(getFilters, URLParameterKey.MadeIn);

export const getDiscountsFromRoute = partial(getFilters, URLParameterKey.Discount);

export const getLoyaltyOfferFromRoute = partial(getFilters, URLParameterKey.loyaltyOffer);

export const getRetailPriceRangeFromRoute = (route: RouteLocationNormalized): RangeValue => {
  const [min] = getParamIds(getHashParams(route), URLParameterKey.retailPriceRangeMin);
  const [max] = getParamIds(getHashParams(route), URLParameterKey.retailPriceRangeMax);

  return {
    min: min ? parseInt(min) : undefined,
    max: max ? parseInt(max) : undefined,
  };
};
