<script lang="ts">
import { sitewideOfferMixin } from '@/mixins/sitewide-offer';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BrandOfferBadges',
  mixins: [sitewideOfferMixin],
  props: {
    discount: {
      type: Number,
      required: true,
    },
  },
});
</script>

<template>
  <div>
    <span
      v-if="discount > 0"
      class="meta-badge ds-text-discount"
    >
      {{ $t('global.label.percentageOff', { discount: discount }) }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
@import '@css/components/_brand_meta_badge';
</style>
