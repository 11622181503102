<template>
  <div class="auth-wrapper">
    <NotificationsPanel v-if="isNotificationsPanelEnabled" />

    <AkTooltipInformation
      v-if="isWishlistEnabled"
      placement="bottom"
      symbol="heart-fill"
      trigger="manual"
      type="success"
      :auto-hide="false"
      :close-button="true"
      :content="
        $t(
          'Whenever you bookmark a product, it will be added to your ”My Favorites” list by default. Now you can also create custom lists️ of products ☝️'
        )
      "
      :show="isTooltipAvailable"
      :title="$t('New in ”My Favourites”')"
    >
      <a
        class="my-list-button"
        href="/my-list"
        :aria-label="$t('My lists')"
      >
        <AkIcon
          symbol="heart"
          size="md"
        />
        <ProductLikesBadge />
      </a>
    </AkTooltipInformation>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import ProductLikesBadge from '@/components/wishlist/product-likes-badge.vue';
import NotificationsPanel from '@/components/notifications/notifications-panel.vue';
import { AkTooltipInformation, AkIcon } from '@ankorstore/design-system';
import { getGlobalConfig } from '@/services/global-config';
import { useStore } from '@/composables/use-store';
import { isEnabled } from '@/services/features';

export default defineComponent({
  name: 'AuthWrapper',
  components: {
    AkIcon,
    AkTooltipInformation,
    NotificationsPanel,
    ProductLikesBadge,
  },
  setup() {
    const store = useStore();

    const userIsRetailer = computed(() => {
      return store.getters.userIsRetailer;
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const isTooltipAvailable = computed(() => {
      return store.getters['wishlist/isTooltipAvailable'];
    });

    const isNotificationsPanelEnabled = computed(() => {
      const {
        braze: { api_key, base_url },
      } = getGlobalConfig();
      return (isEnabled('comms.tgcs_45') || isEnabled('comms.tgcs_117')) && api_key && base_url && user.value;
    });

    const isWishlistEnabled = computed(() => {
      return userIsRetailer.value && isEnabled('wishlist_menu');
    });

    return {
      isNotificationsPanelEnabled,
      isTooltipAvailable,
      isWishlistEnabled,
      userIsRetailer,
    };
  },
});
</script>

<style scoped lang="scss">
.auth-wrapper {
  @apply ds-mr-4 ds-flex ds-gap-4;

  .my-list-button {
    @apply ds-relative;
  }
}
</style>
