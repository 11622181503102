import { BudgetConfig } from '@bc/advertisement/feature/ads-dashboard/types/ads-dashboard.types';

export const COST_PER_CLICK = 3;
export const BUDGET_CONFIG: BudgetConfig[] = [
  { value: 19000, isChecked: false },
  { value: 13500, isChecked: true, isDefault: true },
  { value: 7500, isChecked: false },
];
export const MIN_CAMPAIGN_DURATION_DAYS = 6;
export const CAMPAIGN_LIST_PAGE_SIZE = 10;
