import http from '@/services/api/http';
import { captureException } from '@/sentry';

export const getFeaturedBrands = async () => {
  return http()
    .get('/api/featured/brands')
    .then((response) => response.data.data)
    .catch((error) => {
      captureException(error);
    });
};
