import AnalyticsEvent from '../analytics-event';

export default class RegionBrandsSection extends AnalyticsEvent {
  public readonly name = 'Region Brands Clicked';
  public properties: {
    region: string;
  };

  constructor(region: string) {
    super();
    this.properties = {
      region,
    };
  }
}
