<template>
  <section class="lift-page-hero ds-flex ds-flex-col ds-items-center ds-text-center ds-py-8 ds-px-4">
    <template v-if="isCoreCountry">
      <AkParagraph class="md:ds-text-4xl ds-text-2xl ds-font-semibold ds-mb-0">
        {{ $t('Shop {0} through ankorstore', [brandName]) }}
      </AkParagraph>
      <AkParagraph class="md:ds-text-4xl ds-text-2xl ds-font-bold ds-mb-6 ds-max-w-4xl">
        <i18n-t keypath="Get {amount}">
          <template #amount>
            <span class="ds-text-info">{{ $t('{0} now', [formattedAmount]) }}</span>
          </template>
        </i18n-t>
      </AkParagraph>
      <AkParagraph
        class="ds-max-w-3xl ds-mb-6"
        size="lg"
      >
        <i18n-t
          keypath="Use your code {liftCode} to benefit from the offer on your first order, from {minimumCheckout} excluding VAT."
        >
          <template #liftCode>
            <span class="ds-font-bold">{{ liftCode.code }}</span>
          </template>
          <template #minimumCheckout>
            <span>{{ formattedMinimumCheckout }}</span>
          </template>
        </i18n-t>
      </AkParagraph>
    </template>
    <template v-else>
      <AkParagraph class="md:ds-text-4xl ds-text-2xl ds-font-semibold ds-mb-6">
        <i18n-t
          keypath="Shop {brandName} on Ankorstore and get {amount} off your first order"
          data-testid="shop-on-ankorstore-label"
        >
          <template #brandName>
            <span>{{ brandName }}</span>
          </template>
          <template #amount>
            <span class="ds-text-info">{{ formattedAmount }}</span>
          </template>
        </i18n-t>
      </AkParagraph>
      <i18n-t
        keypath="To redeem this offer, place an order worth {minimumCheckout} or more (excluding VAT) and enter the code {liftCode} at checkout."
        class="ds-max-w-3xl ds-mb-6 ds-text-base"
      >
        <template #minimumCheckout>
          <span>{{ formattedMinimumCheckout }}</span>
        </template>
        <template #liftCode>
          <span class="ds-font-bold">{{ liftCode.code }}</span>
        </template>
      </i18n-t>
    </template>
    <AkButton
      data-testid="hero-register-button"
      class="md:ds-text-base"
      :size="buttonSize"
      @click="$emit('signup-click')"
    >
      {{ $t('Sign up to Shop') }}
    </AkButton>
  </section>
</template>
<script lang="ts">
import { LiftPromocode } from '@/types/order';
import { loginPopinMixin } from '@/mixins/login-popin';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LiftHero',
  mixins: [loginPopinMixin],
  props: {
    liftCode: {
      type: Object as () => LiftPromocode,
      required: true,
    },
    brandId: {
      type: Number,
      required: true,
    },
    brandName: {
      type: String,
      required: true,
    },
    brandCountry: {
      type: String,
      required: true,
    },
  },
  emits: ['signup-click'],
  computed: {
    buttonSize() {
      return window.innerWidth < 767 ? 'md' : 'xl';
    },
    isCoreCountry() {
      return ['FR', 'DE', 'IT', 'ES', 'GB'].includes(this.brandCountry);
    },
    discount() {
      return '10%';
    },
    days() {
      return '30';
    },
    formattedMinimumCheckout() {
      return this.$root.formatPrice(this.liftCode.minimum_checkout, '$0');
    },
    formattedAmount() {
      return this.$root.formatPrice(this.liftCode.amount, '$0');
    },
  },
});
</script>
