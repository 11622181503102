import AnalyticsEvent from '../analytics-event';
import { CheckoutEoriSuccess } from '../segment-ecommerce-types';

export class EoriCheckoutEntrySuccess extends AnalyticsEvent {
  public readonly name = 'EORI Checkout Entry Success';

  constructor(public readonly properties: CheckoutEoriSuccess) {
    super();
  }
}
