export enum ImgCdnImageFormat {
  'WEBP' = 'webp',
  'JPEG' = 'jpeg',
  'PNG' = 'png',
}

export enum ImgCdnImageTransformFn {
  'CROP' = 'crop',
  'FIT' = 'fit',
  'CROPFIT' = 'cropfit',
  'BOUND' = 'bound',
  'BOUNDMIN' = 'boundmin',
  'COVER' = 'cover',
  'FACE' = 'face',
  'FACEHIDE' = 'facehide',
}

type ImgCdnCropAlign =
  | 'north'
  | 'northeast'
  | 'east'
  | 'southeast'
  | 'south'
  | 'southwest'
  | 'west'
  | 'northwest'
  | 'center'
  | 'centre';
type ImgCdnCropAutoStrategy = 'smart' | 'auto' | 'face';
// Gravity focal point:
// X,Y: focal point defined in pixel coordinates (relative to the original image size)
// Xp,Yp: focal point in percentage (0..100) e.g. gravity=80p,50p
// Xc,Yc: focal point in percentage, normalized (0..1) e.g. gravity=0.8,0.5
type GravityFocalPoint = string;

type ImgCdnGravity = ImgCdnCropAlign | ImgCdnCropAutoStrategy | GravityFocalPoint;

// Full documentation:
// https://docs.scaleflex.com/filerobot-documentation/media-optimizers-and-cdn-delivery/image-optimization/operations
export type ImgCdnParameters = {
  // defaults to webp, if the browser does not support it, it will fallback to jpeg
  force_format?: ImgCdnImageFormat;
  // width, height
  h?: string;
  w?: string;
  // device pixel ratio
  dpr?: string;
  // gravity
  gravity?: ImgCdnGravity;
  // aspect ratio
  ar?: string;
  //transform function
  func?: ImgCdnImageTransformFn;
  // flip image
  flip?: 'h' | 'v' | 'hv';
  // rotation
  r?: string;
  // trimming
  trim?: string;
  // background
  bg_color?: string;
  bg_img_fit?: '1';
  bg_blur?: string;
  // prevent enlargement
  org_if_sml?: '1';
};
