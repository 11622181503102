<template>
  <div class="ak-impersonate">
    <div class="ak-impersonate__content">
      <img
        v-if="businessLogo"
        :src="businessLogo"
      />
      {{ $t("You're impersonating") }} <strong>{{ businessName }}</strong>
      <a
        href="#"
        onclick="event.preventDefault();
            document.getElementById('logout-form').submit();"
      >
        <AkButton outlined>
          {{ $t('Stop impersonating') }}
        </AkButton>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';

import { defineComponent } from 'vue';
import { AkButton } from '@ankorstore/design-system';

export default defineComponent({
  name: 'ImpersonateBanner',
  components: { AkButton },
  computed: {
    ...mapGetters(['retailer', 'brand', 'userIsCandidateBrand', 'user']),
    businessLogo() {
      if (this.retailer || this.userIsCandidateBrand) {
        return null;
      }

      return 'https://' + this.$cdn + this.brand.images.rounded + '?auto=format,compress&fm=pjpg&h=40&dpr=2';
    },
    businessName() {
      if (this.retailer) {
        return this.retailer.name;
      } else if (this.brand) {
        return this.brand.name;
      } else {
        // User is candidate brand
        return this.user.business?.brand?.name;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@css/vue-import';

.ak-impersonate {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $header-impersonate-height;
  @apply ds-bg-primary;

  img {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    @apply ds-border ds-border-solid ds-border-neutral-300;
    display: block;
    float: left;
    margin-right: 10px;
  }
}

.ak-impersonate__content {
  @include container-fluid;

  font-size: 14px;
  @apply ds-text-white;
  line-height: 1;
  text-align: center;

  span {
    position: relative;
    top: 1px;
    margin-right: 0.4em;
  }

  .ak-button {
    @apply ds-inline ds-ml-2;
  }
}
</style>
