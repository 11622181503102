import { isEnabled } from '@/services/features';
import { getGlobalEvents } from '@/services/api/business-events';

import {
  getFacets,
  getCatalogConfig as getLocalCatalogConfig,
  mapAttributeToFacet,
  getAttributeFacets,
  AttributeDefinition,
  CatalogFacet,
  Context,
  Config,
  Facet,
  FacetGroup,
  SnippetTemplate,
  facetRef,
} from '@bc/discovery/domain/catalog';

const entityTypeMap: Record<SnippetTemplate, string> = {
  [SnippetTemplate.PRODUCTS]: 'product',
  [SnippetTemplate.BRANDS]: 'brand',
};

export async function getCatalogConfig(context: Context, categoryId: number, lang: string): Promise<Config> {
  const config = getLocalCatalogConfig(context);

  if (!isEnabled('RET-3019')) {
    const [attributeFacets, events] = await Promise.all([
      getAttributeFacets(categoryId, lang, config.template),
      getGlobalEvents(),
    ]);

    const defaultFacets = config.Facets.filter((facet) => facet.name !== facetRef.discount);
    const discountFacet = config.Facets.find((facet) => facet.name === facetRef.discount);
    const defaultQuickFilters = config.quickFilters.filter((filter) => filter.name !== facetRef.discount);
    const discountQuickFilter = config.quickFilters.find((filter) => filter.name === facetRef.discount);
    const isDiscountLive = events.some((event) => event.with_discount_live);

    const facets = [].concat(isDiscountLive && discountFacet ? discountFacet : []).concat(defaultFacets);

    const hasDiscountFacet = facets.some((facet) => facet.name === facetRef.discount);
    const hasAksPlusFacet = facets.some((facet) => facet.name === facetRef.aks_plus || facet.name === facetRef.aks_plus_brand);
    let attributeFacetsStart = 0;
    if (hasDiscountFacet) {
      attributeFacetsStart++;
    }
    if (hasAksPlusFacet) {
      attributeFacetsStart++;
    }
    facets.splice(attributeFacetsStart, 0, ...attributeFacets);

    const quickFilters = [].concat(isDiscountLive && discountQuickFilter ? discountQuickFilter : []).concat(defaultQuickFilters);

    return {
      ...config,
      Facets: facets,
      quickFilters,
    };
  }

  const catalogFacets = await getFacets(context, entityTypeMap[config.template], categoryId, lang);
  const facets = catalogFacets.map(mapToFacet);
  const quickFilters = catalogFacets
    .filter(isCatalogFacet)
    .filter((facet) => facet.catalog_facet.is_quick_filter)
    .map(mapCatalogFacet);

  return {
    ...config,
    Facets: facets,
    quickFilters,
  };
}

const isCatalogFacet = (facet: unknown): facet is CatalogFacet =>
  typeof facet === 'object' && 'type' in facet && facet.type === 'catalog_facet';

const mapCatalogFacet = (facet: CatalogFacet): Facet => ({
  display: true,
  message: facet.catalog_facet.message,
  name: facet.slug,
  placeHolder: facet.catalog_facet.placeholder,
  title: facet.name,
  trackingName: facet.catalog_facet.tracking_name,
  type: facet.catalog_facet.facet_type,
  uid: facet.slug,
  choices: Object.entries(facet.choices ?? {}).map(([slug, name]) => ({
    slug,
    name,
    data: null,
    position: 0,
  })),
  isOpen: facet.catalog_facet.is_open,
  badgeType: facet.catalog_facet.badge_type,
});

const mapToFacet = (facet: CatalogFacet | AttributeDefinition): Facet | FacetGroup => {
  if (isCatalogFacet(facet)) {
    return mapCatalogFacet(facet);
  }

  return mapAttributeToFacet(facet);
};
