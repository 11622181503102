import { createApp as createVueApp } from 'vue';
import { Store } from 'vuex';
import { initSentry, captureException } from '@/sentry';
import { setSentryMetadata } from '@/sentry/helper';

import i18n from '@/services/i18n';
import { configureVue } from '@/services/features';
import * as router from '@/main-router';
import http from '@/services/api/http';
import { keepParameters } from '@/utilities/parameters-persistance';
import Plugins from './plugins';
import GlobalComponents from './global-components-plugin';
import GlobalProperties from './global-properties-plugin';
import { createEventBus } from './global-event-bus-plugin';
import App from './app.vue';
import { configureGa } from './configure-ga';
import { configureHttp } from './configure-http';
import { configureCommitShaVariable } from './utilities/application/appVersion';


export const createApp = (store: Store<unknown>) => {
  try {
    const app = createVueApp(App);

    configureCommitShaVariable();
    configureGa();
    configureHttp(http(), store);
    configureVue(app);

    // Track UTM parameters from Ankorstore.com to my Ankorstore
    keepParameters();

    app.use(store);
    app.use(i18n);

    app.use(Plugins);
    app.use(GlobalProperties);
    app.use(GlobalComponents);

    initSentry(app, router.router);
    setSentryMetadata(store.state);
    app.use(createEventBus({ store }));

    if (router.router) {
      router.router.app = app;
      app.use(router.router);
      router.router.isReady().then(() => {
        app.mount('#app');
      });
    } else {
      app.mount('#app');
    }

    return app;
  } catch (e) {
    captureException(e);

    throw e;
  }
};
