<template>
  <li class="ds-border-b ds-border-solid ds-border-neutral-300 ds-list-none">
    <div
      class="activator ds-cursor-pointer ds-px-4 ds-py-4 ds-flex ds-items-center ds-justify-between ds-w-full hover:ds-bg-neutral-100"
      data-testid="expandableHeader"
      role="button"
      aria-pressed="false"
      tabindex="-1"
      @click="toggleBlockVisibility"
      @keyup.enter="toggleBlockVisibility"
    >
      <div class="order-summary__title">
        <AkIcon
          :symbol="symbol"
          size="md"
          :class="color === 'info' ? 'ds-text-info' : 'ds-text-primary'"
        />
        <AkParagraph
          :size="size"
          weight="bold"
          class="ds-mr-2 ds-mb-0"
          :class="color === 'info' ? 'ds-text-info' : 'ds-text-primary'"
          data-testid="orderSummaryBlock-title"
        >
          {{ title }}
        </AkParagraph>
      </div>
      <div
        class="ds-font-bold ds-flex ds-items-center ds-max-w-6xl"
        data-testid="order-summary-block-total"
      >
        <OrderSummaryBlockAmount
          loader-type="text"
          :with-loader="withLoader"
        >
          <slot name="amount"></slot>
        </OrderSummaryBlockAmount>
      </div>
    </div>

    <AkExpander
      :class="{ 'expander-open': isOpen }"
      :open="isOpen"
    >
      <ul class="order-summary-block__list">
        <slot name="content"></slot>
      </ul>
    </AkExpander>
  </li>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from 'vue';
import OrderSummaryBlockAmount from '@/modules/carts/order-summary/order-summary-block-amount.vue';
import { isEnabled, FeatureFlag } from '@/services/features';

export default defineComponent({
  name: 'OrderSummaryBlock',
  components: {
    OrderSummaryBlockAmount,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'base' | 'lg'>,
      default: 'sm',
    },
    color: {
      type: String,
      default: 'primary',
    },
    withLoader: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['open'],
  setup(_props, { emit }) {
    const isOpen = ref(true);

    const symbol = computed(() => {
      return isOpen.value ? 'chevron-up' : 'chevron-down';
    });

    const setVisibility = (value: boolean) => {
      isOpen.value = value;
    };

    const toggleBlockVisibility = () => {
      isOpen.value = !isOpen.value;

      if (isOpen.value) {
        emit('open');
      }
    };

    return { isOpen, symbol, toggleBlockVisibility, setVisibility, isEnabled, FeatureFlag };
  },
});
</script>
<style lang="scss" scoped>
.expander-open {
  @apply ds-border-t ds-border-solid ds-border-neutral-300;
}

.order-summary {
  &__title {
    @apply ds-flex ds-gap-2 ds-items-center;
  }
}

.order-summary-block {
  &__list {
    @apply ds-flex ds-w-full ds-flex-col ds-pr-4 ds-pl-7 ds-py-4;
  }
}
</style>
