<template>
  <small
    v-if="message"
    :class="notificationClasses"
  >
    {{ message }}
  </small>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { TFieldValidateValue } from '@/components/review/AkForm/types';

export default defineComponent({
  name: 'AkFormNotification',
  props: {
    message: {
      type: String as PropType<TFieldValidateValue>,
      default: null,
    },
    variant: {
      type: String,
      default: 'error',
      validator(value: string) {
        return ['error', 'success'].includes(value);
      },
    },
  },
  computed: {
    notificationClasses(): string[] {
      return ['ds-absolute', 'ds-text-sm', this.variant === 'error' ? 'ds-text-error-700' : 'ds-text-success'];
    },
  },
});
</script>
