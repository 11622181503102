import { computed, getCurrentInstance } from 'vue';

const useCurrency = () => {
  const currentInstance = getCurrentInstance();

  const $currency = computed(() => currentInstance.proxy.$currency);
  return {
    $currency,
  };
};
export default useCurrency;
