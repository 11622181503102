import { getIdFromSlug } from '@/utilities/slug';

export function productCollectionPropsParser(route) {
  return {
    brandId: getIdFromSlug(route.params.brand),
    collectionId: getIdFromSlug(route.params.collectionSlug),
  };
}

export function brandPropsParser(route) {
  return {
    brandId: parseInt(route.params.brand.split('-').slice(-1)[0], 10),
  };
}

export function productPropsParser(route) {
  return {
    productId: parseInt(route.params.product.split('-').slice(-1)[0], 10),
  };
}

export function hasRoute(router, regex) {
  const isPathMatch = window.location.href.match(regex);
  // NOTE: Since we are not using router-view on all pages, we have to use a native link instead of router-link.
  return typeof router !== 'undefined' && !isPathMatch;
}
