import axios, { AxiosResponse } from 'axios';

export const enableTwoFactorAuth = (): Promise<AxiosResponse> => {
  return axios.post('/user/two-factor-authentication').then((response) => response.data);
};

export const confirmTwoFactorAuth = (code: string): Promise<AxiosResponse> => {
  return axios.post('/user/confirmed-two-factor-authentication', { code: code }).then((response) => response.data);
};

export const disableTwoFactorAuth = (): Promise<AxiosResponse> => {
  return axios.delete('/user/two-factor-authentication').then((response) => response.data);
};

export const fetchQRCode = (): Promise<AxiosResponse> => {
  return axios.get('/user/two-factor-qr-code').then((response) => response);
};

export const fetchRecoveryCodes = (): Promise<AxiosResponse> => {
  return axios.get('/user/two-factor-recovery-codes').then((response) => response);
};

export const loadRecoveryCodes = (): Promise<AxiosResponse> => {
  return axios.get('/account/download-two-factor-recovery-codes', { responseType: 'blob' }).then((response) => response);
};

export const confirmWithCode = (code: string): Promise<AxiosResponse> => {
  return axios
    .post('/login/two-factor-challenge', {
      code: code,
    })
    .then((response) => response.data);
};

export const confirmWithRecoveryCode = (code: string): Promise<AxiosResponse> => {
  return axios
    .post('/login/two-factor-challenge', {
      recovery_code: code,
    })
    .then((response) => response.data);
};
