import i18n from '@/services/i18n';
import { ConfigDefinition, Config, Context, facetRef, layoutRef, SnippetTemplate } from '../types/catalog';
import { facets, getQuickFilters } from './facets/facets';
import { URLManagerSearch } from '../services/url-manager/url-manager-search';
import * as URLManagerCategory from '../services/url-manager/url-manager-category';
import Store from '@/store';
import { isEnabled } from '@/services/features';

import { getLang } from '@/utilities/lang';

const lang = getLang();

export const config: Record<Context, ConfigDefinition> = {
  [Context.NEW_PRODUCTS]: {
    template: SnippetTemplate.PRODUCTS,
    name: Context.NEW_PRODUCTS,
    layout: [
      layoutRef.withBreadCrumb,
      layoutRef.withTitle,
      layoutRef.withTabs,
      layoutRef.withFilterSidebar,
      layoutRef.withQuickFilters,
      layoutRef.withCategoriesFilter,
      layoutRef.withSimilarProductsDrawer,
    ],
    eventTile: false,
    filters: [
      facetRef.category,
      facetRef.locationBrand,
      facetRef.made_in,
      facetRef.tags,
      facetRef.margin,
      facetRef.retail_price,
      facetRef.shipping_lead_time,
      facetRef.discount,
      facetRef.brand,
      facetRef.aks_plus,
    ],
    quickFilters: [facetRef.tags, facetRef.margin, facetRef.discount, facetRef.aks_plus],
    urlManager: URLManagerCategory,
    maxPages: 5,
  },
  [Context.NEW_BRANDS]: {
    template: SnippetTemplate.BRANDS,
    name: Context.NEW_BRANDS,
    layout: [
      layoutRef.withBreadCrumb,
      layoutRef.withTitle,
      isEnabled('RET-2902') ? layoutRef.withTabs : undefined,
      layoutRef.withFilterSidebar,
      layoutRef.withQuickFilters,
      layoutRef.withCategoriesFilter,
    ],
    eventTile: false,
    filters: [
      facetRef.category,
      facetRef.location,
      facetRef.made_in,
      facetRef.tags,
      facetRef.marginBrand,
      facetRef.shipping_lead_timeBrand,
      facetRef.aks_plus_brand,
      facetRef.discount,
    ],
    quickFilters: [facetRef.tags, facetRef.marginBrand, facetRef.discount, facetRef.aks_plus_brand],
    urlManager: URLManagerCategory,
    maxPages: 5,
  },
  [Context.PREORDER_PRODUCTS]: {
    template: SnippetTemplate.PRODUCTS,
    name: Context.PREORDER_PRODUCTS,
    layout: [
      layoutRef.withBreadCrumb,
      layoutRef.withTitle,
      layoutRef.withFilterSidebar,
      layoutRef.withQuickFilters,
      layoutRef.withCategoriesFilter,
    ],
    eventTile: false,
    filters: [
      facetRef.category,
      facetRef.locationBrand,
      facetRef.made_in,
      facetRef.tags,
      facetRef.margin,
      facetRef.retail_price,
      facetRef.discount,
      facetRef.brand,
      facetRef.aks_plus,
    ],
    quickFilters: [facetRef.tags, facetRef.margin, facetRef.discount, facetRef.aks_plus],
    urlManager: URLManagerCategory,
    maxPages: 5,
  },
  [Context.PREORDER_BRANDS]: {
    template: SnippetTemplate.BRANDS,
    name: Context.PREORDER_BRANDS,
    layout: [
      layoutRef.withBreadCrumb,
      layoutRef.withTitle,
      layoutRef.withFilterSidebar,
      layoutRef.withQuickFilters,
      layoutRef.withCategoriesFilter,
    ],
    eventTile: false,
    filters: [
      facetRef.category,
      facetRef.location,
      facetRef.made_in,
      facetRef.tags,
      facetRef.marginBrand,
      facetRef.aks_plus_brand,
      facetRef.discount,
    ],
    quickFilters: [facetRef.tags, facetRef.marginBrand, facetRef.discount, facetRef.aks_plus_brand],
    urlManager: URLManagerCategory,
    maxPages: 5,
  },
  [Context.SEARCH]: {
    template: SnippetTemplate.PRODUCTS,
    name: Context.SEARCH,
    layout: [
      layoutRef.withTitle,
      layoutRef.withCategoriesFilter,
      layoutRef.withBrandSuggestion,
      layoutRef.withCategoryTiles,
      layoutRef.withFilterSidebar,
      layoutRef.withQuickFilters,
      layoutRef.withSimilarProductsDrawer,
    ],
    filters: [
      facetRef.category,
      facetRef.locationBrand,
      facetRef.made_in,
      facetRef.brand,
      facetRef.tags,
      facetRef.margin,
      facetRef.retail_price,
      facetRef.shipping_lead_time,
      facetRef.aks_plus,
      facetRef.discount,
    ],
    quickFilters: [facetRef.tags, facetRef.margin, facetRef.discount, facetRef.aks_plus],
    urlManager: URLManagerSearch,
  },
  [Context.CATEGORY_PRODUCTS]: {
    name: Context.CATEGORY_PRODUCTS,
    template: SnippetTemplate.PRODUCTS,
    eventTile: true,
    layout: [
      layoutRef.withBreadCrumb,
      layoutRef.withTitle,
      layoutRef.withTabs,
      layoutRef.withFilterSidebar,
      layoutRef.withQuickFilters,
      layoutRef.withCategoriesFilter,
      layoutRef.withSort,
      layoutRef.withSimilarProductsDrawer,
    ],
    filters: [
      facetRef.category,
      facetRef.locationBrand,
      facetRef.made_in,
      facetRef.tags,
      facetRef.margin,
      facetRef.retail_price,
      facetRef.shipping_lead_time,
      facetRef.discount,
      facetRef.brand,
      facetRef.aks_plus,
    ],
    quickFilters: [facetRef.tags, facetRef.margin, facetRef.discount, facetRef.aks_plus],
    urlManager: URLManagerCategory,
  },
  [Context.CATEGORY_BRANDS]: {
    template: SnippetTemplate.BRANDS,
    name: Context.CATEGORY_BRANDS,
    layout: [
      layoutRef.withBreadCrumb,
      layoutRef.withTitle,
      layoutRef.withTabs,
      layoutRef.withFilterSidebar,
      layoutRef.withQuickFilters,
      layoutRef.withCategoriesFilter,
      layoutRef.withSort,
    ],
    eventTile: true,
    filters: [
      facetRef.category,
      facetRef.location,
      facetRef.made_in,
      facetRef.tags,
      facetRef.marginBrand,
      facetRef.shipping_lead_timeBrand,
      facetRef.aks_plus_brand,
      facetRef.discount,
    ],
    quickFilters: [facetRef.tags, facetRef.marginBrand, facetRef.discount, facetRef.aks_plus_brand],
    urlManager: URLManagerCategory,
  },
  [Context.BRAND]: {
    name: Context.BRAND,
    template: SnippetTemplate.PRODUCTS,
    layout: [
      layoutRef.withSearchInput,
      layoutRef.withFilterSidebar,
      layoutRef.withQuickFilters,
      layoutRef.withCategoriesFilter,
      layoutRef.withSort,
    ],
    filters: [
      facetRef.category,
      facetRef.discount,
      facetRef.made_in,
      facetRef.tags,
      facetRef.margin,
      facetRef.retail_price,
      facetRef.shipping_lead_time,
    ],
    quickFilters: [facetRef.discount, facetRef.tags, facetRef.margin, facetRef.retail_price],
    urlManager: URLManagerSearch,
  },
  [Context.COLLECTION_PRODUCTS]: {
    name: Context.COLLECTION_PRODUCTS,
    template: SnippetTemplate.PRODUCTS,
    layout: [layoutRef.withSearchInput, layoutRef.withFilterSidebar, layoutRef.withQuickFilters, layoutRef.withCategoriesFilter],
    filters: [
      facetRef.category,
      facetRef.made_in,
      facetRef.tags,
      facetRef.margin,
      facetRef.retail_price,
      facetRef.shipping_lead_time,
      facetRef.discount,
      facetRef.aks_plus,
    ],
    quickFilters: [facetRef.tags, facetRef.margin, facetRef.retail_price, facetRef.discount, facetRef.aks_plus],
    urlManager: URLManagerSearch,
  },
  [Context.COLLECTION_BRANDS]: {
    template: SnippetTemplate.BRANDS,
    name: Context.COLLECTION_BRANDS,
    layout: [layoutRef.withSearchInput, layoutRef.withFilterSidebar, layoutRef.withQuickFilters, layoutRef.withCategoriesFilter],
    filters: [
      facetRef.category,
      facetRef.location,
      facetRef.made_in,
      facetRef.tags,
      facetRef.marginBrand,
      facetRef.shipping_lead_timeBrand,
      facetRef.discount,
      facetRef.aks_plus_brand,
    ],
    quickFilters: [facetRef.tags, facetRef.marginBrand, facetRef.discount, facetRef.aks_plus_brand],
    urlManager: URLManagerSearch,
  },
  [Context.WHOLESALE]: {
    template: SnippetTemplate.PRODUCTS,
    name: Context.WHOLESALE,
    layout: [layoutRef.withBreadCrumb, layoutRef.withTitle, layoutRef.withFilterSidebar],
    filters: [],
    quickFilters: [],
    urlManager: URLManagerSearch,
    maxPages: 10,
  },
};

export function getCatalogConfig(context = Context.SEARCH): Config {
  const AksPlusElligible = Store.getters['offers/ankorstorePlus'];
  return {
    context,
    template: config[context].template,
    withTabs: config[context].layout.includes(layoutRef.withTabs),
    withBreadCrumb: config[context].layout.includes(layoutRef.withBreadCrumb),
    withTitle: config[context].layout.includes(layoutRef.withTitle),
    withSearchInput: config[context].layout.includes(layoutRef.withSearchInput),
    withCategoriesFilter: config[context].layout.includes(layoutRef.withCategoriesFilter),
    withSort: config[context].layout.includes(layoutRef.withSort),
    withBrandSuggestion: config[context].layout.includes(layoutRef.withBrandSuggestion),
    withSimilarProductsDrawer: config[context].layout.includes(layoutRef.withSimilarProductsDrawer),
    withCategoryTiles: config[context].layout.includes(layoutRef.withCategoryTiles),
    withFilterSidebar: config[context].layout.includes(layoutRef.withFilterSidebar),
    withQuickFilters: config[context].layout.includes(layoutRef.withQuickFilters),
    Facets: facets(context, !!AksPlusElligible),
    quickFilters: getQuickFilters(config[context].quickFilters, context, !!AksPlusElligible),
    urlManager: config[context].urlManager,
    eventTile: config[context].eventTile ?? false,
    maxPages: config[context].maxPages,
  };
}

export const getMargins = (): Record<string, string> => ({
  'below_1.5': i18n.global.t('x1 - x1.5'),
  '1.5_2.0': i18n.global.t('x1.5 - x2'),
  '2.0_2.5': i18n.global.t('x2 - x2.5'),
  '2.5_3.0': i18n.global.t('x2.5 - x3.0'),
  '3.0_4.0': i18n.global.t('x3.0 - x4.0'),
  '4.0_5.0': i18n.global.t('x4.0 - x5.0'),
  'above_5.0': i18n.global.t('x5.0+'),
});

export const getAksPlus = (): Record<string, string> => ({
  repeat_offers: i18n.global.t('loyalty.filter.repeatOffer.label'),
  delivering_in_48h: i18n.global.t('loyalty.filter.delivery48H.label'),
});

export const getShipping = (): Record<string, string> => ({
  less_than_48: i18n.global.t('In less than 2 business days'),
  '3_to_5': i18n.global.t('Between {minimum} and {maximum} business days', {
    minimum: 3,
    maximum: 5,
  }),
  '6_to_10': i18n.global.t('Between {minimum} and {maximum} business days', {
    minimum: 6,
    maximum: 10,
  }),
  '11_to_20': i18n.global.t('Between {minimum} and {maximum} business days', {
    minimum: 11,
    maximum: 20,
  }),
});

export function getRestrictedSearchableAttributes(context = Context.SEARCH) {
  if (context === Context.NEW_BRANDS) {
    return [];
  }
  return [`searchable_name.${lang}`, `description.${lang}`, 'brand.name', 'brand.synonyms', 'options.sku'];
}

export function getAttributeToRetrieve(template = SnippetTemplate.PRODUCTS) {
  if (template === SnippetTemplate.BRANDS) {
    return [
      'id',
      'uuid',
      'active',
      'business_events_eligible',
      'business_events',
      'link',
      'images.squared',
      'images.large',
      'images.rounded',
      'name',
      'active',
      'highlighted_products',
      'minimum',
      'opened_countries',
      'has_new_products',
      'shipping_lead_time',
      'inserted_at',
      'brand_tags',
      'discount',
      'aks_plus',
      'available_at',
    ];
  }
  return [
    'id',
    'name',
    `description.${lang}`,
    `tags.${lang}`,
    'brand',
    'retail_price',
    'wholesale_price',
    'original_wholesale_price',
    'discount_rate',
    'unit_multiplier',
    'link',
    'options',
    'images',
    'is_new',
    'out_of_stock',
    'discount_event_price',
    'margin',
    'variants',
    'display_name',
    'uuid',
    'available_at',
  ];
}

const addCategoryFilter = (filter: string, category: number) => {
  if (!category) {
    return filter;
  }

  const categoryFilter = `catalog_categories.v3:${category}`;

  return filter ? `${filter} AND (${categoryFilter})` : categoryFilter;
};

export function getDefaultFilter(
  params: {
    country: string;
    brandId?: number;
    user?: { business: { brand?: { id: number } } };
    categoryId?;
    isStaff?: boolean;
    sortBy?: string;
  },
  context = Context.SEARCH,
  customFilter = ''
) {
  let filters = '';
  switch (context) {
    case Context.NEW_PRODUCTS:
      filters = `brand.opened_countries:"${params.country}" AND is_new:true AND sorts=[latest:desc]`;
      break;
    case Context.NEW_BRANDS:
      filters = isEnabled('RET-2902')
        ? `opened_countries:"${params.country}" AND is_new:true AND active = 1`
        : `opened_countries:"${params.country}" AND active = 1`;
      break;
    case Context.PREORDER_PRODUCTS:
      filters = `(brand.opened_countries:"${params.country}") AND (preorder:true)`;
      break;
    case Context.PREORDER_BRANDS:
      filters = `(opened_countries:"${params.country}") AND (preorder:true)`;
      break;
    case Context.SEARCH:
      filters = `(brand.opened_countries:"${params.country}")`;
      break;
    case Context.CATEGORY_PRODUCTS:
      filters = `(brand.opened_countries:"${params.country}")`;
      filters = addCategoryFilter(filters, params.categoryId);
      break;
    case Context.COLLECTION_PRODUCTS:
      filters = `(brand.opened_countries:"${params.country}")`;
      break;
    case Context.CATEGORY_BRANDS:
      filters = `(opened_countries:"${params.country}")`;
      filters = addCategoryFilter(filters, params.categoryId);
      break;
    case Context.COLLECTION_BRANDS:
      filters = `(opened_countries:"${params.country}")`;
      break;
    case Context.BRAND:
      if (params.user?.business.brand?.id === params.brandId || params.isStaff) {
        // Allow brands to view their own page without restrictions when using the "See my store" feature
        filters = `(brand.id=${params.brandId})`;
      } else {
        filters = `(brand.id=${params.brandId}) AND (brand.opened_countries:"${params.country}")`;
      }
      break;
  }
  if (params.sortBy) {
    filters = `${filters} AND (sorts=[${params.sortBy}])`;
  }
  if (customFilter) {
    filters = `${filters} AND (${customFilter})`;
  }
  return filters;
}
