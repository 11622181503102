import { getViteVersionCommitSha } from "./meta.variables";

export function getApplicationCommitSha(): string {
  return isProductionBuildMode() ? getViteVersionCommitSha() : 'local_dev_unknown_commit_sha';
}

export function configureCommitShaVariable(): void {
  globalThis.__VERSION_COMMIT_SHA = getApplicationCommitSha();
}

export function isProductionBuildMode(): boolean {
  return 'production' === process.env.NODE_ENV;
}
