<template>
  <div
    v-if="content"
    class="banner-top-line"
    :class="{ 'banner-top-line--orange': isEnabled('RLO-1475') }"
    data-testid="bannerTopLine"
  >
    <AkParagraph size="sm">
      {{ content }}
      <span
        v-if="isEnabled('RLO-1475')"
        data-testid="bannerTopLineLink"
        class="banner-top-line__link"
        @click="pushToLink"
      >
        {{ $t('homepage.bannerTopLine.orange.link') }}
      </span>
    </AkParagraph>
  </div>
</template>

<script lang="ts" setup>
import { AkParagraph } from '@ankorstore/design-system';
import { isEnabled } from '@/services/features';

defineProps<{
  content: string | null;
}>();

const pushToLink = () => {
  window.location.href = '/shop/backtobusiness#view=1';
};
</script>
<style lang="scss">
.banner-top-line {
  @apply ds-hidden ds-bg-accent-alt ds-text-primary md:ds-inline-flex ds-flex-wrap ds-text-center ds-items-center ds-justify-center ds-gap-1 ds-p-2;
  &--orange {
    @apply ds-bg-[#FF6B28] ds-text-white;
  }

  &__link {
    @apply ds-underline ds-cursor-pointer;
    &:hover,
    &:active,
    &:focus {
      @apply ds-no-underline ds-text-white;
    }
  }
}
</style>
