<template>
  <ShownObserver :callback="sectionViewed">
    <div class="home-faq">
      <Faq
        faq-name="homefaq"
        alignement="center"
        collapser
        size="small"
        :default-content="content"
        context="home"
        :show-more="false"
        @click-event="sendAnalytics"
      />
    </div>
  </ShownObserver>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import Analytics from '@/services/analytics';
import RetailerFaqSection from '@/services/analytics/events/home/retailer-faq';
import ShownObserver from '@/components/global/shown-observer.vue';
import Faq from '@/modules/shared/components/faq/faq.vue';
import { FaqInterface } from '@/types/faq';

export default defineComponent({
  name: 'RetailerFAQ',
  components: {
    ShownObserver,
    Faq,
  },
  props: {
    content: {
      type: Object as () => FaqInterface,
      required: true,
    },
  },
  methods: {
    sectionViewed(): void {
      Analytics.track(new RetailerFaqSection('Viewed'));
    },
    sendAnalytics(): void {
      Analytics.track(new RetailerFaqSection('Clicked'));
    },
  },
});
</script>
<style lang="scss" scoped>
.home-faq {
  @apply ds-container ds-mx-auto ds-max-w-5xl ds-px-5;
}
</style>
