<template>
  <AkAlert
    :hide-icon="true"
    :border="false"
    class="gsheet-banner"
    type="warning"
    data-testid="GsheetBanner"
  >
    <div class="gsheet-banner__title">
      <img
        alt="ankorstore"
        src="/images/logo/ankorstore_symbol_black.svg"
        class="gsheet-banner__icon--logo"
      />
      <AkIcon
        class="gsheet-banner__icon--arrow"
        size="md"
        symbol="arrow-right"
      />
      <img
        src="/images/account/gsheet-modal/google-sheet-icon.png"
        alt="Google Sheets"
        class="gsheet-banner__icon--file"
      />
      <AkParagraph weight="bold">
        {{ i18n.t('brands.catalogIntegration.gsheet.banner.title') }}
      </AkParagraph>
    </div>
    <AkParagraph>
      {{ i18n.t('brands.catalogIntegration.gsheet.banner.text') }}
    </AkParagraph>
    <div class="gsheet-banner__link">
      <AkButton
        link
        size="lg"
        data-testid="GsheetBannerButton"
        @click="clickExportCatalogue"
      >
        {{ i18n.t('brands.catalogIntegration.gsheet.banner.link') }}
      </AkButton>
    </div>
  </AkAlert>
</template>
<script lang="ts">
import { computed, defineComponent, Ref } from 'vue';
import useI18n from '@/composables/use-i18n';
import { useStore } from '@/composables/use-store';
import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';
import { Brand } from '@/types/api/brand';
export default defineComponent({
  name: 'GsheetBanner',
  emits: ['updated'],
  setup(_, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const brand: Ref<Brand> = computed(() => store.getters['brand']);

    const clickExportCatalogue = async () => {
      Analytics.track(
        new UserClick({
          component: 'brand_bo_catalogue_management',
          action: 'update_gsheet_from_banner',
          brand_id: brand.value.id,
          brand_name: brand.value.name,
        })
      );
      emit('updated');
    };

    return {
      i18n,
      clickExportCatalogue,
    };
  },
});
</script>
<style lang="scss" scoped>
.gsheet-banner {
  @apply ds-mb-5 ds-text-left;
  &__title {
    @apply ds-m-0 ds-mb-1 ds-flex ds-items-center;

    img {
      @apply ds-w-5 ds-h-5;
    }
  }
  &__icon--logo {
    @apply ds-mr-2;
  }
  &__icon--arrow {
    @apply ds-mr-2 ds-text-neutral-700;
  }
  &__icon--file {
    @apply ds-mr-1;
  }
  &__text {
    @apply ds-text-left;
  }
  &__link {
    @apply ds-flex;
    &-text {
      @apply ds-underline ds-mr-1 ds-mb-2;
    }
  }
}
</style>
