import { defineAsyncComponent } from 'vue';
import type { RouterOptions, RouteRecordRaw } from 'vue-router';
import { getBoutiqueSectionOffset } from '@bc/discovery/shell/boutique/utils/scroll';

export const BoutiqueRoutes: RouteRecordRaw[] = [
  {
    name: 'boutique',
    path: '/boutique/:name',
    component: defineAsyncComponent(() => import('./pages/boutique-browser.vue')),
    props: true,
  },
];

type ScrollBehavior = RouterOptions['scrollBehavior'];

export const scrollBehavior: ScrollBehavior = (to, _, savedPosition) => {
  if (to.name !== 'boutique') {
    return undefined;
  } else if (savedPosition?.top) {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(savedPosition);
      }, 100)
    );
  } else if (!to.hash) {
    return undefined;
  }

  return new Promise((resolve) =>
    setTimeout(() => {
      const boutiqueSection = to.hash.slice(1);
      const sectionOffset = getBoutiqueSectionOffset(boutiqueSection);
      resolve({
        left: 0,
        top: sectionOffset,
        behavior: 'smooth',
      });
    }, 500)
  );
};
