<template>
  <CustomAisRefinementList
    v-if="filter"
    v-bind="$attrs"
  >
    <template #default="{ refine, items }">
      <CheckboxFilter
        :items="transformItemsFn(items, filter)"
        @checkbox-changed="handleCheckboxChanged($event, refine)"
      />
    </template>
  </CustomAisRefinementList>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { CheckboxFilter, Item, Filter } from '@bc/discovery/ui/filters';
import CustomAisRefinementList from '../custom-ais-refinement-list.vue';

defineOptions({ name: 'CustomAisCheckbox' });

defineProps({
  filter: {
    type: Object as PropType<Filter>,
    required: true,
  },
  transformItemsFn: {
    type: Function as PropType<(items: Item[], filter: Filter) => Item[]>,
    default: (items: Item[]) => items,
  },
});

const handleCheckboxChanged = (item: Item, refine: (value: string | number) => void) => {
  if (item.count > 0) {
    refine(item.value);
  }
};
</script>
