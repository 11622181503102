<template>
  <transition name="fade">
    <div v-if="shouldShow">
      <AkHeading2
        v-if="label"
        class="mx-auto ds-flex ds-font-bold ds-text-2xl ds-mb-4"
        data-testid="recommendations-title"
      >
        {{ label }}
      </AkHeading2>
      <RecommendationsCarousel
        ref="carousel"
        class="recommendations-widget"
        :title="label"
        :algorithm="algorithm"
      >
        <template v-if="isProductsCarousel">
          <IntersectionObserver
            v-for="(item, index) in productItems"
            :id="index + 1"
            :key="index"
            :threshold="1"
            @change="productObserverChanged(arguments, item, index)"
          >
            <ProductSnippet
              :key="item.id"
              :item="item"
              full-width
              with-brand-link
              :with-best-seller-badge="withBestsellerBadge"
              :with-checkbox="withCheckbox"
              :additional-like-tracking-data="getLikeTrackingData(index, item)"
              :parent-section-id="sectionId"
              @clicked="productClicked(item, index)"
            />
          </IntersectionObserver>
        </template>
        <template v-else>
          <IntersectionObserver
            v-for="(item, index) in brandItems"
            :id="index + 1"
            :key="index"
            :threshold="1"
            @change="productObserverChanged(arguments, item, index)"
          >
            <BrandSnippet
              :brand="item"
              full-width
              @clicked="brandClicked(item, index)"
            />
          </IntersectionObserver>
        </template>
      </RecommendationsCarousel>
    </div>
  </transition>
</template>

<script lang="ts">
import ProductSnippet from '@/components/product/product-snippet.vue';
import BrandSnippet from '@/components/brand/brand-snippet.vue';
import RecommendationsCarousel from '../recommendations-carousel/recommendations-carousel.vue';
import {
  AdditionalLikeTrackingData,
  EventName,
  ImpressionElement,
  RecommendationAlgoType,
  snippetType,
  TrackClickItem,
} from '@/types/analytics/recommendation-tracking';
import { mapGetters } from 'vuex';
import Analytics from '@/services/analytics';
import { SectionCreatedEvent } from '@/services/analytics/events/recomendations/section-created-event';
import { defineComponent, PropType } from 'vue';
import IntersectionObserver from '@/components/global/interception-observer.vue';
import { ViewTileEvent } from '@/services/analytics/events/recomendations/view-tile-event';
import { ClickEvent } from '@/services/analytics/events/recomendations/click-event';
import { isEnabled } from '@/services/features';
import { trackMergeFn } from './utils';
import Product, { isProduct } from '@/types/product';
import { Brand as ApiBrand } from '@/types/api/brand';
import { oneOf } from '@/utilities/propValidators';
import { Recommendations } from '../../types';
import { getSectionId } from '@bc/shared';

const MIN_NUM_OF_RECOS_TO_DISPLAY = 4;
enum ItemTypes {
  PRODUCT = 'product',
  BRAND = 'brand',
}
enum DisplayTypeEnum {
  CAROUSEL = 'carousel',
  GRID = 'grid',
}

type DisplayType = DisplayTypeEnum.CAROUSEL | DisplayTypeEnum.GRID;
export default defineComponent({
  name: 'RecommendationsWidget',
  components: {
    IntersectionObserver,
    ProductSnippet,
    BrandSnippet,
    RecommendationsCarousel,
  },
  props: {
    items: {
      type: Array as PropType<Recommendations['products'] | Recommendations['brands']>,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    widgetProposition: {
      type: String,
      required: true,
    },
    algorithm: {
      type: Number as PropType<RecommendationAlgoType>,
      required: true,
    },
    itemNumber: {
      type: Number,
      default: null,
    },
    minNumberRecordsToShow: {
      type: Number,
      default: MIN_NUM_OF_RECOS_TO_DISPLAY,
    },
    displayOrder: { type: Number, default: 1 },
    shouldTrackTileViews: {
      type: Boolean,
      default: isEnabled('track-tile-views'),
    },
    withCheckbox: {
      type: Boolean,
      default: false,
    },
    displayType: {
      type: String as PropType<DisplayType>,
      default: DisplayTypeEnum.CAROUSEL,
      validator: oneOf(Object.values(DisplayTypeEnum)),
    },
    withBestsellerBadge: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['impression-id'],
  computed: {
    ...mapGetters(['user']),
    carousel(): HTMLElement {
      return this.$refs.carousel as HTMLElement;
    },
    shouldShow(): boolean {
      return this.items.length >= this.minNumberRecordsToShow;
    },
    positionInPage(): number {
      return this.displayOrder - 1;
    },
    sectionId(): string {
      return getSectionId({
        valueProposition: this.widgetProposition,
        sectionType: ImpressionElement.recoWidget,
      });
    },
    isProductsCarousel(): boolean {
      return this.items.every((item: Product | ApiBrand) => item.type === ItemTypes.PRODUCT);
    },
    productItems(): Product[] {
      return this.items as Product[];
    },
    brandItems(): ApiBrand[] {
      return this.items as ApiBrand[];
    },
  },
  watch: {
    sectionId: {
      handler(sectionId, prevSectionId) {
        if (!sectionId || sectionId === prevSectionId) {
          return;
        }

        this.$emit('impression-id', sectionId);

        const ids = this.items.map((item, index) => ({
          id: item.id,
          position: index,
        }));

        this.shouldShow &&
          Analytics.track(
            new SectionCreatedEvent({
              id_section: sectionId,
              id_algo: this.algorithm,
              itemtype: this.items[0]?.type as snippetType,
              value_proposition: this.widgetProposition,
              section_type: ImpressionElement.recoWidget,
              id_brand: this.items[0]?.type === snippetType.brand ? ids : [],
              id_product: this.items[0]?.type === snippetType.product ? ids : [],
              position_component: this.positionInPage,
            })
          );
      },
      immediate: true,
    },
  },
  methods: {
    getLikeTrackingData(position: number, item: Product | ApiBrand): AdditionalLikeTrackingData {
      return {
        id_section: this.sectionId,
        component: this.$route?.name === 'carts' ? 'cart_oos_carousel' : null,
        itemtype: item?.type,
        position,
        ...(isProduct(item) && {
          id_brand: `${item.brand.id}`,
        }),
      };
    },
    productObserverChanged([entry, unobserve], item, index): void {
      if (entry.isIntersecting) {
        if (this.shouldTrackTileViews) {
          Analytics.trackMergeBuffer(
            new ViewTileEvent({
              userId: this.user?.id,
              event: EventName.ViewTile,
              page_path: this.$route.path,
              id_section: this.sectionId,
              section_type: ImpressionElement.recoWidget,
              id_algo: this.algorithm,
              itemtype: item.type,
              value_proposition: this.widgetProposition,
              id_brand: this.algorithm !== RecommendationAlgoType.productsYouMayLike ? { id: item.id, position: index } : null,
              id_product: this.algorithm === RecommendationAlgoType.productsYouMayLike ? { id: item.id, position: index } : null,
              position: null,
            }),
            trackMergeFn
          );
        }
        unobserve();
      }
    },
    productClicked(product: Product, index: number): void {
      this.trackClick({
        productId: product.id,
        position: index,
        itemtype: snippetType.product,
        brandId: product.brand.id,
      });
    },
    brandClicked(brand: ApiBrand, index: number): void {
      this.trackClick({
        brandId: brand.id,
        position: index,
        itemtype: snippetType.brand,
      });
    },
    trackClick({ brandId, productId, position, itemtype }: TrackClickItem): void {
      Analytics.track(
        new ClickEvent({
          id_section: this.sectionId,
          component: ImpressionElement.recoWidget.toString(),
          brandId,
          productId,
          position,
          itemtype,
          action: `click_${this.widgetProposition.toLowerCase().split(' ').join('_')}`,
        })
      );
    },
  },
});
</script>
