import http from '@/services/api/http';
import { OrderBrandRejectionType } from '@/types/order-brand-rejection';

export const rejectOrder = (orderId: number, rejectReason: OrderBrandRejectionType): Promise<void> => {
  return http().post(`/api/orders/${orderId}/cancel`, {
    reject_type: rejectReason,
  });
};

export type Address = {
  address: {
    countryCode: string; // TODO: enum
    city: string;
    addressLine: string;
    postalCode: string;
  };
  companyName: string;
  contactPerson: {
    firstName: string;
    lastName: string;
    fullName: string;
    phoneNumber: string;
    email: string;
  };
};
