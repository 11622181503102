<template>
  <div class="ds-flex ds-justify-end ds-h-4">
    <template v-if="isLoading && withLoader">
      <AkSkeleton
        v-if="loaderType === 'skeleton'"
        class="ds-w-8 ds-h-4"
      />
      <AkParagraph
        v-else
        size="base"
      >
        {{ $t('Loading') }}...
      </AkParagraph>
    </template>

    <slot v-else></slot>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { useStore } from '@/composables/use-store';

export default defineComponent({
  name: 'OrderSummaryBlockAmount',
  props: {
    loaderType: {
      type: String as PropType<'skeleton' | 'text'>,
      default: 'skeleton',
    },
    withLoader: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => {
      return store.getters['cart/isTotalsLoading'] ?? false;
    });
    return { isLoading };
  },
});
</script>
