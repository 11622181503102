<template>
  <div
    :class="inputClasses"
    :data-count="valueLength"
  >
    <component
      :is="type"
      ref="inputRef"
      :class="controlClasses"
      :value="modelValue"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :disabled="disabled"
      @input.stop="handleInput"
      @blur="$emit('blur')"
    >
      {{ modelValue }}
    </component>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AkInput',
  compatConfig: {
    COMPONENT_V_MODEL: false,
  },
  props: {
    type: {
      type: String,
      default: 'input',
      validator(value: string) {
        return ['input', 'textarea'].includes(value);
      },
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    maxLength: {
      type: Number,
      default: Infinity,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['blur', 'maxLengthReached', 'update:modelValue'],
  computed: {
    inputClasses(): string[] {
      return ['ak-input', `ak-input--${this.type}`, 'ds-relative'];
    },
    controlClasses(): string[] {
      const { disabled, hasError, maxLength } = this;
      const staticClasses = ['control', 'ds-w-full', 'ds-outline-none', 'ds-border', 'ds-rounded-md', 'ds-text-sm'];
      const dynamicClasses = [];
      if (disabled) {
        dynamicClasses.push('ds-text-neutral-600', 'ds-bg-neutral-300', 'ds-border-neutral-500');
      } else if (hasError) {
        dynamicClasses.push('ds-border-error');
      }
      if (maxLength) {
        dynamicClasses.push('control--with-max-length');
      }
      return [...staticClasses, ...dynamicClasses];
    },
    valueLength(): string {
      return `${this.modelValue?.length}/${this.maxLength}`;
    },
  },
  methods: {
    focus() {
      (this.$refs['inputRef'] as HTMLInputElement).focus();
    },
    handleInput(e) {
      const value = e.target.value;
      if (this.maxLength && value.length <= this.maxLength) {
        this.$emit('update:modelValue', value);
      } else {
        this.$emit('maxLengthReached');
      }
    },
  },
});
</script>

<style scoped lang="scss">
.ak-input {
  .control {
    @apply ds-px-3 ds-py-2;
  }
  &::after {
    @apply ds-absolute ds-text-sm ds-text-neutral-600 ds-right-2;
    content: attr(data-count);
    bottom: 10px;
  }
  &--input {
    .control--with-max-length {
      @apply ds-pr-8;
    }
  }
  &--textarea {
    .control {
      @apply ds-pr-8 ds-pb-6 ds-resize-y;
      min-height: calc(3rem + 2rem * 1.25);
    }
  }
}
</style>
