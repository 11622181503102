export enum CartsPageComponents {
  CartViewTab = 'cart_view_tab',
  CartSavedForLaterTab = 'cart_save_for_later_tab',
  OrderSummary = 'order_summary',
}

export enum CartsPageEventAction {
  Click_Display_Product_Details_Arrow = 'display_product_details',
  Click_Display_Tax_Details = 'display_tax_details',
  Click_Display_Order_Summary_Details = 'display_order_summary_details',
  Click_Display_Discount_Details = 'display_discount_details',
  Click_Saved_For_Later_Tab = 'saved_for_later_tab_clicked',
  Click_Your_Cart_Tab = 'your_cart_tab_clicked',
  Click_Move_Brand_To_Cart = 'move_brand_to_cart',
}

export interface CartsPageEventProperties {
  action: CartsPageEventAction;
  component: CartsPageComponents;
  id_brand?: string;
  id_retailer: string;
  id_checkout?: string;
}

export enum CartsPageEventName {
  User_Click = 'user_click',
}

export type ConcreteCartsPageEventProperties = Omit<CartsPageEventProperties, 'action' | 'component'>;
