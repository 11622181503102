import { JSONApiError, LaravelValidationError } from '@/types/fulfillment';
import http from '@/services/api/http';
import { deserialize } from '@/services/utils/jsonapi-parser';

export type SortDirection = 'asc' | 'desc';

export type SortParams<T extends string> = {
  [key in T]?: SortDirection;
};

export type OffsetResourceParams = {
  offset?: number;
  limit: number;
};
export const isJSONApiError = (error: JSONApiError | LaravelValidationError): error is JSONApiError => {
  return (error as JSONApiError).jsonapi !== undefined;
};

export const getFulfillmentSortParams = <T extends string>(sort: SortParams<T>) => {
  return Object.keys(sort)
    .map((sortKey) => {
      return `${sort[sortKey] === 'desc' ? '-' : ''}${sortKey}`;
    })
    .join(',');
};

/**
 * Generic POST wrapper with additional error handling
 * @param url
 * @param payload
 */
export const post = async <T, P>(url: string, payload: T): Promise<P> => {
  let response = await http()
    .post(url, payload, {
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
    })
    .catch(errorHandling);
  response = deserialize(response.data);
  return response.data;
};
/**
 * Generic GET wrapper with additional error handling
 * @param url
 * @param params
 */
export const get = async <T>(url: string, params?: Record<string, string | number | boolean | Array<string>>): Promise<T> => {
  let response = await http()
    .get<T>(url, {
      params,
    })
    .catch(errorHandling);
  response = deserialize(response.data);
  return response.data;
};
/**
 * Generic DELETE wrapper with additional error handling
 * @param url
 * @param params
 */
export const del = async <T>(url: string, params?: Record<string, string | number | boolean | Array<string>>): Promise<T> => {
  let response = await http()
    .delete<T>(url, {
      params,
    })
    .catch(errorHandling);
  response = deserialize(response.data);
  return response.data;
};
/**
 * Generic PATCH wrapper with additional error handling
 * @param url
 * @param payload
 */
export const patch = async <T, P>(url: string, payload: T): Promise<P> => {
  let response = await http()
    .patch(url, payload, {
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
    })
    .catch(errorHandling);
  response = deserialize(response.data);
  return response.data;
};

const errorHandling = (err: unknown) => {
  if (http().isAxiosError(err)) {
    // If the error come from API, remove the axios wrapper and return it directly
    throw err.response.data;
  }
  throw err;
};
