import { State } from './index';

export const getters = {
  getUserLists: (state: State) =>
    Object.values(state.lists)
      .filter((list) => !list.default)
      .sort((a, b) => a.position - b.position),
  getDefaultList: (state: State) => Object.values(state.lists).find((list) => list.default),
  getList: (state: State) => (listId: string | number) => state.lists[listId],
  getProductLikeCount: (state: State) => state.productLikeCount,
  isTooltipAvailable: (state: State) => state.productLikeCount.shouldDisplayTooltip,
  getListWithProduct: (state: State) => (productId: number) =>
    Object.values(state.lists).find((list) => list.products.some((product) => product.id === productId)),
  getStatus: (state: State) => state.status,
  getWishlist: (state: State) => Object.values(state.lists),
  getNumberProductCheckedFromList: (state: State) => (listId) => state.lists[listId].productsChecked.length,
  getProductsCheckedFromList: (state: State) => (listId) => state.lists[listId].productsChecked,
  getSortColumnByListId: (state: State) => (listId) => state.lists[listId].sort.column,
  getSortDirectionByListId: (state: State) => (listId) => state.lists[listId].sort.direction,
  isProductChecked: (state: State) => (listId, productId) => state.lists[listId].productsChecked.indexOf(productId) !== -1,
};

export default getters;
