import { HistoryRouterBase } from './history-router-base';
import type { SearchRouteState } from '../../types/catalog';
import { URLParameterKey, getLocationsFromRoute } from '../url-manager/url-manager-category';

export class HistoryRouterBrand extends HistoryRouterBase {
  read() {
    const hashParams = this.getHashParams(this.currentRoute);
    const categories = this.urlManager().getCategoriesFromRoute(this.getCategories(), this.currentRoute);
    const locations = getLocationsFromRoute(this.getLocations(), this.currentRoute);

    const hierarchicalMenu = this.removeEmptyValues({
      'catalog_hierarchical_categories.v3.lvl0': categories.map((category) => `${category.id}`),
    });

    const attributes = this.getAttributeRefinements(hashParams);

    const refinementList = this.removeEmptyValues({
      location: locations.map((location) => location.id),
      average_product_margin_ranges: hashParams.get(URLParameterKey.Margin)?.split(',') ?? [],
      [`made_in.${this.getLang()}`]: hashParams.get(URLParameterKey.MadeIn)?.split(',') ?? [],
      [`tags.${this.getLang()}`]: hashParams.get(URLParameterKey.Values)?.split(',') ?? [],
      shipping_lead_time: hashParams.get(URLParameterKey.Shipping)?.split(',') ?? [],
      discount: hashParams.get(URLParameterKey.Discount)?.split(',') ?? [],
      aks_plus: hashParams.get(URLParameterKey.loyaltyOffer)?.split(',') ?? [],
      ...attributes,
    });
    const attributeRanges = this.getAttributeRanges(hashParams);
    const attributesToggle = this.getAttributeToggles(hashParams);

    const range = this.removeEmptyValues(attributeRanges);
    const toggle = this.removeEmptyValues(attributesToggle);
    const textQuery = this.currentRoute.query.q?.toString() ?? '';
    const page = parseInt(this.currentRoute.query.p?.toString() ?? '');

    const routeState: SearchRouteState = {
      ...this.removeEmptyValues({
        hierarchicalMenu,
        refinementList,
        range,
        toggle,
      }),
    };

    if (textQuery) {
      routeState.q = textQuery;
    }

    if (page) {
      routeState.page = page;
    }

    return routeState;
  }
}
