<template>
  <div
    ref="filterContainerRef"
    class="filter-wrapper"
  >
    <slot></slot>
    <hr
      v-if="withSeparator"
      data-testid="filter-wrapper-separator"
      class="filter-wrapper__separator"
    />
  </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';

export default defineComponent({
  name: 'FilterWrapper',
  props: {
    withSeparator: Boolean,
  },
  setup() {
    const filterContainerRef = ref<HTMLDivElement>();
    return { filterContainerRef };
  },
});
</script>

<style lang="scss" scoped>
.filter-wrapper {
  scrollbar-gutter: stable;
  :deep(.ak-heading--3) {
    @apply ds-text-base ds-mb-0;
  }
  &__separator {
    @apply ds-m-0 ds-mb-5 ds-border-neutral-300 ds-border-solid;
  }
}
</style>
