<template>
  <div class="brand-item">
    <img
      :alt="brand.name"
      :src="`https://${$cdn}${brand.images.rounded}?auto=format,compress&fm=pjpg&h=40&dpr=2`"
      class="brand-logo"
    />
    <div class="ds-flex-col ds-flex-1">
      <div class="cart-row">
        <div class="cart-brand-name">
          {{ brand.name }}
        </div>
        <div
          v-if="hasAboveMinimum && cart.total_amount_before_discount"
          class="cart-amount discounted"
        >
          {{ $root.formatPrice(cart.total_amount_before_discount) }}
        </div>
        <div
          v-if="hasAboveMinimum"
          class="cart-amount"
        >
          {{ $root.formatPrice(cart.total_amount) }}
        </div>
        <AkIcon
          symbol="chevron-down"
          size="sm"
          class="ds-ml-1"
        />
      </div>
      <div
        ref="minimumIndication"
        class="cart-row minimum-indication-wrapper minimumIndication"
      >
        <span
          v-if="!brandIsOpenedInUserCountry"
          class="minimum-indication"
        >
          {{ $t('Sorry this brand is not available in your country.') }}
        </span>
        <span
          v-else-if="!cart.minimum.above"
          class="minimum-indication"
        >
          <i18n-t
            keypath="Add {0} to reach the minimum"
            tag="span"
          >
            {{ $root.formatPrice(amountLeftForValidCart) }}
          </i18n-t>
        </span>
        <span
          v-else
          class="minimum-indication success"
        >
          <AkIcon symbol="check" />
          {{ $t('Minimum reached') }}</span>
      </div>
      <div
        v-if="hasAdditionalCost"
        class="ds-text-neutral-700 ds-mt-1"
        data-testid="additionalCostMessage"
      >
        {{ $t('retailer.floatingCart.brandItem.additionalCostMessage') }}
      </div>
      <div
        v-if="showEoriWarnings"
        class="cart-row ds-text-warning ds-mb-0"
        :data-testid="`eori-warning-${cart.id}`"
      >
        {{ $t('EORI required') }}
      </div>
      <div class="cart-row">
        <div
          v-if="brand.event_special_discount"
          class="cart-row ds-text-discount ds-flex-1"
        >
          <AkIcon
            symbol="check2-circle"
            size="sm"
          />
          {{
            $t('@@promote_and_engage.floating_cart.brand_discount_applied', {
              brandExtraDiscount: brand.event_special_discount,
            })
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Cart, CartBrand } from '@/types/cart';
import { FeatureFlag, isEnabled } from '@/services/features';
import { BillingItemType } from '@/types/billing-item';
import { AkIcon } from '@ankorstore/design-system';

export default defineComponent({
  name: 'BrandItem',
  components: { AkIcon },
  props: {
    cart: {
      type: Object as () => Cart,
      required: true,
    },
    userCountry: {
      type: String,
      required: true,
    },
    shouldApplyCountryRestrictions: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    brand(): CartBrand {
      return this.cart.brand;
    },
    showEoriWarnings(): boolean {
      return isEnabled(FeatureFlag.MandatoryEori) && this.cart.shipping_is_restricted_by_eori;
    },
    brandIsOpenedInUserCountry(): boolean {
      if (!this.brand.opened_countries || !this.shouldApplyCountryRestrictions) {
        return true;
      }
      return this.brand.opened_countries?.includes(this.userCountry) ?? false;
    },
    amountLeftForValidCart(): { amount: number; currency: string } {
      if (!this.cart) {
        return {
          amount: 0,
          currency: this.$currency,
        };
      }
      return {
        amount: this.cart.minimum.amount.amount - this.cart.total_amount.amount,
        currency: this.$currency,
      };
    },
    hasAboveMinimum(): boolean {
      return this.cart.minimum.above && this.brandIsOpenedInUserCountry;
    },
    hasAdditionalCost(): boolean {
      return this.cart.billing_items?.some(({ billing_item_type }) =>
        [BillingItemType.retailerShippingFees].includes(billing_item_type)
      );
    },
  },
});
</script>

<style scoped lang="scss">
.brand-item {
  @apply ds-text-xs;
  .cart-row {
    @apply ds-flex ds-flex-row ds-flex-1 ds-items-start;
    i:first-child {
      @apply ds-mr-1;
    }
  }
  .brand-logo {
    @apply ds-mr-2;
  }
  .cart-brand-name {
    @apply ds-font-bold ds-flex-1 ds-pr-1;
  }
}
</style>
