export interface BadgeConfiguration {
  priority: number;
  discounts?: number[];
  text?: string;
  symbol?: string;
  color?:
    | 'blue'
    | 'green'
    | 'green-full'
    | 'grey'
    | 'grey-full'
    | 'orange'
    | 'purple'
    | 'purple-full'
    | 'red'
    | 'red-full'
    | 'blue-alt'
    | 'accent';
  overridesPriority?: boolean;
}

export interface BadgeInfo {
  text?: string;
  symbol?: string;
  color?:
    | 'blue'
    | 'green'
    | 'green-full'
    | 'grey'
    | 'grey-full'
    | 'orange'
    | 'purple'
    | 'purple-full'
    | 'red'
    | 'red-full'
    | 'blue-alt'
    | 'accent';
}

export const buildSourceBadgeList = (config: BadgeConfiguration[]): BadgeInfo[] => {
  const overrideList = config.filter((badge) => badge.overridesPriority);
  const curatedConfig = config
    .filter((badge) => badge.text || badge.discounts?.length > 0)
    .sort((a, b) => (a.priority > b.priority ? 1 : a.priority < b.priority ? -1 : 0));

  const buildList = (list: BadgeConfiguration[]): BadgeInfo[] =>
    list.map(({ text, discounts, color, symbol }) => ({
      text: getDisplayedValue(
        text,
        discounts?.reduce((previous, discount) => previous + discount, 0)
      ),
      symbol,
      color,
    }));

  if (overrideList.length > 0) {
    return [buildList(overrideList)[0]]; // in case of multiple overrides we want only the one with the higher priority
  } else {
    return buildList([...curatedConfig]);
  }
};

function getDisplayedValue(text: string, discount: number): string {
  if (text && discount) {
    return `${text} -${discount}%`;
  } else if (discount) {
    return `-${discount}%`;
  }

  return text;
}
