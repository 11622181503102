import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export const orderIssueFileUploadingMixin = defineComponent({
  name: 'OrderIssueFileUploadingMixin',
  emits: ['set-loader'],
  computed: {
    ...mapGetters('orderRetailerRejection', ['getConfig']),
  },
  methods: {
    setFileUploaderErrors(errors: Array<string> = [], fileUploaderRef: string = null) {
      if (fileUploaderRef) {
        const element = this.$refs[fileUploaderRef];

        if (element) {
          const fileUploader = element[0];
          fileUploader.errors = errors;
        }
      } else {
        this.fileUploader.errors = errors;
      }
    },
    trimFileName(value: string): string {
      if (!value) {
        return '-';
      }
      const maxLength = this.getConfig.maxFileLabelLength;
      let name = `${value.slice(0, maxLength)}`;

      if (value.length > maxLength) {
        name += '...';
      }

      return name;
    },
    removeUploadedFile(index: number, callback): void {
      this.setFileUploaderErrors();
      const file = this.files[index];
      this.files.splice(index, 1);
      callback(file);
    },
    setLoader(value: boolean): void {
      this.$emit('set-loader', value);
    },
  },
});
