<template>
  <AkAlert type="info">
    <p>
      {{
        $t(
          'Your order has been validated by Ankorstore and sent to the brand for acceptation. Please note that brands can refuse an order if they do not have enough stock or if they consider that your shop does not match their criteria.'
        )
      }}
    </p>
    <p>
      <i18n-t keypath="We invite you to check the evolution of the order status {0}.">
        <a
          href="/account/orders"
          class="ds-underline"
        >
          {{ $t('in your account, in the “Orders” section') }}
        </a>
      </i18n-t>
    </p>
  </AkAlert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AlertThankYouValidated',
});
</script>
