<template>
  <AkSelect
    :model-value="modelValue"
    required
    :has-error="!!errors.length"
    :label="attribute.label + ' '"
    :placeholder="attribute.inputHint || $t('Select')"
    ::no-elements-message="$t('brand.productManagement.attributes.empty')"
    :options="attribute.options"
    :label-info="attribute.inputTip"
    tag-color="accent-alt"
    :errors="errors"
    @update:model-value="$emit('update:modelValue', $event)"
  />
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { AkSelect } from '@ankorstore/design-system';
import { ProductTypeAttributes } from '@bc/brands/feature/product-management/types/product-type-attributes';

export default defineComponent({
  name: 'ProductTypeSelect',
  components: {
    AkSelect,
  },
  props: {
    attribute: {
      type: Object as () => ProductTypeAttributes,
      required: true,
      default: () => {},
    },
    modelValue: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
});
</script>
