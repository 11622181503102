<template>
  <OrderSummaryBlock
    :title="$t('@@reorder.order_summary.discounts.title')"
    @open="open"
  >
    <template #amount>
      {{ totalDiscounts }}
    </template>
    <template #content>
      <template v-if="itemsParsed.length > 0">
        <li
          v-for="item in itemsParsed"
          :key="item.id"
          :data-testid="`discount-item-${item.id}`"
          class="discount-line"
          :class="{ 'discount-line--last': item.billing_item_type === BillingItemType.promocode }"
        >
          <div class="ds-w-full ds-flex ds-justify-between">
            <div class="discounts__wrapper">
              <span>{{ item.label }}</span>
              <AkTooltipInformation
                v-if="item.billing_item_type === BillingItemType.promocode"
                data-testid="discountVatTooltip"
                type="invert"
                trigger="hover"
                placement="top"
                :content="$t('retailer.priceBreakdown.discountsSection.vat')"
              >
                <AkIcon
                  class="ds-ml-1"
                  symbol="info-circle"
                  size="sm"
                />
              </AkTooltipInformation>
            </div>
            <OrderSummaryBlockAmount>
              <span>-</span>
              {{ item.amount }}
            </OrderSummaryBlockAmount>
          </div>

          <div
            v-if="item.amount_vat.amount > 0"
            class="ds-mt-2 ds-w-full ds-flex ds-justify-between"
          >
            <span> {{ `+${item.vat_label}` }}</span>
            <OrderSummaryBlockAmount>
              <span>-</span>
              {{ item.amount_vat }}
            </OrderSummaryBlockAmount>
          </div>
        </li>
      </template>
      <li v-else>
        <AkParagraph
          size="sm"
          class="ds-text-neutral-900 ds-mt-2 ds-mb-0"
        >
          {{ $t('retailer.priceBreakdown.discountsSection.noItemsMessage') }}
        </AkParagraph>
      </li>
    </template>
  </OrderSummaryBlock>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import { AkParagraph, AkTooltipInformation } from '@ankorstore/design-system';
import OrderSummaryBlock from '@/modules/carts/order-summary/order-summary-block.vue';
import OrderSummaryBlockAmount from '@/modules/carts/order-summary/order-summary-block-amount.vue';
import { BillingItem, BillingItemType } from '@/types/billing-item';

import Analytics from '@/services/analytics';
import { ShowDiscountDetailsClick } from '@/services/analytics/events/reorder/carts-page/show-discount-details-click.event';
import { useStore } from '@/composables/use-store';
import { capturePurchasingException } from '@/sentry/helper';
import useGlobals from '@/composables/use-globals';
import { formatPrice, absolutePrice, sumPrices } from '@/utilities/price';
import { getCurrency } from '@/services/metas/currency';

const negativeBillingItemTypes: `${BillingItemType}`[] = ['event_discount', 'offer_discount', 'wallet', 'promocode'];

export default defineComponent({
  name: 'Discounts',
  components: {
    OrderSummaryBlock,
    OrderSummaryBlockAmount,
    AkParagraph,
    AkTooltipInformation,
  },
  props: {
    items: {
      type: Array as PropType<BillingItem[]>,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore();
    const { currency } = useGlobals();

    const cartStore = computed(() => store.state.cart);
    const retailerStore = computed(() => store.state.retailer);

    const itemsParsed = computed(() => {
      return props.items.map((item) => {
        return {
          ...item,
          amount: formatPrice(
            negativeBillingItemTypes.includes(item.billing_item_type) ? absolutePrice(item.amount) : item.amount
          ),
          amount_vat: formatPrice(absolutePrice(item.amount_vat)),
        };
      });
    });

    const totalDiscounts = computed((): string => {
      try {
        return formatPrice(
          sumPrices(
            props.items.map(({ amount }) => amount),
            getCurrency()
          )
        );
      } catch (e) {
        capturePurchasingException(e);
        return formatPrice({
          amount: 0,
          currency,
        });
      }
    });

    const open = () => {
      Analytics.track(
        new ShowDiscountDetailsClick({
          id_checkout: cartStore.value.checkout.id,
          id_retailer: retailerStore.value.id,
        })
      );
    };

    return {
      negativeBillingItemTypes,
      BillingItemType,
      totalDiscounts,
      open,
      formatPrice,
      absolutePrice,
      itemsParsed,
    };
  },
});
</script>
<style lang="scss" scoped>
.discounts__wrapper {
  @apply ds-w-full ds-flex ds-items-center;
}
.discount-line {
  @apply ds-flex-col ds-w-full ds-flex ds-justify-between ds-items-center ds-mt-5 ds-text-neutral-900;
  &:first-of-type {
    @apply ds-mt-0;
  }
  &--last {
    @apply ds-order-last;
  }
}
</style>
