import type { RouterOptions } from 'vue-router';

type ScrollBehavior = RouterOptions['scrollBehavior'];
type Position = Parameters<ScrollBehavior>[2];

/**
 * A scroll behavior for Vue Router that scrolls to the
 * saved scroll position when a specific element is present
 * in the DOM.
 * The selector for the element must be specified in the
 * route's meta data.
 *
 * @example This example shows a route that will wait for
 * the element with the id `catalog-hits-tiles-container` to be
 * present in the DOM before scrolling to the saved position.
 * ```ts
 * // routes.ts
 * import type { RouteConfig }, {createRouter} from 'vue-router';
 *
 * export const routes: RouteConfig[] = [
 *   {
 *     path: '/collection/:name',
 *     name: 'collection',
 *     component: () => import('./pages/collection.vue'),
 *     props: true,
 *     meta: {
 *       scrollContainer: '#catalog-hits-tiles-container',
 *     },
 *   },
 * ];
 *
 * const router = createRouter({
 *  mode: 'history',
 *  scrollBehavior(to, from, savedPosition) {
 *    const scrollContainerPosition = scrollContainerBehavior(to, from, savedPosition);
 *
 *    if (scrollContainerPosition) {
 *      return scrollContainerPosition;
 *    }
 *
 *    return savedPosition;
 *  },
 *  routes,
 * });
 * ```
 **/
export const scrollContainerBehavior: ScrollBehavior = (to, _, savedPosition) => {
  if (!('MutationObserver' in globalThis)) {
    return undefined;
  }

  if (!to?.meta?.scrollContainer || !savedPosition) {
    return undefined;
  }

  let resolve: (position: Position) => void;

  const scrollPromise = new Promise<Position>((_resolve) => (resolve = _resolve));
  let isObserving = true;
  const observer = new MutationObserver(() => {
    const element = document.querySelector(to.meta.scrollContainer as string);
    if (element) {
      observer.disconnect();
      resolve(savedPosition);
      isObserving = false;
    }
  });
  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });

  // If the scroll container is not available after 10 seconds,
  // stop waiting for it.
  const TEN_SECONDS = 10000;
  setTimeout(() => {
    if (isObserving) {
      observer.disconnect();
      resolve(undefined);
    }
  }, TEN_SECONDS);

  return scrollPromise;
};

/**
 * Scrolls to the passed element.
 * @param elToScroll
 */
export function scrollToElement(elToScroll: HTMLElement) {
  elToScroll.scrollIntoView({ behavior: 'smooth', block: 'center' });
}
