import { RouteRecordRaw } from 'vue-router';

const searchRoutes: RouteRecordRaw[] = [
  {
    name: 'search',
    path: '/search',
    props: (route) => {
      const categories = Array.isArray(route.query.categories) ? route.query.categories : route.query.categories?.split(',');
      const selectedCategoryId = Number(categories?.at(-1));
      return {
        selectedCategoryId,
        searchQuery: route.query.q,
      };
    },
    component: async () => import('./pages/search-browser.vue'),
  },
];

export default searchRoutes;
