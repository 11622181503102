import { captureLoyaltyException } from '@/sentry/helper';

import http from '@/services/api/http';

export interface OfferEligibilityStatus {
  status: boolean;
  discount?: number;
  endDate?: Date;
}

export type B2REligibility = OfferEligibilityStatus;

export const getB2REligibility = async (isRetailer: boolean): Promise<B2REligibility> => {
  if (!isRetailer) {
    return {
      status: false,
    };
  }

  try {
    const result = await http().get('/api/me/b2r_eligible');

    if (result?.data?.data) {
      return {
        status: result.data.data.status,
        discount: result.data.data.discount,
        endDate: result.data.data.end_date ? new Date(result.data.data.end_date) : null,
      };
    }

    return {
      status: false,
    };
  } catch (e) {
    captureLoyaltyException(e);

    return {
      status: false,
    };
  }
};
