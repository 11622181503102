<template>
  <div class="ds-mx-5">
    <div class="ds-w-full ds-inline-flex ds-items-center ds-gap-2 ds-py-5">
      <AkIcon
        symbol="exclamation-triangle"
        size="md"
        class="ds-bg-error-700 ds-text-white ds-px-1 ds-rounded-lg"
      />
      <div class="ds-text-xs">
        <span class="ds-font-semibold">{{ $t('retailer.reimbursement.title') }}</span>
        {{ $t('retailer.reimbursement.description') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AkIcon } from '@ankorstore/design-system';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BannerReimbursement',
  components: { AkIcon },
});
</script>
