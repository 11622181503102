export enum CaseReason {
  DamagedItems = 'damagedItems',
  MissingItems = 'missingItems',
  WrongItems = 'wrongItems',
  DefectiveItems = 'defectiveItems',
  NotReceived = 'notReceived',
  General = 'general',
}

export enum CaseReasonStep {
  Initial = 'initial',
  OrderInfo = 'orderInfo',
  ProductsCheckList = 'productsCheckList',
  ProductsList = 'productsList',
  Summary = 'summary',
  TrackWithLink = 'trackWithLink',
  ContactBrand = 'contactBrand',
  ParcelReception = 'parcelReception',
  ParcelDetails = 'parcelDetails',
}

export enum ShippingMethod {
  Label = 'label',
  Custom = 'custom',
  Fulfillment = 'fulfillment',
}

export enum CustomShippingMethod {
  Aks = 'aks',
  Brand = 'brand',
}

export enum ParcelIssueOption {
  ParcelExternalIssues = 'parcelExternalIssues',
  DeliveryReservation = 'deliveryReservation',
}

export enum PackagingIssue {
  IrrelevantBoxSize = 'irrelevantBoxSize',
  LackOfProtectiveMaterials = 'lackOfProtectiveMaterials',
  LackOfSeparators = 'lackOfSeparators',
  Other = 'other',
}

export enum FormFileType {
  Packaging = 'packaging',
  Parcel = 'parcel',
  PackagingReservation = 'reservation',
  ParcelContent = 'parcelContent',
}

export enum OrderIssueStatus {
  IssueOpened = 'issue_opened',
  CustomerServiceRequested = 'customer_service_requested',
  AwaitingBrandReshipment = 'awaiting_brand_reshipment',
  Reshipped = 'reshipped',
  Closed = 'issue_closed',
}

export enum OrderIssueEscalationType {
  escalate = 'escalate',
  reshipment = 'reshipment',
}

export interface CaseReasonStepInfo {
  stepNumber: number;
  label: string;
  key: string;
}

export interface FileLinks extends Array<string> {
  link: string;
}

export interface ProductFile {
  file: File;
  link: string;
  url: string;
}

export interface ProductFiles extends Array<ProductFile> {
  [index: number]: ProductFile;
}

export interface FormFile {
  file: File;
  link: string;
  type: FormFileType;
}

export interface PackagingIssuesList {
  [PackagingIssue.IrrelevantBoxSize]: boolean;
  [PackagingIssue.LackOfProtectiveMaterials]: boolean;
  [PackagingIssue.LackOfSeparators]: boolean;
  [PackagingIssue.Other]: boolean;
}

export interface ParcelIssues {
  [ParcelIssueOption.ParcelExternalIssues]: boolean;
  [ParcelIssueOption.DeliveryReservation]: boolean;
}

export interface FormFiles extends Array<FormFile> {
  [index: number]: FormFile;
}

export interface OrderProduct {
  id: number;
  orderItemId: number;
  sku: string;
  name: string;
  originalName: string;
  quantity: string;
  originalQuantity: number;
  selected: boolean;
  expanded: boolean;
  expandedOnce: boolean;
  saved: boolean;
  description: string;
  files: ProductFiles;
  quantityError: string;
  descriptionError: string;
  filesErrors: Array<any>;
}

export interface OrderParcel {
  trackingLink: string;
  clicked: boolean;
}

interface OrderItemVariant {
  sku: string;
}

export interface OrderItemProduct {
  id: number;
  name: string;
  original_name: string;
}

export interface OrderItem {
  id: number;
  internalId: string;
  variant: OrderItemVariant;
  product: OrderItemProduct;
  multiplied_quantity: number;
  unitQuantity: number;
  orderedProduct: {
    data: {
      sku: string;
      name: string;
    }
  }
}

export interface OrderItems extends Array<OrderItem> {
  [index: number]: OrderItem;
}

export interface OrderParcels extends Array<OrderParcel> {
  [index: number]: OrderParcel;
}

export interface OrderProducts extends Array<OrderProduct> {
  [index: number]: OrderProduct;
}
