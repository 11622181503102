<template>
  <div class="product-collection-list">
    <div
      v-if="!collections"
      class="product-collection-list__loader"
    >
      <LoaderIcon />
    </div>
    <template v-else>
      <CollectionSnippet
        v-for="collection in collections"
        :key="collection.id"
        :brand="brand"
        :collection="collection"
        @click="onCollectionClick(collection.id)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getProductCollectionsListFromBrand } from '@/services/product-collections/product-collections';
import { Brand } from '@/types/api/brand';
import Analytics from '@/services/analytics';
import { UserClick } from '@/services/analytics/events/user-click.event';
import { SectionCreatedEvent } from '@/services/analytics/events/section_created.event';
import { CollectionSnippet } from '@bc/discovery';
import LoaderIcon from '@/components/loader-icon.vue';

export default defineComponent({
  name: 'BrandCatalogCollections',
  components: {
    CollectionSnippet,
    LoaderIcon,
  },
  props: {
    brand: {
      type: Object as () => Brand,
      default: null,
    },
  },
  data() {
    return {
      collections: null,
    };
  },
  async mounted() {
    this.collections = await getProductCollectionsListFromBrand(this.brand.id);
    this.trackSectionCreated();
  },
  methods: {
    onCollectionClick(collectionId: number): void {
      Analytics.track(
        new UserClick({
          component: 'product-collection-list',
          action: 'click_on_collection',
          collection_id: collectionId,
          id_brand: this.brand.id,
        })
      );
    },
    trackSectionCreated() {
      const collectionList = this.collections.map(({ id }, position) => ({
        id,
        position,
      }));
      Analytics.track(
        new SectionCreatedEvent({
          // @ts-ignore
          id_brand: this.brand.id,
          collection_list: collectionList,
          itemtype: 'product-collection',
        })
      );
    },
  },
});
</script>
<style lang="scss" scoped>
.product-collection-list {
  @apply ds-space-y-4 ds-mt-4 sm:ds-space-y-6 sm:ds-mt-6;
  &__loader {
    @apply ds-flex ds-items-center ds-justify-center;
    height: 32rem;
  }
}
</style>
