import {
  postAppRequest,
  getAppsRequest,
  patchAppRequest,
  deleteAppRequest,
  getAvailableEventsRequest,
  postSubscriptionRequest,
  deleteSubscriptionRequest,
  patchSubscriptionRequest,
  postRegenerateCredentialsRequest,
} from '@/services/api/private-orders-integration';
import { Integration, Event, Subscription } from '@/types/brand-integration-api';

export interface State {
  apps: Integration[];
  availableEvents: Event[];
  activeApp: Integration | null;
}
const initialState: State = {
  apps: [],
  availableEvents: [],
  activeApp: null,
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SET_APPS: (state: State, apps: Integration[]) => {
      state.apps = apps;
    },
    PUSH_APP: (state: State, app: Integration) => {
      state.apps.push(app);
    },
    UPDATE_APP: (state: State, app: Integration) => {
      const indexToUpdate = state.apps.findIndex((storedApp) => storedApp.id === app.id);
      state.apps[indexToUpdate] = app;
    },
    REMOVE_APP: (state: State, app: Integration) => {
      const indexToUpdate = state.apps.findIndex((storedApp) => storedApp.id === app.id);
      state.apps.splice(indexToUpdate, 1);
    },
    SET_AVAILABLE_EVENTS: (state: State, availableEvents: Event[]) => {
      state.availableEvents = availableEvents;
    },
    SET_ACTIVE_APP: (state: State, id: string) => {
      state.activeApp = state.apps.find((app) => app.id === id);
    },
    CLEAR_ACTIVE_APP: (state: State) => {
      state.activeApp = null;
    },
    PUSH_SUBSCRIPTION: (state: State, { subscriptionData, appId }: { subscriptionData: Subscription; appId: string }) => {
      const appToUpdate = state.apps.find((app) => app.id === appId);
      if (!appToUpdate.webhookSubscriptions) {
        appToUpdate.webhookSubscriptions = { data: [] };
      }
      appToUpdate.webhookSubscriptions.data.push(subscriptionData);
    },
    REMOVE_SUBSCRIPTION: (state: State, { subscriptionId, appId }: { subscriptionId: string; appId: string }) => {
      const appIndexToUpdate = state.apps.findIndex((storedApp) => storedApp.id === appId);
      const subscriptionIndexToUpdate = state.apps[appIndexToUpdate].webhookSubscriptions.data.findIndex(
        (storedSubscription) => storedSubscription.id === subscriptionId
      );

      state.apps[appIndexToUpdate].webhookSubscriptions.data.splice(subscriptionIndexToUpdate, 1);
    },
    UPDATE_SUBSCRIPTION: (state: State, { subscriptionData, appId }: { subscriptionData: Subscription; appId: string }) => {
      const appToUpdate = state.apps.find((app) => app.id === appId);
      if (!appToUpdate.webhookSubscriptions) {
        appToUpdate.webhookSubscriptions = { data: [] };
      }
      const indexToUpdate = appToUpdate.webhookSubscriptions.data.findIndex(
        (storedSubscription) => storedSubscription.id === subscriptionData.id
      );
      appToUpdate.webhookSubscriptions.data[indexToUpdate] = subscriptionData;
    },
  },
  actions: {
    fetchApps: async ({ commit }) => {
      const res = await getAppsRequest();
      commit('SET_APPS', res);
    },
    createApp: async ({ commit }, app: Pick<Integration, 'name' | 'developerEmail'>) => {
      const res = await postAppRequest(app);
      commit('PUSH_APP', res);
      return res;
    },
    editApp: async ({ commit }, app: Pick<Integration, 'name' | 'developerEmail' | 'id'>) => {
      const res = await patchAppRequest(app);
      commit('UPDATE_APP', res);
      return res;
    },
    deleteApp: async ({ commit }, app: Pick<Integration, 'id'>) => {
      await deleteAppRequest(app);
      commit('REMOVE_APP', app);
    },
    fetchAvailableEvents: async ({ commit }): Promise<Event[]> => {
      const res = await getAvailableEventsRequest();
      commit('SET_AVAILABLE_EVENTS', res);
      return res;
    },
    createSubscription: async ({ commit }, payload) => {
      const res = await postSubscriptionRequest(payload);
      const appId = payload.data.relationships.application.data.id;
      commit('PUSH_SUBSCRIPTION', { subscriptionData: res, appId });
      return res;
    },
    setActiveApp({ commit }, id: string) {
      commit('SET_ACTIVE_APP', id);
    },
    clearActiveApp({ commit }) {
      commit('CLEAR_ACTIVE_APP');
    },
    deleteSubscription: async ({ commit }, subscription: Pick<Subscription, 'id'> & { appId: string }) => {
      await deleteSubscriptionRequest(subscription);
      commit('REMOVE_SUBSCRIPTION', {
        subscriptionId: subscription.id,
        appId: subscription.appId,
      });
    },
    editSubscription: async ({ commit }, { subscriptionPayload, appId }) => {
      const res = await patchSubscriptionRequest(subscriptionPayload);
      commit('UPDATE_SUBSCRIPTION', { subscriptionData: res, appId });
      return res;
    },
    regenerateCredentials: async ({ commit }, appId: string) => {
      const res = await postRegenerateCredentialsRequest(appId);
      commit('UPDATE_APP', res);
      return res;
    },
  },
  getters: {
    appsCounter: (state: State) => state.apps.length,
  },
};
