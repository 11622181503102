import { Links, Meta } from '@/types/pagination';
import http from '@/services/api/http';

import Conversations, {
  Conversation,
  Messages,
  ConversationMessageData,
  Recipient,
  ConversationTypeMember,
  UploadedFile,
  UploadedFiles,
} from '@/types/conversation';

import MessageSentEvent from '@/services/analytics/events/message-sent-event';
import MessageRepliedEvent from '@/services/analytics/events/message-replied-event';
import MessageReadEvent from '@/services/analytics/events/message-read-event';
import { OriginProperty } from '@/services/analytics/properties/origin-property';

import Analytics from '@/services/analytics';

export interface State {
  conversations: Conversations;
  conversationsOrder: number[];
  recipients: Recipient[];
  recipient: Recipient;
  meta: Meta;
  conversationsLoading: boolean;
  links: Links;
  conversationsSearchQuery: string;
  conversationsCurrentPage: number;
}

const initialState: State = {
  conversations: {},
  conversationsOrder: [],
  recipients: [],
  recipient: null as Recipient,
  meta: null,
  conversationsLoading: false,
  links: {
    first: null,
    last: null,
    next: null,
    prev: null,
  },
  conversationsSearchQuery: '',
  conversationsCurrentPage: 1,
};
export default {
  namespaced: true,

  state: initialState,

  actions: {
    loadConversations({ commit, state }): void {
      const params: object = {
        page: state.conversationsCurrentPage,
        query: state.conversationsSearchQuery,
      };
      commit('TOGGLE_CONVERSATIONS_LOADING', true);
      http()
        .get('/api/conversations', { params })
        .then(({ data }) => {
          commit('SET_CONVERSATIONS', {
            conversations: Object.fromEntries(data.data.map((conversation) => [conversation.id, conversation])),
            order: data.data.map((conversation) => conversation.id),
          });
          commit('SET_CONVERSATIONS_PAGINATION_META', data);
          commit('TOGGLE_CONVERSATIONS_LOADING', false);
        });
    },
    changeCurrentConversationsPage({ commit, dispatch }, page) {
      commit('SET_CONVERSATIONS_CURRENT_PAGE', page);
      dispatch('loadConversations');
    },
    searchConversations({ commit, dispatch }, query: string): void {
      commit('SET_CONVERSATIONS_SEARCH_QUERY', query);
      commit('SET_CONVERSATIONS_CURRENT_PAGE', 1);
      dispatch('loadConversations');
    },
    createConversation(
      { commit },
      {
        type,
        id,
        content,
        origin,
        attachments,
      }: {
        type;
        id: number;
        content: string;
        origin: OriginProperty;
        attachments: UploadedFiles;
      }
    ): void {
      http()
        .post(
          `/api/conversations`,
          {
            content,
            recipient_id: id,
            recipient_type: type,
            origin,
            attachments,
          },
          {
            noProgressBar: true,
          }
        )
        .then(({ data }) => {
          Analytics.track(new MessageSentEvent(data.data));
          commit('ADD_CONVERSATION', data.data as Conversation);
        });
    },
    loadOlderMessages({ commit }, { nextUrl, conversationId }) {
      http()
        .get(nextUrl, {})
        .then((data) => {
          commit('ADD_MESSAGE_PAGINATION', {
            conversationId: conversationId,
            messages: data.data as Messages,
          });
        });
    },
    loadConversation({ commit }, conversationId: number): void {
      if (!conversationId) {
        return;
      }
      http()
        .get(`/api/conversations/${conversationId}`)
        .then(({ data }) => {
          return commit('ADD_CONVERSATION', data.data as Conversation);
        });
    },
    loadConversationWithRetailer({ commit }, recipientId: number): Promise<any> {
      return http()
        .get(`/api/conversations`, {
          params: { retailer_id: recipientId },
          noProgressBar: true,
        })
        .then(({ data }) => {
          if (data.data?.length > 0) {
            return commit('ADD_CONVERSATION', data.data[0] as Conversation);
          }
        });
    },
    loadConversationWithBrand({ commit }, recipientId: number): Promise<any> {
      return http()
        .get(`/api/conversations`, {
          params: { brand_id: recipientId },
          noProgressBar: true,
        })
        .then(({ data }) => {
          if (data.data?.length > 0) {
            return commit('ADD_CONVERSATION', data.data[0] as Conversation);
          }
        });
    },
    loadConversationByRecipientId({ dispatch }, { id, type }: { id: number; type: ConversationTypeMember }): Promise<any> {
      return type === ConversationTypeMember.brand
        ? dispatch('loadConversationWithBrand', id)
        : dispatch('loadConversationWithRetailer', id);
    },
    loadConversationMessages({ commit }, conversationId: number): void {
      if (!conversationId) {
        return;
      }
      http()
        .get(`/api/conversations/${conversationId}`)
        .then(({ data }) => {
          return commit('SET_CONVERSATION_MESSAGES', {
            messages: data.data.messages as Messages,
            conversationId: conversationId,
          });
        });
    },
    sendMessage(
      { commit, getters },
      {
        conversationId,
        content,
        origin,
        attachments,
      }: {
        conversation;
        conversationId: number;
        content: string;
        origin: OriginProperty;
        attachments: UploadedFiles;
      }
    ) {
      http()
        .post(
          `/api/conversations/${conversationId}/messages`,
          {
            content,
            origin,
            attachments,
          },
          {
            noProgressBar: true,
          }
        )
        .then(({ data }) => {
          const message: Conversation = getters.getConversationById(conversationId);
          Analytics.track(new MessageRepliedEvent(message));

          commit('ADD_MESSAGE', {
            conversationId,
            message: data as ConversationMessageData,
          });
        });
    },
    messageRead({ getters }, { conversationIds }) {
      http().post(
        '/api/conversations/bulk-action',
        {
          conversation_ids: conversationIds,
          action: 'mark_as_read',
        },
        {
          noProgressBar: true,
        }
      );
      const message: Conversation = getters.getConversationById(conversationIds[0]);
      Analytics.track(new MessageReadEvent(message));
    },
    loadRecipients({ commit }, { query }: { query: string }): Promise<any> {
      return http()
        .get(`/api/conversations/recipients?query=${query}`)
        .then(({ data }) => {
          commit('SET_RECIPIENTS', {
            recipients: data.data,
          });
        });
    },
    loadRecipientById({ commit }, { recipientId }: { recipientId: number }): Promise<any> {
      return http()
        .get(`/api/conversations/recipient/${recipientId}`)
        .then(({ data }) => {
          commit('SET_RECIPIENT', data.data);
        });
    },
    async deleteMessageAttachment(_, { id }: { id: number }): Promise<void> {
      return await http().delete(`/api/conversations/messages/attachments/${id}`);
    },
    async addMessageAttachment(_, { file }: { file: File }): Promise<UploadedFile> {
      const formData = new FormData();
      formData.append('file', file);

      const response = await http().post(`/api/conversations/messages/attachments`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        noProgressBar: true,
      });

      return response.data;
    },
  },
  mutations: {
    SET_CONVERSATIONS(state: State, { conversations, order }: { conversations: Conversations; order: number[] }): void {
      state.conversations = { ...conversations };
      state.conversationsOrder = [...order];
    },
    SET_RECIPIENTS(state: State, { recipients }: { recipients: Recipient[] }): void {
      state.recipients = recipients;
    },
    SET_RECIPIENT(state: State, recipient: Recipient): void {
      state.recipient = recipient;
    },
    SET_CONVERSATIONS_PAGINATION_META(state: State, { meta, links }) {
      state.meta = meta;
      state.links = links;
    },
    SET_CONVERSATION_MESSAGES(state: State, { conversationId, messages }): void {
      state.conversations[conversationId]['messages'] = messages;
    },
    SET_CONVERSATIONS_SEARCH_QUERY(state: State, query: string): void {
      state.conversationsSearchQuery = query;
    },
    SET_CONVERSATIONS_CURRENT_PAGE(state: State, page: number): void {
      state.conversationsCurrentPage = page;
    },
    ADD_CONVERSATION(state: State, conversation: Conversation): void {
      state.conversations = {
        ...state.conversations,
        [conversation.id]: conversation,
      };
      state.conversationsOrder.push(conversation.id);
    },
    ADD_MESSAGE(state: State, { conversationId, message }: { conversationId: number; message: ConversationMessageData }): void {
      state.conversations[conversationId].messages.edges.unshift(message);
      this['_vm'].$emit('new-message');
    },
    ADD_MESSAGE_PAGINATION(state: State, { conversationId, messages }: { conversationId: number; messages: Messages }): void {
      state.conversations[conversationId].messages.edges = state.conversations[conversationId].messages.edges.concat(
        messages.edges
      );
      state.conversations[conversationId].messages.links = messages.links;
      state.conversations[conversationId].messages.meta = messages.meta;
    },
    TOGGLE_CONVERSATIONS_LOADING(state, value) {
      state.conversationsLoading = value;
    },
  },
  getters: {
    getConversations: (state: State): Conversations => state.conversations,
    getOrderedConversations: (state: State): Conversation[] =>
      state.conversationsOrder.map((conversationId) => state.conversations[conversationId]),
    getConversationById:
      (state: State) =>
      (id: number): Conversation | undefined =>
        state.conversations[id],
    getConversationByRecipientEntityId:
      (state: State) =>
      (id: number): Conversation | undefined =>
        Object.values(state.conversations).find((conversation) => conversation.recipient.entity.id === id),
    doesConversationHaveHistory:
      (_state: State, getters) =>
      (conversationId: number): boolean => {
        const conversation = getters.getConversationById(conversationId);
        return conversation.messages?.links.next !== null;
      },
    amITheAuthor:
      (_state: State, getters) =>
      (conversationId: number, messageId: number): boolean => {
        const conversation = getters.getConversationById(conversationId);
        return (
          conversation.sender.id === conversation.messages.edges.find((message) => message.data.id == messageId).data.author.id
        );
      },
    getConversationsPagination: (state: State): { meta: Meta; links: Links } => ({
      meta: state.meta,
      links: state.links,
    }),
    getRecipients: (state: State): Recipient[] => {
      return state.recipients;
    },
    getRecipientById:
      (state: State) =>
      (id: number): Recipient | undefined => {
        return state.recipients.find((recipient) => recipient.id === id);
      },
    hasAnyConversation: (state: State): boolean => {
      return Boolean(state.meta?.total_no_filters);
    },
    isConversationsLoading: (state: State): boolean => {
      return Boolean(state.conversationsLoading);
    },
  },
};
