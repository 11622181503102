import AnalyticsEvent from '../analytics-event';
import { getSourceByPath } from '@/services/analytics/events/home/source-property';

export default class RetailerFaqSection extends AnalyticsEvent {
  public readonly name = 'Retailer FAQ Section';
  public properties: {
    type: string;
    source: string;
  };

  constructor(type) {
    super();
    this.properties = {
      type,
      source: getSourceByPath(),
    };
  }
}
