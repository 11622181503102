<script lang="ts" setup>
import { computed } from 'vue';
import { isEnabled } from '@/services/features';
import { imgCdnUrlFill } from '@bc/shared';
import { useBreakpoints } from '@/modules/design-system-candidates';
import { useI18n } from 'vue-i18n';
import ImageLazyload from '@/components/global/image-lazyload.vue';
import Product, { ProductDiscount, ProductHit, DiscountType } from '@/types/product';
import { isProductOutOfStock, isPreorder, isPreorderActual } from '@/services/product-variants';
import { AkIcon } from '@ankorstore/design-system';
import { BadgeConfiguration } from '@/modules/promote-engage/touchpoints/services/badges-list.service';
import BadgesList from '@/modules/promote-engage/touchpoints/badges-list.vue';
import { getTags } from '@/services/metas/tag';

defineOptions({
  name: 'ProductSnippetImage',
});

const props = withDefaults(
  defineProps<{
    item: Product | ProductHit;
    withBestSellerBadge?: boolean;
    withNewBadge?: boolean;
    brandDiscountOrigin?: ProductDiscount | null;
    offerDiscountOrigin?: ProductDiscount | null;
    discountType?: DiscountType | null;
  }>(),
  {
    item: () => ({}) as Product | ProductHit,
    withBestSellerBadge: () => true,
    withNewBadge: () => true,
    brandDiscountOrigin: () => null,
    offerDiscountOrigin: () => null,
    discountType: () => null,
  }
);

const { isMobile } = useBreakpoints();
const { t } = useI18n();

const isBestSeller = computed((): boolean => {
  if (props.item.tags) {
    return !!Object.values(props.item.tags).find((e) => e === getTags().tags_bestseller.label);
  }
  return false;
});

const showNewBadge = computed((): boolean => {
  return (isBestSeller.value && !isMobile.value && props.item?.is_new) || (props.item?.is_new && !isBestSeller.value);
});

const badges = computed((): BadgeConfiguration[] => {
  const result: BadgeConfiguration[] = [];
  const discounts = [];

  if (props.brandDiscountOrigin) {
    discounts.push(props.brandDiscountOrigin.percentage);
  }

  if (props.offerDiscountOrigin) {
    discounts.push(props.offerDiscountOrigin.percentage);
  }

  if (discounts.length > 0) {
    if (props.discountType === DiscountType.LOYALTY) {
      result.push({
        priority: 1,
        color: 'accent',
        text: t('global.percentage.minus', { percentage: discounts[0] }),
        symbol: 'repeat-offer',
      });
    } else {
      result.push({
        priority: 1,
        discounts,
        color: 'purple-full',
      });
    }
  }

  if (isBestSeller.value && props.withBestSellerBadge) {
    result.push({
      priority: 100,
      text: t('Bestseller'),
      color: 'green',
    });
  }

  if (showNewBadge.value && props.withNewBadge) {
    result.push({
      priority: 10,
      text: t('New!'),
      color: 'blue',
    });
  }

  if (isProductOutOfStock(props.item) || (isPreorder(props.item) && !isPreorderActual(props.item))) {
    result.push({
      overridesPriority: true,
      priority: 1,
      text: t('Out of stock'),
      color: 'red-full',
    });
  }

  if (isPreorder(props.item)) {
    result.push({
      symbol: 'calendar-date',
      priority: 1,
      text: t('productCard.badge.preorder'),
      color: 'blue',
    });
  }

  return result;
});
</script>

<template>
  <div class="product-snippet-container ds-relative ds-bg-white">
    <span class="ds-absolute ds-flex bottom-badges">
      <BadgesList :badge-infos="badges" />
    </span>

    <div
      v-if="isProductOutOfStock(item)"
      class="out-of-stock"
    >
      <div
        v-if="!isEnabled('rcb-466')"
        class="out-of-stock-container"
      >
        <AkIcon
          symbol="x-circle"
          size="md"
        />
        <p>{{ $t('Out of stock') }}</p>
      </div>
    </div>

    <ImageLazyload
      class="ds-m-0"
      :src="imgCdnUrlFill(item.images[0], '300')"
      :alt="item.name"
    />
  </div>
</template>

<style lang="scss" scoped>
.top-badges {
  column-gap: 6px;
  left: 8px;
  top: 22px;
  z-index: 3;
}

.bottom-badges {
  @apply ds-gap-x-2 ds-left-3 ds--bottom-3;
  z-index: 3;
}

.has-checkbox .top-badges {
  @apply ds-ml-6;
}

.out-of-stock {
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;

  &-container {
    text-align: center;

    i {
      margin-bottom: 6px;
    }

    p {
      margin-bottom: 0;
    }
  }
}
</style>
