<template>
  <div>
    <router-link
      :to="url"
      :class="classes"
      @click="trackClick()"
    >
      {{ name }}
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import UserClick from '@/services/analytics/events/user-click.event';
import Analytics from '@/services/analytics';
export default defineComponent({
  name: 'AkLink',
  props: {
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      required: true,
    },
    tracking: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    async trackClick() {
      if (!this.tracking) {
        return;
      }
      const tracking = {
        component: this.tracking.component,
        action: this.tracking.action,
      };
      await Analytics.track(new UserClick(tracking));
    },
  },
});
</script>
