<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    class="rich-description"
    v-html="description"
  ></div>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getRichDescription } from './utilities/rich-description';

export default defineComponent({
  name: 'RichDescription',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    description() {
      return getRichDescription(this.text);
    },
  },
});
</script>
<style lang="scss">
.rich-description {
  @apply ds-mb-4;

  ul,
  ol {
    @apply ds-m-0 ds-ml-3;
  }
  li {
    @apply ds-mb-0.5;
    &::before {
      @apply ds-relative ds-inline-block ds-align-baseline ds-mr-2;
    }
  }
  ul li::before {
    content: '';
    width: 0.45em;
    height: 0.45em;
    @apply ds-rounded-full ds--top-px ds-bg-neutral-900;
  }
  ol {
    counter-reset: item;
    li {
      &::before {
        min-width: 0.45em;
        content: attr(data-bullet) '. ';
        @apply ds-font-bold ds-text-center;
      }
    }
  }
}
</style>
