<template>
  <AkAlert
    type="info"
    :title="$t('Were reviewing your order')"
  >
    <p>
      {{ $t('retailer.order.thankYouValidation.alert.title') }}
      <strong>{{ $t('24h to 48h.') }}</strong>
    </p>

    <p>
      <strong>{{
        $t('Please be aware the brands will only be notified of your order once we have confirmed your purchasing eligibility.')
      }}</strong>
      {{ $t('We invite you to check the evolution of the order status in your account, in the “Orders” section.') }}
    </p>
    <a
      :href="$t('https://knowledgecommunity.force.com/faq/s/article/who-can-buy-on-ankorstore-1?language=en_US')"
      target="_blank"
      rel="noopener"
    >
      <AkButton link>
        {{ $t('See more about eligibility criteria') }}
      </AkButton>
    </a>
  </AkAlert>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AlertThankYouValidation',
});
</script>
