export const INIT_RESULTS = 'initResultsAction';
export const FETCH_CATEGORY_SUGGESTIONS = 'fetchCategorySuggestionsAction';
export const FETCH_POPULAR_SEARCHES = 'fetchPopularSearchesElasticAction';
export const FETCH_ALL = 'fetchElasticSearchAllAction';
export const FETCH_RESULTS_ELASTIC = 'fetchResultsAction';
export const HANDLE_ITEM_CLICK = 'handleItemClickAction';
export const HANDLE_CLICK_ALL_RESULTS = 'handleClickAllResultsAction';
export const DO_RESET_RESULTS = 'doResetResultsAction';
export const TRACK_CLICK = 'trackClickAction';
export const BROWSE_TO_RESULT = 'browseToResultAction';
export const HANDLE_R2B_CLICK = 'handleR2BClickAction';
export const SAVE_RECENT_SEARCH = 'saveRecentSearch';
export const INIT_RECENT_SEARCH_FROM_LOCALSTORAGE = 'initRecentSearchFromlocalstorage';
export const HANDLE_ITEM_CLICK_REMOVAL = 'handleItemClickRemoval';
export const SET_RECENT_SEARCH_FOR_QUERY = 'setRecentSearchForSearchQuery';
