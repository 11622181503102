import http from '@/services/api/http';
import { HeaderPills } from '@/types/api/pill';
import { captureLoyaltyException } from '@/sentry/helper';

export const getPill = async (): Promise<HeaderPills> => {
  try {
    const {
      data: { data },
    } = await http().get(`/api/internal/v1/offer-programme/header-pills`);

    return data;
  } catch (e) {
    captureLoyaltyException(e);

    return null;
  }
};
