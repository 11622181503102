<template>
  <div
    :class="{
      'filters-sidebar': true,
      'filters-sidebar--sticky': !isModalOpen,
    }"
  >
    <AkButton
      class="filters-sidebar__modal-button"
      data-testid="filters-sidebar__mobile-button"
      color="white"
      :outlined="true"
      @click="openModal"
    >
      {{ mobileButtonLabel }}
    </AkButton>
    <div
      :class="{
        'filters-sidebar__container': true,
        'filters-sidebar__container--open': isModalOpen,
      }"
    >
      <div class="filters-sidebar__heading">
        <AkHeading3 class="filters-sidebar__title">
          {{ mobileHeading }}
        </AkHeading3>
        <AkButton
          class="filters-sidebar__chevron"
          data-testid="filters-sidebar__close-button"
          color="white"
          size="lg"
          symbol="x"
          @click="closeModal"
        />
      </div>
      <div class="filters-sidebar__content">
        <slot />
      </div>
      <div class="filters-sidebar__footer">
        <AkButton
          class="filters-sidebar__apply-button"
          data-testid="filters-sidebar__apply-button"
          @click="closeModal"
        >
          {{ $t('discovery.filters.filtersModal.applyButton') }}
        </AkButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { AkButton, AkHeading3 } from '@ankorstore/design-system';

export default defineComponent({
  name: 'FiltersSidebar',
  components: {
    AkButton,
    AkHeading3,
  },
  props: {
    mobileButtonLabel: {
      type: String,
      required: true,
    },
    mobileHeading: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isModalOpen = ref(false);
    const openModal = () => {
      isModalOpen.value = true;
    };
    const closeModal = () => {
      isModalOpen.value = false;
    };

    return {
      isModalOpen,
      openModal,
      closeModal,
    };
  },
});
</script>

<style lang="scss" scoped>
@use '@css/abstracts/design-tokens' as d;
.filters-sidebar {
  // block
  background-color: theme('colors.white');
  z-index: 10;

  // element
  & &__modal-button {
    width: 100%;
    @include d.lg() {
      display: none;
    }
  }

  &__container {
    display: none;
    z-index: 1000;
    background-color: theme('colors.white');
    @include d.lg() {
      display: block;
    }
    &--open {
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;
      overscroll-behavior: contain;
    }
  }

  &__heading {
    position: relative;
    padding: 1rem 0 0.75rem;
    border-bottom: 1px solid theme('colors.neutral.300');
    @include d.lg() {
      display: none;
    }
  }

  &__title {
    width: 100%;
    text-align: center;
  }

  & &__chevron {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    @include d.lg() {
      padding: 0;
    }
  }

  &__footer {
    padding: 1rem 1rem 1.5rem;
    @include d.lg() {
      display: none;
    }
  }

  &__apply-button {
    width: 100%;
  }

  // modifier
  &--sticky {
    position: sticky;
  }
}

</style>
