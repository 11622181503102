<template>
  <div class="loading">
    <LoaderIcon class="loading-icon" />
    <span>{{ $t('Please wait,') }}</span>
    <span>{{ $t('we calculate your basket.') }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import LoaderIcon from '@/components/loader-icon.vue';

export default defineComponent({
  name: 'TotalsLoader',
  components: {
    LoaderIcon,
  },
});
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  &-icon {
    margin: 10px 0;
  }
}
</style>
