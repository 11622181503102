import {
  FETCH_ADS_CAMPAIGNS,
  FETCH_ADS_PRODUCT_INFO,
  FETCH_CAMPAIGN_ADS,
  FETCH_CAMPAIGN_INFO,
} from '@bc/advertisement/feature/ads-dashboard/store/actions-types';
import {
  AdStatus,
  CampaignInfo,
  CampaignInfoApiResponse,
  CampaignInfoExtended,
  getAdsCampaignList,
  getCampaignAds,
  getCampaignInfo,
} from '@bc/advertisement/domain';
import {
  SET_ADS_CAMPAIGNS,
  SET_ADS_LIST,
  UPDATE_CURRENT_CAMPAIGN,
} from '@bc/advertisement/feature/ads-dashboard/store/mutations-types';
import {
  calculateDateDifferenceInDays,
  getWeeklyBudgetFromTotalAmount,
  parseCampaignInfoResponse,
  parseProductToAdsProductItem,
} from '@bc/advertisement/feature/ads-dashboard/services/ads-util.service';
import { InitialState } from '@bc/advertisement/feature/ads-dashboard/store/index';
import { getProductListByUuid } from '@/services/api/brand-me';
import { CAMPAIGN_LIST_PAGE_SIZE } from '@bc/advertisement/feature/ads-dashboard/ads-dashboard.config';

export default {
  [FETCH_ADS_CAMPAIGNS]: async ({ commit }, { brandUuid, offset }: { brandUuid: string; offset: number }) => {
    try {
      const campaignResponse = await getAdsCampaignList(brandUuid, offset, CAMPAIGN_LIST_PAGE_SIZE);
      const campaignList: CampaignInfo[] = campaignResponse.data;

      // for each campaign calculate weekly budget
      campaignList.forEach((campaign) => {
        campaign.startDate = new Date(campaign.startDate);
        campaign.endDate = new Date(campaign.endDate);

        (campaign as CampaignInfoExtended).weeklyBudget = getWeeklyBudgetFromTotalAmount(
          campaign.budget.amount,
          calculateDateDifferenceInDays(campaign.startDate, campaign.endDate)
        );
      });

      commit(SET_ADS_CAMPAIGNS, { campaigns: campaignList, campaignsMeta: campaignResponse.meta });
    } catch (e) {
      // The http error is being managed in the try catch of the domain service
      commit(SET_ADS_CAMPAIGNS, []);
    }
  },
  [FETCH_CAMPAIGN_INFO]: async ({ commit }, campaignUuid: string) => {
    try {
      const campaign: CampaignInfoApiResponse = await getCampaignInfo(campaignUuid);

      commit(UPDATE_CURRENT_CAMPAIGN, parseCampaignInfoResponse(campaign));
    } catch (e) {
      // The http error is being managed in the try catch of the domain service
    }
  },
  [FETCH_CAMPAIGN_ADS]: async ({ commit }, campaignUuid: string) => {
    try {
      const ads = await getCampaignAds(campaignUuid);

      commit(SET_ADS_LIST, ads);
    } catch (e) {
      // The http error is being managed in the try catch of the domain service
    }
  },
  [FETCH_ADS_PRODUCT_INFO]: async ({ commit, state }) => {
    try {
      const uuids = (state as InitialState).adsList
        .filter((ad) => [AdStatus.ACTIVE, AdStatus.PENDING].includes(ad.status))
        .map((ad) => ad.externalEntityId);
      const products = await getProductListByUuid(uuids);
      const adsProducts = products.map(parseProductToAdsProductItem);

      commit(UPDATE_CURRENT_CAMPAIGN, { adsProducts });
    } catch (e) {
      // The http error is being managed in the try catch of the domain service
    }
  },
};
