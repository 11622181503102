<script setup lang="ts">
import { AkIcon, AkParagraph, AkRoundedButton } from '@ankorstore/design-system';
defineProps<{ isSelected: boolean; isFocused: boolean }>();
const emit = defineEmits(['switchActive', 'change']);
const handleClick = (event) => {
  emit('switchActive');
  emit('change', event);
};
</script>

<template>
  <AkRoundedButton
    class="like-product-button"
    :is-focused="isFocused"
    :is-selected="isSelected"
    @click="handleClick"
  >
    <template #tooltip="{ click }">
      <AkParagraph
        class="like-product-button__tooltip"
        size="base"
        @click="click"
      >
        {{ $t('discovery.product.like.button.tooltip') }}
      </AkParagraph>
    </template>
    <template #default>
      <transition name="fade">
        <AkIcon
          v-if="!isSelected"
          size="md"
          symbol="heart"
          class="like-product-button__icon"
        />
        <AkIcon
          v-else
          symbol="heart-fill"
          size="md"
          class="like-product-button__icon"
        />
      </transition>
    </template>
  </AkRoundedButton>
</template>

<style scoped lang="scss">
@use '@css/abstracts/design-tokens' as d;
.like-product-button {
  $root: &;
  &:hover {
    #{$root}__icon {
      color: d.getColor('hot-pink');
    }
  }
  &__tooltip {
    padding-right: 0.25rem;
    cursor: pointer;
    user-select: none;
    color: theme('colors.neutral.900');
    text-decoration: underline;
    &:hover {
      color: theme('colors.neutral.700');
    }
  }
  &__icon {
    position: absolute;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms;
  opacity: 1;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
