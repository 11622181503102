<template>
  <div
    class="product-counter-button"
    @click.stop.prevent
  >
    <div
      v-if="!isSucceed"
      class="product-counter-button__default"
      :class="{ loading: isLoading, disabled: isDisabled }"
      @click="$emit('updateCart')"
    >
      <SvgAddToCartIcon v-if="!isLoading" />
      <LoaderIcon
        v-else
        class="product-counter-button__loading-icon"
        small
      />
    </div>

    <div
      v-if="isSucceed"
      class="product-counter-button__success"
    >
      <AkIcon
        class="product-counter-button__success-icon"
        symbol="check"
        size="md"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from 'vue';
import LoaderIcon from '@/components/loader-icon.vue';
import SvgAddToCartIcon from '@/modules/reorder/product-card/product-quantity/svg-add-to-cart-Icon.vue';

export default defineComponent({
  name: 'ProductCounterButton',
  components: {
    LoaderIcon,
    SvgAddToCartIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateCart'],
  setup(props) {
    const isSucceed = ref(false);
    watch(
      () => props.isLoading,
      (newValue) => {
        if (!newValue) {
          isSucceed.value = true;
          setTimeout(() => {
            isSucceed.value = false;
          }, 1200);
        }
      }
    );

    return {
      isSucceed,
    };
  },
});
</script>

<style lang="scss" scoped>
.product-counter-button {
  @apply sm:ds-w-8 ds-w-full sm:ds-mt-0 ds-mt-3;
}

.product-counter-button__default {
  @apply ds-h-6 ds-text-center ds-flex ds-justify-center ds-items-center ds-bg-primary ds-rounded-full ds-cursor-pointer;
  &:hover {
    @apply ds-bg-neutral-900;
  }
}

.product-counter-button__success {
  @apply ds-rounded-full ds-text-center ds-h-6 ds-flex ds-justify-center ds-items-center ds-bg-accent;
  &-icon {
    @apply ds-text-white;
  }
}

.product-counter-button__loading {
  @apply ds-rounded-full ds-text-center ds-h-6 ds-flex ds-justify-center ds-items-center ds-bg-primary;
  &-icon {
    @apply ds-text-white;
  }
}

.loading {
  @apply ds-pointer-events-none ds-cursor-default;
}

.disabled {
  @apply ds-bg-neutral-500 ds-pointer-events-none ds-cursor-default;
}
</style>
