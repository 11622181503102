<!-- eslint-disable @intlify/vue-i18n/no-dynamic-keys -->
<template>
  <AkInput
    v-model.trim="currentModel"
    type="date"
    icon-right="calendar-date"
    :label="question.label"
    :data-testid="`qualification-question-input-${question.alias}`"
    :name="question.alias"
    :has-error="state[question.alias] && state[question.alias].$error"
    :errors="getErrors"
    :placeholder="question.placeholder"
    :required="question.required"
    :disabled="question.disabled"
    @change="handleChanges($event)"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Question } from '@/types/question';
import { State } from '@/types/state';
import { formatDate } from '@/services/date-locale';

export default defineComponent({
  name: 'QuestionDate',
  props: {
    question: {
      type: Object as () => Question,
      required: true,
    },
    state: {
      type: Object as () => State,
      required: true,
    },
  },
  emits: ['change'],
  data() {
    return {
      currentModel: '',
    };
  },
  computed: {
    getErrors() {
      return this.state[this.question.alias]?.$error ? this.$t('This field is required') : '';
    },
  },
  methods: {
    handleChanges(event) {
      const { value } = event.target;
      this.$emit('change', this.question.alias, {
        content: formatDate(value, 'dd/MM/yyyy'),
        answer_id: formatDate(value, 'dd/MM/yyyy'),
      });
    },
  },
});
</script>
