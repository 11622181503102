<template>
  <div class="ds-flex ds-h-full ds-flex-1">
    <BrandResponseForm
      v-if="step === 1"
      :message="message"
      @publish="publishResponse"
      @report="reportResponse"
    />
    <BrandResponseThanks v-if="step === 2" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { DrawerComponentName } from '@/store/drawer';
import BrandResponseForm from '@/components/drawer/drawers/brand-review-response/brand-response-form.vue';
import BrandResponseThanks from '@/components/drawer/drawers/brand-review-response/brand-response-thanks.vue';
import { mapActions, mapGetters } from 'vuex';
import { flagReviewForRemoval, postBrandAnswer } from '@/services/reviews/reviews';
import { ResponseDrawerType } from '@/store/reviews';
import { captureRetailerCoreDiscoveryException } from '@/sentry/helper';

export default defineComponent({
  name: DrawerComponentName.BrandResponseDrawer,
  components: { BrandResponseThanks, BrandResponseForm },
  data() {
    return {
      step: 1,
      message: '',
    };
  },
  computed: {
    ...mapGetters('reviews', ['currentReview', 'getMessageForReview', 'getReportForReview', 'responseDrawerType']),
  },
  created() {
    if (this.responseDrawerType === ResponseDrawerType.public) {
      this.message = this.getMessageForReview(this.currentReview.id) || '';
    } else {
      this.message = this.getReportForReview(this.currentReview.id) || '';
    }
  },
  unmounted() {
    this.setPreventClose(false);
    this.step === 2 && this.updateBrandReviewsData({ brandId: this.currentReview.brand.id });
  },
  methods: {
    ...mapActions('drawer', ['updateDrawerOptions', 'setPreventClose']),
    ...mapActions('reviews', ['clearMessage', 'clearReport', 'updateBrandReviewsData']),
    async publishResponse(message: string): Promise<void> {
      this.updateDrawerOptions({
        title: null,
        subtitle: null,
      });
      try {
        await postBrandAnswer(this.currentReview.id, message);
      } catch (error) {
        // TODO: should notify user we could not publish a response
        // see https://ankorstore.atlassian.net/browse/RET-1131
        captureRetailerCoreDiscoveryException(error, [
          { label: 'component', value: 'BrandResponseDrawer' },
          { label: 'action', value: 'Post brand answer' },
        ]);
      }
      this.clearMessage(this.currentReview.id);
      this.setPreventClose(false);
      this.step = 2;
    },

    async reportResponse(message: string): Promise<void> {
      this.updateDrawerOptions({
        title: null,
        subtitle: null,
      });
      try {
        await flagReviewForRemoval(this.currentReview.reviewable_id, message);
      } catch (error) {
        // TODO: should notify user we could not flag the review
        // see https://ankorstore.atlassian.net/browse/RET-1130
        captureRetailerCoreDiscoveryException(error, [
          { label: 'component', value: 'BrandResponseDrawer' },
          { label: 'action', value: 'Flag review' },
        ]);
      }
      this.clearReport(this.currentReview.id);
      this.setPreventClose(false);
      this.step = 2;
    },
  },
});
</script>

<style lang="scss" scoped>
.response-drawer__content {
  @apply ds-flex ds-items-center;
}
</style>
