import { Filter } from '@bc/discovery/ui/filters';
import { memoize } from 'lodash-es';

type SortItem = { name: string; isRefined: boolean; position?: number };

export const sortByAlphanumeric = (itemA: SortItem, itemB: SortItem) => {
  const startsWithNumberRegex = /^-?\d/;
  const itemAStartsWithNumber = startsWithNumberRegex.test(itemA.name);
  const itemBStartsWithNumber = startsWithNumberRegex.test(itemB.name);

  /** ** Rules ****
   * 1. Items that start with a number should come next.
   * 2. Numbered items should be sorted in numerical order.
   * 3. Lettered items should be sorted in alphabetical order.
   * */

  if (itemAStartsWithNumber && !itemBStartsWithNumber) {
    return -1;
  }

  if (!itemAStartsWithNumber && itemBStartsWithNumber) {
    return 1;
  }

  if (itemAStartsWithNumber && itemBStartsWithNumber) {
    const itemANumber = parseFloat(itemA.name);
    const itemBNumber = parseFloat(itemB.name);

    if (itemANumber < itemBNumber) {
      return -1;
    }

    if (itemANumber > itemBNumber) {
      return 1;
    }

    return 0;
  }

  return itemA.name.localeCompare(itemB.name);
};

export const sortByRefined = (itemA: SortItem, itemB: SortItem) => {
  if (itemA.isRefined && !itemB.isRefined) {
    return -1;
  }

  if (!itemA.isRefined && itemB.isRefined) {
    return 1;
  }

  return 0;
};

export const sortByPosition = (itemA: SortItem, itemB: SortItem) => {
  if (typeof itemA.position === 'number' && typeof itemB.position === 'number') {
    if (itemA.position < itemB.position) {
      return -1;
    }

    if (itemA.position > itemB.position) {
      return 1;
    }
  }

  return 0;
};

export const sort = (itemA: SortItem, itemB: SortItem) => {
  /** ** Rules ****
   * 1. Refined items should come first.
   * 2. Refined items should be sorted by position and alphanumerically.
   * 3. Unrefined items should be sorted by position and alphanumerically.
   * */

  const sortAlphabetically = sortByAlphanumeric(itemA, itemB);
  const sortRefined = sortByRefined(itemA, itemB);
  const sortPosition = sortByPosition(itemA, itemB);

  if (sortRefined === 0 && sortPosition === 0) {
    return sortAlphabetically;
  }

  if (sortRefined === 0 && sortPosition !== 0) {
    return sortPosition;
  }

  return sortRefined;
};

const createKey = (filter: Pick<Filter, 'choices'>) => filter.choices?.map((choice) => choice.name).join();
export const createSorter = memoize((filter: Pick<Filter, 'choices'>) => {
  const choices = new Map(filter.choices?.map((choice) => [choice.slug, choice]));

  return (itemA: SortItem, itemB: SortItem) =>
    sort(
      {
        ...itemA,
        position: choices.get(itemA.name)?.position,
      },
      {
        ...itemB,
        position: choices.get(itemB.name)?.position,
      }
    );
}, createKey);
