<template>
  <AisRefinementList
    v-bind="$attrs"
    class="custom-ais-refinement-list"
    :searchable="true"
    :limit="100"
  >
    <template #default="{ items, refine, searchForItems, isFromSearch }">
      <AkInput
        v-if="!hideSearchBox && (items.length > 20 || isFromSearch)"
        ref="searchBox"
        v-model="searchTerm"
        class="custom-ais-refinement-list__search-box"
        :placeholder="$t('retailer.catalog.filter.search.lbl')"
        @input="searchForItems($event)"
        @focus="searchForItems(searchTerm)"
      >
        <template #right>
          <AkIcon
            class="custom-ais-refinement-list__clear-button"
            symbol="x"
            @click="handleClearClicked(searchForItems)"
          />
        </template>
      </AkInput>
      <div class="custom-ais-refinement-list__items">
        <slot
          :refine="refine"
          :items="items"
        />
      </div>
    </template>
  </AisRefinementList>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { AisRefinementList } from 'vue-instantsearch/vue3/es';
import { AkInput, AkIcon } from '@ankorstore/design-system';

defineProps({
  hideSearchBox: {
    type: Boolean,
    default: false,
  },
});

const searchBox = ref(null);
const searchTerm = ref('');

const handleClearClicked = (searchForItems: (searchTerm: string) => void) => {
  searchTerm.value = '';
  searchForItems('');
  searchBox.value.focusInput();
};
</script>

<style scoped lang="scss">
.custom-ais-refinement-list {
  &__search-box {
    margin-bottom: 1rem;
  }
  &__clear-button {
    cursor: pointer;
  }
  &__items {
    max-height: 300px;
    overflow-y: auto;
  }
  &__expand-button {
    margin-top: 0.5rem;
  }
}
</style>
