import AnalyticsEvent from '../analytics-event';
import { CheckoutEoriError } from '../segment-ecommerce-types';

export class EoriCheckoutEntryError extends AnalyticsEvent {
  public readonly name = 'EORI Checkout Entry Error';

  constructor(public readonly properties: CheckoutEoriError) {
    super();
  }
}
