export enum SortColumns {
  shop_name = 'retailer.name',
  total_spent = 'ordering.totalSpent.amount',
  last_purchase = 'ordering.lastOrderedAt',
  eligible_lift = 'ordering.liftEligible',
  commission_fees = 'commercialTerms.serviceFees',
  personalised_discount = 'commercialTerms.brandDiscount',
  repeat_offer = 'commercialTerms.amountToUnlockRepeatOffer',
  country = 'retailer.contact.country',
}

const buildBiDirectionalMap = (enumObject: Record<string, string>) => {
  const forwardMap: Map<string, string> = new Map();
  const reverseMap: Map<string, string> = new Map();

  Object.entries(enumObject).forEach(([key, value]) => {
    forwardMap.set(key, value);
    reverseMap.set(value, key);
  });

  return { forwardMap, reverseMap };
};

const { forwardMap, reverseMap } = buildBiDirectionalMap(SortColumns);

export const getFacetFromColumn = (key: string): string | undefined => {
  return forwardMap.get(key);
};

export const getColumnFromFacet = (value: string): string | undefined => {
  return reverseMap.get(value);
};
