import type { ContactFilter } from '@bc/brands/domain';

enum CampaignStatus {
  Queued = 'queued',
}

export interface CreateCampaignMethodParams {
  campaignName: string;
  filters: ContactFilter[];
  defaultLanguage: string;
  messages: Message[];
}

export interface CreateCampaignApiParams {
  data: {
    type: 'campaign';
    attributes: {
      name: CreateCampaignMethodParams['campaignName'];
      audience: {
        filters: CreateCampaignMethodParams['filters'];
      };
      content: {
        defaultLanguage: CreateCampaignMethodParams['defaultLanguage'];
        messages: CreateCampaignMethodParams['messages'];
      };
    };
  };
}

export enum MessageType {
  Email = 'email',
}

export type Message = {
  type: MessageType;
  language: string;
  from: string;
  subject: string;
  body: string;
  footer: string;
  ctaText: string;
};

export interface CreateCampaignResponse {
  id: string;
  type: 'campaign';
  attributes: {
    name: string;
    type: string;
    status: CampaignStatus;
    audience: CreateCampaignApiParams['data']['attributes']['audience'];
    content: CreateCampaignApiParams['data']['attributes']['content'];
  };
  meta: {
    createdAt: string;
    verifiedAt?: string;
    completedAt?: string;
    report: {
      recipientCount: number;
      totalDelivered: number;
      opens: number;
      clicks: number;
      openRate: number;
      clickRate: number;
    };
  };
}

export interface CampaignsTemplatesResponse {
  attributes: CampaignsTemplatesAttributes;
  id: string;
  links: {
    self: string;
  };
  type: string;
}

export interface CampaignsTemplatesAttributes {
  id: string;
  name: string;
  description: string;
  contents: {
    body: string;
    ctaText: string;
    footer: string;
    subject: string;
    language: string;
    type: string;
  }[];
  allowedSegments: string[];
  defaultForSegments: string[];
}
