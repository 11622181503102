import type { RouteRecordRaw } from 'vue-router';
import { isEnabled } from '@/services/features';
import { adsDashboardGuard, sepaMandateAdsGuard } from '@bc/advertisement/feature/ads-dashboard/router.guard';

export const routes: RouteRecordRaw[] = [
  ...(isEnabled('MNY_115')
    ? [
        {
          path: 'brand-dashboard-ads',
          name: 'brand-dashboard-ads',
          component: () => import('./pages/ads-dashboard.vue'),
          beforeEnter: adsDashboardGuard,
        },
        {
          path: 'brand-dashboard-ads-creation',
          name: 'brand-dashboard-ads-creation',
          beforeEnter: sepaMandateAdsGuard,
          component: () => import('./pages/campaign-creation-edit.vue'),
        },
        {
          path: 'brand-dashboard-ads-edit/:campaignId',
          name: 'brand-dashboard-ads-edit',
          beforeEnter: sepaMandateAdsGuard,
          component: () => import('./pages/campaign-creation-edit.vue'),
        },
        {
          path: 'brand-dashboard-ads/:campaignId',
          name: 'ads-campaign-details',
          component: () => import('./pages/campaign-overview.vue'),
          props: true,
        },
      ]
    : []),
];
