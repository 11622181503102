import { Ref, ref, onBeforeMount, nextTick } from 'vue';
import { getCategoriesByLanguage } from '@/services/api/categories';
import useGlobals from '@/composables/use-globals';
import { SearchCategory } from '@/types/api/category';

const useCategories = () => {
  const { lang } = useGlobals();

  const categories: Ref<Record<string, SearchCategory>> = ref(null);

  const getTranslatedCategories = (id: string | number): string => {
    return categories.value?.[id].full_name;
  };

  onBeforeMount(async () => {
    try {
      const _categories = await getCategoriesByLanguage(lang);
      nextTick(() => {
        categories.value = _categories;
      });
    } catch {
      // fail silently
    }
  });

  return { categories, getTranslatedCategories };
};

export default useCategories;
