import AnalyticsEvent from '../analytics-event';

interface ClickStockAlertProps {
  id_product: string;
  id_brand: string;
  action: string;
}

export class ClickStockAlert extends AnalyticsEvent {
  public readonly name = 'user_click';
  public properties: ClickStockAlertProps;

  constructor(productId: string, brandId: string) {
    super();

    this.properties = {
      id_product: productId,
      id_brand: brandId,
      action: 'click_stock_alert',
    };
  }
}
