import type { SearchResults } from 'algoliasearch-helper';
import type { Option } from '../types/option';

export const getIdsToRefine = (selectedOptions: Option[]) => {
  const children = selectedOptions.filter((option) => !!option.parent);
  const childIds = children.map((option) => option.id);
  const parentIds = new Set(children.map((option) => option.parent));
  const parentIdsWithoutSelectedChildren = selectedOptions
    .filter((option) => !parentIds.has(option.id) && !option.parent)
    .map((option) => option.id);

  return parentIdsWithoutSelectedChildren.concat(childIds);
};

export const getFacetCounts = (
  facetName: string,
  results: Pick<SearchResults, 'facets' | 'disjunctiveFacets'>
): Record<string, number> => {
  const facet = results?.facets.find(({ name }) => name === facetName)?.data;
  const disjunctiveFacet = results?.disjunctiveFacets.find(({ name }) => name === facetName)?.data;

  if (!facet && !disjunctiveFacet) {
    return {};
  }

  return (facet ?? disjunctiveFacet) as Record<string, number>;
};

export const getSelectedOptions = (options: Option[], facetName: string, results: Pick<SearchResults, 'getRefinements'>) => {
  if (!results) {
    return [];
  }

  return results
    .getRefinements()
    .filter((refinement) => refinement.attributeName === facetName)
    .flatMap((refinement) => {
      const refinedOption = options.find((option) => option.id === refinement.name);
      const refinedOptionParent = options.find((option) => option.children.includes(refinedOption?.id));

      return [refinedOption, refinedOptionParent];
    })
    .filter(Boolean); // remove undefined values
};
