import http from '@/services/api/http';
import type { AxiosResponse } from 'axios';
import { Review, ReviewDTO, ReviewImage, ReviewStatistics } from '@/components/review/types';
import i18n from '@/services/i18n';

export const getOrderReview = async (orderId: string): Promise<Review> => {
  const { data } = await http().get<AxiosResponse<Review>>(`/api/orders/${orderId}/review`);
  return data.data;
};

export const createDraftOrderReview = async (orderId: string): Promise<Review> => {
  const { data } = await http().post<AxiosResponse<Review>>(`/api/reviews`, {
    reviewable_id: orderId,
    review_type: 'order',
  });
  return data.data;
};

export const submitReview = async (reviewDraftId: number, reviewData: ReviewDTO): Promise<Review> => {
  const { data } = await http().put<{ data: Review }>(`/api/reviews/${reviewDraftId}/submit`, reviewData);
  return data.data;
};

export const uploadReviewImage = async (reviewDraftId: number, imageFile: File): Promise<ReviewImage> => {
  const formData = new FormData();
  formData.set('image', imageFile);
  try {
    const { data } = await http().post<AxiosResponse<ReviewImage>>(`/api/reviews/${reviewDraftId}/images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data.data;
  } catch (error) {
    if (http().isAxiosError(error) && error.response.status === 422 && error.response.data?.errors?.image) {
      throw error.response.data.errors.image[0];
    }
    throw i18n.global.t('@@retailer_xp.reviews.server.errors.upload_image');
  }
};

export const deleteReviewImage = (id: number) => http().delete(`/api/reviews/images/${id}`);

export const getBrandOrderAverageStatisticsReview = async (brandId: number): Promise<ReviewStatistics | void> => {
  const { data } = await http().get<AxiosResponse<ReviewStatistics>>(`/api/brands/${brandId}/reviews/statistics`);
  return data.data;
};

export const getBrandReviews = async (brandId: number, params?: any): Promise<Review[]> => {
  const { data } = await http().get<AxiosResponse<Review[]>>(`/api/brands/${brandId}/reviews`, { params });
  return data.data || [];
};

export const postBrandAnswer = async (reviewId: number, answer: string): Promise<void> => {
  await http().post(`/api/brand/reviews/${reviewId}/answer`, {
    answer,
  });
};

export const getAllBrandReviews = async (): Promise<Review[]> => {
  const { data } = await http().get<AxiosResponse>(`/api/brand/reviews`, {
    params: { all: 1 },
  });
  return data.data;
};

export const getReviewsAndStatistics = async (brandId: number): Promise<[Review[], ReviewStatistics | void]> => {
  return [await getBrandReviews(brandId, { all: 1 }), await getBrandOrderAverageStatisticsReview(brandId)];
};

export const flagReviewForRemoval = async (orderId: string, reason: string): Promise<void> => {
  await http().post(`/api/brand/orders/${orderId}/review/report`, {
    reason,
  });
};
