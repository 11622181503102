import AnalyticsEvent from '@/services/analytics/events/analytics-event';
import { CatalogFilterEventName, CatalogFiltersEventProperties } from '@/services/analytics/properties/catalog-filters-property';

export class CatalogFiltersEvent extends AnalyticsEvent {
  public name: CatalogFilterEventName;
  public properties: CatalogFiltersEventProperties;

  constructor({ name, properties }: { name: CatalogFilterEventName; properties: CatalogFiltersEventProperties }) {
    super();
    this.name = name;
    this.properties = properties;
  }
}
