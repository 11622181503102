import AnalyticsEvent from '../analytics-event';
import { CartItemRemoved } from '../segment-ecommerce-types';

export class CartItemRemovedDueToMandatoryEori extends AnalyticsEvent {
  public readonly name = 'Product Removed from Cart due to Mandatory EORI';

  constructor(public readonly properties: CartItemRemoved) {
    super();
  }
}
