<template>
  <div
    v-if="content && content.header_text && content.header_text.length"
    class="catalog-header-text"
    data-testid="catalog-header-text"
  >
    <AkParagraph size="sm">
      {{ catalogHeaderText }}
      <AkButton
        v-if="hasLongHeaderText"
        link
        class="catalog-header-text__button"
        data-testid="toggle-header-text-button"
        @click="toggleHeaderText"
      >
        {{ toggleLinkText }}
      </AkButton>
    </AkParagraph>
  </div>
</template>

<script lang="ts">
import type { CatalogContent } from '@bc/discovery/domain/catalog';
import { defineComponent } from 'vue';
import { AkParagraph, AkButton } from '@ankorstore/design-system';

export default defineComponent({
  name: 'CatalogHeaderText',
  components: {
    AkParagraph,
    AkButton,
  },
  props: {
    content: {
      type: Object as () => CatalogContent,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      isCatalogHeaderTextExpanded: false,
    };
  },
  computed: {
    hasLongHeaderText(): boolean {
      return this.content?.header_text[0]['text']?.length > 250;
    },
    ellipsisText(): string {
      return this.hasLongHeaderText ? '...' : '';
    },
    catalogHeaderText(): string {
      return this.isCatalogHeaderTextExpanded
        ? this.content?.header_text[0]['text']
        : this.content?.header_text[0]['text']?.substr(0, 250) + this.ellipsisText;
    },
    toggleLinkText(): string {
      return this.isCatalogHeaderTextExpanded ? this.$t('Read less') : this.$t('Read more');
    },
  },
  methods: {
    toggleHeaderText(): void {
      this.isCatalogHeaderTextExpanded = !this.isCatalogHeaderTextExpanded;
    },
  },
});
</script>

<style lang="scss" scoped>
.catalog-header-text {
  display: flex;
  flex-wrap: wrap;

  &__button {
    height: 1.25rem;
  }
}
</style>
