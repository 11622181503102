<template>
  <div v-if="state">
    <slot
      :items="items"
      :state="computedState"
      :status="status"
      :error="error"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { createWidgetMixin } from 'vue-instantsearch/vue3/es';
import { connectHits } from 'instantsearch.js/cjs/connectors';
import { Hit } from 'instantsearch.js';

export default defineComponent({
  name: 'AisCustomHits',
  mixins: [createWidgetMixin({ connector: connectHits }, { $$widgetType: 'ais.custom-hits' })],
  props: {
    escapeHTML: {
      type: Boolean,
      default: true,
    },
    transformItems: {
      type: Function,
      default: undefined,
    },
  },
  computed: {
    items() {
      return this.state.hits as Hit[];
    },
    computedState() {
      return {
        page: this.state.instantSearchInstance.helper.state.page,
        query: this.state.instantSearchInstance.helper.state.query,
      };
    },
    status() {
      return this.state.instantSearchInstance.status;
    },
    error() {
      return this.state.instantSearchInstance.error;
    },
    widgetParams() {
      return {
        escapeHTML: this.escapeHTML,
        transformItems: this.transformItems,
      };
    },
  },
});
</script>
