import { QuestionType } from '@/types/question';
import { QualificationQuestion } from '@/types/registration-funnel-questions';
import i18n from '@/services/i18n';

export const first_name: QualificationQuestion = {
  question_type: QuestionType.Free,
  alias: 'first_name',
  required: true,
  label: i18n.global.t('First name'),
  isRegister: true,
};

export const last_name: QualificationQuestion = {
  question_type: QuestionType.Free,
  alias: 'last_name',
  required: true,
  label: i18n.global.t('Last name'),
  isRegister: true,
};

export const password: QualificationQuestion = {
  question_type: QuestionType.Password,
  alias: 'password',
  required: true,
  label: '',
  isRegister: true,
};

export const vat_number: QualificationQuestion = {
  question_type: QuestionType.Free,
  alias: 'vat_number',
  required: true,
  label: '',
  isRegister: true,
};

export const ankorstart: QualificationQuestion = {
  question_type: QuestionType.Radio,
  alias: 'ankorstart',
  required: true,
  isRegister: false,
  label: 'What type of retailer are you?',
  answers: [
    {
      type: 'retailer_qualification_answer',
      id: 66,
      answer_type: 'fixed_value',
      alias: 'ankorstart_no',
      label: 'I already operate a shop or e-shop',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 60,
      answer_type: 'fixed_value',
      alias: 'ankorstart_yes',
      label: 'I plan to open a new shop or e-shop',
      prefilled_content: null,
    },
  ],
};

export const search_company: QualificationQuestion = {
  question_type: QuestionType.CompanySearch,
  alias: 'company_search',
  required: false,
  isRegister: false,
  label: 'Registered name of company',
  answers: [
    {
      type: 'retailer_qualification_answer',
      id: 65,
      answer_type: 'company_search',
      alias: 'company_search',
      label: 'company search',
      prefilled_content: null,
    },
  ],
};

export const country_select: QualificationQuestion = {
  question_type: QuestionType.CountrySelect,
  alias: 'country_id',
  required: true,
  label: i18n.global.t('Select country'),
  isRegister: true,
};

export const phone: QualificationQuestion = {
  question_type: QuestionType.Phone,
  alias: 'phone_number',
  required: true,
  label: i18n.global.t('Phone number'),
  isRegister: true,
};

export const address: QualificationQuestion = {
  question_type: QuestionType.GoogleMapAutocomplete,
  alias: 'address',
  required: true,
  label: i18n.global.t('Address'),
  isRegister: true,
};

export const shop_activity: QualificationQuestion = {
  question_type: QuestionType.SingleChoiceDropdown,
  alias: 'shop_activity',
  required: true,
  label: 'Shop activity',
  isRegister: false,
  answers: [
    {
      type: 'retailer_qualification_answer',
      id: 1,
      answer_type: 'fixed_value',
      alias: 'grocery',
      label: 'Grocery / Gourmet Store',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 53,
      answer_type: 'fixed_value',
      alias: 'travel_agency',
      label: 'Travel agency',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 17,
      answer_type: 'text',
      alias: 'other',
      label: 'Other',
      prefilled_content: null,
    },
  ],
};

export const online_presence: QualificationQuestion = {
  question_type: QuestionType.SingleChoiceDropdown,
  alias: 'online_presence',
  required: true,
  label: 'Shop type',
  isRegister: false,
  answers: [
    {
      type: 'retailer_qualification_answer',
      id: 18,
      answer_type: 'fixed_value',
      alias: 'physical_shop_only',
      label: 'Physical only',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 19,
      answer_type: 'fixed_value',
      alias: 'physical_shop_and_website',
      label: 'Physical and Online',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 20,
      answer_type: 'fixed_value',
      alias: 'website_only',
      label: 'Online only',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 59,
      answer_type: 'fixed_value',
      alias: 'not_retailer',
      label: "I don't resell to customers",
      prefilled_content: null,
    },
  ],
};

export const shop_age: QualificationQuestion = {
  question_type: QuestionType.SingleChoiceDropdown,
  alias: 'shop_age',
  required: true,
  label: 'When did you open your store?',
  isRegister: false,
  answers: [
    {
      type: 'retailer_qualification_answer',
      id: 28,
      answer_type: 'fixed_value',
      alias: 'less_than_1y',
      label: 'Less than a year',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 29,
      answer_type: 'fixed_value',
      alias: 'less_than_5y',
      label: 'Less than 5 years',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 30,
      answer_type: 'fixed_value',
      alias: 'more_than_5y',
      label: 'More than 5 years',
      prefilled_content: null,
    },
  ],
};

export const opening_date: QualificationQuestion = {
  question_type: QuestionType.SingleChoiceDropdown,
  alias: 'opening_date',
  required: true,
  label: 'Expected opening date of your shop',
  isRegister: false,
  answers: [
    {
      type: 'retailer_qualification_answer',
      id: 64,
      answer_type: 'fixed_value',
      alias: 'coming_month',
      label: 'During the coming month',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 65,
      answer_type: 'fixed_value',
      alias: 'next_six_months',
      label: 'In the next 6 months',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 66,
      answer_type: 'fixed_value',
      alias: 'more_than_6_months',
      label: 'In more than 6 months',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 67,
      answer_type: 'fixed_value',
      alias: 'does_not_know',
      label: "I don't know yet",
      prefilled_content: null,
    },
  ],
};

export const restock_frequency: QualificationQuestion = {
  question_type: QuestionType.SingleChoiceDropdown,
  alias: 'restock_frequency',
  required: true,
  label: 'How often do you buy stock?',
  isRegister: false,
  answers: [
    {
      type: 'retailer_qualification_answer',
      id: 50,
      answer_type: 'fixed_value',
      alias: 'weekly',
      label: 'Weekly',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 52,
      answer_type: 'fixed_value',
      alias: 'monthly',
      label: 'Monthly',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 53,
      answer_type: 'fixed_value',
      alias: 'seasonally',
      label: 'Seasonally',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 54,
      answer_type: 'fixed_value',
      alias: 'ad_hoc',
      label: 'Ad hoc',
      prefilled_content: null,
    },
  ],
};

export const heard_about_ankorstore: QualificationQuestion = {
  question_type: QuestionType.SingleChoiceDropdown,
  alias: 'heard_about_ankorstore',
  required: true,
  label: 'How did you hear about us?',
  isRegister: false,
  answers: [
    {
      type: 'retailer_qualification_answer',
      id: 41,
      answer_type: 'fixed_value',
      alias: 'from_ankorstore_commercial',
      label: 'A representative in my shop',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 42,
      answer_type: 'fixed_value',
      alias: 'from_ankorstore_call',
      label: 'A call from Ankorstore',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 31,
      answer_type: 'fixed_value',
      alias: 'from_brand',
      label: 'A brand I work with',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 32,
      answer_type: 'fixed_value',
      alias: 'received_voucher',
      label: 'I received an Ankorstore voucher',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 33,
      answer_type: 'fixed_value',
      alias: 'from_facebook',
      label: 'Facebook / Instagram',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 34,
      answer_type: 'fixed_value',
      alias: 'from_other_social_network',
      label: 'Other social networks (Pinterest, Linkedin...)',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 35,
      answer_type: 'fixed_value',
      alias: 'from_ankorstore_email',
      label: 'Email from Ankorstore',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 36,
      answer_type: 'fixed_value',
      alias: 'from_word_of_mouth',
      label: 'Word of mouth',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 43,
      answer_type: 'fixed_value',
      alias: 'from_google',
      label: 'Google',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 44,
      answer_type: 'fixed_value',
      alias: 'from_tradeshow',
      label: 'Tradeshow',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 49,
      answer_type: 'fixed_value',
      alias: 'outdoor_advertising',
      label: 'Outdoor advertising',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 46,
      answer_type: 'fixed_value',
      alias: 'ankorstore_magazine',
      label: 'Mary Portas partnership',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 47,
      answer_type: 'fixed_value',
      alias: 'ankorstore_event',
      label: 'Ankorstore event',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 48,
      answer_type: 'fixed_value',
      alias: 'webinar',
      label: 'Webinar',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 37,
      answer_type: 'fixed_value',
      alias: 'from_news',
      label: 'News (press, TV, radio) ',
      prefilled_content: null,
    },
    {
      type: 'retailer_qualification_answer',
      id: 38,
      answer_type: 'text',
      alias: 'other',
      label: 'Other',
      prefilled_content: null,
    },
  ],
};

const promocode: QualificationQuestion = {
  question_type: QuestionType.Promocode,
  alias: 'lift_code',
  required: false,
  label: i18n.global.t('Promocode (Optional)'),
  isRegister: true,
};

export const website: QualificationQuestion = {
  question_type: QuestionType.Website,
  alias: 'website',
  required: false,
  label: i18n.global.t('Website (optional)'),
  isRegister: false,
};

export const store_name: QualificationQuestion = {
  question_type: QuestionType.Free,
  alias: 'store_name',
  required: true,
  label: i18n.global.t('Website (optional)'),
  isRegister: true,
};

export const qualificationQuestionsMocks = {
  data: [first_name, last_name, password, country_select, phone, address, promocode],
};
