import { Address } from '@/types/address';
import { CountryIsoCode } from '@/types/country';
import { JsonApiResponse } from '@/types/fulfillment';
import { Currency } from 'dinero.js';

export enum MenuOptions {
  /** @deprecated - used only as redirect route for new separated address and shipping pages. */
  ADDRESSES_AND_SHIPPING = 'brandSettingsAddressesAndShipping',
  ADDRESSES = 'brandSettingsAddresses',
  SHIPPING = 'brandSettingsShipping',
  SHOP_SETTINGS = 'brandSettingsShopSettings',
  COMPANY_DETAILS = 'brandSettingsCompanyDetails',
  COMMUNICATIONS = 'brandSettingsCommunications',
  PASSWORD = 'brandSettingsPassword',
  TWO_FACTOR = 'brandSettingsTwoFactor',
  BPG = 'brandSettingsBestPriceGuarantee',
}

export type MenuOptionsLiteral = `${Exclude<MenuOptions, MenuOptions.ADDRESSES_AND_SHIPPING>}`;

export type MenuEntryType = {
  label: string;
  enabled: boolean;
  routeName: string;
  alert?: boolean;
  stepNumber?: number;
  stepCompleted?: boolean;
  tip?: string;
};

export type MenuEntry = {
  [key in MenuOptionsLiteral]?: MenuEntryType;
};

export enum CountryIso {
  UnitedKingdom = 'GB',
  Germany = 'DE',
}

export enum AddressType {
  Billing = 'billingAddress',
  Public = 'publicAddress',
  Shipping = 'shippingAddress',
}

export interface AddressApiPayload {
  street: string;
  postalCode: string;
  city: string;
  countryCode: CountryIsoCode;
}

export interface BrandShippingSettingsApiPayload {
  averageLeadTime?: string;
  [AddressType.Billing]?: AddressApiPayload;
  [AddressType.Public]?: AddressApiPayload;
  [AddressType.Shipping]?: AddressApiPayload;
  whitelistCountries?: string[];
  eoriNumber?: string;
  lucidNumber?: string;
}

export type UpdateBrandShippingFeeSettingApiPayload = Pick<BrandShippingFeeSetting, 'fee' | 'amount' | 'corridor'>;

export interface BrandShippingSettingsApiResponse {
  averageLeadTime: string;
  [AddressType.Billing]: AddressApiPayload;
  [AddressType.Public]: AddressApiPayload;
  [AddressType.Shipping]: AddressApiPayload;
  whitelistCountries: string[];
  eoriNumber: string;
  lucidNumber: string;
}

export interface BrandHolidaySettingsApiPayload {
  startDate?: Date;
  endDate?: Date;
}
export interface BrandHolidaySettingsApiResponse {
  startDate?: Date;
  endDate?: Date;
}

export const eu8Countries = ['FR', 'GB', 'DE', 'ES', 'IT', 'NL', 'BE', 'AT'];
export type BrandShippingFeeCorridor = 'domestic' | 'eu8' | 'international';

export interface BrandShippingFeeOption {
  amount: number;
  currency: Currency;
  isSpecialDeal: boolean;
  isDefault: boolean;
}

export interface BrandShippingFeeSetting {
  id: string;
  amount: number;
  corridor: BrandShippingFeeCorridor;
  currency: Currency;
  fee: number;
  type: 'franco-settings';
  meta: {
    amountOptions: BrandShippingFeeOption[];
    feeOptions: BrandShippingFeeOption[];
  };
}

export type BrandShippingFeeSettingsApiResponse = JsonApiResponse<BrandShippingFeeSetting[]>;
export type BrandShippingFeeSettingOptionsApiResponse = JsonApiResponse<BrandShippingFeeOption[]>;

export interface AddressesFormState {
  [AddressType.Billing]: AddressApiPayload;
  [AddressType.Public]: AddressApiPayload;
  [AddressType.Shipping]: AddressApiPayload;
  hasPublicAddress: boolean;
  hasShippingAddress: boolean;
}

export interface ShippingFormState {
  shippingFeeSettings: BrandShippingFeeSetting[];
  averageLeadTime: string;
  whitelistCountries: string[];
  eoriNumber: string;
  lucidNumber: string;
}

type PayloadKeys = keyof BrandShippingSettingsApiPayload;
export type BrandShippingSettingsApiErrors = { [Key in PayloadKeys]: string[] };

export interface AddressApiResourceResponse {
  shipping_address: Address;
  display_address: Address;
  billing_address: Address;
}

export enum DeliveryZone {
  Everywhere = 'everywhere',
  BrandCountry = 'brand_country',
  Custom = 'custom',
}

export interface CommunicationsConsentState {
  contact_opt_in: boolean | null;
  contact_opt_in_updated_at?: Date | null;
}

export interface BrandBestPriceEnrollmentStatus {
  created_at: string;
  updated_at: string;
  enrolled: boolean;
}

export interface BrandBestPriceEnrollmentPayload {
  enrolled: boolean;
}

export interface BrandCompanyDetailsApiBase {
  founded_in_year: number;
  company_name: string;
  tax_number: string;
  company_invoice_description: string;
  vat_number: string;
  vat_exemption: boolean;
  above_intracomm_threshold: boolean;
}

export interface BrandCompanyDetailsApiPayload extends BrandCompanyDetailsApiBase {
  is_sole_trader?: boolean;
  sole_trader_number?: string;
}

export interface BrandCompanyDetailsApiResponse extends BrandCompanyDetailsApiBase {
  sole_trader_number: string;
}

export interface CompanyDetailsFormData {
  founded_in_year: number;
  company_name: string;
  tax_number: string;
  vat_number: string;
  vat_exemption: boolean;
  legal_form: string;
  registration_city: string;
  above_intracomm_threshold: boolean;
  is_sole_trader: boolean;
  sole_trader_number: string;
}
