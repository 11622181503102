<template>
  <div id="email-header">
    <div id="email-header-partner">
      <img
        ref="roundedImg"
        alt="brand_logo"
        class="img-fluid"
        width="50"
        :src="'https://' + $cdn + images.rounded + '?auto=compress&fm=pjpg&w=50&dpr=2'"
      />
      <img
        alt="ankorstore_cross"
        class="img-fluid"
        src="/images/cross.svg"
        width="15"
      />
      <img
        alt="ankorstore_logo"
        class="img-fluid"
        src="/images/logo/logo-white.svg"
        width="120"
      />
    </div>
    <img
      ref="largeImg"
      alt="brand_banner"
      class="img-fluid"
      :src="'https://' + $cdn + images.large + '?auto=compress&fm=pjpg&w=100%&dpr=2'"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CreateCampaignsEmailHeader',
  props: {
    images: {
      type: Object,
      required: true,
      default: null,
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

#email-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

#email-header > img {
  max-height: 130px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}

#email-header-partner {
  @apply ds-bg-primary;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#email-header-partner > img {
  margin-bottom: 0.5rem;
}
</style>
