<template>
  <Card
    :class="{
      'product-snippet': true,
      'has-checkbox': withCheckbox,
      'product-snippet--full': fullWidth,
      'product-snippet--sponsored': isSponsored,
    }"
    data-testid="productSnippetCard"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <template #topLeftActions>
      <div
        v-if="withCheckbox"
        class="checkbox-form"
      >
        <AkCheckbox
          :id="'checkbox-' + item.id"
          :checked="item.isChecked"
          :value="`${item.id}`"
          @change="onChecked(item)"
        />
      </div>

      <QuickAddToCart
        v-else-if="
          (isMobile || isXlMobile || isTablet || isXlTablet) && withQuickAddToCart && hasPriceBreakdown && canHaveQuickCart
        "
        :key="item.id"
        :index="index"
        :page-number="pageNumber"
        :product="item"
        :price-breakdown="priceBreakdown"
        :parent-section-id="parentSectionId"
        :parent-component="parentComponent"
        :is-sponsored="isSponsored"
        mobile
        data-testid="qatc-mobile"
        @clicked-add-to-cart="openQuickAddToCartModal"
        @clicked="emit('quickAddToCartClicked', item)"
      />

      <StockAlertBtn
        v-else-if="isRestockAlertBtnVisible"
        class="product-snippet__stock-alert-btn-mobile"
        mobile
        :is-loading="isStockAlertLoading"
        :stock-alert="productVariantStockAlert"
        data-testid="productSnippetStockAlertBtnMobile"
        @set-stock-alert="onSetStockAlert"
      />
    </template>

    <template #topRightActions>
      <div class="product-snippet__cta-buttons">
        <LikeButton
          v-if="canHaveFavourite"
          :id="item.id"
          class="ds-justify-self-end"
          type="product"
          data-testid="favourite-product"
          :additional-tracking-data="additionalLikeTrackingData"
          :with-tooltip="true"
          :is-focused="isHovered"
        />
        <SimilarProductsButton
          v-if="$isEnabled('RET-3124') && withSimilarProductsDrawerButton"
          :is-focused="isHovered"
          @click="$emit('similarProductsClicked')"
        />
      </div>
    </template>

    <template #middleActions>
      <QuickAddToCart
        v-if="showQuickAdd"
        :key="'desktop-' + item.id"
        class="product-snippet__qatc"
        :index="index"
        :page-number="pageNumber"
        :product="item"
        :price-breakdown="priceBreakdown"
        :parent-section-id="parentSectionId"
        :parent-component="parentComponent"
        :is-sponsored="isSponsored"
        data-testid="qatc-desktop"
        @clicked-add-to-cart="openQuickAddToCartModal"
        @clicked="$emit('quickAddToCartClicked', item)"
      />
      <div
        v-else-if="isRestockAlertBtnVisible"
        class="product-snippet__stock-alert"
      >
        <StockAlertBtn
          class="product-snippet__stock-alert-btn"
          :is-loading="isStockAlertLoading"
          :stock-alert="productVariantStockAlert"
          btn-size="md"
          :multiline="true"
          data-testid="productSnippetStockAlertBtnDesktop"
          @set-stock-alert="onSetStockAlert"
        />
      </div>
    </template>

    <template #topContent>
      <ProductSnippetImage
        class="product-snippet__image"
        :can-get-wholesale-price="canGetWholesalePrice"
        :item="item"
        :index="index"
        :page-number="pageNumber"
        :price-breakdown="priceBreakdown"
        :brand-discount-origin="brandDiscountOrigin"
        :discount-type="item.discount_type"
        :offer-discount-origin="appliedDiscount"
        :with-quick-add-to-cart="withQuickAddToCart"
        :with-best-seller-badge="withBestSellerBadge"
        :with-new-badge="withNewBadge"
      />
    </template>

    <template #bottomContent>
      <ProductProperties
        v-if="hasPriceBreakdown"
        :retail-price="retail_price"
        :original-wholesale-price="original_wholesale_price"
        :can-get-wholesale-price="canGetWholesalePrice"
        :user-is-retailer="userIsRetailer"
        :user="user"
        :brand-discount-origin="brandDiscountOrigin"
        :offer-discount-origin="appliedDiscount"
        :price-breakdown="priceBreakdown"
        :item="item"
        :with-link-to-f-p="withLinkToFP"
        :with-brand-link="withBrandLink"
        :is-sponsored="isSponsored"
        @handle-click-product="handleClickProduct"
        @handle-brand-click="handleBrandClick"
        @ads-tooltip-clicked="handleAdsTooltipClicked"
      />

      <Teleport to="body">
        <AkModal
          v-if="item.variants && withQuickAddToCart && hasPriceBreakdown"
          ref="quickAddToCartModal"
          size="xl"
          :with-padding="false"
          data-testid="addToCartModal"
        >
          <AddToCartPopin
            :product-id="item.id"
            :price-breakdown="priceBreakdown"
            @add-to-cart-variant="handleVariantAddToCart"
          />
        </AkModal>
      </Teleport>
    </template>
  </Card>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from 'vue';
import { mapActions } from 'vuex';
import { loginPopinMixin } from '@/mixins/login-popin';
import { sitewideOfferMixin } from '@/mixins/sitewide-offer';
import Product, { PriceBreakdown, ProductDiscount, ProductHit, ProductHitBrand } from '@/types/product';
import { Brand } from '@/types/api/brand';
import { getHighestDiscount } from '@/services/product';
import { LinkRoutingMixin } from '@/mixins/link-routing';
import { getDiscount, getPriceBreakdown } from '@/services/utils/tooling';
import { isProductMultiple, isProductOutOfStock, isPreorder } from '@/services/product-variants';
import { AdditionalLikeTrackingData } from '@/types/analytics/recommendation-tracking';
import { AkCheckbox, AkModal } from '@ankorstore/design-system';
import { useEventBus, getSectionId } from '@bc/shared';
import LikeButton from '@/components/like-button.vue';
import Card from '@/modules/cards/card.vue';
import QuickAddToCart from '@/components/cart/quick-add-to-cart.vue';
import AddToCartPopin from '@/components/popins/add-to-cart-popin.vue';
import ProductProperties from '@/components/product/sub-components/product-properties.vue';
import ProductSnippetImage from '@/components/product/sub-components/product-snippet-image.vue';
import Analytics from '@/services/analytics';
import { ProductQuickAddToCartEvent } from '@/services/analytics/events/product-quick-added-to-cart.event';
import { ProductProperty } from '@/services/analytics/properties/product-property';
import { DineroObject } from 'dinero.js';
import { withBreakpoints } from '@/modules/design-system-candidates';
import { trackAdsProductTooltipClick } from '@bc/advertisement';
import StockAlertBtn from '@/modules/reorder/stock-status/stock-alert-btn.vue';
import { useStockStatus } from '@/modules/reorder/stock-status/composables/stock-status';
import usePrice from '@/composables/use-price';
import {
  type ESProductHit,
  type ESSponsoredProductHit,
  trackBrandClickedInProductTileSegment,
  DiscoveryTrackingEvents,
} from '@bc/discovery';
import rootStoreHelpers from '@/store/helpers';
import offersStoreHelpers from '@/store/offers/helpers';

// eslint-disable-next-line dependency-cruiser/errors
import SimilarProductsButton from '@bc/discovery/ui/recommendation/similar-products-button/similar-products-button.vue';

export default defineComponent({
  name: 'ProductSnippet',
  components: {
    StockAlertBtn,
    AkCheckbox,
    ProductProperties,
    ProductSnippetImage,
    LikeButton,
    QuickAddToCart,
    Card,
    AkModal,
    AddToCartPopin,
    SimilarProductsButton,
  },
  mixins: [loginPopinMixin, sitewideOfferMixin, LinkRoutingMixin, withBreakpoints],
  props: {
    index: {
      type: Number,
      required: false,
      default: undefined,
    },
    pageNumber: {
      type: Number,
      required: false,
      default: undefined,
    },
    item: {
      type: Object as PropType<
        | Product
        | (ProductHit & { categoryName?: string })
        | (Product & { isChecked: boolean })
        | ESProductHit
        | ESSponsoredProductHit
      >,
      required: true,
    },
    withQuickAddToCart: {
      type: Boolean,
      default: true,
    },
    withLinkToFP: {
      type: Boolean,
      default: false,
    },
    withFavoriteBtn: {
      type: Boolean,
      default: true,
    },
    withBrandLink: {
      type: Boolean,
      default: false,
    },
    withCheckbox: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      required: false,
      default: null,
    },
    productBrand: {
      type: Object as PropType<Brand | ProductHitBrand>,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    withBestSellerBadge: {
      type: Boolean,
      default: true,
    },
    withNewBadge: {
      type: Boolean,
      default: true,
    },
    withSimilarProductsDrawer: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: Object,
      default: null,
    },
    additionalLikeTrackingData: {
      type: Object as PropType<AdditionalLikeTrackingData>,
      default: null,
    },
    parentSectionId: {
      type: String as PropType<string>,
      default: '',
    },
    parentComponent: {
      type: String as PropType<string>,
      default: null,
    },
    isSponsored: {
      type: Boolean,
      default: false,
    },
    // We introduce this prop to avoid emitting events on the event bus for the data recommendation component
    // This is a temporary solution until we refactor event handling in the product snippet component
    // Ticket here : https://ankorstore.atlassian.net/browse/RLO-1331
    disabledEventBus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['checked', 'clicked', 'brandClicked', 'similarProductsClicked', 'quickAddToCartClicked'],
  setup(props) {
    // we noop function if disabledEventBus is true to avoid manipulate or change old function with multiple condition to encapsulated function
    const { eventBus } = props.disabledEventBus ? { eventBus: { emit: () => {}, tryEmit: () => {} } } : useEventBus();
    const { createStockAlert, isStockAlertLoading, productVariantStockAlert } = useStockStatus();
    const { getConvertedPrice } = usePrice();
    const { user, userIsRetailer, canGetWholesalePrice, hasUnhandledCountry, retailer, productByOptionId, userCurrency } =
      rootStoreHelpers.useGetters([
        'user',
        'userIsRetailer',
        'canGetWholesalePrice',
        'hasUnhandledCountry',
        'retailer',
        'productByOptionId',
        'userCurrency',
      ]);
    const { b2rEligibility, welcomeOffer, appliedDiscount } = offersStoreHelpers.useGetters([
      'b2rEligibility',
      'welcomeOffer',
      'appliedDiscount',
    ]);

    const isHovered = ref(false);

    function handleAdsTooltipClicked() {
      trackAdsProductTooltipClick(props.item, props.parentSectionId, props.pageNumber, props.index);

      window.location.assign('/account/brand-dashboard-ads');
    }

    const withSimilarProductsDrawerButton = computed(() => props.withSimilarProductsDrawer && userIsRetailer.value);

    return {
      isHovered,
      eventBus,
      handleAdsTooltipClicked,
      isPreorder,
      createStockAlert,
      isStockAlertLoading,
      productVariantStockAlert,
      getConvertedPrice,
      user,
      userIsRetailer,
      canGetWholesalePrice,
      hasUnhandledCountry,
      retailer,
      productByOptionId,
      userCurrency,
      b2rEligibility,
      welcomeOffer,
      appliedDiscount,
      withSimilarProductsDrawerButton,
    };
  },
  data() {
    return {
      original_wholesale_price: { amount: 0, currency: 'EUR', precision: 0 } as DineroObject,
      wholesale_price: { amount: 0, currency: 'EUR', precision: 0 } as DineroObject,
      retail_price: { amount: 0, currency: 'EUR', precision: 0 } as DineroObject,
      discount_event_price: { amount: 0, currency: 'EUR', precision: 0 } as DineroObject,
      priceBreakdown: {} as PriceBreakdown,
      observer: null,
    };
  },
  computed: {
    hasPriceBreakdown() {
      return Object.keys(this.priceBreakdown).length !== 0;
    },
    brandDiscount(): number | null {
      return this.item.discount_rate ?? getHighestDiscount(this.item as ProductHit);
    },
    offerDiscount(): number | null {
      const offer = this.sitewideOfferContent['product-tile-offer-badge']?.content;
      if (typeof offer === 'string') {
        return parseInt(offer.replace(/(^-)|(%$)/g, ''));
      }
      return null;
    },
    brandDiscountOrigin(): ProductDiscount {
      return getDiscount(this.brandDiscount, 'brand');
    },
    canHaveFavourite(): boolean {
      return this.user && this.userIsRetailer && this.$isEnabled('like_products_and_brands') && this.withFavoriteBtn;
    },
    canHaveQuickCart(): boolean {
      return isPreorder(this.item)
        ? this.userCurrency === 'EUR' && this.withQuickAddToCart && this.canGetWholesalePrice && !isProductOutOfStock(this.item)
        : this.withQuickAddToCart && this.canGetWholesalePrice && !isProductOutOfStock(this.item);
    },
    sectionId(): string {
      return getSectionId({ sectionType: 'product_snippet', valueProposition: `product_${this.item.id}` });
    },
    isRestockAlertBtnVisible(): boolean {
      return this.withQuickAddToCart && isProductOutOfStock(this.item);
    },
    showQuickAdd(): boolean {
      return (
        (this.isDesktop || this.isXlDesktop) &&
        this.canHaveQuickCart &&
        (!isPreorder(this.item) || (isPreorder(this.item) && this.$isEnabled('oxp-1484-r3')))
      );
    },
  },
  watch: {
    variant() {
      this.update();
    },
    appliedDiscount() {
      this.update();
    },
  },
  created() {
    this.update();
  },
  methods: {
    ...mapActions('shop', ['storeBrand']),
    openQuickAddToCartModal(): void {
      (this.$refs.quickAddToCartModal as DS['AkModal']).openModal();
    },
    handleClickProduct(event): void {
      this.storeBrand({ brand: this.item.brand });
      this.$emit('clicked', { product: this.item, event });
    },
    async handleBrandClick() {
      this.$emit('brandClicked', { product: this.item });
      let component;
      if (this.isSponsored) {
        component = 'native_ads';
      } else if (this.parentComponent) {
        component = this.parentComponent;
      } else {
        component = `${this.$route?.name}_page`;
      }
      const id_section = this.parentSectionId || this.sectionId;
      const properties = {
        brandId: this.item.brand.id,
        position: this.index,
        page: this.pageNumber,
        component,
        id_section,
        productId: this.item.id,
      };
      const legacyTracking = () => {
        trackBrandClickedInProductTileSegment({
          ...properties,
          component,
          id_section,
        });
      };
      this.eventBus.tryEmit(DiscoveryTrackingEvents.PRODUCT_SNIPPET_BRAND_CLICKED, legacyTracking, {
        ...properties,
        product: this.item,
        isSponsoredTile: this.isSponsored,
      });
    },
    async update() {
      let { original_wholesale_price, wholesale_price, retail_price } = this.item;
      if (isProductMultiple(this.item) && this.variant) {
        const priceHolder = (this.variant || this.item.variants[0]).price;
        original_wholesale_price = wholesale_price = priceHolder.wholesale_price;
        retail_price = priceHolder.retail_price;
      }

      this.original_wholesale_price = await this.getConvertedPrice(original_wholesale_price);
      this.retail_price = await this.getConvertedPrice(retail_price);
      this.wholesale_price = await this.getConvertedPrice(wholesale_price);

      this.priceBreakdown = getPriceBreakdown(
        this.original_wholesale_price,
        this.retail_price,
        this.wholesale_price,
        this.brandDiscountOrigin,
        this.appliedDiscount
      );
    },
    async handleVariantAddToCart(payload): Promise<void> {
      const product = this.productByOptionId(payload.option.id);
      if (product) {
        const productProperty = {
          id: product.id,
          name: product.name,
          wholesalePrice: product.wholesale_price,
          option: payload.option,
          variant: payload.variant,
        } as ProductProperty;
        const brandProperty = payload.brand;
        const page = this.pageNumber;
        const quantity = payload.quantity * product.unit_multiplier;
        const legacyTracking = () => {
          Analytics.track(new ProductQuickAddToCartEvent(productProperty, brandProperty, page, quantity, this.sectionId));
        };
        this.eventBus.tryEmit(DiscoveryTrackingEvents.QUICK_ADD_TO_CART_PRODUCT_ADDED, legacyTracking, {
          productProperty,
          brandProperty,
          page,
          position: this.index,
          quantity,
          sectionId: this.parentSectionId || this.sectionId,
        });
      }
    },
    onChecked(item): void {
      this.$emit('checked', item);
    },
    onSetStockAlert(): void {
      this.createStockAlert(this.item.id, this.item.brand.id, this.item.variants?.[0]?.id);
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';
.product-snippet {
  $baseClass: &;
  &__qatc {
    $elementWidth: calc(10 / 12 * 100%);
    transition: opacity 100ms;
    width: $elementWidth;
    bottom: 46%;
    position: absolute;
    left: calc(50% - (#{$elementWidth} / 2));
    opacity: 80%;
    &:not(.QATC--with-feedback) {
      @apply ds-hidden;
    }
  }
  &__cta-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
  }
  &__stock-alert {
    @apply ds-hidden ds-relative ds-bottom-1/2 ds-justify-center;
    z-index: $z-index-catalog-card-items;
  }
  &__stock-alert-btn {
    @apply ds-absolute;
  }
  &__stock-alert-btn-mobile {
    @include media-breakpoint-up(xl) {
      @apply ds-hidden;
    }
  }
  &:hover {
    #{$baseClass}__image {
      opacity: 0.15;
    }
    #{$baseClass}__qatc {
      @apply ds-opacity-100;
      @include media-breakpoint-up(md) {
        @apply ds-flex;
      }
    }
    #{$baseClass}__stock-alert {
      @include media-breakpoint-up(xl) {
        @apply ds-flex;
      }
    }
  }
}

.my-list {
  #bestsellers {
    @apply ds-hidden;
  }
}
.checkbox-form {
  top: 1.25rem;
  z-index: 3;
  @apply ds-ml-2 ds-absolute ds-left-4;
  .side-label {
    @apply ds-m-0 ds-pl-0;
    &::before {
      width: 20px !important;
      height: 20px !important;
    }
    &::after {
      @apply ds-w-4 ds-h-4;
    }
  }
  input[type='checkbox']:checked + .side-label::after {
    font-size: 16px;
    top: 4px !important;
  }
}
</style>
