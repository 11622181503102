import { SearchResponse } from '@algolia/client-search';
import { SearchParameters } from 'algoliasearch-helper';
import { ESProductHit } from '../../types';
import { searchClient } from '../algolia-client/algolia.client';
import { IndexName, getIndexName } from '@/services/analytics/algolia/index-name';
import { ProductAdapter } from '@bc/discovery/domain/product';
import { ProductSearchParameters } from '../../types/product-search-parameters';
import { ProductFilterBuilder } from '../../utils/product-filter-builder';

export const createQuery = (params: ProductSearchParameters) => {
  const searchParameters = new SearchParameters()
    .setIndex(getIndexName(IndexName.Products))
    .setPage(params.page)
    .setHitsPerPage(params.hitsPerPage);

  if (params.searchQuery) {
    searchParameters.query = params.searchQuery;
  }

  searchParameters.filters = new ProductFilterBuilder()
    .withBrandId(params.brandId)
    .withCollectionId(params.collectionId)
    .withIsOutOfStock(params.isOutOfStock)
    .withSortBy(params.sortBy)
    .withOpenedCountrie(params.userCountry)
    .withCategories(params.categories)
    .withNew(params.isNew)
    .build();

  searchParameters.attributesToRetrieve = [
    'id',
    `name.${params.lang}`,
    `description.${params.lang}`,
    `tags.${params.lang}`,
    'brand',
    'retail_price',
    'wholesale_price',
    'original_wholesale_price',
    'discount_rate',
    'unit_multiplier',
    'link',
    'options',
    'images',
    'out_of_stock',
    'discount_event_price',
    'margin',
    'opened_countries',
    'made_in',
    'is_new',
    'variants',
    'display_name',
    'available_at',
  ];

  return {
    indexName: searchParameters.index,
    params: searchParameters.getQueryParams(),
  };
};

export const searchProducts = async (params: ProductSearchParameters[]) => {
  const algoliaSearchClient = searchClient();
  const queries = params.map(createQuery);
  const { results } = (await algoliaSearchClient.search<ESProductHit>(queries)) as { results: SearchResponse<ESProductHit>[] };

  return {
    results: results.map((result, index) => ({
      ...result,
      hits: result.hits.map((hit) => ProductAdapter.fromProductHit(params[index].lang, hit)),
    })),
  };
};
