import { createCampaign } from '@bc/brands/feature/campaigns-management/services/api';
import { MessageType } from '@bc/brands/feature/campaigns-management/types/api.types';
import Campaign from '@/types/campaign';

export const actions = {
  async createCampaign({ rootGetters }, form: Campaign): Promise<void> {
    await createCampaign({
      campaignName: form.name,
      filters: rootGetters['account/contactManagement/getFiltersState'],
      defaultLanguage: form.language,
      messages: [
        {
          type: MessageType.Email,
          language: form.language,
          from: form.from,
          subject: form.subject,
          body: form.body,
          footer: form.footer,
          ctaText: form.ctaText,
        },
      ],
    });
  },
};

export default actions;
