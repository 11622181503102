<template>
  <div :class="[{ 'ds-border-b ds-border-solid ds-border-neutral-600 ds-mt-5 ds-pb-5': showSectionsExpander }]">
    <div
      v-if="showSectionsExpander"
      class="ds-flex ds-justify-between ds-items-start"
    >
      <div class="ds-text-lg ds-font-semibold ds-mb-0 ds-w-4/5">
        {{ $t('Inside the parcel') }}
      </div>
      <AkButton
        class="parcel-details__expand-btn"
        outlined
        data-testid="expandParcelDetailsBtn"
        @click="expandContent"
      >
        <AkIcon
          :symbol="expanded ? 'chevron-up' : 'chevron-down'"
          size="md"
        />
      </AkButton>
    </div>
    <div v-show="isExpanded">
      <AkParagraph :class="showSectionsExpander ? 'ds-mt-4' : 'ds-text-lg ds-font-semibold'">
        {{ $t('Did the parcel look like it was carefully prepared?') }}
      </AkParagraph>
      <AkRadio
        v-if="showRadio('hide')"
        class="ds-mt-4 ds-pb-4"
        value="hide"
        :is-checked="hideContent"
        name="parcel-details-options"
        data-testid="showPreparedPackagingOption"
        @change="switchContent"
      >
        <AkParagraph
          class="ds-mb-0"
          :class="hideContent ? 'ds-text-primary' : 'ds-text-neutral-900'"
        >
          <span class="ds-font-bold">{{ $t('Yes') }}, </span>
          <span>{{ $t('it was carefully prepared') }}</span>
        </AkParagraph>
      </AkRadio>
      <AkRadio
        v-if="showRadio('show')"
        class="ds-pb-4"
        value="show"
        :is-checked="showContent"
        name="parcel-details-options"
        @change="switchContent"
      >
        <AkParagraph
          class="ds-mb-0"
          :class="showContent ? 'ds-text-primary' : 'ds-text-neutral-900'"
        >
          <span class="ds-font-bold">{{ $t('No') }}: </span>
          <span>{{ $t('it did not match best packing practices') }}</span>
        </AkParagraph>
      </AkRadio>
      <div
        v-show="enableContent"
        class="ds-mt-5"
      >
        <div>
          <AkParagraph :class="showSectionsExpander ? 'ds-text-primary' : 'ds-font-semibold ds-text-lg'">
            {{ $t('What looked wrong?') }}
          </AkParagraph>
          <div>
            <AkCheckbox
              v-if="showCheckBox(irrelevantBoxSize)"
              value=""
              :checked="isCheckboxChecked(irrelevantBoxSize)"
              :disabled="showSectionsExpander"
              @change="checkboxChecked($event, irrelevantBoxSize)"
            >
              <AkParagraph :class="checkboxStyles(irrelevantBoxSize)">
                {{ $t('orderIssue.packagingIssues.irrelevantBoxSize.title') }}
              </AkParagraph>
            </AkCheckbox>
            <AkCheckbox
              v-if="showCheckBox(lackOfProtectiveMaterials)"
              value=""
              :checked="isCheckboxChecked(lackOfProtectiveMaterials)"
              :disabled="showSectionsExpander"
              @change="checkboxChecked($event, lackOfProtectiveMaterials)"
            >
              <AkParagraph :class="checkboxStyles(lackOfProtectiveMaterials)">
                {{ $t('orderIssue.packagingIssues.lackOfProtectiveMaterials.title') }}
              </AkParagraph>
            </AkCheckbox>
            <AkCheckbox
              v-if="showCheckBox(lackOfSeparators)"
              value=""
              :checked="isCheckboxChecked(lackOfSeparators)"
              :disabled="showSectionsExpander"
              @change="checkboxChecked($event, lackOfSeparators)"
            >
              <AkParagraph :class="checkboxStyles(lackOfSeparators)">
                {{ $t('orderIssue.packagingIssues.lackOfSeparators.title') }}
              </AkParagraph>
            </AkCheckbox>
            <AkCheckbox
              v-if="showCheckBox(otherIssue)"
              value=""
              :checked="isCheckboxChecked(otherIssue)"
              :disabled="showSectionsExpander"
              data-testid="otherIssueParcelDetailsCheckbox"
              @change="checkboxChecked($event, otherIssue)"
            >
              <AkParagraph :class="checkboxStyles(otherIssue)">
                {{ $t('orderIssue.packagingIssues.other.title') }}
              </AkParagraph>
            </AkCheckbox>
            <div
              v-if="isCheckboxChecked(otherIssue)"
              class="ds-mb-4"
            >
              <AkInput
                v-model="issueDescription"
                :has-error="descriptionError"
                :disabled="showSectionsExpander"
                :placeholder="$t('Describe other issues here')"
                data-testid="otherIssueParcelDetailsInput"
                @input="changeDescription"
              />
              <AkParagraph
                v-if="descriptionError"
                class="ds-text-error-700"
                size="sm"
              >
                {{ $t('Please, fill the field') }}
              </AkParagraph>
            </div>
          </div>
        </div>
        <AkParagraph class="ds-text-neutral-900">
          {{ $t('Add photos showing how products were packed inside the parcel') }}
        </AkParagraph>
        <FileUploader
          @upload-file="uploadFile"
          @remove-file="removeFile"
          @set-loader="setLoader"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import FileUploader from '@/components/account/orders/order/order-retailer-reject/components/file-uploader.vue';
import { CaseReasonStep, FormFileType, PackagingIssue } from '@/types/order-retailer-rejection';
import { orderIssueFileUploadingMixin } from '@/components/account/orders/order/order-retailer-reject/mixins/order-issue-file-uploading';

export default defineComponent({
  name: 'ParcelDetails',
  components: {
    FileUploader,
  },
  mixins: [orderIssueFileUploadingMixin],
  emits: ['set-checked'],
  data() {
    return {
      expanded: false,
      enableContent: null,
      issueDescription: '',
      descriptionError: false,
      irrelevantBoxSize: PackagingIssue.IrrelevantBoxSize,
      lackOfProtectiveMaterials: PackagingIssue.LackOfProtectiveMaterials,
      lackOfSeparators: PackagingIssue.LackOfSeparators,
      otherIssue: PackagingIssue.Other,
    };
  },
  computed: {
    ...mapGetters('orderRetailerRejection', ['getCurrentStep', 'getPackagingIssues', 'showSectionsExpander']),
    isExpanded(): boolean {
      return this.getCurrentStep.key === CaseReasonStep.Summary ? this.expanded : true;
    },
    enableNextStep(): boolean {
      if (this.enableContent) {
        const hasCheckedIssues = Object.values(this.getPackagingIssues).includes(true);

        if (this.getPackagingIssues[PackagingIssue.Other]) {
          return hasCheckedIssues && !this.descriptionError && this.issueDescription !== '';
        } else {
          return hasCheckedIssues;
        }
      } else {
        return true;
      }
    },
    showContent(): boolean {
      return this.enableContent === true;
    },
    hideContent(): boolean {
      return this.enableContent === false;
    },
  },
  methods: {
    ...mapActions('orderRetailerRejection', [
      'setPackagingIssueDescription',
      'setPackagingIssue',
      'uploadFormFile',
      'removeFormFile',
    ]),
    showRadio(label: string): boolean {
      if (this.showSectionsExpander) {
        if (label === 'show') {
          return this.showContent;
        } else {
          return this.hideContent;
        }
      }

      return true;
    },
    expandContent() {
      this.expanded = !this.expanded;
    },
    async uploadFile(file: File, callback): Promise<void> {
      const result = await this.uploadFormFile({ file, type: FormFileType.ParcelContent });
      callback(result);
    },
    removeFile(file: File): void {
      this.removeFormFile({ file });
    },
    switchContent(value: boolean, radioValue = 'show') {
      const checked = radioValue === 'show' && value;

      this.enableContent = checked;
      this.$emit('set-checked', this.enableNextStep);
    },
    showCheckBox(issueType: PackagingIssue): boolean {
      return this.showSectionsExpander ? this.isCheckboxChecked(issueType) : true;
    },
    isCheckboxChecked(issueType: PackagingIssue) {
      return this.getPackagingIssues[issueType];
    },
    checkboxChecked(value: boolean, issueType: PackagingIssue) {
      if (issueType === PackagingIssue.Other) {
        this.descriptionError = false;
      }

      this.setPackagingIssue({ issueType, value });
      this.$emit('set-checked', this.enableNextStep);
    },
    checkboxStyles(issueType: PackagingIssue): string {
      if (this.getCurrentStep.key === CaseReasonStep.Summary) {
        return 'ds-text-neutral-900';
      } else {
        return this.isCheckboxChecked(issueType) ? 'ds-text-neutral-900 ds-font-semibold' : 'ds-text-neutral-700';
      }
    },
    changeDescription(value: string): void {
      this.descriptionError = value === '';
      this.setPackagingIssueDescription({ value });
      this.$emit('set-checked', this.enableNextStep);
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.parcel-details__expand-btn.ak-button.ak-button--outlined {
  @apply ds-mr-2 ds-pr-0;
  background-color: transparent !important;
  box-shadow: none !important;
}

:deep(.ak-radio:last-of-type) {
  @apply ds-pb-0;
}

:deep(.ak-radio__sidelabel) {
  @apply ds-mt-1;
}

:deep(.ak-checkbox__sidelabel) {
  @apply ds-mt-1;
}
</style>
