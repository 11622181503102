<template>
  <div class="range-widget">
    <AkParagraph class="range-widget__label">
      {{ filter.label }}
    </AkParagraph>
    <div class="range-widget__container">
      <AkInput
        :model-value="minValue"
        :label="filter.minLabel"
        class="range-widget__input"
        :placeholder="formattedMinPrice"
        type="number"
        @update:model-value="setValue($event, maxValue)"
      />
      <span class="range-widget__separator">{{ filter.separatorLabel }}</span>
      <AkInput
        :model-value="maxValue"
        :label="filter.maxLabel"
        class="range-widget__input"
        :placeholder="formattedMaxPrice"
        type="number"
        @update:model-value="setValue(minValue, $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import usePrice from '@/composables/use-price';
import useCurrency from '@/composables/use-currency';
import { RangeWidgetComponent } from '../../types/filter.types';
import { ContactFilterType, RangeContactFilter } from '../../types/api.types';
import { AkParagraph, AkInput } from '@ankorstore/design-system';

export default defineComponent({
  name: 'RangeWidget',
  components: {
    AkParagraph,
    AkInput,
  },
  props: {
    filter: {
      type: Object as PropType<RangeWidgetComponent>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<RangeContactFilter>,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { formatPrice } = usePrice();
    const { $currency } = useCurrency();
    const { filter } = props;

    const parseValue = (value) => (value !== undefined && value !== '' ? value / 100 : '');

    const minValue = computed(() => {
      return parseValue(props?.modelValue?.min);
    });

    const maxValue = computed(() => {
      return parseValue(props?.modelValue?.exclusiveMax);
    });

    const setValue = (min: number|string, max: number|string) => {
      const formatValue = (value) => (value !== '' ? value * 100 : '');
      emit('update:modelValue', {
        min: formatValue(min),
        exclusiveMax: formatValue(max),
        type: ContactFilterType.Range,
        name: props.filter.attributeName,
      });
    };

    const formattedMinPrice = computed(() => {
      return formatPrice({ amount: Number(filter.attributes.min), currency: $currency.value }, '$0');
    });

    const formattedMaxPrice = computed(() => {
      return filter.attributes.max ? formatPrice({ amount: Number(filter.attributes.max), currency: $currency.value }, '$0') : '';
    });

    return { formattedMinPrice, formattedMaxPrice, minValue, maxValue, setValue };
  }
});
</script>
<style scoped lang="scss">
  .range-widget {

    &__container {
      @apply ds-flex ds-items-end ds-px-4;
    }

    &__label {
      @apply ds-mb-2 ds-text-neutral-900;
    }

    &__input {
      @apply ds-w-full;
    }

    &__separator {
    @apply ds-px-2 ds-pb-3;
     }
  }
</style>
