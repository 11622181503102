<template>
  <a @click="clearCookies">
    <slot></slot>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Cookie from '@/utilities/cookies/cookies';

export default defineComponent({
  name: 'RedirectLanguageLink',
  props: {
    userCookieName: {
      type: String,
      required: false,
      default: '',
    },
    adminCookieName: {
      type: String,
      required: false,
      default: '',
    },
    cookieDomain: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    clearCookies() {
      const cookiesToClear = [this.userCookieName, this.adminCookieName];

      const options =
        '' !== this.cookieDomain.trim()
          ? {
              domain: this.cookieDomain,
            }
          : {};
      for (const idx in cookiesToClear) {
        Cookie.delete(cookiesToClear[idx], options);
      }
    },
  },
});
</script>
