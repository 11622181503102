export function vuelidateFieldClassName(field?: { $dirty?: boolean; $invalid?: boolean }): string {
  if (!field) {
    return '';
  }
  if (field.$dirty && !field.$invalid) {
    return 'is-success';
  }
  if (field.$dirty && field.$invalid) {
    return 'is-invalid';
  }
  return '';
}

export default function fieldClassName(field?: {
  $touched?: boolean;
  $submitted?: boolean;
  $valid?: boolean;
  $invalid?: boolean;
}): string {
  if (!field) {
    return '';
  }
  if ((field.$touched || field.$submitted) && field.$valid) {
    return 'is-success';
  }
  if ((field.$touched || field.$submitted) && field.$invalid) {
    return 'is-invalid';
  }
  return '';
}
