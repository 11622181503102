<template>
  <div class="success-invitation">
    <div class="success-invitation__header">
      <AkParagraph
        class="success-invitation__text success-invitation__text--no-margin"
        weight="bold"
      >
        {{ $t('Congratulations!') }}
      </AkParagraph>
      <i18n-t
        keypath="Your invitation was {sending}"
        class="success-invitation__text success-invitation__text--no-margin success-invitation__text--bold"
        tag="p"
      >
        <template #sending>
          <span class="success-invitation__text success-invitation__text--success">{{ $t('successfully sent') }}</span>
        </template>
      </i18n-t>
    </div>
    <div class="success-invitation__content">
      <div class="success-invitation__highlight">
        <AkParagraph class="success-invitation__text">
          {{
            $t('Remember it takes more than an email to convert retailers.')
          }}
        </AkParagraph>
        <div class="success-invitation__highlight--legend">
          <AkIcon
            symbol="telephone"
            size="md"
          />
          <AkParagraph class="success-invitation__text success-invitation__text--no-margin">
            {{
              $t('Follow-up with a phone call. It will multiply your chances to close the sale by 11!')
            }}
          </AkParagraph>
        </div>
      </div>
    </div>
    <div class="success-invitation__footer">
      <AkButton
        size="lg"
        @click="closePopin"
      >
        {{ $t('Back to My network') }}
      </AkButton>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CampaignsSuccessInvitation',
  emits: ['closePopin'],
  methods: {
    closePopin() {
      this.$emit('closePopin');
    },
  },
});
</script>
<style scoped lang="scss">
@import '@css/vue-import';

.success-invitation {
  &__header,
  &__content,
  &__footer {
    @apply ds-p-5;
  }
  &__header {
    @apply ds-bg-primary ds-text-left ds-text-white;
  }
  &__text {
    @apply ds-text-base;
    &--no-margin {
      @apply ds-mb-0;
    }
    &--bold {
      @apply ds-font-bold;
    }
    &--success {
      @apply ds-text-accent-alt;
    }
  }
  &__content {
    @apply ds-overflow-y-auto;
    max-height: 50vh;

    &--align-left {
      @apply ds-text-left;
    }
  }
  &__footer {
    @apply ds-flex ds-flex-row ds-justify-end;
    @apply ds-border-t ds-border-solid ds-border-neutral-300;
  }
  &__highlight {
    @apply ds-bg-warm-white ds-p-4 ds-rounded-md;
    &--legend {
      @apply ds-flex ds-gap-2;
      @apply ds-text-neutral-700;
    }
  }
}
</style>
