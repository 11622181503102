import { R2BOfferConfiguration } from '@/types/r2b';
import { B2REligibility } from '@/services/b2r';
import mutations from '@/store/offers/mutations';
import actions from '@/store/offers/actions';
import getters from '@/store/offers/getters';
import { WelcomeVoucherEligibility } from '@/services/welcome-voucher';
import { BusinessEventPeriode } from '@/types/business-event';
import { SumUpEligibility } from '@/services/offer-eligibility';
import { OfferProgramEligibility } from '@/modules/promote-engage/services/offer';

export { OFFERS_STORE_ID } from './constants';

export interface OffersState {
  r2b: {
    offers_config: R2BOfferConfiguration;
    offers_config_fetched: boolean;
  };
  b2r: {
    eligibility: B2REligibility;
    eligibilityFetched: boolean;
  };
  welcomeOffer: {
    eligibility: WelcomeVoucherEligibility;
    eligibilityFetched: boolean;
  };
  sumUp: {
    eligibility: SumUpEligibility;
    eligibilityFetched: boolean;
  };
  events: BusinessEventPeriode[];
  offerEligibility: {
    eligibility: OfferProgramEligibility[];
    eligibilityFetched: boolean;
  };
}

const state: OffersState = {
  r2b: {
    offers_config: null,
    offers_config_fetched: false,
  },
  b2r: {
    eligibility: {
      status: false,
    },
    eligibilityFetched: false,
  },
  sumUp: {
    eligibility: {
      status: false,
    },
    eligibilityFetched: false,
  },
  welcomeOffer: {
    eligibility: {
      status: false,
    },
    eligibilityFetched: false,
  },
  events: [],
  offerEligibility: {
    eligibility: [],
    eligibilityFetched: false,
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
