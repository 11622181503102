<template>
  <router-link
    :to="{ name: 'ankorstore-plus-signup' }"
    data-testid="ankorstorePlusJoin"
  >
    <template #default="{ href, navigate }">
      <a
        :href="href"
        class="badge"
        data-testid="ankorstorePlusJoinLink"
        @click="
          trackAnalyticsEvent();
          navigate($event);
        "
      >
        <AkLogoAKS type="symbol" />
        <span class="badge__bold">{{ t('retailer.aksPlus.join.lbl') }}</span>
      </a>
    </template>
  </router-link>
</template>

<script setup lang="ts">
import { AkLogoAKS } from '@ankorstore/design-system';
import Analytics from '@/services/analytics';
import UserClick from '@/services/analytics/events/user-click.event';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const trackAnalyticsEvent = () => {
  Analytics.track(
    new UserClick({
      component: 'ankorstore-plus-join',
      action: 'click_ankorstore_plus_join',
    })
  );
};
</script>
<style lang="scss" scoped>
.badge {
  @apply md:ds-pl-1 ds-flex md:ds-flex-row md:ds-items-center ds-gap-1;
  &__bold {
    @apply ds-font-bold ds-underline;
  }
}
</style>
