import { ComputedRef } from 'vue';
import { HistoryRouterBase } from './history-router-base';
import { CreateHistoryRouterParams, Router } from './types';
import { SearchRouteState, SnippetTemplate } from '../../types/catalog';

export class HistoryRouterManager extends HistoryRouterBase {
  constructor(
    params: CreateHistoryRouterParams,
    private readonly template: ComputedRef<SnippetTemplate>,
    private readonly productRouter: Router<SearchRouteState>,
    private readonly brandRouter: Router<SearchRouteState>
  ) {
    super(params);
  }

  read() {
    return this.currentHistoryRouter.read();
  }

  createURL(routeState: SearchRouteState) {
    return this.currentHistoryRouter.createURL(routeState);
  }

  write(routeState: SearchRouteState) {
    this.currentHistoryRouter.write(routeState);
  }

  onUpdate(callback: (routeState: SearchRouteState) => void) {
    super.onUpdate(callback);
  }

  dispose() {
    super.dispose();
    this.productRouter.dispose();
    this.brandRouter.dispose();
  }

  private get currentHistoryRouter(): Router<SearchRouteState> {
    switch (this.template.value) {
      case SnippetTemplate.PRODUCTS:
        return this.productRouter;
      case SnippetTemplate.BRANDS:
        return this.brandRouter;
      default:
        throw new Error('Unknown Snippet Template');
    }
  }
}
