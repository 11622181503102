import AnalyticsEvent from '@/services/analytics/events/analytics-event';
import { ViewTileTracking } from '@/types/analytics/recommendation-tracking';

export class ViewTileEvent extends AnalyticsEvent {
  public readonly name = 'View Tile';
  public properties: ViewTileTracking;

  constructor(properties: ViewTileTracking) {
    super();

    this.properties = { ...properties };
  }
}
