import http from '@/services/api/http';
import { memoize } from 'lodash-es';
import { ProductTypeSuggestionItem, ProductTypeTreeItem, ProductTypeCategories } from '@bc/brands/feature/product-management/types/product-types';
import {
  ProductTypeAttributesData,
  ProductTypeAttributeValue,
} from '@bc/brands/feature/product-management/types/product-type-attributes';

const jsonApiConfigs = {
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
};

export const getProductTypesList = memoize(async (): Promise<ProductTypeSuggestionItem[]> => {
  const apiRoute = `/api/internal/v1/product-types/autocomplete`;
  const response = await http().get(apiRoute, jsonApiConfigs);
  return response.data.data;
});

export const getProductTypesTree = memoize(async (): Promise<ProductTypeTreeItem[]> => {
  const apiRoute = `/api/internal/v1/product-types/tree`;
  const response = await http().get(apiRoute, jsonApiConfigs);
  return response.data.data;
});

export const getProductTypeAttributesList = memoize(async (id: number): Promise<ProductTypeAttributesData[]> => {
  const apiRoute = `/api/internal/v1/product-types/${id}/schema`;
  const response = await http().get(apiRoute, jsonApiConfigs);
  return response.data.data;
});

export const getProductTypeCategories = async (id: number, attributes?: ProductTypeAttributeValue[]): Promise<ProductTypeCategories[]> => {
  const apiPayload = attributes || {};
  const apiRoute = `/api/internal/v1/product-types/${id}/categories`;
  const response = await http().post(apiRoute, apiPayload, jsonApiConfigs);
  return response.data.data;
};
