<template>
  <component
    :is="component"
    v-show="false"
    :attribute="facet.name"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Facet, FacetsType } from '@bc/discovery/domain/catalog';
import { AisRefinementList, AisRangeInput, AisToggleRefinement } from 'vue-instantsearch/vue3/es';

const { facet } = defineProps<{ facet: Facet }>();

const component = computed(() => {
  const facetMap = {
    [FacetsType.refinement]: AisRefinementList,
    [FacetsType.priceRange]: AisRangeInput,
    [FacetsType.numeric]: AisRangeInput,
    [FacetsType.color]: AisRefinementList,
    [FacetsType.hierarchicalMultiSelect]: AisRefinementList,
    [FacetsType.boolean]: AisToggleRefinement,
  };
  return facetMap[facet.type];
});
</script>
