import AnalyticsEvent from '@/services/analytics/events/analytics-event';
import { SectionCreatedTracking } from '@/types/analytics/recommendation-tracking';

export class SectionCreatedEvent extends AnalyticsEvent {
  public readonly name = 'Section Created';
  public properties: SectionCreatedTracking & { nb_tiles: number };

  constructor(value: SectionCreatedTracking) {
    super();

    const productCount = value.id_product?.length ?? 0;
    const brandCount = value.id_brand?.length ?? 0;

    this.properties = { ...value, nb_tiles: productCount + brandCount };
  }
}
