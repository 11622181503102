<template>
  <div class="track-parcels">
    <AkAlert
      hide-icon
      class="ds-mb-4"
    >
      <div class="ds-flex ds-items-start">
        <AkIcon
          symbol="info-circle"
          size="md"
          class="ds-text-info ds-mr-3"
        />
        {{
          getParcels.length > 1
            ? $t(
              'Your order will be delivered in multiple parcels. Please check all the tracking links below before reporting any issue.'
            )
            : $t(
              'Your order is being tracked by the logistic partner. Please check the tracking link below before reporting any issue.'
            )
        }}
      </div>
    </AkAlert>
    <div
      v-if="getParcels.length === 1"
      class="ds-flex ds-justify-center"
    >
      <AkButton
        size="lg"
        outlined
        symbol="truck"
        @click="parcelLinkClicked(getParcels[0])"
      >
        {{ $t('Track the delivery') }}
      </AkButton>
    </div>
    <div
      v-else
      v-for="(parcel, index) in getParcels"
      :key="index"
      class="track-parcels__parcel"
    >
      <AkParagraph class="track-parcels__parcel-title">
        {{ $t('Parcel {number}', { number: index + 1 }) }}
      </AkParagraph>
      <AkButton
        size="lg"
        outlined
        symbol="truck"
        @click="parcelLinkClicked(parcel)"
      >
        {{ $t('Track the delivery') }}
      </AkButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { OrderParcel } from '@/types/order-retailer-rejection';

export default defineComponent({
  name: 'TrackParcels',
  computed: {
    ...mapGetters('orderRetailerRejection', ['getParcels']),
  },
  methods: {
    ...mapMutations('orderRetailerRejection', ['SET_PARCEL_PROPERTY']),
    trackParcel(trackingLink: string): void {
      window.open(trackingLink, '_blank');
    },
    parcelLinkClicked(parcel: OrderParcel): void {
      this['SET_PARCEL_PROPERTY']({
        parcel: parcel,
        property: 'clicked',
        value: true,
      });
      this.trackParcel(parcel.trackingLink);
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.track-parcels {
  @apply ds-font-basic ds-pt-4;

  &__parcel {
    @apply ds-flex ds-justify-between ds-py-3;

    &:not(:last-child) {
      @apply ds-border-solid ds-border-b ds-border-neutral-300;
    }
  }

  &__parcel-title {
    @apply ds-font-semibold ds-mb-0;
    line-height: 2.5em;
  }
}
</style>
