<template>
  <div :class="{ 'ds-grid ds-grid-cols-2 lg:ds-grid-cols-3 xl:ds-grid-cols-3 ds-gap-3 md:ds-gap-1': showAsColumns }">
    <div
      v-for="item in items"
      :key="item.value"
      class="ds-w-full ds-pb-4"
    >
      <slot :item="item"></slot>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ItemsFilter',
};
</script>

<script setup lang="ts" generic="TItem extends Item">
import { PropType } from 'vue';
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- Used in generic type for component setup
import { Item } from './types';

defineProps({
  items: {
    type: Array as PropType<TItem[]>,
    required: true,
  },
  showAsColumns: {
    type: Boolean,
    default: false,
  },
});
</script>
