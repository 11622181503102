<template>
  <div class="ds-flex ds-flex-col ds-w-full ds-mb-4">
    <button
      class="ds-flex ds-items-center ds-w-full ds-justify-between ds-mb-2"
      @click="toggle()"
    >
      <slot name="header"></slot>
      <AkIcon
        size="md"
        :symbol="show ? 'chevron-up' : 'chevron-down'"
        class="chevron"
      />
    </button>
    <slot
      v-if="show"
      name="text"
    ></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { AkIcon } from '@ankorstore/design-system';

export default defineComponent({
  name: 'AkExpander',
  components: {
    AkIcon,
  },
  emits: ['expanded'],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggle() {
      this.show = !this.show;
      if (this.show) {
        this.$emit('expanded');
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.chevron {
  @apply ds-ml-4 ds-text-neutral-600 ds-text-base;
  font-size: 24px;
}
</style>
