import { TailwindConfig } from '@ankorstore/design-system';

export enum CLIENT_DEVICE {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

export interface BreakpointsState {
  isMobile: boolean;
  isXlMobile: boolean;
  isTablet: boolean;
  isXlTablet: boolean;
  isDesktop: boolean;
  isXlDesktop: boolean;
  currentDevice: CLIENT_DEVICE;
}

export enum BREAKPOINT_PX_LIMIT {
  SM = Number.parseInt(TailwindConfig.theme.screens.sm, 10),
  MD = Number.parseInt(TailwindConfig.theme.screens.md, 10),
  LG = Number.parseInt(TailwindConfig.theme.screens.lg, 10),
  XL = Number.parseInt(TailwindConfig.theme.screens.xl, 10),
  XXL = Number.parseInt(TailwindConfig.theme.screens['2xl'], 10),
}
