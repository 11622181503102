import i18n from '@/services/i18n';
import { Filter } from '../types/filters';

const createFilter = (id: string, label: string): Filter => ({
  id,
  label: label as string,
});

export const getDiscounts = (): Promise<Filter[]> =>
  Promise.resolve([
    createFilter('1_10', i18n.global.t('1% - 10%')),
    createFilter('11_20', i18n.global.t('11% - 20%')),
    createFilter('21_30', i18n.global.t('21% - 30%')),
    createFilter('31_40', i18n.global.t('31% - 40%')),
    createFilter('41_*', i18n.global.t('catalog.filter.discounts.range.over-40')),
  ]);
