import type { App } from 'vue';
import type { Store } from 'vuex';
import { EventEmitter } from '@bc/shared';

type Options = {
  name?: string;
  store?: Store<unknown>;
};

export const createEventBus = ({ name = 'globalEventBus', store }: Options = {}) => {
  return {
    install: (app: App) => {
      const eventBus = new EventEmitter();

      type OnParams = Parameters<typeof eventBus.on>;
      type OnceParams = Parameters<typeof eventBus.once>;
      type OffParams = Parameters<typeof eventBus.off>;
      type EmitParams = Parameters<typeof eventBus.emit>;
      type VueEventBus = {
        $on: (...args: OnParams) => VueEventBus;
        $once: (...args: OnceParams) => VueEventBus;
        $off: (...args: OffParams) => VueEventBus;
        $emit: (...args: EmitParams) => VueEventBus;
      };

      const vueEventBus: VueEventBus = {
        ...eventBus,
        $on(...args) {
          eventBus.on(...args);
          return vueEventBus;
        },
        $once(...args) {
          eventBus.once(...args);
          return vueEventBus;
        },
        $off(...args) {
          eventBus.off(...args);
          return vueEventBus;
        },
        $emit(...args) {
          eventBus.emit(...args);
          return vueEventBus;
        },
      };

      if (store) {
        // There are some places where we use `store._vm` as a global event bus.
        // This is a workaround for the fact that `store._vm` is not available in Vue 3.
        store['_vm'] = vueEventBus;
      }

      Object.defineProperty(app.config.globalProperties, `$${name}`, {
        get() {
          return vueEventBus;
        },
      });
    },
  };
};
