import http from '@/services/api/http';
import { BpgReportIssueRequest, BpgReportIssueResponse } from '@/modules/shared/best-price-guarantee/types/types';
import jsonToFormData from 'json-form-data';
/**
 * Post a BPG report form
 * @param payload BpgReportIssueRequest
 * @returns BpgReportIssueResponse data
 */

export const submitBpgRequest = async (payload: BpgReportIssueRequest): Promise<BpgReportIssueResponse> => {
  const formData = jsonToFormData(payload, {
    initialFormData: new FormData(),
    showLeafArrayIndexes: true,
    includeNullValues: false,
  });

  return http()
    .post('/api/me/retailer/pricing/bpg/issues', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data);
};
