export enum ReviewStatus {
  UNKNOWN = 'unknown',
  WRITE_A_REVIEW = 'write_a_review',
  DRAFT = 'draft',
  IN_REVIEW = 'in_review',
  PUBLISHED = 'published',
  NOT_PUBLISHED = 'not_published',
  REMOVED = 'removed',
}

export enum ReviewCriteria {
  OVERALL = 'overall',
  ACCURACY = 'accuracy',
  PRODUCT_AND_PACKAGING = 'product_and_packaging',
  COMMUNICATION = 'communication',
  QUALITY = 'quality',
}

export interface ReviewNote {
  status: ReviewStatus;
  title: string;
  content: {
    icon?: string;
    text: string;
  };
  cta: {
    color?: 'red-danger' | 'green-success';
    icon?: string;
    text: string;
    onClick: () => void;
  };
}

export type ReviewRatings = {
  [key in ReviewCriteria]?: number;
};

export interface ReviewDTO {
  title: string;
  description?: string;
  ratings: ReviewRatings;
  review_type?: string;
  reviewable_id: string;
}

export interface ImageResource {
  id: number;
  path: string;
  status: string;
}

export interface ReviewImage {
  created_at: Date;
  id: number;
  path: string;
  status: string;
}

interface Brand {
  id: number;
  name: string;
}

export interface Retailer {
  id: number;
  first_name: string;
  last_name: string;
  store_name: string;
  country: string;
}

export interface Review {
  id?: number;
  title?: string;
  description?: string;
  brand: Brand;
  brand_answer: {
    answer: string;
    brand_name: string;
    created_at: Date;
    updated_at: Date;
    brand_image: string;
  } | null;
  status: ReviewStatus;
  flagged: boolean;
  flagged_messages: ReviewFlagMessage[];
  created_at: Date;
  updated_at: Date;
  published_at: Date;
  review_type: string;
  ratings: ReviewRatings;
  images: ImageResource[];
  retailer: Retailer;
  reviewable_id?: number;
}

export interface ReviewFlagMessage {
  id: number;
  reason: string;
  created_at: Date;
}

export interface ReviewsBreakdown {
  total: number;
  5: number;
  4: number;
  3: number;
  2: number;
  1: number;
}

export interface ReviewStatistics {
  average: ReviewRatings;
  breakdown: ReviewsBreakdown;
}
