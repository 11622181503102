import axios, { Method, AxiosError } from 'axios';
import { getGlobalConfig } from '@/services/global-config';
import { getInjectedContent } from '@/services/injected-content';
import { GlobalMetaTagName } from '@/services/initial-state';

const AKS_CSRF_HEADER = 'x-aks-csrf';
const CSRF_HEADER = 'X-CSRF-TOKEN';
const aksCsrfLocalStorageKey = 'aks-csrf';

const refreshToken = async () => {
  const {
    data: { csrf },
  } = await axios.get('/auth/csrf');

  localStorage.setItem(aksCsrfLocalStorageKey, csrf);
  axios.defaults.headers.common[CSRF_HEADER] = csrf;

  return csrf;
};

// CSRF interceptor
if (getGlobalConfig().aegis_enabled) {
  const methodsToExcludeCSRFCheck: Method[] = ['get', 'head', 'options'];

  const initialCsrf = getInjectedContent<string>(GlobalMetaTagName.CSRFToken);

  localStorage.setItem(aksCsrfLocalStorageKey, initialCsrf);

  axios.interceptors.request.use(async function (config) {
    if (!methodsToExcludeCSRFCheck.includes(config.method)) {
      let csrfToken = localStorage.getItem(aksCsrfLocalStorageKey);
      if (!csrfToken) {
        csrfToken = await refreshToken();
      }

      config.headers[AKS_CSRF_HEADER] = csrfToken;
      config.headers[CSRF_HEADER] = csrfToken;
    }

    return config;
  });

  const MAX_RETRY_COUNT = 1;

  axios.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      error.config.retryCount = error.config.retryCount ?? 0;

      if (
        error.response?.status === 401 &&
        !methodsToExcludeCSRFCheck.includes(error.config.method) &&
        error.config.retryCount < MAX_RETRY_COUNT
      ) {
        // Access token has expired, refresh it
        const newAccessToken = await refreshToken();

        // Update the request headers with the new access token
        error.config.headers[AKS_CSRF_HEADER] = newAccessToken;
        error.config.headers[CSRF_HEADER] = newAccessToken;

        error.config.retryCount++;

        try {
          // Retry the original request
          return await axios(error.config);
        } catch (retryError: unknown) {
          if (axios.isAxiosError(retryError) && retryError.response.status === 401) {
            // If the retry request also fails with 401, redirect to login
            globalThis.location.href = `/login?redirect_uri=${globalThis.location.pathname}${globalThis.location.search}${globalThis.location.hash}`;
          }

          return Promise.reject(retryError);
        }
      }

      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(function (response) {
    if (response.headers?.[AKS_CSRF_HEADER]) {
      localStorage.setItem(aksCsrfLocalStorageKey, response.headers[AKS_CSRF_HEADER]);
    }
    return response;
  });
}
/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */
const token = getInjectedContent<string>(GlobalMetaTagName.CSRFToken);

if (token) {
  axios.defaults.headers.common[CSRF_HEADER] = token;
}
// END OF CSRF interceptor

export const http = () => axios;
export default http;
