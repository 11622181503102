import AnalyticsEvent from '../analytics-event';
import { EoriWarningInteraction } from '../segment-ecommerce-types';

export class EoriNavigatedToHelpDeskEvent extends AnalyticsEvent {
  public readonly name = 'EORI Warning Clicked Helpdesk';

  constructor(public readonly properties: EoriWarningInteraction) {
    super();
  }
}
