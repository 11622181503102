<template>
  <div
    v-if="carts.length > 0"
    data-testid="cartSummaryItems"
    class="cartSummaryItems"
  >
    <li
      v-for="(cart, index) in carts"
      :key="cart.brand.name"
      data-testid="orderTotalByBrand"
      class="cart-summary-items__item"
    >
      <div class="brand-name">
        <span>{{ cart.brand.name }}</span>
        <span
          v-if="brandsDiscounts[cart.brand.id]"
          :data-testid="`brandDiscountDetail_${index}`"
          class="brand-name__discount-detail"
        >({{
          $t('cart.orderSummary.orderTotal.brandDiscount.percent.lbl', {
            percent: brandsDiscounts[cart.brand.id].metadata.discount_rate,
          })
        }})</span>
        <span
          v-if="cart.brand.event_special_discount"
          :data-testid="`brandDiscountDetail_${index}`"
          class="brand-name__discount-detail brand-name__discount-detail--purple"
        >({{
          $t('cart.orderSummary.orderTotal.brandDiscount.percent.lbl', {
            percent: cart.brand.event_special_discount,
          })
        }})</span>
      </div>

      <div class="amounts">
        <OrderSummaryBlockAmount
          v-if="cart.total_amount_before_discount"
          :data-testid="`orderTotalAmountBeforeDiscount_${index}`"
          class="total-amount--before-discount"
        >
          {{ formatPrice(cart.total_amount_before_discount) }}
        </OrderSummaryBlockAmount>
        <OrderSummaryBlockAmount
          :data-testid="`orderTotalAmount_${index}`"
          class="total-amount"
        >
          {{ formatPrice(cart.total_amount) }}
        </OrderSummaryBlockAmount>
      </div>
    </li>
  </div>
  <li
    v-else
    data-testid="noItemsMessage"
  >
    <AkParagraph
      size="sm"
      class="no-items-message"
    >
      {{ $t('retailer.priceBreakdown.aboveBrandsSection.noItemsMessage') }}
    </AkParagraph>
  </li>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import OrderSummaryBlockAmount from '@/modules/carts/order-summary/order-summary-block-amount.vue';
import { Cart } from '@/types/cart';

import { useStore } from '@/composables/use-store';
import usePrices from '@/composables/use-price';
import { BrandDiscount } from '@/types/api/brand-discount';

export default defineComponent({
  name: 'CartSummaryItems',
  components: {
    OrderSummaryBlockAmount,
  },
  props: {
    carts: {
      type: Array as PropType<Cart[]>,
      default: () => [],
    },
  },

  setup(props) {
    const store = useStore();
    const { formatPrice } = usePrices();
    const brandsDiscounts = computed<{ [key: number]: BrandDiscount }>(() => store.getters['cart/getPersonalDiscounts']);

    const rawTotal = computed(() => {
      return formatPrice(
        props.carts.reduce(
          (acc, cart) => {
            return {
              amount: acc.amount + cart.total_amount.amount,
              currency: cart.total_amount.currency,
            };
          },
          { amount: 0, currency: store.state.currency }
        )
      );
    });

    return { rawTotal, brandsDiscounts, formatPrice };
  },
});
</script>

<style lang="scss" scoped>
.no-items-message {
  @apply ds-text-neutral-900 ds-mx-1 ds-mb-0;
}
.amounts {
  @apply ds-flex ds-gap-2;
}

.total-amount {
  @apply ds-text-primary;

  &--before-discount {
    @apply ds-text-neutral-700  ds-mb-1 ds-line-through;
  }
}
.brand-name {
  @apply ds-flex ds-flex-wrap ds-items-center ds-gap-1;

  &__discount-detail {
    @apply ds-text-neutral-700 ds-text-sm;
    &--purple {
      @apply ds-text-discount;
    }
  }
}

.cart-summary-items {
  &__item {
    @apply ds-flex ds-w-full ds-justify-between ds-items-center ds-mb-5 ds-text-neutral-900;
    &:last-of-type {
      @apply ds-mb-0;
    }
  }
}
</style>
