import http from '@/services/api/http';
import { getInjectedContent } from "@/services/injected-content";
import { DynamicMetaTagName } from "@/services/initial-state";
import { Brand } from "@/types/api/brand";

const initialState = {
  globals: {}, // Algolia keys, etc.
  brandHistory: {},
  brands: {},
};

const shopBrand = getInjectedContent<Brand>(DynamicMetaTagName.ShopBrands);
if (shopBrand) {
  initialState.brands[shopBrand.id] = shopBrand;
}

export default {
  namespaced: true,
  state: initialState,
  actions: {
    fetchBrand({ commit }, { brand_id }) {
      return http()
        .get(`/api/brands/${brand_id}`)
        .then(({ data }) => {
          const brand = data.data;
          commit('STORE_BRAND', {
            ...brand,
            ...{ isRefreshed: true },
          });
          return brand;
        });
    },
    storeBrand({ commit }, { brand }) {
      commit('STORE_BRAND', brand);
    },
    storeBrands({ commit }, { brands }) {
      commit('STORE_BRANDS', brands);
    },
  },
  mutations: {
    SHOP_SET_GLOBALS(state, globals) {
      state.globals = globals;
    },
    STORE_BRAND(state, brand) {
      // https://vuejs.org/v2/guide/list.html#Caveats
      // Vue.set(state.brands, brand.id, brand);
      state.brands[brand.id] = brand;
    },
    STORE_BRANDS(state, brands) {
      // https://vuejs.org/v2/guide/list.html#Caveats
      state.brands = { ...brands, ...state.brands };
    },
  },
  getters: {
    getBrands: (state) => state.brands,
    brandById:
      (state, _getters, _rootState, { shouldApplyCountryRestrictions, userCountry }) =>
      (id) =>
        !state.brands[id]
          ? false
          : {
              ...state.brands[id],
              isOpenedInUserCountry: shouldApplyCountryRestrictions
                ? state.brands[id]?.opened_countries?.includes(userCountry) ?? false
                : true,
            },
  },
};
