<template>
  <ItemsFilter
    :items="items"
    :show-as-columns="showAsColumns"
  >
    <template #default="{ item }">
      <AkCheckbox
        :disabled="false"
        :value="item.value ?? 'undefined'"
        :checked="item.isRefined"
        @change="notifyCheckboxChanged(item)"
      >
        <FilterLabel
          :label="item.label"
          :count="item.count"
        />
      </AkCheckbox>
    </template>
  </ItemsFilter>
</template>

<script lang="ts">
export default { name: 'CheckboxFilter' };
</script>
<script setup lang="ts">
import { AkCheckbox } from '@ankorstore/design-system';
import { Item, ItemsFilterProps } from './types';
import FilterLabel from './filter-label.vue';
import ItemsFilter from './items-filter.vue';

defineProps<ItemsFilterProps>();

const emit = defineEmits(['checkboxChanged']);
const notifyCheckboxChanged = (item: Item) => emit('checkboxChanged', item);
</script>
