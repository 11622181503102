import { getCurrentInstance } from 'vue';
import { getGlobalConfig } from '@/services/global-config';
import { formatPrice } from '@/utilities/price';

function replacePlaceHolder(value, store): string {
  if (value === '' || value === undefined) {
    return '';
  }
  if (value.indexOf('{{minimumOrder}}') != -1 && getGlobalConfig().minimum_order_amount) {
    value = value.replace(/{{minimumOrder}}/g, formatPrice(getGlobalConfig().minimum_order_amount, '$0'));
  }

  if (value.indexOf('{{freeShippingAmount}}') != -1 && getGlobalConfig().free_shipping_minimum_amount) {
    value = value.replace(/{{freeShippingAmount}}/g, formatPrice(getGlobalConfig().free_shipping_minimum_amount, '$0'));
  }

  if (value.indexOf('{{liftCodeAmount}}') != -1 && store.state.brand?.lift_promocode?.amount !== undefined) {
    value = value.replace(/{{liftCodeAmount}}/g, formatPrice(store.state.brand.lift_promocode.amount, '$0'));
  }

  if (value.indexOf('{{liftCodeMinCheckout}}') != -1 && store.state.brand?.lift_promocode !== undefined) {
    value = value.replace(/{{liftCodeMinCheckout}}/g, formatPrice(store.state.brand.lift_promocode.minimum_checkout, '$0'));
  }

  if (value.indexOf('{{liftCode}}') != -1 && store.state.brand.lift_promocode !== undefined) {
    value = value.replace(/{{liftCode}}/g, store.state.brand.lift_promocode.code);
  }

  if (value.indexOf('{{liftCodeSpecific}}') != -1 && store.state.brand?.lift_promocode !== undefined) {
    value = value.replace(
      /{{liftCodeSpecific}}/g,
      '<span class="text-nowrap">' + store.state.brand.lift_promocode.code + '</span>'
    );
  }

  if (value.indexOf('{{brandName}}') != -1 && store.state.brand?.name) {
    value = value.replace(/{{brandName}}/g, store.state.brand.name);
  }

  if (value.indexOf('{{userEmail}}') != -1 && store.state.user?.email) {
    value = value.replace(/{{userEmail}}/g, store.state.user.email);
  }

  if (value.indexOf('{{userFirstName}}') != -1 && store.state.user?.first_name) {
    value = value.replace(/{{userFirstName}}/g, store.state.user.first_name);
  }

  if (value.indexOf('{{userLastName}}') != -1 && store.state.user?.last_name) {
    value = value.replace(/{{userLastName}}/g, store.state.user.last_name);
  }

  if (value.indexOf('{{brandUrl}}') != -1 && store.state.brand?.link) {
    value = value.replace(/{{brandUrl}}/g, store.state.brand.link);
  }

  if (value.indexOf('{{liftCodeBrandWidgetV2}}') != -1 && store.state.brand?.lift_promocode) {
    value = value.replace(
      /{{liftCodeBrandWidgetV2}}/g,
      '<span class="lift-code">' + store.state.brand.lift_promocode.code + '</span>'
    );
  }

  return value;
}

export function deepReplace(data, store) {
  if (Array.isArray(data)) {
    return data.map((item) => deepReplace(item, store));
  }

  if (typeof data === 'object') {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        switch (typeof value) {
          case 'string':
            return [key, replacePlaceHolder(value, store)];
          case 'object':
            return [key, value ? deepReplace(value, store) : value];
          default:
            return [key, value];
        }
      })
    );
  }
  return data;
}

export default function usePrismic() {
  const vm = getCurrentInstance().proxy;
  const store = vm.$store;
  return {
    client: vm.$prismic.client,
    prismic: vm.$prismic,
    deepReplace: (data) => deepReplace(data, store),
  };
}
