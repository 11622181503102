/**
 * NOTE: This module is going to be deprecated soon in favor of the new carts and checkout modules.
 * @deprecated
 */
import { Cart, CartTracking, CartType, Checkout, NeededInformations } from '@/types/cart';
import { CartState } from '@/store/cart/index';
import {
  ADD_ITEM,
  CALCULATE_CHECKOUT,
  CALCULATE_BRANDS_TOTALS,
  CART_API_CART_CALL_DECREMENT,
  CART_API_CART_CALL_INCREMENT,
  CART_MINIMUM_HAS_GONE,
  CART_MINIMUM_REACHED,
  CHANGE_ITEM_QUANTITY,
  CHANGE_VAT_EXEMPTION,
  CLEAR_COMPANY_ID_ERRORS,
  CLEAR_TAX_NUMBER_ERRORS,
  DELETE_ITEM,
  REFRESH_CHECKOUT,
  REMOVE_LOCAL_CART,
  SET_BUSINESS_VALIDATION_STEP,
  SET_CART_BUTTON_LOADING,
  SET_COMPANY_ID_ERRORS,
  SET_HOKODO_COMPANY_ID,
  SET_TAX_NUMBER,
  SET_TAX_NUMBER_ERRORS,
  SET_TAX_NUMBER_VALUE,
  SET_TRACKING,
  SET_CART_LOADING,
  TOGGLE_CART_SAVE_FOR_LATER,
  TOGGLE_HOKODO_LOADING,
  TOGGLE_SKIP_TAX_NUMBER,
  UPDATE_BULK,
  RESET_BULK_UPDATES,
  SET_TOTALS_LOADING,
  SET_RECARGO_ERRORS,
  CHANGE_RECARGO_ENABLED,
  CLEAR_RECARGO_ERRORS,
  CHANGE_EORI,
  CLEAR_EORI_ERRORS,
  SET_EORI_SUCCESS,
  SET_EORI_ERRORS,
  SET_LAST_PROMOCODE_CALL_RESULT,
  SET_CHECKOUT_START_LOADING_TIME,
  SET_BRANDS_DISCOUNTS,
  SET_SAVED_FOR_LATER,
  SET_CURRENT_TAB,
  SET_ORDER_SUMMARY,
  SET_ITEMS_COUNT,
  MOVE_CART,
  SET_CART_BRAND_COUNTRY,
} from '@/store/cart/mutation-types';
import { findIndex } from 'lodash-es';
import { BulkUpdate } from '@/services/api/cart';
import { BrandDiscount } from '@/types/api/brand-discount';
import { getCurrency } from '@/services/metas/currency';
import Country from '@/types/country';

export default {
  [SET_TAX_NUMBER](state: CartState, value) {
    state.taxNumber = { ...value };
  },
  [SET_TAX_NUMBER_VALUE](state: CartState, value) {
    state.taxNumber = { ...state.taxNumber, ...value };
  },
  [TOGGLE_SKIP_TAX_NUMBER](state: CartState, isSkipped) {
    state.taxNumber = { ...state.taxNumber, isSkipped };
  },
  [SET_BUSINESS_VALIDATION_STEP](state: CartState, step: NeededInformations) {
    state.businessValidationStep = step;
  },
  [SET_HOKODO_COMPANY_ID](state: CartState, value: string): void {
    state.hokodoCompany = value;
  },
  [TOGGLE_HOKODO_LOADING](state: CartState, value: boolean): void {
    state.isHokodoLoading = value;
  },
  [SET_CART_BUTTON_LOADING](state: CartState, value: boolean): void {
    state.isCartButtonLoading = value;
  },
  [SET_TRACKING](state: CartState, value: CartTracking): void {
    state.tracking = value;
  },
  [SET_CART_LOADING](state: CartState, value: boolean): void {
    state.isCartLoading = value;
  },
  [CART_MINIMUM_REACHED](state: CartState, cartId: number): void {
    state.tracking = {
      ...state.tracking,
      minimumReachedEventsSent: [...state.tracking.minimumReachedEventsSent, cartId],
    };
  },
  [CART_MINIMUM_HAS_GONE](state: CartState, cartId: number): void {
    state.tracking = {
      ...state.tracking,
      minimumReachedEventsSent: state.tracking.minimumReachedEventsSent.filter((i) => i !== cartId),
    };
  },
  [TOGGLE_CART_SAVE_FOR_LATER](state: CartState, cart: Cart) {
    const theCart = state.checkout.carts.find((c) => {
      return c === cart;
    });

    theCart.saved_for_later = !theCart.saved_for_later;
  },
  [REMOVE_LOCAL_CART](state: CartState, cartId: number) {
    state.checkout.carts = state.checkout.carts.filter((c) => c.id !== cartId);
  },
  [REFRESH_CHECKOUT](state: CartState, checkout: Checkout) {
    state.checkout = checkout;
    state.itemsCounts = {
      main: checkout.order_informations?.order_items_count_main,
      savedForLater: checkout.order_informations?.order_items_count_saved_for_later,
    };
  },
  [CHANGE_ITEM_QUANTITY](state: CartState, { brand, item, quantity, cartType = CartType.Main }) {
    const stateCart = (
      CartType.SavedForLater === cartType
        ? state.savedForLater
        : state.checkout.carts
    )?.find((cart) => cart.brand.id === brand.id);

    if (!stateCart) {
      throw new Error(`Cart of brand id ${brand.id} not found in checkout object`);
    }

    const stateItem = stateCart.items.find((itemInCart) => {
      return itemInCart.id === item.id;
    });

    if (!stateItem) {
      throw new Error(`Item ${item.id} not found in cart id ${stateCart.id}`);
    }

    // Calculate displayed data
    stateItem.quantity = quantity; // Quantity
    stateItem.active = true;

    stateItem.multiplied_quantity = quantity * stateItem.product.unit_multiplier; // Quantity
    stateItem.amount.amount = stateItem.multiplied_quantity * stateItem.unit_price.amount; // Item total amount

    if (stateItem.amount_before_discount) {
      // Unit price before discount
      stateItem.amount_before_discount = {
        amount: stateItem.multiplied_quantity * stateItem.unit_price_before_discount.amount,
        currency: stateItem.unit_price_before_discount.currency,
      };
    }
  },
  [DELETE_ITEM](state: CartState, { brand, item }) {
    const stateCart = (state.checkout as Checkout).carts.find((cart) => {
      return cart.brand.id === brand.id;
    });

    if (!stateCart) {
      return;
    }

    stateCart.items = stateCart.items.filter((itemInCart) => itemInCart.id !== item.id);

    if (stateCart.items.length === 0) {
      // Remove empty cart
      state.checkout.carts = (state.checkout as Checkout).carts.filter((cart) => cart.brand.id !== brand.id);
    }
  },
  [ADD_ITEM](state: CartState, { brand, option, variant, product, quantity }) {
    let stateCart = state.checkout.carts.find((cart) => {
      return cart.brand.id === brand.id;
    });

    if (!stateCart) {
      stateCart = {
        type: 'cart',
        id: null,
        saved_for_later: false,
        minimum: {
          above: false,
          amount: brand.minimum,
        },
        brand: brand,
        items: [],
        retailer_net_amount: {
          amount: 0,
          currency: getCurrency(),
        },
        total_amount_with_vat: {
          amount: 0,
          currency: getCurrency(),
        },
        total_amount_vat: {
          amount: 0,
          currency: getCurrency(),
        },
        total_amount: {
          amount: 0,
          currency: getCurrency(),
        },
        billing_items: [],
        minimumReachedEventSent: false,
        is_first_order_on_this_brand: false,
        recargo_de_equivalencia_amount: {
          amount: 0,
          currency: getCurrency(),
        },
        shipping_lead_time: {
          minimum_estimated_shipping_date: '',
          maximum_estimated_shipping_date: '',
          minimum_estimated_shipping_day: '',
          maximum_estimated_shipping_day: '',
        },
        customs_shipping_fees: {
          refundable: '',
          applicable: '',
        },
      };

      state.checkout.carts.push(stateCart);
    }

    stateCart.items.unshift({
      type: 'order_item',
      id: null,
      active: true,
      quantity: quantity,
      multiplied_quantity: quantity * product.unit_multiplier,
      unit_price: product.wholesale_price,
      amount_with_vat: {
        amount: 0,
        currency: getCurrency(),
      },
      amount_vat: {
        amount: 0,
        currency: getCurrency(),
      },
      amount: {
        amount: product.wholesale_price.amount * quantity * product.unit_multiplier,
        currency: getCurrency(),
      },
      option: option,
      variant: variant,
      product: product,
    });
  },
  [CALCULATE_BRANDS_TOTALS](state: CartState, { cartType = CartType.Main }) {
    (cartType === CartType.SavedForLater ? state.savedForLater : state.checkout.carts).forEach((cart) => {
      // Cart amount
      cart.total_amount = {
        amount: cart.items.filter((item) => item.active).reduce((total, itemInCart) => total + itemInCart.amount.amount, 0),
        currency: getCurrency(),
      };
      // Cart minimum reached?
      cart.minimum.above = cart.total_amount.amount >= cart.minimum.amount.amount;
    });
  },
  [CALCULATE_CHECKOUT](state: CartState) {
    state.checkout.firstInit = false;

    state.checkout.total_amount = {
      amount: state.checkout.carts
        .filter((cart) => cart.minimum.above && !cart.saved_for_later)
        .reduce((total, cart) => total + cart.total_amount.amount, 0),
      currency: getCurrency(),
    };

    // Free shipping?
    if (state.checkout.free_shipping_minimum_amount) {
      state.checkout.free_shipping = state.checkout.total_amount.amount > state.checkout.free_shipping_minimum_amount.amount;
    }

    // Billing items

    // VAT
    state.checkout.total_amount_vat.amount = 0;
    state.checkout.total_amount_with_vat.amount = 0;
  },
  [CHANGE_VAT_EXEMPTION](state: CartState, vatExemption) {
    state.taxNumber = { ...state.taxNumber, vatExemption };
  },
  [CART_API_CART_CALL_INCREMENT](state: CartState) {
    state.apiCallCount++;
  },
  [CART_API_CART_CALL_DECREMENT](state: CartState) {
    state.apiCallCount--;
  },
  [CLEAR_TAX_NUMBER_ERRORS](state: CartState) {
    state.checkoutFormErrors.taxNumber = [];
  },
  [CLEAR_COMPANY_ID_ERRORS](state: CartState) {
    state.checkoutFormErrors.companyId = false;
  },
  [CLEAR_RECARGO_ERRORS](state: CartState) {
    state.checkoutFormErrors.recargo = [];
  },
  [CLEAR_EORI_ERRORS](state: CartState) {
    state.checkoutFormErrors.eori = [];
  },
  [SET_EORI_SUCCESS](state: CartState, val: boolean) {
    state.checkoutFormSuccess.eori = val;
  },
  [SET_COMPANY_ID_ERRORS](state: CartState) {
    state.checkoutFormErrors.companyId = true;
  },
  [SET_TAX_NUMBER_ERRORS](state: CartState, errors: string[]) {
    state.checkoutFormErrors.taxNumber = [...errors];
  },

  [UPDATE_BULK](state: CartState, update: BulkUpdate) {
    const preparedUpdates = state.bulkUpdates;

    const existingIndexUpdate = findIndex(preparedUpdates, (preparedUpdate) => {
      if (preparedUpdate.order_item_id) {
        return preparedUpdate.order_item_id === update?.order_item_id;
      } else if (preparedUpdate.option_id) {
        return preparedUpdate.option_id === update?.option_id;
      }
      return false;
    });

    if (update?.order_item_id === null) {
      // NOTE: item is still adding to the cart
      return;
    }

    if (existingIndexUpdate === -1) {
      preparedUpdates.push(update);
    } else {
      preparedUpdates.splice(existingIndexUpdate, 1, update);
    }
  },
  [RESET_BULK_UPDATES](state: CartState) {
    state.bulkUpdates = [];
  },

  [SET_TOTALS_LOADING](state: CartState, loading = true) {
    state.isTotalsLoading = loading;
  },
  [SET_RECARGO_ERRORS](state: CartState, errors: string[]) {
    state.checkoutFormErrors.recargo = [...errors];
  },
  [SET_EORI_ERRORS](state: CartState, errors: string[]) {
    state.checkoutFormErrors.eori = [...errors];
  },
  [CHANGE_RECARGO_ENABLED](state: CartState, recargoEnabled) {
    state.recargoEnabled = recargoEnabled;
  },
  [CHANGE_EORI](state: CartState, eori) {
    state.eori = eori;
  },
  [SET_LAST_PROMOCODE_CALL_RESULT](state: CartState, { code, errorMessage }) {
    state.lastPromocodeCallResult = { code, errorMessage };
  },
  [SET_CHECKOUT_START_LOADING_TIME](state: CartState, time) {
    state.checkoutStartLoadingTime = time;
  },
  [SET_BRANDS_DISCOUNTS](state: CartState, discounts: BrandDiscount[]) {
    state.brandsDiscounts = Object.fromEntries(discounts.map((discount) => [discount.brand_id, discount]));
  },
  [SET_SAVED_FOR_LATER](state: CartState, savedForLaterCarts: Cart[]) {
    state.savedForLater = savedForLaterCarts;
  },
  [SET_CURRENT_TAB](state: CartState, isSavedForLaterDisplayed: boolean) {
    state.isSavedForLaterDisplayed = isSavedForLaterDisplayed;
  },
  [SET_ORDER_SUMMARY](state: CartState, orderSummary) {
    state = {
      ...state,
      checkout: {
        ...state.checkout,
        ...orderSummary,
      },
    };
  },
  [SET_ITEMS_COUNT](state: CartState, { main, savedForLater }: { main: number; savedForLater: number }) {
    state.itemsCounts = {
      main,
      savedForLater,
    };
  },
  [MOVE_CART](state: CartState, { cart, isGotoSavedForLater }: { cart: Cart; isGotoSavedForLater: boolean }) {
    state.savedForLater = isGotoSavedForLater
      ? [...state.savedForLater, cart]
      : state.savedForLater.filter((savedCart) => savedCart.id !== cart.id);
    state.checkout.carts = isGotoSavedForLater
      ? state.checkout.carts.filter((savedCart) => savedCart.id !== cart.id)
      : [...state.checkout.carts, cart];
  },
  [SET_CART_BRAND_COUNTRY](state: CartState, params: { cartId: number; country: Country }) {
    const cart = state.checkout.carts?.find((cart) => cart.id === params.cartId);
    if (cart) {
      cart.brand.country = params.country;
    }
  },
};
