// Check if the passed value has only alphanumeric chars and can have one or more spaces and underscore
export const alphanumericWithSpaceUnderscoreTest = (valueToTest: string): boolean => {
  const regex = /^(\w+\s)*\w+$(\.0-9+)?/;

  return regex.test(valueToTest);
};

export const httpsUrl = (valueToTest: string): boolean => {
  const regex = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

  return regex.test(valueToTest);
};
