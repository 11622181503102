<template>
  <div
    class="products-list"
    :class="mainWrapperStyles"
  >
    <div class="ds-flex ds-justify-between ds-items-start">
      <AkParagraph class="products-list__title">
        {{ content.title }}
      </AkParagraph>
      <AkButton
        v-if="showSectionsExpander && showRevampedVersion"
        class="products-list__expand-btn"
        data-testid="expandProductListOption"
        outlined
        @click="expandContent"
      >
        <AkIcon
          :symbol="expanded ? 'chevron-up' : 'chevron-down'"
          size="md"
        />
      </AkButton>
    </div>
    <div v-show="isExpanded">
      <div
        v-for="product in getSelectedProducts"
        :key="product.id"
        class="product-wrapper"
        :class="showAgreementCheckbox ? 'product-wrapper--full-bordered' : ''"
      >
        <div
          color="white"
          class="product-toggle-btn"
          data-testid="order-rejection-product-toggle-btn"
          @click="toggleProduct(product)"
        >
          <div class="product-name-wrapper">
            <div class="product-name">
              {{ product.name }}
            </div>
            <AkParagraph class="ds-text-neutral-700">
              {{ getProductIdentifier(product) }}
            </AkParagraph>
          </div>
          <div :class="['ds-flex ds-items-center', { 'ds-mr-2': showSectionsExpander && showRevampedVersion }]">
            <div
              v-if="showProductError(product)"
              class="ds-p-3 mr-2"
            >
              <AkIcon
                class="ds-rounded-full ds-bg-error-alt ds-text-error-700 ds-p-1.5"
                symbol="exclamation-triangle"
              />
            </div>
            <AkIcon
              :symbol="product.expanded ? 'chevron-up' : 'chevron-down'"
              size="md"
            />
          </div>
        </div>
        <div :class="product.expanded ? '' : 'ds-hidden'">
          <div class="ds-mt-4 ds-mb-5">
            <div class="product-quantity-wrapper">
              <div>
                <AkParagraph
                  class="ds-mb-0 ds-mr-2"
                  :class="product.quantityError ? 'ds-text-error-700' : 'ds-text-neutral-900'"
                  size="sm"
                >
                  {{ content.productsQuantityTitle }}
                </AkParagraph>
                <AkParagraph
                  v-if="product.quantityError"
                  size="xs"
                  class="ds-text-error-700 ds-mb-0"
                >
                  {{ product.quantityError }}
                </AkParagraph>
              </div>
              <AkInput
                required
                class="product-quantity"
                :has-error="product.quantityError != ''"
                @input="changeQuantity($event, product)"
              />
            </div>
          </div>
          <div v-if="withAttachments">
            <div class="product-files-wrapper">
              <AkParagraph
                class="ds-text-neutral-900 ds-mb-0 ds-mr-1"
                size="sm"
              >
                {{ content.productPhotosLabel }}
              </AkParagraph>
              <AkTooltipInformation
                trigger="hover"
                type="success"
                :content="content.productPhotosTooltip"
              >
                <AkIcon
                  symbol="info-circle"
                  size="md"
                  class="ds-ml-1"
                />
              </AkTooltipInformation>
            </div>
            <AkFileUploader
              :ref="getFileUploaderRefName(product.id)"
              :key="product.id"
              :primary-label="$t('Drag and drop your files here or')"
              :secondary-label="$t('browse your computer')"
              :above-max-file-size-message="$t('One of your file is too heavy')"
              :unaccepted-type-message="$t('Allowed file types: images (png / jpg)')"
              :types-label="$t('JPEG and PNG only.')"
              size-label="Max. 10Mb"
              :max-file-size="getConfig.maxFileSize"
              :max-file-quantity-message="$t('You reached the limit of files to upload')"
              :uploaded-file-label-max-length="getConfig.maxFileLabelLength"
              :max-file-quantity="getConfig.maxProductFileQuantity"
              :required="true"
              :multiple="true"
              :accepted-types="getConfig.allowedImageTypes"
              @change="uploadFiles($event, product, getFileUploaderRefName(product.id))"
            />
            <div class="ds-flex ds-flex-col">
              <AkUploadedFile
                v-for="(item, index) in product.files"
                :key="index"
                :ref="getUploadedFileRefName(product.id)"
                :label="trimFileName(item.file?.name)"
                class="ds-mt-4 ds-w-max"
                @click="removeFile(product, item, getFileUploaderRefName(product.id))"
              />
            </div>
          </div>
          <div
            v-if="withDescription"
            class="ds-my-4"
          >
            <AkParagraph
              :class="product.descriptionError ? 'ds-text-error-700' : 'ds-text-neutral-900'"
              size="sm"
            >
              {{ $t('Describe the issue') }}
            </AkParagraph>
            <AkTextarea
              no-resize
              :placeholder="$t('Write your description here')"
              :maxlength="getConfig.maxDescriptionLength"
              :value="product.description"
              :has-error="product.descriptionError != ''"
              :errors="product.descriptionError"
              @input="changeDescription($event, product)"
              @keydown.enter.shift.exact="newline(product)"
            />
          </div>
          <div class="ds-flex ds-justify-center ds-mb-3">
            <AkButton
              link
              class="product-delete-btn"
              data-testid="order-rejection-product-delete-btn"
              @click="deleteProduct(product)"
            >
              {{ $t('Delete') }}
            </AkButton>
            <AkButton
              outlined
              :disabled="productHasErrors(product)"
              @click="openNextProduct(product)"
            >
              {{ $t('Save') }}
            </AkButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { OrderProduct, CaseReason, CaseReasonStep } from '@/types/order-retailer-rejection';
import { orderIssueFileUploadingMixin } from '@/components/account/orders/order/order-retailer-reject/mixins/order-issue-file-uploading';

interface ProductItemData {
  productsQuantityTitle: string;
  productPhotosLabel?: string;
  productPhotosTooltip?: string;
  title?: string;
}

export default defineComponent({
  name: 'ProductsList',
  mixins: [orderIssueFileUploadingMixin],
  props: {
    withAttachments: {
      type: Boolean,
      required: false,
      default: false,
    },
    withDescription: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['set-loader', 'products-validation', 'all-products-removed'],
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapGetters('orderRetailerRejection', [
      'getSelectedProducts',
      'getConfig',
      'getCurrentStep',
      'getCaseReason',
      'showAgreementCheckbox',
      'showSectionsExpander',
      'showRevampedVersion',
    ]),
    content() {
      let data = {} as ProductItemData;

      switch (this.getCaseReason) {
        case CaseReason.DamagedItems:
          data = {
            productsQuantityTitle: this.$t('Quantity of damaged items'),
            productPhotosLabel: this.$t('Group all damaged items in one photo'),
            productPhotosTooltip: this.$t(
              'Please add one photo of all items you considered damaged for this product (SKU number above)'
            ),
          };
          break;
        case CaseReason.WrongItems:
          data = {
            productsQuantityTitle: this.$t('Quantity of wrong items'),
            productPhotosLabel: this.$t('Group all wrong items in one photo'),
            productPhotosTooltip: this.$t(
              'Please add one photo of all items you considered wrong for this product (SKU number above)'
            ),
          };
          break;
        case CaseReason.MissingItems:
          data = {
            productsQuantityTitle: this.$t('Quantity of missing items'),
          };
          break;
        case CaseReason.DefectiveItems:
          data = {
            productsQuantityTitle: this.$t('Quantity of defective items'),
            productPhotosLabel: this.$t('Group all defective items in one photo'),
            productPhotosTooltip: this.$t(
              'Please add one photo of all items you considered defective for this product (SKU number above)'
            ),
          };
          break;
      }

      if (this.getCurrentStep.key === CaseReasonStep.Summary) {
        data.title = this.$t('Product information');
      } else {
        data.title = this.$t('Enter the information per product');
      }

      return data;
    },
    showRevampedSummary(): boolean {
      return this.showRevampedVersion && this.getCurrentStep.key === CaseReasonStep.Summary;
    },
    isExpanded(): boolean {
      return this.showRevampedSummary ? this.expanded : true;
    },
    mainWrapperStyles(): string {
      if (!this.showRevampedSummary) {
        return 'ds-mt-5';
      } else {
        let styles = '';

        if (this.showSectionsExpander) {
          styles = 'ds-mt-5 ds-mb-2 ds-border-b ds-border-solid ds-border-neutral-600';
          styles += this.expanded ? '' : ' ds-pb-4';
        }

        return styles;
      }
    },
  },
  watch: {
    getSelectedProducts: {
      handler() {
        this.expandFirstProduct();
      },
      deep: true,
    },
  },
  mounted() {
    this.expandFirstProduct();
  },
  methods: {
    ...mapActions('orderRetailerRejection', ['setProductProperty', 'uploadProductFile', 'removeProductFile', 'clearProductData']),
    expandContent() {
      this.expanded = !this.expanded;
    },
    expandFirstProduct(): void {
      const hasExpandedProducts = this.getSelectedProducts.some((product) => product.expandedOnce);

      if (!hasExpandedProducts) {
        const firstItem = this.getSelectedProducts[0];

        this.setProductProperty({
          product: firstItem,
          property: 'expanded',
          value: true,
        });
        this.setProductProperty({
          product: firstItem,
          property: 'expandedOnce',
          value: true,
        });
      }
    },
    getProductIdentifier(product: OrderProduct): string {
      return `SKU ${product.sku}`;
    },
    productHasErrors(product: OrderProduct): boolean {
      return (
        Boolean(product.quantityError) ||
        product.quantity === '' ||
        (this.withDescription ? Boolean(product.descriptionError) || product.description === '' : false) ||
        (this.withAttachments ? product.files.length === 0 : false)
      );
    },
    newline(product: OrderProduct): void {
      this.setProductProperty({
        product,
        property: 'description',
        value: `${this.value}\n`,
      });
    },
    toggleProduct(product: OrderProduct): void {
      this.setProductProperty({
        product,
        property: 'expanded',
        value: !product.expanded,
      });
      this.setProductProperty({
        product,
        property: 'expandedOnce',
        value: true,
      });
    },
    showProductError(product: OrderProduct): boolean {
      return product.expandedOnce && !product.expanded && this.productHasErrors(product);
    },
    changeQuantity(value: number, product: OrderProduct): void {
      let errorMsg = '';
      const quantity = Number(value);
      const pattern = /^[0-9]+$/;
      const digitsOnly = pattern.test(value.toString());

      if (!digitsOnly || quantity === 0) {
        errorMsg = this.$t('Please, fill the integer');
      } else if (value > product.originalQuantity) {
        errorMsg = this.$t('Quantity entered is more than quantity ordered');
      }

      this.setProductProperty({
        product,
        property: 'quantityError',
        value: errorMsg,
      });

      if (!errorMsg) {
        this.setProductProperty({
          product,
          property: 'quantity',
          value: quantity,
        });
      }

      this.checkAllProductsValid();
    },
    changeDescription(value: string, product: OrderProduct): void {
      const errorMsg = value === '' ? this.$t('Please, fill the field') : '';

      this.setProductProperty({
        product,
        property: 'description',
        value: value,
      });
      this.setProductProperty({
        product,
        property: 'descriptionError',
        value: errorMsg,
      });

      this.checkAllProductsValid();
    },
    async uploadFiles(files: FileList, product: OrderProduct, fileUploaderRef: string): Promise<void> {
      let errors = [];
      const fileUploader = this.$refs[fileUploaderRef][0];
      fileUploader.files = null;

      if (files && files.length) {
        this.$emit('set-loader', true);
        //@ts-ignore
        //TODO: File type mismatch
        files = Array.from(files);

        for (let index = 0; index < files.length; index++) {
          if (product.files.length >= this.getConfig.maxProductFileQuantity) {
            errors = [this.$t('You reached the limit of files to upload')];
            this.setFileUploaderErrors(errors, fileUploaderRef);
            break;
          }

          const file = files[index];
          const result = await this.uploadProductFile({ product, file });

          if (!result) {
            errors = [this.$t('An error occurred. Please retry.')];
            this.setFileUploaderErrors(errors, fileUploaderRef);
            break;
          }
        }

        this.checkAllProductsValid();
        this.$emit('set-loader', false);
      }

      if (fileUploader.hasErrors) {
        errors = fileUploader.errors;
      }

      this.setProductProperty({
        product,
        property: 'filesErrors',
        value: errors,
      });
    },
    removeFile(product: OrderProduct, file: File, fileUploaderRef: string) {
      this.setFileUploaderErrors([], fileUploaderRef);
      this.removeProductFile({ product, file });
    },
    clearProductsFileErrors(): void {
      this.getSelectedProducts.forEach((product) => {
        const fileUploaderRef = this.getFileUploaderRefName(product.id);
        this.setFileUploaderErrors([], fileUploaderRef);
      });
    },
    deleteProduct(product: OrderProduct): void {
      this.clearProductData({ product });

      if (this.getSelectedProducts.length > 0) {
        this.openNextProduct();
      } else {
        this.$emit('all-products-removed');
      }
    },
    openNextProduct(product: OrderProduct = null): void {
      if (product) {
        this.setProductProperty({
          product,
          property: 'expanded',
          value: false,
        });
        this.setProductProperty({ product, property: 'saved', value: true });

        const fileUploaderRef = this.getFileUploaderRefName(product.id);
        this.setFileUploaderErrors([], fileUploaderRef);
      }

      const currentPosition = product ? this.getSelectedProducts.indexOf(product) : 0;

      if (currentPosition < this.getSelectedProducts.length) {
        let expandedProduct = false;

        for (let index = currentPosition; index < this.getSelectedProducts.length; index++) {
          const currentProduct = this.getSelectedProducts[index];

          if (this.productHasErrors(currentProduct)) {
            this.setProductProperty({
              product: currentProduct,
              property: 'expanded',
              value: true,
            });
            expandedProduct = true;
            break;
          }
        }

        if (!expandedProduct) {
          for (let index = 0; index < currentPosition; index++) {
            const currentProduct = this.getSelectedProducts[index];

            if (this.productHasErrors(currentProduct)) {
              this.setProductProperty({
                product: currentProduct,
                property: 'expanded',
                value: true,
              });
              break;
            }
          }
        }
      }

      this.checkAllProductsValid();
    },
    checkAllProductsValid(): void {
      let validProductsCount = 0;

      this.getSelectedProducts.forEach((product) => {
        if (!this.productHasErrors(product) && product.saved) {
          ++validProductsCount;
        } else {
          this.setProductProperty({ product, property: 'saved', value: false });
        }
      });

      this.$emit('products-validation', validProductsCount === this.getSelectedProducts.length);
    },
    getUploadedFileRefName(key: string | number): string {
      return 'uploadedFile' + key;
    },
    getFileUploaderRefName(key: string | number): string {
      return 'fileUploader' + key;
    },
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

.products-list {
  @apply ds-font-basic;

  &__title {
    @apply ds-font-semibold ds-text-lg ds-text-primary ds-leading-none;
  }

  &__expand-btn.ak-button.ak-button--outlined {
    @apply ds-mr-2 ds-pr-0;
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.product-wrapper {
  @apply ds-mt-4 ds-pb-4 ds-border-b ds-border-solid ds-border-neutral-600;

  &:last-child {
    @apply ds-border-none;
  }
}

:deep(.product-quantity) {
  max-width: 100px;
}

:deep(.product-quantity input) {
  @apply ds-text-center;
}

.product {
  &-name-wrapper {
    @apply ds-flex ds-flex-col ds-items-start ds-overflow-hidden ds-w-full ds-mr-3;
  }

  &-name {
    @apply ds-mb-0 ds-font-semibold ds-overflow-ellipsis ds-overflow-hidden ds-w-full ds-text-left ds-text-neutral-900;
  }

  &-quantity-wrapper {
    @apply ds-flex ds-justify-between ds-items-center;

    @include media-breakpoint-down(lg) {
      @apply ds-items-start;
    }
  }

  &-files-wrapper {
    @apply ds-flex ds-items-center ds-mb-4;

    @include media-breakpoint-down(lg) {
      @apply ds-items-start;
    }
  }

  &-toggle-btn {
    @apply ds-flex ds-items-start ds-justify-between ds-w-full ds-h-auto;
  }

  &-delete-btn {
    @apply ds-mr-6;
  }
}
</style>
