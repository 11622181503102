import { Question, QuestionType } from '@/types/question';
import { httpsUrl } from '@/utilities/regex';
import { extraContentAnswers } from './constants';
import { QualificationQuestion } from '@/types/registration-funnel-questions';
import Store from '@/store/index';

export const required = (question?: Question | QualificationQuestion) => (value) => {
  if (!value) {
    return false;
  }
  if (question?.question_type === QuestionType.SingleChoiceDropdown) {
    return value.answer_id != null;
  } else {
    return value.content !== '';
  }
};

export const validationsByQuestion = {
  [QuestionType.SingleChoiceDropdown]: (question) => ({
    [question.alias]: {
      extraContentRequired: (value) => {
        if (value?.answer_id == null) {
          return true;
        }
        const answer = question.answers.find((item) => item.id === value.answer_id);
        if (answer && extraContentAnswers.includes(answer.answer_type)) {
          return value.content !== '';
        }
        return true;
      },
    },
  }),
  [QuestionType.CompanySearch]: () => ({
    store_name: {
      required: required(),
    },
  }),
  [QuestionType.MultipleChoiceOption]: (question) => ({
    [question.alias]: {
      required: (value) => {
        return Array.isArray(value) && value.length > 0;
      },
    },
  }),
  [QuestionType.GoogleMapAutocomplete]: (question, isRevamp) => {
    const object = {
      city: {
        required: required(),
      },
      postal_code: {
        required: required(),
      },
      address: {
        required: (value, model) => {
          if (!model.street?.content?.length) {
            return required()(value);
          }
          return model.street?.content !== '';
        },
      },
      street: {
        required: required(),
      },
    };
    return isRevamp
      ? {
          [question.alias]: object,
        }
      : object;
  },
  [QuestionType.Phone]: (question, isRevamp?) => ({
    [question.alias]: {
      phoneFormat: function (value) {
        if (isRevamp && !value.number.content) {
          return true;
        } else if (value?.content == '') {
          return true;
        }
        return Store.getters['signUp/formValues'].isPhoneValid;
      },
    },
  }),
  [QuestionType.Password]: (question) => ({
    [question.alias]: {
      passwordMinlength: (value) => {
        if (value.content == '') {
          return true;
        }
        return value.content.length >= 8;
      },
    },
  }),
  [QuestionType.Website]: (question) => ({
    [question.alias]: {
      format: (value) => {
        if (value.content !== '') {
          const url = value.content.startsWith('https://') ? value.content : `https://${value.content}`;
          return httpsUrl(url);
        }
        return true;
      },
    },
  }),
};
