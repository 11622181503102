<template>
  <div>
    <slot></slot>

    <div
      v-if="showMessage"
      class="billing-item-touchpoint"
    >
      <template v-if="showUpsell">
        <i18n-t
          keypath="product.touchpoint.program-offer.heavy-bulk-with-plus"
          tag="p"
          class="billing-item-touchpoint__text"
        >
          <template #ankorstorePlus>
            <AnkorstorePlusBadge class="billing-item-touchpoint__badge" />
          </template>
        </i18n-t>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';
import { Amount } from '@/types/amount';
import { BillingItemType } from '@/types/billing-item';
import { OfferType } from '@/modules/promote-engage/services/offer';
import { useStore } from '@/composables/use-store';

import AnkorstorePlusBadge from '@/modules/promote-engage/subscription-program/UI/ankorstore-plus-badge.vue';

import { isEnabled } from '@/services/features';

export default defineComponent({
  name: 'BillingItemProgramTouchpoint',
  components: {
    AnkorstorePlusBadge,
  },
  props: {
    price: {
      type: Object as PropType<Amount>,
      required: true,
    },
    itemType: {
      type: String as PropType<BillingItemType>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const ankorstorePlus = computed(() => {
      return store.getters['offers/ankorstorePlus'];
    });

    const isShippingFees = computed((): boolean => {
      return props.itemType === BillingItemType.shippingFees && !isEnabled('enable_new_business_model');
    });

    const showMessage = computed((): boolean => {
      return !!ankorstorePlus.value && isShippingFees.value && props.price?.amount > 0;
    });

    const showUpsell = computed((): boolean => {
      return !!(
        ankorstorePlus.value?.offers.find(
          (offer) => offer.type === OfferType.ShippingFees || offer.type === OfferType.HeavyBulkFees
        ) && isShippingFees.value
      );
    });

    return {
      ankorstorePlus,
      showMessage,
      showUpsell,
    };
  },
});
</script>

<style lang="scss">
.billing-item-touchpoint {
  @apply ds-mt-1;
  &__text {
    @apply ds-text-right ds-text-primary;
  }
  &__badge {
    @apply ds-ml-1;
  }
}
</style>
