<template>
  <div class="base-case-wrapper">
    <TrackParcels v-if="showComponent('TrackParcels')" />
    <ContactBrand v-if="showComponent('ContactBrand')" />
    <DescribeIssue v-if="showComponent('DescribeIssue')" />
    <CaseActions
      :next-step-ready="nextStepReady()"
      :show-back-button="showBackButton"
      @set-loader="setLoader"
      @submit="$emit('submit')"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { CaseReasonStep } from '@/types/order-retailer-rejection';
import BaseCase from '@/components/account/orders/order/order-retailer-reject/cases/base-case.vue';
import CaseActions from '@/components/account/orders/order/order-retailer-reject/components/case-actions.vue';
import TrackParcels from '@/components/account/orders/order/order-retailer-reject/components/track-parcels.vue';
import ContactBrand from '@/components/account/orders/order/order-retailer-reject/components/contact-brand.vue';
import DescribeIssue from '@/components/account/orders/order/order-retailer-reject/components/describe-issue.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotReceived',
  components: {
    TrackParcels,
    ContactBrand,
    DescribeIssue,
    CaseActions,
  },
  extends: BaseCase,
  emits: ['submit'],
  computed: {
    ...mapGetters('orderRetailerRejection', ['getCaseReason', 'getCurrentStep', 'getDescription']),
  },
  methods: {
    showComponent(component: string): boolean {
      let showComponent = false;
      const currentKey = this.getCurrentStep.key;

      switch (component) {
        case 'TrackParcels':
          if (currentKey === CaseReasonStep.TrackWithLink) {
            showComponent = true;
          }
          break;
        case 'ContactBrand':
          if (currentKey === CaseReasonStep.ContactBrand) {
            showComponent = true;
          }
          break;
        case 'DescribeIssue':
          if (currentKey === CaseReasonStep.Summary) {
            showComponent = true;
          }
          break;
      }

      return showComponent;
    },
    nextStepReady(): boolean {
      let stepReady = false;

      switch (this.getCurrentStep.key) {
        case CaseReasonStep.TrackWithLink:
          stepReady = true;
          break;
        case CaseReasonStep.ContactBrand:
          stepReady = true;
          break;
        case CaseReasonStep.OrderInfo:
          stepReady = !this.hasFormErrors;
          break;
        case CaseReasonStep.Summary:
          stepReady = this.getDescription.length > 0 && !this.hasFormErrors && !this.loading;
          break;
      }

      return stepReady;
    },
  },
});
</script>
