<template>
  <div
    :class="{
      'ds-px-6 ds-py-5': isNewStyleEnabled,
      'ds-bg-neutral-100': isNewStyleEnabled,
      'ds-border ds-border-solid ds-border-neutral-300 ds-rounded-md': isNewStyleEnabled,
    }"
  >
    <div v-if="isProductVariantEnabled && !isProductOutOfStock(product)">
      <VariantForm
        :layout="isNewStyleEnabled ? VariantLayout.Horizontal : VariantLayout.Vertical"
        :product="product"
        @selected-variant="setSelectedVariant($event)"
      />
    </div>
    <div v-else-if="!isProductOutOfStock(product)">
      <label v-if="multiOptions">
        <span>{{ $t('Options') }}</span>
        <span class="quantity">{{ $t('Quantity') }}</span>
      </label>
      <label v-else>{{ $t('Quantity') }}</label>
      <SelectInput
        v-for="option in product.options"
        :key="option.id"
        :model-value="quantities[option.id]"
        :out-of-stock="isVariantOutOfStock(option)"
        :list="listValuesForSelect(option)"
        :label="multiOptions ? option.variation.name : null"
        :class="{
          'ds-bg-white ds-border ds-border-solid ds-border-neutral-300 ds-rounded-md': isNewStyleEnabled,
        }"
        data-testid="productQuantitySelect"
        @update:model-value="onChangeSelect(option.id, $event)"
      />
    </div>
    <AkParagraph
      v-if="isRestrictedCountry"
      class="ds-mt-3 warning"
      data-testid="restrictedCountryMessage"
    >
      {{ $t('Sorry this brand is not available in your country.') }}
    </AkParagraph>
    <AkButton
      v-if="!isProductOutOfStock(product)"
      size="lg"
      :disabled="isButtonDisabled"
      class="justify-center button-add-to-cart"
      data-testid="addToCartButton"
      @click="syncWithCart()"
    >
      {{ buttonLabel }}
      <span
        v-if="showTotalPrice"
        class="total-price ds-ml-1"
      >
        {{ totalPriceLabel }}
      </span>
    </AkButton>
    <template v-else>
      <StockAlertBtn
        class="ds-top-2"
        btn-type="outlined"
        :is-loading="isStockAlertLoading"
        :product-variant-id="selectedVariant ? selectedVariant.id : variantId"
        :stock-alert="productVariantStockAlert"
        @set-stock-alert="createStockAlert(product.id, product.brand.id, selectedVariant?.id ?? variantId)"
      />
    </template>
    <div class="ds-mt-3">
      <slot name="footer" />
    </div>
    <AkModal
      v-if="isEnabled('oxp-1484-r3')"
      ref="preorderArrivedPopup"
      size="md"
      data-testid="preorderArrivedPopup"
      @cancelled="handlePopupClose"
    >
      <PreorderArrivedPopup @popup-close="handlePopupClose" />
    </AkModal>
  </div>
</template>

<script lang="ts">
import { loginPopinMixin } from '@/mixins/login-popin';
import { AkButton, AkParagraph } from '@ankorstore/design-system';
import {
  isProductOutOfStock,
  getVariantQuantity,
  getListValuesForSelectInput,
  getMaxQuantity,
  isVariantOutOfStock,
  calculateTotalPrice,
  isPreorder,
} from '@/services/product-variants';
import { Amount } from '@/types/amount';
import Product, { Variant, Option, PriceBreakdown, VariantLayout } from '@/types/product';
import { RetailerFormType } from '@/types/retailer-form-type';
import VariantForm from '@/components/cart/variant-form.vue';
import SelectInput from '@/components/select-input.vue';
import StockAlertBtn from '@/modules/reorder/stock-status/stock-alert-btn.vue';
import { useStockStatus } from '@/modules/reorder/stock-status/composables/stock-status';
import { defineComponent, computed, PropType, ref } from 'vue';
import ProductDraft from '@/types/account/account-products/catalog-integration/product-integration';
import { useStore } from '@/composables/use-store';
import { isEnabled } from '@/services/features';
import StoreRootHelpers from '@/store/helpers';
import PreorderArrivedPopup from '@/components/popins/preorder-arrived-popup.vue';
import Cookie from '@/utilities/cookies/cookies';
import { COOKIE_NAME } from '@/types/cookies';
import usePrice from '@/composables/use-price';

interface AddToCartData {
  quantities: { [key: number]: number };
  vatPopinTriggered: boolean;
  disabledButton: boolean;
  saving: boolean;
  selectedVariant: Variant | undefined;
}

export default defineComponent({
  name: 'AddToCart',
  components: { PreorderArrivedPopup, AkButton, SelectInput, VariantForm, StockAlertBtn, AkParagraph },
  mixins: [loginPopinMixin],
  props: {
    product: {
      type: Object as PropType<Product | ProductDraft>,
      required: true,
    },
    priceBreakdown: {
      type: Object as PropType<PriceBreakdown>,
      required: true,
    },
    isNewStyleEnabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isVariantsAtc: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['addToCart', 'done', 'openvat', 'select-variant', 'updateItemCart'],
  setup(props) {
    const store = useStore();
    const { getConvertedPrice, formatPrice } = usePrice();
    const { createStockAlert, isStockAlertLoading, productVariantStockAlert } = useStockStatus();
    const product: Product = props.product as Product;
    const { userCurrency } = StoreRootHelpers.useGetters(['userCurrency']);

    const totalPriceLabel = ref('');
    const preorderArrivedPopup = ref(null);

    const user = computed(() => store.getters['user']);
    const userIsRetailer = computed(() => store.getters['userIsRetailer']);
    const userIsBrand = computed(() => store.getters['userIsBrand']);
    const canGetWholesalePrice = computed(() => store.getters['canGetWholesalePrice']);
    const itemByOptionId = computed(() => store.getters['cart/itemByOptionId']);
    const itemByVariantId = computed(() => store.getters['cart/itemByVariantId']);
    const preorderItemByVariantId = computed(() => store.getters['cartPreorder/itemByVariantId']);
    const variantId = computed(() => {
      if (product?.variants && product?.variants.length > 0) {
        return product.variants[0].id;
      }
      return null;
    });
    const showTotalPrice = computed(() => {
      return (
        userIsRetailer.value &&
        canGetWholesalePrice.value &&
        (!isPreorder(product) || (isPreorder(product) && isEnabled('oxp-1484-r3') && userCurrency.value === 'EUR'))
      );
    });

    const handlePopupClose = () => {
      Cookie.set(COOKIE_NAME.PREORDER_ARRIVED_POPUP_SNOOZED, { expires: 365 });
      (preorderArrivedPopup.value as DS['AkModal']).close();
    };

    return {
      totalPriceLabel,
      user,
      userIsRetailer,
      userIsBrand,
      canGetWholesalePrice,
      itemByOptionId,
      itemByVariantId,
      preorderItemByVariantId,
      createStockAlert,
      isStockAlertLoading,
      productVariantStockAlert,
      userCurrency,
      variantId,
      showTotalPrice,
      VariantLayout,
      preorderArrivedPopup,
      handlePopupClose,
      getConvertedPrice,
      formatPrice,
    };
  },
  data(): AddToCartData {
    return {
      quantities: {},
      vatPopinTriggered: false,
      disabledButton: true,
      saving: false,
      selectedVariant: undefined,
    };
  },
  computed: {
    multiOptions(): boolean {
      return this.getVariantsArray.length > 1;
    },
    sum(): Amount {
      return calculateTotalPrice(
        this.priceBreakdown.finalPrice,
        this.quantities[this.selectedVariant?.id],
        this.product.unit_multiplier
      );
    },
    numberOfItemsAddedToCart(): number {
      let numberOfItemsAddedToCart = 0;

      this.getVariantsArray.forEach((option) => {
        numberOfItemsAddedToCart += this.quantities[option.id] * this.product.unit_multiplier;
      });

      return numberOfItemsAddedToCart;
    },
    buttonLabel(): string {
      if (this.user && !this.userIsRetailer) {
        return this.$t('Only retailers can shop');
      }
      if (this.isPreorder(this.product) && this.userCurrency !== 'EUR') {
        return this.$t('cart.preorder.cta.notAvailableCountries');
      }
      if (this.isPreorder(this.product) && !isEnabled('oxp-1484-r3')) {
        return this.$t('cart.preorder.cta.disabled');
      }
      if (this.isProductVariantEnabled && !this.selectedVariant) {
        return this.$t('Choose an option');
      }
      if (this.sum.amount === 0) {
        return this.alreadyInCart() ? this.$t('Remove from cart') : this.$t('Select quantities');
      }

      if (this.saving) {
        return this.$tc('Item added to cart! | Item added to cart! | Items added to cart!', this.numberOfItemsAddedToCart);
      }

      if (this.alreadyInCart()) {
        return this.isPreorder(this.product) ? this.$t('cart.preorder.update.cta') : this.$t('Update cart');
      }

      return this.isPreorder(this.product) ? this.$t('cart.preorder.cta') : this.$t('Add to cart');
    },
    getVariantsArray(): Variant[] | Option[] {
      return this.isProductVariantEnabled ? this.product.variants : this.product.options;
    },
    isProductVariantEnabled(): boolean {
      return this.product?.brand?.is_product_variants_enabled;
    },
    isButtonDisabled(): boolean {
      return (
        (this.user && !this.userIsRetailer) ||
        (this.sum.amount === 0 && !this.alreadyInCart()) ||
        (this.isProductVariantEnabled && !this.selectedVariant) ||
        (this.isPreorder(this.product) && !isEnabled('oxp-1484-r3')) ||
        (this.isPreorder(this.product) && this.userCurrency !== 'EUR') ||
        this.isRestrictedCountry
      );
    },
    isRestrictedCountry() {
      return !this.product.brand.opened_countries.includes(this.user?.business?.country.iso_code);
    },
  },
  watch: {
    async sum(newValue): Promise<void> {
      this.totalPriceLabel = newValue.amount === 0 ? '' : this.formatPrice(await this.getConvertedPrice(newValue));
    },
    canGetWholesalePrice(newValue): void {
      if (newValue) {
        // without that test, the syncWithCart is called even when we entered the vat to see the wholesale price
        // it was making a weird behavior like adding an item to the cart when you just wanted to see the wholesale price.
        if (this.vatPopinTriggered) {
          this.syncWithCart();
        }
      }
    },
  },
  created(): void {
    const defaultQuantity = this.multiOptions ? 0 : 1;
    this.getVariantsArray.forEach((option) => {
      const item =
        isPreorder(this.product) && isEnabled('oxp-1484-r3')
          ? this.getItemByVariant(option.uuid)
          : this.getItemForOption(option.id);
      this.quantities[option.id] = item ? item.quantity : defaultQuantity;
    });
    this.lastQuantities = this.quantities;
  },
  methods: {
    isEnabled,
    isPreorder,
    toggleClass(element: Element, classname: string): void {
      element.classList.toggle(classname);
      this.saving = true;
      setTimeout(() => {
        this.saving = false;
        element.classList.toggle(classname);
      }, 3000);
    },
    getItemForOption(id: number): Variant | Option {
      return this.isProductVariantEnabled ? this.itemByVariantId(id) : this.itemByOptionId(id);
    },
    getItemByVariant(uuid: string): Variant | Option {
      return this.preorderItemByVariantId(uuid);
    },
    onChangeSelect(optionId: number, value: string): void {
      this.quantities = Object.assign({}, this.quantities, {
        [optionId]: value,
      });
    },

    listValuesForSelect(option: Option): ReturnType<typeof getListValuesForSelectInput> {
      const alreadyInCart = this.multiOptions || this.alreadyInCart();
      const availableQuantity = getVariantQuantity(option);

      return getListValuesForSelectInput(
        alreadyInCart,
        getMaxQuantity(availableQuantity, this.quantities[option.id], this.product.unit_multiplier),
        this.product.unit_multiplier
      );
    },
    syncWithCart(): void {
      if (!this.user) {
        this.openLoginPopin(RetailerFormType.Register);
        return;
      }

      if (!this.canGetWholesalePrice) {
        this.vatPopinTriggered = true;
        this.$emit('openvat');
        return;
      }

      if (!this.product.refreshed) {
        alert('Product is not refreshed');
        return;
      }

      const buttonAddToCart = document.querySelector('.add-to-cart .button-add-to-cart');

      this.getVariantsArray.forEach((item) => {
        const quantity = parseInt(this.quantities[item.id]?.toString());
        const cartItem =
          isPreorder(this.product) && isEnabled('oxp-1484-r3')
            ? this.getItemByVariant(item.uuid)
            : this.getItemForOption(item.id);

        if (quantity && cartItem && quantity === cartItem.quantity) {
          return;
        }
        if (cartItem) {
          if (quantity === 0) {
            this.deleteItem(cartItem);
          } else {
            this.updateItem(cartItem, quantity, buttonAddToCart);
          }
        } else if (quantity > 0) {
          this.addToCart(item, quantity, buttonAddToCart);
        }

        this.lastQuantities = this.quantities;
        this.$emit('done');
      });
    },
    async addToCart(item: Option, quantity: number, buttonAddToCart: Element): Promise<void> {
      this.toggleClass(buttonAddToCart, 'added');
      let payload;

      if (isPreorder(this.product) && isEnabled('oxp-1484-r3')) {
        payload = {
          brand: this.product.brand,
          variant: item,
          quantity,
        };
      } else {
        payload = {
          brand: this.product.brand,
          option: item,
          variant: null,
          quantity,
        };
      }
      if (this.isProductVariantEnabled && this.selectedVariant) {
        payload.variant = item;
        payload.option = this.product.options.find(({ sku }) => sku === item.sku);
      }
      if (isPreorder(this.product) && isEnabled('oxp-1484-r3')) {
        await this.$store.dispatch('cartPreorder/addToCart', { ...payload });
        if (!Cookie.get(COOKIE_NAME.PREORDER_ARRIVED_POPUP_SNOOZED)) {
          (this.preorderArrivedPopup as DS['AkModal']).openModal();
        }
      } else {
        await this.$store.dispatch('cart/addToCart', { ...payload, noTrack: this.isVariantsAtc });
      }
      this.$emit('addToCart', payload);
    },
    updateItem(item: Option, quantity: number, buttonAddToCart: Element): void {
      this.toggleClass(buttonAddToCart, 'added');
      const quantityAlreadyInCart = item.quantity + quantity;

      if (isPreorder(this.product) && isEnabled('oxp-1484-r3')) {
        this.$store
          .dispatch('cartPreorder/updateItemQuantity', {
            brand: this.product.brand,
            item,
            quantity: quantityAlreadyInCart,
          })
          .then(() => {});
      } else {
        this.$store
          .dispatch('cart/updateItemQuantity', {
            brand: this.product.brand,
            item,
            quantity: quantityAlreadyInCart,
          })
          .then(() => {});
      }
      this.$emit('updateItemCart', { item, quantity });
    },
    deleteItem(item: Option): void {
      this.$store.dispatch('cart/deleteItem', {
        brand: this.product.brand,
        item: item,
      });
      // reset select value to 1 for only 1 option after deletedItem
      if (!this.multiOptions) {
        this.quantities[this.product.options[0].id] = 1;
      }
    },
    alreadyInCart(): boolean {
      if (
        this.isProductVariantEnabled &&
        this.selectedVariant &&
        (this.itemByVariantId(this.selectedVariant.id) || this.preorderItemByVariantId(this.selectedVariant?.uuid))
      ) {
        return true;
      }
      for (const option of this.getVariantsArray) {
        if (this.itemByOptionId(option.id)) {
          return true;
        }
      }
      return false;
    },
    setSelectedVariant(selected: { variant: Variant; quantity: number }): void {
      // reset the others value to cart value
      this.getVariantsArray.forEach((option) => {
        const item =
          isPreorder(this.product) && isEnabled('oxp-1484-r3')
            ? this.preorderItemByVariantId(option.uuid)
            : this.itemByVariantId(option.id);
        this.quantities = Object.assign({}, this.quantities, {
          [option.id]: item ? item.quantity : 0,
        });
      });
      // set new variant and quantity
      this.selectedVariant = selected.variant;
      if (selected.variant) {
        this.quantities = Object.assign({}, this.quantities, {
          [selected.variant.id]: selected.quantity,
        });
      }
      this.$emit('select-variant', this.selectedVariant);
    },
    isVariantOutOfStock,
    isProductOutOfStock,
  },
});
</script>

<style scoped lang="scss">
@import '@css/vue-import';

select {
  width: 100%;
}
button {
  width: 100%;
  margin-top: 10px;
}

label {
  @apply ds-text-neutral-700;
  position: relative;
  font-size: 14px;
  width: 100%;
  span.quantity {
    position: absolute;
    right: 0;
  }
}

.warning {
  color: rgba(255, 100, 0, var(--tw-text-opacity));
}

.button {
  height: 50px;

  span {
    display: inline-block;
    margin-left: 8px;
  }
}

.button-add-to-cart {
  @apply ds-text-base ds-sticky ds-bottom-0;
  margin-top: 20px;
}

button.added {
  i {
    opacity: 1;
    transition: opacity 1s linear;
  }

  .total-price {
    display: none;
  }
}

.button-black:active,
.button-black:hover,
.button-black:focus {
  @apply ds-bg-primary ds-text-white;
  text-decoration: none;
}

.button-black:disabled:active,
.button-black:disabled:hover,
.button-black:disabled:focus {
  @apply ds-text-neutral-300;
  @apply ds-bg-neutral-900;
}
</style>
