<template>
  <div
    v-if="hasBadges"
    class="ds-flex ds-gap-1 badge-list"
  >
    <template
      v-for="(badgeInfo, index) in displayedBadges"
      :key="index"
    >
      <AkBadge
        v-if="badgeInfo.text !== 'aks+'"
        :content="badgeInfo.text"
        :size="size"
        :symbol="badgeInfo.symbol"
        :color="badgeInfo.color"
      /><DiscountAkplusBadge
        v-else
        :key="index + 'aks+'"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { AkBadge } from '@ankorstore/design-system';
import {
  BadgeConfiguration,
  BadgeInfo,
  buildSourceBadgeList,
} from '@/modules/promote-engage/touchpoints/services/badges-list.service';
import { mapGetters } from 'vuex';
import { OfferProgramEligibility } from '@/modules/promote-engage/services/offer';
import DiscountAkplusBadge from '@/modules/promote-engage/discount-info/discount-akplus-badge.vue';

const DESKTOP_BADGES_AMOUNT = 2;
const MOBILE_BADGES_AMOUNT = 1;

export default defineComponent({
  name: 'BadgesList',
  components: {
    AkBadge,
    DiscountAkplusBadge,
  },
  props: {
    badgeInfos: {
      type: Array as PropType<BadgeConfiguration[]>,
      required: true,
    },
    size: {
      type: String as PropType<'xs' | 'sm' | 'base'>,
      default: 'xs',
    },
  },
  data() {
    return {
      displayedBadges: [] as BadgeInfo[],
      sourceBadges: [] as BadgeInfo[],
    };
  },
  computed: {
    ...mapGetters('offers', ['ankorstorePlusDiscount']),
    hasBadges(): boolean {
      return this.displayedBadges.length > 0;
    },
  },
  watch: {
    badgeInfos: {
      handler(newBadgesInfo: BadgeConfiguration[]): void {
        this.sourceBadges = buildSourceBadgeList(newBadgesInfo);

        this.computeDisplayedBadges();
      },
      immediate: true,
    },
    ankorstorePlusDiscount: {
      handler(newAkDiscount: OfferProgramEligibility): void {
        if (newAkDiscount) {
          this.sourceBadges = buildSourceBadgeList(this.badgeInfos);

          this.computeDisplayedBadges();
        }
      },
      immediate: true,
    },
  },
  created() {
    window.addEventListener('resize', this.computeDisplayedBadges);
  },
  unmounted() {
    window.removeEventListener('resize', this.computeDisplayedBadges);
  },
  methods: {
    computeDisplayedBadges(): void {
      if (this.sourceBadges.length === 1) {
        this.displayedBadges = this.sourceBadges;
      } else {
        const maxItems = window.innerWidth > this.$theme.screens.md ? DESKTOP_BADGES_AMOUNT : MOBILE_BADGES_AMOUNT;

        this.displayedBadges = this.sourceBadges.slice(0, maxItems);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.badge-list {
  @apply ds-normal-case ds-flex-wrap;
}
</style>
