<template>
  <ul>
    <li class="divider"></li>
    <li>
      <a class="disabled">{{ $t('Events') }}</a>
    </li>
    <li v-if="$isEnabled('campaigns')">
      <a class="disabled">{{ $t('Campaigns') }}</a>
    </li>
    <li>
      <a class="disabled">{{ $t('My network') }}</a>
    </li>
    <li>
      <a class="disabled">{{ $t('Orders') }}</a>
    </li>
    <li v-if="$isEnabled('prepared_orders')">
      <a class="disabled">{{ $t('Prepared orders') }}</a>
    </li>
    <li>
      <a class="disabled">{{ $t('Reviews') }}</a>
    </li>
    <li v-if="$isEnabled('messages')">
      <a class="disabled">{{ $t('Messages') }}</a>
    </li>
    <li v-if="!$isEnabled('mxb-703')">
      <a class="disabled">{{ $t('Accelerator') }}</a>
    </li>
    <li>
      <a :href="links.products">{{ $t('Products') }}</a>
    </li>
    <li v-if="$isEnabled('account_product_collections')">
      <a class="disabled">{{ $t('Collections') }}</a>
    </li>
    <li>
      <a class="disabled">{{ $t('Fulfilment') }}</a>
    </li>
    <li v-if="$isEnabled('account_store_page')">
      <a :href="links.store">{{ $t('brand.account.menu.desktop.storePage.lbl') }}</a>
    </li>
    <li v-if="!$isEnabled('account_store_page')">
      <a class="disabled">{{ $t('Online Store') }}</a>
    </li>
    <li v-if="$isEnabled('account_brand_private_offer_page')">
      <a class="disabled">{{ $t('Exclusivities') }}</a>
    </li>
    <li v-if="$isEnabled('account_brand_analytics')">
      <a class="disabled">{{ $t('Analytics') }}</a>
    </li>
    <li>
      <a class="disabled">{{ $t('Payments') }}</a>
    </li>
    <li v-if="$isEnabled('account_integrations')">
      <a
        :class="{ disabled: !$isEnabled('TECH-110.menu') }"
        :href="links.integrations"
      >{{ $t('Integrations') }}</a>
    </li>
    <li>
      <a :href="links.settings">{{ $t('Settings') }}</a>
    </li>
    <MenuMobileItemHelpCenter />
    <li class="divider"></li>
    <li>
      <a
        :href="links.logout"
        onclick="document.getElementById('logout-form').submit(); return false;"
      >{{ $t('Logout') }}</a>
    </li>
    <li class="divider"></li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MenuMobileItemHelpCenter from '@/components/header/menu-mobile-item-help-center.vue';

export default defineComponent({
  name: 'MenuMobileCandidateBrand',
  components: {
    MenuMobileItemHelpCenter,
  },
  props: {
    links: { type: Object, required: true },
  },
});
</script>
