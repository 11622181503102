import { Item } from './types';

export const extractSelectedCategoryId = (items: Item[]) => {
  const firstRefinement = items?.[0]?.refinements?.[0];
  const lastCategoryId = firstRefinement?.value?.split('>')?.at(-1);

  return lastCategoryId?.trim();
};

export const deselectCategory = (items: Item[]) => {
  const firstItem = items?.[0];

  if (!firstItem) {
    return;
  }

  firstItem.refine(firstItem.refinements[0]);
};
