<template>
  <AkTooltipInformation
    auto-hide
    hide-on-hover
    :hide-delay-seconds="6"
    :close-button="true"
    :content="content"
    :placement="placement"
    :show="isTooltipVisible && !isSnoozed"
    :custom-class="tooltipClassBasedOnType"
    data-testid="navbar-tooltip"
    trigger="manual"
    type="success"
    @hide="setSnoozeState()"
  />
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import { oneOf } from '@/utilities/propValidators';
import { withBreakpoints } from '@/modules/design-system-candidates';
import { COOKIE_NAME } from '@/types/cookies';
import Cookie from '@/utilities/cookies/cookies';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavbarTooltip',
  mixins: [withBreakpoints],
  props: {
    tooltipType: {
      type: String,
      required: true,
      validator: oneOf(['vatMissingInfo', 'vatMissingInfoMobile']),
    },
  },
  data() {
    return {
      content: null,
      display: null,
      isSnoozed: false,
    };
  },
  computed: {
    ...mapGetters('account/business', ['getVatInfo', 'isVatComplianceAvailableInCountry']),
    isUserOnAccountPage() {
      return this.$route?.matched.some(({ path }) => path === '/account');
    },
    placement() {
      return this.isMobile ? 'right' : 'bottom';
    },
    isTooltipVisible(): boolean {
      const vatMissingDisplay =
        !this.getVatInfo.vatInfoUpdated && !this.isUserOnAccountPage && this.isVatComplianceAvailableInCountry;

      switch (this.tooltipType) {
        case 'vatMissingInfo':
          return vatMissingDisplay && !this.isMobile;
        case 'vatMissingInfoMobile':
          return vatMissingDisplay && this.isMobile;
        default:
          return false;
      }
    },
    tooltipClassBasedOnType(): 'burger' | 'normal' {
      switch (this.tooltipType) {
        case 'vatMissingInfo':
          return 'normal';
        case 'vatMissingInfoMobile':
          return 'burger';
        default:
          return 'normal';
      }
    },
  },
  mounted() {
    this.content = this.getTooltipContent();
    this.checkSnoozeState();
  },
  methods: {
    getTooltipContent(): string {
      switch (this.tooltipType) {
        case 'vatMissingInfo':
        case 'vatMissingInfoMobile':
          return this.$t(
            'Your status must be updated. Add your business information in your Account settings to guarantee the right VAT is calculated.'
          );
        default:
          return '';
      }
    },
    checkSnoozeState() {
      const tooltipSnoozedState = Cookie.get(COOKIE_NAME.NAVBAR_TOOLTIP_SNOOZED);

      if (tooltipSnoozedState) {
        this.isSnoozed = true;
      }
    },
    setSnoozeState() {
      Cookie.set(COOKIE_NAME.NAVBAR_TOOLTIP_SNOOZED, 'true', { expires: 14 });
    },
  },
});
</script>
