<script setup lang="ts">
import { computed } from 'vue';
import { AisToggleRefinement } from 'vue-instantsearch/vue3/es';
import type { Filter } from '@bc/discovery/ui/filters';
import { CheckboxFilter } from '@bc/discovery/ui/filters';
import { useI18n } from 'vue-i18n';

defineOptions({ name: 'CustomAisBooleanFilter' });
const props = defineProps<{ filter: Filter }>();
const { t } = useI18n();

const label = computed(() => `${t('retailer.catalog.filter.boolean.lbl')} ${props.filter.title}`);
</script>

<template>
  <AisToggleRefinement :attribute="filter.name">
    <template #default="{ value, refine }">
      <CheckboxFilter
        :items="[{ ...value, label }]"
        @checkbox-changed="refine(value)"
      />
    </template>
  </AisToggleRefinement>
</template>
